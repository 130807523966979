import styled from "styled-components";

import { RightArrow } from "assets";
import { TextButton } from "shared/atoms/Button/Button";
import { CheckBox } from "shared/atoms/inputs";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";
import Row from "shared/atoms/Row";

const Input = styled(StyledInput)`
  max-width: 35px;
  height: 30px;
  padding: ${props => props.theme.spacing.S_5};
  margin: 0 ${props => props.theme.spacing.S_5};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type="number"] {
    appearance: textfield;
  }
`;

const StyledArrow = styled(RightArrow)`
  width: 12px;
  height: 12px;
`;

const ClearAllButton = styled(TextButton)`
  margin-left: auto;
  margin-right: auto;
  color: ${props => props.theme.colors.redesign.b100};

  &:disabled {
    color: ${props => props.theme.colors.greys.gunmetal};
    opacity: 0.5;
  }
`;

const Sidebar = styled.div<{ open?: boolean }>`
  min-width: ${props => (props.open ? "300px" : 0)};
  width: ${props => (props.open ? "300px" : 0)};
  position: relative;
  left: 0;
  background-color: ${props => props.theme.colors.greys.light4};
  transition: 0.3s;
`;

const Title = styled.span`
  display: inline-block;
  color: ${props => props.theme.colors.greys.warm};
  font-size: 18px;
  margin-top: ${props => props.theme.spacing.S_20};
`;

const StyledCheckBox = styled(CheckBox)`
  margin: 8px 0;
  ${props => props.theme.belowBreakpoint} {
    padding: 8px 0;
  }
`;

const Container = styled.div`
  overflow: hidden;
  padding: 10px 10px 0 20px;
  ${props => props.theme.belowBreakpoint} {
    padding: 0 20px;
  }
`;

const ModalContainer = styled.div`
  margin: ${props => props.theme.spacing.S_20};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
`;

const StyledRow = styled(Row)`
  cursor: pointer;
  padding: ${props => props.theme.spacing.S_15};
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  &:hover {
    background-color: ${props => props.theme.colors.greys.light2};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const CloseBtn = styled.a`
  position: absolute;
  top: ${props => props.theme.spacing.S_30};
  right: ${props => props.theme.spacing.S_30};
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
`;

const StyledSpan = styled.span`
  ${props => props.theme.font.table.row};
  line-height: 10px;
  margin-left: ${props => props.theme.spacing.S_10};
`;

export {
  Input,
  StyledArrow,
  StyledSpan,
  StyledRow,
  CloseBtn,
  Container,
  Title,
  ModalContainer,
  Sidebar,
  ClearAllButton,
  StyledCheckBox,
};
