import { useTranslation } from "react-i18next";
import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { ActivityLibraryEntry } from "api/schemas/ActivityLibraryEntry";
import type { GetActivityLibraryEntriesArgs } from "api/types/GetActivityLibraryEntriesArgs";
import useApiClient from "api/useApiClient";

export const getActivityLibraryEntriesQueryKey = (query: GetActivityLibraryEntriesArgs) =>
  ["ActivityLibraryEntries", query] as const;

export const useActivityLibraryEntries = (
  query: GetActivityLibraryEntriesArgs
): UseQueryResult<ActivityLibraryEntry[]> => {
  const { i18n } = useTranslation();
  const client = useApiClient();
  const locale = i18n.language.toLocaleLowerCase();
  return useQuery(getActivityLibraryEntriesQueryKey(query), () => client.getActivityLibraryEntries(locale, query));
};
