import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MUITextInput from "shared/atoms/inputs/MUITextInput";

import { SectionRow } from ".";

interface Props {
  readonly legacyText: string | undefined;
}

const LegacySection: React.VFC<Props> = ({ legacyText }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionRow>
        <LegacyLabel>{t("patients.notes.helptexts.medical_record_note")}</LegacyLabel>
      </SectionRow>
      <SectionRow>
        <LegacyField
          name="medical_record_note"
          label={t("patients.notes.fields.clinical_note")}
          value={legacyText}
          disabled
          unregistered
          multiline
        />
      </SectionRow>
    </>
  );
};

const LegacyLabel = styled.div`
  flex: 1;
  margin-top: ${props => props.theme.spacing.S_15};
  color: ${props => props.theme.colors.salmon};
  font-size: 14px;
`;

const LegacyField = styled(MUITextInput)`
  flex: 1;
  .MuiInputBase-root {
    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.salmon};
      }
    }
  }
`;

export { LegacySection };
