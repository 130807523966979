import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const getVoiceCallToken = (
  pid: number,
  id: number
): Promise<
  AxiosResponse<{
    token: "string";
    call_identifier: "string";
  }>
> =>
  AxiosInstance.post(`/app_to_app_calls/`, {
    to_id: pid,
    from_id: id,
  });

export default getVoiceCallToken;
