import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 970px;
  padding: ${props => props.theme.spacing.S_50} ${props => props.theme.spacing.S_15};
  margin: 0 auto;
`;
const RecordRow = styled.div`
  display: flex;
  max-width: 940px;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;
const RecordContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  color: ${props => props.theme.colors.greys.dark};
  ${props => props.theme.font.header4};
`;
const PartnerHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 940px;
  align-items: center;
  padding: ${props => props.theme.spacing.S_15};
  padding-bottom: 0;
  margin: 0 auto;
`;
const PartnerInfoText = styled.span`
  color: ${props => props.theme.colors.greys.dark};
  width: 220px;
  margin: ${props => props.theme.spacing.S_10} 0 0 auto;
`;

export { PartnerInfoText, Container, RecordRow, RecordContainer, PartnerHeaderContainer };
