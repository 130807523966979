import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { useProfileContext } from "contexts/ProfileContext";
import useUpcomingCalls from "routes/dashboard/default/queries/useUpcomingCalls";
import type { Appointment as AppointmentData } from "routes/messages/types";
import Pagination from "shared/atoms/Pagination";
import { Table, TableCell, TableFooter, TableHeader, TableMissingContent, TableRow } from "shared/molecules/Table";

import Appointment from "./components/Appointment";

const LIMIT = 10;

export type AppointmentType = {
  patient: {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  medium: string;
  startTime: string;
  endTime: string;
  purpose: string;
  location: string;
  id: number;
  state: null | string;
};

const UpcomingAppointments: React.VFC = () => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const { profile, setScheduledCalls } = useProfileContext();
  const [appointments, setAppointments] = useState<Array<AppointmentType>>([]);
  const START = offset + 1;
  const END = offset + LIMIT;

  invariant(profile);
  const { data, isLoading, error, refetch } = useUpcomingCalls(profile.id);

  useEffect(() => {
    if (data && !isLoading && !error) {
      setScheduledCalls(data);
      const tempAppointments = data
        .filter((_node: AppointmentData, index: number) => index >= offset && index < offset + LIMIT)
        .map((node: AppointmentData) => {
          return {
            id: node.id,
            patient: {
              id: node.patient_id,
              firstName: node.patient_first_name,
              lastName: node.patient_last_name,
              phoneNumber: node.patient_number,
            },
            medium: node.medium.toUpperCase(),
            startTime: node.start_time,
            endTime: node.end_time,
            purpose: node.purpose.toUpperCase(),
            location: node.location,
            state: node.state,
          };
        });
      setAppointments(tempAppointments as Array<AppointmentType>);
    }
  }, [data, offset]);

  const onRefetch = () => {
    if (data?.length === START) {
      setOffset(offset - LIMIT);
    }
    refetch();
  };

  const hasNextPage = data ? data.length > offset + LIMIT : false;
  const hasPreviousPage = offset !== 0 || false;
  const totalCount = data?.length || 0;

  return (
    <Table overflow="visible">
      <thead>
        <TableHeader colSpan={4} title={t("dashboard.tables.calls.title")} />
      </thead>
      <tbody>
        {(isLoading || error) && <TableMissingContent loading={isLoading} error={!!error} />}
        {data &&
          appointments.map(appointment => (
            <Appointment refetch={() => onRefetch()} data={appointment} key={appointment.id} />
          ))}
        {appointments.length <= 0 && (
          <TableRow hoverEnabled={false}>
            <TableCell>{t("dashboard.tables.calls.empty") as string}</TableCell>
          </TableRow>
        )}
      </tbody>
      <tfoot>
        {totalCount > LIMIT && (
          <TableFooter colSpan={4}>
            <Pagination
              totalCount={totalCount}
              first={START}
              last={END < totalCount ? END : totalCount}
              pageInfo={{ hasNextPage, hasPreviousPage }}
              onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
            />
          </TableFooter>
        )}
      </tfoot>
    </Table>
  );
};

UpcomingAppointments.propTypes = {};

export default UpcomingAppointments;
