import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NotificationSound } from "assets";
import { useDropInCallContext } from "contexts/DropInCallContext";
import { useNotificationsContext } from "contexts/NotificationsContext";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

const useDropInCallNotification = () => {
  const { t } = useTranslation();

  const { isReady, currentSession, patientName } = useDropInCallContext();
  const { addNotification, closeNotification } = useNotificationsContext();
  const [notificationId, setNotificationId] = useState<undefined | string>();
  const originalTitle = useRef(document.title);
  const navigate = useNavigate();

  const sessionState = currentSession?.state;
  const patientId = currentSession?.patient_id;

  const favicon = (document.getElementById("favicon") ?? {}) as HTMLAnchorElement;
  const baseUrl = window.location.origin;

  const generateNotification = (name?: string) => {
    const message = `${t("drop_in_calls.pane.review_waiting", { name })}`;

    return {
      message,
      buttonLabel: t("drop_in_calls.pane.review_cta_small"),
      onClick: () => {
        AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.START_REVIEW_CLICKED, { placement: "notification" });
        navigate(`/patients/${patientId}`);
      },
      onClose: () => setNotificationId(undefined),
    };
  };

  useEffect(() => {
    if (isReady && sessionState === "claimed" && !notificationId) {
      const sound = new Audio(NotificationSound);
      sound.play();

      const id = addNotification(generateNotification(patientName));
      setNotificationId(id);

      document.title = `⚠ ${originalTitle.current}`;
      favicon.href = `${baseUrl}/favicon-red.ico`;
    }
    if (sessionState !== "claimed" && notificationId) {
      closeNotification(notificationId);
      setNotificationId(undefined);
      document.title = originalTitle.current;
      favicon.href = `${baseUrl}/favicon.ico`;
    }
  }, [sessionState, isReady, patientName]);
};

export default useDropInCallNotification;
