import { useContext, useEffect, useState } from "react";

import { faArrowLeft, faNote } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import type { LateralLocation } from "api/models/PainLocation";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import type { HealthFormType } from "api/schemas/UserCourse";
import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";
import CheckBox from "shared/atoms/inputs/CheckBox";
import { CurrentPatientContext } from "utils/contexts";

import type { UserCourseView } from "../UserCourseScreen";

import DropDown from "./DropDown";
import IconLink from "./IconLink";
import PatientDetails from "./PatientDetails";
import RadioButtons from "./RadioButtons";
import {
  OuterMargin,
  SmallPrimaryButton,
  SmallSecondaryButton,
  TopBar,
  TopLeft,
  TopRight,
} from "./SharedStyledComponents";
import YesNoPopup from "./YesNoPopup";

const Summary = ({
  onTemplateClick,
  onMarkTreatmentReady,
  disableButtons,
  onSave,
  unsavedChanges,
  onChangeView,
  view,
  exercisesPerDay,
  onChangeExercisesPerDay,
  functionalTest,
  onChangeFunctionalTest,
  healthFormType,
  onChangeHealthFormType,
  healthFormTypeInteractable,
  enableMismatchWarning,
}: {
  onTemplateClick: () => void;
  onMarkTreatmentReady: () => void;
  disableButtons: boolean;
  onSave: () => void;
  unsavedChanges: boolean;
  onChangeView: (view: UserCourseView) => void;
  view: UserCourseView;
  exercisesPerDay: number;
  onChangeExercisesPerDay: (n: number) => void;
  functionalTest: boolean;
  onChangeFunctionalTest: (b: boolean) => void;
  healthFormType: HealthFormType | undefined;
  onChangeHealthFormType: (hft: HealthFormType) => void;
  healthFormTypeInteractable: boolean;
  enableMismatchWarning: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  const [healthFormTypeMismatchWarning, setHealthFormTypeMismatchWarning] = useState<string>("");
  const goBack = () => navigate(-1);

  const { data: healthJournal } = useGetPatientHealthJournalQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });

  useEffect(() => {
    const { ailment, most_painful_location } = patient;

    if (!(most_painful_location?.location || ailment) || healthFormType === undefined) {
      return;
    }

    const { location, lateral_location }: { location: Joint; lateral_location: LateralLocation } =
      patient.most_painful_location || {};

    if (enableMismatchWarning) {
      const healthFormJointTypeMap: { [key: string]: Joint[] } = {
        neck: ["neck"],
        hand_oa: ["hand"],
        hip_oa: ["hip"],
        knee_oa: ["knee"],
        low_back_pain: ["lower_back"],
        shoulder_pain: ["shoulder"],
      };

      const healthFormAilmentTypeMap: { [key: string]: Ailment[] } = {
        osteoporosis: ["osteoporosis", "fall_prevention"],
        fall_prevention: ["fall_prevention", "osteoporosis"],
      };

      const jointMatch = location && healthFormJointTypeMap[healthFormType]?.includes(location);
      const ailmentMatch = ailment && healthFormAilmentTypeMap[healthFormType]?.includes(ailment);

      if (location && !jointMatch && healthFormType !== "generic") {
        const painLocation = `${
          lateral_location && lateral_location !== "undefined"
            ? `${t(`patients.lateral_location.${lateral_location}`)} `
            : ""
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        }${t(`patients.pain_location.${location}.0`)}`;

        const hft = t(`usercourse.healthformtype.${healthFormType as HealthFormType}`);

        const message = t("usercourse.templates.HFTMismatchJoint", { hft, painLocation });

        setHealthFormTypeMismatchWarning(message);
      } else if (!location && ailment && !ailmentMatch) {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const translatedAilment = t(`patients.ailments.${ailment}`);

        const hft = t(`usercourse.healthformtype.${healthFormType as HealthFormType}`);

        const message = t("usercourse.templates.HFTMismatchAilment", { hft, ailment: translatedAilment });

        setHealthFormTypeMismatchWarning(message);
      } else {
        setHealthFormTypeMismatchWarning("");
      }
    }
  }, [healthFormType, patient]);

  const hfkeys: HealthFormType[] = [
    "generic",
    "hand_oa",
    "hip_oa",
    "knee_oa",
    "low_back_pain",
    "neck",
    "shoulder_pain",
    "fall_prevention",
    "osteoporosis",
  ];

  const healthFormOptions: { label: string; value: HealthFormType }[] = hfkeys.map(k => ({
    label: t(`usercourse.healthformtype.${k}`) || k,
    value: k,
  }));

  return (
    <Root>
      <OuterMargin>
        <RootWrapper>
          <TopBar>
            <TopLeft>
              <TopLink
                onClick={() => {
                  if (!unsavedChanges) {
                    setShowExitConfirm(false);
                    goBack();
                  } else {
                    setShowExitConfirm(true);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                {t("buttons.back")}
              </TopLink>
            </TopLeft>
            <TopRight>
              <IconLink
                onClick={() => {
                  onTemplateClick();
                }}
                icon={faNote}
                title={`${t("usercourse.templates.manage")}`}
                disabled={disableButtons}
              />
              {!patient.awaiting_treatment_ready && (
                <SmallPrimaryButton disabled={!unsavedChanges} onClick={onSave}>
                  {t("buttons.save")}
                </SmallPrimaryButton>
              )}
              {patient.awaiting_treatment_ready && (
                <SmallSecondaryButton disabled={!unsavedChanges} onClick={onSave}>
                  {t("buttons.save")}
                </SmallSecondaryButton>
              )}
              {patient.awaiting_treatment_ready && (
                <div id="tip_activate">
                  <SmallPrimaryButton onClick={onMarkTreatmentReady} disabled={disableButtons}>
                    {t("usercourse.mark_as_ready_cta")}
                  </SmallPrimaryButton>
                </div>
              )}
            </TopRight>
          </TopBar>
          <TopBar>
            <TopLeft>
              <Title>
                {patient.awaiting_treatment_ready ? t("usercourse.create_course") : t("usercourse.edit_course")}
              </Title>
            </TopLeft>
          </TopBar>
          <TopBar>
            <TopLeft>
              {!disableButtons && (
                <DetailsTable>
                  <tbody>
                    <Row>
                      <TitleTd>{t("usercourse.exercise_per_day")}</TitleTd>
                      <td>
                        <DropDown
                          options={[
                            { label: "1", value: "1" },
                            { label: "2", value: "2" },
                            { label: "3", value: "3" },
                            { label: "4", value: "4" },
                            { label: "5", value: "5" },
                            { label: "6", value: "6" },
                          ]}
                          value={String(exercisesPerDay)}
                          onChange={v => {
                            onChangeExercisesPerDay(Number(v));
                          }}
                        />
                      </td>
                    </Row>
                    <Row>
                      <TitleTd>{`${t("usercourse.function_test")}:`}</TitleTd>
                      <td>
                        {" "}
                        <CheckBox
                          name="functional-test-checkbox"
                          label=""
                          checked={functionalTest}
                          onChange={() => {
                            onChangeFunctionalTest(!functionalTest);
                          }}
                        />
                      </td>
                    </Row>
                    <Row>
                      <TitleTd>{`${t("usercourse.health_form_type")}:`}</TitleTd>
                      <td>
                        {healthFormTypeInteractable ? (
                          <DropDown
                            options={healthFormOptions}
                            value={healthFormType || ""}
                            onChange={v => {
                              onChangeHealthFormType(v || "generic");
                            }}
                          />
                        ) : (
                          <TitleTd>{t(`usercourse.healthformtype.${healthFormType || "generic"}`)}</TitleTd>
                        )}
                      </td>
                    </Row>
                  </tbody>
                </DetailsTable>
              )}
            </TopLeft>
            <TopRight>
              <PatientDetails ailment={healthJournal?.ailment} />
            </TopRight>
          </TopBar>
          {healthFormTypeMismatchWarning && <Warning>{healthFormTypeMismatchWarning}</Warning>}

          <VertSpace>
            {!disableButtons && (
              <RadioButtons
                options={[
                  { label: t("usercourse.exercises"), value: "exercises" },
                  { label: t("usercourse.lessons"), value: "lessons" },
                ]}
                value={view}
                onChange={value => {
                  onChangeView(value);
                }}
                pillForm
              />
            )}
          </VertSpace>
        </RootWrapper>
        {showExitConfirm && (
          <YesNoPopup
            headerText={t("usercourse.unsavedchanges.header")}
            bodyText={t("usercourse.unsavedchanges.body")}
            onClose={() => {
              setShowExitConfirm(false);
            }}
            onYes={() => {
              onSave();
              goBack();
            }}
            onNo={() => {
              goBack();
            }}
          />
        )}
      </OuterMargin>
    </Root>
  );
};

export default Summary;

const VertSpace = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 1.4em;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: 8px;
`;

const TopLink = styled.div`
  color: ${props => props.theme.colors.redesign.b100};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Root = styled.div`
  background-color: white;
`;

const DetailsTable = styled.table`
  margin-bottom: 20px;
`;

const TitleTd = styled.td`
  padding-right: 10px;
`;
const Row = styled.tr`
  height: 30px;
`;

const Warning = styled.div`
  background-color: ${props => props.theme.colors.salmon};
  margin-bottom: 32px;
  color: white;
  padding: 20px;
`;

const RootWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;
