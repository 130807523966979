import type React from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { EditIcon } from "assets";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";
import { Notification } from "shared/atoms/Notification";

import { updatePassword } from "../../queries";

const ProfilePasswordContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
`;

const ProfilePasswordTable = styled.table`
  padding: ${props => props.theme.spacing.S_20} 0;
`;

const StyledTableRow = styled.tr`
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const PersonalPasswordProperty = styled.td`
  ${props => ({ ...props.theme.font.body1 })}
  min-width: 166px;
  padding: ${props => props.theme.spacing.S_10};
`;

const PersonalPasswordValue = styled.div`
  ${props => ({ ...props.theme.font.body1 })}
  font-family: "Roboto", sans-serif;
  font-weight: ${props => props.theme.fontWeight.regular};
  padding: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  flex-basis: 100%;
  box-sizing: border-box;
  border: 2px solid hsla(0, 0%, 0%, 0);
  color: ${props => props.theme.colors.greys.dark};
  background-color: transparent;
`;

const PersonalPasswordInput = styled(StyledInput)`
  width: 300px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledTableData = styled.td`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin: auto 0 auto auto;
  }
`;

const ProfilePassword: React.VFC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isNotValid, setIsNotValid] = useState({ state: false, reason: "" });
  const { handleSubmit, register } = useForm<{
    current_password: string;
    new_password: string;
    confirm_new_password: string;
  }>();

  const { t } = useTranslation();

  const handleChangePasswordClick = () => {
    setIsEditing(!isEditing);
    setIsNotValid({ ...isNotValid, state: false, reason: "" });
  };

  const onSubmit = handleSubmit(async dataFromForm => {
    setIsNotValid({ ...isNotValid, state: false });
    const parcel = {
      current_password: dataFromForm.current_password,
      password: dataFromForm.new_password,
      password_confirmation: dataFromForm.confirm_new_password,
    };
    if (!dataFromForm.current_password) {
      setIsNotValid({ ...isNotValid, state: true, reason: "current_password_missing" });
      return;
    }
    if (dataFromForm.new_password !== dataFromForm.confirm_new_password) {
      setIsNotValid({ ...isNotValid, state: true, reason: "not_equal" });
      return;
    }
    if (dataFromForm.new_password.length <= 7) {
      setIsNotValid({ ...isNotValid, state: true, reason: "too_short" });
      return;
    }
    updatePassword(parcel)
      .then(() => {
        setIsEditing(!isEditing);
      })
      .catch(err => {
        setError(err);
      });
  });

  return (
    <ProfilePasswordContainer data-testid="profile-password">
      <form onSubmit={onSubmit}>
        <ProfilePasswordTable>
          <tbody>
            <StyledTableRow>
              <PersonalPasswordProperty>{t("settings.profile_password.password")}</PersonalPasswordProperty>
              <StyledTableData>
                {isEditing ? (
                  <PersonalPasswordInput
                    data-testid="current-password"
                    {...register("current_password")}
                    type="password"
                    placeholder="************"
                  />
                ) : (
                  <PersonalPasswordValue>************</PersonalPasswordValue>
                )}
              </StyledTableData>
            </StyledTableRow>
            {isEditing && (
              <>
                <StyledTableRow>
                  <PersonalPasswordProperty>{t("settings.profile_password.new_password")}</PersonalPasswordProperty>
                  <StyledTableData>
                    <PersonalPasswordInput
                      data-testid="new-password"
                      type="password"
                      {...register("new_password")}
                      placeholder=""
                    />
                  </StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                  <PersonalPasswordProperty>{t("settings.profile_password.confirm_password")}</PersonalPasswordProperty>
                  <StyledTableData>
                    <PersonalPasswordInput
                      data-testid="confirm-password"
                      type="password"
                      {...register("confirm_new_password")}
                      placeholder=""
                    />
                  </StyledTableData>
                </StyledTableRow>
              </>
            )}
          </tbody>
        </ProfilePasswordTable>
        {isEditing ? (
          <Wrapper>
            <TextButton onClick={handleChangePasswordClick}>{t("buttons.cancel")}</TextButton>
            <StyledPrimaryButton type="submit">{t("buttons.save")}</StyledPrimaryButton>
          </Wrapper>
        ) : (
          <IconButton
            label={t("settings.profile_password.change_profile_password")}
            onClick={handleChangePasswordClick}
          >
            <EditIcon />
          </IconButton>
        )}
      </form>
      {isNotValid.state && (
        <Notification variant="danger" style={{ margin: "18px auto" }}>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(`settings.profile_password.${isNotValid.reason}`)}
        </Notification>
      )}
      {error && (
        <Notification variant="danger" style={{ margin: "10px 0" }}>
          {t("errors.generic")}
        </Notification>
      )}
    </ProfilePasswordContainer>
  );
};

export default ProfilePassword;
