import { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useProfileContext } from "contexts/ProfileContext";
import { userIsDoctor, userIsPartner } from "utils/profile/profileHelper";

import AboutYou from "./views/AboutYou";
import Complete from "./views/Complete";
import PartnerWelcomePage from "./views/PartnerWelcomePage";
import ProfilePicture from "./views/ProfilePicture";
import WelcomePage from "./views/WelcomePage";

const OnboardingRoutes = (): JSX.Element => {
  const { profile } = useProfileContext();
  const navigate = useNavigate();
  const isDoctor = userIsDoctor(profile);
  const isPartner = userIsPartner(profile);

  invariant(profile);

  useEffect(() => {
    if (profile.state !== "in_onboarding") {
      navigate("/dashboard");
    }
  }, [profile]);

  if (isPartner) {
    return (
      <OnboardingContainer>
        <OnboardingContent>
          <Routes>
            <Route path="complete" element={<Complete />} />
            <Route path="/" element={<PartnerWelcomePage />} />
          </Routes>
        </OnboardingContent>
      </OnboardingContainer>
    );
  }
  if (isDoctor) {
    return (
      <OnboardingContainer>
        <OnboardingContent>
          <Routes>
            <Route path="/" element={<Complete />} />
          </Routes>
        </OnboardingContent>
      </OnboardingContainer>
    );
  }
  return (
    <OnboardingContainer>
      <OnboardingContent>
        <Routes>
          <Route path="profile-picture" element={<ProfilePicture />} />
          <Route path="about-you" element={<AboutYou />} />
          <Route path="complete" element={<Complete />} />
          <Route path="/" element={<WelcomePage />} />
        </Routes>
      </OnboardingContent>
    </OnboardingContainer>
  );
};

export default OnboardingRoutes;

const OnboardingContainer = styled.div`
  height: 100vh;
  width: 100%;
  padding: 100px ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_50}
    ${props => props.theme.spacing.S_15};
  display: block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
`;

const OnboardingContent = styled.div`
  margin: 0 auto;
  max-width: 680px;
`;
