import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface PutPatientNote {
  readonly id: number;
  readonly therapistId: number;
  readonly note: string;
}

const putPatientNote = ({ id, therapistId, note }: PutPatientNote): Promise<AxiosResponse<void>> =>
  AxiosInstance.put(`/patients/${id}/note`, {
    therapist_id: therapistId,
    note,
  });

export default putPatientNote;
