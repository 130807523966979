import type React from "react";
import { useCallback, useEffect, useState } from "react";

import Calendar from "react-calendar";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

import { CalendarIcon, ClockIcon, CrossIcon, ProfileIcon } from "assets";
import "react-calendar/dist/Calendar.css";
import { useProfileContext } from "contexts/ProfileContext";
import type { Appointment, Purpose } from "routes/messages/types";
import { Medium } from "routes/messages/types";
import { getPatientInfo } from "routes/patients/queries";
import { PrimaryButton } from "shared/atoms/Button";
import Dropdown from "shared/atoms/inputs/Dropdown";
import { Notification } from "shared/atoms/Notification";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import useLocalizedDate from "utils/date";
import useHeight from "utils/hooks/useHeight";
import capitalize from "utils/string/capitalize";

import useScheduleCall from "../../queries/useScheduleCall";

import ScheduledCalls from "./components/ScheduledCalls";
import {
  Buttons,
  CalendarAndCalls,
  Ends,
  Header,
  Patient,
  PurposeWrapper,
  ScheduleCallForm,
  Starts,
  Type,
} from "./helpers";

interface Props {
  show: boolean;
  closeCallback: (value: boolean) => void;
  selectedPurpose?: Purpose;
  selectedMedium?: Medium;
  patientId: number;
}

const ScheduleForm: React.VFC<Props> = ({ show, closeCallback, patientId, selectedPurpose, selectedMedium }) => {
  const { t } = useTranslation();
  const {
    add,
    format,
    formatISO,
    parseISO,
    isSameDay,
    set,
    sub,
    isBefore,
    differenceInCalendarMonths,
    areIntervalsOverlapping,
  } = useLocalizedDate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm();
  const { profile, scheduledCalls, setScheduledCalls } = useProfileContext();
  const [error, setError] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState(false);
  const [heightRef, heightMax] = useHeight<HTMLDivElement>();

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarActiveStartDate, setCalendarActiveStartDate] = useState<Date | undefined>(undefined);

  const watchStartTime = watch("startTime");
  const watchEndTime = watch("endTime");
  const watchDay = watch("day", new Date().getDate().toString());
  const watchMonthOffset = watch("monthOffset", "0");

  const { animDisplay, maxHeight, borderTopWidth } = useSpring({
    maxHeight: show ? heightMax : 0,
    animDisplay: show ? 1 : 0,
    borderTopWidth: show ? "1px" : "0px",
  });

  const scheduleCall = useScheduleCall();

  const location = useLocation();
  const isPatientProfile = Boolean(location.pathname.includes("/patients"));

  const isValidMeeting = (startTime?: string, endTime?: string) => {
    if (!startTime || !endTime) {
      return false;
    }
    const [startHour, startMinute] = startTime.split("_").map(x => parseInt(x, 10));
    const [endHour, endMinute] = endTime.split("_").map(x => parseInt(x, 10));
    const start = set(new Date(), { hours: startHour, minutes: startMinute });
    const end = set(new Date(), { hours: endHour, minutes: endMinute });

    return isBefore(start, end);
  };

  const isDoubleBooking = (startTime: string, endTime: string) => {
    const [startHour, startMinute] = startTime.split("_").map(x => parseInt(x, 10));
    const [endHour, endMinute] = endTime.split("_").map(x => parseInt(x, 10));
    const fullStartDate = set(add(new Date(), { months: Number(watchMonthOffset) }), {
      date: Number(watchDay),
      hours: Number(startHour),
      minutes: Number(startMinute),
      seconds: 0,
      milliseconds: 0,
    });
    const fullEndDate = set(fullStartDate, { hours: Number(endHour), minutes: Number(endMinute), milliseconds: 0 });

    const match = scheduledCalls.find((el: Appointment) => {
      return areIntervalsOverlapping(
        { start: fullStartDate, end: fullEndDate },
        { start: parseISO(el.start_time), end: parseISO(el.end_time) }
      );
    });

    if (match) {
      return true;
    }
    return false;
  };

  const [patientProfileRequest, setPatientProfileRequest] = useState<{
    data: { first_name?: string; last_name?: string };
    loading: boolean;
    error?: string;
  }>({
    data: {},
    loading: true,
    error: undefined,
  });

  useEffect(() => {
    getPatientInfo(patientId)
      .then(({ data }) => {
        setPatientProfileRequest({
          data,
          loading: false,
          error: undefined,
        });
      })
      .catch(err => {
        setPatientProfileRequest({ data: {}, loading: false, error: err });
      });
  }, [patientId]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [patientId]);

  useEffect(() => {
    // Manually register these since they have no input component but relies only on calendar
    register("day", { required: true });
    register("monthOffset", { required: true });
  }, []);

  useEffect(() => {
    setValue("day", Number(calendarDate.getDate()));
    const now = new Date();
    const future = set(now, {
      year: Number(calendarDate.getFullYear()),
      month: Number(calendarDate.getMonth()),
    });
    const monthOffset = differenceInCalendarMonths(future, now);
    setValue("monthOffset", monthOffset);
  }, [calendarDate]);

  // Set default meeting time to 15 min unless user specifies otherwise
  useEffect(() => {
    const start = getValues("startTime");
    if (start && start !== "-") {
      setValue("endTime", getTimeOptions(start, false)[0]?.value);
    }
  }, [watchStartTime]);

  // double booking warning
  useEffect(() => {
    const start = getValues("startTime");
    const end = getValues("endTime");

    if (isValidMeeting(start, end)) {
      if (isDoubleBooking(start, end)) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [watchStartTime, watchEndTime, watchDay, watchMonthOffset]);

  const onSubmit = handleSubmit(({ day, endTime, monthOffset, purpose, startTime, type }) => {
    const [startHours, startMinutes] = startTime.split("_");
    const [endHours, endMinutes] = endTime.split("_");

    const startDate = set(add(new Date(), { months: Number(monthOffset) }), {
      date: Number(day),
      hours: Number(startHours),
      minutes: Number(startMinutes),
      seconds: 0,
    });

    const endDate = set(startDate, { hours: Number(endHours), minutes: Number(endMinutes) });
    scheduleCall
      .mutateAsync({
        body: {
          health_care_professional_id: profile?.id ?? 0,
          patient_id: patientId,
          start_time: formatISO(startDate),
          end_time: formatISO(endDate),
          purpose: purpose.toLowerCase(),
          medium: type.toLowerCase(),
          location: type === "PHONE" ? null : "-",
        },
      })
      .then(response => {
        AnalyticsService.track(AnalyticsEvents.MESSAGES.SCHEDULE_CALL, {
          purpose: response.data.purpose,
          patient: response.data.patient_id,
        });
        setScheduledCalls((calls: Appointment[]) => [...calls, response.data as unknown as Appointment]);
        setShowWarning(false);
        setError(false);
        reset();
        resetCalendar();
        setValue("purpose", t("messages.appointments.purpose"));
        closeCallback(true);
      })
      .catch(() => {
        setError(true);
      });
  });

  const formatTime = (hours: number, minutes: number) => {
    return format(set(new Date(), { hours, minutes }), "p");
  };

  const formatValue = (hour: number, minutes: number) => {
    return `${hour}_${minutes}`;
  };

  const getTimeOptions = (startTime: string, start: boolean) => {
    const hours = [];
    let startHour = Number(startTime.split("_")[0]);
    let startMinutes = Number(startTime.split("_")[1]);
    for (let hour = startHour; hour < 24; hour += 1) {
      if (!start && hour === startHour) {
        if (startMinutes + 15 > 60) {
          startHour += 1;
          startMinutes = (startMinutes + 15) % 60;
        } else {
          startMinutes += 15;
        }
        for (let minutes = startMinutes; minutes < 60; minutes += 5) {
          hours.push({
            value: formatValue(startHour, minutes),
            label: formatTime(startHour, minutes),
          });
        }
      } else {
        for (let minutes = 0; minutes < 60; minutes += 5) {
          hours.push({
            value: formatValue(hour, minutes),
            label: formatTime(hour, minutes),
          });
        }
      }
    }
    if (!start) {
      hours.push({ value: formatValue(24, 0), label: formatTime(24, 0) });
    }

    return hours;
  };

  const getPurposeOptions = () => {
    return [
      { value: "KICK_OFF", label: t("messages.appointments.types.KICK_OFF") },
      { value: "FOLLOW_UP", label: t("messages.appointments.types.FOLLOW_UP") },
      ...(profile?.market === "SE"
        ? [
            {
              value: "DISCHARGE_CALL",
              label: t("messages.appointments.types.DISCHARGE_CALL"),
            },
          ]
        : []),
      { value: "GENERAL", label: t("messages.appointments.types.GENERAL") },
    ];
  };

  const getTypeOptions = () => {
    let types = [{ value: "PHONE", label: t("messages.appointments.types.PHONE") }];

    if (profile?.therapist_profile?.video_calls_enabled || profile?.therapist_profile?.payer_video_call_required) {
      types = [...types, { value: "VIDEO", label: t("messages.appointments.types.VIDEO") }];
    }

    return types;
  };

  const startTime =
    Number(watchMonthOffset) === 0 && Number(watchDay) === new Date().getDate() && new Date().getHours() > 5
      ? formatValue(sub(new Date(), { hours: 1 }).getHours(), 0)
      : formatValue(5, 0);

  const resetForm = () => {
    closeCallback(false);
    setError(false);
    reset();
    resetCalendar();
    setValue("purpose", t("messages.appointments.purpose"));
  };

  const resetCalendar = () => {
    const today = new Date();
    setCalendarActiveStartDate(today);
    setCalendarDate(today);
  };

  // https://github.com/wojtekmaj/react-calendar/issues/342
  const onCalendarActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date }) => {
    setCalendarActiveStartDate(activeStartDate);
  };

  const hasBookings = (date: Date) => {
    return scheduledCalls.filter((call: Appointment) => isSameDay(parseISO(call.start_time), date)).length > 0;
  };

  const getNavigationLabel = useCallback(({ date }: { date: Date }) => capitalize(format(date, "LLLL")), []);

  const isTileDisabled = useCallback(({ date }: { date: Date }) => isBefore(add(date, { days: 1 }), new Date()), []);

  const getTileContent = ({ date }: { date: Date }) => {
    const isToday = isSameDay(date, new Date());

    return hasBookings(date) ? (
      <DotWrapper>
        <BookedDayDot $isToday={isToday} />
      </DotWrapper>
    ) : null;
  };

  return (
    <form data-testid="schedule-form" onSubmit={onSubmit}>
      <Container
        style={{
          maxHeight,
          borderTopWidth,
          width: "100%",
          display: animDisplay.interpolate(displ => (displ === 0 ? "none" : "flex")),
        }}
      >
        <div ref={heightRef}>
          <ScheduleCallForm $isPatientProfile={isPatientProfile}>
            <Header>
              <Title>{t("messages.schedule_call")}</Title>
              <Close
                data-testid="scheduleform-cancel-btn"
                onClick={() => {
                  resetForm();
                }}
              >
                <CrossIcon />
              </Close>
            </Header>
            <CalendarAndCalls>
              <CalendarAndCallsWrapper data-testid="calendar">
                <Calendar
                  onChange={setCalendarDate}
                  value={calendarDate}
                  activeStartDate={calendarActiveStartDate}
                  onActiveStartDateChange={onCalendarActiveStartDateChange}
                  prev2Label={null}
                  next2Label={null}
                  minDetail="month"
                  showFixedNumberOfWeeks
                  navigationLabel={getNavigationLabel}
                  tileDisabled={isTileDisabled}
                  tileContent={getTileContent}
                />
                <ScheduledCallsWrapper>
                  <ScheduledCalls chosenDate={calendarDate} />
                </ScheduledCallsWrapper>
              </CalendarAndCallsWrapper>
            </CalendarAndCalls>
            <Patient>
              <Info>
                <ProfileIconWrapper />
                {`${patientProfileRequest.data.first_name} ${patientProfileRequest.data.last_name}`}
              </Info>
              <Info>
                <CalendarIconWrapper />
                {format(calendarDate, "PP")}
              </Info>
            </Patient>
            <Starts>
              <Info>
                <ClockIconWrapper />
                <FormFieldLabel>{t("common.begins")}</FormFieldLabel>
              </Info>
              <SmallDropdown
                m="5px 0 0 0"
                size="small"
                options={getTimeOptions(startTime, true)}
                placeholder="-"
                {...register("startTime", { required: true, validate: value => value !== "-" })}
                error={errors.startTime && t("errors.string.empty")}
              />
            </Starts>
            <Ends>
              <Info>
                <ClockIconWrapper />
                <FormFieldLabel>{t("common.ends")}</FormFieldLabel>
              </Info>
              <SmallDropdown
                m="5px 0 0 0"
                size="small"
                options={
                  watchStartTime && watchStartTime !== "-"
                    ? getTimeOptions(watchStartTime, false)
                    : getTimeOptions(startTime, false)
                }
                placeholder="-"
                {...register("endTime", { required: true, validate: value => value !== "-" })}
                error={errors.endTime && t("errors.string.empty")}
              />
            </Ends>
            <PurposeWrapper>
              <FormFieldLabel>{t("messages.appointments.purpose")}</FormFieldLabel>
              <LargeDropdown
                m="5px 0 0 0"
                size="small"
                options={getPurposeOptions()}
                selected={selectedPurpose}
                placeholder={t("messages.appointments.purpose")}
                {...register("purpose", {
                  required: true,
                  validate: value => value !== t("messages.appointments.purpose"),
                })}
                error={errors.purpose && t("errors.string.empty")}
              />
            </PurposeWrapper>
            <Type>
              <FormFieldLabel>{t("messages.appointments.type")}</FormFieldLabel>
              <LargeDropdown
                size="small"
                options={getTypeOptions()}
                placeholder={t("messages.appointments.type")}
                selected={selectedMedium === Medium.VIDEO ? "VIDEO" : "PHONE"}
                {...register("type", {
                  required: true,
                  validate: value => value !== t("messages.appointments.type"),
                })}
                error={errors.type && t("errors.string.empty")}
                m="5px 0 0 0"
              />
            </Type>
            <Buttons>
              <ButtonGroup>
                <PrimaryButton type="submit" disabled={scheduleCall.isLoading}>
                  {t("messages.schedule")}
                </PrimaryButton>
              </ButtonGroup>
              {error && (
                <Notification variant="danger" style={{ marginBottom: "5px" }}>
                  {t("errors.generic")}
                </Notification>
              )}
              {showWarning && (
                <Notification variant="warning" style={{ margin: "10px 5px" }}>
                  {t("messages.appointments.double_booking_warning")}
                </Notification>
              )}
            </Buttons>
          </ScheduleCallForm>
        </div>
      </Container>
    </form>
  );
};

export default ScheduleForm;

const DotWrapper = styled.span`
  position: relative;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(animated.div)`
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.header4}
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: ${props => props.theme.zIndex.belowMessage};

  ${props => props.theme.belowBreakpoint} {
    height: 100vh;
    overflow-y: scroll;
  }
  overflow: hidden;

  .react-calendar {
    width: 330px;
    background: #f6f6f6;
    border: none;
    border-radius: ${props => props.theme.borderRadius.basic};

    ${props => props.theme.belowBreakpoint} {
      width: 320px;
    }
  }

  .react-calendar__tile {
    border-radius: 50%;
    border: 2px solid #f6f6f6;
    padding: 1em 0.5em;
    color: #3e5672;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: 12px;
  }

  .react-calendar__month-view__weekdays {
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: 10px;
    line-height: 12px;
    color: #3e5672;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #3e5672;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .react-calendar__tile--active {
    background: ${props => props.theme.colors.redesign.b30};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${props => props.theme.colors.redesign.b30};
  }

  .react-calendar__tile--now {
    color: white;
    background: ${props => props.theme.colors.redesign.b100};
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${props => props.theme.colors.redesign.b100};
  }

  .react-calendar__navigation__arrow {
    font-size: 24px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f6f6f6;
    font-size: 24px;
  }

  .react-calendar__navigation button[disabled] {
    ${props => props.theme.font.body2};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: black;
    background-color: #f6f6f6;
  }

  .react-calendar__tile:disabled {
    color: gray;
    background-color: #f0f0f0;
  }

  /* Remove dotted lines under calendar day titles */
  abbr[title] {
    text-decoration: none;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: 21px;
  font-size: 18px;
  height: 46px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Info = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: 19px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CalendarAndCallsWrapper = styled.div`
  background-color: #f6f6f6;
  padding: ${props => props.theme.spacing.S_10};
  padding-top: 0;
  border-radius: ${props => props.theme.borderRadius.basic};
`;

const ScheduledCallsWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.S_10};
`;

const ProfileIconWrapper = styled(ProfileIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
`;

const CalendarIconWrapper = styled(CalendarIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
`;

const ClockIconWrapper = styled(ClockIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
`;

const FormFieldLabel = styled.span`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: 16px;
  font-size: 14px;
`;

const Close = styled.div`
  padding: 2px;
`;

const SmallDropdown = styled(Dropdown)`
  width: 100%;
`;

const LargeDropdown = styled(Dropdown)`
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  margin: ${props => props.theme.spacing.S_30} 0;
  align-items: center;
  justify-content: center;
`;

const BookedDayDot = styled.span<{ $isToday: boolean }>`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${props => (props.$isToday ? "white" : props.theme.colors.redesign.b100)};
  display: inline-block;
`;
