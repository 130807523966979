import { z } from "zod";

const FeelingReportArgsSchema = z.object({
  patientID: z.number(),
  treatment_id: z.number(),
});
export type FeelingReportArgs = z.infer<typeof FeelingReportArgsSchema>;

export const FeelingReportSchema = z.object({
  week: z.number(),
  general_feeling: z.number(),
});
export type FeelingReport = z.infer<typeof FeelingReportSchema>;
