import { useContext } from "react";

import DropInCallTimerContext from "./DropInCallTimerContext";
import type { DropInCallTimerContextValue } from "./DropInCallTimerContextValue";

const useDropInCallTimerContext = (): DropInCallTimerContextValue => {
  const value = useContext(DropInCallTimerContext);

  if (value === null) {
    throw new Error("useDropInCallTimerContext cannot be used outside the DropInCallTimerContextProvider");
  }
  return value;
};

export default useDropInCallTimerContext;
