import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { abortBankID, initBankID } from "routes/login/queries";
import type { LegacyProgressStatus } from "routes/login/queries/api";
import getSignedOutParams from "routes/login/utils/getSignedOutParams";
import { translateStatus } from "routes/login/utils/translateStatus";

import { QRCode } from "../components/BankID/QRCode";
import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { Container, LoginWrapper, Status } from "../components/styledComponents";

export const ScanQR: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const confirmation_token = new URLSearchParams(location.search).get("t");
  const { redirectUrl } = getSignedOutParams(location);
  const successUrl = redirectUrl || "/dashboard";
  const [status, setStatus] = useState<LegacyProgressStatus | null>(null);
  const [qrCode, setQRCode] = useState<string | null>(null);

  useEffect(() => {
    initBankID({
      confirmation_token: confirmation_token || undefined,
      flowType: "qr",
      successUrl,
      navigate,
      onInitDone: data => {
        setQRCode(data.qr_data);
      },
      onUpdate: data => {
        setStatus(data.legacy_progress_status);
        setQRCode(data.qr_data);
      },
    });

    return () => {
      abortBankID();
    };
  }, []);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.bankid.title")}>{t("login.bankid.scan_qr.header_text")}</LoginInfoContainer>
        <Container>
          <Instructions>
            <li>{t("login.bankid.scan_qr.instructions.step_1")}</li>
            <li>{t("login.bankid.scan_qr.instructions.step_2")}</li>
            <li>{t("login.bankid.scan_qr.instructions.step_3")}</li>
            <li>{t("login.bankid.scan_qr.instructions.step_4")}</li>
          </Instructions>
          {qrCode && <QRCode qrCode={qrCode} />}
          {status && <Status>{translateStatus({ flowType: "qr", status })}</Status>}
          <OpenAppLink to={{ pathname: "/login/bankid/open-desktop-app", search: location.search }}>
            {t("login.bankid.scan_qr.open_app_instead")}
          </OpenAppLink>
        </Container>
      </LoginContainer>
    </LoginWrapper>
  );
};

const Instructions = styled.ol`
  margin-top: 0;
  margin-bottom: 16px;
`;

const OpenAppLink = styled(Link)`
  margin-top: auto;
  margin-bottom: 16px;
`;
