import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";
import type { PremiumActivateState } from "api/models/PatientProfile";
import type ReferralProcessStatus from "api/types/PutReferralProcessStatus";
import type { Treatment } from "api/types/Treatment";

export interface GetPatientInfoResponse {
  address: string;
  adherence: number;
  appointment_to_schedule: string | null;
  avatars: {
    small: string;
    medium: string;
    large: string;
  };
  birthday: string;
  gender: string;
  city: string;
  co_payer: boolean;
  country: string;
  deleted_at: string | null;
  email: string;
  first_name: string;
  goal: string;
  iana_timezone: string;
  id: number;
  joined_at: string;
  last_activity_at: string | null;
  last_name: string;
  last_received_message_at: string | null;
  new_comments: boolean;
  new_messages: boolean;
  new_patient: boolean;
  orthopedist_viewed_at: string;
  payer_name: string;
  payer_category: string;
  phone_number: string;
  physio_viewed_at: string;
  preferred_name: string;
  referral_process_status: ReferralProcessStatus;
  prio: boolean;
  review: boolean;
  state: string;
  status: string;
  treatments: Treatment[];
  uid: string;
  week: number;
  zip_code: string;
  pt_on_demand_enabled: boolean;
  therapist_assignment_role: string | null;
  premium_activation_state: PremiumActivateState;
  first_day_activities_enabled: boolean;
}

// TODO: This should be removed and rewritten to reacy-query.
const getPatientInfo = (id: number): Promise<AxiosResponse<GetPatientInfoResponse>> =>
  AxiosInstance.get(`patients/${id}`);

export default getPatientInfo;
