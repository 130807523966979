import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props {
  backButton?: boolean;
  children: React.ReactNode;
  title: string | React.ReactNode;
}

export const Wrapper: React.VFC<Props> = ({ backButton, children, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Content>
      <Title>{title}</Title>
      {children}
      {backButton && <BackButton onClick={() => navigate(-1)}> {t("buttons.back")}</BackButton>}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.redesign.db90};
  font-size: 18px;
  text-align: center;

  svg {
    margin-left: 4px;
  }
`;

const BackButton = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.redesign.db60};
  font-size: 18px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.belowBreakpoint} {
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 8px;
  }
`;
