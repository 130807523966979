const typeSort: { [key: string]: number } = {
  diagnosis: 0,
  appointment: 1,
  chat: 2,
  protocol_weekly_w0: 3,
  protocol_weekly_w1: 4,
  protocol_weekly_w2: 5,
  protocol_weekly_w3: 6,
  protocol_weekly_w4: 7,
  protocol_weekly_w5: 8,
  protocol_weekly_w6: 9,
  protocol_weekly_w7: 10,
  protocol_weekly_w8: 11,
  protocol_weekly_w9: 12,
  protocol_weekly_w10: 13,
  protocol_weekly_w11: 14,
  protocol_weekly_w12: 15,
  protocol_discharge: 16,
  discharge: 17,
  protocol_monthly: 18,
  unlocking: 19,
  other: 20,
};

export default typeSort;
