import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { BookingIntervalResponse } from "api/schemas/BookingInterval";
import type { NewAppointment } from "api/schemas/NewAppointment";
import type { FormStates } from "routes/calendar/helpers/misc";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";

type Translations = {
  delete: string;
  confirmDeletion: string;
  success: string;
  successDeletion: string;
  successEdit: string;
};

interface Props {
  formState: FormStates;
  error: string | null;
  editMode: boolean;
  event?: BookingIntervalResponse | NewAppointment | null;
  deleteAppointment: () => void;
  text?: Partial<Translations>;
}

export const FormFooter: React.VFC<Props> = ({ formState, error, editMode, event, deleteAppointment, text = {} }) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletion, setDeletion] = useState(false);

  const translations: Translations = {
    delete: t("booking.buttons.cancel"),
    confirmDeletion: t("booking.form.confirm_deletion"),
    success: t("booking.success"),
    successEdit: t("booking.success_edit"),
    successDeletion: t("booking.success_deletion"),
    ...text,
  };

  useEffect(() => {
    setDeletion(false);
  }, [event]);

  if (formState === "saving" || formState === "success") {
    return (
      <FooterWrapper>
        {formState === "saving" && <Spinner style={{ width: 50, height: 50 }} />}
        {formState === "success" && (
          <Notification variant="success">
            {deletion && translations.successDeletion}
            {!deletion && (editMode ? translations.successEdit : translations.success)}
          </Notification>
        )}
      </FooterWrapper>
    );
  }

  return (
    <>
      {error && (
        <Notification variant="danger" style={{ margin: "20px 0 20px 0" }}>
          {error}
        </Notification>
      )}

      <FooterWrapper>
        {confirmDelete && (
          <Buttons>
            <Notification variant="warning" style={{ margin: "0 0 20px 0" }}>
              {translations.confirmDeletion}
            </Notification>
            <BaseButton
              text={t("buttons.confirm")}
              dataTestID="confirm-delete-appointment"
              variant="danger"
              onClick={() => {
                deleteAppointment();
                setDeletion(true);
              }}
              uppercase
            />
            <BaseButton
              text={t("buttons.cancel")}
              dataTestID="cancel-delete"
              variant="tertiaryBlue"
              onClick={() => setConfirmDelete(false)}
              style={{ marginTop: 16 }}
              uppercase
            />
          </Buttons>
        )}

        {!confirmDelete && (
          <Buttons>
            <BaseButton
              dataTestID="save-booking-button"
              text={editMode ? t("booking.buttons.update_booking") : t("booking.buttons.save_booking")}
              type="submit"
              uppercase
            />

            {editMode && event && (
              <BaseButton
                text={translations.delete}
                variant="danger"
                onClick={() => setConfirmDelete(true)}
                style={{ marginTop: 16 }}
                uppercase
              />
            )}
          </Buttons>
        )}
      </FooterWrapper>
    </>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`;
