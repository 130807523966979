import type React from "react";

import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { CheckIcon } from "assets";
import Card from "shared/atoms/Card";
import { Notification } from "shared/atoms/Notification";

const Icon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
`;

const HoverCard = styled(Card)<{ $notifications: number; $withError: boolean; border: boolean }>`
  ${props =>
    props.$notifications > 0 &&
    `&:hover {
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
      transition: box-shadow 400ms;
    }`}

  ${props =>
    props.$withError &&
    `
    height: 120px;
  `}
  flex-wrap: wrap;
  user-select: none;
`;

const StyledLink = styled(Link)<{ $notifications: number }>`
  ${props => props.$notifications === 0 && `pointer-events: none;`}
  text-decoration: none;
`;

const Label = styled.div`
  ${props => ({ ...props.theme.font.link1 })};
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 1;
  padding: 16px;
  white-space: nowrap;
`;

const Notifications = styled.div`
  ${props => ({ ...props.theme.font.header2 })};
  color: ${props => props.theme.colors.salmon};
`;

const StyledNotification = styled(Notification)`
  max-height: 30px;
  width: 100%;
  margin-bottom: 5px;
`;

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

interface Props {
  linkTo: string;
  children?: React.ReactNode;
  label: string;
  notifications?: number;
  error?: AxiosError | unknown;
  dataTestId?: string;
}

const EventCard: React.VFC<Props> = ({
  linkTo,
  notifications = 0,
  children,
  label,
  error = false,
  dataTestId = "event-card",
}) => {
  const { t } = useTranslation();
  return (
    <StyledLink to={notifications > 0 ? linkTo : " "} $notifications={notifications} data-testid={dataTestId}>
      <HoverCard border $notifications={notifications} $withError={!!error}>
        {children}
        <Label>{label}</Label>
        {!error && notifications > 0 ? <Notifications>{notifications}</Notifications> : null}
        {!error && notifications === 0 ? <Icon data-testid="check-icon" /> : null}
        {error && <Break />}
        {error && <StyledNotification variant="danger">{t("errors.generic")}</StyledNotification>}
      </HoverCard>
    </StyledLink>
  );
};

export default EventCard;
