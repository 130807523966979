import type React from "react";
import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePostPatient } from "api/hooks/useManagerBooking";
import { PatientPayloadSchema } from "api/schemas/ManagerBooking";
import type { PatientPayload, PatientResponse } from "api/schemas/ManagerBooking";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton } from "shared/atoms/Button";
import { MUITextInput } from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";
import { reportError } from "utils/errorReporting";

import { BOOKING_SCREENS } from "../AdminBookingForm";

interface Props {
  setPatient: (patient: PatientResponse) => void;
  setPersonalNumber: (personalNumber: string | null) => void;
}

export const CreatePatient: React.VFC<Props> = ({ setPatient, setPersonalNumber }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useProfileContext();
  const [error, setError] = useState<string | null>(null);

  const postCreatePatient = usePostPatient();

  invariant(profile);

  const form = useForm<PatientPayload>({
    resolver: zodResolver(PatientPayloadSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit = handleSubmit(formData => {
    setPersonalNumber(formData.personal_number);
    postCreatePatient.mutateAsync(
      { data: formData, managerId: profile?.id },
      {
        onSuccess: data => {
          setPatient(data);
          navigate(`../${BOOKING_SCREENS.SELECT_MODE}`);
        },
        onError: e => {
          reportError("Caught an error while creating a patient", e);
          setError(t("errors.generic"));
        },
      }
    );
  });

  return (
    <FormProvider {...form}>
      <Form onSubmit={onSubmit} noValidate>
        <MUITextInput
          label={t("bankid.personal_identity_number")}
          name="personal_number"
          error={errors?.personal_number && t("bankid.invalid_soc_sec_number")}
          required
        />
        <MUITextInput
          label={t("form.first_name")}
          name="first_name"
          error={errors?.first_name && t("errors.string.empty")}
          required
        />
        <MUITextInput
          label={t("form.last_name")}
          name="last_name"
          error={errors?.last_name && t("errors.string.empty")}
          required
        />
        <PrimaryButton style={{ alignSelf: "center" }}>{t("booking.buttons.continue")}</PrimaryButton>

        {error && (
          <Notification variant="danger" style={{ marginTop: "20px" }}>
            {error}
          </Notification>
        )}
      </Form>
    </FormProvider>
  );
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 378px;

  > div {
    margin-bottom: 16px;
  }

  > div:last-of-type {
    margin-bottom: 24px;
  }
`;
