import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";

export const OuterMargin = styled.div`
  margin: 0 auto;
  max-width: 932px;
  padding: 0 32px;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  padding-top: 10px;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

export const TopLeft = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  ${props => props.theme.belowBreakpoint} {
    margin-bottom: 16px;
    justify-content: space-between;
  }
`;

export const TopRight = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  ${props => props.theme.belowBreakpoint} {
    justify-content: space-between;
  }
`;

export const JointTag = styled.div`
  font-size: 14px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  display: inline-block;
  background-color: ${props => props.theme.colors.greys.light2};
`;

export const SmallPrimaryButton = styled(PrimaryButton)`
  max-height: 40px;
  min-width: 64px;
  font-size: 12px;
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
  max-height: 40px;
  min-width: 64px;
  font-size: 12px;
`;

export const RelativePosition = styled.div`
  position: relative;
`;
