import { format, parseISO } from "date-fns";
import i18n from "i18next";

import type { Activity, Medium, Message, Purpose } from "../../types";
import ChatBubble from "../ChatBubble";
import CommentCard from "../CommentCard";
import InfoPreference from "../InfoPreference";
import InfoSchedule from "../InfoSchedule";
import SystemMessage from "../SystemMessage";
import TherapistChange from "../TherapistChange";

const renderCommentCard = (activity?: Activity) => {
  if (!activity) {
    return null;
  }
  switch (activity?.activity_name) {
    case "followup_health_report":
      return (
        <CommentCard
          date={activity?.completed_on}
          comment={activity?.comment}
          activity={{ type: "report", title: "messages.comment_types.follow_up" }}
        />
      );
    case "w1_health_report":
      return (
        <CommentCard
          date={activity?.completed_on}
          comment={activity?.comment}
          activity={{ type: "report", title: "messages.comment_types.health" }}
        />
      );
    case "weekly_health_report":
      return (
        <CommentCard
          date={activity?.completed_on}
          comment={activity?.comment}
          activity={{ type: "report", title: "messages.comment_types.weekly_report" }}
        />
      );
    case "exercise":
      return (
        <CommentCard
          date={activity?.completed_on}
          comment={activity?.comment}
          activity={{
            type: "exercise",
            title: i18n.t(`activities.${activity.activity_type}`),
            levels: (activity?.exercise?.levels?.length || 2) - 1,
            current: activity?.exercise?.level,
          }}
        />
      );
    default:
      return null;
  }
};

const renderMessages = (
  messages: Array<Message>,
  userId: number,
  selectedPatient: number,
  lastReadByPatientId: number
): JSX.Element => {
  return (
    <>
      {messages.map(message => {
        // TODO: Add new unified translations, release and then remove the old ones.
        let title = i18n.t(`messages.system_messages.${message.type}.title`);
        let body = i18n.t(`messages.system_messages.${message.type}.body`);

        switch (message.type) {
          case "appointment":
            return (
              <InfoSchedule
                key={message.id}
                start={message.included?.appointment?.start_time || ""}
                end={message.included?.appointment?.end_time || ""}
                purpose={message.included?.appointment?.purpose.toUpperCase() as Purpose}
                type={message.included?.appointment?.medium.toUpperCase() as Medium}
                phoneNumber={message.included?.appointment?.patient_number || ""}
                calendarUrl={message.included?.appointment?.ics_url}
                appointmentState={message.included?.appointment?.state_v2}
              />
            );
          case "insurance_change":
            title = i18n.t("messages.insurance_change.header");
            body = message.body ? message.body : i18n.t("messages.insurance_change.placeholder");
            return <SystemMessage key={message.id} title={title} body={body} />;
          case "suggested_kickoff_appointment":
            return <InfoPreference key={message.id} message={message.body} />;
          case "patient_end_treatment":
            title = i18n.t("messages.end_treatment");
            return <SystemMessage key={message.id} title={title} />;
          case "text":
            return (
              <ChatBubble
                id={message.id}
                message={message.body || ""}
                date={message.created_at}
                key={message.id}
                sender={message.from.id === userId}
                read={message.id === lastReadByPatientId ? message.read_at : null}
                selectedPatient={selectedPatient}
              />
            );
          case "activity":
            return (
              <ChatBubble
                id={message.id}
                message={message.body || ""}
                date={message.created_at}
                key={message.id}
                sender={message.from.id === userId}
                read={message.id === lastReadByPatientId ? message.read_at : null}
                selectedPatient={selectedPatient}
              >
                {message.type === "activity" && renderCommentCard(message.included?.activity)}
              </ChatBubble>
            );
          case "patient_assignment_change":
            if (message.message_data) {
              return (
                <TherapistChange
                  key={message.id}
                  substituteTherapist={message.message_data.assignment_role === "substitute_therapist"}
                  change={message.message_data.change}
                  therapistName={`${message.message_data.previously_active_therapist?.first_name} ${message.message_data.previously_active_therapist?.last_name}`}
                  assignmentEndDate={message.message_data.assignment_end_date}
                />
              );
            }
            return null;
          case "switched_treatment":
            title = i18n.t(`messages.system_messages.${message.type}.title`, {
              date: format(parseISO(message.created_at), "P"),
              previous_lateral_location: i18n.t(`patients.lateral_location.${message.previous_lateral_location}`),
              previous_location: i18n.t(`patients.pain_location.${message.previous_location}.0`),
              new_lateral_location: i18n.t(`patients.lateral_location.${message.new_lateral_location}`),
              new_location: i18n.t(`patients.pain_location.${message.new_location}.0`),
            });
            body = i18n.t(`messages.system_messages.${message.type}.body`);
            return <SystemMessage key={message.id} title={title} body={body} centerBody />;
          default:
            return (
              <SystemMessage
                key={message.id}
                title={title}
                // Only pass body if a translation exists
                body={body !== `messages.system_messages.${message.type}.body` ? body : undefined}
              />
            );
        }
      })}
    </>
  );
};

export default renderMessages;
