import type React from "react";

import styled from "styled-components";

const size = "25px";

const Wrapper = styled.div`
  height: ${size};
  width: ${size};
`;

const Plate = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Meatball = styled.div`
  border-radius: ${props => props.theme.borderRadius.circular};
  width: 4px;
  height: 4px;
  background: ${props => props.theme.colors.primary.base};
  margin-right: 3px;
  ${Plate}:hover & {
    background: ${props => props.theme.colors.greys.slate};
  }
`;

const Line = styled.div`
  position: relative;
  width: 24px;
  height: 2px;
  background-color: ${props => props.theme.colors.primary.base};
  :first-of-type {
    transform: rotate(135deg);
    top: calc(${size} / 2);
  }
  :last-of-type {
    top: calc(${size} / 2 - 2px);
    transform: rotate(225deg);
  }
  ${Wrapper}:hover & {
    background: ${props => props.theme.colors.greys.slate};
  }
`;

interface Props {
  readonly open: boolean;
  readonly toggleMenu: () => void;
}

const MeatballMenu: React.VFC<Props> = ({ open, toggleMenu }) =>
  open ? (
    <Wrapper data-testid="meatball-menu" role="button" onClick={toggleMenu}>
      <Line />
      <Line />
    </Wrapper>
  ) : (
    <Plate data-testid="meatball-menu" role="button" onClick={toggleMenu}>
      <Meatball />
      <Meatball />
      <Meatball />
    </Plate>
  );

export default MeatballMenu;
