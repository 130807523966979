import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { TreatmentAdherence, TreatmentAdherenceArgs } from "api/schemas/patients/TreatmentAdherence";
import useApiClient from "api/useApiClient";

type QueryKey = ["getTreatmentAdherence", number, number | undefined];
export const getTreatmentAdherenceQueryKey = (args: TreatmentAdherenceArgs): QueryKey => [
  "getTreatmentAdherence",
  args.patientID,
  args.treatmentID,
];

const useGetTreatmentAdherence = (
  args: TreatmentAdherenceArgs,
  options?: UseQueryOptions<TreatmentAdherence, Error, TreatmentAdherence, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getTreatmentAdherenceQueryKey(args), () => client.getTreatmentAdherence(args), options);
};

export default useGetTreatmentAdherence;
