import styled from "styled-components";

export const OnboardingHeader = styled.h1`
  ${props => props.theme.font.header1};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.primary.base};
  margin: ${props => props.theme.spacing.S_50} 0 0;
`;

export const OnboardingMessage = styled.div`
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.greys.dark};
  margin-top: ${props => props.theme.spacing.S_30};
`;

export const OnboardingButtons = styled.div`
  text-align: center;
  margin-top: ${props => props.theme.spacing.S_50};

  & :not(:first-child) {
    margin-left: ${props => props.theme.spacing.S_20};
  }

  ${props => props.theme.belowMobileBreakpoint} {
    & :not(:first-child) {
      margin: 16px 0;
    }
  }
`;

interface UploadErrorProps {
  $margin?: string;
  $visible?: boolean;
}

export const UploadError = styled.div<UploadErrorProps>`
  ${props => props.theme.font.caption};
  font-weight: ${props => props.theme.fontWeight.regular};
  height: 20px;
  min-height: 20px;
  margin-top: ${props => (props.$margin ? props.$margin : props.theme.spacing.S_20)};
  color: ${props => props.theme.colors.primary.error};
  transition: opacity 200ms;
  opacity: ${props => (props.$visible ? "1" : "0")};
`;
