import { useMutation } from "react-query";

import type { CareEvent } from "api/models/CareEvent";
import type { PostCareEventSuggestedArgs } from "api/schemas/CareEventBilling";
import useApiClient from "api/useApiClient";

export const usePostCareEventSuggested = () => {
  const client = useApiClient();

  return useMutation<CareEvent, Error, PostCareEventSuggestedArgs>(args => client.postCareEventSuggested(args));
};
