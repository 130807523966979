import {
  add,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  format,
  formatISO,
  formatRelative,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  parse,
  parseISO,
  set,
  startOfToday,
  sub,
} from "date-fns";

import useLocale from "./useLocale";

// Date format patterns
const ISO_DATE = "yyyy-MM-dd";
const LONG_LOCALIZED_DATE_PP = "PP";
const LONG_LOCALIZED_DATE_TIME_PPpp = "PPpp";
const WRITTEN_LOCALIZED_SHORT_MONTH_YEAR_MMMyyyy = "MMM yyyy";
const WRITTEN_LOCALIZED_MONTH_YEAR_MMMMyyyy = "MMMM yyyy";
const WRITTEN_LOCALIZED_DATE_MONTH_YEAR_dMMMyyyy = "d MMM yyyy";

/** @url https://date-fns.org/v2.28.0/docs/format */
const formats = Object.freeze({
  /** @example "2020-09-04" */
  ISO_DATE,
  /** @example "Apr 29, 1453" */
  LONG_LOCALIZED_DATE_PP,
  /** @example "Apr 29, 1453, 12:00:00 AM" */
  LONG_LOCALIZED_DATE_TIME_PPpp,
  /** @example "Jan. 2020" */
  WRITTEN_LOCALIZED_SHORT_MONTH_YEAR_MMMyyyy,
  /** @example "January 2020" */
  WRITTEN_LOCALIZED_MONTH_YEAR_MMMMyyyy,
  /** @example "18 jan 2020" */
  WRITTEN_LOCALIZED_DATE_MONTH_YEAR_dMMMyyyy,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useLocalizedDate() {
  const locale = useLocale();

  const customFormat = (date: Date | number, formatString: string): string => {
    return format(date, formatString, {
      locale,
    });
  };

  const customFormatRelative = (date: Date | number): string => {
    return formatRelative(date, new Date(), {
      locale,
    });
  };

  const api = {
    add,
    formatISO,
    getDaysInMonth,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isToday,
    isTomorrow,
    isValid,
    isYesterday,
    parse,
    parseISO,
    set,
    startOfToday,
    sub,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    differenceInHours,
    areIntervalsOverlapping,
    formatRelative: customFormatRelative,
    format: customFormat,
    formats,
  };

  return api;
}
