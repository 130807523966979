import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CallFailedIcon } from "assets/videocall";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Popup from "shared/atoms/Popup";

interface Props {
  readonly onMessage: () => void;
  readonly onClose: () => void;
  readonly name: string;
  readonly open: boolean;
}

const DisconnectModal: React.VFC<Props> = ({ onMessage, onClose, name, open }) => {
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Popup blankModal>
      <ModalBackground>
        <IconContainer>
          <IconFailedCall />
        </IconContainer>
        <TextContainer>
          <Text>
            <b>{t("drop_in_calls.modal.cancel_header")}</b>
            <br />
            <br />
            {t("drop_in_calls.modal.cancel_body", { name })}
          </Text>
        </TextContainer>

        <ButtonWrapper>
          <PrimaryButton data-testid="call-again-button" onClick={onMessage}>
            {t("drop_in_calls.modal.message_patient")}
          </PrimaryButton>
          <TextButton data-testid="exit-call-button" onClick={onClose}>
            {t("buttons.exit")}
          </TextButton>
        </ButtonWrapper>
      </ModalBackground>
    </Popup>
  );
};

export default DisconnectModal;

const ButtonWrapper = styled.div`
  width: 175px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
`;

const IconContainer = styled.div`
  margin: 0 auto;
  position: relative;
  top: 33px;
`;

const IconFailedCall = styled(CallFailedIcon)`
  width: 44pxpx;
  height: 44px;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.p`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.primary.base};
  font-size: 18px;
  margin: 0;
  margin: ${props => props.theme.spacing.S_60} 0 ${props => props.theme.spacing.S_40} 0;
  text-align: center;
`;

const ModalBackground = styled.div`
  max-width: 500px;
  background: linear-gradient(to bottom, ${props => props.theme.colors.pastels.red} 64px, #fff 64px);
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_20}
    ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_20};
  display: flex;
  flex-direction: column;
`;
