import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { EarningsUsTherapist, EarningsUsTherapistArgs } from "api/schemas/EarningsUsTherapist";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getEarningsUsTherapist";
type QueryKey = [typeof queryKeyPrefix, number, string];
export const getEarningsUsTherapistQueryKey = (args: EarningsUsTherapistArgs): QueryKey => [
  queryKeyPrefix,
  args.therapist_id,
  args.start_date,
];

const useGetEarningsUsTherapist = (
  args: EarningsUsTherapistArgs,
  options?: UseQueryOptions<EarningsUsTherapist, Error, EarningsUsTherapist, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getEarningsUsTherapistQueryKey(args), () => client.getEarningsUsTherapist(args), options);
};

export default useGetEarningsUsTherapist;
