import { useMutation } from "react-query";

import type { HcpServiceManager } from "api/schemas/HcpService";
import type { PutServiceArgs } from "api/types/PostService";
import useApiClient from "api/useApiClient";

export const usePutService = () => {
  const client = useApiClient();

  return useMutation<HcpServiceManager, Error, PutServiceArgs>(args => client.putService(args));
};
