const getQuickTextValues = (field: string, isGB: boolean): string[] => {
  if (isGB) {
    switch (field) {
      case "functional_tests":
        return [
          "pour_from_pitcher",
          "behind_the_head_reach",
          "behind_the_back_reach",
          "difficulty_with_walking",
          "difficulty_with_floor_to_standing_transfers",
          "difficulty_with_squatting",
          "difficulty_with_stair_navigation",
          "without_remark",
          "not_applicable",
        ];
      case "goal_free_text":
        return ["improve_walking", "improve_pain", "improve_knowledge", "improve_strength", "able_to"];
      case "inspection":
        return ["swelling", "redness", "bruising", "deformity", "trophic_changes"];
      case "joint_functionality":
        return [
          "normal_range_of_motion",
          "reduced_range_of_motion",
          "giving_way",
          "locking",
          "crepitation",
          "stiffness",
        ];
      case "medication":
        return [
          "paracetamol",
          "co-codamol",
          "naproxen",
          "ibuprofen",
          "levothyroxine",
          "statins",
          "omeprazole",
          "metformin",
          "amitriptyline",
          "ramipril",
          "amlodipine",
        ];
      case "neurology":
        return [
          "altered_sensation_areas",
          "reduced_muscle_power_areas",
          "gait_disturbances",
          "difficulty_with_coordination",
          "altered_proprioception",
        ];
      case "palpation":
        return ["tender_to_touch", "painful_to_touch", "warm_to_touch"];
      case "past_medical_history":
        return [
          "THREADS",
          "history_of_cancer",
          "smoker",
          "non-smoker",
          "diabetes_type",
          "hypertension",
          "hypercholesterolemia",
          "hypoactive_thyroid",
          "previous_surgeries_include",
        ];
      case "red_flags":
        return [
          "bladder_retention",
          "bowel_incontinence",
          "saddle_anaesthesia",
          "sexual_dysfunction",
          "single_or_bilateral_lower_limb_pain",
          "pins_and_needles_or_numbness",
          "gait_disturbance",
          "dizziness",
          "diplopia",
          "drop_attacks",
          "dysarthria",
          "dysphagia",
          "nausea",
          "numbness",
          "nystagmus",
          "unremitting_pain",
          "night_sweats",
          "thoracic_pain",
        ];
      case "social":
        return [
          "retired",
          "semi-retired",
          "works_as_a",
          "hobbies_include",
          "mobilises_independently",
          "mobilises_with_aid",
        ];
      default:
        return ["without_remark", "not_applicable"];
    }
  }

  switch (field) {
    case "inspection":
      return [
        "limp",
        "swelling",
        "bone_spurs",
        "external_misalignment",
        "leg_length_discrepancy",
        "without_remark",
        "not_applicable",
      ];
    case "palpation":
      return ["tenderness", "warmth", "without_remark", "not_applicable"];
    case "neurology":
      return [
        "impaired_sensation",
        "positive_neural_tension_test",
        "positive_standing_slr",
        "positive_supine_slr",
        "diminished_strength_toe_walking",
        "diminished_strength_heel_walking",
        "without_remark",
        "not_applicable",
      ];
    case "joint_functionality":
      return [
        "instability",
        "limited_range_of_motion",
        "crepitations",
        "stiffness",
        "without_remark",
        "not_applicable",
      ];
    case "functional_tests":
      return [
        "pour_from_pitcher",
        "behind_the_head_reach",
        "behind_the_back_reach",
        "difficulty_with_walking",
        "difficulty_with_floor_to_standing_transfers",
        "difficulty_with_squatting",
        "difficulty_with_stair_navigation",
        "without_remark",
        "not_applicable",
      ];
    case "previous_surveys_how":
      return ["x_ray", "physical_visit", "no_visit"];
    default:
      return ["without_remark", "not_applicable"];
  }
};

export { getQuickTextValues };
