import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";
import Search from "shared/molecules/Search";
import { TableCell } from "shared/molecules/Table";

const Title = styled.span`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 1;
  margin: auto;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing.S_20} 0;
  ${props => props.theme.belowBreakpoint} {
    display: block;
    text-align: center;
  }
`;
const StyledSearch = styled(Search)`
  flex-grow: 2;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;
const DurationText = styled.span`
  flex-grow: 1;
  line-height: 1px;
`;
const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ViewLink = styled.a`
  display: inline-flex;
  flex-grow: 2;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_40};
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  min-width: 86px;
  max-width: 86px;
  height: 35px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  ${props => props.theme.font.button_text};
  background-color: ${props => props.theme.colors.redesign.b100};
  color: ${props => props.theme.colors.white};
  ${props => props.theme.font.button_text};
  ${props => props.theme.aboveBreakpoint} {
    margin: auto 0 auto auto;
  }
  text-decoration: none;
`;
const StyledTableCell = styled(TableCell)`
  font-weight: 400;
  color: ${props => props.theme.colors.greys.slate};
`;

const Dot = styled.div<{ $deleted: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${props => (props.$deleted ? props.theme.colors.salmon : props.theme.colors.green)};
  margin-right: 8px;
  border-radius: 50%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
  min-width: 100px;
  padding: 0 24px;
  height: 48px;
`;

const Container = styled.div`
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    justify-content: center;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  & > :not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-left-width: 1px;
    border-top-left-radius: 0;
  }
  & > :not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-right-width: 1px;
    border-top-right-radius: 0;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;

  ${props => props.theme.belowBreakpoint} {
    margin-top: 20px;
  }
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: ${props => props.theme.colors.greys.slate};
`;

type ButtonProps = {
  $size?: "medium" | "large";
};

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  color: ${props => props.theme.colors.greys.dark};
  cursor: pointer;
  /* Medium is default */
  padding: 8px 14px;
  ${props => props.$size === "large" && "padding: 16px;"}
  margin: 0;
  &:active {
    background-color: ${props => props.theme.colors.greys.light5};
    border-color: ${props => props.theme.colors.greys.pinkish};
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.greys.light4};
    border-color: ${props => props.theme.colors.greys.pinkish};
  }
`;

export {
  Button,
  ButtonGroup,
  Title,
  HeaderContainer,
  StyledSearch,
  DurationText,
  CellContainer,
  ViewLink,
  StyledTableCell,
  Dot,
  StyledPrimaryButton,
  Container,
  StyledButtonGroup,
  Counter,
};
