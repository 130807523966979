import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AnatomyMan, AnatomyWoman } from "assets";
import { CurrentPatientContext } from "utils/contexts";

import PainMarkers from "../PainMarkers";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
  }
`;

const Image = styled.div`
  width: 185px;
  position: relative;
  margin: auto 0;
  ${props => props.theme.belowMobileBreakpoint} {
    margin: 0 auto;
  }
`;

const Woman = styled(AnatomyWoman)`
  margin: 0 auto;
  width: 185px;
`;

const Man = styled(AnatomyMan)`
  margin: 0 auto;
  width: 185px;
`;

const Info = styled.div`
  flex: 1 3;
  padding-left: ${props => props.theme.spacing.S_20};
`;

const TextTitle = styled.div`
  margin-top: ${props => props.theme.spacing.S_30};
  ${props => props.theme.font.caption};
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.light};
`;

const Text = styled.div`
  ${props => props.theme.font.table.header};
  line-height: 20px;
  color: ${props => props.theme.colors.primary.base};
`;
interface PainLocation {
  lateral_location: "left" | "right" | "undefined";
  location:
    | "undefined"
    | "neck"
    | "spine"
    | "lower_back"
    | "upper_back"
    | "knee"
    | "hip"
    | "shoulder"
    | "foot"
    | "elbow"
    | "hand"
    | "jaw";
}
const Overview: React.VFC = () => {
  const { patient } = useContext(CurrentPatientContext);
  const { t } = useTranslation();
  const painfulJoints = patient?.pain_locations
    ?.map((location: PainLocation) => (
      <Text key={location.lateral_location + location.location}>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {`${t(`patients.pain_location.${location.location}.0`)}${
          location.lateral_location === "undefined"
            ? ""
            : `: ${t(`patients.lateral_location.${location.lateral_location}`).toLowerCase()}`
        }`}
      </Text>
    ))
    .reverse();

  const gender = patient?.gender;

  const painMarkers = patient?.pain_locations?.map((location: PainLocation) => {
    return (
      <PainMarkers
        key={location.lateral_location + location.location}
        side={location.lateral_location}
        location={location.location}
      />
    );
  });

  const mostPainful = () => {
    const location = patient?.most_painful_location?.location;
    const lateral = patient?.most_painful_location?.lateral_location;
    if (location) {
      if (lateral === "undefined") {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t(`patients.pain_location.${location}.0`);
      }

      return t("patients.pain_construct", {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lateral: t(`patients.lateral_location.${lateral}`),
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        location: t(`patients.pain_location.${location}.0`),
      });
    }
    return null;
  };

  return (
    <Container data-testid="overview">
      <Image>
        {gender === "male" ? <Man data-testid="image-male" /> : <Woman data-testid="image-female" />}
        {painMarkers}
      </Image>
      <Info>
        {patient ? (
          <>
            <TextTitle>{t("patients.most_painful")}</TextTitle>
            <Text>{mostPainful()}</Text>
            <TextTitle>{t("patients.joint_pain")}</TextTitle>
            {painfulJoints}
          </>
        ) : (
          <div>{t("errors.generic")}</div>
        )}
      </Info>
    </Container>
  );
};

export default Overview;
