import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getServiceTemplateQueryKey = (userId: number) => ["getServiceTemplate", userId] as const;

const useGetServiceTemplate = (userId: number) => {
  const client = useApiClient();

  return useQuery(getServiceTemplateQueryKey(userId), () => client.getServiceTemplate(userId));
};

export default useGetServiceTemplate;
