import type React from "react";

import styled from "styled-components";

import { BroadcastIcon } from "assets";
import { Container, DateTime, Message } from "routes/messages/styles/bubble";
import RelativeDatetime from "shared/atoms/RelativeDatetime";

export interface Props {
  children?: React.ReactNode;
  message: string;
  date: string;
}

const BroadcastBubble: React.VFC<Props> = ({ message, date, children }) => {
  return (
    <Container>
      <Bubble data-testid="broadcast-bubble">
        <Wrapper>
          {children}
          <Message $sender>{message}</Message>
          <DateTime $sender>
            <RelativeDatetime capitalize dateString={date} />
          </DateTime>
          <IconWrapper>
            <BroadcastIcon />
          </IconWrapper>
        </Wrapper>
      </Bubble>
    </Container>
  );
};

export default BroadcastBubble;

const Bubble = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 115px;

  ${({ theme }) => theme.belowBreakpoint} {
    padding-left: 50px;
    padding-right: ${({ theme }) => theme.spacing.S_15};
    margin-bottom: ${({ theme }) => theme.spacing.S_20};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.S_25};
  padding-right: ${({ theme }) => theme.spacing.S_50};
  border-radius: 24px 24px 8px 24px;
  background-color: ${({ theme: { colors } }) => colors.secondary.light};
  color: ${({ theme: { colors } }) => colors.white};

  ${({ theme }) => theme.belowBreakpoint} {
    padding: ${({ theme }) => theme.spacing.S_15};
    padding-right: ${({ theme }) => theme.spacing.S_40};
    border-radius: 10px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme: { colors } }) => colors.secondary.light};
    }
  }

  ${({ theme }) => theme.belowBreakpoint} {
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
