import type React from "react";

import { useTranslation } from "react-i18next";

import type { Question } from "../../helpers/helpers";
import { ContentRow, MarginWrapper } from "../helpers";
import ReportQuestionWrapper from "../ReportQuestionWrapper";

const ReportQuestionHeader: React.VFC<Question> = ({ header, header_description }) => {
  const { t } = useTranslation();
  return (
    <>
      {header && (
        <ContentRow data-testid="hr-row">
          <ReportQuestionWrapper>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {t(`patients.health_reports.questions_content.${header}`)}
            {header_description && (
              <>
                {/* FIXME: type translation */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <MarginWrapper>{`${t("patients.health_reports.low")}: ${t(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `patients.health_reports.questions_content.${header_description[0]}`
                )}`}</MarginWrapper>
                {/* FIXME: type translation */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <MarginWrapper>{`${t("patients.health_reports.high")}: ${t(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `patients.health_reports.questions_content.${header_description[1]}`
                )}`}</MarginWrapper>
              </>
            )}
          </ReportQuestionWrapper>
        </ContentRow>
      )}
    </>
  );
};

export default ReportQuestionHeader;
