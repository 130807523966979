import type React from "react";

import type { RemoteTrack, RemoteTrackPublication } from "twilio-video";

import AudioTrack from "../video/AudioTrack";
import useTrack from "../video/hooks/useTrack";
import VideoTrack from "../video/VideoTrack";

interface Props {
  readonly publication: RemoteTrackPublication;
}

const RemotePublication: React.VFC<Props> = ({ publication }) => {
  const track = useTrack(publication) as RemoteTrack;

  if (!track) return null;

  if (track.kind === "video") {
    return <VideoTrack track={track} isMain={false} />;
  }

  if (track.kind === "audio") {
    return track.isEnabled ? <AudioTrack track={track} /> : null;
  }

  return null;
};

export default RemotePublication;
