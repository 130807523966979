import type React from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetPatientCareEventsQuery } from "api/hooks/useGetPatientCareEventsQuery";
import { useMedicalReferrals } from "api/hooks/useMedicalReferrals";
import type { CareEvent } from "api/models/CareEvent";
import type { MedicalReferral } from "api/models/MedicalReferral";
import { useProfileContext } from "contexts/ProfileContext";
import { Accordion } from "shared/atoms/Accordion";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import type { Market, MedicalNoteType } from "types";
import type { DateString } from "types/DateString";
import useLocalizedDate from "utils/date";
import { getMedicalNoteConfig } from "utils/medicalNotes";

import { isCareEvent, isReferral } from "../../utils/typeCheckers";
import RecordDynamicNote from "../RecordDynamicNote";
import RecordNote from "../RecordNote";
import RecordReferral from "../RecordReferral";

type MixedRecord = MedicalReferral | CareEvent;

const RecordNoteAndReferralList: React.VFC = () => {
  const { t } = useTranslation();
  const { parseISO } = useLocalizedDate();
  const { profile } = useProfileContext();
  const patientId = Number(useParams().patientId);

  const compareNotes = (note1: MixedRecord, note2: MixedRecord) => {
    const date1 = (note1 as CareEvent).start_time || (note1 as MedicalReferral).date_of_physician_signature;
    const date2 = (note2 as CareEvent).start_time || (note2 as MedicalReferral).date_of_physician_signature;
    if (date2 === null) return 1;
    if (date1 === null) return -1;
    const parsedDate1 = parseISO(date1);
    const parsedDate2 = parseISO(date2);
    if (parsedDate1 === parsedDate2) return 0;
    return parsedDate1 < parsedDate2 ? 1 : -1;
  };

  const { data: careEventData = [], ...careEvents } = useGetPatientCareEventsQuery({
    patientId,
    signed: true,
  });
  const { data: referralData = [], ...referrals } = useMedicalReferrals(patientId);
  const allRecords = [...careEventData, ...referralData];
  const isSuccess = careEvents.isSuccess || referrals.isSuccess;

  return (
    <>
      {isSuccess && allRecords?.length === 0 && <span>{t("patients.medical_records.no_signed_medical_records")}</span>}
      {isSuccess && allRecords && (
        <Accordion>
          {allRecords.sort(compareNotes).map(record => {
            if (isReferral(record)) return <RecordReferral key={record.id} record={record} />;

            if (isCareEvent(record)) {
              if (record.medical_record_note === null)
                return (
                  <RecordDynamicNote
                    key={record.id}
                    record={record}
                    config={getMedicalNoteConfig(
                      profile?.market as Market,
                      record.label as MedicalNoteType,
                      record.signed_at as DateString
                    )}
                  />
                );
              return <RecordNote key={record.id} record={record} />;
            }
            return "";
          })}
        </Accordion>
      )}
      {(careEvents.isLoading || referrals.isLoading) && <Spinner dataTestId="loading" small />}
      {careEvents.error && referrals.error && <Notification variant="danger">{t("errors.generic")}</Notification>}
    </>
  );
};

export default RecordNoteAndReferralList;
