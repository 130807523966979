import type React from "react";
import { useCallback, useContext, useEffect, useState } from "react";

import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";

import { useGetMedicalReferrals } from "api/hooks/useGetMedicalReferrals";
import { useProfileContext } from "contexts/ProfileContext";
import usePrimaryInsuranceInfo from "routes/patients/utils/hooks/usePrimaryInsuranceInfo";
import PatientStatus, { getPatientRelevantStatus } from "shared/molecules/PatientStatus";
import { MarketTypes } from "types";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { isMarket } from "utils/market";

import type { PaymentMethod } from "../../components/PaymentMethodTag";
import { PaymentMethodTag } from "../../components/PaymentMethodTag";
import {
  CareEventsCounterWrapper,
  Data,
  DataWrapper,
  DotsWrapper,
  DownLoadIcon,
  EmptyDot,
  FullDot,
  InfoGrid,
  Label,
  RegionData,
  Spacer,
  isPatientDischarged,
} from "../../helpers";

const InfoList: React.FC = () => {
  const { t } = useTranslation();
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const [referralUrl, setReferralUrl] = useState("");
  const getMedicalReferralsQuery = useGetMedicalReferrals(patient?.id, { enabled: Boolean(patient) });
  const insuranceCoverage = usePrimaryInsuranceInfo(patient);

  const { format, parseISO } = useLocalizedDate();
  const formatDate = (date: string) => {
    // date can be null
    return date ? format(parseISO(date), "PP") : t("common.unknown");
  };

  useEffect(() => {
    if (Array.isArray(getMedicalReferralsQuery.data) && getMedicalReferralsQuery.data.length > 0) {
      const patientReferral = getMedicalReferralsQuery.data[0];
      if (patientReferral.url) {
        setReferralUrl(patientReferral.url);
      }
    }
  }, [getMedicalReferralsQuery]);

  const lastMedicalReferral = () => {
    if (!patient.last_medical_referral_signature_at) return null;

    return (
      <>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Label>{t("patients.header_info.last_medical_referral_signature")}</Label>
        <Data>
          {new Date(patient.last_medical_referral_signature_at).toLocaleDateString(profile?.locale, {
            timeZone: "UTC",
          })}
          {referralUrl.length > 0 && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <a href={referralUrl} target="_blank" rel="noreferrer">
              <DownLoadIcon />
            </a>
          )}
        </Data>
      </>
    );
  };

  const insuranceInfo = () => {
    const { insuranceData } = patient;

    const getCoverage = () => {
      const hasInsurance = insuranceData?.insurance_found;
      const hasActiveSubscription = patient.subscriptionData?.status === "active";

      if (hasInsurance && !hasActiveSubscription) {
        return t("patients.header_info.coverage_options.insurance");
      }

      if (hasInsurance && hasActiveSubscription) {
        return t("patients.header_info.coverage_options.out_of_pocket");
      }

      if (!hasInsurance && !hasActiveSubscription) {
        return t("patients.header_info.coverage_options.cash_pay");
      }

      return "";
    };

    if (getCoverage()) {
      return (
        <>
          <Label>{t("patients.header_info.coverage")}</Label>
          <DataWrapper $wrap>
            <Spacer $right={5}>
              <Data>{getCoverage()}</Data>
            </Spacer>
          </DataWrapper>
        </>
      );
    }

    return null;
  };

  const healthPlanInfo = () => {
    if (!insuranceCoverage) {
      return null;
    }

    return (
      <>
        <Label>{t("patients.header_info.health_plan")}</Label>
        <Data>{insuranceCoverage}</Data>
      </>
    );
  };

  const subscriptionInfo = () => {
    if (!patient.subscriptionData) {
      return <></>;
    }
    const { status, started_on, expires_on, subscription_plan, is_free_trial } = patient.subscriptionData || {};
    const length = started_on && expires_on && formatDistance(new Date(started_on), new Date(expires_on));

    const cost =
      subscription_plan.cost_cents !== undefined &&
      subscription_plan.currency &&
      `${parseInt(subscription_plan.cost_cents, 10) / 100} ${subscription_plan.currency}`;

    return (
      <>
        <Label>{t("patients.header_info.subscription")}</Label>
        <Data>
          {status} {is_free_trial && `(${t("patients.header_info.subscription_free_trial")})`},{" "}
          {length && `${length}, `} {cost}
        </Data>
      </>
    );
  };

  const upcomingSubscriptionInfo = () => {
    if (!patient?.subscriptionData?.upcoming_subscription_plan) {
      return <></>;
    }

    const { expires_on, upcoming_subscription_plan } = patient.subscriptionData || {};
    const upcomingCost =
      upcoming_subscription_plan.cost_cents !== undefined &&
      upcoming_subscription_plan.currency &&
      `${parseInt(upcoming_subscription_plan.cost_cents, 10) / 100} ${upcoming_subscription_plan.currency}`;

    return (
      <>
        <Label>{t("patients.header_info.upcoming_subscription")}</Label>
        <Data>
          {upcomingCost} {expires_on && `, ${expires_on}`}
        </Data>
      </>
    );
  };

  const careEventsCounter = () => {
    // Hide care event counter if patient is discharged
    if (isPatientDischarged(patient)) return null;

    const careEventDot = (i: number) => {
      if (i < patient.billable_care_events.count) {
        return <FullDot key={i} />;
      }
      return <EmptyDot key={i} />;
    };

    if (patient.billable_care_events) {
      return (
        <>
          <Label>{t("patients.header_info.care_events")}</Label>
          <Data>
            <CareEventsCounterWrapper>
              {patient.billable_care_events.max <= 10 && (
                <DotsWrapper>
                  {Array.from(Array(patient.billable_care_events.max).keys()).map(i => careEventDot(i))}
                </DotsWrapper>
              )}
              <span style={{ fontWeight: 500 }}>{patient.billable_care_events.count}</span>
              <span style={{ margin: "0 4px" }}>/</span>
              <span>{patient.billable_care_events.max}</span>
            </CareEventsCounterWrapper>
          </Data>
        </>
      );
    }
    return null;
  };

  const careInfo = () => {
    if (isMarket(MarketTypes.SE)) {
      const getRegionInfo = () => {
        if (patient.registered_in_region === undefined) {
          return "";
        }
        const withinOrOutsideRegion = patient.registered_in_region
          ? t("patients.header_info.within_region")
          : t("patients.header_info.outside_region");
        return `${withinOrOutsideRegion} ${t("common.by")} `;
      };
      const paymentMethod = patient?.payment_method as PaymentMethod;
      return (
        <>
          <Label>{t("patients.header_info.care")}</Label>
          <RegionData>
            <div style={{ marginRight: "4px" }}>{`${getRegionInfo()}${patient.payer_name}`}</div>
            {paymentMethod && <PaymentMethodTag paymentMethod={paymentMethod} />}
          </RegionData>
        </>
      );
    }
    return null;
  };

  const addressStateInfo = () => {
    if (patient.address_state) {
      return (
        <>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Label>{t("patients.header_info.address_state")}</Label>
          <Data>{`${patient.address_state.name}, ${patient.address_state.code}`}</Data>
        </>
      );
    }
    return null;
  };

  const patientRelevantStatusInfo = useCallback(() => {
    return getPatientRelevantStatus([patient, patient.insuranceData?.data]);
  }, [patient]);

  return (
    <InfoGrid>
      <>
        <Label>{t("patients.header_info.status")}</Label>
        <PatientStatus patientStatuses={patientRelevantStatusInfo()} />
      </>
      {lastMedicalReferral()}
      {insuranceInfo()}
      {healthPlanInfo()}
      {subscriptionInfo()}
      {upcomingSubscriptionInfo()}
      {careEventsCounter()}
      {careInfo()}
      {addressStateInfo()}
      <>
        <Label>{t("patients.header_info.patient_id")}</Label>
        <Data>{patient.id}</Data>
      </>
      {patient.premium_type && patient.premium_type !== "none" && (
        <>
          <Label>{t("patients.header_info.subscription")}</Label>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Data>{t(`patients.membershipStatuses.${patient.premium_type}`)}</Data>
        </>
      )}
      <>
        <Label>{t("patients.header_info.last_active")}</Label>
        <Data>{formatDate(patient.last_activity_at)}</Data>
      </>
      <>
        <Label>{t("patients.header_info.joined_date")}</Label>
        <Data>{formatDate(patient.joined_at)}</Data>
      </>
    </InfoGrid>
  );
};

export default InfoList;
