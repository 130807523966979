import type React from "react";

import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { useProfileContext } from "contexts/ProfileContext";
import { userIsDoctor } from "utils/profile/profileHelper";

import { PatientProfile, PatientsList } from "./views";
import PatientProfileDoctor from "./views/PatientProfileDoctor";

const PatientsContainer = styled.div`
  background: ${props => props.theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100vh;
`;

const PatientsRoutes: React.VFC = () => {
  const { profile } = useProfileContext();
  const isDoctor = userIsDoctor(profile);

  return (
    <Routes>
      <Route path=":patientId/*" element={isDoctor ? <PatientProfileDoctor /> : <PatientProfile />} />
      <Route
        path="/"
        element={
          <PatientsContainer>
            <PatientsList />
          </PatientsContainer>
        }
      />
    </Routes>
  );
};

export default PatientsRoutes;
