/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef } from "react";

import type { ChangeHandler } from "react-hook-form";
import styled from "styled-components";

import { StyledInput, StyledLabel } from "../StyledInputs";

interface HelperTextProps {
  readonly error: boolean;
}

const HelperText = styled.div<HelperTextProps>`
  display: flex;
  flex-grow: 1;
  color: ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.greys.dark)};
  ${props => ({ ...props.theme.font.caption })};
  padding-left: 2px;
  padding-right: 2px;
  justify-content: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.spacing.S_5};
  margin-top: ${props => props.theme.spacing.S_5};
`;

interface TextInputProps {
  type?: "text" | "email" | "password" | "number" | "tel";
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  form?: string;
  /* also takes on the role of id for the label */
  name?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  label?: string;
  /* additional props */
  completed?: boolean;
  instruction?: string;
  error?: string;
  size?: "small" | "large";
  className?: string;
  dataTestId?: string;
  autoComplete?: "on" | "off";
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      form,
      name,
      error,
      type = "text",
      disabled = false,
      placeholder = "",
      required = false,
      label = null,
      completed = false,
      instruction = null,
      size = "large",
      className,
      autoComplete = "on",
      dataTestId = "text-input",
      onChange = () => {},
      onBlur = () => {},
    },
    ref
  ) => {
    const leftText = <HelperText error={!!error}>{error || instruction}</HelperText>;

    return (
      <Container className={className}>
        {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
        <StyledInput
          ref={ref}
          data-testid={dataTestId}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          form={form}
          name={name}
          id={name}
          completed={completed}
          error={error}
          $size={size}
          autoComplete={autoComplete}
          onChange={onChange}
          onBlur={onBlur}
        />
        {(error || instruction) && leftText}
      </Container>
    );
  }
);

export default TextInput;
