import type React from "react";

import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import Tooltip from "shared/molecules/Tooltip";

import QuickRepliesForm from "../QuickRepliesForm";

interface Props {
  readonly close: () => void;
  readonly visible: boolean;
  readonly onQuickReplySelected: (text: string) => void;
}

const QuickRepliesOverlay: React.VFC<Props> = ({ close, visible, onQuickReplySelected }) => {
  return (
    <>
      <Hidden type="belowTablet">
        <Tooltip data-testid="quick-replies-overlay-desktop" requestClose={close} isOpen={visible} offset="top-left">
          <QuickRepliesForm onQuickReplySelected={onQuickReplySelected} />
        </Tooltip>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal data-testid="quick-replies-overlay-mobile" open={visible} close={close}>
          <QuickRepliesForm onQuickReplySelected={onQuickReplySelected} />
        </Modal>
      </Hidden>
    </>
  );
};

export default QuickRepliesOverlay;
