import { useMutation } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import type { PostAppointmentArgs } from "api/types/PostAppointment";
import useApiClient from "api/useApiClient";

export const usePostAppointment = () => {
  const client = useApiClient();

  return useMutation<NewAppointment, Error, PostAppointmentArgs>(args => client.postAppointment(args));
};
