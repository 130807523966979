import type React from "react";
import { useEffect, useRef } from "react";

import {
  add,
  endOfMonth,
  endOfYear,
  getDaysInMonth,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Dropdown from "shared/atoms/inputs/Dropdown";
import useLocalizedDate from "utils/date";

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const YearWrapper = styled.div`
  width: 80px;
  margin-right: ${props => props.theme.spacing.S_5};
`;

const MonthWrapper = styled.div`
  width: 80px;
  margin: 0 ${props => props.theme.spacing.S_5};
`;

const DayWrapper = styled.div`
  width: 60px;
  margin: 0 ${props => props.theme.spacing.S_5};
`;

const SmallDropdown = styled(Dropdown)`
  width: 100%;
`;

interface Props {
  readonly initialDate?: Date;
  readonly range: [Date, Date];
  readonly onChanged: (date: Date) => void;
}

interface SelectOption {
  disabled?: boolean;
  value: string;
  label: string;
}

export const getYearOptions = (rangeStart: Date, rangeEnd: Date): SelectOption[] => {
  const startYear = rangeStart.getFullYear();
  const endYear = rangeEnd.getFullYear();

  return Array(endYear - startYear + 1)
    .fill(1)
    .map((_x, i) => {
      return { value: `${startYear + i}`, label: `${startYear + i}` };
    });
};

export const getMonthOptions = (selectedDate: Date, rangeStart: Date): SelectOption[] => {
  const selectedYearEnd = endOfYear(selectedDate);
  const startOfRange = startOfMonth(rangeStart);
  return Array(12)
    .fill(1)
    .map((_x, i) => {
      return new Date(Date.UTC(selectedDate.getFullYear(), i, 1, 12));
    })
    .reduce((acc: SelectOption[], current: Date, i: number) => {
      if (isWithinInterval(current, { start: startOfRange, end: selectedYearEnd })) {
        acc.push({ label: current.toISOString(), value: `${i}` });
      }
      return acc;
    }, []);
};

export const getDayOptions = (selectedDate: Date, rangeStart: Date): SelectOption[] => {
  const selectedMonthStart = startOfMonth(selectedDate);
  const selectedMonthEnd = endOfMonth(selectedDate);
  const startOfRange = startOfDay(rangeStart);
  return Array(getDaysInMonth(selectedDate))
    .fill(1)
    .map((_x, i) => {
      return add(selectedMonthStart, { days: i });
    })
    .reduce((acc: SelectOption[], current: Date, i: number) => {
      if (isWithinInterval(current, { start: startOfRange, end: selectedMonthEnd })) {
        acc.push({ label: `${i + 1}`, value: `${i + 1}` });
      }
      return acc;
    }, []);
};

const DatePicker: React.VFC<Props> = ({ initialDate = new Date(), range, onChanged }) => {
  const { format } = useLocalizedDate();
  const { register, setValue, watch } = useForm();

  const [rangeStart, rangeEnd] = range;

  const initialYear = `${initialDate.getFullYear()}`;
  const initialMonth = `${initialDate.getMonth()}`;
  const initialDay = format(initialDate, "d");

  const watchYear = parseInt(watch("year", initialYear), 10);
  const watchMonth = parseInt(watch("month", initialMonth), 10);
  const watchDay = parseInt(watch("day", initialDay), 10);
  const daysInMonth = getDaysInMonth(new Date(watchYear, watchMonth));
  const selectedDate = new Date(Date.UTC(watchYear, watchMonth, watchDay > daysInMonth ? daysInMonth : watchDay, 12));

  const initialized = useRef(false);

  const updateSelections = (date: Date) => {
    setValue("year", `${date.getFullYear()}`);
    setValue("month", `${date.getMonth()}`);
    setValue("day", format(date, "d"));
  };

  useEffect(() => {
    if (initialized.current) {
      let day = watchDay;

      if (day > daysInMonth) {
        day = daysInMonth;
        setValue("day", `${day}`);
      }

      if (isBefore(selectedDate, rangeStart)) {
        updateSelections(rangeStart);
        onChanged(rangeStart);
      } else if (isAfter(selectedDate, rangeEnd)) {
        updateSelections(rangeEnd);
        onChanged(rangeEnd);
      } else {
        onChanged(selectedDate);
      }
    } else {
      initialized.current = true;
    }
  }, [watchYear, watchMonth, watchDay]);

  return (
    <Container>
      <YearWrapper>
        <SmallDropdown
          data-testid="year-dropdown"
          options={getYearOptions(rangeStart, rangeEnd)}
          {...register("year")}
          selected={initialYear}
          size="small"
        />
      </YearWrapper>

      <MonthWrapper>
        <SmallDropdown
          data-testid="month-dropdown"
          options={getMonthOptions(selectedDate, rangeStart).map(option => ({
            ...option,
            label: format(new Date(option.label), "LLL"),
          }))}
          {...register("month")}
          selected={initialMonth}
          size="small"
        />
      </MonthWrapper>

      <DayWrapper>
        <SmallDropdown
          data-testid="day-dropdown"
          options={getDayOptions(selectedDate, rangeStart)}
          {...register("day")}
          selected={initialDay}
          size="small"
        />
      </DayWrapper>
    </Container>
  );
};

export default DatePicker;
