import { useTranslation } from "react-i18next";

import type { Payload } from "api/schemas/patients/SwitchTreatmentPrograms";
import { BaseButton } from "shared/atoms/BaseButton";

import { ButtonGroup, ErrorMessage, Label, Question } from "./styles";
import type { FormError } from "./TreatmentSwitch";

interface Props {
  formError?: FormError;
  painJoint: string;
  payload: Payload;
  updatePayload: (key: string, value: string | boolean) => void;
}

export const HipKneeQuestions: React.VFC<Props> = ({ formError, painJoint, payload, updatePayload }) => {
  const { t } = useTranslation();

  return (
    <>
      <Question>
        <Label>{t("patients.medical_records.acute.specific_joint_examined", { painJoint })}</Label>
        <ButtonGroup>
          <BaseButton
            onClick={() => updatePayload("specific_joint_examined", true)}
            text={t("buttons.yes")}
            variant="secondary"
            active={payload.specific_joint_examined === true}
          />
          <BaseButton
            onClick={() => updatePayload("specific_joint_examined", false)}
            text={t("buttons.no")}
            variant="secondary"
            active={payload.specific_joint_examined === false}
          />
        </ButtonGroup>
        {formError?.specific_joint_examined && <ErrorMessage>{t("form.validation.required")}</ErrorMessage>}
      </Question>

      <Question>
        <Label>{t("patient.ONBOARDING.SPECIFIC_JOINT_OA_DIAGNOSED", { painJoint })}</Label>
        <ButtonGroup>
          <BaseButton
            onClick={() => updatePayload("specific_joint_oa_diagnosed", true)}
            text={t("buttons.yes")}
            variant="secondary"
            active={payload.specific_joint_oa_diagnosed === true}
          />
          <BaseButton
            onClick={() => updatePayload("specific_joint_oa_diagnosed", false)}
            text={t("buttons.no")}
            variant="secondary"
            active={payload.specific_joint_oa_diagnosed === false}
          />
        </ButtonGroup>
        {formError?.specific_joint_oa_diagnosed && <ErrorMessage>{t("form.validation.required")}</ErrorMessage>}
      </Question>
    </>
  );
};
