import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { CommentReplies, GetSmartRepliesArgs } from "api/schemas/SmartReply";
import useApiClient from "api/useApiClient";

type QueryKey = ["getSmartCommentReplies", number, number];
const getSmartCommentRepliesQueryKey = ({ therapistId, patientId }: GetSmartRepliesArgs): QueryKey => [
  "getSmartCommentReplies",
  therapistId,
  patientId,
];

const useGetSmartCommentReplies = (
  args: GetSmartRepliesArgs,
  options?: UseQueryOptions<CommentReplies, Error, CommentReplies, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getSmartCommentRepliesQueryKey(args), () => client.getSmartCommentReplies(args), options);
};

export default useGetSmartCommentReplies;
