import type React from "react";

import styled from "styled-components";

import Avatar from "shared/atoms/Avatar";

const StyledLi = styled.li`
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${props => props.theme.spacing.S_20};
  ${props => props.theme.font.header4}
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.greys.light4};
  }
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
`;

const StyledAvatar = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 40px;
`;

const Wrapper = styled.div`
  margin-left: ${props => props.theme.spacing.S_15};
`;

interface Props {
  name: string;
  onClick: () => void;
  id: number;
}

const PatientSearchResult: React.VFC<Props> = ({ name, id, onClick }) => {
  return (
    <StyledLi onClick={onClick}>
      <StyledAvatar>
        <Avatar size={40} id={id} name={name} />
      </StyledAvatar>
      <Wrapper>{name}</Wrapper>
    </StyledLi>
  );
};

export default PatientSearchResult;
