import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface Response {
  status: string;
}

const unpinMessage = (id: number): Promise<AxiosResponse<Response>> => AxiosInstance.put(`/messages/${id}/unpin`);

export default unpinMessage;
