import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CancelIcon, DotDotDotIcon } from "assets";

import DropdownButton from "./components/DropdownButton";

interface Props {
  toggleEndTreatment: () => void;
}

const PatientHeaderInfoShowMoreButton: React.VFC<Props> = ({ toggleEndTreatment }) => {
  const { t } = useTranslation();

  return (
    <DropdownButton
      dataTestId="extra-button"
      id="extra-button"
      small
      last
      options={[
        {
          children: (
            <OptionItem>
              <CancelIcon />
              <div>{t("patients.header_info.end_treatment_button")}</div>
            </OptionItem>
          ),
          onClick: () => toggleEndTreatment(),
          dataTestId: "button-end-treatment-forms",
        },
      ]}
      tooltips={[]}
    >
      <DotDotDotIcon />
    </DropdownButton>
  );
};

const OptionItem = styled.div`
  display: flex;
  > svg {
    border: 0px;
    margin-right: ${props => props.theme.spacing.S_10};
  }
`;

export default PatientHeaderInfoShowMoreButton;
