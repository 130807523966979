import { useContext } from "react";

import SavedInputsContext from "./SavedInputsContext";
import type { SavedInputsContextValue } from "./SavedInputsContextValue";

const useSavedInputsContext = (): SavedInputsContextValue => {
  const value = useContext(SavedInputsContext);

  if (value === null) {
    throw new Error("useSavedInputsContext cannot be used outside the SavedInputsContextProvider");
  }
  return value;
};

export default useSavedInputsContext;
