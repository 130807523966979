import type React from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import Dropdown from "shared/atoms/inputs/Dropdown";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";
import { Notification } from "shared/atoms/Notification";
import type { Market } from "types";
import useLocalizedDate from "utils/date";
import getLngDropdownOpts from "utils/locale/getLngDropdownOpts";
import { setStorageValue } from "utils/storage";

type FormData = {
  first_name: string;
  last_name: string;
  language_code?: string;
  address: string;
  birth_year: string;
  birth_month: string;
  birth_date: string;
  city: string;
  phone_number: string;
  zip_code: string;
};

const ProfileInformation: React.VFC = () => {
  const { i18n, t } = useTranslation();
  const { profile, setProfile } = useProfileContext();
  const { parse, formatISO, set, getDaysInMonth, format } = useLocalizedDate();
  const putTherapistMutation = usePutTherapistMutation();

  invariant(profile);

  const birthday = profile.therapist_profile.birthday
    ? parse(profile.therapist_profile.birthday, "yyyy-MM-dd", new Date())
    : null;

  const { handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      first_name: profile.first_name || "",
      last_name: profile.last_name || "",
      address: profile.therapist_profile.address || "",
      zip_code: profile.therapist_profile.zip_code || "",
      city: profile.therapist_profile.city || "",
      phone_number: profile.therapist_profile.phone_number || "",
      birth_year: birthday?.getFullYear().toString() || t("common.year"),
      birth_month: birthday?.getMonth().toString() || t("common.month"),
      birth_date: birthday?.getDate().toString() || t("common.day"),
      language_code: profile.language_code || undefined,
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  const watchBirthYear = watch("birth_year");
  const watchBirthMonth = watch("birth_month");
  const watchBirthDate = watch("birth_date");

  const handleChangePersonalInformationClick = () => {
    setIsEditing(!isEditing);
    putTherapistMutation.reset();
  };

  const REGION = profile.market;
  const dropdownValues = getLngDropdownOpts(REGION as Market);

  const formatBirthday = ({ birth_date, birth_month, birth_year }: FormData): string | undefined => {
    if ([birth_date, birth_month, birth_year].some(el => Number.isNaN(parseInt(el, 10)))) return undefined;
    return formatISO(
      set(new Date(), {
        year: parseInt(birth_year, 10),
        month: parseInt(birth_month, 10),
        date: parseInt(birth_date, 10),
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    );
  };

  const onSubmit = async (profileDataFromForm: FormData) => {
    const birthdayInUTC = formatBirthday(profileDataFromForm);
    const therapistId = profile.id;
    const data = {
      email: profile.email ?? "",
      language_code: profileDataFromForm.language_code ? profileDataFromForm.language_code : profile.language_code,
      first_name: profileDataFromForm.first_name ? profileDataFromForm.first_name : profile.first_name ?? "",
      last_name: profileDataFromForm.last_name ? profileDataFromForm.last_name : profile.last_name ?? "",
      therapist_profile: {
        address: profileDataFromForm.address === "" ? profile.therapist_profile.address : profileDataFromForm.address,
        birthday: birthdayInUTC || (profile.therapist_profile.birthday ?? null),
        city: profileDataFromForm.city === "" ? profile.therapist_profile.city : profileDataFromForm.city,
        phone_number:
          profileDataFromForm.phone_number === ""
            ? profile.therapist_profile.phone_number
            : profileDataFromForm.phone_number,
        zip_code:
          profileDataFromForm.zip_code === "" ? profile.therapist_profile.zip_code : profileDataFromForm.zip_code,
      },
    };

    const variables = { data, therapistId };

    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);

        reset({
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          address: data.therapist_profile.address || "",
          zip_code: data.therapist_profile.zip_code || "",
          city: data.therapist_profile.city || "",
          phone_number: data.therapist_profile.phone_number || "",
        });
        setIsEditing(!isEditing);

        const newLanguage = response.locale;

        if (newLanguage && i18n.language !== newLanguage) {
          setStorageValue("language", newLanguage);
          i18n.changeLanguage(newLanguage);
        }
      },
    });
  };

  const getDropdownYears = () => {
    const currentYear = new Date().getFullYear();
    return Array(119)
      .fill(0)
      .map((_x, i) => {
        return { value: (currentYear - i).toString(), label: (currentYear - i).toString() };
      })
      .reverse();
  };

  const getDropdownMonths = () => {
    return Array(12)
      .fill(0)
      .map((_x, i) => {
        return { value: String(i), label: format(set(new Date(), { month: i }), "LLL") };
      });
  };

  const getDropdownDays = () => {
    const today = new Date();
    const daysInMonth =
      watchBirthYear && watchBirthYear !== t("common.year") && watchBirthMonth && watchBirthMonth !== t("common.month")
        ? getDaysInMonth(
            set(today, {
              year: parseInt(watchBirthYear, 10),
              month: parseInt(watchBirthMonth, 10),
            })
          )
        : 31;
    return Array(daysInMonth)
      .fill(0)
      .map((_x, i) => {
        return { value: (i + 1).toString(), label: (i + 1).toString() };
      });
  };

  return (
    <ProfileInformationContainer data-testid="profile-information">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProfileInformationTable>
          <tbody>
            <StyledTableRow>
              <PersonalInformationProperty>{t("form.first_name")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="firstname-input" {...register("first_name")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="firstname-value">
                    {profile.first_name}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <PersonalInformationProperty>{t("form.last_name")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="lastname-input" {...register("last_name")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="lastname-value">{profile.last_name}</PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <PersonalInformationProperty>{t("settings.profile_information.address")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="address-input" {...register("address")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="address-value">
                    {profile.therapist_profile.address}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <PersonalInformationProperty>{t("settings.profile_information.postal")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="zip-input" {...register("zip_code")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="zip-value">
                    {profile.therapist_profile.zip_code}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <PersonalInformationProperty>{t("settings.profile_information.city")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="city-input" {...register("city")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="city-value">
                    {profile.therapist_profile.city}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            {dropdownValues && (
              <StyledTableRow>
                <PersonalInformationProperty>{t("settings.profile_information.language")}</PersonalInformationProperty>
                {isEditing ? (
                  <td>
                    <StyledLanguageDropdown
                      m="0"
                      localiseLabels
                      placeholder={t("settings.profile_information.language")}
                      {...register("language_code")}
                      selected={profile.language_code}
                      options={dropdownValues}
                    />
                  </td>
                ) : (
                  <td>
                    <PersonalInformationValue data-testid="lng-value">
                      {/* FIXME: type translation */}
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {t(`common.locales.${profile.language_code}`)}
                    </PersonalInformationValue>
                  </td>
                )}
              </StyledTableRow>
            )}
            <StyledTableRow>
              <PersonalInformationProperty>
                {t("settings.profile_information.mobile_number")}
              </PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <PersonalInformationInput data-testid="phone-input" {...register("phone_number")} />
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="phone-value">
                    {profile.therapist_profile.phone_number}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <PersonalInformationProperty>{t("settings.profile_information.birth_date")}</PersonalInformationProperty>
              {isEditing ? (
                <td>
                  <StyledBirthdayDropdownContainer>
                    <StyledBirthdayDropdown
                      m="0"
                      selected={watchBirthMonth}
                      placeholder={t("common.month")}
                      {...register("birth_month")}
                      options={getDropdownMonths()}
                    />
                    <StyledBirthdayDropdown
                      m="0"
                      selected={watchBirthDate}
                      placeholder={t("common.day")}
                      {...register("birth_date")}
                      options={getDropdownDays()}
                    />
                    <StyledBirthdayDropdown
                      m="0"
                      selected={watchBirthYear}
                      {...register("birth_year")}
                      placeholder={t("common.year")}
                      options={getDropdownYears()}
                    />
                  </StyledBirthdayDropdownContainer>
                </td>
              ) : (
                <td>
                  <PersonalInformationValue data-testid="birthday-value">
                    {birthday && format(birthday, "PP")}
                  </PersonalInformationValue>
                </td>
              )}
            </StyledTableRow>
          </tbody>
        </ProfileInformationTable>
        {isEditing ? (
          <Wrapper>
            <TextButton onClick={handleChangePersonalInformationClick}>{t("buttons.cancel")}</TextButton>
            <StyledPrimaryButton type="submit">{t("buttons.save")}</StyledPrimaryButton>
          </Wrapper>
        ) : (
          <IconButton
            dataTestId="edit-profile-information-button"
            label={t("settings.profile_information.change_profile_information")}
            onClick={handleChangePersonalInformationClick}
          >
            <EditIcon />
          </IconButton>
        )}
        {putTherapistMutation.isError && (
          <Notification variant="danger" style={{ margin: "5px 0" }}>
            {t("errors.generic")}
          </Notification>
        )}
      </form>
    </ProfileInformationContainer>
  );
};

const ProfileInformationContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
`;

const ProfileInformationTable = styled.table`
  padding: ${props => props.theme.spacing.S_20} 0;
`;

const StyledTableRow = styled.tr`
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const PersonalInformationProperty = styled.td`
  ${props => ({ ...props.theme.font.body1 })}
  min-width: 166px;
  padding: ${props => props.theme.spacing.S_10};
  ${props => props.theme.belowMobileBreakpoint} {
    padding-bottom: 0;
    padding-top: 6px;
  }
`;

const PersonalInformationValue = styled.div`
  ${props => ({ ...props.theme.font.body1 })}
  font-family: "Roboto", sans-serif;
  font-weight: ${props => props.theme.fontWeight.medium};
  padding: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  flex-basis: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.colors.greys.dark};
  background-color: transparent;
  ${props => props.theme.belowMobileBreakpoint} {
    padding-top: 0;
    margin: 0;
  }
`;

const PersonalInformationInput = styled(StyledInput)`
  width: 300px;
`;

const StyledLanguageDropdown = styled(Dropdown)`
  width: 300px;
`;

const StyledBirthdayDropdownContainer = styled.div`
  display: flex;
`;

const StyledBirthdayDropdown = styled(Dropdown)`
  width: 100px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

export default ProfileInformation;
