import { parseISO } from "date-fns";
import invariant from "ts-invariant";

import type {
  Market,
  MedicalNoteConfigVersion,
  MedicalNoteSectionConfig,
  MedicalNoteType,
  MedicalNoteTypesConfig,
} from "types";
import type { DateString } from "types/DateString";

import MedicalNoteConfigVersions from "../../medical_note_definitions";

const processConfig = (marketConfig: MedicalNoteTypesConfig): MedicalNoteTypesConfig => {
  const processedConfigs = {} as MedicalNoteTypesConfig;

  Object.entries((marketConfig as MedicalNoteTypesConfig) || {}).forEach(([type, config]) => {
    if (typeof config === "string") {
      processedConfigs[type as MedicalNoteType] = (marketConfig as MedicalNoteTypesConfig)[config as MedicalNoteType];
    } else {
      processedConfigs[type as MedicalNoteType] = (marketConfig as MedicalNoteTypesConfig)[type as MedicalNoteType];
    }
  });
  return processedConfigs;
};

const getVersionOfConfig = (
  marketConfigs: MedicalNoteConfigVersion[],
  validOn?: DateString
): MedicalNoteTypesConfig => {
  const validOnDate = validOn ? parseISO(validOn) : new Date();
  const validMarketConfig = marketConfigs
    .sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime()) // oldest to newest
    .reduce((prev, curr) => {
      return new Date(curr.validFrom) <= validOnDate ? curr : prev;
    }, marketConfigs[0]);
  return validMarketConfig.config;
};

const getMedicalNoteConfig = (
  market: Market | undefined,
  noteType: MedicalNoteType,
  validOn?: DateString
): MedicalNoteSectionConfig[] => {
  const type = noteType.toLocaleUpperCase() as MedicalNoteType;
  invariant(market, "market is required");
  const marketConfigVersions = MedicalNoteConfigVersions[market];
  invariant(marketConfigVersions, `No config defined for market: ${market}`);

  const marketConfig = getVersionOfConfig([...marketConfigVersions], validOn);
  const processedConfigs = processConfig(marketConfig as MedicalNoteTypesConfig);
  if (processedConfigs[type]) {
    // return market and note type specific config
    return processedConfigs[type] as MedicalNoteSectionConfig[];
  }

  // fallback to market specific DEFAULT
  if (marketConfig.DEFAULT) return marketConfig.DEFAULT as MedicalNoteSectionConfig[];

  // fallback to GLOBAL DEFAULT
  invariant(MedicalNoteConfigVersions.GLOBAL, "No config defined for GLOBAL");
  return getVersionOfConfig(MedicalNoteConfigVersions.GLOBAL).DEFAULT as MedicalNoteSectionConfig[];
};

export default getMedicalNoteConfig;
