import type React from "react";

import { NavLink } from "react-router-dom";
import styled from "styled-components";

import PatientSearchResult from "../PatientSearchResult";

const StyledUl = styled.ul<{ $open: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  overflow: scroll;
  max-height: 270px;
  display: ${props => (props.$open ? "block" : "none")};
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  background-color: ${props => props.theme.colors.white};
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;

interface Props {
  patientData: Array<{
    id: number;
    name: string;
  }>;
  handleSelect: () => void;
  open: boolean;
}

const PatientSearchResults: React.VFC<Props> = ({ patientData, handleSelect, open }) => {
  return (
    <StyledUl $open={open} data-testid="messages-patient-search-result">
      {patientData.map(patient => {
        return (
          <Link key={patient.id} to={`/messages/${patient.id}`}>
            <PatientSearchResult name={patient.name} id={patient.id} onClick={() => handleSelect()} />
          </Link>
        );
      })}
    </StyledUl>
  );
};

export default PatientSearchResults;
