import type React from "react";
import { useEffect } from "react";

import { Route, Routes, useMatch } from "react-router-dom";

import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import Settings from "./views/Settings";

const SettingsRoutes: React.VFC = () => {
  const match = useMatch("*");
  const offsetTop = usePageContentOffsetTop();

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.SETTINGS, match?.pathname);
    }
  }, [match?.pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container $pageContentOffsetTop={offsetTop}>
            <Settings />
          </Container>
        }
      />
    </Routes>
  );
};

export default SettingsRoutes;
