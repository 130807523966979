import { useMutation } from "react-query";

import type { BookingIntervalResponse, PostManagerBookingIntervalArgs } from "api/schemas/BookingInterval";
import useApiClient from "api/useApiClient";

export const usePostManagerBookingInterval = () => {
  const client = useApiClient();
  return useMutation<BookingIntervalResponse, Error, PostManagerBookingIntervalArgs>(args =>
    client.postManagerBookingInterval(args)
  );
};
