import { useContext } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";

import { getAvailableOptionsFromBusinessLogic } from "./notesHelper";

type DropDownOptionType = {
  label: string;
  onClick: () => Promise<void>;
  disabled: boolean;
  dataTestId?: string;
  customDropdownStyling?: string;
};

interface Props {
  createNote: (type: string) => Promise<void>;
}

const usePrepareCareEventOptions = ({ createNote }: Props): DropDownOptionType[] => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { hasFeature } = useFeatureToggleContext();
  const { isCreatable } = useContext(CurrentPatientContext);

  invariant(profile);

  const creatable = isCreatable.data;
  // the discharge option in SE is displayed only for self-care enabled therapists
  const dischargeEnabled =
    (hasFeature(Feature.DISCHARGE_NOTE_RESOLUTION) && hasFeature(Feature.SELFCARE)) ||
    !hasFeature(Feature.DISCHARGE_NOTE_RESOLUTION);

  const options: Record<string, DropDownOptionType> = {
    chat: {
      label: t("dashboard.tables.notes.types.CHAT"),
      onClick: () => createNote("chat"),
      dataTestId: "create-chat-note",
      disabled: creatable ? !creatable.chat_event : false,
    },
    treatment: {
      label: t("dashboard.tables.notes.types.TREATMENT"),
      onClick: () => createNote("treatment"),
      dataTestId: "create-treatment-note",
      disabled: creatable ? !creatable.treatment_event : false,
    },
    treatment_and_progress: {
      label: t("dashboard.tables.notes.types.TREATMENT_AND_PROGRESS"),
      onClick: () => createNote("treatment_and_progress"),
      dataTestId: "create-treatment_and_progress-note",
      disabled: creatable ? !creatable.treatment_and_progress_event : false,
    },
    re_evaluation: {
      label: t("dashboard.tables.notes.types.RE_EVALUATION"),
      onClick: () => createNote("re_evaluation"),
      dataTestId: "create-re_evaluation-note",
      disabled: creatable ? !creatable.re_evaluation_event : false,
    },
    supplemental: {
      label: t("dashboard.tables.notes.types.SUPPLEMENTAL"),
      onClick: () => createNote("supplemental"),
      dataTestId: "create-supplemental-note",
      disabled: creatable ? !creatable.supplemental_event : false,
    },
    physical_visit_new: {
      label: t("dashboard.tables.notes.types.PHYSICAL_VISIT_NEW"),
      onClick: () => createNote("physical_visit_new"),
      dataTestId: "create-physical_visit_new-note",
      disabled: creatable ? !creatable.physical_visit_new_event : false,
    },
    physical_visit_return: {
      label: t("dashboard.tables.notes.types.PHYSICAL_VISIT_RETURN"),
      onClick: () => createNote("physical_visit_return"),
      dataTestId: "create-physical_visit_return-note",
      disabled: creatable ? !creatable.physical_visit_return_event : false,
    },
    ...(dischargeEnabled
      ? {
          discharge: {
            label: t("dashboard.tables.notes.types.DISCHARGE"),
            onClick: () => createNote("discharge"),
            dataTestId: "create-discharge-note",
            disabled: creatable ? !creatable.discharge_event : false,
          },
        }
      : {}),
  };

  const visibleOptions = getAvailableOptionsFromBusinessLogic(profile.market ?? "", isCreatable.data);

  return Object.keys(options).reduce((final, type) => {
    if (visibleOptions.indexOf(type) >= 0) {
      final.push(options[type]);
    }
    return final;
  }, [] as Array<DropDownOptionType>);
};

export default usePrepareCareEventOptions;
