import styled from "styled-components";

const EmptyRow = styled.tr<{ height: string }>`
  height: ${props => props.height};
`;

const TableEmptyRows = (currentRows: number, maxRows: number, height: string): Array<JSX.Element> => {
  const rows = [];

  for (let i = currentRows; i < maxRows; i += 1) {
    rows.push(<EmptyRow key={`empty-${i}`} data-testid="empty-row" height={height} />);
  }

  return rows;
};

export default TableEmptyRows;
