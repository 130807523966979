import { useTranslation } from "react-i18next";
import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetActivityLibraryEntryResponse } from "api/types/GetActivityLibraryEntry";
import useApiClient from "api/useApiClient";

export const getActivityRecordQueryKey = (activityId: string) => ["GetActivityLibraryEntryQuery", activityId] as const;

export const useActivityLibraryEntry = (activityId: string): UseQueryResult<GetActivityLibraryEntryResponse, Error> => {
  const { i18n } = useTranslation();
  const client = useApiClient();
  const locale = i18n.language.toLocaleLowerCase();
  return useQuery(getActivityRecordQueryKey(activityId), () => client.getActivityLibraryEntry(activityId, locale));
};
