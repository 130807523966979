import type React from "react";
import { useContext } from "react";

import styled, { ThemeContext } from "styled-components";

import Body1 from "shared/atoms/Typography/Body1";

interface LoginInfoContainerProps {
  title: string;
  success?: boolean;
}

export const LoginInfoContainer: React.FC<LoginInfoContainerProps> = ({ title, children, success = false }) => {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper success={success}>
      <H1>{title}</H1>
      {children && <Body1 style={{ color: theme.colors.primary.base, marginTop: "12px" }}>{children}</Body1>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ success: boolean }>`
  padding: 24px;
  background-color: ${props => (props.success ? props.theme.colors.pastels.green : props.theme.colors.greys.light3)};
`;

const H1 = styled.h1`
  ${({ theme }) => theme.font.header1}
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.primary.base};
  margin: 0px;
`;
