/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type React from "react";

import styled from "styled-components";

import { SearchIcon } from "assets";

import { inputStyles } from "../StyledInputs";

const Wrapper = styled.div`
  padding: 0;
  max-width: 100%;
  position: relative;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
`;

const IconWrapper = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  & svg {
    max-width: 25px;
  }
`;

const Input = styled.input`
  ${inputStyles}
  padding-left: ${props => props.theme.spacing.S_40};

  :focus {
    outline: none;
    border: none;
    border: 2px solid ${props => props.theme.colors.greys.silver};
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: ${props => props.theme.spacing.S_10};
  overflow: hidden;
  overflow-y: auto;
  animation: enlarge 0.25s forwards;
  background: ${props => props.theme.colors.white};

  position: absolute;
  top: 40px;
  z-index: 2;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  border-top: none;
  border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
  border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
  width: 296px;
`;

const ListItem = styled.li`
  display: flex;
  padding: ${props => props.theme.spacing.S_5} 0;
  cursor: pointer;
  ${props => props.theme.font.caption}

  &:hover {
    background: whitesmoke;
  }
`;

const ItemWrapper = styled.span`
  padding: 0 ${props => props.theme.spacing.S_10};
`;

export type ListItem = {
  value: string;
  label: string;
};

interface Props {
  onItemClick: (item: ListItem) => void;
  placeholder: string;
  listData: Array<ListItem>;
  searchValue: string;
  setSearchValue: (value: string) => void;
  renderItem: (item: ListItem) => JSX.Element;
}

const SearchableSelect: React.VFC<Props> = ({
  onItemClick,
  placeholder,
  searchValue,
  setSearchValue,
  listData,
  renderItem,
}) => {
  const onClickItem = (item: ListItem) => {
    onItemClick(item);
    setSearchValue("");
  };

  const onInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearchValue(e.target?.value);
  };

  return (
    <>
      <Wrapper>
        <Search>
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
          <Input
            id="search"
            type="search"
            value={searchValue}
            placeholder={placeholder}
            onChange={onInput}
            autoComplete="off"
            data-testid="searchable-select"
          />
        </Search>
        {searchValue.length > 0 && listData?.length > 0 && (
          <List>
            {listData.map(item => (
              <ListItem onClick={() => onClickItem(item)} key={item.value}>
                <ItemWrapper>{renderItem(item)}</ItemWrapper>
              </ListItem>
            ))}
          </List>
        )}
      </Wrapper>
    </>
  );
};

export default SearchableSelect;
