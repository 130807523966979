import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import type { HealthReport } from "api/models/HealthReport";
import { DropdownIcon } from "assets";
import { CheckBox } from "shared/atoms/inputs";
import { inputStyles } from "shared/atoms/inputs/StyledInputs";
import useLocalizedDate from "utils/date";

interface Props {
  defaultOpen?: boolean;
  reportList?: HealthReport[];
  selected: number[];
  changeCallback: (selectedIds: number[]) => void;
}

const ReportsDropdown: React.VFC<Props> = ({ defaultOpen = false, selected, reportList, changeCallback }) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const [open, setOpen] = useState(defaultOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const clickAway = useCallback(
    event => {
      if (
        dropdownRef.current &&
        !(event.target === dropdownRef.current || dropdownRef.current.contains(event.target)) &&
        open
      ) {
        setOpen(!open);
      }
    },
    [open, setOpen]
  );

  useEffect(() => {
    document.addEventListener("click", clickAway);
    return () => {
      document.removeEventListener("click", clickAway);
    };
  }, [clickAway]);

  const renderText = () => {
    switch (selected.length) {
      case 0:
        return t("patients.health_reports.none");
      case 1: {
        const selection = reportList?.find(el => el.id === selected[0]);
        if (selection) {
          return `${t("patients.health_reports.q_number", {
            number: selection.health_report_number,
          })} | ${format(parseISO(selection.created_at), "P")}`;
        }
        return "";
      }
      default:
        return t("patients.health_reports.selected", { number: selected.length });
    }
  };

  const handleClick = (id: number) => {
    if (selected.find(el => el === id) && selected.length > 1) changeCallback(selected.filter(el => el !== id));
    else if (!selected.find(el => el === id)) changeCallback([...selected, id]);
  };

  return (
    <RelativeWrapper data-testid="reports-dropdown" ref={dropdownRef}>
      <Container
        $open={open}
        onClick={() => reportList?.length && setOpen(!open)}
        data-testid="reports-dropdown-header"
      >
        <TextWrapper>
          <Caption>{t("patients.health_reports.questionnaire", { number: "" })}</Caption>
          {renderText()}
        </TextWrapper>
        <AnimatedDropdownIcon $open={open} />
      </Container>
      <DropdownList $open={open}>
        {open &&
          reportList?.map(report => {
            const selection = !!selected.find(el => el === report.id);
            const disabled = !!selected.find(el => el === report.id) && selected.length === 1;
            invariant(selected);
            return (
              <StyledCheckBox
                key={report.id}
                name={selection ? "selected" : "unselected"}
                label={`${t("patients.health_reports.q_number", { number: report.health_report_number })} | ${format(
                  parseISO(report.created_at),
                  "P"
                )}`}
                checked={selection}
                onChange={() => {
                  handleClick(report.id);
                }}
                disabled={disabled}
              />
            );
          })}
      </DropdownList>
    </RelativeWrapper>
  );
};

const RelativeWrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ $open: boolean }>`
  width: 100%;
  ${inputStyles}
  ${props =>
    props.$open &&
    `border: 2px solid ${props.theme.colors.secondary.base};
  outline: none;`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_10};
`;

const TextWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
`;

const Caption = styled.div`
  ${props => ({ ...props.theme.font.caption2 })};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  line-height: 17px;
`;

const AnimatedDropdownIcon = styled(DropdownIcon)<{ $open: boolean }>`
  transition: transform 300ms;
  transform: ${props => (props?.$open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const DropdownList = styled.div<{ $open: boolean }>`
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
  margin-top: ${props => props.theme.spacing.S_5};
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.basic};
  border: 2px solid ${props => props.theme.colors.secondary.base};
  ${props => !props.$open && `display: none;`}
  min-height: 40px;
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.primary.base};
`;

const StyledCheckBox = styled(CheckBox)`
  padding: 12px;
  border-bottom: 2px solid rgb(218, 223, 226);
  &:first-child {
    border-top: none;
    border-top-left-radius: ${props => props.theme.borderRadius.basic};
    border-top-right-radius: ${props => props.theme.borderRadius.basic};
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
    border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
  }
`;

export default ReportsDropdown;
