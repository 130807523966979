import type { VFC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Vacation } from "assets";
import { useIsPtOoo } from "contexts/ProfileContext";

export const OOO_BANNER_HEIGHT = 64;

const Root = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.weekly_report_and_health.background};
  box-sizing: border-box;
  color: ${props => props.theme.colors.weekly_report_and_health.accent};
  display: flex;
  gap: 12px;
  min-height: ${OOO_BANNER_HEIGHT}px;
  justify-content: center;
  padding: 0 12px;
  width: 100%;
  z-index: 800;
  flex-shrink: 0;
`;

const Icon = styled(Vacation)`
  flex: none;
`;

const OooBanner: VFC = () => {
  const isPtOoo = useIsPtOoo();
  const { t } = useTranslation();

  if (!isPtOoo) {
    return null;
  }
  return (
    <Root data-testid="ooo-banner">
      <Icon />
      <span>{t("ooo_banner_message")}</span>
    </Root>
  );
};

export default OooBanner;
