import type React from "react";
import { useState } from "react";

import { useGetPatientIcdCodesQuery } from "api/hooks/useGetPatientIcdCodes";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { isNoteCreatable } from "routes/patients/queries/notesApiHandler";
import type { ICDCodeResponse } from "types/ICDCodes";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";

import { useGetPatientFullDetailsQuery } from "./useGetPatientsFullDetailsQuery";

interface Props {
  children: React.ReactNode;
}

const CurrentPatientContextProvider: React.VFC<Props> = ({ children }) => {
  const { profile } = useProfileContext();
  const [dirtyPatientId, setPatientId] = useState<number | string | undefined>();
  const [isCreatable, setCreatable] = useState({ create: false, error: false });
  const [activeCareEventNote, setActiveCareEventNote] = useState(null);
  const [availableICDCodes, setAvailableICDCodes] = useState<ICDCodeResponse[]>([]);
  const [previousPage, setPreviousPage] = useState({ pathname: "/", text: "dashboard" });
  const { hasFeature } = useFeatureToggleContext();
  const useICDCodesApi = hasFeature(Feature.USE_ICD_CODES_API);
  const patientId = toPatientId(dirtyPatientId);
  const { market: therapistMarket, id: therapistId } = profile ?? {};
  const [previousTreatmentId, setPreviousTreatmentId] = useState<number | null>(null);

  const fullDetailsArgs =
    patientId && therapistId && therapistMarket
      ? { patientId, therapistId, therapistMarket, previousTreatmentId }
      : null;
  const getPatientFullDetailsQuery = useGetPatientFullDetailsQuery(fullDetailsArgs, {
    enabled: !!fullDetailsArgs,
  });

  const refreshPatient = () => {
    getPatientFullDetailsQuery.refetch();
  };

  const icdCodesArgs = patientId && useICDCodesApi ? { patientId } : null;
  useGetPatientIcdCodesQuery(icdCodesArgs, {
    enabled: !!icdCodesArgs,
    onSuccess(response) {
      setAvailableICDCodes(response);
    },
  });

  const updateIsCreatable = () => {
    isNoteCreatable(patientId)
      .then(({ data }) => {
        let create = false;
        if (data?.chat_event) {
          create = true;
        }
        setCreatable(obj => ({ ...obj, create, data }));
      })
      .catch(() => {
        setCreatable(obj => ({ ...obj, create: false, error: true }));
      });
  };

  return (
    <CurrentPatientContext.Provider
      value={{
        refreshPatient,
        setPatientId,
        patient: getPatientFullDetailsQuery.data,
        patientError: getPatientFullDetailsQuery.error,
        patientLoading: getPatientFullDetailsQuery.isLoading,
        previousPage,
        setPreviousPage,
        isCreatable,
        setCreatable,
        updateIsCreatable,
        activeCareEventNote,
        setActiveCareEventNote,
        availableICDCodes,
        previousTreatmentId,
        setPreviousTreatmentId,
      }}
    >
      {children}
    </CurrentPatientContext.Provider>
  );
};

export default CurrentPatientContextProvider;

const toPatientId = (value: number | string | undefined) => {
  if (typeof value === "number" || typeof value === "undefined") {
    return value;
  }
  const number = Number.parseInt(value, 10);

  if (!Number.isNaN(number)) {
    return number;
  }
  return undefined;
};
