import { isValidDate } from "utils/date";

import useProfileContext from "./useProfileContext";

const useIsPtOoo = (): boolean => {
  const { profile } = useProfileContext();

  if (profile && profile.active_time_off_period?.end_date) {
    const date = new Date(profile.active_time_off_period.end_date);

    if (isValidDate(date)) {
      return date > new Date();
    }
  }
  return false;
};

export default useIsPtOoo;
