import styled from "styled-components";

interface ColProps {
  flex?: number;
  reverse?: boolean;
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
  align?: "flex-start" | "flex-end" | "center" | "stretch";
  background?: string;
}

const Col = styled.div<ColProps>`
  display: flex;
  flex: ${props => (props.flex ? props.flex : "1")};
  flex-direction: ${props => (props.reverse ? "column-reverse" : "column")};
  justify-content: ${props => (props.justify ? props.justify : "flex-start")};
  align-items: ${props => (props.align ? props.align : "stretch")};
  background: ${props => (props.background ? props.background : "transparent")};
`;

export default Col;
