import type { DragEvent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import throttle from "lodash.throttle";
import { useTranslation } from "react-i18next";
import type { Step } from "react-joyride";
import styled from "styled-components";

import type { UserCourseLesson } from "api/schemas/UserCourse";

import IconLink from "./IconLink";
import { OuterMargin, TopBar, TopLeft, TopRight } from "./SharedStyledComponents";
import UserCourseLessonItem from "./UserCourseLesson";

const ITEM_HEIGHT = 120;

const LessonsList = ({
  lessons,
  onDeleteLesson,
  onSetLessons,
  newlyAddedLessons,
  onOpenLessonPicker,
  editable,
  setTour,
}: {
  lessons: UserCourseLesson[];
  onDeleteLesson: (id: number) => void;
  onSetLessons: (lessons: UserCourseLesson[]) => void;
  newlyAddedLessons: number[];
  onOpenLessonPicker: () => void;
  editable: boolean;
  setTour: (t: { id: string; steps: Step[] } | null) => void;
}) => {
  const { t } = useTranslation();
  const [dndLessons, setDndLessons] = useState<UserCourseLesson[]>([]);
  const [sourceIndex, setSourceIndex] = useState<number | undefined>();
  const [sourceElement, setSourceElement] = useState<UserCourseLesson>();
  const topRef = useRef<HTMLDivElement>(null);

  const lessonsSteps: Step[] = [
    {
      target: "#tip_lessonlist",
      content: String(t("usercourse.tip_lessonlist")),
      placement: "top",
      floaterProps: { placement: "bottom" },
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#tip_draghandle",
      content: String(t("usercourse.tip_draghandle")),
      disableScrolling: true,
    },
    {
      target: "#tip_addlesson",
      content: String(t("usercourse.tip_addlesson")),
      disableScrolling: true,
    },
    {
      target: "#tip_removelesson",
      content: String(t("usercourse.tip_removelesson")),
      disableScrolling: true,
    },
    {
      target: "#tip_activate",
      content: String(t("usercourse.tip_activate")),
      disableScrolling: true,
    },
  ];

  useEffect(() => {
    const initialDndLessons: UserCourseLesson[] = lessons.map(lesson => ({
      ...lesson,
    }));
    setDndLessons(initialDndLessons);
  }, [lessons]);

  const isDragging = sourceIndex !== undefined;

  const insertElementAt = (index: number) => {
    if (sourceElement !== undefined && sourceIndex !== undefined) {
      setDndLessons(state => {
        const newState = [...state];
        newState.splice(sourceIndex, 1);
        newState.splice(index, 0, sourceElement);
        return newState;
      });
      setSourceIndex(index);
    }
  };

  const onDragOver = useCallback(
    throttle(
      (e: DragEvent, index: number) => {
        e.dataTransfer.dropEffect = "move";
        if (sourceElement !== undefined && sourceIndex !== undefined) {
          if (index === 0) {
            insertElementAt(0);
          } else if (index >= dndLessons.length) {
            insertElementAt(dndLessons.length - 1);
          } else {
            insertElementAt(index);
          }
        }
      },
      250,
      { leading: true, trailing: false }
    ),
    [setDndLessons, sourceIndex, sourceElement]
  );

  return (
    <>
      <div ref={topRef} style={{ position: "absolute", top: 0, left: 0 }} />
      <PanelBg>
        <OuterMargin>
          <TopBar>
            <TopLeft>
              <LeftSpace>
                <IconLink
                  icon={faQuestionCircle}
                  title={t("usercourse.help")}
                  onClick={() => {
                    topRef.current?.scrollIntoView(); // joyride behaves strange if page is not scrolled to top
                    setTour({ id: "usercourse-lessons", steps: lessonsSteps });
                  }}
                />
              </LeftSpace>
            </TopLeft>
            <TopRight>
              {editable && (
                <div id="tip_addlesson">
                  <IconLink
                    icon={faCirclePlus}
                    title={String(t("usercourse.add_lesson"))}
                    onClick={() => {
                      onOpenLessonPicker();
                    }}
                  />
                </div>
              )}
            </TopRight>
          </TopBar>
          <div>
            {lessons && (
              <div id="tip_lessonlist">
                <Lessons>
                  <DropOverlay $isDragging={isDragging}>
                    {Array.from({ length: dndLessons.length }).map((l, index) => {
                      return (
                        <DropRow // eslint-disable-next-line react/no-array-index-key
                          key={`dropzone_${index}`}
                        >
                          <Index>{index + 1}</Index>
                          <DropZone
                            onDragOver={e => {
                              e.preventDefault();
                              onDragOver(e, index);
                            }}
                            onDragEnter={e => e.preventDefault()}
                            onDrop={e => {
                              e.preventDefault();
                              onSetLessons(dndLessons);
                            }}
                            $editable={editable}
                          />
                        </DropRow>
                      );
                    })}
                  </DropOverlay>
                  <>
                    {dndLessons.map((lesson, index) => {
                      return (
                        <ItemRoot
                          key={`itemroot_${lesson.id}`}
                          draggable={editable ? "true" : false}
                          onDragStart={e => {
                            e.dataTransfer.effectAllowed = "move";
                            setTimeout(() => setSourceIndex(index), 1);
                            setTimeout(() => setSourceElement(dndLessons[index]), 1);
                          }}
                          onDragEnd={e => {
                            e.preventDefault();
                            setSourceIndex(undefined);
                            setSourceElement(undefined);
                          }}
                          $focus={index === sourceIndex}
                        >
                          <ItemWrapper $hide={index === sourceIndex} $isDragging={isDragging} $editable={editable}>
                            <UserCourseLessonItem
                              newlyAdded={newlyAddedLessons.includes(lesson.id)}
                              key={lesson.id}
                              lesson={lesson}
                              onDelete={() => {
                                onDeleteLesson(lesson.id);
                              }}
                              editable={editable}
                            />
                          </ItemWrapper>
                        </ItemRoot>
                      );
                    })}
                  </>
                </Lessons>
              </div>
            )}
          </div>
        </OuterMargin>
      </PanelBg>
    </>
  );
};

export default LessonsList;

const Lessons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PanelBg = styled.div`
  padding: 16px 0;
`;

const DropOverlay = styled.div<{ $isDragging: boolean }>`
  position: absolute;
  z-index: ${props => (props.$isDragging ? 1 : 0)};
  pointer-events: ${props => (props.$isDragging ? "inherit" : "none")};
  width: calc(100% - 64px);
  max-width: 932px;
  transform: ${Math.round(ITEM_HEIGHT / 2)}px;
`;

const DropZone = styled.div<{ $editable: boolean }>`
  flex-grow: 1;
  height: ${Math.round(ITEM_HEIGHT + 16)}px;
  &:hover {
    cursor: ${props => (props.$editable ? "ns-resize" : "inherit")};
  }
`;

const ItemWrapper = styled.div<{ $isDragging: boolean; $hide: boolean; $editable: boolean }>`
  height: ${ITEM_HEIGHT}px;
  pointer-events: ${props => (props.$isDragging ? "none" : "inherit")};
  opacity: ${props => (props.$hide ? 0 : 1)};
  &:hover {
    cursor: ${props => (props.$editable ? "ns-resize" : "inherit")};
    box-shadow: ${props => (props.$editable ? "0 2px 7px 0 rgba(0, 0, 0, 0.2)" : "inherit")};
  }
`;

const ItemRoot = styled.div<{ $focus: boolean }>`
  background-color: ${props => (props.$focus ? props.theme.colors.greys.light1 : "inherit")};
  margin-left: 32px;
`;

const Index = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
  transform: translateY(-8px);
  color: #6f7f93;
`;

const DropRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftSpace = styled.div`
  margin-left: 32px;
`;
