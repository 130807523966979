import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import type { Resolver } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Goal } from "api/models/Goal";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import { ErrorWrapper } from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/helpers";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Col from "shared/atoms/Col";
import Dropdown from "shared/atoms/inputs/Dropdown";
import { StyledLabel } from "shared/atoms/inputs/StyledInputs";
import TextArea from "shared/atoms/inputs/TextArea";
import { Notification } from "shared/atoms/Notification";
import Row from "shared/atoms/Row";
import { setGoal, updateGoal } from "shared/molecules/PatientGoal/queries/setGoal";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import isFiniteNumber from "utils/number/is-finite-number";

import generateDropdownValues from "./utils/generateDropdownValues";

export type FormData = {
  free_text: string | null;
  pain: string | null;
  functionality: string | null;
  feeling: string | null;
};

export const validateForm: Resolver<FormData> = data => {
  const values = Object.entries(data).reduce((currentValues, [key, value]) => {
    if (value === undefined || value === "") {
      return { ...currentValues, [key]: null };
    }
    if (key !== "free_text" && value) {
      const parsedValue = parseInt(value, 10);
      const isNaN = Number.isNaN(parsedValue);
      const updatedValue = isNaN ? null : value;
      return { ...currentValues, [key]: updatedValue };
    }
    return { ...currentValues, [key]: value };
  }, {});
  return {
    values,
    errors: {},
  };
};

type GoalFormData = {
  id?: number;
  description?: string | "-" | null;
  functionality?: string | "-" | null;
  pain?: string | "-" | null;
  feeling?: string | "-" | null;
};

interface Props {
  id: number;
  onCancel?: () => void;
  isPhysicalFunctionAvailable: () => boolean;
  isFeelingAvailable: () => boolean;
  isPainAvailable: () => boolean;
  onSuccess: (data: Partial<Goal>) => void;
  currentGoal: GoalFormData;
}

const setOrUpdateGoal = (id: number, data: FormData, goalId: number | undefined) => {
  if (!goalId) return setGoal(id, data);
  return updateGoal(id, data, goalId);
};

const PatientGoal: React.VFC<Props> = ({
  id,
  onCancel,
  onSuccess,
  currentGoal,
  isPhysicalFunctionAvailable,
  isFeelingAvailable,
  isPainAvailable,
}) => {
  const { t } = useTranslation();
  const getValuesRef = useRef<null | ((payload: string | string[]) => string | FormData)>(null);
  const { patient } = useContext(CurrentPatientContext);
  const { savedGoals, setSavedGoals } = useSavedInputsContext();
  const [goalState, setGoalState] = useState({ loading: false, error: false, success: false });
  const [allFieldsEmptyError, setAllFieldsEmptyError] = useState(false);

  useEffect(() => {
    return () => {
      if (getValuesRef.current) {
        const pain = getValuesRef?.current("pain") || "";
        const functionality = getValuesRef?.current("functionality") || "";
        const free_text = getValuesRef?.current("free_text") || "";
        const feeling = getValuesRef?.current("feeling") || "";

        if (
          pain !== currentGoal?.pain ||
          functionality !== currentGoal?.functionality ||
          free_text !== currentGoal?.description ||
          feeling !== currentGoal?.feeling
        ) {
          // TODO: remove this once Router is refactored to ts
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setSavedGoals((goals: Record<string, Partial<Goal>>) => ({
            ...goals,
            [patient?.id]: {
              pain,
              functionality,
              free_text,
              feeling,
            },
          }));
        } else {
          // TODO: remove this once Router is refactored to ts
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setSavedGoals((all: any) => {
            // TODO: Fix linting
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const goals = { ...all };
            delete goals[patient.id];
            return goals;
          });
        }
      }
    };
  }, []);

  const { register, handleSubmit, getValues } = useForm<FormData>({
    resolver: validateForm,
    defaultValues: {
      free_text: savedGoals[patient.id] ? savedGoals[patient.id]?.free_text : currentGoal?.description || "",
    },
  });

  useEffect(() => {
    getValuesRef.current = getValues;
  });

  const onSubmit = async (data: FormData) => {
    const allNull = Object.values(data).every(value => value === null);
    if (allNull) {
      setAllFieldsEmptyError(true);
    } else {
      try {
        setGoalState({ loading: true, error: false, success: false });
        const {
          data: { free_text, functionality, pain, id: goalId, feeling },
        } = await setOrUpdateGoal(id, data, currentGoal?.id);
        AnalyticsService.track(AnalyticsEvents.PATIENTS.UPDATE_GOAL, {
          pain,
          functionality,
          patient: id,
          goalDescription: free_text,
          feeling,
        });
        setGoalState({ loading: false, error: false, success: true });
        onSuccess({ free_text, functionality, pain, id: goalId, feeling });
      } catch (err) {
        setGoalState({ loading: false, error: true, success: false });
      }
    }
  };

  const selectedPainValue = resolvePainValue(savedGoals[patient.id]?.pain, currentGoal?.pain);
  const selectedFunctionalityValue = resolveFunctionalityValue(
    savedGoals[patient.id]?.functionality,
    currentGoal?.functionality
  );
  const selectedFeelingValue = resolveFeelingValue(savedGoals[patient.id]?.feeling, currentGoal?.feeling);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <StyledCol flex={3}>
          <Label htmlFor="free_text">{t("dashboard.tables.calls.goal.description")}</Label>
          <TextArea dataTestId="free_text" {...register("free_text")} rows={2} maxLength={300} />
        </StyledCol>
        <Col flex={1}>
          {isPainAvailable() && (
            <Col className="dropdown_wrapper">
              <Label>{t("dashboard.tables.calls.goal.pain")}</Label>
              <Dropdown {...register("pain")} m="0" options={generateDropdownValues(10)} selected={selectedPainValue} />
            </Col>
          )}
          {isPhysicalFunctionAvailable() && (
            <Col className="dropdown_wrapper">
              <Label>{t("dashboard.tables.calls.goal.function")}</Label>
              <Dropdown
                {...register("functionality")}
                m="0"
                options={generateDropdownValues(35)}
                selected={selectedFunctionalityValue}
              />
            </Col>
          )}
          {isFeelingAvailable() && (
            <Col className="dropdown_wrapper">
              <Label>{t("dashboard.tables.calls.goal.feeling")}</Label>
              <Dropdown
                {...register("feeling")}
                m="0"
                options={generateDropdownValues(100)}
                selected={selectedFeelingValue}
              />
            </Col>
          )}
        </Col>
      </Container>
      <ButtonContainer>
        <CancelButton onClick={onCancel} type="button" data-testid="cancel_patient_goal">
          {t("buttons.cancel")}
        </CancelButton>
        <StyledButton
          success={goalState.success}
          disabled={goalState.loading}
          data-testid="submit_patient_goal"
          type="submit"
        >
          {t("buttons.save")}
        </StyledButton>
      </ButtonContainer>
      {allFieldsEmptyError && (
        <ErrorWrapper>
          <StyledNotification variant="danger">{t("errors.all_empty_goal_fields")}</StyledNotification>
        </ErrorWrapper>
      )}
    </StyledForm>
  );
};

export default PatientGoal;

const resolvePainValue = (
  savedGoalPain: null | number | undefined,
  currentGoalPain: null | string | undefined
): string | undefined => {
  if (isFiniteNumber(savedGoalPain)) {
    return String(savedGoalPain);
  }
  return currentGoalPain ?? undefined;
};

const resolveFunctionalityValue = (
  savedGoalFunctionality: null | number | undefined,
  currentGoalFunctionality: null | string | undefined
): string | undefined => {
  if (isFiniteNumber(savedGoalFunctionality)) {
    return String(savedGoalFunctionality);
  }
  return currentGoalFunctionality ?? undefined;
};

const resolveFeelingValue = (
  savedGoalFeeling: null | number | undefined,
  currentGoalFeeling: null | string | undefined
): string | undefined => {
  if (isFiniteNumber(savedGoalFeeling)) {
    return String(savedGoalFeeling);
  }
  return currentGoalFeeling ?? undefined;
};

const StyledForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${props => props.theme.belowMobileBreakpoint} {
    padding: 0;
  }
`;

const StyledNotification = styled(Notification)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  & .dropdown_wrapper {
    width: 200px;
    :first-of-type {
      margin-bottom: ${props => props.theme.spacing.S_20};
    }
  }

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    & .dropdown_wrapper {
      margin-top: ${props => props.theme.spacing.S_5};
    }
    & .dropdown_wrapper:first-of-type {
      margin-bottom: 0;
    }
  }
`;

const ButtonContainer = styled(Row)`
  padding-top: ${props => props.theme.spacing.S_20};
  flex-direction: row;
  justify-content: flex-end;

  & button {
    height: 50px;
  }

  ${props => props.theme.belowBreakpoint} {
    align-items: center;
    justify-content: flex-start;
  }
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const StyledCol = styled(Col)`
  margin-right: ${props => props.theme.spacing.S_20};

  ${props => props.theme.belowBreakpoint} {
    margin-right: 0;
  }
`;

const Label = styled(StyledLabel)`
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const CancelButton = styled(TextButton)`
  margin-right: ${props => props.theme.spacing.S_20};
  border-width: 2px;
  ${props => props.theme.belowMobileBreakpoint} {
    margin-right: 0;
  }
`;

const StyledButton = styled(PrimaryButton)<{ success?: boolean }>`
  margin-left: ${props => (props.success ? props.theme.spacing.S_20 : 0)};

  ${props => props.theme.belowBreakpoint} {
    margin-top: ${props => (props.success ? props.theme.spacing.S_20 : 0)};
  }
`;
