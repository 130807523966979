import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import { useProfileContext } from "contexts/ProfileContext";

export type UsTherapistLicense = {
  id: number;
  code: string;
  name: string;
  license_number: string;
  expiration_date: Date;
};

export type UsTherapistLicensesHook = {
  usTherapistLicenses: UsTherapistLicense[] | undefined;
  refetch: () => void;
  isLoading: boolean;
  error: boolean;
};

const useUsTherapistLicenses = (): UsTherapistLicensesHook => {
  const { profile } = useProfileContext();

  const { data, isLoading, error, refetch } = useQuery(
    [],
    async () => {
      return (await AxiosInstance.get(`therapists/${profile?.id}/us_therapist_licenses`)).data;
    },
    { refetchOnWindowFocus: false }
  );

  return {
    usTherapistLicenses: data as undefined | UsTherapistLicense[],
    refetch,
    isLoading,
    error: error as boolean,
  };
};

export default useUsTherapistLicenses;
