import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "shared/atoms/Button";

import { BOOKING_SCREENS } from "../../AdminBookingForm";
import { Container } from "../../misc";

interface Props {
  submitIntroductionHealthReport: (value: boolean) => void;
}

export const PhysicalExaminationScreen: React.VFC<Props> = ({ submitIntroductionHealthReport }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = (physicalExamination: boolean) => {
    submitIntroductionHealthReport(physicalExamination);
    navigate(`../../${BOOKING_SCREENS.SELECT_DATE_AND_PERSONNEL}`);
  };

  return (
    <Container>
      <PrimaryButton onClick={() => onClick(true)} style={{ marginBottom: "16px" }}>
        {t("buttons.yes")}
      </PrimaryButton>
      <PrimaryButton onClick={() => onClick(false)}>{t("buttons.no")}</PrimaryButton>
    </Container>
  );
};
