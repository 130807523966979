import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Counter from "./Counter";
import { SmallPrimaryButton } from "./SharedStyledComponents";

interface Props {
  level: number;
  onLevelChange: (level: number) => void;
  maxLevel: number;
  showAddButton: boolean;
  onAddExercise: (level: number) => void;
}

const ExerciseAddRow: React.VFC<Props> = ({ maxLevel, showAddButton, onAddExercise, level, onLevelChange }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Label>{t("usercourse.level")}</Label>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Counter value={level} maxValue={maxLevel} minValue={1} clickCallback={onLevelChange} />

        {showAddButton && (
          <SmallPrimaryButton
            onClick={() => {
              onAddExercise(level);
            }}
          >
            {t("buttons.add")}
          </SmallPrimaryButton>
        )}
      </div>
    </Wrapper>
  );
};

export default ExerciseAddRow;

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.redesign.db60};
  font-size: 12px;
  line-height: 16px;
`;
