import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import useApiClient from "api/useApiClient";

type Args = { patientId: number };

export const usePostMarkTreatmentReady = (
  args: Args,
  options?: UseMutationOptions<null, Error, Args>
): UseMutationResult<null, Error, Args> => {
  const client = useApiClient();

  return useMutation(({ patientId }) => {
    return client.postMarkTreatmentReady(patientId);
  }, options);
};
