import { useMutation } from "react-query";

import useApiClient from "api/useApiClient";

export type DeleteAppointmentArgs = {
  hcProfId: number;
  appointmentId: number;
};

export const useDeleteAppointment = () => {
  const client = useApiClient();

  return useMutation<null, Error, DeleteAppointmentArgs>({
    mutationFn: args => client.deleteAppointment(args),
  });
};
