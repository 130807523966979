import type React from "react";
import { useContext, useEffect, useState } from "react";

import { faShuffle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Treatment } from "api/types/Treatment";
import { DropdownIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Tag from "shared/atoms/Tag";
import theme from "theme";
import { CurrentPatientContext } from "utils/contexts";
import capitalize from "utils/string/capitalize";

import DropdownButton from "./components/DropdownButton";
import { TreatmentSwitch } from "./PatientDataList/TreatmentSwitch";

const getCurrentTreatment = (treatments: Treatment[]): Treatment => {
  return treatments.find(treatment => treatment.is_current_treatment) as Treatment;
};

const TreatmentDropdown: React.VFC = () => {
  const { profile } = useProfileContext();
  const { patient, previousTreatmentId, setPreviousTreatmentId } = useContext(CurrentPatientContext);
  const treatments = patient.treatments as Treatment[];
  const previousTreatment = treatments.find((tm: Treatment) => tm.id === previousTreatmentId);
  const [treatmentOptions, setTreatmentOptions] = useState<Treatment[]>(treatments);
  const [selectedOption, setSelectedOption] = useState<Treatment | null>(
    previousTreatment || getCurrentTreatment(treatments)
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [showTreatmentSwitch, setShowTreatmentSwitch] = useState(false);

  useEffect(() => {
    const sortedArray = patient.treatments
      .filter((treatment: Treatment) => treatment.id !== selectedOption?.id)
      .sort((a: Treatment, b: Treatment) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    setTreatmentOptions(sortedArray);

    if (previousTreatment) {
      setSelectedOption(previousTreatment);
    } else {
      setSelectedOption(getCurrentTreatment(treatments));
    }
  }, [patient]);

  useEffect(() => {
    if (selectedOption?.id) {
      if (!selectedOption.is_current_treatment) {
        setPreviousTreatmentId(selectedOption.id);
      } else {
        setPreviousTreatmentId(null);
      }
    }
  }, [selectedOption]);

  const hasPreviousTreatments = treatments.length > 1;

  return (
    <>
      <Label>{t("patients.header_info.treatment.header")}</Label>
      <Wrapper>
        <DropdownButton
          id="TreatmentDropdown"
          options={[
            ...treatmentOptions.map(option => {
              return {
                children: (
                  <OptionItem key={JSON.stringify(option)}>
                    <TreatmentOption option={option} showTag={hasPreviousTreatments} />
                  </OptionItem>
                ),
                onClick: () => {
                  setSelectedOption(option);
                  setMenuOpen(false);
                },
              };
            }),
            ...(profile?.market === "SE"
              ? [
                  {
                    children: (
                      <SwitchTreatmentOptionItem key="switch-treatment-option-item">
                        <div className="icon-container">
                          <FontAwesomeIcon
                            icon={faShuffle}
                            color={theme.colors.white}
                            className="icon"
                            style={{ height: "12px", width: "12px", padding: "4px 8px" }}
                          />
                        </div>
                        {t("switch_treatment.label")}
                      </SwitchTreatmentOptionItem>
                    ),
                    onClick: () => {
                      setShowTreatmentSwitch(true);
                    },
                  },
                ]
              : []),
          ]}
          onClick={() => {
            setMenuOpen(true);
          }}
          onClose={() => setMenuOpen(false)}
          padding={hasPreviousTreatments ? "8px 4px 8px 8px" : "12px"}
          dropdownListMinWidth="298px"
        >
          {selectedOption && (
            <OptionItemSelected key="selected-option" $hasPreviousTreatments={hasPreviousTreatments}>
              <TreatmentOption option={selectedOption} showTag={hasPreviousTreatments} />
            </OptionItemSelected>
          )}
          <DownChevron $isOpen={menuOpen} />
        </DropdownButton>
      </Wrapper>
      {showTreatmentSwitch && <TreatmentSwitch onClose={() => setShowTreatmentSwitch(false)} />}
    </>
  );
};

const TreatmentOption = ({ option, showTag }: { option: Treatment; showTag: boolean }) => {
  const { t } = useTranslation();

  const { ailment, lateral_location, pain_location } = option;

  const getTreatmentTranslation = () => {
    if (ailment === "fall_prevention") {
      return t("patients.ailments.fall_prevention");
    }

    if (ailment === "osteoporosis") {
      return t("patients.ailments.osteoporosis");
    }

    if (lateral_location === "both" && pain_location !== "neck" && pain_location !== "spine") {
      return capitalize(
        t("patients.pain_construct", {
          lateral: t(`patients.lateral_location.${lateral_location}`),
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          location: t(`patients.pain_location.${pain_location}.1`),
        })
      );
    }

    if (lateral_location === "undefined") {
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t(`patients.pain_location.${pain_location}.0`);
    }

    return capitalize(
      t("patients.pain_construct", {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lateral: t(`patients.lateral_location.${lateral_location}`),
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        location: t(`patients.pain_location.${pain_location}.0`),
      })
    );
  };

  return (
    <>
      {showTag && (
        <TreatmentTag
          bgColor={
            option.is_current_treatment
              ? theme.colors.treatmentSwitching.current
              : theme.colors.treatmentSwitching.previous
          }
          color={theme.colors.primary.base}
          borderColor="transparent"
          label={
            option.is_current_treatment
              ? t("patients.header_info.treatment.current")
              : t("patients.header_info.treatment.previous")
          }
        />
      )}
      {getTreatmentTranslation()}
    </>
  );
};

const OptionItemSelected = styled.div<{ $hasPreviousTreatments: boolean }>`
  display: ${props => props.$hasPreviousTreatments && "flex"};
  flex-grow: 1;
  align-items: center;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
`;

const TreatmentTag = styled(Tag)`
  margin-right: ${props => props.theme.spacing.S_10};
  border: none;
  height: 24px;
  line-height: 24px;
`;

const DownChevron = styled(DropdownIcon)<{ $isOpen: boolean }>`
  transform: ${props => props.$isOpen && "rotate(180deg)"};
  transition: 0.3s;
`;

const Wrapper = styled.div`
  margin: 12px 0;
  border-color: ${props => props.theme.colors.greys.silver};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
`;

const Label = styled.div`
  font-weight: 500;
  color: ${props => props.theme.colors.primary.base};
`;

const SwitchTreatmentOptionItem = styled(OptionItem)`
  font-weight: 500;
  justify-content: center;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.redesign.b100};
    border-radius: 4px;
    margin-right: 8px;
  }

  &:hover {
    .icon-container {
      background: ${props => props.theme.colors.white};
    }

    .icon {
      color: ${props => props.theme.colors.redesign.db80};
    }
  }
`;

export default TreatmentDropdown;
