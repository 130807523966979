import { z } from "zod";

export const ModeSchema = z.enum(["digital", "physical"]);

export const PurposeSchema = z.enum(["discharge_call", "follow_up", "general", "kick_off"]).nullable();

export type PurposeType = z.infer<typeof PurposeSchema>;

export const HcpServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  health_care_provider_id: z.number(),
  duration: z.number(),
  mode: ModeSchema,
  purpose: PurposeSchema,
  payment_methods: z.string().array().nullable(),
  opening_hours: z.number(),
  closing_hours: z.number(),
  no_earlier_than: z.number(),
  no_later_than: z.number(),
  capacity: z.number(),
  health_care_provider_service_category_id: z.number(),
  cents: z.number(),
  currency: z.string(),
  bookable_by_patients: z.boolean(),
});

export type HcpService = z.infer<typeof HcpServiceSchema>;

export const HcpServiceManagerSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  health_care_provider_id: z.number(),
  duration: z.number(),
  mode: ModeSchema,
  purpose: PurposeSchema,
  payment_methods: z.string().array().nullable(),
  opening_hours: z.number(),
  closing_hours: z.number(),
  no_earlier_than: z.number(),
  no_later_than: z.number(),
  capacity: z.number(),
  health_care_provider_service_category_id: z.number(),
  cents: z.number(),
  currency: z.string(),
  health_questionnaire_type: z.string(),
  health_care_professionals: z
    .object({
      id: z.number(),
      full_name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  bookable_by_patients: z.boolean(),
});

export type HcpServiceManager = z.infer<typeof HcpServiceManagerSchema>;

export const ServiceTemplateSchema = z.object({
  health_care_provider_service_categories: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  health_care_professional_ids: z
    .object({
      id: z.number(),
      full_name: z.string(),
    })
    .array(),
  mode: z.string().array(),
  purpose: z.string().array(),
  payment_methods: z.string().array(),
  duration: z.number().array(),
});

export type ServiceTemplateField = "mode" | "purpose" | "payment_methods" | "duration";

export type ServiceTemplate = z.infer<typeof ServiceTemplateSchema>;
