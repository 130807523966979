import type React from "react";
import { useState } from "react";

import type { SelectProps } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { MUIBorder, MUIErrorText, MUIInput, MUITextInputLabel } from "../StyledInputs";

const menuProps = {
  style: {
    maxHeight: 460,
  },
};

const MUIDropdownInForm: React.VFC<Props> = ({
  className,
  error,
  label,
  name,
  options,
  renderItem,
  disabled,
  required,
  minWidth = 116,
  onChange,
  defaultValue = "",
}) => {
  const { control } = useFormContext();

  return (
    <Container className={className}>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledFormControl variant="outlined" disabled={disabled} fullWidth>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <StyledSelect
              $minWidth={minWidth}
              error={!!error}
              labelId={`${name}-label`}
              data-testid={`dropdown-${name}`}
              value={field.value ?? defaultValue}
              onChange={event => {
                field.onChange(event);
                if (onChange) onChange(event);
              }}
              MenuProps={menuProps}
              renderValue={() => {
                return <>{options.find(option => option.value === field.value)?.label}</>;
              }}
            >
              {options.map(option => (
                <MenuItem
                  key={option.value}
                  disabled={option.disabled}
                  value={option.value}
                  data-testid={`dropdown-option-${option.value}`}
                >
                  {renderItem ? renderItem(option) : `${option.label} ${option.customLabel ? option.customLabel : ""}`}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        )}
      />
      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

const MUIDropdownNoForm: React.VFC<Props> = ({
  className,
  error,
  label,
  name,
  options,
  renderItem,
  disabled,
  minWidth = 116,
  value,
  onChange,
  defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<unknown>(value || options[0].value);

  return (
    <Container className={className}>
      <StyledFormControl variant="outlined" disabled={disabled} fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <StyledSelect
          $minWidth={minWidth}
          error={!!error}
          labelId={`${name}-label`}
          data-testid={`dropdown-${name}`}
          MenuProps={menuProps}
          value={selectedValue ?? defaultValue}
          onChange={event => {
            setSelectedValue(event.target.value);
            if (onChange) onChange(event);
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              disabled={option.disabled}
              value={option.value}
              data-testid={`dropdown-option-${option.value}`}
            >
              {renderItem ? renderItem(option) : option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>

      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

const MUIDropdown: React.VFC<Props> = props => {
  const { in_form = true } = props;
  if (in_form) {
    return <MUIDropdownInForm {...props} />;
  }
  return <MUIDropdownNoForm {...props} />;
};

const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    .MuiSelect-root {
      padding: 23px 12px 7px;
    }
  }
  ${MUIInput}
  ${MUIBorder}
  ${MUITextInputLabel}
`;

const StyledSelect = styled(Select)<{ $minWidth: number }>`
  .MuiSelect-root {
    background-color: white;
    min-width: ${props => `${props.$minWidth}px`};
  }
`;

const Container = styled.div`
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
`;

const ErrorText = styled.div`
  margin-top: 4px;
  ${MUIErrorText}
`;

export interface Option {
  label: string;
  value: string | number;
  customLabel?: string;
  disabled?: boolean;
}

export interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly in_form?: boolean;
  readonly options: Array<Option>;
  readonly className?: string;
  readonly minWidth?: number;
  readonly value?: string | number;
  readonly renderItem?: (option: Option) => JSX.Element;
  readonly onChange?: (event: Parameters<NonNullable<SelectProps["onChange"]>>[0]) => void;
  readonly defaultValue?: string;
}

export default MUIDropdown;
