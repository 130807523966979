import type { Location } from "react-router-dom";

interface SignedOutParams {
  redirectUrl: string | null;
  signedOutError: string | null;
}

const getSignedOutParams = (location: Location): SignedOutParams => {
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");
  const signedOutError = searchParams.get("error");

  return { redirectUrl, signedOutError };
};

export default getSignedOutParams;
