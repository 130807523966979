import type React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";

import { ChevronRightIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Avatar from "shared/atoms/Avatar";
import Row from "shared/atoms/Row";
import NavBarDropdown from "shared/NavBar/components/NavBarDropdown";

interface Props {
  collapsed: boolean;
  isOpenSideBar: boolean;
  setOpenSideBar: (isOpenSideBar: boolean) => void;
}

const Extras: React.VFC<Props> = ({ collapsed, isOpenSideBar, setOpenSideBar }) => {
  const [isOpen, setOpen] = useState(false);
  const { profile } = useProfileContext();
  const name = `${profile?.first_name} ${profile?.last_name}`;

  useEffect(() => {
    if (isOpenSideBar === false) {
      setOpen(false);
    }
  }, [isOpenSideBar]);

  return (
    <Container>
      <NavBarDropdownButton
        onClick={event => {
          event.stopPropagation();
          setOpen(!isOpen);
        }}
      >
        <RowWrapper data-testid="header-dropdown-button" align="center">
          <AvatarWrapper $collapsed={collapsed}>
            <Avatar id={profile?.id} size={32} src={profile?.avatars?.medium || ""} name={name} />
          </AvatarWrapper>
          {!collapsed && (
            <>
              <Name>{name}</Name>
              <Chevron $isOpen={isOpen} />
            </>
          )}
        </RowWrapper>
      </NavBarDropdownButton>
      <ClickWrapper
        onClick={event => {
          event.stopPropagation();
          setOpenSideBar(false);
        }}
      >
        <NavBarDropdown open={isOpen} collapsedNavBar={collapsed} onClose={() => setOpen(false)} />
      </ClickWrapper>
    </Container>
  );
};

export default Extras;

const Container = styled.div`
  position: sticky;
  ${props => props.theme.belowBreakpoint} {
    bottom: 0px;
  }
`;

const NavBarDropdownButton = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background: #fff;
  width: 100%;
  bottom: 0px;
  z-index: 99;
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
`;

const AvatarWrapper = styled.div<{ $collapsed: boolean }>`
  padding-left: ${props => !props.$collapsed && "0px"};
`;

const RowWrapper = styled(Row)`
  justify-content: center;
  min-height: 40px;
  padding: 8px 12px;
`;

const Name = styled.div`
  flex: 1;
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Chevron = styled(ChevronRightIcon)<{ $isOpen: boolean }>`
  padding: 8px;
  transform: ${props => (props.$isOpen ? "rotate(90deg)" : "rotate(-90deg)")};
  transition: 0.3s;
`;

const ClickWrapper = styled.div``;
