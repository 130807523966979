import type { MostPainfulJointLocation } from "api/schemas/patients/HealthJournal";

export const hasLateralLocation = (joint?: MostPainfulJointLocation) => {
  switch (joint) {
    case "shoulder":
    case "elbow":
    case "wrist":
    case "hand":
    case "hip":
    case "knee":
    case "lower_leg":
    case "foot":
      return true;
    case "neck":
    case "lower_back":
      return false;
    default:
      return false;
  }
};
