import React from "react";

import styled from "styled-components";

const StyledInput = styled.input<{ label?: string }>`
  display: none;
`;

const StyledLabel = styled.label<{ checked?: boolean; reverse?: boolean; disabled?: boolean }>`
  cursor: pointer;
  font-size: 18px;
  line-height: 30px;
  font-weight: ${props => (props?.checked ? props.theme.fontWeight.medium : props.theme.fontWeight.regular)};
  color: ${props => (props?.checked ? props.theme.colors.primary.base : props.theme.colors.greys.dark)};
  ${props => props.disabled && `color: ${props.theme.colors.greys.silver};`}
  display: flex;
  flex-direction: ${props => (props?.reverse ? "row-reverse" : "row")};
  justify-content: ${props => (props?.reverse ? "flex-end" : "flex-start")};
  align-items: center;
  white-space: nowrap;
  &:hover {
    & > div {
      border-color: ${props => !props.disabled && props.theme.colors.primary.base};
    }
  }
`;

const RadioContainer = styled.div<{ checked?: boolean; error?: boolean; disabled?: boolean }>`
  position: relative;
  box-sizing: border-box;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid ${props => (props?.checked ? props.theme.colors.primary.base : props.theme.colors.greys.silver)};
  ${props => props.disabled && `border: 2px solid ${props.theme.colors.greys.silver};`}
  ${props => props?.error && `border-color: ${props.theme.colors.tomato}`};
  border-radius: ${props => props.theme.borderRadius.circular};
  background: ${props => props.theme.colors.white};
  margin-right: ${props => props.theme.spacing.S_5};
`;

const RadioCheck = styled.div<{ disabled?: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background: ${props => (props.disabled ? props.theme.colors.greys.silver : props.theme.colors.primary.base)};
  border-radius: ${props => props.theme.borderRadius.circular};
`;

interface RadioButtonProps {
  className?: string;
  errors?: Record<string, unknown>;
  name: string;
  value: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string;
  reverse?: boolean;
  disabled?: boolean;
}

const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const { className, errors, name, value, label, onChange, selected, reverse, disabled } = props;
  const CHECKED = selected === value;
  const hasError = !!errors?.[name];

  return (
    <StyledLabel className={className} reverse={reverse} checked={CHECKED} disabled={disabled}>
      <StyledInput
        ref={ref}
        onChange={event => {
          if (!disabled) onChange(event);
        }}
        type="radio"
        name={name}
        value={value}
        label={label}
        disabled={!CHECKED ? disabled : false}
      />
      <RadioContainer data-testid="radio-button" error={hasError} checked={CHECKED} disabled={disabled}>
        {CHECKED && <RadioCheck data-testid="checked-button" disabled={disabled} />}
      </RadioContainer>
      {label}
    </StyledLabel>
  );
});

export default RadioButton;
