import type { MedicalNoteType } from "types";

export const isKickOffNote = (label: MedicalNoteType): boolean => {
  return label.toUpperCase() === "PROTOCOL_WEEKLY_W0";
};

export const isChatNote = (label: MedicalNoteType): boolean => {
  return label.toUpperCase() === "CHAT";
};

export const isRTMInitial = (label: MedicalNoteType): boolean => {
  return label.toUpperCase() === "RTM_INITIAL";
};
