import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";
import type { UserRole } from "api/models/User";
import { getRestHeaders } from "utils/cookie";

interface Data {
  [key: string]: unknown;
  state: string;
  patient_profile?: unknown;
  locale: string;
  id: number;
  roles: UserRole[];
}

const getUserData = async (): Promise<AxiosResponse<{ data: Data }>> => {
  // small speed improvement that avoids unnecessary requests
  if (!getRestHeaders()) throw new Error("Not logged in.");
  return AxiosInstance.get("/me");
};

export default getUserData;
