import React from "react";

import styled from "styled-components";

import { BaseBlueCheckmarkIcon } from "assets";

interface CheckBoxProps {
  className?: string;
  errors?: Record<string, unknown>;
  name: string;
  value?: string;
  label: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reverse?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  noHighlight?: boolean; // prevents font-weight change on checked
}

const CheckBox = React.forwardRef<HTMLInputElement, CheckBoxProps>((props, ref) => {
  const {
    className,
    errors,
    name,
    value,
    label,
    onChange,
    checked = false,
    reverse = false,
    disabled = false,
    children = "",
    noHighlight = false,
  } = props;
  const hasError = !!errors?.[name];

  return (
    <StyledLabel
      data-testid={`checkbox-${name}`}
      error={hasError}
      className={className}
      reverse={reverse}
      checked={checked}
      disabled={disabled}
      noHighlight={noHighlight}
    >
      <StyledInput
        data-testid={`checkbox-${name}-input`}
        ref={ref}
        onChange={event => {
          if (!disabled) onChange(event);
        }}
        type="checkbox"
        name={name}
        value={value}
        label={label}
        checked={checked}
        disabled={!checked ? disabled : false}
      />
      <StyledCheckbox checked={checked} label={label} reverse={reverse} error={hasError}>
        <StyledIcon checked={checked} />
      </StyledCheckbox>
      {label}
      {children}
    </StyledLabel>
  );
});

export default CheckBox;

const StyledInput = styled.input<{ label: string }>`
  display: none;
`;

const StyledLabel = styled.label<{
  checked: boolean;
  reverse: boolean;
  error?: boolean;
  disabled: boolean;
  noHighlight: boolean;
}>`
  display: flex;
  ${props => props.theme.font.body2}
  font-weight: ${props => props?.checked && !props?.noHighlight && "400"};
  color: ${props => (props?.checked ? props.theme.colors.primary.base : props.theme.colors.greys.dark)};
  flex-direction: ${props => (props?.reverse ? "row-reverse" : "row")};
  justify-content: ${props => (props?.reverse ? "flex-end" : "flex-start")};
  align-items: center;
  white-space: nowrap;
  user-select: none;
  &:hover {
    & > div {
      ${props => !props.disabled && `border-color: ${props.theme.colors.redesign.b100};`}
    }
  }
  ${props => props.theme.belowBreakpoint} {
    white-space: normal;
  }
  opacity: ${props => props.disabled && "0.5"};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  error: boolean;
  reverse: boolean;
  label: string;
}>`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  padding: 0 3px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  border: 2px solid ${props => (props.checked ? props.theme.colors.redesign.b100 : props.theme.colors.greys.silver)};
  ${props => props?.error && `border-color: ${props.theme.colors.tomato}`};
  margin: ${props => (props?.reverse ? `0 0 0 8px` : `0 8px 0 0;`)};
`;

const StyledIcon = styled(BaseBlueCheckmarkIcon)<{ checked: boolean }>`
  width: 100%;
  height: 100%;
  transform: ${props => (props.checked ? "scale(1)" : "scale(0)")};
  transition: transform 300ms;
`;
