import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.redesign.db90};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
`;

export const Header = styled.header`
  padding: 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.redesign.db20};
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
  margin: 4px 0 12px 0;
`;
