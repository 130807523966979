/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

import type { LocalTrackPublication, Participant, RemoteParticipant, RemoteTrackPublication } from "twilio-video";

type Publication<T> = T extends RemoteParticipant ? RemoteTrackPublication : LocalTrackPublication;

export default function usePublications<T extends Participant>(participant: T | null): Publication<T>[] {
  const [publications, setPublications] = useState<Publication<T>[]>([]);

  useEffect(() => {
    const updatePublicationsState = () => {
      if (participant) {
        setPublications([...(Array.from(participant.tracks.values()) as Publication<T>[])]);
      }
    };

    if (participant) {
      updatePublicationsState();

      participant.on("trackDisabled", updatePublicationsState);
      participant.on("trackEnabled", updatePublicationsState);
      participant.on("trackPublished", updatePublicationsState);
      participant.on("trackUnpublished", updatePublicationsState);

      return () => {
        participant.off("trackDisabled", updatePublicationsState);
        participant.off("trackEnabled", updatePublicationsState);
        participant.off("trackPublished", updatePublicationsState);
        participant.off("trackUnpublished", updatePublicationsState);
      };
    }
  }, [participant]);

  return publications;
}
