import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { isTranslatedActivity } from "routes/patients/utils/activityHelpers";
import ActivityTitle from "shared/atoms/ActivityTitle";
import RelativeDatetime from "shared/atoms/RelativeDatetime";

type ActivityTypes = "exercise" | "lesson" | "functionality" | "report";

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.library};
  margin-bottom: ${props => props.theme.spacing.S_20};
  padding-bottom: ${props => props.theme.spacing.S_10};
`;

const Comment = styled.div<{ $type: ActivityTypes }>`
  margin: 0 ${props => props.theme.spacing.S_10};
  background-color: ${props => props.theme.colors[props.$type].background || props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.library};
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.greys.bluish};
  padding: ${props => props.theme.spacing.S_15};
`;

const Date = styled.div`
  color: ${props => props.theme.colors.greys.gunmetal};
  ${props => props.theme.font.caption}
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.header5}
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const StyledDiv = styled.div`
  padding: ${props => props.theme.spacing.S_15};
  padding-bottom: 0;
`;

interface Props {
  date?: string | null;
  comment: string;
  activity: {
    title: string;
    levels?: number;
    current?: number;
    type: ActivityTypes;
  };
}

const CommentCard: React.VFC<Props> = ({ date, comment, activity }) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="comment-card">
      <StyledDiv>
        <ActivityTitle
          size="small"
          type={activity?.type}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title={isTranslatedActivity(activity?.type) ? activity?.title : t(activity?.title)}
          levels={activity?.type === "exercise" ? { current: activity?.current || 1, max: activity.levels || 1 } : null}
        />
      </StyledDiv>
      <Comment $type={activity?.type}>
        {date && (
          <Date data-testid="date">
            <RelativeDatetime capitalize dateString={date} />
          </Date>
        )}
        <Title>{t("messages.comment_title")}</Title>
        {comment}
      </Comment>
    </Container>
  );
};

export default CommentCard;
