import styled from "styled-components";

const DayListWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  ${props => props.theme.belowLargeBreakpoint} {
    background-color: ${props => props.theme.colors.white};
    margin-top: ${props => props.theme.spacing.S_20};
  }
  ${props => props.theme.aboveLargeBreakpoint} {
    border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
    background-color: ${props => props.theme.colors.greys.light4};
  }
`;

const DayList = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1 1 1110px;
  max-width: 1090px;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.spacing.S_20};
`;

const DayTabs = styled(DayList)`
  max-width: 970px;
  padding: 0 ${props => props.theme.spacing.S_15};
`;

const DayItem = styled.div<{ current: boolean }>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  ${props => props.theme.font.link3}
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.primary.base};
  ${props => props.current && `background-color: ${props.theme.colors.greys.light1};`}
`;

const DayTab = styled(DayItem)<{ selected: boolean }>`
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-right: 0;
  cursor: pointer;
  ${props => props.selected && `border-bottom: 2px solid ${props.theme.colors.primary.base};`}
  &:first-child {
    border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
    border-top-left-radius: ${props => props.theme.borderRadius.basic};
  }
  &:last-child {
    border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
    border-top-right-radius: ${props => props.theme.borderRadius.basic};
    border-right: 1px solid ${props => props.theme.colors.greys.silver};
  }
`;

const CalendarContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  padding: 0 ${props => props.theme.spacing.S_20};
  padding-bottom: ${props => props.theme.spacing.S_30};
`;

const CalendarColumn = styled.div<{ current: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  min-width: 135px;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.spacing.S_5};
  padding-top: ${props => props.theme.spacing.S_20};
  ${props => props.current && `background-color: ${props.theme.colors.greys.light4};`}
`;

export { DayListWrapper, DayList, DayTabs, DayItem, DayTab, CalendarContent, CalendarColumn };
