import type React from "react";
import { useState } from "react";

import { faCheck, faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import DropdownButton from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/components/DropdownButton";
import { userIsAdmin, userIsTherapist } from "utils/profile/profileHelper";

interface Props {
  className?: string;
}

export const Filters: React.VFC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { profile } = useProfileContext();
  const {
    showMyAvailability,
    setShowMyAvailability,
    showTherapistAvailability,
    setShowTherapistAvailability,
    showCanceledAppointments,
    setShowCanceledAppointments,
    showRescheduledAppointments,
    setShowRescheduledAppointments,
    showWeekends,
    setShowWeekends,
  } = useCalendarContext();
  const [showFilters, setShowFilters] = useState(false);

  invariant(profile);

  const isTherapist = userIsTherapist(profile);
  const isAdmin = userIsAdmin(profile);

  const filters = [
    ...(isTherapist
      ? [
          {
            children: (
              <Filter>
                <Icon>
                  {showMyAvailability && <StyledFontAwesomeIcon icon={faCheck} color={theme.colors.redesign.b100} />}
                </Icon>
                <div>{t("booking.calendar.filters.show_my_availability")}</div>
              </Filter>
            ),
            onClick: () => {
              setShowMyAvailability(!showMyAvailability);
            },
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            children: (
              <Filter>
                <Icon>
                  {showTherapistAvailability && (
                    <StyledFontAwesomeIcon icon={faCheck} color={theme.colors.redesign.b100} />
                  )}
                </Icon>
                <div>{t("booking.calendar.filters.show_therapist_availability")}</div>
              </Filter>
            ),
            onClick: () => {
              setShowTherapistAvailability(!showTherapistAvailability);
            },
          },
        ]
      : []),
    {
      children: (
        <Filter>
          <Icon>
            {showRescheduledAppointments && <StyledFontAwesomeIcon icon={faCheck} color={theme.colors.redesign.b100} />}
          </Icon>
          <div>{t("booking.calendar.filters.show_rescheduled_appointments")}</div>
        </Filter>
      ),
      onClick: () => {
        setShowRescheduledAppointments(!showRescheduledAppointments);
      },
    },
    {
      children: (
        <Filter>
          <Icon>
            {showCanceledAppointments && <StyledFontAwesomeIcon icon={faCheck} color={theme.colors.redesign.b100} />}
          </Icon>
          <div>{t("booking.calendar.filters.show_canceled_appointments")}</div>
        </Filter>
      ),
      onClick: () => {
        setShowCanceledAppointments(!showCanceledAppointments);
      },
    },
    {
      children: (
        <Filter>
          <Icon>{showWeekends && <StyledFontAwesomeIcon icon={faCheck} color={theme.colors.redesign.b100} />}</Icon>
          <div>{t("booking.calendar.filters.show_weekends")}</div>
        </Filter>
      ),
      onClick: () => {
        setShowWeekends(!showWeekends);
      },
    },
  ];

  return (
    <StyledDropdownButton
      id="calendar-filters"
      options={filters}
      border={false}
      onClick={() => setShowFilters(true)}
      onClose={() => setShowFilters(false)}
      $showFilters={showFilters}
      className={className}
    >
      <FontAwesomeIcon
        icon={faListCheck}
        color={showFilters ? theme.colors.redesign.b100 : theme.colors.redesign.db90}
        style={{ height: "16px", width: "16px", padding: "12px 16px" }}
      />
    </StyledDropdownButton>
  );
};

const StyledDropdownButton = styled(DropdownButton)<{ $showFilters: boolean }>`
  height: 40px;
  width: 48px;
  position: absolute;
  right: 0;
  background: #fff !important;
  border: 1px solid
    ${({ theme, $showFilters }) => ($showFilters ? theme.components.calendar.btn_bg : theme.colors.redesign.db30)} !important;
  border-radius: 8px;

  #DropdownButton {
    background: none;

    > svg {
      margin: 0;
    }
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.components.calendar.btn_bg} !important;

    #DropdownButton > svg > path {
      fill: ${({ theme }) => theme.colors.redesign.b100};
    }
  }

  ${props => props.theme.belowBreakpoint} {
    right: 8px;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  min-width: 16px;
  margin-right: 8px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
`;
