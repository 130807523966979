import type React from "react";

import styled from "styled-components";

interface ActivityDividerProps {
  title: string;
}

const ActivityDivider: React.VFC<ActivityDividerProps> = ({ title = "" }) => {
  return (
    <Container data-testid="activity-divider">
      <Title>{title}</Title>
    </Container>
  );
};

export default ActivityDivider;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 990px;
  margin: 36px 0 12px 0;
  padding-left: 12px;
  ${props => props.theme.belowBreakpoint} {
    text-align: center;
    padding: 0;
  }
`;

const Title = styled.span`
  display: block;
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.header2};
`;
