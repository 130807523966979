import type { AxiosResponse } from "axios";
import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import { QUERY_KEYS } from "types/QueryKeys";

import type { Conversation } from "../types";

interface GetConversationsResponse {
  unreadCount: number;
  conversations: Array<Conversation>;
}

interface GetConversationsQuery {
  response?: AxiosResponse<GetConversationsResponse>;
  error: unknown;
  isLoading: boolean;
  refetch: () => Promise<UseQueryResult>;
}

const getConversations = (profileId: number): Promise<AxiosResponse<GetConversationsResponse>> =>
  AxiosInstance.get(`/users/${profileId}/conversations?digest=true`);

const useGetConversations = (profileId: number): GetConversationsQuery => {
  const { data, error, isLoading, refetch } = useQuery([QUERY_KEYS.conversations], () => getConversations(profileId));
  return { response: data, error, isLoading, refetch };
};

export default useGetConversations;
