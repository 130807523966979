import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PhysioIcon } from "assets";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${props => props.theme.font.header3}
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.warm};
`;

const StyledImage = styled(PhysioIcon)`
  filter: grayscale(100%) opacity(50%);
  margin-bottom: ${props => props.theme.spacing.S_50};
`;

const ChatNotSelected: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledImage />
      {t("messages.no_convo_selected")}
    </Container>
  );
};

export default ChatNotSelected;
