import React, { Suspense } from "react";

import ReactDOM from "react-dom";
import styled from "styled-components";

import "./i18n";
import { AppConfigContextProvider } from "contexts/AppConfigContext";
import Spinner from "shared/atoms/Spinner";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_ENABLE_MOCK_SERVER) {
  import("./mocks/browser").then(worker => {
    worker.default.start();
  });
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

ReactDOM.render(
  <AppConfigContextProvider>
    <Suspense
      fallback={
        <Container>
          <Spinner />
        </Container>
      }
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </AppConfigContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
