import type React from "react";
import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Dropdown from "shared/atoms/inputs/Dropdown";
import type { Language, Market } from "types";
import { LanguageTypes } from "types";
import type { ValidLocale } from "types/validLocale";
import getLngDropdownOpts from "utils/locale/getLngDropdownOpts";
import { getStorageValue, setStorageValue } from "utils/storage";

export const LanguageDropdown: React.VFC = () => {
  // NOTE: remove the splitting when dropdown values match available locales for the region
  const defaultLng = (getStorageValue("language")?.split("-")[0] || LanguageTypes.en) as Language;

  const { i18n } = useTranslation();
  const { watch, register } = useForm();

  const languageValue = watch("languages");
  const REGION = i18n.language.split("-")[1] as Market;

  useEffect(() => {
    // NOTE: remove once the dropdown values match the available locales
    const locale = `${languageValue}-${REGION}`;
    if (languageValue && locale !== i18n.language) {
      setStorageValue("language", locale as ValidLocale);
      i18n.changeLanguage(locale);
    }
  }, [languageValue]);

  const dropdownOptions = getLngDropdownOpts(REGION, defaultLng);

  return (
    <>
      {dropdownOptions && (
        <StyledDropdown
          localiseLabels
          {...register("languages")}
          m="0 30px 0"
          selected={defaultLng}
          options={dropdownOptions}
        />
      )}
    </>
  );
};

const StyledDropdown = styled(Dropdown)`
  min-width: 130px;
  margin: 0 0 4px;
`;
