import { z } from "zod";

const RawQuickReplySchema = z.object({
  id: z.number(),
  text: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  user_id: z.number(),
});

export type RawQuickReply = z.infer<typeof RawQuickReplySchema>;

export const QuickReplySchema = RawQuickReplySchema.transform(({ created_at, updated_at, user_id, ...rest }) => ({
  createdAt: created_at,
  updatedAt: updated_at,
  userId: user_id,
  ...rest,
}));

export type QuickReply = z.infer<typeof QuickReplySchema>;
