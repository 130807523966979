import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { VoiceCallIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { useVoiceCallContext } from "contexts/VoiceCallContext";
import { CurrentPatientContext } from "utils/contexts";

import { Button } from "../../helpers";

interface Props {
  readonly inDropdown?: boolean;
}

const VoiceCallButton: React.VFC<Props> = ({ inDropdown }) => {
  const { profile } = useProfileContext();
  const { patient } = useContext(CurrentPatientContext);
  const { voiceCallData, setVoiceCallData } = useVoiceCallContext();
  const { callData: videoCallData } = useVideoCallContext();
  const { t } = useTranslation();

  return (
    <Button
      $justifyContent={inDropdown ? "flex-start" : ""}
      style={{ border: inDropdown ? "none" : "" }}
      data-testid="voice-call-button"
      disabled={!!voiceCallData || !!videoCallData}
      onClick={() => {
        invariant(profile);
        setVoiceCallData({
          caller: {
            id: profile.id,
          },
          callee: {
            id: patient.id,
            name: `${patient.first_name} ${patient.last_name}`,
          },
        });
      }}
    >
      <VoiceCallIcon />
      {t("buttons.voice_call")}
    </Button>
  );
};

export default VoiceCallButton;
