import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const IconLink = ({
  onClick,
  icon,
  title,
  disabled = false,
}: {
  onClick: () => void;
  icon: IconProp;
  title: string;
  disabled?: boolean;
}) => {
  return (
    <TopLink $disabled={disabled} onClick={() => !disabled && onClick()}>
      <FontAwesomeIcon icon={icon} />
      {title}
    </TopLink>
  );
};

export default IconLink;

const TopLink = styled.div<{ $disabled: boolean }>`
  color: ${props => (props.$disabled ? props.theme.colors.greys.gunmetal : props.theme.colors.redesign.b100)};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${props => (props.$disabled ? "default" : "pointer")};
`;
