import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import type { Therapist } from "api/models/Therapist";
import type { Appointment } from "routes/messages/types";
import useAnalytics from "utils/hooks/useAnalytics";
import getBestLng from "utils/locale";
import { userIsPartner } from "utils/profile/profileHelper";

import ProfileContext from "./ProfileContext";

const useProfileContextProvider = () => {
  const [profile, setProfile] = useState<Therapist | undefined>();
  const [scheduledCalls, setScheduledCalls] = useState<Appointment[]>([]);
  const { i18n } = useTranslation();
  const isPartner = userIsPartner(profile);

  useEffect(() => {
    if (profile?.locale) {
      i18n.changeLanguage(getBestLng(profile.locale));
    }
  }, [i18n, profile]);

  useAnalytics(profile?.id, isPartner);

  const value = {
    profile,
    setProfile,
    scheduledCalls,
    setScheduledCalls,
  };

  return value;
};

const ProfileContextProvider: React.FC = ({ children }) => {
  const value = useProfileContextProvider();
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export type ProfileContextValue = ReturnType<typeof useProfileContextProvider>;

export default ProfileContextProvider;
