interface Patient {
  address: string | null;
  adherence: number;
  appointment_to_schedule: string | null;
  birthday: string | null;
  city: string | null;
  country: string;
  deleted_at: string | null;
  email: string | null;
  first_name: string | null;
  goal: string;
  id: number;
  last_activity_at: string | null;
  last_name: string | null;
  payer_category: string | null;
  phone_number: string | null;
  prio: boolean;
  week: number;
  zip_code: string | null;
  pt_on_demand_enabled: boolean;
  therapist_assignment_role: string | null;
}

interface Payer {
  id: number;
  claim_order_type: string | null;
  copay_consent: boolean;
  copay_estimate_cents: number | null;
  immediate_referral_required: boolean | null;
  insurance_company_copay_consent: boolean;
  insurance_company_name: string;
  insurance_plan: string | null;
  latest_copay_cents: number | null;
  member_id: string | null;
  patient_id: number | null;
  pre_authorization_required: boolean | null;
  status: string | null;
  subscriber_info: Record<string, never> | null;
  us_insurance_company_id: number;
  video_call_required: boolean;
}

interface InsuranceData extends Payer {
  secondary_payer: Payer;
  insurance_company_supported: boolean;
}

export interface Insurance {
  insurance_found: boolean;
  data: InsuranceData;
}

interface Country {
  id: number;
  name: string;
}

interface SubscriptionPlan {
  id: number;
  cost_cents: number;
  currency: string;
  country: Country;
}

interface Subscription {
  patient_id: number;
  status: string;
  subscription_plan: SubscriptionPlan;
  upcoming_subscription_plan: SubscriptionPlan;
  id?: number;
  accumulated_discount_cents?: number;
  is_free_trial: boolean;
  expires_on?: string;
  started_on?: string;
  invoiced_at?: string;
}

interface UsProviderGroupProfile {
  patient_id: number;
  us_provider_group_clinic: {
    name: string;
  };
}

export interface PatientProfile {
  patient: Patient;
  insuranceData: Insurance;
  subscriptionData: Subscription;
  providerGroupData: UsProviderGroupProfile;
}

const getPrimaryInsurancePlan = (insuranceData: Insurance): string | null => {
  if (insuranceData?.insurance_found && insuranceData?.data?.insurance_plan) {
    return insuranceData.data.insurance_plan;
  }

  return null;
};

const getSecondaryInsurancePlan = (insuranceData: Insurance): string | null => {
  if (insuranceData?.insurance_found && insuranceData?.data?.secondary_payer?.insurance_plan) {
    return insuranceData.data.secondary_payer.insurance_plan;
  }

  return null;
};

export { getPrimaryInsurancePlan, getSecondaryInsurancePlan };
