import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";

import type { Patients } from "api/schemas/Patients";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getPatients";
type QueryKey = [typeof queryKeyPrefix, number];
export const getPatientsQueryKey = (userId: number): QueryKey => [queryKeyPrefix, userId];

const useGetPatients = (userId: number, options?: UseQueryOptions<Patients[], Error, Patients[], QueryKey>) => {
  const client = useApiClient();
  return useQuery(getPatientsQueryKey(userId), () => client.getPatients(userId), options);
};

export default useGetPatients;
