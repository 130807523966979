import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useRoutes } from "react-router-dom";
import invariant from "ts-invariant";

import { usePostPatientIntroductionHealthReport } from "api/hooks/useManagerBooking";
import type { AvailableJoints } from "api/schemas/ManagerBooking";
import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";
import { useProfileContext } from "contexts/ProfileContext";

import { Wrapper } from "../../misc";

import { JointScreen } from "./JointScreen";
import { LateralLocationScreen } from "./LateralLocationScreen";
import { PainScreen } from "./PainScreen";
import { PhysicalExaminationScreen } from "./PhysicalExaminationScreen";

export type JointType = Exclude<Joint, "jaw" | "spine" | "upper_back"> | "other";

export type JointItem = {
  icon?: React.ReactNode;
  label: string;
  value: JointType;
  hasLateralLocation: boolean;
};

export const HEALTH_QUESTIONNAIRE_SCREENS = {
  JOINT: "joint",
  LATERAL_LOCATION: "lateral_location",
  PAIN: "pain",
  PHYSICAL_EXAMINATION: "physical_examination",
} as const;

interface Props {
  patientId: number | undefined;
}

export const HealthQuestionnaire: React.VFC<Props> = ({ patientId }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const [joint, setJoint] = useState<JointItem | undefined>(undefined);
  const [mostPainfulJoint, setMostPainfulJoint] = useState<AvailableJoints | undefined>(undefined);
  const [specificJointPain, setSpecificJointPain] = useState<number | undefined>(undefined);
  invariant(profile);

  const postPatientIntroductionHealthReport = usePostPatientIntroductionHealthReport();

  const submitIntroductionHealthReport = (specificJointExamined: boolean) => {
    if (patientId && mostPainfulJoint && specificJointPain) {
      postPatientIntroductionHealthReport.mutateAsync({
        managerId: profile.id,
        patientId,
        data: {
          most_painful_joint: mostPainfulJoint,
          specific_joint_pain: specificJointPain,
          specific_joint_examined: specificJointExamined,
        },
      });
    }
  };

  const routes = useRoutes([
    {
      path: HEALTH_QUESTIONNAIRE_SCREENS.JOINT,
      element: (
        <Wrapper title={t("booking.manager_booking.hq_screens.joint")}>
          <JointScreen patientId={patientId} setJoint={setJoint} setMostPainfulJoint={setMostPainfulJoint} />
        </Wrapper>
      ),
    },
    {
      path: HEALTH_QUESTIONNAIRE_SCREENS.LATERAL_LOCATION,
      element: (
        <Wrapper title={t("booking.manager_booking.hq_screens.most_painful_joint", { joint: joint?.value })}>
          <LateralLocationScreen joint={joint?.value} setMostPainfulJoint={setMostPainfulJoint} />
        </Wrapper>
      ),
    },
    {
      path: HEALTH_QUESTIONNAIRE_SCREENS.PAIN,
      element: (
        <Wrapper title={t("booking.manager_booking.hq_screens.pain")}>
          <PainScreen specificJointPain={specificJointPain} setSpecificJointPain={setSpecificJointPain} />
        </Wrapper>
      ),
    },
    {
      path: HEALTH_QUESTIONNAIRE_SCREENS.PHYSICAL_EXAMINATION,
      element: (
        <Wrapper title={t("booking.manager_booking.hq_screens.physical_examination", { joint: joint?.value })}>
          <PhysicalExaminationScreen submitIntroductionHealthReport={submitIntroductionHealthReport} />
        </Wrapper>
      ),
    },
  ]);

  return routes;
};
