/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

import type { LocalTrack, LocalTrackPublication, RemoteTrack, RemoteTrackPublication } from "twilio-video";

export default function useTrack(
  publication: RemoteTrackPublication | LocalTrackPublication
): RemoteTrack | LocalTrack | null {
  const [track, setTrack] = useState<RemoteTrack | LocalTrack | null>(publication?.track);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication?.track);

    if (publication) {
      const removeTrack = () => setTrack(null);

      publication.on("subscribed", setTrack);
      publication.on("unsubscribed", removeTrack);
      return () => {
        publication.off("subscribed", setTrack);
        publication.off("unsubscribed", removeTrack);
      };
    }
    return undefined;
  }, [publication]);

  return track;
}
