import Cookies from "js-cookie";

interface IAuthTokens {
  idToken: string;
  refreshToken: string;
}

const getAuthTokens = (): IAuthTokens | null => {
  const idToken = Cookies.get("rja_auth_id_token");
  const refreshToken = Cookies.get("rja_auth_refresh_token");

  if (!idToken || !refreshToken) {
    return null;
  }

  return {
    idToken,
    refreshToken,
  };
};

export type { IAuthTokens };
export default getAuthTokens;
