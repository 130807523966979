import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetPartnerPatientList = (queryParams: string) => {
  const client = useApiClient();
  return useQuery(`GetPartnerPatientList${queryParams}`, () => client.getPartnerPatientList(queryParams), {
    keepPreviousData: true,
  });
};

export default useGetPartnerPatientList;
