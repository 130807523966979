import type { ReactNode } from "react";
import type React from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const PatientSubMenu: React.VFC = () => {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const PATH = `/patients/${patientId}/information`;

  return (
    <>
      <Container data-testid="sub-menu">
        <MenuItem dataTestId="sub-menu-medical-record" to={`${PATH}/journal`}>
          <Text>{t("patients.sub_menu.medical_record")}</Text>
        </MenuItem>
        <MenuItem dataTestId="sub-menu-sign-up-questions" to={`${PATH}/sign-up-questions`}>
          <Text>{t("patients.sub_menu.sign_up_questions")}</Text>
        </MenuItem>
        <MenuItem dataTestId="sub-menu-reports" to={`${PATH}/health-reports`}>
          <Text>{t("patients.sub_menu.health_questionnaires")}</Text>
        </MenuItem>
        <MenuItem dataTestId="sub-menu-goals" to={`${PATH}/goals`}>
          <Text>{t("patients.sub_menu.goal")}</Text>
        </MenuItem>
        <MenuItem dataTestId="sub-menu-personal-details" to={`${PATH}/personal`}>
          <Text>{t("patients.sub_menu.personal_details")}</Text>
        </MenuItem>
      </Container>
    </>
  );
};

export default PatientSubMenu;

interface MenuItemProps {
  to: string;
  children: ReactNode;
  dataTestId?: string;
}

const MenuItem = ({ to, children, dataTestId }: MenuItemProps) => {
  const navigate = useNavigate();
  const match = useMatch(to);

  return (
    <Item data-testid={dataTestId} onClick={() => navigate(to)} $active={!!match}>
      {children}
    </Item>
  );
};

const Container = styled.div`
  min-height: 32px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  ${props => props.theme.belowBreakpoint} {
    justify-content: space-between;
  }
`;

const Text = styled.span`
  ${props => props.theme.font.link2};
  white-space: nowrap;
  color: ${props => props.theme.colors.greys.dark};
  ${props => props.theme.belowBreakpoint} {
    ${props => props.theme.font.sub_menu.link};
    color: ${props => props.theme.colors.primary.base};
  }
`;

const Item = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-wrap: none;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_20};
  ${props => props.$active && `border-bottom: 2px solid ${props.theme.colors.primary.base}`};
`;
