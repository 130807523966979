import type React from "react";

import { ActivityAdmin, ActivityExercise, ActivityLesson, ActivityReport, ActivityTest } from "assets";

interface Props {
  readonly type?: "exercise" | "lesson" | "functionality" | "report" | "payment" | "update_payment_method" | null;
}

const ActivityIcon: React.VFC<Props> = ({ type = null }) => {
  switch (type) {
    case "exercise":
      return <ActivityExercise data-testid="exercise" />;
    case "report":
      return <ActivityReport data-testid="report" />;
    case "payment":
      return <ActivityAdmin data-testid="payment" />;
    case "update_payment_method":
      return <ActivityAdmin data-testid="update_payment_method" />;
    case "functionality":
      return <ActivityTest data-testid="functionality" />;
    default:
      return <ActivityLesson data-testid="lesson" />;
  }
};

export default ActivityIcon;
