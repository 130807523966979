import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { CreateUserCourseArgs } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const useCreateUserCourseTemplate = (
  options?: UseMutationOptions<null, Error, CreateUserCourseArgs>
): UseMutationResult<null, Error, CreateUserCourseArgs> => {
  const client = useApiClient();
  return useMutation<null, Error, CreateUserCourseArgs>(({ therapistId, course }) => {
    return client.createUserCourseTemplate(therapistId, course);
  }, options);
};
