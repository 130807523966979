/* eslint-disable @typescript-eslint/ban-ts-comment */
import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import invariant from "ts-invariant";

import useGetPatientAvailableJoints from "api/hooks/useGetPatientAvailableJoints";
import type { AvailableJoints } from "api/schemas/ManagerBooking";
import { JaBack, JaCalf, JaElbow, JaFoot, JaHand, JaHip, JaKnee, JaNeck, JaShoulder, JaWrist } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Spinner from "shared/atoms/Spinner";

import { Container } from "../../misc";

import type { JointItem } from "./HealthQuestionnaire";
import { HEALTH_QUESTIONNAIRE_SCREENS } from "./HealthQuestionnaire";

interface Props {
  patientId: number | undefined;
  setJoint: (value: JointItem) => void;
  setMostPainfulJoint: (value: AvailableJoints) => void;
}

export const JointScreen: React.VFC<Props> = ({ patientId, setJoint, setMostPainfulJoint }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useProfileContext();
  const [joints, setJoints] = useState<JointItem[]>([]);

  invariant(profile);
  const { data, isLoading } = useGetPatientAvailableJoints(
    { managerId: profile.id, patientId: patientId as number },
    { enabled: !!patientId }
  );

  useEffect(() => {
    if (data) {
      const jointsArray: JointItem[] = [];

      if (data.hip) {
        jointsArray.push({
          icon: <JaHip />,
          // @ts-ignore
          label: t("patients.pain_location.hip.0"),
          value: "hip",
          hasLateralLocation: true,
        });
      }

      if (data.knee) {
        jointsArray.push({
          icon: <JaKnee />,
          // @ts-ignore
          label: t("patients.pain_location.knee.0"),
          value: "knee",
          hasLateralLocation: true,
        });
      }

      if (data.lower_back) {
        jointsArray.push({
          icon: <JaBack />,
          // @ts-ignore
          label: t("patients.pain_location.back.0"),
          value: "lower_back",
          hasLateralLocation: false,
        });
      }

      if (data.neck) {
        jointsArray.push({
          icon: <JaNeck />,
          // @ts-ignore
          label: t("patients.pain_location.neck.0"),
          value: "neck",
          hasLateralLocation: false,
        });
      }

      if (data.shoulder) {
        jointsArray.push({
          icon: <JaShoulder />,
          // @ts-ignore
          label: t("patients.pain_location.shoulder.0"),
          value: "shoulder",
          hasLateralLocation: true,
        });
      }

      if (data.hand) {
        jointsArray.push({
          icon: <JaHand />,
          // @ts-ignore
          label: t("patients.pain_location.hand.0"),
          value: "hand",
          hasLateralLocation: true,
        });
      }

      if (data.wrist) {
        jointsArray.push({
          icon: <JaWrist />,
          // @ts-ignore
          label: t("patients.pain_location.wrist.0"),
          value: "wrist",
          hasLateralLocation: true,
        });
      }

      if (data.elbow) {
        jointsArray.push({
          icon: <JaElbow />,
          // @ts-ignore
          label: t("patients.pain_location.elbow.0"),
          value: "elbow",
          hasLateralLocation: true,
        });
      }

      if (data.foot) {
        jointsArray.push({
          icon: <JaFoot />,
          // @ts-ignore
          label: t("patients.pain_location.foot.0"),
          value: "foot",
          hasLateralLocation: true,
        });
      }

      if (data.lower_leg) {
        jointsArray.push({
          icon: <JaCalf />,
          // @ts-ignore
          label: t("patients.pain_location.lower_leg.0"),
          value: "lower_leg",
          hasLateralLocation: true,
        });
      }

      jointsArray.push({ label: t("patients.pain_location.other"), value: "other", hasLateralLocation: false });

      setJoints(jointsArray);
    }
  }, [data]);

  const handleOnClick = (joint: JointItem) => {
    setJoint(joint);
    if (joint.hasLateralLocation) {
      navigate(`../${HEALTH_QUESTIONNAIRE_SCREENS.LATERAL_LOCATION}`);
    } else {
      setMostPainfulJoint(joint.value as AvailableJoints);
      navigate(`../${HEALTH_QUESTIONNAIRE_SCREENS.PAIN}`);
    }
  };

  return (
    <Container>
      {isLoading && <Spinner dataTestId="joints-spinner" />}
      {joints.length > 0 && (
        <JointsGroup>
          {joints.map(joint => {
            return (
              <JointButton key={joint.value} onClick={() => handleOnClick(joint)}>
                <span>{joint.label}</span>
                {joint.icon}
              </JointButton>
            );
          })}
        </JointsGroup>
      )}
    </Container>
  );
};

export const JointsGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 800px;
`;

export const JointButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  width: 130px;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.redesign.b100};
  font-size: 16px;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.colors.redesign.db30};
  border-radius: 8px;
  cursor: pointer;

  ${({ theme }) => theme.belowBreakpoint} {
    width: calc(50% - 42px);
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.redesign.b100};
      color: ${({ theme }) => theme.colors.white};
    `}
`;
