import type React from "react";
import { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import usePostMedicalReferral from "api/hooks/usePostMedicalReferral";
import { useProfileContext } from "contexts/ProfileContext";
import TextArea from "shared/atoms/inputs/TextArea";
import TextInput from "shared/atoms/inputs/TextInput";
import { CurrentPatientContext } from "utils/contexts";

import type { ReferralStatusType } from "./ReferralNoteActionRow";
import ReferralNoteActionRow, { ReferralStatus } from "./ReferralNoteActionRow";
import type { IcdCodeType } from "./ReferralNoteDiagnosisCode";
import ReferralNoteDiagnosisCode from "./ReferralNoteDiagnosisCode";

interface Props {
  onDone: () => void;
}

const ReferralNoteForm: React.VFC<Props> = ({ onDone }) => {
  const { t } = useTranslation();
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();

  invariant(profile);

  const [referralStatus, setReferralStatus] = useState<ReferralStatusType | null>(null);
  const [icdCodes, setIcdCodes] = useState<IcdCodeType[]>([]);
  const postReferralNote = usePostMedicalReferral(patient.id);

  const { register, watch } = useForm({
    defaultValues: {
      reason: "",
      duration: 180,
    },
  });

  const reasonValue = watch("reason");
  const durationValue = watch("duration");
  const variables = {
    patientId: patient.id,
    reason: reasonValue,
    duration: durationValue,
    icdCodes,
    doctorId: String(profile.id),
  };

  const onSignConfirm = () => {
    postReferralNote.mutate(variables, {
      onError() {
        setReferralStatus(ReferralStatus.FAILED);
      },
      onSuccess() {
        setReferralStatus(ReferralStatus.CREATED);
      },
    });
  };

  return (
    <>
      <Row>
        <ReferralNoteDiagnosisCode icdCodes={icdCodes} setIcdCodes={setIcdCodes} />
        <CommentContainer>
          <TextArea
            rows={6}
            placeholder={t("doctor.referrals.input_placeholder")}
            {...register("reason", { required: true })}
          />
        </CommentContainer>
      </Row>
      <Row>
        <DurationInput
          label={t("common.duration")}
          dataTestId="duration-input"
          autoComplete="off"
          {...register("duration")}
          instruction={t("common.days")}
          type="number"
          size="small"
        />
      </Row>
      <Row>
        <ReferralNoteActionRow
          onDone={onDone}
          onSignConfirm={onSignConfirm}
          referralStatus={referralStatus}
          setReferralStatus={setReferralStatus}
          buttonDisabled={reasonValue === ""}
        />
      </Row>
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const CommentContainer = styled.div`
  flex: 2;
  margin-left: ${props => props.theme.spacing.S_40};
`;

const DurationInput = styled(TextInput)`
  width: 220px;
`;

export default ReferralNoteForm;
