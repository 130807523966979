import type React from "react";

import { useDataColumns } from "routes/patients/PatientsList/dataColumns";
import useFilters from "routes/patients/PatientsList/PatientsFilter/useFilters";
import { PatientsColumnsContext, PatientsFilterContext } from "utils/contexts";

const PatientListContextProviders: React.FC = ({ children }) => {
  const dataColumnsState = useDataColumns();
  const filtersState = useFilters();

  return (
    <PatientsColumnsContext.Provider value={dataColumnsState}>
      <PatientsFilterContext.Provider value={filtersState}>{children} </PatientsFilterContext.Provider>
    </PatientsColumnsContext.Provider>
  );
};

export default PatientListContextProviders;
