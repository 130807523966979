import type React from "react";

import styled from "styled-components";

import { iconButtonStyles, iconWrapperStyles } from "shared/styles";

const IconButton = styled.button`
  ${iconButtonStyles}
  border: none;
`;

const IconWrapper = styled.div`
  ${iconWrapperStyles}
`;

interface Props {
  readonly children: React.ReactNode;
  readonly onClick: React.MouseEventHandler<HTMLButtonElement>;
  readonly m?: string;
}

const ActionButton: React.VFC<Props> = ({ children, onClick, m }) => (
  <IconButton onClick={onClick} margin={m} data-testid="action-button">
    <IconWrapper>{children}</IconWrapper>
  </IconButton>
);

export default ActionButton;
