import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Spinner from "shared/atoms/Spinner";

const Container = styled.div<{ $height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.$height};
  width: 100%;
  ${props => props.theme.font.table.row};
`;

interface Props {
  height?: string;
  error?: boolean;
  loading?: boolean;
  message?: string;
  columnCount?: number;
  nodata?: boolean;
}

const TableMissingContent: React.VFC<Props> = ({
  height = "100px",
  error = false,
  loading = false,
  message = "",
  columnCount = undefined,
  nodata = false,
}) => {
  const { t } = useTranslation();

  return error || loading || nodata ? (
    <tr>
      <td colSpan={columnCount && columnCount}>
        <Container $height={height}>
          {loading && <Spinner dataTestId="spinner" small />}
          {error && <div data-testid="error">{message || t("errors.generic")}</div>}
          {nodata && <div data-testid="no-data">{message || t("errors.generic_nodata")}</div>}
        </Container>
      </td>
    </tr>
  ) : null;
};

export default TableMissingContent;
