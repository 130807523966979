import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Popup from "shared/atoms/Popup";

import type { CallState } from "../../types";

import StateInfo from "./StateInfo";

interface Props {
  readonly onCallAgain: () => void;
  readonly onEndCall: () => void;
  readonly callState: CallState;
  readonly error?: string | null;
}

const CallingStateModal: React.VFC<Props> = ({ onCallAgain, onEndCall, callState, error = null }) => {
  const contentStates = ["CONNECTION_FAILED", "CALLEE_REJECTED", "CALLEE_DISCONNECTED"];
  const { t } = useTranslation();

  if (contentStates.includes(callState)) {
    return (
      <Popup callModal>
        <StateInfo callState={callState} error={error} />
        <ButtonWrapper>
          <PrimaryButton data-testid="call-again-button" onClick={onCallAgain}>
            {t("buttons.call_again")}
          </PrimaryButton>
          <TextButton data-testid="exit-call-button" onClick={onEndCall}>
            {t("buttons.exit")}
          </TextButton>
        </ButtonWrapper>
      </Popup>
    );
  }

  return null;
};

export default CallingStateModal;

const ButtonWrapper = styled.div`
  width: 175px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
`;
