import type React from "react";

import { useTranslation } from "react-i18next";

import type { GetPatientGoalsResponse } from "api/types/GetPatientGoals";
import { Accordion, AccordionItem } from "shared/atoms/Accordion";

import { CardBody, CardTitle, ParagraphContent, ParagraphTitle, Title } from "./Goal";

interface Props {
  data: GetPatientGoalsResponse | undefined;
}

const PastGoals: React.VFC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const pastGoals = data?.map(({ id: goalID, free_text: description, pain, functionality }, index) => {
    return index !== 0 ? (
      <AccordionItem
        key={goalID}
        buttonLabel={t("patients.comments.show_more")}
        header="-"
        content={
          <>
            <p>
              <ParagraphTitle>{t("dashboard.tables.calls.goal.description")}</ParagraphTitle>
              <ParagraphContent>{description}</ParagraphContent>
            </p>
            <p>
              <ParagraphTitle>{t("dashboard.tables.calls.goal.pain")}</ParagraphTitle>
              <ParagraphContent>{pain}</ParagraphContent>
            </p>
            <p>
              <ParagraphTitle>{t("dashboard.tables.calls.goal.function")}</ParagraphTitle>
              <ParagraphContent>{functionality}</ParagraphContent>
            </p>
          </>
        }
      />
    ) : null;
  });

  return (
    <>
      <CardTitle>
        <Title>{t("patients.past_goals")}</Title>
      </CardTitle>
      <CardBody>
        {data !== undefined && data?.length > 1 ? (
          <Accordion>{pastGoals}</Accordion>
        ) : (
          <ParagraphContent>{t("patients.no_past_goals")}</ParagraphContent>
        )}
      </CardBody>
    </>
  );
};

export default PastGoals;
