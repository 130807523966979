import styled, { css } from "styled-components";

type Size = "large" | "small";

const inputStyles = css<{ $size?: Size; error?: string; completed?: boolean }>`
  font-family: "Roboto", sans-serif;
  ${props => ({ ...props.theme.font.body1 })};
  font-weight: ${props => props.theme.fontWeight.regular};
  padding: ${props => props.$size === "small" && "2px "} ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  flex-basis: 100%;
  box-sizing: border-box;
  border: 2px solid ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.greys.silver)};
  color: ${props => props.theme.colors.greys.dark};
  background-color: ${props => (props.completed ? props.theme.colors.greys.light2 : props.theme.colors.white)};
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  ::placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  &:disabled {
    background-color: ${props => props.theme.colors.greys.light2};
  }
  &:focus {
    border: 2px solid ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.secondary.base)};
    outline: none;
  }
`;

const StyledInput = styled.input<{ $size?: Size; error?: string; completed?: boolean }>`
  ${inputStyles}
  &:read-only {
    background-color: ${props => props.theme.colors.greys.light2};
    border: 2px solid ${props => props.theme.colors.greys.silver};
  }
  height: ${props => (props.$size === "small" ? "36px" : "48px")};
`;

const StyledMultilineInput = styled.textarea<{
  $size?: Size;
  error?: string;
  completed?: boolean;
  height?: string;
  $margin?: string;
  $scrollbarVisible?: boolean;
}>`
  white-space: pre-wrap;
  &:read-only {
    background-color: ${props => props.theme.colors.greys.light2};
  }
  padding: ${props => props.$size === "small" && "2px "} 10px;
  padding-bottom: ${props => props.theme.spacing.S_5};
  margin: ${props => props.$margin};
  resize: none;
  ${props => props.height && `height: ${props.height};`}

  font-family: "Roboto", sans-serif;
  ${props => ({ ...props.theme.font.body1 })};
  font-weight: ${props => props.theme.fontWeight.regular};
  flex-basis: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.colors.primary.base};
  background-color: ${props => props.theme.colors.white};
  border: none;
  scrollbar-width: ${props => (props.$scrollbarVisible ? "auto" : "none")};
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  ::-webkit-scrollbar {
    display: ${props => (props.$scrollbarVisible ? "auto" : "none")};
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  ::placeholder {
    color: ${props => props.theme.colors.greys.pinkish};
    user-select: none;
  }
  &:disabled {
    background-color: ${props => props.theme.colors.greys.light2};
  }
  &:focus {
    outline: none;
  }
  border-radius: ${props => props.theme.borderRadius.basic};
`;

const StyledDropdown = styled.select<{ $size?: Size; error?: string; completed?: boolean }>`
  ${inputStyles}
  appearance: none;
  cursor: pointer;
`;

const StyledLabel = styled.label<{ error?: string; htmlFor?: string }>`
  ${props => ({ ...props.theme.font.body1 })};
  color: ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.primary.base)};
`;

export { StyledInput, StyledMultilineInput, StyledDropdown, StyledLabel, inputStyles };
