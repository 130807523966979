import styled from "styled-components";

const SectionRow = styled.div`
  display: flex;
  flex: 1;
  > div {
    margin-bottom: ${props => props.theme.spacing.S_10};
  }
`;

const SplitSectionRow = styled(SectionRow)`
  > div {
    width: 228px;
    max-width: calc(50% - ${props => props.theme.spacing.S_15} / 2);
    flex: none;

    &:first-child {
      margin-right: ${props => props.theme.spacing.S_15};
      ${props => props.theme.belowMobileBreakpoint} {
        margin-right: ${props => props.theme.spacing.S_10};
      }
    }
  }

  ${props => props.theme.belowMobileBreakpoint} {
    > div {
      flex: 1 1 100%;
      width: 0;
    }
  }
`;

export { SectionRow, SplitSectionRow };
