import type React from "react";
import { useCallback } from "react";

import { useProfileContext } from "contexts/ProfileContext";
import { getHasFeature } from "utils/features";
import type { Feature } from "utils/features/types";

import FeatureToggleContext from "./FeatureToggleContext";

const FeatureToggleContextProvider: React.FC = ({ children }) => {
  const { profile } = useProfileContext();

  const hasFeature = useCallback(getHasFeature<Feature>({ profile }, true), [profile]);

  const value = {
    hasFeature,
  };

  return <FeatureToggleContext.Provider value={value}>{children}</FeatureToggleContext.Provider>;
};

export default FeatureToggleContextProvider;
