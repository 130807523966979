import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { RightArrow } from "assets";

const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  ${props => props.theme.font.caption};
  color: ${props => props.theme.colors.greys.dark};
`;

const LeftButton = styled.button`
  outline: none;
  border-radius: ${props => props.theme.borderRadius.basic} 0px 0px ${props => props.theme.borderRadius.basic};
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.greys.dark};
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  transition: color 200ms, background-color 200ms, border-color 200ms;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  &:disabled {
    opacity: 0.5;
    background: transparent;
    cursor: default;
  }
  ${props =>
    !props.disabled &&
    `&:hover {
      background-color: ${props.theme.colors.greys.light4};
      border: 2px solid ${props.theme.colors.greys.dark};
    }`}
`;

const RightButton = styled(LeftButton)`
  position: relative;
  border-radius: 0px ${props => props.theme.borderRadius.basic} ${props => props.theme.borderRadius.basic} 0px;
  border-left: none;
  ${props =>
    !props.disabled &&
    `&:hover {
      border-left: none;
    }
    &:hover > span {
      background-color: ${props.theme.colors.greys.dark};
    }`}
`;

const RightBorder = styled.span`
  background: transparent;
  position: absolute;
  width: 2px;
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
`;

const Wrapper = styled.div`
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.theme.belowMobileBreakpoint} {
    display: none;
  }
`;

const StyledArrow = styled(RightArrow)`
  width: 12px;
  height: 12px;
`;

const StyledRotatedArrow = styled(StyledArrow)`
  transform: rotate(180deg);
`;

const Label = styled.div`
  padding-top: 6px;
  height: 40px;
  width: 80px;
  box-sizing: border-box;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  border-left: none;
  user-select: none;
`;

const ButtonWrap = styled.div`
  ${props => props.theme.belowMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_5};
  }
  display: flex;
  flex-wrap: nowrap;
`;

interface Props {
  readonly totalCount?: number;
  readonly first?: number;
  readonly last?: number;
  readonly pageInfo?: {
    readonly hasNextPage?: boolean;
    readonly hasPreviousPage?: boolean;
  };
  readonly label?: string;
  readonly onPageChange?: (str: string) => void;
}

const Pagination: React.VFC<Props> = ({
  totalCount = 0,
  first = 0,
  last = 0,
  pageInfo = { hasNextPage: false, hasPreviousPage: false },
  onPageChange = () => undefined,
  label = null,
}) => {
  const { t } = useTranslation();

  const data = totalCount > 0 && first > 0 && last > 0;

  return (
    <Container data-testid="pagination">
      {data && <Wrapper>{t("common.pagination", { first, last, totalCount })}</Wrapper>}
      <ButtonWrap>
        <LeftButton
          data-testid="pagination-previous"
          onClick={() => {
            if (pageInfo.hasPreviousPage) onPageChange("previous");
          }}
          disabled={!pageInfo.hasPreviousPage}
        >
          <StyledRotatedArrow />
        </LeftButton>
        {label && <Label>{label}</Label>}
        <RightButton
          data-testid="pagination-next"
          onClick={() => {
            if (pageInfo.hasNextPage) onPageChange("next");
          }}
          disabled={!pageInfo.hasNextPage}
        >
          <RightBorder />
          <StyledArrow />
        </RightButton>
      </ButtonWrap>
    </Container>
  );
};
export default Pagination;
