import { useTranslation } from "react-i18next";

import type { Osteoporosis, OsteoporosisCause } from "api/schemas/patients/HealthJournal";

import { booleanToAnswer, exists } from "../helpers";
import { textToAnswer } from "../helpers/helpers";

import { Row } from "./helpers";

interface Props {
  fields: Osteoporosis;
}

export const OsteoporosisQuestions: React.VFC<Props> = ({ fields }) => {
  const { t } = useTranslation();
  const {
    osteoporosis_accident_or_fall_last_months,
    osteoporosis_cause,
    osteoporosis_diagnosed,
    osteoporosis_diagnosed_during_fracture,
    osteoporosis_exercise_recommended,
    osteoporosis_have_or_had_cancer,
    osteoporosis_osteopenia_diagnosed,
    osteoporosis_sudden_back_pain,
    osteoporosis_sudden_weight_loss,
  } = fields;

  const getCause = (cause: OsteoporosisCause): string => {
    switch (cause) {
      case "age_menopause":
        return t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_AGE");
      case "medicine":
        return t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_MEDICAL");
      case "dont_know":
        return t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_UNKNOWN");
      default:
        return "";
    }
  };

  return (
    <>
      {exists(osteoporosis_diagnosed) && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_DIAGNOSED")}
          right={booleanToAnswer(osteoporosis_diagnosed)}
          translated
        />
      )}
      {exists(osteoporosis_osteopenia_diagnosed) && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_OSTEOPENI_DIAGNOSED")}
          right={booleanToAnswer(osteoporosis_osteopenia_diagnosed)}
          translated
        />
      )}
      {exists(osteoporosis_diagnosed_during_fracture) && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_FRACTURE")}
          right={booleanToAnswer(osteoporosis_diagnosed_during_fracture)}
          translated
        />
      )}
      {osteoporosis_cause && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL")}
          right={getCause(osteoporosis_cause)}
          translated
        />
      )}
      {exists(osteoporosis_exercise_recommended) && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_EXERCISE")}
          right={booleanToAnswer(osteoporosis_exercise_recommended)}
          translated
        />
      )}
      {osteoporosis_accident_or_fall_last_months && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_CAUSED_BY_ACCIDENT")}
          right={textToAnswer(osteoporosis_accident_or_fall_last_months)}
          translated
        />
      )}
      {exists(osteoporosis_sudden_back_pain) && (
        <Row
          left={t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_BACK_PAIN")}
          right={booleanToAnswer(osteoporosis_sudden_back_pain)}
          translated
        />
      )}
      {exists(osteoporosis_have_or_had_cancer) && (
        <Row
          left={t("patients.medical_records.have_had_cancer")}
          right={booleanToAnswer(osteoporosis_have_or_had_cancer)}
          translated
          highlight={Boolean(osteoporosis_have_or_had_cancer)}
        />
      )}
      {exists(osteoporosis_sudden_weight_loss) && (
        <Row
          left={t("patients.medical_records.acute.weight_loss")}
          right={booleanToAnswer(osteoporosis_sudden_weight_loss)}
          translated
          highlight={Boolean(osteoporosis_sudden_weight_loss)}
        />
      )}
    </>
  );
};
