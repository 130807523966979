import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

type ProfilePassword = {
  password: string;
  password_confirmation: string;
};

const updatePassword = (body: ProfilePassword): Promise<AxiosResponse<unknown>> =>
  AxiosInstance.put(`/auth/password`, {
    ...body,
  });

export default updatePassword;
