import type { Language, Market, MarketMap } from "types";
import { LanguageTypes } from "types";

const DEFAULTS: { [key in MarketMap]: Language } = {
  US: LanguageTypes.en,
  SE: LanguageTypes.sv,
  GB: LanguageTypes.en,
  FR: LanguageTypes.fr,
  DE: LanguageTypes.de,
  UK: LanguageTypes.en,
};

const getDefaultLng = (region: Market): Language => {
  return DEFAULTS[region] || LanguageTypes.en;
};

export default getDefaultLng;
