import type React from "react";

import { Route, Routes } from "react-router-dom";

import ScheduledMaintenance from "./views/ScheduledMaintenance";

const MaintenanceRoutes: React.VFC = () => {
  return (
    <Routes>
      <Route path="/" element={<ScheduledMaintenance />} />
    </Routes>
  );
};

export default MaintenanceRoutes;
