import type { MedicalNoteType } from "../../types";
import type { DateTimeString } from "../../types/DateTimeString";

import type { MedicalNoteAddendum } from "./MedicalNoteAddendum";
import type { PremiumType } from "./PatientProfile";
import type { UsPatientPlanOfCare } from "./UsPatientPlanOfCare";

type CommunicationGroups = "async" | "sync";
type CommunicationTypes = "none" | "phone" | "video" | "writing";
type DischargeResolutionTypes = "discharged" | "selfcare";

export const PayerTypes = {
  FROSJO: "frosjo",
  VGR: "vgr",
  STOCKHOLM: "stockholm",
} as const;

export type PayerType = typeof PayerTypes[keyof typeof PayerTypes];

export type Payer = {
  payer_type: PayerType;
};

export type PatientStateTypes =
  | "blocked"
  | "discharged"
  | "invited"
  | "in_diagnosis_course"
  | "in_first_health_form"
  | "in_introduction"
  | "in_limbo"
  | "in_selfcare"
  | "in_six_week_course"
  | "in_six_week_welcome"
  | "in_sustain_course"
  | "in_sustain_welcome"
  | "new"
  | "referred_elsewhere"
  | "treatment_ended";

export type CareEvent = {
  billable?: boolean;
  cap35?: boolean;
  confirmed_identity?: boolean;
  confirmed_insurance?: boolean;
  confirmed_video_call?: boolean;
  confirmed_chaperone?: boolean; // Used in UK market
  communication_group: CommunicationGroups | null;
  communication_type: CommunicationTypes | null;
  cpt_codes?: string[];
  discharge_resolution?: DischargeResolutionTypes | null;
  exclusion_reason?: string | null;
  general_condition: string | null;
  health_care_professional_name: string;
  health_care_professional_type: string;
  icd_code?: string | null;
  icd_codes?: string[];
  id: number;
  label: MedicalNoteType;
  medical_note_addenda?: MedicalNoteAddendum[];
  medical_record_note: string | null;
  patient_id: number;
  patient_deleted: boolean;
  patient_state?: PatientStateTypes;
  patient_name: string;
  patient_preferred_name: string;
  payer_id?: number;
  problem: string;
  rtm_care_event_log_id?: number;
  signed_at: DateTimeString | null;
  signed_limit_reached?: boolean;
  start_time: DateTimeString | null;
  structured_fields: CareEventStructuredFields;
  suggested?: boolean;
  therapist_assignment_role?: string | null;
  us_patient_plan_of_care?: UsPatientPlanOfCare | null;
  premium_type: PremiumType;
  week_number?: number;
  payer?: Payer;
};

export type CareEventStructuredFields = {
  complexity?: string | null;
  red_flags?: string | null;
  subjective?: string | null;
  objective?: string | null;
  assessment?: string | null;
  function?: string | null;
  past_medical_history?: string | null;
  previous_and_current_treatments?: string | null;
  treatment?: string | null;
  chaperone_name?: string | null; // Used in UK market
  current_pain?: number | null;
  current_functionality?: number | null;
  goal_pain?: number | null;
  goal_pain_achieved?: boolean | null;
  goal_functionality?: number | null;
  goal_functionality_achieved?: boolean | null;
  goal_free_text?: string | null;
  goal_free_text_achieved?: boolean | null;
  plan_duration_days?: number | null;
  adherence_measure?: string | null;
  improvement_pain?: string | null;
  improvement_functionality?: string | null;
  progress_towards_goal?: string | null;
  skilled_care_provided?: string | null;
  clinical_note?: string | null;
  measure?: string | null;
  frequency?: string | null;
  symptoms_and_pain?: string | null;
  social?: string | null;
  aids?: string | null;
  physical_activity?: string | null;
  previous_surveys?: string | null;
  plan?: string | null;
  results?: string | null;
  treatment_period?: string | null;
  exclusion_reason?: string | null;
  suggested_assessment_classification?: string | null;
  suggested_assessment_recommendation?: string | null;
  suggested_measure_classification?: string | null;
  suggested_measure_recommendation?: string | null;
  suggested_plan_recommendation?: string | null;
  bmi?: number | null;
  kva_codes?: string[] | null;
};
