import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const prefixString = (isDoctor: boolean, isPartner: boolean) => {
  if (isDoctor) return "doctors";
  if (isPartner) return "clinic_partners";
  return "therapists";
};

const completeOnboarding = (profileId: number, isDoctor: boolean, isPartner: boolean): Promise<AxiosResponse> =>
  AxiosInstance.post(`/${prefixString(isDoctor, isPartner)}/${profileId}/complete_onboarding`);

export default completeOnboarding;
