import type React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { NewMessageIcon, SendIcon } from "assets";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import useSendMessageCall from "routes/messages/queries/useSendMessage";
import { PrimaryButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import TextArea from "shared/atoms/inputs/TextArea";
import { Notification } from "shared/atoms/Notification";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
// import { useActivityLogging } from "utils/hooks/useActivityLogging";
// import { isRTM } from "utils/patient";

interface Props {
  comment: string;
  activity: number;
  reply?: string | null;
}

type GetValues = ReturnType<typeof useForm>["getValues"];
type Comments = { [key: number]: string };

const CommentReply: React.VFC<Props> = ({ comment, reply, activity }) => {
  const { t } = useTranslation();
  const [replyOpen, setReplyOpen] = useState(false);
  const { patientId } = useParams<{ patientId: string }>();
  const getValuesRef = useRef<GetValues | null>(null);
  const { patient, updateIsCreatable } = useContext(CurrentPatientContext);
  const [localReply, setLocalReply] = useState({ reply: "", confirmedSent: false });
  const { savedComments, setSavedComments } = useSavedInputsContext();
  const [error, setError] = useState(null);
  // const { trackActivity } = useActivityLogging();
  // const startTime = useRef(new Date().toISOString());

  useEffect(() => {
    return () => {
      if (getValuesRef.current) {
        const inputValue = getValuesRef?.current("replyMessage");
        if (inputValue) {
          setSavedComments((c: Comments) => ({ ...c, [activity]: inputValue }));
        } else if (inputValue === "" && savedComments[activity]) {
          setSavedComments((c: Comments) => {
            const comments = { ...c };
            delete comments[activity];
            return comments;
          });
        }
      }
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("replyMessage", savedComments[activity] && savedComments[activity]);
  }, []);

  useEffect(() => {
    getValuesRef.current = getValues;
  });

  const { mutateAsync: sendMessage } = useSendMessageCall();

  const onSubmit = handleSubmit(async ({ replyMessage }) => {
    sendMessage({ to: Number(patientId), body: replyMessage, activity_id: activity })
      .then(({ data }) => {
        AnalyticsService.track(AnalyticsEvents.PATIENTS.COMMENT_REPLY, {
          patient: patientId,
        });

        // if (patientId && isRTM(patient?.insuranceData)) {
        //   trackActivity({
        //     category: "comment_posted",
        //     patient_id: parseInt(patientId, 10),
        //     start_time: startTime.current,
        //     end_time: new Date().toISOString(),
        //   });
        // }

        setError(null);
        setReplyOpen(false);
        setLocalReply({ reply: data.message.body, confirmedSent: true });
        updateIsCreatable();
        if (savedComments[activity])
          setSavedComments((c: Comments) => {
            const comments = { ...c };
            delete comments[activity];
            return comments;
          });
        reset();
      })
      .catch(errorObj => {
        setError(errorObj);
      });
  });

  const renderReply = useCallback(() => {
    const replyToUse = localReply.reply || reply;
    if (replyToUse) return <Reply data-testid="reply-text">{replyToUse}</Reply>;
    return null;
  }, [reply, localReply]);

  return (
    <Container data-testid="comment">
      <Comment data-testid="comment-text">{comment}</Comment>
      {renderReply()}
      {localReply.confirmedSent && (
        <Notification variant="success" style={{ marginTop: "20px" }}>
          {t("reply_comment.message_sent", {
            first_name: patient.first_name,
            last_name: patient.last_name,
          })}
        </Notification>
      )}
      <ReplyWrapper $visible={replyOpen}>
        <form data-testid="reply-form" onSubmit={onSubmit}>
          <TextArea
            rows={2}
            padding="3px 0"
            placeholder={t("patients.comments.reply_placeholder")}
            {...register("replyMessage", {
              required: true,
              validate: value => {
                return !!value.trim();
              },
            })}
            onValueUpdate={value => setValue("replyMessage", value)}
            error={errors.replyMessage && ""}
            expandToHeight={250}
            emojiPicker
            scrollbarVisible={false}
            noBorder
          />
          <SendButton data-testid="reply-submit-button" type="submit">
            <SendIcon />
          </SendButton>
          {error !== null && (
            <Notification variant="danger" style={{ marginBottom: "5px" }}>
              {t("errors.generic")}
            </Notification>
          )}
        </form>
      </ReplyWrapper>
      <ReplyButtonWrapper $visible={!replyOpen && !localReply.reply && !reply}>
        <IconButton label={t("buttons.reply")} onClick={() => setReplyOpen(true)} dataTestId="reply-button">
          <NewMessageIcon />
        </IconButton>
      </ReplyButtonWrapper>
    </Container>
  );
};

export default CommentReply;

const Container = styled.div`
  width: 280px;
  ${props => props.theme.belowMobileBreakpoint} {
    width: 200px;
  }
  min-height: 130px;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_5} 0;
`;

const Comment = styled.div`
  max-height: 90px;
  overflow-y: auto;
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.greys.dark};
  padding-bottom: ${props => props.theme.spacing.S_20};
`;

const Reply = styled.div`
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.greys.dark};
  margin-top: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  background: ${props => props.theme.colors.greys.light2};
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
`;

const ReplyWrapper = styled.div<{ $visible: boolean }>`
  display: ${props => (props.$visible ? "block" : "none")};
  border-top: 1px solid ${props => props.theme.colors.greys.light1};
  position: relative;
`;

const ReplyButtonWrapper = styled.div<{ $visible: boolean }>`
  display: ${props => (props.$visible ? "block" : "none")};
  margin: ${props => props.theme.spacing.S_30} 0 ${props => props.theme.spacing.S_20};
`;

const SendButton = styled(PrimaryButton)`
  position: absolute;
  right: 12px;
  bottom: 8px;
  min-height: 32px;
  height: 32px;
  min-width: 52px;
  padding: 0px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;
