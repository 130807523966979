import { z } from "zod";

import { allColumns } from "routes/patients/PatientsList/dataColumns";

type StorageOptions = {
  keepOnClear: boolean;
};

function createStorageDefinition<Type>(schema: z.ZodType<Type>, options: StorageOptions = { keepOnClear: false }) {
  return { schema, options };
}

const dataColumn = z.enum(allColumns);

export const storageDefinitions = {
  calendar_settings: createStorageDefinition(
    z.object({
      show_my_availability: z.boolean(),
      show_others_availability: z.boolean(),
      show_canceled: z.boolean(),
      show_rescheduled: z.boolean(),
      show_weekends: z.boolean(),
    }),
    { keepOnClear: true }
  ),
  collapsedSidebar: createStorageDefinition(z.enum(["collapsed"])),
  "dashboard.discharge.sort": createStorageDefinition(
    z.object({
      column: z.string(),
      descending: z.boolean(),
    })
  ),
  "datacolumns.columnPreference": createStorageDefinition(
    z.object({
      name: z.boolean(),
      preferredName: z.boolean(),
      primaryJoint: z.boolean(),
      status: z.boolean(),
      week: z.boolean(),
      id: z.boolean(),
      lastActivityCompletedAt: z.boolean(),
      activityAdherence: z.boolean(),
    })
  ),
  "datacolumns.orderPreference": createStorageDefinition(z.array(dataColumn)),
  hideSideContainer: createStorageDefinition(z.enum(["hide"])),
  language: createStorageDefinition(z.string(), { keepOnClear: true }),
  "login-remember-pn": createStorageDefinition(z.string(), { keepOnClear: true }),
  "login-remember-email": createStorageDefinition(z.string(), { keepOnClear: true }),
  "medical-notes-sort": createStorageDefinition(
    z.object({
      selectedColumn: z.string(),
      descending: z.boolean(),
    })
  ),
  "patient-list-sort": createStorageDefinition(z.object({ selectedColumn: dataColumn, descending: z.boolean() })),
  "suggestion-dismissed": createStorageDefinition(z.record(z.number())),
  "tours-completed": createStorageDefinition(z.record(z.boolean())),
};

export type StorageKey = keyof typeof storageDefinitions;

export type StorageValue<T extends StorageKey> = z.infer<typeof storageDefinitions[T]["schema"]>;
