import { useContext } from "react";

import PinnedMessagesContext from "./PinnedMessagesContext";
import type { PinnedMessagesContextValue } from "./PinnedMessagesContextValue";

const usePinnedMessagesContext = (): PinnedMessagesContextValue => {
  const value = useContext(PinnedMessagesContext);

  if (value === null) {
    throw new Error("usePinnedMessagesContext cannot be used outside the PinnedMessagesContextProvider");
  }
  return value;
};

export default usePinnedMessagesContext;
