import { z } from "zod";

import { isStage } from "utils/misc/getBuildEnvironment";

export const PatientsSchema = z.object({
  appointment_to_schedule: z.any().nullable(), // TODO: fix validation
  email: z.string(),
  deleted: z.boolean(),
  // Workaround for test patients not having a first_name
  first_name: isStage() ? z.string().nullable() : z.string(),
  id: z.number(),
  last_activity_completed_at: z.string().optional(),
  // Workaround for test patients not having a last_name
  last_name: isStage() ? z.string().nullable() : z.string(),
  new_comments: z.boolean(),
  new_messages: z.boolean(),
  new_patient: z.boolean(),
  payer_name: z.string(),
  // Workaround for test patients not having a preferred_name
  preferred_name: isStage() ? z.string().nullable() : z.string(),
  prio: z.boolean(),
  referral_process_status: z
    .enum(["questionnaire_submitted", "waiting_for_referral_upload", "rejected", "referral_uploaded"])
    .nullable(),
  state: z.enum([
    "discharged",
    "explore",
    "in_custom_course",
    "in_introduction",
    "in_onboarding",
    "in_selfcare",
    "in_six_week_course",
    "in_sustain_course",
    "new",
    "ready",
    "treatment_ended",
  ]),
  status: z.any(), // TODO: fix validation
  subscription: z
    .object({
      status: z.string(),
      is_free_trial: z.boolean(),
    })
    .optional(),
  week: z.number(),
  therapist_assignment_role: z.enum(["main_therapist", "substitute_therapist"]),
  premium_type: z.enum(["none", "selfcare", "guided_care"]),
  npo_consent: z.boolean().nullable(),
  primary_joint: z
    .object({
      location: z.string(),
      lateral_location: z.string(),
    })
    .optional(),
  adherence: z.number().optional(),
});

export type Patients = z.infer<typeof PatientsSchema>;
