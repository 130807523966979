import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { PutTherapistArgs, PutTherapistResponse } from "../types/PutTherapist";
import useApiClient from "../useApiClient";

export type UsePutTherapistQueryVariables = PutTherapistArgs;

export const usePutTherapistMutation = (
  options?: UseMutationOptions<PutTherapistResponse, Error, UsePutTherapistQueryVariables>
): UseMutationResult<PutTherapistResponse, Error, UsePutTherapistQueryVariables> => {
  const client = useApiClient();

  return useMutation<PutTherapistResponse, Error, UsePutTherapistQueryVariables>(
    variables => client.putTherapist(variables),
    options
  );
};
