import { useEffect, useState } from "react";

import { getIncidents } from "utils/statuspage";
import type { IncidentData } from "utils/statuspage/statuspageHelper";
import type { Component } from "utils/statuspage/types";

export interface UseStatuspageReturnValue {
  blockLogin: boolean;
  hasIncidents: boolean;
  physioStatusErrorMessage: string | null;
  patientStatusErrorMessage: string | null;
  physioIncidentUrl?: string;
  patientIncidentUrl?: string;
}

const useStatuspage = (): UseStatuspageReturnValue => {
  const [hasIncidents, setHasIncidents] = useState(false);
  const [physioStatusErrorMessage, setPhysioStatusErrorMessage] = useState<string | null>(null);
  const [patientStatusErrorMessage, setPatientStatusErrorMessage] = useState<string | null>(null);
  const [physioIncidentUrl, setPhysioIncidentUrl] = useState<string>();
  const [patientIncidentUrl, setPatientIncidentUrl] = useState<string>();
  const [incidents, setIncidents] = useState<IncidentData[] | null>();
  const [blockLogin, setBlockLogin] = useState(false);
  const CHECK_STATUSPAGE_INTERVAL_IN_MS = 60000;

  const statuspageBaseUrl = `${process.env.REACT_APP_STATUSPAGE_URL}/api/v2`;
  const physioId = process.env.REACT_APP_STATUSPAGE_PHYSIO_ID;
  const patientId = process.env.REACT_APP_STATUSPAGE_PATIENT_ID;

  const resetIncidentStates = () => {
    setBlockLogin(false);
    setHasIncidents(false);
    setPhysioStatusErrorMessage(null);
    setPhysioIncidentUrl(undefined);
    setPatientStatusErrorMessage(null);
    setPatientIncidentUrl(undefined);
  };

  const decideBlockingLogin = (component: Component) => {
    if (component.status === "major_outage" || component.status === "under_maintenance") {
      setBlockLogin(true);
    }
  };

  useEffect(() => {
    if (incidents) {
      resetIncidentStates();
      setHasIncidents(incidents.length > 0);

      incidents.forEach(incident => {
        incident.components.forEach(component => {
          if (component.id === physioId) {
            setPhysioStatusErrorMessage(`${component.name}: ${incident.title} - ${incident.body}`);
            setPhysioIncidentUrl(incident.shortlink);
            decideBlockingLogin(component);
          } else if (component.id === patientId) {
            setPatientStatusErrorMessage(`${component.name}: ${incident.title} - ${incident.body}`);
            setPatientIncidentUrl(incident.shortlink);
          }
        });
      });
    } else {
      resetIncidentStates();
    }
  }, [incidents, physioId, patientId]);

  useEffect(() => {
    const incidentsUrl = `${statuspageBaseUrl}/incidents/unresolved.json`;

    async function checkStatus() {
      if (physioId && patientId) {
        const theIncidents = await getIncidents(incidentsUrl, [physioId, patientId]);
        setIncidents(theIncidents);
      }
    }

    checkStatus();
    const intervalId = setInterval(checkStatus, CHECK_STATUSPAGE_INTERVAL_IN_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [patientId, physioId, statuspageBaseUrl]);

  return {
    blockLogin,
    hasIncidents,
    physioStatusErrorMessage,
    patientStatusErrorMessage,
    physioIncidentUrl,
    patientIncidentUrl,
  };
};

export default useStatuspage;
