import type React from "react";
import { useState } from "react";

import type { UserCourseLibraryExercise } from "api/schemas/UserCourse";

import ExerciseAddRow from "./ExerciseAddRow";
import ExerciseDetails from "./ExerciseDetails";

interface Props {
  exercise: UserCourseLibraryExercise;
  onAddExercise: (level: number) => void;
  showAddButton: boolean;
  onClose: () => void;
}

const ExercisePreviewPopup: React.VFC<Props> = ({ exercise, onAddExercise, showAddButton, onClose }) => {
  const [level, setLevel] = useState(1);
  return (
    <ExerciseDetails currentLevel={level} exercise={exercise} onClose={onClose}>
      <ExerciseAddRow
        level={level}
        onLevelChange={l => setLevel(l)}
        maxLevel={exercise.levels.length}
        showAddButton={showAddButton}
        onAddExercise={onAddExercise}
      />
    </ExerciseDetails>
  );
};

export default ExercisePreviewPopup;
