import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PrimaryButton, TextButton } from "shared/atoms/Button";

export const ReferralStatus = {
  CREATED: "Created",
  FAILED: "Failed",
  DONE: "Done",
} as const;
type Values<T> = T[keyof T];
export type ReferralStatusType = Values<typeof ReferralStatus>;

interface Props {
  onSignConfirm: () => void;
  onDone: () => void;
  referralStatus: ReferralStatusType | null;
  setReferralStatus: (referralStatus: ReferralStatusType | null) => void;
  buttonDisabled: boolean;
}

const ReferralNoteActionRow: React.VFC<Props> = ({
  onSignConfirm,
  onDone,
  setReferralStatus,
  buttonDisabled,
  referralStatus = null,
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);

  const onFailedClick = () => {
    setConfirm(false);
    setReferralStatus(null);
  };

  const onSuccessClick = () => {
    setConfirm(false);
    setReferralStatus(null);
    onDone();
  };

  if (referralStatus === ReferralStatus.CREATED) {
    return (
      <SuccessRow>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ConfirmText>{t("doctor.referrals.signed_success")}</ConfirmText>
        <ButtonContainer>
          <PrimaryButton type="button" onClick={onSuccessClick}>
            {t("buttons.ok")}
          </PrimaryButton>
        </ButtonContainer>
      </SuccessRow>
    );
  }

  if (referralStatus === ReferralStatus.FAILED) {
    return (
      <FailedRow>
        <ConfirmText>{t("doctor.referrals.signed_failed")}</ConfirmText>
        <ButtonContainer>
          <PrimaryButton type="button" onClick={onFailedClick}>
            {t("buttons.ok")}
          </PrimaryButton>
        </ButtonContainer>
      </FailedRow>
    );
  }

  if (confirm) {
    return (
      <ConfirmRow>
        <ConfirmText>{t("doctor.referrals.sign_confirm")}</ConfirmText>
        <ButtonContainer>
          <TextButton type="button" onClick={() => setConfirm(false)}>
            {t("buttons.cancel")}
          </TextButton>
          <PrimaryButton type="button" onClick={onSignConfirm} disabled={buttonDisabled}>
            {t("buttons.yes_sign")}
          </PrimaryButton>
        </ButtonContainer>
      </ConfirmRow>
    );
  }

  return (
    <Row>
      <PrimaryButton type="button" onClick={() => setConfirm(true)} disabled={buttonDisabled}>
        {t("buttons.sign")}
      </PrimaryButton>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${props => props.theme.spacing.S_10};

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const ConfirmRow = styled(Row)`
  background-color: ${props => props.theme.colors.primary.ice};
  border-radius: ${props => props.theme.borderRadius.basic};
  justify-content: space-between;
`;

const SuccessRow = styled(Row)`
  background-color: ${props => props.theme.colors.pastels.green};
  border-radius: ${props => props.theme.borderRadius.basic};
  justify-content: space-between;
`;

const FailedRow = styled(Row)`
  background-color: ${props => props.theme.colors.pastels.red};
  border-radius: ${props => props.theme.borderRadius.basic};
  justify-content: space-between;
`;

const ConfirmText = styled.span`
  color: ${props => props.theme.colors.primary.base};
`;

const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

export default ReferralNoteActionRow;
