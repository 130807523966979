import type React from "react";

import styled from "styled-components";

import { useVideoCallContext } from "contexts/VideoCallContext";

interface ContainerProps {
  $expandMargin: boolean;
}

const Container = styled.div<ContainerProps>`
  margin-left: auto;
  margin-right: auto;
`;

interface Props {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly dataTestId?: string;
}

const VideoCallAwareContainer: React.VFC<Props> = ({ children, className, dataTestId }) => {
  const { callData, minimised } = useVideoCallContext();

  return (
    <Container $expandMargin={callData !== null && !minimised} className={className} data-testid={dataTestId}>
      {children}
    </Container>
  );
};

export default VideoCallAwareContainer;
