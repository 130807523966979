import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetUserCourseTemplatesResponse } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const GET_USER_COURSE_TEMPLATES_QUERY_KEY = "useGetUserCourseTemplatesQuery";
type QueryKey = [typeof GET_USER_COURSE_TEMPLATES_QUERY_KEY];

export const useGetUserCourseTemplates = (
  options?: UseQueryOptions<GetUserCourseTemplatesResponse, Error, GetUserCourseTemplatesResponse, QueryKey>
): UseQueryResult<GetUserCourseTemplatesResponse, Error> => {
  const client = useApiClient();
  return useQuery(
    [GET_USER_COURSE_TEMPLATES_QUERY_KEY],
    () => {
      return client.getUserCourseTemplates();
    },
    options
  );
};
