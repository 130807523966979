import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

import type { Comment } from "../PatientProfile/views/Comments";

type Params = {
  [param: string]: string | number;
};
export const getComments = async (
  id: string,
  params: Params,
  unreadOnly: boolean
): Promise<AxiosResponse<Comment[]> | false> => {
  try {
    const param_string = Object.keys(params).reduce((current: string, key: string, index: number, keys: string[]) => {
      let s = current;
      if (key && params[key]) s += `page[${key}]=${params[key]}`;
      if (index < keys.length - 1) s += "&";
      return s;
    }, "");

    const param_unread = unreadOnly ? "&viewed_by_therapist=false" : "";

    const response = await AxiosInstance.get<Comment[]>(
      `/patients/${id}/activities/comments?${param_string}${param_unread}`
    );
    return response;
  } catch (error) {
    return false;
  }
};

export default getComments;
