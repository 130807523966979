import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPatientGoalsArgs, GetPatientGoalsResponse } from "api/types/GetPatientGoals";
import useApiClient from "api/useApiClient";

export const getPatientGoalsQueryKey = ({ patientId, currentPeriod, treatment_id }: GetPatientGoalsArgs) =>
  ["GetPatientGoalsQuery", patientId, currentPeriod, treatment_id] as const;

export const useGetPatientGoalsQuery = (
  args: GetPatientGoalsArgs,
  options?: UseQueryOptions<
    GetPatientGoalsResponse,
    Error,
    GetPatientGoalsResponse,
    readonly ["GetPatientGoalsQuery", number, boolean | undefined, number | undefined | null]
  >
): UseQueryResult<GetPatientGoalsResponse, Error> => {
  const client = useApiClient();

  return useQuery(getPatientGoalsQueryKey(args), () => client.getPatientGoals(args), options);
};
