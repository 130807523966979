import type React from "react";

import type { UserCourseExercise } from "api/schemas/UserCourse";

import ExerciseDetails from "./ExerciseDetails";
import ExerciseEditRow from "./ExerciseEditRow";

interface Props {
  exercise: UserCourseExercise;
  currentProtocolWeek: number;
  onChangeEndWeek: (id: number, n: number | null) => void;
  onChangeLevel: (id: number, n: number) => void;
  onChangeWeek: (id: number, n: number) => void;
  onClose: () => void;
}

const ExerciseEditPopup: React.VFC<Props> = ({
  exercise,
  currentProtocolWeek,
  onChangeEndWeek,
  onChangeLevel,
  onChangeWeek,
  onClose,
}) => {
  return (
    <ExerciseDetails exercise={exercise} currentLevel={exercise.current_level} onClose={onClose}>
      <ExerciseEditRow
        exercise={exercise}
        currentProtocolWeek={currentProtocolWeek}
        onChangeEndWeek={onChangeEndWeek}
        onChangeLevel={onChangeLevel}
        onChangeWeek={onChangeWeek}
      />
    </ExerciseDetails>
  );
};

export default ExerciseEditPopup;
