import i18n from "i18next";

import type { CustomStatus, FlowType } from "../queries/authenticateWithBankid";

interface Args {
  flowType: FlowType;
  status: CustomStatus;
}

export const translateStatus = ({ flowType, status }: Args) => {
  switch (status) {
    case "CERTIFICATE_ERR":
      return i18n.t("login.bankid.statuses.CERTIFICATE_ERR");
    case "COMPLETE":
      return i18n.t("login.bankid.statuses.COMPLETE");
    case "EXPIRED_TRANSACTION":
      return i18n.t("login.bankid.statuses.EXPIRED_TRANSACTION");
    case "INTERNAL_ERROR":
      return i18n.t("login.bankid.statuses.INTERNAL_ERROR");
    case "OUTSTANDING_TRANSACTION":
      switch (flowType) {
        case "desktop":
          return i18n.t("login.bankid.statuses.OUTSTANDING_TRANSACTION.desktop");
        case "mobile":
          return i18n.t("login.bankid.statuses.OUTSTANDING_TRANSACTION.mobile");
        case "qr":
          return i18n.t("login.bankid.statuses.OUTSTANDING_TRANSACTION.scan_qr");
        default:
          return i18n.t("login.bankid.statuses.UNKNOWN");
      }
    case "START_FAILED":
      switch (flowType) {
        case "qr":
          return i18n.t("login.bankid.statuses.START_FAILED.scan_qr");
        default:
          return i18n.t("login.bankid.statuses.START_FAILED.default");
      }
    case "USER_CANCEL":
      return i18n.t("login.bankid.statuses.USER_CANCEL");
    case "USER_SIGN":
      switch (flowType) {
        case "desktop":
          return i18n.t("login.bankid.statuses.USER_SIGN.desktop");
        default:
          return i18n.t("login.bankid.statuses.USER_SIGN.default");
      }
    case "no_user":
      return i18n.t("login.errors.no_user");
    case "patient_profile":
      return i18n.t("login.errors.patient_profile");
    default:
      return i18n.t("login.bankid.statuses.UNKNOWN");
  }
};
