// polyfills for ICU (only where needed):
// - Intl.getCanonicalLocales (+ Intl)
import "@formatjs/intl-getcanonicallocales/polyfill";
// - Intl.Locale
import "@formatjs/intl-locale/polyfill";
// - Intl.NumberFormat + locale-data
import "@formatjs/intl-numberformat/polyfill";
// - Intl.PluralRules + locale-data
import "@formatjs/intl-pluralrules/polyfill";

import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";

import en_GB from "assets/translations/en-GB.json";
import en_US from "assets/translations/en-US.json";
import en from "assets/translations/en.json";
import fr from "assets/translations/fr.json";
import sv from "assets/translations/sv.json";
import { LanguageTypes } from "types";
import getBestLng from "utils/locale";

const CURRENT_LOCALE = getBestLng();

export const defaultNS = "translation";
export const resources = {
  en: {
    translation: en,
  },
  "en-GB": {
    translation: en_GB,
  },
  "en-US": {
    translation: en_US,
  },
  fr: {
    translation: fr,
  },
  sv: {
    translation: sv,
  },
};

i18n
  .use(ICU)
  .use(initReactI18next)
  .init({
    resources,
    lng: CURRENT_LOCALE,
    fallbackLng: LanguageTypes.en,
    ns: ["translation"],
    defaultNS,

    load: "all",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
