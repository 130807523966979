import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { CareEvent } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import { AccordionItem } from "shared/atoms/Accordion";
import useLocalizedDate from "utils/date";
import { isKickOffNote } from "utils/medicalNotes";
import { userIsPartner } from "utils/profile/profileHelper";

import { Addenda } from "../Addenda";
import { PDFDownloadButton } from "../PDFDownload";

interface Props {
  record: CareEvent;
}

const RecordNote: React.VFC<Props> = ({ record }) => {
  const {
    id,
    start_time,
    label,
    health_care_professional_name,
    health_care_professional_type,
    confirmed_identity,
    structured_fields,
    problem,
    medical_note_addenda,
    medical_record_note,
    general_condition,
    communication_type,
  } = record;
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const isPartner = userIsPartner(profile);
  const { format, parseISO, add } = useLocalizedDate();
  const renderDuration = (days: number | undefined | null): string => {
    if (typeof days === "number") {
      const expiryDate = format(add(parseISO(start_time ?? ""), { days }), "PP");
      const durationAndExpiryDate = t("record_extended_note.duration", {
        days: days?.toString(),
        date: expiryDate,
      });
      return durationAndExpiryDate;
    }
    return t("patients.initial_evaluation.no_duration");
  };

  return (
    <AccordionItem
      buttonLabel={t("patients.comments.show_more")}
      print
      header={
        <HeaderWrapper>
          <DateWrapper data-testid="record-note-date">{format(parseISO(start_time ?? ""), "PP")}</DateWrapper>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <TextWrapper>{t(`dashboard.tables.notes.types.${record.label.toUpperCase()}`)}</TextWrapper>
        </HeaderWrapper>
      }
      content={
        <>
          {isPartner && <PDFDownloadButton record={record} />}
          <Row>
            <Section>
              {t("patients.medical_records.responsible")}
              <Subsection>{health_care_professional_name}</Subsection>
            </Section>
            <Section>
              {t("patients.medical_records.role")}
              {/* FIXME: type translation */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Subsection>{t(`patients.roles.${health_care_professional_type}`)}</Subsection>
            </Section>
          </Row>
          <Row>
            <Section>
              {confirmed_identity && <Subsection>{t("patients.notes.fields.confirmed_identity")}</Subsection>}
            </Section>
          </Row>
          <Row>
            <Section>
              {t("patients.medical_records.problem")}
              <Subsection>{problem}</Subsection>
            </Section>
          </Row>
          <Row>
            <Section>
              {t("patients.medical_records.note")}
              <Subsection>{medical_record_note}</Subsection>
            </Section>
          </Row>
          <Row>
            <Section>
              {t("patients.medical_records.general_health")}
              <Subsection>{general_condition ?? "-"}</Subsection>
            </Section>
          </Row>
          {communication_type && (
            <Row>
              <Section>
                {t("patients.notes.fields.communication_type")}
                <Subsection>{t(`patients.notes.communication_type_dropdown.options.${communication_type}`)}</Subsection>
              </Section>
            </Row>
          )}
          {isKickOffNote(label) && (
            <Row>
              <Section>
                {t("patients.initial_evaluation.frequency")}
                <Subsection>{t("patients.initial_evaluation.daily")}</Subsection>
              </Section>
              <Section>
                {t("patients.initial_evaluation.duration")}
                <Subsection>{renderDuration(structured_fields.plan_duration_days)} </Subsection>
              </Section>
            </Row>
          )}

          {!isPartner && <Addenda careEventId={id} medical_note_addenda={medical_note_addenda} />}
        </>
      }
    />
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.dark};
  overflow: hidden;
`;

const DateWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  min-width: 130px;
  flex-shrink: 0;
`;

const TextWrapper = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${props => props.theme.spacing.S_30};
`;

const Row = styled.div`
  margin-top: ${props => props.theme.spacing.S_10};
  padding-left: 16px;
  display: flex;
  &:first-child {
    margin-top: 0;
    display: inline-flex;
  }

  ${props => props.theme.aboveBreakpoint} {
    max-width: 50%;
  }
`;

const Section = styled.div`
  ${props => props.theme.font.caption}
  color: ${props => props.theme.colors.greys.warm};
  margin-right: ${props => props.theme.spacing.S_10};
`;

const Subsection = styled.div`
  white-space: pre-wrap;
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.dark};
`;

export default RecordNote;
