import styled from "styled-components";

type Props<T extends string> = {
  options: { label: string; value: T }[];
  value: T;
  onChange: (v: T) => void;
  disabled?: boolean;
  size?: "small" | "large";
};

const DropDown = <T extends string>(props: Props<T>) => {
  const { options, value, onChange, disabled, size = "small" } = props;
  return (
    <div>
      <ArrowWrapper disabled={!!disabled} $size={size}>
        <Select
          $size={size}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={e => {
            onChange(e.target.value as T);
          }}
          value={value}
          disabled={disabled}
        >
          {options.map(o => (
            <option key={o.label} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      </ArrowWrapper>
    </div>
  );
};

// hack to add dropdown arrow (have to turn off appearance to remove ugly gradient in safari)
const ArrowWrapper = styled.div<{ disabled: boolean; $size: "small" | "large" }>`
  &:after {
    display: ${props => (props.disabled ? "none" : "inline")};
    content: " ";
    position: absolute;
    margin-top: ${props => (props.$size === "large" ? "20px" : "14px")};
    margin-left: ${props => (props.$size === "large" ? "-30px" : "-20px")};
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.colors.black};
    pointer-events: none;
  }
`;

const Select = styled.select<{ $size: "small" | "large" }>`
  font-size: 16px;
  font-weight: normal;
  height: ${props => (props.$size === "large" ? "48px" : "34px")};
  border: 1px solid ${props => props.theme.colors.greys.concrete};
  padding-left: ${props => (props.$size === "large" ? "16px" : "8px")};
  padding-right: ${props => (props.$size === "large" ? "32px" : "24px")};
  width: 100%;
  appearance: none;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
`;

export default DropDown;
