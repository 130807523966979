import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetUserCourseTemplateResponse } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const GET_USER_COURSE_TEMPLATE_QUERY_KEY = "useGetUserCourseQuery";
type QueryKey = [typeof GET_USER_COURSE_TEMPLATE_QUERY_KEY, number];

export const getUserCourseTemplateQueryKey = (args: { templateId: number }): QueryKey => [
  GET_USER_COURSE_TEMPLATE_QUERY_KEY,
  args.templateId,
];

export const useGetUserCourseTemplateQuery = (
  args: { templateId: number },
  options?: UseQueryOptions<GetUserCourseTemplateResponse, Error, GetUserCourseTemplateResponse, QueryKey>
): UseQueryResult<GetUserCourseTemplateResponse, Error> => {
  const client = useApiClient();
  return useQuery(
    [GET_USER_COURSE_TEMPLATE_QUERY_KEY, args.templateId],
    () => {
      return client.getUserCourseTemplate(args.templateId);
    },
    options
  );
};
