import type React from "react";

interface Props {
  readonly children: React.ReactNode;
}

const Accordion: React.VFC<Props> = ({ children }) => {
  return <>{children}</>;
};
export default Accordion;
