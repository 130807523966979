import styled from "styled-components";

const StyledIcon = styled.div`
  width: 27px;
  height: 27px;
  position: relative;
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
`;

const StyledLi = styled.li`
  height: calc(100% - 2px);
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  position: relative;
`;

const StyledSpan = styled.span<{ $collapsed: boolean }>`
  margin-left: ${props => props.theme.spacing.S_10};
  display: ${props => props.$collapsed && "none"};
`;
interface BorderProps {
  active: boolean;
}
const Border = styled.div<BorderProps>`
  position: relative;
  top: 0px;
  height: 2px;
  transition: transform 200ms;
  background-color: ${props => props.theme.colors.primary.base};
  transform: ${props => (props.active ? "scaleX(1)" : "scaleX(0)")};
  ${props => props.theme.belowBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_20};
    margin: 0;
  }
`;

export { Border, StyledSpan, StyledLi, List, StyledIcon };
