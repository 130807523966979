import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { getComponentStatuses } from "utils/statuspage";

export interface UseStatuspageBankIdReturnValue {
  bankIdOnline: boolean;
  blockBankIdLogin: boolean;
  bankIdStatusErrorMessage: string | null;
}

const useStatuspageBankId = (): UseStatuspageBankIdReturnValue => {
  const { t } = useTranslation();
  const [bankIdOnline, setBankIdOnline] = useState(true);
  const [blockBankIdLogin, setBlockBankIdLogin] = useState(false);
  const [bankIdStatusErrorMessage, setBankIdStatusErrorMessage] = useState<string | null>(null);
  const CHECK_STATUSPAGE_INTERVAL_IN_MS = 60000;

  const statuspageBaseUrl = `${process.env.REACT_APP_STATUSPAGE_URL}/api/v2`;
  const bankIdStatuspageId = process.env.REACT_APP_STATUSPAGE_BANKID_ID;
  const location = useLocation();

  useEffect(() => {
    const componentsUrl = `${statuspageBaseUrl}/components.json`;

    async function checkStatus() {
      if (location.pathname.includes("bankid") && bankIdStatuspageId) {
        const componentStatuses = await getComponentStatuses(componentsUrl, [bankIdStatuspageId]);
        if (!componentStatuses || componentStatuses.length === 0) {
          // No bankid component or no error
          setBankIdOnline(true);
          setBlockBankIdLogin(false);
        } else {
          const bankIdStatus = componentStatuses[0].status;
          setBankIdOnline(bankIdStatus === "operational");
          if (bankIdStatus === "operational") {
            setBlockBankIdLogin(false);
          } else if (bankIdStatus === "degraded_performance" || bankIdStatus === "partial_outage") {
            setBlockBankIdLogin(false);
            setBankIdStatusErrorMessage(t("statuspage.bankid.login.warning"));
          } else if (bankIdStatus === "major_outage" || bankIdStatus === "under_maintenance") {
            setBlockBankIdLogin(true);
            setBankIdStatusErrorMessage(t("statuspage.bankid.login.blocked"));
          }
        }
      }
    }

    checkStatus();
    const intervalId = setInterval(checkStatus, CHECK_STATUSPAGE_INTERVAL_IN_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [bankIdStatuspageId, location.pathname, statuspageBaseUrl, t]);

  return {
    bankIdOnline,
    blockBankIdLogin,
    bankIdStatusErrorMessage,
  };
};

export default useStatuspageBankId;
