import { CalendarIcon, CommentsIcon, MessageIcon, NewMessageIcon, UsersIcon } from "assets";
import { getHasFeature } from "utils/features";
import { Feature } from "utils/features/types";

const hasFeature = getHasFeature({});

type Card = {
  label: string;
  icon: JSX.Element;
  link: string;
  dataTestId: string;
};

const cards = [
  {
    label: "new_patients",
    icon: <UsersIcon />,
    link: "/patients?filter=new_patients",
    dataTestId: "event-card-new_patients",
  },
  ...(hasFeature(Feature.SHOW_AWAITING_TREATMENT_READY)
    ? [
        {
          label: "awaiting_treatment_ready",
          icon: <NewMessageIcon />,
          link: "/patients?filter=awaiting_treatment_ready",
          dataTestId: "event-card-create_usercourse",
        },
      ]
    : []),
  { label: "new_messages", icon: <MessageIcon />, link: "/messages", dataTestId: "event-card-new_messages" },
  {
    label: "new_comments",
    icon: <CommentsIcon />,
    link: "/patients?filter=new_comments",
    dataTestId: "event-card-new_comments",
  },
  {
    label: "appointments_to_schedule",
    icon: <CalendarIcon />,
    link: "/patients?filter=appointment_to_schedule",
    dataTestId: "event-card-appointments_to_schedule",
  },
];

const getDashboardContent = (): Array<Card> => {
  return cards;
};

export default getDashboardContent;
