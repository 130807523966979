import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { HcpService } from "api/schemas/HcpService";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getHcpServices";
type QueryKey = [typeof queryKeyPrefix, number];
export const getHcpServicesQueryKey = (userId: number): QueryKey => [queryKeyPrefix, userId];

const useGetHcpServices = (userId: number, options?: UseQueryOptions<HcpService[], Error, HcpService[], QueryKey>) => {
  const client = useApiClient();

  return useQuery(getHcpServicesQueryKey(userId), () => client.getHcpServices(userId), options);
};

export default useGetHcpServices;
