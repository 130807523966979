export const insuranceProviders = [
  {
    state: "Alabama",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Arizona",
    insurance_plan: "Cigna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "California",
    insurance_plan: "Blue Shield California",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "California",
    insurance_plan: "Cigna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "California",
    insurance_plan: "Key Medical (Humana)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "California",
    insurance_plan: "Medicare California",
    pre_authorization_required: false,
    referral_required: true,
    rtm_accepted: false,
  },
  {
    state: "Florida",
    insurance_plan: "Cigna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Florida",
    insurance_plan: "Chenmed",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Florida",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Florida",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Florida",
    insurance_plan: "Intune",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Georgia",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Georgia",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Illinois",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Kansas",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Kentucky",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Louisiana",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Massachusetts",
    insurance_plan: "Blue Cross Blue Shield of Rhode Island",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Michigan",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Mississippi",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Missouri",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Nevada",
    insurance_plan: "Cigna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Nevada",
    insurance_plan: "Medicare Nevada",
    pre_authorization_required: false,
    referral_required: true,
    rtm_accepted: false,
  },
  {
    state: "North Carolina",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Ohio",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Pennsylvania",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Rhode Island",
    insurance_plan: "Blue Cross Blue Shield of Rhode Island",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Rhode Island",
    insurance_plan: "Medicare Rhode Island",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "South Carolina",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Tennessee",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Texas",
    insurance_plan: "Evry Health",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Texas",
    insurance_plan: "Dedicated",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Virginia",
    insurance_plan: "JenCare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Aetna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Anthem",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "BlueCross BlueShield (Premera)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Cigna",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Community Health Plan (Medicaid, Med. Adv.)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Coventry",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "DSHS/Provider One",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Federal Employees Plan",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "First Choice",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "First Health",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Healthcare Management Admin (HMA)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Humana",
    pre_authorization_required: true,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Integrated Health Plan",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Kaiser Permanente",
    pre_authorization_required: true,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Lifewise (Except Alpine Network)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Labor and Industries",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Mail Handlers Benefit",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Medicare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Motor Vehicle Accident (MVA)",
    pre_authorization_required: true,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Multiplan",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Optum",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "PacificSource",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Premera (BCBS)",
    pre_authorization_required: "Sometimes",
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Providence Health Plans",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Railroad Medicare (Palmetto)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Regence",
    pre_authorization_required: "Sometimes",
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Sound Health & Wellness Trust",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Uniform Medical (Regence)",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "United Healthcare",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: true,
  },
  {
    state: "Washington",
    insurance_plan: "Veterans Administration-Tricare/Triwest",
    pre_authorization_required: false,
    referral_required: false,
    rtm_accepted: false,
  },
  {
    state: "Washington",
    insurance_plan: "Worker's Compensation-L&I, Sedgwick, Puget Sound, etc.",
    pre_authorization_required: true,
    referral_required: false,
    rtm_accepted: false,
  },
];
