import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ResponsivePopup from "./components/ResponsivePopup";
import { SmallPrimaryButton, SmallSecondaryButton } from "./components/SharedStyledComponents";

const RemoveExercisesConfirmDialog = ({
  onClose,
  onYes,
  onNo,
}: {
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ResponsivePopup onClose={onClose} open>
      <PopupRoot>
        <StyledHeader>{t("usercourse.remove_all_exercises.header")}</StyledHeader>
        <SmallPrimaryButton onClick={onYes}>{t("buttons.yes")}</SmallPrimaryButton>
        <SmallSecondaryButton onClick={onNo}>{t("buttons.no")}</SmallSecondaryButton>
      </PopupRoot>
    </ResponsivePopup>
  );
};

export default RemoveExercisesConfirmDialog;

const PopupRoot = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const StyledHeader = styled(Header)`
  padding: 0px 20px;
`;
