import type { AxiosResponse } from "axios";
import axios from "axios";
import { useQuery } from "react-query";

import { parseMaintenances } from "utils/statuspage/statuspageHelper";
import type { ScheduledMaintenance, ScheduledMaintenancesResponse } from "utils/statuspage/types";

const statuspageBaseUrl = `${process.env.REACT_APP_STATUSPAGE_URL}/api/v2`;
const upcomingMaintenancesUrl = `${statuspageBaseUrl}/scheduled-maintenances/upcoming.json`;

const CHECK_STATUSPAGE_INTERVAL_IN_MS = 300000;

const getUpcomingMaintenances = (): Promise<AxiosResponse<ScheduledMaintenancesResponse>> => {
  return axios.get(upcomingMaintenancesUrl);
};

export type UseUpcomingMaintenanceReturnValue = {
  upcomingMaintenance?: ScheduledMaintenance;
};

export const useUpcomingMaintenance = (): UseUpcomingMaintenanceReturnValue => {
  const upcomingMaintenancesQuery = useQuery("GetUpcomingMaintenances", getUpcomingMaintenances, {
    select(data) {
      const upcomingMaintenances = parseMaintenances(data);

      if (upcomingMaintenances) {
        return upcomingMaintenances;
      }

      return null;
    },
    refetchInterval: CHECK_STATUSPAGE_INTERVAL_IN_MS,
  });

  return { upcomingMaintenance: upcomingMaintenancesQuery.data?.[0] };
};
