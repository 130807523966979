import type React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useActivityLibraryEntry } from "api/hooks/useActivityLibraryEntry";
import Container from "shared/atoms/Container";
import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import Popup from "shared/atoms/Popup";
import PremiumIcon from "shared/atoms/PremiumIcon";
import Spinner from "shared/atoms/Spinner";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import ActivityTemplate from "../components/ActivityTemplate";
import ErrorTemplate from "../components/ErrorTemplate";
import ExerciseInstruction from "../components/ExerciseInstruction";
import Video from "../components/Video";
import parseHTML from "../utils/parseHTML";

interface Props {
  readonly className?: string;
  readonly activity: "lesson" | "exercise" | "functionality";
}

interface GetTip {
  tipTitle: string | undefined;
  tipDescription: string | undefined;
}

const getTip = ({ tipTitle, tipDescription }: GetTip) => {
  if (tipTitle && tipDescription) {
    return { title: tipTitle, description: tipDescription };
  }
  return undefined;
};

const Activity: React.VFC<Props> = ({ className, activity }) => {
  const { t } = useTranslation();
  const { activityID } = useParams();
  const offsetTop = usePageContentOffsetTop();
  const navigate = useNavigate();
  const match = useMatch("*");

  useEffect(() => {
    if (match) {
      if (activity === "lesson") {
        AnalyticsService.viewedPage(AnalyticsPages.LIBRARY.ACTIVITY, match.pathname, { activityType: "TextLesson" });
      }
      if (activity === "exercise") {
        AnalyticsService.viewedPage(AnalyticsPages.LIBRARY.ACTIVITY, match.pathname, { activityType: "Exercise" });
      }
      if (activity === "functionality") {
        AnalyticsService.viewedPage(AnalyticsPages.LIBRARY.ACTIVITY, match.pathname, {
          activityType: "FunctionalityTest",
        });
      }
    }
  }, [match?.pathname]);

  const { data, isLoading, error } = useActivityLibraryEntry(activityID as string);

  const tipTitle = data?.tip_title;
  const tipDescription = data?.tip_description;

  const renderActivity = () => {
    if (error) return <ErrorTemplate />;
    if (data == null && !isLoading) return <ErrorTemplate errorMessage="errors.activity_not_found" />;
    if (isLoading)
      return (
        <SpinnerContainer data-testid="loading-spinner">
          <Spinner />
        </SpinnerContainer>
      );

    if (data && activity === "lesson") {
      return (
        <ActivityTemplate className={className}>
          <div data-testid="lesson-view">
            {data.content.map(({ type, content }, i) => {
              const key = `${data.id}#${i}`;
              switch (type) {
                case "heading":
                  return <Title key={key}>{content}</Title>;
                case "paragraph":
                  return <Body key={key} dangerouslySetInnerHTML={{ __html: parseHTML(content) }} />;
                case "image":
                  return <Image key={key} src={content} alt={data.title} />;
                default:
                  return null;
              }
            })}
          </div>
        </ActivityTemplate>
      );
    }

    if (data && activity === "exercise") {
      return (
        <ActivityTemplate className={className}>
          <Video
            videoFileUrl={data.video_file_url}
            poster={data.video_thumbnail_file_url}
            captionFileUrl={data.caption_file_url || undefined}
          />
          <HeaderContainer data-testid="exercise-header">
            <Header>{`${data.title} ${data.level}`}</Header>
            {data.premium && <PremiumIcon size="big" premiumType="guided_care" margin="8px 0 0 0 " />}
            <Variation>{data.variation_title}</Variation>
          </HeaderContainer>
          <ExerciseInstruction
            instruction={data.instructions_text1 ?? ""}
            moreInstructions={data.instructions_text3 ?? ""}
            reps={data.reps?.toString() ?? ""}
            body={data.instructions_text2}
            icon={data.instructions_icon_name}
            tip={getTip({ tipTitle, tipDescription })}
          />
          {data.about && (
            <div>
              <SubHeader>{t("library.activity.exercise.about")}</SubHeader>
              <Body dangerouslySetInnerHTML={{ __html: parseHTML(data.about) }} />
            </div>
          )}
        </ActivityTemplate>
      );
    }

    if (data && activity === "functionality") {
      return (
        <ActivityTemplate className={className}>
          <div data-testid="functionality-view">
            <Video videoFileUrl={data.video_file_url} poster={data.video_thumbnail_file_url} />
            <Title>{data.title}</Title>
            <Tip>
              <TipTitle>{data.tip_title}</TipTitle>
              <TipBody>{data.tip_description}</TipBody>
            </Tip>
            {data.content.map(({ type, content }, i) => {
              const key = `${data.id}#${i}`;
              switch (type) {
                case "heading":
                  return <Title key={key}>{content}</Title>;
                case "paragraph":
                  return <Body key={key} dangerouslySetInnerHTML={{ __html: parseHTML(content) }} />;
                default:
                  return null;
              }
            })}
          </div>
        </ActivityTemplate>
      );
    }

    return null;
  };

  return (
    <Container $pageContentOffsetTop={offsetTop}>
      <Hidden type="belowTablet">
        <Popup fullBgOpacity onClickOutside={() => navigate(-1)}>
          {renderActivity()}
        </Popup>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal open close={() => navigate(-1)} title={data?.title}>
          {renderActivity()}
        </Modal>
      </Hidden>
    </Container>
  );
};

export default Activity;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  display: block;
  ${props => props.theme.font.header3};
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const Body = styled.span`
  display: block;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.primary.base};
  white-space: pre-wrap;
`;

const Image = styled.img`
  width: 100%;
`;

const HeaderContainer = styled.div`
  margin: ${props => props.theme.spacing.S_20} 0 ${props => props.theme.spacing.S_10} 0;
`;

const Header = styled.div`
  ${props => props.theme.font.header3};
  color: ${props => props.theme.colors.primary.base};
`;

const Variation = styled.div`
  ${props => props.theme.font.header5};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.iron};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const SubHeader = styled.div`
  ${props => props.theme.font.header4};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const Tip = styled.div`
  background: ${props => props.theme.colors.functionality.background};
  border-radius: ${props => props.theme.borderRadius.library};
  padding: ${props => props.theme.spacing.S_15};
  margin: ${props => props.theme.spacing.S_20} 0;
`;

const TipTitle = styled.div`
  text-transform: lowercase;
  ${props => props.theme.font.small_caps_title};
  color: ${props => props.theme.colors.functionality.accent};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const TipBody = styled.div`
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.primary.base};
`;
