import type React from "react";

import styled from "styled-components";

import { Calendar } from "../components/Calendar";

export const CalendarView: React.VFC = () => {
  return (
    <PageContainer>
      <Calendar showButtons />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  position: relative;
`;
