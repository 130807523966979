import type { SetStateAction } from "react";
import type React from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { usePutPatientMarkAsPrioMutation } from "api/hooks/usePutPatientMarkAsPrioMutation";
import { CancelIcon, StarHollow, VideoCallIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { CurrentPatientContext } from "utils/contexts";
import { useCallFeatures } from "utils/hooks";
import { userIsDoctor } from "utils/profile/profileHelper";

import { Button, DropdownItem } from "./helpers";
import NewNoteMenu from "./NewNoteMenu";
import PhoneCallButton from "./PatientDataList/PhoneCallButton/PhoneCallButton";
import VideoCallButton from "./PatientDataList/VideoCallButton";
import VoiceCallButton from "./PatientDataList/VoiceCallButton";
import PatientHeaderDropdown, { PatientHeaderDropdownSub } from "./PatientHeaderDropdown/PatientHeaderDropdown";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  showEndTreatmentForm: boolean | undefined;
  setShowEndTreatmentForm: React.Dispatch<SetStateAction<boolean>> | undefined;
}

const PatientHeaderDropdownMenu: React.VFC<Props> = ({
  isOpen,
  onClose,
  showEndTreatmentForm,
  setShowEndTreatmentForm,
}) => {
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();

  invariant(profile);

  const [subOpen, setSubOpen] = useState(false);
  const isDoctor = userIsDoctor(profile);
  const { t } = useTranslation();
  const { videoCallsEnabled, voiceCallsEnabled, phoneCallsEnabled } = useCallFeatures();
  const putPatientMarkAsPrioMutation = usePutPatientMarkAsPrioMutation({
    patientId: patient?.id,
    therapistId: profile.id,
    therapistMarket: profile.market,
    previousTreatmentId,
  });

  const togglePriority = () => {
    const variables = {
      data: {
        prio: !patient.prio,
      },
    };

    putPatientMarkAsPrioMutation.mutate(variables);
  };

  if (isDoctor) {
    return (
      <PatientHeaderDropdown
        open={isOpen}
        closeMenu={onClose}
        subOpen={subOpen}
        data-testid="patient-header-dropdown-menu"
      >
        <DropdownItem>
          <VideoCallButton onCallInitiated={onClose} />
        </DropdownItem>
      </PatientHeaderDropdown>
    );
  }

  const callMenuItem = () => {
    const callFeatures = [
      {
        key: "voice",
        enabled: voiceCallsEnabled,
        component: <VoiceCallButton />,
      },
      {
        key: "video",
        enabled: videoCallsEnabled,
        component: <VideoCallButton onCallInitiated={onClose} />,
      },
      {
        key: "phone",
        enabled: phoneCallsEnabled,
        component: <PhoneCallButton />,
      },
    ];

    const enabledCallFeatures = callFeatures.filter(feature => feature.enabled);

    if (enabledCallFeatures.length === 1) {
      return <DropdownItem>{enabledCallFeatures[0].component}</DropdownItem>;
    }

    if (enabledCallFeatures.length > 1) {
      return (
        <PatientHeaderDropdownSub
          isMainOpen={isOpen}
          onVisibleChange={visible => {
            setSubOpen(visible);
          }}
          data-testid="patient-header-dropdown-submenu"
          label={
            <>
              <VideoCallIcon />
              {t("buttons.call")}
            </>
          }
        >
          {enabledCallFeatures.map(callFeature => (
            <DropdownItem key={callFeature.key}>{callFeature.component}</DropdownItem>
          ))}
        </PatientHeaderDropdownSub>
      );
    }

    return null;
  };

  return (
    <PatientHeaderDropdown
      open={isOpen}
      closeMenu={onClose}
      subOpen={subOpen}
      data-testid="patient-header-dropdown-menu"
    >
      <DropdownItem>
        <Button
          onClick={() => {
            togglePriority();
            onClose();
          }}
        >
          <StarHollow style={{ width: 20, height: 20 }} />
          {patient.prio
            ? t("patients.header_info.unmark_as_priority_button")
            : t("patients.header_info.mark_as_priority_button")}
        </Button>
      </DropdownItem>
      <NewNoteMenu isOpen={isOpen} onClose={onClose} setSubOpen={setSubOpen} />
      {callMenuItem()}
      <DropdownItem>
        <Button
          onClick={() => {
            if (setShowEndTreatmentForm) {
              setShowEndTreatmentForm(!showEndTreatmentForm);
            }
            onClose();
          }}
        >
          <CancelIcon />
          {t("patients.header_info.end_treatment_button")}
        </Button>
      </DropdownItem>
    </PatientHeaderDropdown>
  );
};

export default PatientHeaderDropdownMenu;
