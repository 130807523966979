import type React from "react";
import { useState } from "react";

import { useQueryClient } from "react-query";

import pinMessageQuery from "routes/messages/queries/pinMessage";
import unpinMessageQuery from "routes/messages/queries/unpinMessage";
import type { PinnedMessage } from "routes/messages/types";
import { QUERY_KEYS } from "types/QueryKeys";

import PinnedMessagesContext from "./PinnedMessagesContext";

const PinnedMessagesContextProvider: React.FC = ({ children }) => {
  const [pinnedMessages, setPinnedMessages] = useState<PinnedMessage[]>([]);
  const queryClient = useQueryClient();

  const pinMessage = async (message: PinnedMessage) => {
    // Check if conversation already have a pinned message, unpin before pinning new message
    if (pinnedMessagesInConversation(message.peerId).length > 0) {
      try {
        pinnedMessagesInConversation(message.peerId).forEach(pinnedMessage => {
          unpinMessage(pinnedMessage.id);
        });
      } catch (error) {
        // TODO: Add error logging
      }
    }

    try {
      await pinMessageQuery(message.id);
      setPinnedMessages(prevState => [...prevState, { ...message }]);
      await queryClient.invalidateQueries(QUERY_KEYS.unreadMessagesCount);
    } catch (error) {
      // TODO: Add error logging
    }
  };

  const unpinMessage = async (id: number) => {
    try {
      await unpinMessageQuery(id);
      setPinnedMessages(prevState => prevState.filter(pinnedMessage => pinnedMessage.id !== id));
      await queryClient.invalidateQueries(QUERY_KEYS.unreadMessagesCount);
    } catch (error) {
      // TODO: Add error logging
    }
  };

  const messageIsPinned = (id: number) => {
    return pinnedMessages.filter(pinnedMessage => pinnedMessage.id === id).length > 0;
  };

  const pinnedMessagesInConversation = (peerId: number) => {
    return pinnedMessages.filter(pinnedMessage => pinnedMessage.peerId === peerId);
  };

  const pinnedMessageInConversation = (peerId: number) => {
    return pinnedMessages.filter(pinnedMessage => pinnedMessage.peerId === peerId)?.[0];
  };

  const value = { setPinnedMessages, pinMessage, unpinMessage, messageIsPinned, pinnedMessageInConversation };
  return <PinnedMessagesContext.Provider value={value}>{children}</PinnedMessagesContext.Provider>;
};

export default PinnedMessagesContextProvider;
