import type React from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";

import type { ActivityTypes } from "api/models/Activity";
import { CommentsIcon, CrossedBackground, SaveSuccess } from "assets";
import ActionButton from "shared/atoms/ActionButton";
import ActivityIcon from "shared/atoms/ActivityIcon";
import ActivityLevels from "shared/atoms/ActivityLevels";
import PremiumIcon from "shared/atoms/PremiumIcon";
import Tooltip from "shared/molecules/Tooltip";
import { IgnoreClickOutsideContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import CommentReply from "../CommentReply";

interface CalendarCardProps {
  comment: string;
  commentPlace?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
    | "side-left"
    | "side-right";
  completed?: string | null;
  completedAt?: string | null;
  currentLevel?: number | null;
  id: number;
  isPast?: boolean;
  levels?: number | null;
  openActivity?: () => void;
  reply?: string | null;
  required?: boolean;
  responsive?: boolean;
  subtitle?: string | null;
  title: string;
  type?: ActivityTypes;
  premium?: boolean | null;
}

const CalendarCard: React.VFC<CalendarCardProps> = ({
  type = "exercise",
  title,
  subtitle = null,
  levels = null,
  currentLevel = null,
  isPast = false,
  required = false,
  completed = null,
  responsive = false,
  comment,
  commentPlace = "side-right",
  id,
  openActivity = () => undefined,
  reply,
  premium = false,
}) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [ignoreClickOutside, setIgnoreClickOutside] = useState(false);
  const theme = useContext(ThemeContext);

  validateLevels({ levels, currentLevel, type });
  validateCurrentLevel({ levels, currentLevel, type });

  return (
    <CardWrapper responsive={responsive}>
      <IgnoreClickOutsideContext.Provider value={{ setIgnoreClickOutside }}>
        <Tooltip
          isOpen={tooltipOpen}
          offset={commentPlace}
          requestClose={() => setTooltipOpen(false)}
          ignoreClickOutside={ignoreClickOutside}
        >
          <CommentReply comment={comment} reply={reply} activity={id} />
        </Tooltip>
      </IgnoreClickOutsideContext.Provider>
      <Container
        onClick={openActivity}
        commentOpen={tooltipOpen}
        responsive={responsive}
        isPast={isPast}
        completed={Boolean(completed)}
        color={completed ? theme.colors[type].accent : theme.colors[type].background}
        data-testid="calendar-card"
      >
        <Title>
          <IconsWrapper>
            <ActivityIcon type={type} />
            {premium && <PremiumIcon size="big" premiumType="guided_care" margin="0 0 0 8px" />}
          </IconsWrapper>
          <TextWrapper completed={Boolean(completed)}>
            {required && "*"}
            {title}
          </TextWrapper>
          {type === "exercise" && typeof levels === "number" && typeof currentLevel === "number" && (
            <ActivityLevels size="small" levels={levels} currentLevel={currentLevel} invert={Boolean(completed)} />
          )}
          {subtitle && <Subtitle completed={Boolean(completed)}>{subtitle}</Subtitle>}
        </Title>
        <BottomWrapper>
          {completed && required && (
            <CompletedWrapper>
              {t("patients.protocol.completed")}
              <Date>{format(parseISO(completed), "d MMM")}</Date>
            </CompletedWrapper>
          )}

          {comment && (
            <ActionButton
              m="0 4px 0 0"
              onClick={e => {
                e.stopPropagation();
                setTooltipOpen(true);
              }}
            >
              <CommentsIcon data-testid="message-button" />
            </ActionButton>
          )}
          {Boolean(completed) && <SaveSuccess style={{ height: "20px", width: "20px", marginLeft: "auto" }} />}
        </BottomWrapper>
      </Container>
    </CardWrapper>
  );
};

export default CalendarCard;

const validateLevels = (props: { levels: number | null; currentLevel: number | null; type: ActivityTypes }) => {
  const { levels, currentLevel, type } = props;
  const propName = "levels";
  const componentName = "CalendarCard";

  if (type === "exercise" && levels === null) {
    return new Error(`Missing prop, levels is required for type 'exercise' in ${componentName}`);
  }
  if (levels && typeof levels !== "number") {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}, expected number`);
  }
  if (levels && currentLevel && currentLevel > levels) {
    return new Error(`Invalid props, currentLevel need to be smaller or equal to levels in ${componentName}`);
  }
  return null;
};

const validateCurrentLevel = (props: { levels: number | null; currentLevel: number | null; type: ActivityTypes }) => {
  const { currentLevel, levels, type } = props;
  const propName = "currentLevel";
  const componentName = "CalendarCard";

  if (type === "exercise" && currentLevel === null) {
    return new Error(`Missing prop, currentLevel is required for type 'exercise' in ${componentName}`);
  }
  if (currentLevel && typeof currentLevel !== "number") {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}, expected number`);
  }
  if (levels && currentLevel && currentLevel > levels) {
    return new Error(`Invalid props, currentLevel need to be smaller or equal to levels in ${componentName}`);
  }
  return null;
};

const CardWrapper = styled.div<{ responsive: boolean }>`
  position: relative;
  width: ${props => (props.responsive ? "100%" : "140px")};
  margin: 0 ${props => !props.responsive && props.theme.spacing.S_5};
`;

const Container = styled.div<{ completed: boolean; isPast: boolean; responsive: boolean; commentOpen: boolean }>`
  background-color: ${props => (props.isPast && !props.completed ? props.theme.colors.greys.light3 : props.color)};
  ${props => props.isPast && !props.completed && `background-image: url(${CrossedBackground});`}
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  border-radius: ${props => props.theme.borderRadius.basic};
  overflow: hidden;
  border: ${props => (props.isPast && !props.completed ? `1px solid ${props.theme.colors.redesign.db20}` : "none")};
  height: 160px;
  ${props => props.responsive && "max-width: 140px;"}
  width: ${props => (props.responsive ? "100%" : "140px")};
  ${props => props.responsive && "min-width: 120px;"}
  padding: ${props => props.theme.spacing.S_10};
  margin-bottom: ${props => props.theme.spacing.S_10};
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    transition: transform 300ms ease, box-shadow 300ms ease;
    transform: translateY(-5px);
  }
  ${props =>
    props.commentOpen &&
    `box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  `}
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div<{ completed: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${props => props.theme.spacing.S_5};
  color: ${props => (props.completed ? props.theme.colors.white : props.theme.colors.primary.base)};
  white-space: pre-wrap;
`;

const Subtitle = styled.div<{ completed: boolean }>`
  color: ${props => (props.completed ? props.theme.colors.white : props.theme.colors.greys.gunmetal)};
  ${props => props.completed && "opacity: 0.7;"}
  ${props => props.theme.font.caption}
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CompletedWrapper = styled.div`
  height: 40px;
  flex-grow: 1;
  margin-right: ${props => props.theme.spacing.S_5};
  padding-top: 2px;
  ${props => props.theme.font.caption3}
  color: ${props => props.theme.colors.white};
`;

const Date = styled.div`
  font-size: 14px;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
