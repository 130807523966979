import type React from "react";
import { useEffect } from "react";

import { useMatch } from "react-router-dom";

import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import PatientOverview from "../components/Overview";

const Overview: React.VFC = () => {
  const match = useMatch("*");

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.OVERVIEW, match.pathname);
    }
  }, [match?.pathname]);

  return (
    <>
      <PatientOverview />
    </>
  );
};

export default Overview;
