import type React from "react";
import { useCallback, useState } from "react";

import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { userIsDoctor } from "utils/profile/profileHelper";

import CallConfirmationPopup from "./components/CallConfirmationPopup";
import VideoCall from "./views/VideoCall";

const VideoCallRoute: React.VFC = () => {
  const { callData, setCallData, minimised, setMinimised } = useVideoCallContext();
  const { profile } = useProfileContext();
  const isDoctor = userIsDoctor(profile);
  const [lastCallId, setLastCallId] = useState<number | null>(null);

  const endCall = useCallback(() => {
    setCallData(null);
    setMinimised(false);
  }, [setCallData, setMinimised]);

  const callInitiated = (callId: number | null) => {
    setLastCallId(callId);
  };

  return (
    <>
      {callData && (
        <VideoCall
          callData={callData}
          minimised={minimised}
          setMinimised={setMinimised}
          onCallEnded={endCall}
          onCallInitiated={callInitiated}
        />
      )}
      {isDoctor && <CallConfirmationPopup callId={lastCallId} />}
    </>
  );
};

export * from "./types";

export default VideoCallRoute;
