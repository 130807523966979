import type React from "react";

import type { RadioGroupProps } from "@material-ui/core";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { MUIErrorText, MUILabelBase } from "../StyledInputs";

export interface Option {
  label: string;
  value: string | number;
}

interface Props {
  readonly disabled?: boolean;
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly options: Array<Option>;
  readonly required?: boolean;
  readonly onChange?: (event: Parameters<NonNullable<RadioGroupProps["onChange"]>>[0]) => void;
}

const MUIRadioGroup: React.VFC<Props> = ({ disabled, error, label, name, options, required, onChange }) => {
  const { control } = useFormContext();

  return (
    <Container>
      {label && <StyledFormLabel component="legend">{label}</StyledFormLabel>}
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field }) => (
          <StyledRadioGroup
            value={field.value ?? ""}
            row
            onChange={event => {
              field.onChange(event);
              if (onChange) onChange(event);
            }}
            data-testid={`radiogroup-${name}`}
          >
            {options.map(option => (
              <FormControlLabel
                control={
                  <Radio color="primary" disabled={disabled} data-testid={`radiogroup-option-${option.value}`} />
                }
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </StyledRadioGroup>
        )}
      />

      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

const Container = styled.div``;

const StyledFormLabel = styled(FormLabel)<{ component: string }>`
  &.MuiFormLabel-root {
    ${props => props.theme.font.input.label_standalone}
    margin-top: ${props => props.theme.spacing.S_10};
    color: ${props => props.theme.colors.redesign.db90};
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  &.MuiFormGroup-root {
    margin-bottom: -12px;
  }
  .MuiFormControlLabel-label {
    ${MUILabelBase}
    user-select: none;
  }
  .MuiRadio-root {
    color: ${props => props.theme.colors.redesign.db30};
    &.Mui-checked {
      color: ${props => props.theme.colors.redesign.b60};
    }
    &.Mui-focusVisible,
    &.Mui-focusVisible + .MuiTypography-root {
      color: ${props => props.theme.colors.redesign.b60};
    }
    &.Mui-disabled {
      color: ${props => props.theme.colors.greys.silver};
    }
  }
`;

const ErrorText = styled.div`
  margin-top: 9px;
  ${MUIErrorText}
`;

export default MUIRadioGroup;
