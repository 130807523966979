import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "shared/atoms/Button";

import { BOOKING_SCREENS } from "../AdminBookingForm";
import { Container } from "../misc";

interface Props {
  setMode: (mode: string) => void;
}

export const SelectMode: React.VFC<Props> = ({ setMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <PrimaryButton
        onClick={() => {
          setMode("digital");
          navigate(`../${BOOKING_SCREENS.SELECT_SERVICE}`);
        }}
        style={{ marginBottom: "16px" }}
      >
        {t("booking.manager_booking.select_mode.digital_visit")}
      </PrimaryButton>
    </Container>
  );
};
