import { useMutation } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import type { PutAppointmentArgs } from "api/types/PostAppointment";
import useApiClient from "api/useApiClient";

export const usePutAppointment = () => {
  const client = useApiClient();

  return useMutation<NewAppointment, Error, PutAppointmentArgs>(args => client.putAppointment(args));
};
