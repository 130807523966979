import { useMutation } from "react-query";

import type { DeleteBookingIntervalArgs } from "api/schemas/BookingInterval";
import useApiClient from "api/useApiClient";

export const useDeleteBookingInterval = () => {
  const client = useApiClient();

  return useMutation<null, Error, DeleteBookingIntervalArgs>({
    mutationFn: args => client.deleteBookingInterval(args),
  });
};
