import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

// import type { Appointment } from "api/schemas/Appointment";
import useApiClient from "api/useApiClient";

type QueryKey = ["useGetAppointments", number];
export const getAppointmentsQueryKey = (userId: number): QueryKey => ["useGetAppointments", userId];

// TODO: Use Appointment[] type from schema when refactoring all usages
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUpcomingCalls = (userId: number, options?: UseQueryOptions<any[], Error, any[], QueryKey>) => {
  const client = useApiClient();

  return useQuery(getAppointmentsQueryKey(userId), () => client.getAppointments(userId), options);
};

export default useUpcomingCalls;
