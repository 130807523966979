import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Payer } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import MUITextInput from "shared/atoms/inputs/MUITextInput";
import type { MedicalNoteFieldConfig, MedicalNoteType } from "types";
import { CurrentPatientContext } from "utils/contexts";

import { DynamicField, LegacySection, SectionRow } from ".";

interface Props {
  readonly readonly: boolean;
  readonly fields: MedicalNoteFieldConfig[] | undefined;
  readonly legacyText: string | undefined | null;
  readonly onChange?: () => void;
  readonly label?: MedicalNoteType;
  readonly payer?: Payer;
}

const TopSection: React.VFC<Props> = ({ readonly, fields, legacyText, onChange, label, payer }) => {
  const { patient } = useContext(CurrentPatientContext);
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const personalNumber = patient?.personal_number;

  return (
    <Container data-testid="top-section">
      <StyledSectionRow>
        <NameField
          name="patient_name"
          label={t("patients.extended_medical_note.patient_name.label")}
          value={`${patient.first_name} ${patient.last_name}`}
          disabled
          unregistered
        />
        <DateField
          name={personalNumber ? "patient_personal_number" : "patient_birthday"}
          label={
            personalNumber
              ? t("patients.medical_records.personal_number")
              : t("patients.extended_medical_note.patient_birthday.label")
          }
          value={
            personalNumber ||
            (patient.birthday && new Date(patient.birthday).toLocaleDateString(profile?.locale, { timeZone: "UTC" }))
          }
          disabled
          unregistered
        />
      </StyledSectionRow>
      {fields &&
        fields.map(field => (
          <SectionRow key={`section-row-${field.name}`}>
            <DynamicField
              type={field.type}
              name={field.name}
              readonly={readonly}
              required={field.required}
              options={field.options}
              onChange={onChange}
              note_type={label}
              payer={payer}
            />
          </SectionRow>
        ))}
      {legacyText && <LegacySection legacyText={legacyText} />}
    </Container>
  );
};

const Container = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  padding-right: 120px;
  border-bottom: 1px solid ${props => props.theme.colors.redesign.db30};

  ${props => props.theme.belowBreakpoint} {
    padding-right: ${props => props.theme.spacing.S_20};
  }
`;

const StyledSectionRow = styled(SectionRow)`
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const NameField = styled(MUITextInput)`
  flex: 1;
  margin-right: ${props => props.theme.spacing.S_15};

  ${props => props.theme.belowBreakpoint} {
    margin-right: 0;
  }
`;

const DateField = styled(MUITextInput)`
  width: 135px;
  flex: none;

  ${props => props.theme.belowBreakpoint} {
    width: auto;
    flex: 1;
  }
`;

export { TopSection };
