import type { MouseEvent } from "react";
import type React from "react";

import styled from "styled-components";

import Hidden from "shared/atoms/Hidden";

const Container = styled.div<Pick<Props, "small" | "disabled">>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${props => (props?.small ? props.theme.font.link3 : props.theme.font.link1)};
  color: ${props => props.theme.colors.primary.base};
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    ${props => !props.disabled && `opacity: 0.7;`}
  }
  cursor: pointer;
  user-select: none;
  ${props =>
    props.disabled &&
    ` cursor: default;
    color: ${props.theme.colors.greys.pinkish};
  `}
`;

const IconWrapper = styled.div<Pick<Props, "small" | "disabled" | "iconToRight">>`
  margin-left: ${({ iconToRight }) => (iconToRight ? "8px !important" : "none")};

  &:first-child {
    width: ${props => (props?.small ? "20px" : "24px")};
    height: ${props => (props?.small ? "20px" : "24px")};
  }
  ${props =>
    props.disabled &&
    `&:first-child path {
      fill: ${props.theme.colors.greys.pinkish};
    }`}
`;

const LabelWrapper = styled.div`
  margin-left: ${props => props.theme.spacing.S_10};
`;

interface Props {
  readonly label: string;
  readonly small?: boolean;
  readonly children?: React.ReactNode;
  readonly disabled?: boolean;
  readonly onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  readonly onDisabled?: () => void;
  readonly hideLabel?: "aboveTablet" | "belowTablet" | "aboveMobile" | "belowMobile";
  readonly className?: string;
  readonly dataTestId?: string;
  readonly iconToRight?: boolean;
}

const IconButton: React.VFC<Props> = ({
  label,
  className,
  children = null,
  small = false,
  disabled = false,
  onClick = () => undefined,
  onDisabled = () => undefined,
  hideLabel = null,
  dataTestId = "icon-button",
  iconToRight = false,
}) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      onDisabled();
    } else {
      onClick(event);
    }
  };

  return (
    <Container
      className={className}
      small={small}
      data-testid={dataTestId}
      disabled={disabled}
      onClick={handleClick}
      role="button"
    >
      {children && !iconToRight && (
        <IconWrapper small={small} disabled={disabled}>
          {children}
        </IconWrapper>
      )}
      {hideLabel !== null ? (
        <Hidden type={hideLabel}>
          <LabelWrapper>{label}</LabelWrapper>
        </Hidden>
      ) : (
        <LabelWrapper>{label}</LabelWrapper>
      )}

      {children && iconToRight && (
        <IconWrapper small={small} disabled={disabled} iconToRight={iconToRight}>
          {children}
        </IconWrapper>
      )}
    </Container>
  );
};
export default IconButton;
