import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetSuggestedCareEventsArgs, GetSuggestedCareEventsResponse } from "api/types/GetSuggestedCareEvents";
import useApiClient from "api/useApiClient";

type QueryKey = [string, number];
export const getSuggestedCareEventsQueryKey = ({ patientId }: GetSuggestedCareEventsArgs): QueryKey => [
  "GetSuggestedCareEvents",
  patientId,
];

export const useGetSuggestedCareEventsQuery = (
  args: GetSuggestedCareEventsArgs,
  options?: UseQueryOptions<GetSuggestedCareEventsResponse, Error, GetSuggestedCareEventsResponse, QueryKey>
): UseQueryResult<GetSuggestedCareEventsResponse, Error> => {
  const client = useApiClient();

  return useQuery(getSuggestedCareEventsQueryKey(args), () => client.getSuggestedCareEvents(args), options);
};
