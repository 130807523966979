import type React from "react";
import { useEffect, useState } from "react";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import useGetProfessionals from "api/hooks/useGetProfessionals";
import type { Personnel } from "contexts/CalendarContext";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { MUIBorder, MUIInput, MUITextInputLabel } from "shared/atoms/inputs/StyledInputs";

import { HCPROF_COLORS } from "./Calendar/helpers/transformAppointments";
import { BlueIconButton } from "./styles";

export const PersonnelAutocomplete: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { profile } = useProfileContext();
  const { selectedPersonnel, setSelectedPersonnel } = useCalendarContext();
  const [professionalOptions, setProfessionalOptions] = useState<Personnel[]>([]);

  invariant(profile);

  const { data = [] } = useGetProfessionals(profile.id);

  useEffect(() => {
    if (data.length > 0) {
      const professionals: Personnel[] = data?.map((professional, i) => ({
        id: professional.health_care_professional_id,
        full_name: professional.health_care_professional_full_name,
        bgColor: HCPROF_COLORS[i]?.bgColor,
        borderColor: HCPROF_COLORS[i]?.borderColor,
        availabilityColor: HCPROF_COLORS[i]?.availabilityColor,
      }));
      setProfessionalOptions(professionals);

      if (selectedPersonnel.length <= 0) {
        setSelectedPersonnel(professionals);
      }
    }
  }, [data]);

  const onChange = (_event: React.ChangeEvent<unknown>, value: Personnel[]) => {
    setSelectedPersonnel(value);
  };

  return (
    <Container>
      <TopSection>
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={professionalOptions}
          value={selectedPersonnel}
          onChange={onChange}
          getOptionLabel={option => option.full_name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              {...params}
              label={
                selectedPersonnel.length === 0
                  ? t("booking.calendar.selected_personnel_all")
                  : t("booking.calendar.selected_personnel", { number: selectedPersonnel.length })
              }
              variant="outlined"
            />
          )}
          renderTags={() => null}
          style={{ width: "320px", background: "#fff", borderRadius: "8px" }}
        />

        <BlueIconButton
          dataTestId="booking-services"
          label={t("booking.services.title")}
          onClick={() => {
            navigate("services");
          }}
        >
          <FontAwesomeIcon icon={faEdit} color={theme.colors.redesign.b100} style={{ height: "16px", width: "16px" }} />
        </BlueIconButton>
      </TopSection>

      <Chips style={{ marginBottom: selectedPersonnel.length ? "8px" : "auto" }}>
        {selectedPersonnel.map(personnel => {
          return (
            <Chip
              key={personnel.id}
              label={personnel.full_name}
              style={{ margin: "0 8px 8px 0", background: personnel.bgColor }}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onDelete={() => {}}
              deleteIcon={
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  onMouseDown={event => {
                    event.stopPropagation();
                    setSelectedPersonnel(prevState => [
                      ...prevState.filter(prevPersonnel => prevPersonnel.id !== personnel.id),
                    ]);
                  }}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faCircleX} style={{ height: "18px", width: "18px" }} />
                </div>
              }
            />
          );
        })}
      </Chips>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .MuiAutocomplete-root {
    background-color: white;
    border-radius: ${props => props.theme.borderRadius.basic};
    min-width: 116px;

    .MuiFormLabel-root {
      display: block;
    }
    .MuiFormControl-root {
      .MuiInputBase-root {
        padding: 23px 12px 7px;
        .MuiInputBase-input {
          padding: 0;
        }
      }
    }

    .MuiInputBase-root {
      padding: 23px 12px 7px;

      &.Mui-disabled {
        background-color: ${props => props.theme.colors.greys.light2};
      }
      .MuiChip-root {
        opacity: 1;
        color: ${props => props.theme.colors.primary.base};
      }
    }
    ${MUIInput}
    ${MUIBorder}
    ${MUITextInputLabel}
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;
