import type React from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { BackIcon, JointAcademyLogo, SupportIcon } from "assets";
import Hidden from "shared/atoms/Hidden";
import Logo from "shared/NavBar/components/Logo";
import { MarketTypes } from "types";
import { getSupportLink, isMarket } from "utils/market";
import { StatuspageBar } from "utils/statuspage/components/StatuspageBar";

import { MarketLoginSelector } from "../MarketLoginSelector";

interface Props {
  backButton?: boolean;
  marketLoginSelector?: boolean;
}

export const LoginPageWrapper: React.FC<Props> = ({ children, backButton, marketLoginSelector }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper data-testid="login-page-wrapper">
      <StatuspageBar />

      <LoginPageLogo fullLogo />

      <Hidden type="aboveTablet">
        <TopSection>
          {backButton && (
            <BackButton data-testid="back-button" to="/login/email">
              <BackIcon />
            </BackButton>
          )}

          <JointAcademyLogo />
        </TopSection>
      </Hidden>

      {children}

      {marketLoginSelector && !isMarket(MarketTypes.US) && <MarketLoginSelector />}

      <SupportSection>
        <Question>{t("common.questions")}</Question>
        <Email href={`mailto:${getSupportLink()}`}>
          <StyledIcon />
          {getSupportLink()}
        </Email>
      </SupportSection>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginPageLogo = styled(Logo)`
  margin: 40px 0;

  svg {
    max-height: 90px;
    max-width: 100%;
  }

  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const BackButton = styled(NavLink)`
  color: ${props => props.theme.colors.primary.base};
  position: absolute;
  text-decoration: none;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  left: ${props => props.theme.spacing.S_10};
`;

const TopSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 50px;
  max-height: 50px;
  width: 100%;
  padding: 4px 0 8px;
  background: ${({ theme }) => theme.colors.white};

  svg {
    max-width: 180px;
  }
`;

const SupportSection = styled.section`
  display: flex;
  margin: auto 0 40px;

  ${props => props.theme.belowBreakpoint} {
    margin: 32px 0 8px;
  }
`;

const Question = styled.div`
  color: ${({ theme }) => theme.colors.primary.base};
  ${props => props.theme.font.body1}
  margin-right: ${props => props.theme.spacing.S_10};

  ${props => props.theme.belowLargeBreakpoint} {
    display: none;
  }
`;

const Email = styled.a`
  color: ${props => props.theme.colors.redesign.b100};
  ${props => props.theme.font.body1}
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: 21px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(SupportIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
`;
