import type React from "react";
import { useEffect, useRef, useState } from "react";

import {
  faArrowDownShortWide,
  faArrowsToCircle,
  faBars,
  faCalendarClock,
  faClock,
  faClockRotateLeft,
  faClockTwoThirty,
  faCreditCard,
  faFileSignature,
  faFileWaveform,
  faHospitalUser,
  faHourglassClock,
  faList,
  faStickyNote,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import { useDeleteService } from "api/hooks/useDeleteService";
import useGetServiceTemplate from "api/hooks/useGetServiceTemplate";
import { usePostService } from "api/hooks/usePostService";
import { usePutService } from "api/hooks/usePutService";
import type { HcpServiceManager, ServiceTemplateField } from "api/schemas/HcpService";
import type { ServicePayload } from "api/types/PostService";
import { useProfileContext } from "contexts/ProfileContext";
import { MUICheckBox, MUIDropdown, MUITextInput } from "shared/atoms/inputs";

import { END_TIME, START_TIME } from "../helpers/misc";
import type { FormStates } from "../helpers/misc";
import { convertMinutesToTime, generateArrayWithMinutesStep } from "../helpers/settingsHelpers";

import { MultiselectField } from "./MultiselectField";
import { ServiceFormFooter } from "./ServicesFormFooter";

const iconStyle = { height: "18px", width: "18px" };

type Option = {
  label: string;
  value: string | number;
};

export type ServiceFormData = {
  name: string | undefined;
  description: string | undefined | null;
  health_care_provider_service_category_id: number | undefined;
  purpose: string | undefined | null;
  mode: string;
  health_questionnaire_type: string | undefined;
  capacity: number | undefined;
  opening_hours: number | undefined;
  closing_hours: number | undefined;
  duration: number | undefined;
  no_earlier_than: number | undefined;
  no_later_than: number | undefined;
  payment_methods: string[] | undefined | null;
  health_care_professional_ids: string[] | undefined;
  bookable_by_patients: boolean;
};

interface Props {
  service: HcpServiceManager | undefined;
  refetchServices: () => void;
}

export const ServiceForm: React.VFC<Props> = ({ service, refetchServices }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  invariant(profile);
  const [formDisabled, setFormDisabled] = useState(false);
  const [startTime, setStartTime] = useState<number | undefined>(undefined);
  const [formState, setFormState] = useState<FormStates>("default");
  const [error, setError] = useState<string | null>(null);
  const formRef = useRef<ServiceFormData>();
  const serviceRef = useRef<HcpServiceManager | undefined>(undefined);
  const theme = useTheme();

  const form = useForm<ServiceFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      bookable_by_patients: false,
    },
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
  } = form;

  // APIs
  const { data: service_template } = useGetServiceTemplate(profile.id);
  const deleteServiceMutation = useDeleteService();
  const postServiceMutation = usePostService();
  const putServiceMutation = usePutService();

  const onSubmit = handleSubmit(formData => {
    const data: ServicePayload = {
      name: formData.name ?? "",
      description: formData.description ?? "",
      health_care_provider_service_category_id: formData.health_care_provider_service_category_id
        ? parseInt(formData.health_care_provider_service_category_id.toString(), 10)
        : null,
      capacity: formData.capacity ? parseInt(formData.capacity.toString(), 10) : null,
      mode: formData.mode,
      payment_methods: formData.payment_methods ?? [],
      purpose: formData.purpose ?? "",
      opening_hours: formData.opening_hours ?? null,
      closing_hours: formData.closing_hours ?? null,
      duration: formData.duration ? parseInt(formData.duration.toString(), 10) : null,
      no_earlier_than: formData.no_earlier_than ?? null,
      no_later_than: formData.no_later_than ?? null,
      health_questionnaire_type: formData.health_questionnaire_type ?? "",
      health_care_professional_ids: formData.health_care_professional_ids
        ? formData.health_care_professional_ids.map(option => parseInt(option.toString(), 10))
        : [],
      bookable_by_patients: formData.bookable_by_patients ?? null,
    };

    if (service) {
      putServiceMutation.mutateAsync(
        {
          data: { ...data, id: service.id },
          managerId: profile.id,
        },
        onSetupCallback()
      );
    } else {
      postServiceMutation.mutateAsync(
        {
          data,
          managerId: profile.id,
        },
        onSetupCallback(true)
      );
    }
  });

  const deleteService = () => {
    if (service) {
      deleteServiceMutation.mutateAsync({ managerId: profile.id, serviceId: service.id }, onSetupCallback());
    }
  };

  const onSetupCallback = (triggerFormReset?: boolean) => {
    setFormState("saving");
    setFormDisabled(true);
    setError(null);
    return {
      onSuccess: () => {
        setFormState("success");
        refetchServices();
        setTimeout(() => {
          setFormState("default");
          if (triggerFormReset) reset();
          if (!service) {
            setFormDisabled(false);
            setNewServiceFormValues();
          }
        }, 3000);
      },
      onError() {
        setError(t("errors.generic"));
        setFormState("default");
        setFormDisabled(false);
      },
    };
  };

  // Set the form default values
  // updated upon service or template change
  useEffect(() => {
    // cache form only when switching from new to existing service
    if (service && serviceRef.current === undefined) {
      formRef.current = getValues();
    }
    if (service?.id !== serviceRef.current?.id) setFormState("default"); // resets the state when new service is selected;
    reset(); // clears any validation errors
    setError(null); // clears the form error
    serviceRef.current = service; // cache current service

    // default values
    if (service) {
      setFormDisabled(true);
      setFormValues({
        ...service,
        health_care_professional_ids: service.health_care_professionals
          ? service.health_care_professionals.map(option => option.id.toString())
          : [],
      });
    } else {
      setFormDisabled(false);
      if (formRef.current) {
        setFormValues(formRef.current);
      } else {
        setNewServiceFormValues();
      }
    }
  }, [service, service_template]);

  // controls and updates the end time - it cannot be before the start time
  const workdayStartChange = () => {
    const currentStart = getValues("opening_hours");
    const currentEnd = getValues("closing_hours");
    setStartTime(currentStart);
    if (currentStart && currentEnd && currentStart > currentEnd) {
      setValue("closing_hours", currentStart);
    }
  };

  // triggered when a "Cancel" button is clicked, canceling an edit
  const resetChanges = () => {
    reset();
    setFormDisabled(true);
    if (service) {
      setFormValues({
        ...service,
        health_care_professional_ids: service.health_care_professionals
          ? service.health_care_professionals.map(option => option.id.toString())
          : [],
      });
    }
  };

  // DROPDOWN OPTIONS

  const generateOptions = (field: ServiceTemplateField, key?: string): Option[] => {
    if (service_template === undefined) return [];

    if (key) {
      return (
        service_template[field].map(option => {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { label: `${option} ${t(`booking.services.${key}`)}`, value: option };
        }) ?? []
      );
    }
    return (
      service_template[field].map(option => {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { label: t(`booking.services.${field}_options.${option}`) as string, value: option };
      }) ?? []
    );
  };

  const optionsWorkHours = generateArrayWithMinutesStep(START_TIME, END_TIME).map(option => {
    return { value: option, label: convertMinutesToTime(option) };
  });

  const optionsNoEarlierThan = [
    { label: `1 ${t("common.hour")}`.toLowerCase(), value: 1 * 60 },
    { label: `2 ${t("common.hours")}`.toLowerCase(), value: 2 * 60 },
    { label: `3 ${t("common.hours")}`.toLowerCase(), value: 3 * 60 },
    { label: `4 ${t("common.hours")}`.toLowerCase(), value: 4 * 60 },
  ];

  const optionsNoLaterThan = [
    { label: `3 ${t("common.days")}`.toLowerCase(), value: 3 * 24 * 60 },
    { label: `7 ${t("common.days")}`.toLowerCase(), value: 7 * 24 * 60 },
    { label: `14 ${t("common.days")}`.toLowerCase(), value: 14 * 24 * 60 },
    { label: `28 ${t("common.days")}`.toLowerCase(), value: 28 * 24 * 60 },
  ];

  const optionsHealthQuestionnaireType = [
    { label: t("booking.services.health_questionnaire_type_options.default"), value: "default" },
    { label: t("booking.services.health_questionnaire_type_options.none"), value: "none" },
  ];

  const optionsHealthCareProfessionals =
    service_template?.health_care_professional_ids.map(option => {
      return { label: option.full_name, value: option.id.toString() };
    }) ?? [];

  const optionsCategory =
    service_template?.health_care_provider_service_categories.map(option => {
      return { label: option.name, value: option.id };
    }) ?? [];

  // default values when new service is selected
  const setNewServiceFormValues = () => {
    setValue(
      "health_care_professional_ids",
      optionsHealthCareProfessionals.map(option => option.value.toString())
    );
  };

  // set form values
  const setFormValues = (currentService: ServiceFormData) => {
    setValue("name", currentService.name);
    setValue("capacity", currentService.capacity);
    setValue("purpose", currentService.purpose);
    setValue("health_care_provider_service_category_id", currentService.health_care_provider_service_category_id);
    setValue("mode", currentService.mode);
    setValue("health_questionnaire_type", currentService.health_questionnaire_type);

    setValue("description", currentService.description);
    setValue("opening_hours", currentService.opening_hours);
    setValue("closing_hours", currentService.closing_hours);
    setValue("duration", currentService.duration);
    setValue("no_earlier_than", currentService.no_earlier_than);
    setValue("no_later_than", currentService.no_later_than);

    setValue("payment_methods", currentService.payment_methods);
    setValue("bookable_by_patients", currentService.bookable_by_patients);

    if (currentService.health_care_professional_ids)
      setValue(
        "health_care_professional_ids",
        currentService.health_care_professional_ids.map(option => option.toString())
      );
  };

  // Payment methods options are dependent on mode value
  const watchedPaymentMethods = watch("payment_methods");

  // Payment methods values are updated based on latest input - either free option or non free options
  useEffect(() => {
    if (watchedPaymentMethods?.includes("free")) {
      setValue("payment_methods", [watchedPaymentMethods.at(-1) ?? ""]);
    }
  }, [watchedPaymentMethods?.at(-1)]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={onSubmit} data-testid="service-form" noValidate>
        {!service && <Title>{t("booking.services.new_service")}</Title>}

        <Row>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faFileSignature} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUITextInput
              name="name"
              label={t("booking.services.name")}
              disabled={formDisabled}
              required
              multiline
              error={errors.name && t("errors.field.required")}
            />
          </Input>

          <Input>
            <Icon icon={<FontAwesomeIcon icon={faStickyNote} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MUITextInput
              name="description"
              label={t("booking.services.description")}
              disabled={formDisabled}
              required
              multiline
              error={errors.description && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon
              icon={
                <FontAwesomeIcon icon={faArrowDownShortWide} color={theme.colors.redesign.db90} style={iconStyle} />
              }
            />
            <MUITextInput
              label={t("booking.services.capacity")}
              name="capacity"
              disabled={formDisabled}
              required
              type="number"
              min={1}
              max={100}
              error={errors.capacity && t("errors.field.required")}
            />
          </Input>
          <Input>
            <Icon icon={<FontAwesomeIcon icon={faClock} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MUIDropdown
              label={t("booking.services.opening_hours")}
              name="opening_hours"
              options={optionsWorkHours}
              onChange={() => workdayStartChange()}
              disabled={formDisabled}
              required
              error={errors.opening_hours && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon icon={<FontAwesomeIcon icon={faList} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MUIDropdown
              label={t("booking.services.category")}
              name="health_care_provider_service_category_id"
              options={optionsCategory}
              disabled={formDisabled}
              required
              error={errors.health_care_provider_service_category_id && t("errors.field.required")}
            />
          </Input>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faClockTwoThirty} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.closing_hours")}
              name="closing_hours"
              options={optionsWorkHours.filter(option => (startTime ? option.value >= startTime : option.value))}
              disabled={formDisabled}
              required
              error={errors.closing_hours && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon icon={<FontAwesomeIcon icon={faBars} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MUIDropdown
              label={t("booking.services.purpose")}
              name="purpose"
              options={generateOptions("purpose")}
              disabled={formDisabled}
              required
              error={errors.purpose && t("errors.field.required")}
            />
          </Input>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faHourglassClock} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.duration")}
              name="duration"
              options={generateOptions("duration", "minutes")}
              disabled={formDisabled}
              required
              error={errors.duration && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faArrowsToCircle} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.mode")}
              name="mode"
              options={generateOptions("mode")}
              disabled={formDisabled}
              required
              error={errors.mode && t("errors.field.required")}
            />
          </Input>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faClockRotateLeft} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.no_earlier_than")}
              name="no_earlier_than"
              options={optionsNoEarlierThan}
              disabled={formDisabled}
              required
              error={errors.no_earlier_than && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faFileWaveform} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.health_questionnaire_type")}
              name="health_questionnaire_type"
              options={optionsHealthQuestionnaireType}
              disabled={formDisabled}
              required
              error={errors.health_questionnaire_type && t("errors.field.required")}
            />
          </Input>
          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faCalendarClock} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUIDropdown
              label={t("booking.services.no_later_than")}
              name="no_later_than"
              options={optionsNoLaterThan}
              disabled={formDisabled}
              required
              error={errors.no_later_than && t("errors.field.required")}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon icon={<FontAwesomeIcon icon={faCreditCard} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MultiselectField
              name="payment_methods"
              label={t("booking.services.payment_methods")}
              optionsFull={generateOptions("payment_methods")}
              disabled={formDisabled}
              required
              error={errors.payment_methods && t("errors.field.required")}
            />
          </Input>

          <Input>
            <Icon
              icon={<FontAwesomeIcon icon={faHospitalUser} color={theme.colors.redesign.db90} style={iconStyle} />}
            />
            <MUICheckBox
              name="bookable_by_patients"
              label={t("booking.services.bookable_by_patients")}
              disabled={formDisabled}
            />
          </Input>
        </Row>

        <Row>
          <Input>
            <Icon icon={<FontAwesomeIcon icon={faUser} color={theme.colors.redesign.db90} style={iconStyle} />} />
            <MultiselectField
              name="health_care_professional_ids"
              label={t("booking.services.health_care_professionals")}
              optionsFull={optionsHealthCareProfessionals}
              disabled={formDisabled}
              required
              error={errors.health_care_professional_ids && t("errors.field.required")}
            />
          </Input>
        </Row>

        <ServiceFormFooter
          formState={formState}
          formDisabled={formDisabled}
          service={service}
          error={error}
          setFormDisabled={setFormDisabled}
          deleteService={deleteService}
          resetChanges={resetChanges}
        />
      </Form>
    </FormProvider>
  );
};

type IconProps = {
  icon: JSX.Element;
};
const Icon: React.VFC<IconProps> = ({ icon }) => {
  return <IconWrapper>{icon}</IconWrapper>;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 24px;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  padding: 24px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.greys.light4};
  color: ${props => props.theme.colors.primary.base};

  ${props => props.theme.belowBreakpoint} {
    padding-left: 8px;
    padding-right: 12px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 40px;
  width: 800px;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    gap: 16px;
    width: auto;
  }
`;

const Input = styled.div`
  display: flex;
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-top: 16px;
  margin-right: 8px;
`;
