import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "emoji-mart/css/emoji-mart.css";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { SendIcon } from "assets";
import type { SavedMessages } from "contexts/SavedInputsContext";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import useBroadCastMessageCall from "routes/messages/queries/useBroadcastMessage";
import { Container, DesktopButton, RelativeWrapper, StyledWrapper } from "routes/messages/styles/input";
import TextArea from "shared/atoms/inputs/TextArea";
import Spinner from "shared/atoms/Spinner";
import { QUERY_KEYS } from "types/QueryKeys";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

interface Props {
  onSuccess: () => void;
}

type GetValues = ReturnType<typeof useForm>["getValues"];

const BroadcastInput: React.VFC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const getValuesRef = useRef<GetValues | null>(null);
  const [error, setError] = useState("");
  const { savedMessages, setSavedMessages } = useSavedInputsContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      if (getValuesRef.current) {
        const inputValue = getValuesRef.current("message");
        if (inputValue) {
          setSavedMessages((m: SavedMessages) => ({ ...m, broadcast: inputValue }));
        } else if (inputValue === "" && savedMessages.broadcast) {
          setSavedMessages((m: SavedMessages) => {
            const messages = { ...m };
            delete messages.broadcast;
            return messages;
          });
        }
      }
    };
  }, []);

  const { register, handleSubmit, reset, setValue, getValues } = useForm();
  const { mutateAsync: broadcastMessages, isLoading: isBroadcasting } = useBroadCastMessageCall();

  useEffect(() => {
    setError("");
    setValue("message", savedMessages.broadcast ? savedMessages.broadcast : "");
  }, []);

  useEffect(() => {
    getValuesRef.current = getValues;
  });

  const onSubmit = handleSubmit(async ({ message }) => {
    broadcastMessages({ body: message })
      .then(() => {
        AnalyticsService.track(AnalyticsEvents.MESSAGES.SEND_BROADCAST_MESSAGE);
        setError("");
        onSuccess();
        if (savedMessages.broadcast) {
          setSavedMessages((m: SavedMessages) => {
            const messages = { ...m };
            delete messages.broadcast;
            return messages;
          });
        }
        reset();
        queryClient.invalidateQueries(QUERY_KEYS.conversations);
      })
      .catch(() => setError(t("errors.generic")));
  });

  return (
    <RelativeWrapper>
      <form data-testid="message-form" onSubmit={onSubmit}>
        <Container $error={error}>
          <StyledWrapper>
            <TextArea
              rows={2}
              padding="0"
              expandToHeight={140}
              placeholder={t("messages.input_placeholder")}
              {...register("message", {
                required: true,
                validate: value => {
                  return !!value.trim();
                },
              })}
              error={error}
              scrollbarVisible={false}
              onValueUpdate={value => setValue("message", value)}
              emojiPicker
              showQuickReplies
              noBorder
            />
          </StyledWrapper>
          <ButtonsWrapper>
            {isBroadcasting ? (
              <Spinner small />
            ) : (
              <DesktopButton type="submit">
                <SendIcon />
              </DesktopButton>
            )}
          </ButtonsWrapper>
        </Container>
      </form>
    </RelativeWrapper>
  );
};

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 8px;
`;

export default BroadcastInput;
