import type React from "react";
import { useCallback } from "react";

import { useVoiceCallContext } from "contexts/VoiceCallContext";

import VoiceCall from "./views/VoiceCall";

const VoiceCallRoute: React.VFC = () => {
  const { voiceCallData, setVoiceCallData } = useVoiceCallContext();

  const endVoiceCall = useCallback(() => {
    setVoiceCallData(null);
  }, [setVoiceCallData]);

  return voiceCallData && <VoiceCall voiceCallData={voiceCallData} onVoiceCallEnded={endVoiceCall} />;
};

export * from "./types";

export default VoiceCallRoute;
