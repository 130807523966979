import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Content, Header } from "routes/calendar/components/EventDetails/styles";
import Popup from "shared/atoms/Popup";

import { insuranceProviders } from "./insuranceProviders";

interface Props {
  onClose: () => void;
}

export const RTMHealthPlans: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const providersAcceptingRTM = insuranceProviders.filter(({ rtm_accepted }) => rtm_accepted);
  const providersNotAcceptingRTM = insuranceProviders.filter(({ rtm_accepted }) => !rtm_accepted);

  return (
    <Popup onClickOutside={onClose} fullBgOpacity noPadding={false} showCloseButton>
      <StyledContent>
        <Header>{t("invite.rtm_health_plans.header")}</Header>
        <Group>
          <Category>{t("invite.rtm_health_plans.rtm_accepted")}</Category>
          {providersAcceptingRTM.map(({ insurance_plan, state }) => (
            <Row key={`${state}-${insurance_plan}`}>
              <Label>
                {state} {insurance_plan}
              </Label>
            </Row>
          ))}
        </Group>
        <Group>
          <Category>{t("invite.rtm_health_plans.rtm_not_accepted")}</Category>
          {providersNotAcceptingRTM.map(({ insurance_plan, state }) => (
            <Row key={`${state}-${insurance_plan}`}>
              <Label>
                {state} {insurance_plan}
              </Label>
            </Row>
          ))}
        </Group>
      </StyledContent>
    </Popup>
  );
};

const StyledContent = styled(Content)`
  max-width: 554px;
  padding: 40px 26px 0;
  overflow-y: auto;
`;

const Group = styled.div`
  margin-bottom: 24px;
`;

const Category = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
`;

const Row = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.redesign.db20};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
