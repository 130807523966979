import { useTranslation } from "react-i18next";
import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import type { Patient } from "routes/patients/PatientsList/queries/fetchPatients";
import fetchPatients from "routes/patients/PatientsList/queries/fetchPatients";
import { QUERY_KEYS } from "types/QueryKeys";

type UsePatientsReadyForDischargeQueryVariables = {
  therapistId: number;
};

const usePatientsReadyForDischargeQuery = (
  inputs: UsePatientsReadyForDischargeQueryVariables,
  options: UseQueryOptions<Patient[], Error, Patient[], [string]> = {}
): UseQueryResult<Patient[], Error> => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureToggleContext();
  const { therapistId } = inputs;

  return useQuery<Patient[], Error, Patient[], [string]>(
    [QUERY_KEYS.dashboard.patientsReadyForDischarge],
    () =>
      fetchPatients(therapistId, hasFeature, t, ["billableCareEvents", "premiumActivationOccurredAt"], {
        include_deleted: false,
        ready_for_discharge: true,
      }),
    options
  );
};

export default usePatientsReadyForDischargeQuery;
