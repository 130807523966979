import type React from "react";

import styled, { css } from "styled-components";
import type { LocalParticipant } from "twilio-video";

import DisabledPublicationsOverlay from "../DisabledPublicationsOverlay";
import usePublications from "../video/hooks/usePublications";

import MainPublication from "./MainPublication";

type MyVideoScreenProps = Pick<MainParticipantProps, "hasRemoteParticipantConnected">;

const MyVideoScreen = styled.div<MyVideoScreenProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;

  ${props =>
    props.hasRemoteParticipantConnected &&
    css`
      background-color: #3c4c60;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: ${props.theme.borderRadius.basic};
      box-shadow: 2px 4px 11px rgba(29, 44, 62, 0.2);
      overflow: hidden;
      width: 120px;
      height: 160px;
      z-index: 1;
    `}
`;

interface MainParticipantProps {
  readonly minimised: boolean;
  readonly participant?: LocalParticipant | null;
  readonly hasRemoteParticipantConnected: boolean;
  readonly videoEnabled: boolean;
  readonly audioEnabled: boolean;
  readonly username: string;
}

const MainParticipant: React.VFC<MainParticipantProps> = ({
  minimised,
  participant = null,
  hasRemoteParticipantConnected,
  audioEnabled,
  videoEnabled,
  username,
}) => {
  const publications = usePublications(participant);

  if (participant && publications.length > 0) {
    return (
      <MyVideoScreen hasRemoteParticipantConnected={hasRemoteParticipantConnected}>
        {publications.map(publication => {
          return (
            <MainPublication
              key={`${publication.kind}_${publication.trackSid}`}
              publication={publication}
              videoEnabled={videoEnabled}
              audioEnabled={audioEnabled}
            />
          );
        })}

        {minimised && (
          <DisabledPublicationsOverlay
            kinds={["video"]}
            minimised={false}
            publications={publications}
            username={username}
          />
        )}
      </MyVideoScreen>
    );
  }

  return null;
};

export default MainParticipant;
