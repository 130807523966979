import type { AxiosResponse } from "axios";

import { AuthClient } from "api";
import { getAppDataRegion } from "utils/region";

const deleteEmptyParams = (params: { [key: string]: string | undefined }) => {
  const cleanParams = params;
  Object.keys(cleanParams).forEach(param => {
    if (cleanParams[param] === null || cleanParams[param] === undefined) {
      delete cleanParams[param];
    }
  });

  return cleanParams;
};

type PendingStatuses = "NO_CLIENT" | "OUTSTANDING_TRANSACTION" | "STARTED" | "USER_SIGN";
type FailedStatuses =
  | "CANCELLED"
  | "CERTIFICATE_ERR"
  | "EXPIRED_TRANSACTION"
  | "INTERNAL_ERROR"
  | "START_FAILED"
  | "USER_CANCEL";
export type LegacyProgressStatus = PendingStatuses | FailedStatuses | "COMPLETE";
type ProgressStatus = "complete" | "failed" | "pending";

export interface StartResponse {
  auto_start_token: string;
  order_reference: string;
  qr_data: string;
}

export const start = (): Promise<AxiosResponse<StartResponse>> =>
  AuthClient.post("/bankid/start", { personal_number: "" });

interface CheckArgs {
  order_reference?: string;
  confirmation_token?: string;
}

export interface CheckResponse {
  legacy_progress_status: LegacyProgressStatus;
  progress_status: ProgressStatus;
  qr_data: string;
  result: {
    id_token: string;
    refresh_token: string;
  };
}

export const check = ({ order_reference, confirmation_token }: CheckArgs): Promise<AxiosResponse<CheckResponse>> =>
  AuthClient.post(
    "/bankid/sign_in",
    deleteEmptyParams({
      order_reference,
      country_code: getAppDataRegion(),
      confirmation_token,
    })
  );
