import type React from "react";

import { animated } from "react-spring";
import styled from "styled-components";

const StyledRow = styled(animated.tr)<{ $hoverEnabled: boolean }>`
  ${props => props.$hoverEnabled && "cursor: pointer;"}
  height: 42px;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  &:hover {
    ${props => props.$hoverEnabled && `background-color: ${props.theme.colors.greys.light2};`}
  }

  &:last-child > td {
    border-bottom-style: none;
  }
`;

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
  hoverEnabled?: boolean;
  className?: string;
}

const TableRow: React.VFC<Props> = ({ children, onClick = undefined, hoverEnabled = true, className = undefined }) => {
  return (
    <StyledRow onClick={onClick && onClick} $hoverEnabled={hoverEnabled} className={className} data-testid="table-row">
      {children}
    </StyledRow>
  );
};

export default TableRow;
