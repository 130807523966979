import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { UserCourseLibraryLessons } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const GET_USER_COURSE_LIBRARY_LESSONS_QUERY_KEY = "useGetUserCourseLibraryLessonsQuery";
type QueryKey = typeof GET_USER_COURSE_LIBRARY_LESSONS_QUERY_KEY;

export const useGetUserCourseLibraryLessonsQuery = (
  options?: UseQueryOptions<UserCourseLibraryLessons, Error, UserCourseLibraryLessons, QueryKey>
): UseQueryResult<UserCourseLibraryLessons, Error> => {
  const client = useApiClient();
  return useQuery(
    GET_USER_COURSE_LIBRARY_LESSONS_QUERY_KEY,
    () => {
      return client.getUserCourseLibraryLessons();
    },
    options
  );
};
