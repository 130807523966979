import { useEffect, useState } from "react";

import theme from "theme";

type WindowSize = {
  height: number;
  width: number;
  isLargeScreen?: boolean;
  isSmallScreen?: boolean;
};

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isLargeScreen: window.innerWidth > parseInt(theme.breakpoint, 10),
    isSmallScreen: window.innerWidth < parseInt(theme.breakpoint, 10),
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isLargeScreen: window.innerWidth > parseInt(theme.breakpoint, 10),
        isSmallScreen: window.innerWidth < parseInt(theme.breakpoint, 10),
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
