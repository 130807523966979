import type React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { LOGIN_URL } from "utils/auth";
import { getRestHeaders } from "utils/cookie";

import { LoginPageWrapper } from "./components/LoginPageWrapper";
import {
  Bankid,
  Email,
  NewPassword,
  OpenDesktopApp,
  OpenMobileApp,
  ResetPassword,
  ResultFailed,
  ScanQR,
} from "./views";

const LoginRoutes: React.VFC = () => {
  const cookie = getRestHeaders();

  if (cookie) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Routes>
        <Route
          path="reset-password"
          element={
            <LoginPageWrapper backButton>
              <ResetPassword />
            </LoginPageWrapper>
          }
        />
        <Route
          path="reset-password/:email"
          element={
            <LoginPageWrapper backButton>
              <ResetPassword />
            </LoginPageWrapper>
          }
        />
        <Route
          path="set-password"
          element={
            <LoginPageWrapper>
              <NewPassword />
            </LoginPageWrapper>
          }
        />
        <Route
          path="bankid"
          element={
            <LoginPageWrapper marketLoginSelector>
              <Bankid />
            </LoginPageWrapper>
          }
        />
        <Route
          path="bankid/open-desktop-app"
          element={
            <LoginPageWrapper backButton>
              <OpenDesktopApp />
            </LoginPageWrapper>
          }
        />
        <Route
          path="bankid/open-mobile-app"
          element={
            <LoginPageWrapper backButton>
              <OpenMobileApp />
            </LoginPageWrapper>
          }
        />
        <Route
          path="bankid/result-failed"
          element={
            <LoginPageWrapper backButton>
              <ResultFailed />
            </LoginPageWrapper>
          }
        />
        <Route
          path="bankid/scan-qr"
          element={
            <LoginPageWrapper backButton>
              <ScanQR />
            </LoginPageWrapper>
          }
        />
        <Route
          path="email"
          element={
            <LoginPageWrapper marketLoginSelector>
              <Email />
            </LoginPageWrapper>
          }
        />
        <Route path="/" element={<Navigate to={LOGIN_URL} />} />
      </Routes>
    </>
  );
};

export default LoginRoutes;
