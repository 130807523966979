import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { AvailableJoints } from "api/schemas/ManagerBooking";
import { PrimaryButton } from "shared/atoms/Button";

import { Container } from "../../misc";

import { HEALTH_QUESTIONNAIRE_SCREENS } from "./HealthQuestionnaire";

interface Props {
  joint?: string;
  setMostPainfulJoint: (value: AvailableJoints) => void;
}

export const LateralLocationScreen: React.VFC<Props> = ({ joint, setMostPainfulJoint }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = (lateralLocation: "left" | "right") => {
    setMostPainfulJoint(`${lateralLocation}_${joint}` as AvailableJoints);
    navigate(`../${HEALTH_QUESTIONNAIRE_SCREENS.PAIN}`);
  };

  return (
    <Container>
      <PrimaryButton onClick={() => onClick("left")} style={{ marginBottom: "16px" }}>
        {t("patients.lateral_location.left")}
      </PrimaryButton>
      <PrimaryButton onClick={() => onClick("right")}>{t("patients.lateral_location.right")}</PrimaryButton>
    </Container>
  );
};
