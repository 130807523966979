import type React from "react";

import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  overflow?: string;
  dataTestId?: string;
  height?: string;
  $isFullWidthOnMobile?: boolean;
}

const Table: React.VFC<Props> = ({
  children,
  className = "",
  overflow = "hidden",
  dataTestId = "table",
  height = "auto",
  $isFullWidthOnMobile = false,
}) => {
  return (
    <TableWrapper
      $height={height}
      className={className}
      $overflow={overflow}
      data-testid={dataTestId}
      $isFullWidthOnMobile={$isFullWidthOnMobile}
    >
      <StyledTable>{children}</StyledTable>
    </TableWrapper>
  );
};

export default Table;

const TableWrapper = styled.div<{ $overflow: string; $height: string; $isFullWidthOnMobile: boolean }>`
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  border-spacing: 0;
  background: ${props => props.theme.colors.white};
  overflow: ${props => props.$overflow};
  height: ${props => props.$height};

  ${props => props.theme.belowBreakpoint} {
    border-left: ${props => props.$isFullWidthOnMobile && "none"};
    border-right: ${props => props.$isFullWidthOnMobile && "none"};
    border-radius: ${props => props.$isFullWidthOnMobile && 0};
  }
`;

const StyledTable = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-style: hidden;
  border-collapse: collapse;
`;
