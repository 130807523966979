import type { AxiosResponse } from "axios";
import type { UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import { AxiosInstance } from "api";

interface BroadCastMessageCallVars {
  body: string;
}

interface BroadCastMessageCall {
  status: string;
}

const postBroadCastMessageCall = (callVars: BroadCastMessageCallVars): Promise<AxiosResponse<BroadCastMessageCall>> =>
  AxiosInstance.post("messages/broadcast", callVars);

const useBroadCastMessageCall = (): UseMutationResult<
  AxiosResponse<BroadCastMessageCall>,
  unknown,
  BroadCastMessageCallVars,
  unknown
> => {
  return useMutation((callVars: BroadCastMessageCallVars) => postBroadCastMessageCall(callVars));
};

export default useBroadCastMessageCall;
