import { useContext } from "react";

import ProfileContext from "./ProfileContext";

const useProfileContext = () => {
  const value = useContext(ProfileContext);

  if (value === null) {
    throw new Error("useProfileContext cannot be used outside the ProfileContextProvider");
  }
  return value;
};

export default useProfileContext;
