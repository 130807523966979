import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetMedicalReferralsResponse } from "api/types/GetMedicalReferrals";
import useApiClient from "api/useApiClient";

type QueryKey = [string, number];
export const getMedicalReferralsQueryKey = (patientId: number): QueryKey => ["GetMedicalReferralsQuery", patientId];

export const useGetMedicalReferrals = (
  patientId: number,
  options?: UseQueryOptions<GetMedicalReferralsResponse, Error, GetMedicalReferralsResponse, QueryKey>
): UseQueryResult<GetMedicalReferralsResponse, Error> => {
  const client = useApiClient();
  return useQuery(getMedicalReferralsQueryKey(patientId), () => client.getMedicalReferrals(patientId), options);
};
