import type React from "react";
import { useEffect, useState } from "react";

import type { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import invariant from "ts-invariant";

import { AxiosInstance } from "api";
import { useProfileContext } from "contexts/ProfileContext";
import { getBroadcastMessages } from "routes/messages/queries/getBroadcastMessages";
import type { Message } from "routes/messages/types";
import Spinner from "shared/atoms/Spinner";
import getPaginationHeaders from "utils/pagination/getPaginationHeaders";
import type { ResponseHeaders } from "utils/pagination/getPaginationHeaders";

import BroadcastBubble from "../BroadcastBubble";
import BroadcastInput from "../BroadcastInput";

const MESSAGES_AMOUNT = 15;

interface State {
  messages: Message[];
  nextUrl: string;
  hasMore: boolean;
  finishedInitialRequest: boolean;
  error: boolean;
}

const BroadcastWindow: React.VFC = () => {
  const { profile } = useProfileContext();
  const { t } = useTranslation();

  invariant(profile);
  const TID = profile.id;
  const [{ error, messages, nextUrl, hasMore, finishedInitialRequest }, setState] = useState<State>({
    error: false,
    nextUrl: "",
    messages: [],
    hasMore: true,
    finishedInitialRequest: false,
  });

  const setError = () => setState(prev => ({ ...prev, error: true }));

  const setMessages = (response: AxiosResponse<Message[]>) => {
    const { data, headers } = response;
    const { next, totalCount } = getPaginationHeaders(headers as ResponseHeaders);
    setState(prev => {
      const newMessages = [...prev.messages, ...data];
      return {
        ...prev,
        messages: newMessages,
        nextUrl: next || prev.nextUrl,
        hasMore: totalCount ? newMessages.length < totalCount : false,
        finishedInitialRequest: true,
      };
    });
  };

  useEffect(() => {
    const initialFetch = async () => {
      try {
        const response = await getBroadcastMessages(TID, { limit: MESSAGES_AMOUNT });
        if (response) setMessages(response);
      } catch (e) {
        setState(prev => ({ ...prev, finishedInitialRequest: true }));
        setError();
        // TODO: Add error logging
      }
    };
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEarlier = async () => {
    try {
      const response = await AxiosInstance.get(nextUrl);
      setMessages(response);
    } catch (e) {
      setError();
      // TODO: Add error logging
    }
  };

  const fetchNewAfterSend = async () => {
    const params: { after?: string } = {};
    if (messages.length > 0) params.after = messages[0].created_at;
    const response = await getBroadcastMessages(TID, params);
    if (response) {
      const newMessage = response.data[response.data.length - 1];
      setState(prev => ({ ...prev, messages: [newMessage, ...prev.messages] }));
    } else setError();
  };

  const renderMessages = (): JSX.Element => {
    return (
      <>
        {messages.map(message => (
          <BroadcastBubble
            key={`${message.created_at}-${message.body}`}
            message={message.body || ""}
            date={message.created_at}
          />
        ))}
      </>
    );
  };

  const loader = (
    <CenterWrapper style={{ paddingTop: 10 }}>
      <Spinner small />
    </CenterWrapper>
  );
  const errorComponent = <CenterWrapper>{t("errors.generic")}</CenterWrapper>;

  const renderLoaderOrError = () => {
    if (!finishedInitialRequest) return loader;
    if (error) return errorComponent;
    return false;
  };

  return (
    <>
      <BroadcastInfoWrapper>
        <BroadcastInfo data-testid="broadcast-info">{t("messages.broadcast_window.info")}</BroadcastInfo>
      </BroadcastInfoWrapper>
      <Container id="message-container">
        {renderLoaderOrError() || (
          <>
            {messages.length === 0 && <CenterWrapper>{t("messages.no_messages")}</CenterWrapper>}

            <InfiniteScroll
              dataLength={messages.length}
              next={fetchEarlier}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse
              hasMore={hasMore}
              loader={loader}
              scrollableTarget="message-container"
              endMessage={messages.length > 0 && <Title>{t("messages.chat_window.beginning")}</Title>}
            >
              {renderMessages()}
            </InfiniteScroll>
          </>
        )}
      </Container>
      <BroadcastInput onSuccess={fetchNewAfterSend} />
    </>
  );
};

export default BroadcastWindow;

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 0 ${props => props.theme.spacing.S_15};
  overflow: auto;

  ${props => props.theme.aboveBreakpoint} {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  height: 66px;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_20} 0;
  ${props => props.theme.font.header4}
  color: ${props => props.theme.colors.greys.dark};
`;

const CenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.font.header3}
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.warm};
`;

const BroadcastInfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.broadcast.background};
  display: flex;
  align-items: center;
  padding: 25px 4px;
`;

const BroadcastInfo = styled.p`
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.base};
`;
