import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const videoCall = (callerId: number, recipientId: number): Promise<AxiosResponse> =>
  AxiosInstance.post(`/video_calls/`, {
    caller_id: callerId,
    recipient_id: recipientId,
  });

export default videoCall;
