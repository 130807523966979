import type React from "react";

import type { PremiumType } from "api/models/PatientProfile";
import {
  PremiumGuidedCareBigIcon,
  PremiumGuidedCareSmallIcon,
  PremiumSelfCareBigIcon,
  PremiumSelfCareSmallIcon,
} from "assets";

interface Props {
  size: "small" | "big";
  premiumType: PremiumType;
  margin?: string;
}

const PremiumIcon: React.VFC<Props> = ({ premiumType, size, margin }) => {
  if (premiumType === "guided_care") {
    if (size === "big") return <PremiumGuidedCareBigIcon data-testid="guided_care_icon" style={{ margin }} />;
    if (size === "small")
      return <PremiumGuidedCareSmallIcon data-testid="guided_care_icon" style={{ minWidth: 20, margin }} />;
  }
  if (premiumType === "selfcare") {
    if (size === "big") return <PremiumSelfCareBigIcon data-testid="selfcare_icon" style={{ margin }} />;
    if (size === "small")
      return <PremiumSelfCareSmallIcon data-testid="selfcare_icon" style={{ minWidth: 20, margin }} />;
  }
  return null;
};

export default PremiumIcon;
