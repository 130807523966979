import type React from "react";

import HealthReports from "../components/HealthReports";

const HealthQuestionnaires: React.VFC = () => {
  return (
    <div data-testid="health-questionnaires">
      <HealthReports />
    </div>
  );
};

export default HealthQuestionnaires;
