import { useMutation } from "react-query";

import type { PostDismissAppointmentSuggestionArgs } from "api/types/PostDismissAppointmentSuggestion";
import useApiClient from "api/useApiClient";

export const usePostDismissAppointmentSuggestion = () => {
  const client = useApiClient();
  return useMutation<null, Error, PostDismissAppointmentSuggestionArgs>(args =>
    client.postDismissAppointmentSuggestion(args)
  );
};
