import styled from "styled-components";

import { FormState } from "./FormState";

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.primary.base};
  margin-bottom: 4px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => theme.font.input.error}
  color: ${({ theme }) => theme.colors.primary.error};
  margin-top: 4px;
`;

export const StyledFormState = styled(FormState)`
  margin-top: 16px;
`;
