import styled from "styled-components";

interface RoomContainerProps {
  $minimised: boolean;
}

const RoomContainer = styled.div<RoomContainerProps>`
  background-color: ${props => props.theme.colors.greys.darker};
  border-radius: ${props => `${props.theme.borderRadius.basic} 0 0 ${props.theme.borderRadius.basic}`};
  bottom: ${props => props.$minimised && "0"};
  box-shadow: 0px 10px 20px rgba(141, 155, 173, 0.3);
  display: flex;
  height: ${props => (props.$minimised ? "320px" : "640px")};
  max-height: calc(100% - 70px);
  position: fixed;
  right: 0;
  top: ${props => !props.$minimised && "50px"};
  width: ${props =>
    props.$minimised ? `${props.theme.videoCallWidthMinimised}px` : `${props.theme.videoCallWidthMaximised}px`};
  max-width: 100vw;
  z-index: ${props => props.theme.zIndex.videoCall};

  ${props => props.theme.belowBreakpoint} {
    border-radius: 0;
    max-height: 100%;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: ${props => props.theme.zIndex.videoCallMobile};
  }
`;

const IdConfirmation = styled.div`
  color: ${props => props.theme.colors.white};
  left: ${props => props.theme.spacing.S_20};
  max-width: calc(100% - 160px);
  position: absolute;
  top: ${props => props.theme.spacing.S_20};
  width: 100%;
  ${props => props.theme.font.body1};
`;

const TopGradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.631042) 0%, rgba(0, 0, 0, 9e-5) 100%);
  border-top-left-radius: 8px;
  height: 84px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const BottomGradient = styled.div`
  background: linear-gradient(180deg, rgba(154, 154, 154, 0.0001) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-bottom-left-radius: 8px;
  bottom: 0;
  height: 154px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
`;

const StatusText = styled.p`
  ${props => props.theme.font.header1};
  color: ${props => props.theme.colors.white};
  margin: 0;
  position: absolute;
  text-align: center;
  text-shadow: 0px 0px 10px ${props => props.theme.colors.black};
  top: calc(50% - 18px);
  width: 100%;
`;

export { RoomContainer, IdConfirmation, TopGradient, BottomGradient, StatusText };
