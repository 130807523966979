import type { Dispatch, SetStateAction } from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import invariant from "ts-invariant";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { PatientsColumnsContext, PatientsFilterContext } from "utils/contexts";

import { QUERY_KEYS } from "../../../types/QueryKeys";

import type { ColumnType, DataColumnsContextType } from "./dataColumns";
import { compareFn, matchesSearch } from "./dataColumns";
import fetchPatients from "./queries/fetchPatients";
import type { Patient } from "./queries/fetchPatients";

export type UsePatientQuery = {
  error: unknown;
  isLoading: boolean;
  offset: number;
  patients: Patient[];
  setOffset: Dispatch<SetStateAction<number>>;
  unfilteredPatients: Patient[];
};

const usePatients = (searchValue: string, selectedColumn: ColumnType, descending: boolean): UsePatientQuery => {
  const { columns } = useContext(PatientsColumnsContext) as DataColumnsContextType;
  const { hasFeature } = useFeatureToggleContext();
  const { matchesActiveFilters } = useContext(PatientsFilterContext);
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [offset, setOffset] = useState(0);

  invariant(profile);

  // We don't want to refetch just because the order of the columns changes
  const columnsKey = [...columns].sort().join("_");

  const { data, isLoading, error } = useQuery([QUERY_KEYS.patients, columnsKey], () =>
    fetchPatients(profile?.id, hasFeature, t, columns)
  );

  useEffect(() => {
    if (data) {
      const filtered = data
        .filter(matchesActiveFilters)
        .filter(p => matchesSearch(p, columns, searchValue))
        .sort(compareFn(selectedColumn, descending));

      if (filtered.length !== patients.length && filtered.some((p, i) => p.id !== patients[i]?.id)) {
        setOffset(0);
      }
      setPatients(filtered);
    }
  }, [data, searchValue, selectedColumn, descending, columns, matchesActiveFilters]);

  return {
    patients,
    offset,
    setOffset,
    isLoading,
    error,
    unfilteredPatients: data ?? [],
  };
};

export default usePatients;
