import { z } from "zod";

const unclaimedReferral = {
  id: z.number(),
  patient_first_name: z.string(),
  patient_last_name: z.string(),
  patient_date_of_birth: z.string(),
  provider_first_name: z.string(),
  provider_last_name: z.string(),
  duration_days: z.number(),
  icd_codes: z.array(z.string()),
  date_of_physician_signature: z.string(),
  source: z.string(),
  url: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
};

const RawUnclaimedReferralSchema = z.object({ ...unclaimedReferral });

export type RawUnclaimedReferral = z.infer<typeof RawUnclaimedReferralSchema>;

export const PartnerUnclaimedReferralSchema = RawUnclaimedReferralSchema.transform(
  ({
    patient_first_name,
    patient_last_name,
    patient_date_of_birth,
    provider_first_name,
    provider_last_name,
    duration_days,
    icd_codes,
    date_of_physician_signature,
    created_at,
    updated_at,
    ...rest
  }) => ({
    patientName: `${patient_first_name} ${patient_last_name}`,
    patientDateOfBirth: patient_date_of_birth,
    providerFirstName: provider_first_name,
    providerLastName: provider_last_name,
    durationDays: duration_days,
    icdCodes: icd_codes,
    dateOfPhysicianSignature: date_of_physician_signature,
    createdAt: created_at,
    updatedAt: updated_at,
    ...rest,
  })
);

export type UnclaimedReferral = z.infer<typeof PartnerUnclaimedReferralSchema>;
