import type React from "react";
import { useState } from "react";

import type { CallData } from "routes/videocall";

import VideoCallContext from "./VideoCallContext";

const VideoCallContextProvider: React.FC = ({ children }) => {
  const [callData, setCallData] = useState<CallData | null>(null);
  const [minimised, setMinimised] = useState(false);

  const value = {
    callData,
    setCallData,
    minimised,
    setMinimised,
  };

  return <VideoCallContext.Provider value={value}>{children}</VideoCallContext.Provider>;
};

export default VideoCallContextProvider;
