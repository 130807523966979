import styled from "styled-components";

interface Props {
  readonly color?: string;
  readonly border?: boolean;
  readonly align?: string;
}

const Card = styled.div<Props>`
  background-color: ${({ color = null, theme }) => color || theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.basic};
  border-width: 1px;
  border-color: ${props => props.theme.colors.greys.silver};
  border-style: ${({ border = false }) => (border ? "solid" : "none")};
  min-height: 18px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: ${({ align = "center" }) => align};
  color: ${props => props.theme.colors.primary.base};
`;
export default Card;
