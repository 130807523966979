import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getPersonalInvitaionQueryKey = (userId: number) => ["GetPersonalInvitaionLink", userId] as const;

const useGetPersonalInvitationLink = (userId: number) => {
  const client = useApiClient();

  return useQuery(getPersonalInvitaionQueryKey(userId), () => client.getPeronalInvitationLink(userId));
};

export default useGetPersonalInvitationLink;
