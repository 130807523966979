import type React from "react";
import { useEffect, useState } from "react";

import { getStorageValue, removeStorageValue, setStorageValue } from "utils/storage";

import AppConfigContext from "./AppConfigContext";

const isPrideMonth = new Date().getMonth() === 5;

const AppConfigContextProvider: React.FC = ({ children }) => {
  const [navBarCollapsed, setNavBarCollapsed] = useState<boolean>(!!getStorageValue("collapsedSidebar") || false);

  useEffect(() => {
    if (navBarCollapsed) {
      setStorageValue("collapsedSidebar", "collapsed");
    } else {
      removeStorageValue("collapsedSidebar");
    }
  }, [navBarCollapsed]);

  const value = {
    navBarCollapsed,
    setNavBarCollapsed,
    isPrideMonth,
  };

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};

export default AppConfigContextProvider;
