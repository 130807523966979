import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BaseButton } from "shared/atoms/BaseButton";

import ResponsivePopup from "./components/ResponsivePopup";

interface Props {
  weeksMissingExercises: number[];
  onRevert: () => void;
  onIgnoreMissingExercises: () => void;
}

const NoExercisesConfirmDialog: React.VFC<Props> = ({ weeksMissingExercises, onRevert, onIgnoreMissingExercises }) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <ResponsivePopup onClose={() => {}} open>
      <PopupRoot>
        <StyledHeader>
          {t("usercourse.templates.no_exercises_for_week_warning.title", {
            weeks: weeksMissingExercises?.join(", "),
          })}
        </StyledHeader>
        <ButtonsContainer>
          <BaseButton text={t("usercourse.templates.no_exercises_for_week_warning.undo")} onClick={onRevert} small />
          <BaseButton
            text={t("usercourse.templates.no_exercises_for_week_warning.continue")}
            onClick={onIgnoreMissingExercises}
            variant="secondary"
            small
          />
        </ButtonsContainer>
      </PopupRoot>
    </ResponsivePopup>
  );
};

export default NoExercisesConfirmDialog;

const PopupRoot = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const StyledHeader = styled(Header)`
  padding: 0px 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;

  & > button {
    flex-grow: 0;
    flex-basis: auto;

    &:first-of-type {
      margin-right: 8px;
    }
  }
`;
