import type React from "react";

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import type { AxiosError } from "axios";
import axios from "axios";
import { ZodError } from "zod";

import { isStage } from "utils/misc/getBuildEnvironment";
import { isCypressRunning } from "utils/misc/isCypressRunning";
import { getAppDataRegion } from "utils/region";

declare global {
  interface Window {
    Cypress: unknown;
  }
}

type AllowedErrorTypes = Error | AxiosError | ZodError | string;

export function isErrorOfType(error: unknown): error is AllowedErrorTypes {
  return error instanceof Error || axios.isAxiosError(error) || error instanceof ZodError || typeof error === "string";
}

function getErrorMessage(error: AllowedErrorTypes) {
  if (axios.isAxiosError(error)) {
    return JSON.stringify(error.response?.data);
  }

  if (error instanceof ZodError) {
    return error.errors.map(e => e.message).join(", ");
  }

  return "";
}

export const reportError = (
  name: string,
  error: AllowedErrorTypes,
  options?: { action?: string; message?: string; context?: Record<string, unknown> }
): void => {
  if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
    if (error instanceof ZodError) {
      // eslint-disable-next-line no-console
      console.error({ ...error });
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test") {
    Honeybadger.notify(error, name, {
      action: options?.action,
      message: options?.message || getErrorMessage(error),
      context: options?.context,
    });
  }
};

interface Props {
  children: React.ReactNode;
}

const CustomErrorBoundary: React.VFC<Props> = ({ children }) => {
  if (isCypressRunning() || window.location.hostname.includes("localhost")) {
    return <>{children}</>;
  }

  // FIXME: honeybadger should probably be initiated when module is loading. Preferably with env variables or some static config.
  const honeybadger = Honeybadger.configure({
    apiKey: "183c57a3",
    environment: isStage() ? "staging" : "production",
    enableUnhandledRejection: false,
  });

  honeybadger.beforeNotify(notice => {
    if (notice?.message && /ResizeObserver loop/.test(notice.message)) {
      return false;
    }
    return true;
  });

  honeybadger.setContext({
    market: getAppDataRegion(),
    release: `${process.env.REACT_APP_VERSION}-${getAppDataRegion()}`,
  });

  return <HoneybadgerErrorBoundary honeybadger={honeybadger}>{children}</HoneybadgerErrorBoundary>;
};

export default CustomErrorBoundary;
