import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const getPhoneCallToken = async (
  pid: number,
  id: number
): Promise<
  AxiosResponse<{
    token: "string";
    call_identifier: "string";
  }>
> => {
  const response = await AxiosInstance.post(`/phone_calls/`, {
    recipient_id: pid,
    caller_id: id,
  });

  return {
    ...response,
    data: {
      token: response.data.token,
      call_identifier: response.data.phone_call_identifier,
    },
  };
};

export default getPhoneCallToken;
