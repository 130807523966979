import type { DomainMailExtension, Market } from "types";
import {
  DomainMailExtensionMapTypes,
  MarketTypes,
  isTypeMailDomainExtensionMap,
  isTypeMarket,
  isTypeMarketMap,
} from "types";
import { getAppRawDataRegion } from "utils/region";

export const getNormalizedMarket = (market: unknown): Market | undefined => {
  if (!market) {
    return undefined;
  }
  if (isTypeMarket(market)) {
    return market;
  }
  if (isTypeMarketMap(market)) {
    return MarketTypes[market];
  }
  return undefined;
};

export const isMarket = (market: Market | Market[]): boolean => {
  const currentMarket = getAppMarket();
  if (!currentMarket) {
    return false;
  }
  let checkMarket = market;
  if (!Array.isArray(checkMarket)) {
    checkMarket = [market] as Market[];
  }
  return checkMarket.includes(currentMarket);
};

/**
 * This method will return the normalized/corrected app market based on the `REACT_APP_MARKET`
 * In case the MarketTypes have a mapped market like `UK` -> `GB` and `REACT_APP_MARKET` == `UK`,
 * this method will provide the corrected market `GB`
 *
 * @returns Market or undefined if no market and no mapped market information
 */
export const getAppMarket = (): Market | undefined => getNormalizedMarket(getAppRawDataRegion());

export const getMarketMailDomain = (market?: Market): DomainMailExtension => {
  if (market && isTypeMailDomainExtensionMap(market)) {
    return DomainMailExtensionMapTypes[market];
  }
  const appMarket = getAppMarket();
  if (isTypeMailDomainExtensionMap(appMarket)) {
    return DomainMailExtensionMapTypes[appMarket];
  }
  return DomainMailExtensionMapTypes.US; // defaults to `com`
};

export const getSupportLink = () => {
  const link = "support@jointacademy.com";
  const domainOriginReplacement = getMarketMailDomain();
  if (domainOriginReplacement) {
    return link.replace("com", domainOriginReplacement);
  }
  return link;
};
