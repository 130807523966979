import { useMutation } from "react-query";

import useApiClient from "api/useApiClient";

export type DeleteAppointmentManagerArgs = {
  managerId: number;
  appointmentId: number;
};

export const useDeleteAppointmentManager = () => {
  const client = useApiClient();

  return useMutation<null, Error, DeleteAppointmentManagerArgs>({
    mutationFn: args => client.deleteAppointmentManager(args),
  });
};
