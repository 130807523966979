import type { VFC } from "react";
import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { usePostPatientEndTreatment } from "api/hooks/usePostPatientEndTreatment";
import { DropdownIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton } from "shared/atoms/Button";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { getPatientFullDetailsQueryKey } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";
import useLocalizedDate from "utils/date";

import { InformationContainer, InformationContainerHeader } from "../PatientHeaderInfo/helpers";

type PartialPatientResponse = {
  id: number;
  treatment_started: boolean;
  end_treatment_at: string | undefined;
};

type PatientEndTreatmentForms = {
  patient: PartialPatientResponse;
};

const PatientEndTreatmentForms: React.VFC<PatientEndTreatmentForms> = ({ patient }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { profile } = useProfileContext();
  const { format, parseISO } = useLocalizedDate();
  const [isOpen, setIsOpen] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const postPatientEndTreatment = usePostPatientEndTreatment();
  const [error, setError] = useState<string | null>(null);
  const initiateEndTreatment = async () => {
    setShowButton(false);
    await postPatientEndTreatment.mutate(patient.id, {
      onSuccess() {
        // invalidate patient query
        if (profile) {
          const queryKey = getPatientFullDetailsQueryKey({
            patientId: patient.id,
            therapistId: profile.id,
            therapistMarket: profile.market ?? "",
          });
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError() {
        setError(t("errors.generic"));
        setShowButton(true);
      },
    });
  };

  return (
    <InformationContainer data-testid="end-treatment-forms">
      <InformationContainerHeader onClick={() => setIsOpen(!isOpen)} type="button">
        {patient.treatment_started
          ? t("patient.end_treatment_forms.form_had_treatment.header")
          : t("patient.end_treatment_forms.form_no_treatment.header")}
        <IconWrapper $isOpen={isOpen}>
          <DropdownIcon />
        </IconWrapper>
      </InformationContainerHeader>
      {patient.treatment_started ? (
        <Body $isOpen={isOpen} data-testid="end-treatment-form-treated">
          <Step>
            <StepTitle>{t("patient.self_care_activation.step1_header")}</StepTitle>
            <StepText>
              <BulletList text={t("patient.self_care_activation.step1_text")} />
            </StepText>
          </Step>
          <Step>
            <StepTitle>{t("patient.self_care_activation.step2_header")}</StepTitle>
            <StepText>
              <BulletList text={t("patient.self_care_activation.step2_text")} />
            </StepText>
          </Step>
        </Body>
      ) : (
        <Body $isOpen={isOpen} data-testid="end-treatment-form-untreated">
          <Step>
            {profile?.market === "SE" && (
              <StepText>
                {t("patient.end_treatment_forms.form_no_treatment.text1a")}
                <StyledLink
                  href="https://jointacademy.secure.force.com/support/se/sv/article/Patienten-svarar-inte-p%C3%A5-mitt-uppstartssamtal-vad-g%C3%B6r-jag"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("patient.end_treatment_forms.form_no_treatment.link")}
                </StyledLink>
                {t("patient.end_treatment_forms.form_no_treatment.text1b")}
              </StepText>
            )}
            {profile?.market === "US" && (
              <StepText>
                {t("patient.end_treatment_forms.form_no_treatment.text1a_us")}
                <StyledLink
                  href="https://jointacademy.secure.force.com/support/us/en/article/What-do-I-do-if-my-new-patient-is-not-being-responsive-to-my-communication"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("patient.end_treatment_forms.form_no_treatment.link_us")}
                </StyledLink>
                {t("patient.end_treatment_forms.form_no_treatment.text1b_us")}
              </StepText>
            )}
            <StepText>{t("patient.end_treatment_forms.form_no_treatment.text2")}</StepText>
          </Step>
          <Step>
            {patient.end_treatment_at ? (
              <>
                <StepTitle>{t("patient.end_treatment_forms.form_no_treatment.text3")}</StepTitle>
                <EndDateLabel data-testid="end-date-label">
                  {format(parseISO(patient.end_treatment_at), "PP")}
                </EndDateLabel>
              </>
            ) : (
              <>
                {showButton ? (
                  <StyledButton data-testid="button-end-treatment" onClick={() => initiateEndTreatment()}>
                    {t("patient.end_treatment_forms.form_no_treatment.button")}
                  </StyledButton>
                ) : (
                  <Spinner small />
                )}
              </>
            )}
          </Step>
          {error && <Notification variant="danger">{error}</Notification>}
        </Body>
      )}
    </InformationContainer>
  );
};

export default PatientEndTreatmentForms;

type IconWrapperProps = {
  $isOpen: boolean;
};

const IconWrapper = styled.div<IconWrapperProps>`
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "none")};
`;

type BodyProps = {
  $isOpen: boolean;
};

const Body = styled.div<BodyProps>`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  margin: 0 24px 24px;
  flex-direction: row;
  gap: 24px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  position: relative;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin: 6px 0;
  color: ${props => props.theme.colors.greys.darker};
`;

const StepTitle = styled(Text)`
  font-weight: 600;
`;

const EndDateLabel = styled.div`
  font-weight: 300;
  font-size: 72px;
`;

const StepText = styled(Text)``;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 2em;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 6px;
  }
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing.S_20};
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 500;
`;

type BulletListProps = {
  separator?: string;
  text: string;
};

const BulletList: VFC<BulletListProps> = ({ separator = "\n", text }) => {
  const lines = text.split(separator);

  return (
    <List>
      {lines.map((line: string) => (
        <ListItem key={line}>{line}</ListItem>
      ))}
    </List>
  );
};
