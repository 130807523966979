import type React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import type { PartnerPatient } from "api/schemas/PartnerPatientsSchema";
import Hidden from "shared/atoms/Hidden";
import { TableRow } from "shared/molecules/Table";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import useLocalizedDate from "utils/date";

import { CellContainer, Dot, StyledTableCell } from "./helpers";

interface Props {
  patient: PartnerPatient;
}
const PartnerPatientsTableRow: React.VFC<Props> = ({ patient }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { format, parseISO } = useLocalizedDate();
  const treatmentStarted = format(parseISO(patient.created_at), "d MMMM, yyyy");
  const latestNote = patient.latest_note_at ? format(parseISO(patient.latest_note_at), "d MMMM, yyyy") : "-";
  const openPatientPage = () => {
    navigate(`/patients/${patient.id}`, {
      state: {
        pathname,
        text: "patients",
      },
    });
    AnalyticsService.track(AnalyticsEvents.CLINIC_PARTNER.PATIENT_PROFILE_VIEWED);
  };
  return (
    <>
      <Hidden type="belowTablet">
        <TableRow key={patient.id} onClick={() => openPatientPage()}>
          <StyledTableCell>
            <CellContainer>
              <Dot $deleted={!!patient.deleted_at} />
              {patient.id}
            </CellContainer>
          </StyledTableCell>
          <StyledTableCell>{patient.name}</StyledTableCell>
          <StyledTableCell>
            <CellContainer>{treatmentStarted}</CellContainer>
          </StyledTableCell>
          <StyledTableCell>
            <CellContainer>{latestNote}</CellContainer>
          </StyledTableCell>
          <StyledTableCell>
            <CellContainer>{patient.sources}</CellContainer>
          </StyledTableCell>
          <StyledTableCell>
            <CellContainer>{patient.icd_codes}</CellContainer>
          </StyledTableCell>
          <StyledTableCell>
            <CellContainer>{patient.adherence}%</CellContainer>
          </StyledTableCell>
        </TableRow>
      </Hidden>
      <Hidden type="aboveTablet">
        <TableRow key={patient.id} onClick={() => openPatientPage()}>
          <StyledTableCell>
            <CellContainer>
              <Dot $deleted={!!patient.deleted_at} />
              {patient.id}
            </CellContainer>
          </StyledTableCell>
          <StyledTableCell>{patient.name}</StyledTableCell>
          <StyledTableCell>
            <CellContainer>{treatmentStarted}</CellContainer>
          </StyledTableCell>
        </TableRow>
      </Hidden>
    </>
  );
};

export default PartnerPatientsTableRow;
