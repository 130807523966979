import { useMutation, useQueryClient } from "react-query";

import type { QuickReply } from "api/schemas/QuickReply";
import useApiClient from "api/useApiClient";

import { getQuickRepliesQueryKey } from "./useGetQuickReplies";

export type DeleteQuickReplyArgs = {
  userId: number;
  id: number;
};

export const useDeleteQuickReply = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation(({ userId, id }: DeleteQuickReplyArgs) => client.deleteQuickReply({ userId, id }), {
    onMutate: async (deleteQuickReply: DeleteQuickReplyArgs) => {
      const queryKey = getQuickRepliesQueryKey(deleteQuickReply.userId);

      await queryClient.cancelQueries(queryKey);
      const previousQuickReplies = queryClient.getQueryData<QuickReply[]>(queryKey);

      if (previousQuickReplies) {
        queryClient.setQueryData<QuickReply[]>(
          queryKey,
          previousQuickReplies.filter(quickReply => quickReply.id !== deleteQuickReply.id)
        );
      }

      return { previousQuickReplies };
    },
    onError: (error, variables, context) => {
      const queryKey = getQuickRepliesQueryKey(variables.userId);

      if (context?.previousQuickReplies) {
        queryClient.setQueryData<QuickReply[]>(queryKey, context.previousQuickReplies);
      }
    },
    onSettled: context => {
      if (context) {
        queryClient.invalidateQueries(getQuickRepliesQueryKey(context.userId));
      }
    },
  });
};
