import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

import type { Message } from "../types";

export type Params = {
  [param: string]: string | number;
};

export const getChatMessages = async (
  id: number,
  patientId: number,
  params: Params,
  signal?: AbortSignal
): Promise<AxiosResponse<Message[]> | false> => {
  try {
    const param_string = Object.keys(params).reduce((current: string, key: string, index: number, keys: string[]) => {
      let s = current;
      if (key && params[key]) s += `page[${key}]=${params[key]}`;
      if (index < keys.length - 1) s += "&";
      return s;
    }, "");
    const response = await AxiosInstance.get<Message[]>(
      `/users/${id}/conversations?peer_id=${patientId}&${param_string}`,
      { signal }
    );
    return response;
  } catch (error) {
    return false;
  }
};

export default getChatMessages;
