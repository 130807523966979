import type { AxiosRequestHeaders, AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const newPassword = (
  body: { password: string; password_confirmation: string },
  headers: AxiosRequestHeaders
): Promise<AxiosResponse> =>
  AxiosInstance.put(
    "auth/password",
    { ...body },
    {
      headers,
    }
  );

export default newPassword;
