import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { useProfileContext } from "contexts/ProfileContext";
import { Notification } from "shared/atoms/Notification";
import { Switch, SwitchLabel, SwitchWrapper } from "shared/atoms/Switch";

const NewPatientNotificationSwitch: React.VFC = () => {
  const { profile, setProfile } = useProfileContext();
  const { t } = useTranslation();
  const putTherapistMutation = usePutTherapistMutation();

  invariant(profile);

  // Note! The server store the "ignore"-value while we are displaying the
  // opposite to enable notifications / disable notifications.
  const isIgnoreEmail = Boolean(profile.therapist_profile.ignore_new_patient_email);
  const inEnabled = !isIgnoreEmail;

  const onSubmit = (ignoreNewPatientEmail: boolean) => {
    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        ignore_new_patient_email: ignoreNewPatientEmail,
      },
    };

    // Optimistically update the profile for immediate UI feedback.
    // Then onSuccess we update the profile with "real" server state.
    setProfile({
      ...profile,
      therapist_profile: {
        ...profile.therapist_profile,
        ...data.therapist_profile,
      },
    });

    const variables = { therapistId, data };
    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);
      },
    });
  };

  return (
    <NewPatientEmailContainer data-testid="new-patient-email">
      <StyledHeader data-testid="new-patient-email-header">
        {t("therapist_settings.new_patient_notification_switch")}
      </StyledHeader>
      <SwitchWrapper>
        <Switch
          data-testid="new-patient-email-switch"
          id="new-patient-email-switch"
          type="checkbox"
          checked={inEnabled}
          onChange={() => {
            onSubmit(!isIgnoreEmail);
          }}
        />
        <SwitchLabel htmlFor="new-patient-email-switch" />
      </SwitchWrapper>

      {putTherapistMutation.isError && (
        <Notification variant="danger" style={{ margin: "10px 0" }}>
          {t("errors.generic")}
        </Notification>
      )}
    </NewPatientEmailContainer>
  );
};

const NewPatientEmailContainer = styled.div`
  width: 100%;
`;
const StyledHeader = styled.h5`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
`;

export default NewPatientNotificationSwitch;
