/* eslint-disable @typescript-eslint/no-empty-function */
import type React from "react";
import { forwardRef, useEffect, useState } from "react";

import type { ChangeHandler } from "react-hook-form";
import { animated, useSpring } from "react-spring";
import styled, { css } from "styled-components";

import { CancelRoundIcon } from "assets";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
`;

const IconWrapperCss = css`
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    max-width: 25px;
  }
`;

const IconWrapper = styled.div`
  ${IconWrapperCss};
  left: 0;
`;

const SearchInput = styled(StyledInput)<{ $icon: boolean; $search: boolean }>`
  box-sizing: border-box;
  padding: ${props => (props.$icon ? `0px 15px 0px 40px` : `0px 15px`)};
`;

const ResetWrapper = styled.div`
  ${IconWrapperCss};
  right: 0;
`;

const Placeholder = styled(animated.span)`
  ${props => ({ ...props.theme.font.body1 })};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.pinkish};
  height: 23px;
  left: 42px;
  pointer-events: none;
  position: absolute;
  white-space: nowrap;
`;

interface Props {
  placeholder?: string;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  name: string;
  className?: string;
  reset?: () => void;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  search?: string;
  inputStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const Search = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className = undefined,
    placeholder = "",
    name,
    icon: Icon = null,
    reset = () => {},
    search = null,
    onChange = undefined,
    onBlur = undefined,
    inputStyle,
    wrapperStyle,
  } = props;
  const [searching, setSearching] = useState({ active: false, value: "" });

  const handleChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setSearching({ active: true, value: target?.value });
  };

  const handleOut = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setSearching({ active: e.type === "focus" || target?.value !== "", value: target?.value });
  };

  useEffect(() => {
    const selector = document.querySelector(`input[name="${name}"]`);
    selector?.addEventListener("focus", handleOut);
    selector?.addEventListener("blur", handleOut);
    selector?.addEventListener("input", handleChange);

    return () => {
      selector?.removeEventListener("input", handleChange);
      selector?.removeEventListener("focus", handleOut);
      selector?.removeEventListener("blur", handleOut);
    };
  }, [name]);

  const { animDisplay, opacity, top } = useSpring({
    animDisplay: !search && !searching.active ? 1 : 0,
    opacity: !searching.active ? 1 : 0,
    top: !searching.active ? "12px" : "0px",
  });

  return (
    <Wrapper data-testid="search-wrapper" style={wrapperStyle}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      <SearchInput
        style={inputStyle}
        className={className}
        ref={ref}
        name={name}
        $icon={!!Icon}
        $search={searching.active}
        onChange={onChange}
        onBlur={onBlur}
      />
      {!!searching.value && (
        <ResetWrapper
          onClick={() => {
            setSearching({ active: false, value: "" });
            reset();
          }}
          data-testid="reset-search"
        >
          <CancelRoundIcon />
        </ResetWrapper>
      )}
      <Placeholder
        style={{
          opacity,
          top,
          display: animDisplay.interpolate(displ => (displ === 0 ? "none" : "initial")),
        }}
      >
        {placeholder}
      </Placeholder>
    </Wrapper>
  );
});

export default Search;
