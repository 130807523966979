import type { Market } from "types";
import { isTypeMarket } from "types";
import { isMarket } from "utils/market";
import type { UserProfile } from "utils/profile/profileHelper";
import { isUserMarket, isUserOfType } from "utils/profile/profileHelper";

import type { CriteriaConditionValidators, CriteriaConditionsProfile } from "./types";
import { isTypeCriteriaConditionsProfile } from "./types";

const validators: CriteriaConditionValidators = {
  market: (condition, options?) => {
    if (!condition) {
      return false;
    }
    const providedMarkets = Array.isArray(condition) ? condition : [condition];
    const validMarkets: Market[] = providedMarkets.filter(value => isTypeMarket(value));

    // Prefer profile market check if exists
    if (options?.profile?.market) {
      return isUserMarket(validMarkets, options.profile);
    }

    return isMarket(validMarkets);
  },
  profile: (condition, options?) => {
    const conditionProfile = condition as CriteriaConditionsProfile;
    if (!isTypeCriteriaConditionsProfile(condition)) {
      return false;
    }
    if (typeof conditionProfile.roles === "undefined") {
      return false;
    }
    if (!options || typeof options === "undefined" || !options.profile) {
      return false;
    }
    const validRoles = conditionProfile.roles;
    if (validRoles.length === 0) {
      return true;
    }

    return isUserOfType(validRoles, options.profile as UserProfile);
  },
};

export default validators;
