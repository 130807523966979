import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import type { Step } from "react-joyride";
import Joyride from "react-joyride";

import { setStorageValue } from "utils/storage";

export type Tour = { id: string; steps: Step[] };

interface TourPlayerProps {
  tour: Tour | null;
  storeStatus?: boolean;
}

const TourPlayer: React.VFC<TourPlayerProps> = ({ tour, storeStatus }) => {
  const [_tour, _setTour] = useState<Tour | null>(null);

  useEffect(() => {
    _setTour(tour);
  }, [tour]);

  const { t } = useTranslation();

  return _tour ? (
    <Joyride
      showSkipButton
      continuous
      steps={_tour.steps}
      callback={d => {
        const done = d.status === "finished" || d.status === "skipped" || d.action === "close";
        if (done) {
          _setTour(null);
          if (storeStatus) {
            setStorageValue("tours-completed", { [_tour.id]: true });
          }
        }
      }}
      locale={{
        back: t("buttons.back"),
        close: t("buttons.close"),
        last: t("buttons.done"),
        next: t("buttons.next"),
        skip: t("buttons.cancel"),
      }}
      styles={{
        options: {
          overlayColor: "rgba(0, 0, 0, 0.25)",
          primaryColor: "#5096E8",
        },
      }}
    />
  ) : null;
};
export default TourPlayer;
