import type React from "react";

import SignUpReport from "../components/SignUpReport";

const SignUpQuestions: React.VFC = () => {
  return (
    <div data-testid="sign-up-questions">
      <SignUpReport />
    </div>
  );
};

export default SignUpQuestions;
