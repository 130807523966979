import { useMutation, useQueryClient } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import type { PostAppointmentManagerArgs } from "api/types/PostAppointment";
import useApiClient from "api/useApiClient";

import { queryKeyPrefix } from "./useGetAppointments";

export const usePostAppointmentManager = () => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<NewAppointment, Error, PostAppointmentManagerArgs>(args => client.postAppointmentManager(args), {
    onSuccess() {
      queryClient.invalidateQueries([queryKeyPrefix]);
    },
  });
};
