import type React from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { MedicalNoteFieldConfig } from "types";
import { getFieldLabelKey } from "utils/medicalNotes";

interface Props {
  readonly field: MedicalNoteFieldConfig;
}

const SystemSuggestionsField: React.VFC<Props> = ({ field }) => {
  const { t } = useTranslation();
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const label = t(getFieldLabelKey(field.name));
  const { getValues } = useFormContext();
  const value = getValues(field.name);
  return (
    <>
      <Label data-testid={`${field.name}-label`}>{label}</Label>
      <Text data-testid={`${field.name}-value`}>{value}</Text>
    </>
  );
};

const Label = styled.div`
  margin: 8px 12px;
  ${({ theme }) => theme.font.system_suggestions_section.label};
`;
const Text = styled.div`
  margin: 8px 12px 12px;
  ${({ theme }) => theme.font.system_suggestions_section.body};
`;

export { SystemSuggestionsField };
