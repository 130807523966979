import type React from "react";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { invariant } from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";
import { Notification } from "shared/atoms/Notification";
import { Switch, SwitchLabel, SwitchWrapper } from "shared/atoms/Switch";

const WeeklyPatientLimit: React.VFC = () => {
  const { profile, setProfile } = useProfileContext();
  const [isEditing, setIsEditing] = useState(false);
  const putTherapistMutation = usePutTherapistMutation();
  const { handleSubmit, register, reset, watch, setValue } = useForm({
    defaultValues: {
      weekly_patient_assignment_limit: profile?.therapist_profile.weekly_assignment_limit || null,
      weekly_patient_assignment_limit_enabled: !!profile?.therapist_profile.weekly_assignment_limit || false,
    },
  });

  const watchWeeklyLimitEnabled: boolean = watch("weekly_patient_assignment_limit_enabled", false);

  useEffect(() => {
    if (!watchWeeklyLimitEnabled && isEditing) {
      setValue("weekly_patient_assignment_limit", null);
    }
  }, [watchWeeklyLimitEnabled]);

  const weeklyPatientLimit: number | null = profile?.therapist_profile?.weekly_assignment_limit ?? null;

  const { t } = useTranslation();

  const handleChangeWeeklyPatientLimitClick = () => {
    putTherapistMutation.reset();
    setIsEditing(!isEditing);
  };

  const handleCancelWeeklyPatientLimitClick = () => {
    putTherapistMutation.reset();
    setIsEditing(!isEditing);
    reset();
  };

  const onSubmit = async ({ weekly_patient_assignment_limit }: { weekly_patient_assignment_limit: number | null }) => {
    invariant(profile);

    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        weekly_assignment_limit: weekly_patient_assignment_limit ?? null,
      },
    };
    const variables = { data, therapistId };

    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);
        reset({
          weekly_patient_assignment_limit: data.therapist_profile.weekly_assignment_limit,
          weekly_patient_assignment_limit_enabled: data.therapist_profile.weekly_assignment_limit != null,
        });
        setIsEditing(!isEditing);
      },
    });
  };

  return (
    <WeeklyPatientLimitContainer data-testid="weekly-patient-limit">
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader>{t("settings.weekly_patient_assignment_limit.title")}</StyledHeader>
        {isEditing ? (
          <>
            <SwitchAndValueWrapper>
              <SwitchWrapper>
                <Switch
                  data-testid="patient-limit-switch"
                  id="patient-limit-switch"
                  type="checkbox"
                  {...register("weekly_patient_assignment_limit_enabled")}
                />
                <SwitchLabel htmlFor="patient-limit-switch" />
              </SwitchWrapper>
              <WeeklyLimitInput
                data-testid="patient-limit-input"
                {...register("weekly_patient_assignment_limit")}
                disabled={!watchWeeklyLimitEnabled}
                min="0"
                max="100"
                type="number"
              />
            </SwitchAndValueWrapper>
            <ButtonWrapper>
              <TextButton onClick={handleCancelWeeklyPatientLimitClick}>{t("buttons.cancel")}</TextButton>
              <StyledPrimaryButton type="submit">{t("buttons.save")}</StyledPrimaryButton>
            </ButtonWrapper>
          </>
        ) : (
          <>
            <WeeklyLimit>{weeklyPatientLimit ?? t("settings.weekly_patient_assignment_limit.unlimited")}</WeeklyLimit>
            <IconButton
              dataTestId="edit-patient-limit-button"
              label={t("buttons.change")}
              onClick={handleChangeWeeklyPatientLimitClick}
            >
              <EditIcon />
            </IconButton>
          </>
        )}
        {putTherapistMutation.isError && (
          <Notification variant="danger" style={{ margin: "10px 0" }}>
            {t("errors.generic")}
          </Notification>
        )}
      </form>
    </WeeklyPatientLimitContainer>
  );
};

const WeeklyPatientLimitContainer = styled.div`
  width: 100%;
`;

const StyledHeader = styled.h5`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

const SwitchAndValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-items: center;
`;

const WeeklyLimit = styled.div`
  ${props => ({ ...props.theme.font.body1 })}
  font-family: "Roboto", sans-serif;
  font-weight: ${props => props.theme.fontWeight.regular};
  margin-bottom: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  flex-basis: 100%;
  box-sizing: border-box;
  border: 2px solid transparent;
  color: ${props => props.theme.colors.greys.dark};
  background-color: transparent;
`;

const WeeklyLimitInput = styled(StyledInput)`
  ${props => ({ ...props.theme.font.body1 })}
  max-width: 70px;
  padding: ${props => props.theme.spacing.S_10};
  margin-top: ${props => props.theme.spacing.S_10};
  margin-bottom: ${props => props.theme.spacing.S_20};
`;

export default WeeklyPatientLimit;
