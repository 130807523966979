import type React from "react";

import { faClock, faEdit, faUserPen } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import invariant from "ts-invariant";

import type { BookingIntervalResponse } from "api/schemas/BookingInterval";
import { useProfileContext } from "contexts/ProfileContext";
import Popup from "shared/atoms/Popup";
import useLocalizedDate from "utils/date";

import { getAppointmentTitle } from "../../Calendar/helpers/getAppointmentTitle";
import { BlueIconButton } from "../../styles";
import { Buttons, Content, Header, Label, Row, StyledFontAwesomeIcon, Value } from "../styles";

interface Props {
  bookingInterval: BookingIntervalResponse;
  navigateToCalendar: boolean;
  editBooking?: () => void;
  onClose: () => void;
  onEditBookingInterval: () => void;
  refetchAppointments: () => void;
}

export const BookingIntervalDetails: React.VFC<Props> = ({
  bookingInterval,
  navigateToCalendar,
  onEditBookingInterval,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { parseISO, format } = useLocalizedDate();
  const { profile } = useProfileContext();

  invariant(profile);

  const { end_time, health_care_professional, health_care_service, recurrence, start_time } = bookingInterval;

  return (
    <Popup onClickOutside={onClose} fullBgOpacity noPadding={false} showCloseButton>
      <Content>
        {health_care_service?.name && (
          <Header>
            {getAppointmentTitle({ hcpServiceName: health_care_service.name })}
            {" - "}
            <span style={{ textTransform: "lowercase" }}>{t("booking.forms.availability")}</span>
          </Header>
        )}

        {health_care_professional?.full_name && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faUserPen} color={theme.colors.redesign.db90} />
              {t("booking.event_details.personnel")}
            </Label>
            <Value>{health_care_professional.full_name}</Value>
          </Row>
        )}

        {start_time && end_time && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faClock} color={theme.colors.redesign.db90} />
              {t("booking.event_details.time")}
            </Label>
            <Value>{`${format(parseISO(start_time), "PPPPp")} - ${format(parseISO(end_time), "p")}`}</Value>
          </Row>
        )}

        {recurrence && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faClock} color={theme.colors.redesign.db90} />
              {t("booking.form.recurrence")}
            </Label>
            <Value>{t(`booking.form.recurrence_options.${recurrence}`)}</Value>
          </Row>
        )}

        {health_care_professional?.id === profile.id && (
          <Buttons>
            <BlueIconButton
              dataTestId="booking-edit-booking"
              label={t("booking.buttons.edit")}
              onClick={() => {
                onEditBookingInterval();
                if (navigateToCalendar) {
                  navigate("/calendar");
                }
              }}
            >
              <StyledFontAwesomeIcon icon={faEdit} color={theme.colors.redesign.b100} />
            </BlueIconButton>
          </Buttons>
        )}
      </Content>
    </Popup>
  );
};
