import type React from "react";

import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { css, keyframes } from "styled-components";

import type { UserCourseExercise } from "api/schemas/UserCourse";
import IconButton from "shared/atoms/IconButton";
import PremiumIcon from "shared/atoms/PremiumIcon";

import ActiveTag from "./ActiveTag";
import ExerciseEditRow from "./ExerciseEditRow";
import { JointTag } from "./SharedStyledComponents";

interface UserCourseExerciseProps {
  exercise: UserCourseExercise;
  currentProtocolWeek: number;
  onChangeLevel: (exerciseId: number, level: number) => void;
  onDelete: (id: number) => void;
  onChangeWeek: (id: number, week: number) => void;
  onChangeEndWeek: (id: number, week: number | null) => void;
  onClickExercise: (id: number) => void;
  newlyAdded?: boolean;
}

const UserCourseExerciseComponent: React.VFC<UserCourseExerciseProps> = ({
  exercise,
  currentProtocolWeek,
  onChangeLevel,
  onChangeWeek,
  onChangeEndWeek,
  onDelete,
  onClickExercise,
  newlyAdded = false,
}) => {
  const { id, premium, introduction_protocol_week: week, active, target_joints: joints } = exercise;

  const { t } = useTranslation();
  const label = exercise.levels[exercise.current_level - 1].title;
  const variation = exercise.levels[exercise.current_level - 1].variation_title;
  const image = exercise.levels[exercise.current_level - 1].image_url;

  return (
    <Root
      onClick={() => {
        onClickExercise(id);
      }}
      $newlyAdded={newlyAdded}
    >
      <Row>
        <Fill>
          <Title>
            {premium && (
              <IconWrapper>
                <PremiumIcon size="small" premiumType="guided_care" margin="0 6px 0 0 " />
              </IconWrapper>
            )}
            {label}
            {variation ? ` (${variation})` : ""}
          </Title>
          <Tags>
            <ActiveTag active={active} week={String(week)} />
            {joints.map(v => (
              // FIXME: type translation
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <JointTag key={v}>{t(`patients.pain_location.${v}.0`)}</JointTag>
            ))}
          </Tags>
        </Fill>
        <FlexEnd>
          <img height="88" alt="" src={image} />
        </FlexEnd>
        <Relative>
          <DeleteButtonWrapper>
            <IconButton
              label=""
              onClick={e => {
                e.stopPropagation();
                onDelete(id);
              }}
            >
              <CloseIcon icon={faCircleXmark} />
            </IconButton>
          </DeleteButtonWrapper>
        </Relative>
      </Row>

      <ExerciseEditRow
        currentProtocolWeek={currentProtocolWeek}
        exercise={exercise}
        onChangeEndWeek={onChangeEndWeek}
        onChangeLevel={onChangeLevel}
        onChangeWeek={onChangeWeek}
      />
    </Root>
  );
};

export default UserCourseExerciseComponent;

const Fill = styled.div`
  flex-grow: 1;
`;

const IconWrapper = styled.span`
  vertical-align: middle;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const newAnimKeyframes = keyframes`
  0% {
    box-shadow: 0px 0px 15px 0px #5096E8;
    transform: scale(1.1);
  }
  5% {
    transform: scale(1);
  }
  75% {
    box-shadow: 0px 0px 15px 0px #5096E8
  }
  100% {
    box-shadow: 0px 2px 12px 0px #0000000d;
  }
`;

const newAnim = css`
  animation: 5s ease-out ${newAnimKeyframes} forwards;
`;

const defaultValues = css`
  box-shadow: 0px 2px 12px 0px #0000000d;
  scale: 1;
`;

const Root = styled.div<{ $newlyAdded: boolean }>`
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  width: 300px;
  @media screen and (max-width: 694px) {
    width: 100%;
  }
  border-radius: 8px;
  ${props => (props.$newlyAdded ? newAnim : defaultValues)};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: #d4dbe2;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: end;
`;

const Relative = styled.div`
  position: relative;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: -18px;
  top: -8px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
