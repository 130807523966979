import type { PremiumType } from "api/models/PatientProfile";
import type { State } from "api/schemas/NewAppointment";
import type { DateString } from "types/DateString";

interface Participant {
  id: number;
  first_name: string;
  last_name: string;
  preferred_name: string;
  avatar: string | null;
}

export interface Appointment {
  deleted_at?: string | null;
  end_time: string;
  health_care_professional_id?: number;
  ics_url: string;
  id: number;
  location?: string;
  medium: "phone" | "video";
  patient_first_name: string;
  patient_id: number;
  patient_last_name: string;
  patient_preferred_name?: string;
  patient_number: string;
  purpose: "kick_off" | "follow_up" | "general";
  start_time: string;
  uid?: string;
  state: string | null;
  state_v2?: State | null;
}

export interface Activity {
  id: number;
  completed_on: string;
  name: string;
  duration_minutes: number | null;
  status: string;
  comment: string;
  required: boolean;
  blocking: boolean;
  start_date: string;
  viewed_by_therapist_at: string | null;
  week_number: number;
  day_index: number;
  week_day: number;
  translations: {
    title?: string | null;
  };
  activity_name: string;
  activity_type: string;
  enabled: boolean;
  exercise?: {
    current_level: number;
    level: number;
    levels: [
      {
        id: number;
        level: number;
      }
    ];
  };
  w1_health_report?: {
    name: string;
  };
  weekly_health_report?: {
    name: string;
  };
}
interface Included {
  appointment?: Appointment;
  activity?: Activity;
}

export interface Message {
  body?: string;
  created_at: string;
  from: Participant;
  id: number;
  included?: Included;
  read_at: string | null;
  to: Participant;
  message_data?: {
    assignment_role: string;
    change: string;
    previously_active_therapist?: { id: number; first_name: string; last_name: string };
    assignment_end_date: DateString | null;
  };
  type:
    | "activity"
    | "appointment"
    | "suggested_kickoff_appointment"
    | "text"
    | "insurance_change"
    | "patient_assignment_change"
    | "patient_end_treatment"
    | "pt_on_demand_started"
    | "pt_on_demand_ended"
    | "pt_on_demand_payment_success"
    | "guided_care_started"
    | "guided_care_ended"
    | "switched_treatment";
  previous_lateral_location?: string;
  previous_location?: string;
  new_lateral_location?: string;
  new_location?: string;
}

export const Purpose = {
  DISCHARGE_CALL: "DISCHARGE_CALL",
  KICK_OFF: "KICK_OFF",
  FOLLOW_UP: "FOLLOW_UP",
  FOLLOW_UP_VIDEO_CALL: "FOLLOW_UP_VIDEO_CALL",
  GENERAL: "GENERAL",
  ADHERENCE: "ADHERENCE",
} as const;
export type Purpose = typeof Purpose[keyof typeof Purpose];

export const Medium = { PHONE: "PHONE", VIDEO: "VIDEO", FACE_TO_FACE: "FACE_TO_FACE" } as const;
export type Medium = typeof Medium[keyof typeof Medium];

interface Peer {
  // TODO: properly type available_until (there was no Peer with any other value but null in the scrub)
  available_until: string | null;
  avatars: {
    large?: string;
    medium?: string;
    small?: string;
  };
  first_name: string;
  id: number;
  last_name: string;
  locked_reason: string;
  roles: Array<string>;
  type: string;
  unlocked: boolean;
  therapist_assignment_role: string;
  premium_type: PremiumType;
}

interface LastMessage {
  body: string | null;
  created_at: string;
  inbound: boolean;
  read: boolean;
  read_at: string | null;
  type: "chat" | "appointment";
}

export interface PinnedMessage {
  body: string;
  created_at: string;
  id: number;
  peerId: number;
}

export interface Conversation {
  peer: Peer;
  last_message: LastMessage;
  has_unread_messages: boolean;
  pinned_messages: PinnedMessage[];
}

export interface Conversations {
  unreadCount: number;
  conversations: Array<Conversation>;
}
