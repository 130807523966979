import type React from "react";

import { useTranslation } from "react-i18next";

import type { UnclaimedReferral } from "api/schemas/PartnerUnclaimedReferralSchema";
import { TableCell, TableRow } from "shared/molecules/Table";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import useLocalizedDate from "utils/date";

import { CellContainer, DurationText, ViewLink } from "./helpers";

const UnclaimedReferralsTableRow: React.VFC<UnclaimedReferral> = ({
  id,
  patientName,
  durationDays,
  url,
  dateOfPhysicianSignature,
}) => {
  const { t } = useTranslation();
  const { format, parseISO, add } = useLocalizedDate();
  const referralIssued = format(parseISO(dateOfPhysicianSignature), "d MMMM, yyyy");

  const renderDuration = (days: number | undefined) => {
    if (days) {
      const expiryDate = add(parseISO(dateOfPhysicianSignature ?? ""), { days });
      const durationAndExpiryDate =
        days?.toString().concat(" Days (") + expiryDate?.toString().slice(4, 15).concat(")");
      return durationAndExpiryDate;
    }
    return t("partner_patients.unclaimed_referral.no_duration");
  };
  const openReferral = () => {
    const win = window.open(url, "_blank");
    win?.focus();
    AnalyticsService.track(AnalyticsEvents.CLINIC_PARTNER.UNCLAIMED_REFERRAL_VIEWED);
  };
  return (
    <TableRow onClick={() => openReferral()}>
      <TableCell>{id}</TableCell>
      <TableCell>{patientName}</TableCell>
      <TableCell>
        <CellContainer>{referralIssued}</CellContainer>
      </TableCell>
      <TableCell>
        <CellContainer>
          <DurationText>{renderDuration(durationDays)}</DurationText>
          <ViewLink data-testid="url-link">{t("buttons.view")}</ViewLink>
        </CellContainer>
      </TableCell>
    </TableRow>
  );
};

export default UnclaimedReferralsTableRow;
