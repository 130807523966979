import type React from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MUITextInput from "shared/atoms/inputs/MUITextInput";
import Row from "shared/atoms/Row";
import { getSectionTitleKey } from "utils/medicalNotes";

import { Section } from ".";

interface Props {
  readonly?: boolean;
  visible?: boolean;
  onChange?: (value: string) => void;
}

const ExclusionSection: React.VFC<Props> = ({ readonly = false, visible = false, onChange }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return visible ? (
    <Section
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label={t(getSectionTitleKey("exclusion"))}
      disabled={readonly}
      dataTestId="exclusion-section"
      backgroundColor="blue"
    >
      <Description>{t("patients.notes.exclusion.reason_description")}</Description>
      <MUITextInput
        error={errors?.exclusion_reason ? t("errors.string.empty") : undefined}
        name="structured_fields.exclusion_reason"
        disabled={readonly}
        label={t("patients.notes.exclusion.reason_title")}
        multiline
        onUpdate={onChange}
      />
      <StyledRow>{t("patients.notes.exclusion.not_saved")}</StyledRow>
    </Section>
  ) : null;
};

const Description = styled(Row)`
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.caption};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;
const StyledRow = styled(Row)`
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.caption};
`;

export { ExclusionSection };
