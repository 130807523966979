import i18n from "i18next";

import type { HealthReportAnswer, HealthReportAnswers } from "api/models/HealthReport";
import type { Ailment, TextAnswer } from "api/schemas/patients/HealthJournal";
import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";

import fall_prevention_questions from "./fall_prevention_questions.json";
import osteoporosis_questions from "./osteoporosis_questions.json";
import questionsData from "./questions.json";

export interface Question {
  title_key?: string;
  subtitle?: string;
  header?: string;
  key: string;
  type: string;
  sub_questions?: Question[] | null;
  params?: Record<string, string> | null;
  description?: string[] | null;
  header_description?: string[];
}

interface ReducedQuestion extends Omit<Question, "sub_questions" | "key"> {
  accepts_params?: string[];
  sub_questions?: Record<string, ReducedQuestion>;
}

export type PainLocation = "knee" | "hip" | "hand" | "shoulder" | "lower_back" | "neck";

const questions = (painLocation: string, joint: string, ailment: Ailment): Question[] => {
  const questionsToArray = (obj: Record<string, ReducedQuestion>, level: number): Question[] => {
    return Object.keys(obj).map(key => {
      const { sub_questions, accepts_params, ...value } = obj[key as keyof typeof obj];

      const result = {
        key,
        params: null,
        description: null,
        ...value,
      } as Question;

      if (accepts_params) {
        if (accepts_params.includes("painLocation")) {
          result.params = Object.assign(result.params || {}, { painLocation });
        }

        if (accepts_params.includes("joint")) {
          result.params = Object.assign(result.params || {}, { joint });
        }
      }

      if (sub_questions) {
        result.sub_questions = questionsToArray(sub_questions, 1);
      } else if (level === 0) {
        result.sub_questions = null;
      }

      return result;
    });
  };

  switch (ailment) {
    case "fall_prevention":
      return questionsToArray(fall_prevention_questions, 0);
    case "osteoporosis":
      return questionsToArray(osteoporosis_questions, 0);
    default:
      return questionsToArray(questionsData, 0);
  }
};

const booleanToAnswer = (answer?: boolean | string | null): string => {
  if (answer == null) {
    return i18n.t("patients.medical_records.not_applicable");
  }
  return answer ? i18n.t("patient.answered.yes") : i18n.t("patient.answered.no");
};

const textToAnswer = (answer: TextAnswer): string => {
  switch (answer) {
    case "no":
      return i18n.t("patient.ONBOARDING.ANSWERS.NO");
    case "dont_know":
      return i18n.t("patient.ONBOARDING.ANSWERS.DONT_KNOW");
    case "yes":
      return i18n.t("patient.ONBOARDING.ANSWERS.YES");
    case "yes_examined":
      return i18n.t("patient.ONBOARDING.ANSWERS.ACCIDENT_EXAMINED");
    case "yes_unexamined":
      return i18n.t("patient.ONBOARDING.ANSWERS.ACCIDENT_NOT_EXAMINED");
    default:
      return "";
  }
};

const rangeToAnswer = (key: string, answer: string | number): string => `${key}_values.${answer}`;

const stringRangeToAnswer = (key: string, answer: string | number): string => `${key}_values.${answer}`;

const multipleToAnswer = (key: string, answer: string[]): string[] => {
  return answer.map(a => `${key}_values.${a}`);
};

const multipleFingersToAnswer = (key: string, answer: string[]): string[] => {
  return answer.map(a => `${key}_values.${a}`);
};

export interface HealthReport {
  answers: HealthReportAnswers;
}

const someAnswered = (reports: HealthReport[], key: string, isSub: boolean, parentKey: string | null): boolean => {
  let answered = false;
  for (let i = 0; i < reports.length; i += 1) {
    const report = reports[i];
    const parentAnswer = parentKey && report.answers[parentKey];
    const childAnswer = getChildAnswer(parentAnswer, key);

    if (report.answers[key] !== undefined) {
      answered = true;
    } else if (isSub && parentAnswer !== undefined && childAnswer !== undefined) {
      answered = true;
    }
    if (answered) break;
  }
  return answered;
};

const getChildAnswer = (
  parentAnswer: null | HealthReportAnswer | Record<string, HealthReportAnswer>,
  questionKey: string
): HealthReportAnswer => {
  if (
    parentAnswer != null &&
    typeof parentAnswer !== "string" &&
    typeof parentAnswer !== "boolean" &&
    typeof parentAnswer !== "number" &&
    !(parentAnswer instanceof Array)
  ) {
    return parentAnswer[questionKey];
  }
  return undefined;
};

const answersChanged = (
  reports: HealthReport[],
  key: string,
  isSub: boolean,
  parentKey: string | null,
  render?: boolean
): boolean => {
  let changed = false;
  if (reports.length === 1 && render) return true;
  let uniqueAnswer = null;
  for (let i = 0; i < reports.length; i += 1) {
    const report = reports[i];
    const answer = report.answers[key];
    const parentAnswer = parentKey && report.answers[parentKey];
    const childAnswer = getChildAnswer(parentAnswer, key);

    if (uniqueAnswer === null) {
      if (answer !== undefined) {
        uniqueAnswer = answer;
      } else if (isSub && parentKey && report.answers[parentKey] !== undefined && childAnswer !== undefined) {
        uniqueAnswer = childAnswer;
      }
    } else {
      if (answer !== undefined && answer !== uniqueAnswer) {
        changed = true;
      } else if (isSub && parentAnswer !== undefined && childAnswer !== undefined && childAnswer !== uniqueAnswer) {
        changed = true;
      }
      if (changed) break;
    }
  }
  return changed;
};

const handleHeight = (height: number, unit: string): string => {
  if (unit === "in") {
    return `${Math.floor(height / 12)}'${height % 12}`;
  }
  return `${height} ${unit}`;
};

const exists = (element: unknown): boolean => element !== undefined && element !== null;

const getSelectedLeftHand = (answer: string[]): string[] => answer.filter(a => a.includes("left"));

const getSelectedRightHand = (answer: string[]): string[] => answer.filter(a => a.includes("right"));

const getInnerJoint = (j: string): string => `patients.painful_finger_joint_${j.split("_")[2]}`;

const isAdditionalJoint = (joint?: string) => joint && ["lower_leg", "wrist", "elbow", "foot"].includes(joint);

const hasAnsweredAcute = (painDuration: string) =>
  painDuration === "less_than_1_month" || painDuration === "1_to_3_months";

const isLegOrArm = (joint?: Joint): "leg" | "arm" | undefined => {
  switch (joint) {
    case "hip":
    case "knee":
    case "lower_back":
      return "leg";
    case "neck":
    case "shoulder":
    case "elbow":
      return "arm";
    default:
      return undefined;
  }
};

export {
  questions,
  booleanToAnswer,
  textToAnswer,
  rangeToAnswer,
  stringRangeToAnswer,
  someAnswered,
  answersChanged,
  multipleToAnswer,
  multipleFingersToAnswer,
  handleHeight,
  exists,
  getSelectedLeftHand,
  getSelectedRightHand,
  getInnerJoint,
  getChildAnswer,
  isAdditionalJoint,
  hasAnsweredAcute,
  isLegOrArm,
};
