import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

import type { GetPatientUnreadCommentsCountResponse } from "../types/GetPatientUnreadCommentsCount";

type QueryKey = [string, number];
export const getPatientUnreadCommentsCountQueryKey = (patientId: number): QueryKey => [
  "GetPatientUnreadCommentsCountQuery",
  patientId,
];

export const useGetPatientUnreadCommentsCountQuery = (
  patientId: number,
  options?: UseQueryOptions<
    GetPatientUnreadCommentsCountResponse,
    Error,
    GetPatientUnreadCommentsCountResponse,
    QueryKey
  >
): UseQueryResult<GetPatientUnreadCommentsCountResponse, Error> => {
  const client = useApiClient();

  return useQuery(
    getPatientUnreadCommentsCountQueryKey(patientId),
    () => client.getPatientUnreadCommentsCount(patientId),
    options
  );
};
