import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { PhoneCallIcon, VideoCallIcon, VoiceCallIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { useVoiceCallContext } from "contexts/VoiceCallContext";
import { CurrentPatientContext } from "utils/contexts";
import { useCallFeatures } from "utils/hooks";

import DropdownButton from "./components/DropdownButton";
import { Button } from "./helpers";

const PatientHeaderInfoCallButton: React.VFC = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { patient } = useContext(CurrentPatientContext);
  const { voiceCallData, setVoiceCallData } = useVoiceCallContext();
  const { videoCallsEnabled, voiceCallsEnabled, phoneCallsEnabled } = useCallFeatures();
  const { callData: videoCallData, setCallData } = useVideoCallContext();

  invariant(profile);
  invariant(patient);

  const disabled = !!videoCallData || !!voiceCallData;

  const options = [
    {
      children: (
        <OptionItem>
          <VoiceCallIcon />
          <div>{t("buttons.voice_call")}</div>
        </OptionItem>
      ),
      enabled: voiceCallsEnabled,
      dataTestId: "voice-call-button",
      disabled,
      onClick: () => {
        if (disabled) return;
        setVoiceCallData({
          caller: {
            id: profile.id,
          },
          callee: {
            id: patient.id,
            name: `${patient.first_name} ${patient.last_name}`,
          },
        });
      },
    },
    {
      children: (
        <OptionItem>
          <VideoCallIcon />
          <div>{t("buttons.video_call")}</div>
        </OptionItem>
      ),
      enabled: videoCallsEnabled,
      dataTestId: "video-call-button",
      disabled,
      onClick: () => {
        if (disabled) return;
        setCallData({
          caller: {
            id: profile.id,
            name: `${profile.first_name} ${profile.last_name}`,
          },
          callee: {
            id: patient.id,
            name: `${patient.first_name} ${patient.last_name}`,
          },
        });
      },
    },
    {
      children: (
        <OptionItem>
          <PhoneCallIcon />
          <div>{t("buttons.phone_call")}</div>
        </OptionItem>
      ),
      enabled: phoneCallsEnabled,
      dataTestId: "phone-call-button",
      disabled,
      onClick: () => {
        if (disabled) return;
        setVoiceCallData({
          caller: {
            id: profile.id,
          },
          callee: {
            id: patient.id,
            name: `${patient.first_name} ${patient.last_name}`,
            phone_number: patient.phone_number,
          },
        });
      },
    },
  ];

  const enabledOptions = options.filter(option => option.enabled);

  if (enabledOptions.length === 0) {
    return null;
  }

  if (enabledOptions.length === 1) {
    return <Button data-testid="call-button" type="button" {...enabledOptions[0]} />;
  }

  return (
    <DropdownButton id="call-dropdown-button" dataTestId="call-dropdown-button" options={enabledOptions}>
      <VideoCallIcon />
      <NewCallText>{t("buttons.call")}</NewCallText>
    </DropdownButton>
  );
};

export default PatientHeaderInfoCallButton;

const OptionItem = styled.div`
  display: flex;
  align-items: center;

  > svg {
    border: 0;
    margin-right: ${props => props.theme.spacing.S_10};
    min-width: 25px;
  }
`;

const NewCallText = styled.div`
  color: ${props => props.theme.colors.primary.base};

  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;
