import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const verifyCallSuccess = (id: number): Promise<AxiosResponse> =>
  AxiosInstance.post(`/video_calls/${id}/verify_success`, {
    call_successful: true,
  });

export default verifyCallSuccess;
