import type { NewAppointment, PaymentMethod, State } from "api/schemas/NewAppointment";
import type { Personnel } from "contexts/CalendarContext";
import theme from "theme";

import { maybeConvertUTCToZoned } from "../../helpers";

import { getAppointmentBgColor, getAppointmentBorderColor } from "./getAppointmentColors";
import { getAppointmentTitle } from "./getAppointmentTitle";

type GroupedAppointments = {
  [hcProfId: string]: NewAppointment[];
};

export const HCPROF_COLORS = [
  {
    bgColor: theme.colors.redesign.p20,
    borderColor: theme.colors.redesign.p40,
    availabilityColor: theme.colors.redesign.p10,
  },
  {
    bgColor: theme.colors.redesign.p40,
    borderColor: theme.colors.redesign.p60,
    availabilityColor: theme.colors.redesign.p30,
  },
  {
    bgColor: theme.colors.redesign.g20,
    borderColor: theme.colors.redesign.g40,
    availabilityColor: theme.colors.redesign.g10,
  },
  {
    bgColor: theme.colors.redesign.g40,
    borderColor: theme.colors.redesign.g60,
    availabilityColor: theme.colors.redesign.g30,
  },
  {
    bgColor: theme.colors.redesign.o20,
    borderColor: theme.colors.redesign.o40,
    availabilityColor: theme.colors.redesign.o10,
  },
  {
    bgColor: theme.colors.redesign.o40,
    borderColor: theme.colors.redesign.o60,
    availabilityColor: theme.colors.redesign.o30,
  },
  {
    bgColor: theme.colors.redesign.t20,
    borderColor: theme.colors.redesign.t40,
    availabilityColor: theme.colors.redesign.t10,
  },
  {
    bgColor: theme.colors.redesign.t40,
    borderColor: theme.colors.redesign.t60,
    availabilityColor: theme.colors.redesign.t30,
  },
  {
    bgColor: theme.colors.redesign.y20,
    borderColor: theme.colors.redesign.y40,
    availabilityColor: theme.colors.redesign.y10,
  },
  {
    bgColor: theme.colors.redesign.y40,
    borderColor: theme.colors.redesign.y60,
    availabilityColor: theme.colors.redesign.y30,
  },
  {
    bgColor: theme.colors.redesign.b20,
    borderColor: theme.colors.redesign.b40,
    availabilityColor: theme.colors.redesign.b10,
  },
  {
    bgColor: theme.colors.redesign.b40,
    borderColor: theme.colors.redesign.b60,
    availabilityColor: theme.colors.redesign.b30,
  },
  {
    bgColor: theme.colors.redesign.db20,
    borderColor: theme.colors.redesign.db40,
    availabilityColor: theme.colors.redesign.db10,
  },
  {
    bgColor: theme.colors.redesign.db40,
    borderColor: theme.colors.redesign.db60,
    availabilityColor: theme.colors.redesign.db30,
  },
  {
    bgColor: theme.colors.redesign.r20,
    borderColor: theme.colors.redesign.r40,
    availabilityColor: theme.colors.redesign.r10,
  },
  {
    bgColor: theme.colors.redesign.r40,
    borderColor: theme.colors.redesign.r60,
    availabilityColor: theme.colors.redesign.r30,
  },
];

const SHADOW = "0px 4px 8px rgba(0, 0, 0, 0.2)";

interface Args {
  appointments: NewAppointment[];
  isAdmin: boolean;
  selectedPersonnel?: Personnel[];
  selectedAppointment?: NewAppointment | null;
  showCanceledAppointments: boolean;
  showRescheduledAppointments: boolean;
  ianaTimeZone?: string | null;
}

export function transformAppointments({
  appointments,
  isAdmin,
  selectedPersonnel,
  selectedAppointment,
  showCanceledAppointments,
  showRescheduledAppointments,
  ianaTimeZone,
}: Args) {
  let filteredAppointments: NewAppointment[] = appointments;
  let transformedAppointments;

  if (!showCanceledAppointments) {
    filteredAppointments = filteredAppointments.filter(appointment => appointment.state !== "canceled");
  }

  if (!showRescheduledAppointments) {
    filteredAppointments = filteredAppointments.filter(appointment => appointment.state !== "rescheduled");
  }

  if (isAdmin) {
    // Group all appointments by health_care_professional.id
    const groupedAppointments: GroupedAppointments = filteredAppointments.reduce(
      (hcProfs, item) => ({
        ...hcProfs,
        [item.health_care_professional.id]: [...(hcProfs[item.health_care_professional.id] || []), item],
      }),
      {} as GroupedAppointments
    );

    // Transform grouped appointments and lastly flatten the nested array
    transformedAppointments = Object.keys(groupedAppointments)
      .filter(hcProfId =>
        selectedPersonnel && selectedPersonnel.length > 0
          ? selectedPersonnel.map(pr => pr.id)?.includes(parseInt(hcProfId, 10))
          : hcProfId
      )
      .map((hcProf: string, i) => {
        const backgroundColor =
          selectedPersonnel?.find(hcProfId => hcProfId.id === parseInt(hcProf, 10))?.bgColor ??
          HCPROF_COLORS[i]?.bgColor;
        const borderColor =
          selectedPersonnel?.find(hcProfId => hcProfId.id === parseInt(hcProf, 10))?.borderColor ??
          HCPROF_COLORS[i]?.borderColor;

        return groupedAppointments[hcProf].map(appointment => ({
          title: getAppointmentTitle({
            purpose: appointment.purpose,
            hcpServiceName: appointment.health_care_provider_service_name,
          }),
          start: maybeConvertUTCToZoned({ date: appointment.start_time, ianaTimeZone }),
          end: maybeConvertUTCToZoned({ date: appointment.end_time, ianaTimeZone }),
          editable: !isAppointmentDisabled(appointment.state),
          extendedProps: {
            type: "appointment",
            eventId: appointment.id,
            participants: appointment.participants,
            payer: appointment.payer,
            payment_method: appointment.payment_method,
            personnel: {
              full_name: appointment.health_care_professional.full_name,
              profession: appointment.health_care_professional.profession,
            },
            state: appointment.state,
          },
          backgroundColor,
          borderColor,
          boxShadow: selectedAppointment && appointment.id === selectedAppointment.id && SHADOW,
          displayEventTime: false,
          displayEventEnd: false,
        }));
      })
      .flat();
  } else {
    transformedAppointments = filteredAppointments.map(appointment => ({
      title: getAppointmentTitle({
        purpose: appointment.purpose,
        hcpServiceName: appointment.health_care_provider_service_name,
      }),
      start: maybeConvertUTCToZoned({ date: appointment.start_time, ianaTimeZone }),
      end: maybeConvertUTCToZoned({ date: appointment.end_time, ianaTimeZone }),
      editable: !isAppointmentDisabled(appointment.state),
      extendedProps: {
        type: "appointment",
        eventId: appointment.id,
        participants: appointment.participants,
        payer: appointment.payer,
        payment_method: appointment.payment_method,
        personnel: {
          full_name: appointment.health_care_professional.full_name,
          profession: appointment.health_care_professional.profession,
        },
        state: appointment.state,
      },
      backgroundColor: getAppointmentBgColor(appointment),
      borderColor: getAppointmentBorderColor(appointment),
      boxShadow: selectedAppointment && appointment.id === selectedAppointment.id && SHADOW,
      displayEventTime: false,
      displayEventEnd: false,
    }));
  }

  return transformedAppointments;
}

interface IsAppointmentConfirmedArgs {
  paymentMethod: PaymentMethod;
  payerName: string | null | undefined;
}

export function isAppointmentConfirmed({ paymentMethod, payerName }: IsAppointmentConfirmedArgs) {
  // Patients with payerName "Frösjö", "SOS" and "Euro Accident" are always confirmed as they don't need to add payment method
  if (payerName === "Frösjö" || payerName === "SOS" || payerName === "Euro Accident") return true;
  if (paymentMethod !== "none") return true;
  return false;
}

export function isAppointmentDisabled(state: State) {
  switch (state) {
    case "canceled":
    case "completed":
    case "no_show":
    case "rescheduled":
      return true;
    default:
      return false;
  }
}
