import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type {
  GetPhysicalFunctionGraphArgs,
  GetPhysicalFunctionGraphResponse,
} from "api/types/GetPhysicalFunctionGraph";
import useApiClient from "api/useApiClient";

export const GET_PHYSICAL_FUNCTION_GRAPH_DATA_QUERY_KEY = "getPhysicalFunctionGraphData";

export const useGetPhysicalFunctionGraphData = (
  args: GetPhysicalFunctionGraphArgs,
  options?: UseQueryOptions<GetPhysicalFunctionGraphResponse, Error>
): UseQueryResult<GetPhysicalFunctionGraphResponse, Error> => {
  const client = useApiClient();

  return useQuery<GetPhysicalFunctionGraphResponse, Error>(
    [GET_PHYSICAL_FUNCTION_GRAPH_DATA_QUERY_KEY, args.patientId, args.treatment_id],
    () => client.getPhysicalFunctionGraphData(args),
    options
  );
};
