import type React from "react";
import { useEffect, useState } from "react";

import type { QRCodeRenderersOptions } from "qrcode";
import QRLib from "qrcode";

const qrCodeOptions: QRCodeRenderersOptions = {
  margin: 3,
  errorCorrectionLevel: "L",
  width: 200,
};

interface Props {
  qrCode: string;
}

export const QRCode: React.VFC<Props> = ({ qrCode }) => {
  const [qrImage, setQrImage] = useState<string | undefined>();

  useEffect(() => {
    QRLib.toDataURL(qrCode, qrCodeOptions)
      .then(url => {
        setQrImage(url);
      })
      .catch(() => {
        setQrImage(undefined);
      });
  }, [qrCode]);

  if (!qrImage) {
    return null;
  }

  return <img src={qrImage} alt="QR code" />;
};
