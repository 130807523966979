import { useQueryClient } from "react-query";

import { getTreatmentActivityLogsPatientQueryKey } from "api/hooks/useGetTreatmentActivityLogsPatient";
import { usePostTreatmentActivityLog } from "api/hooks/usePostTreatmentActivityLog";
import type { TreatmentActivityLogPayload } from "api/schemas/TreatmentActivityLog";

export const useActivityLogging = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = usePostTreatmentActivityLog();

  const trackActivity = async (data: TreatmentActivityLogPayload) => {
    await mutateAsync(
      { data },
      {
        onSuccess: () => {
          const queryKey = getTreatmentActivityLogsPatientQueryKey(data.patient_id);
          queryClient.invalidateQueries(queryKey);
        },
      }
    );
  };

  return { trackActivity };
};
