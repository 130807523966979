import type React from "react";
import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import type { z } from "zod";

import { EmailIcon, LockIcon } from "assets";
import { authenticateWithEmail } from "routes/login/queries";
import { loginSchema } from "routes/login/utils/validation/emailPasswordSchemas";
import { MUITextInput } from "shared/atoms/inputs";
import CheckBox from "shared/atoms/inputs/CheckBox";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { useStatuspage } from "utils/statuspage/hooks";
import { getStorageValue, setStorageValue } from "utils/storage";

import getSignedOutParams from "../../utils/getSignedOutParams";
import { ForgotPasswordLink } from "../ForgotPasswordLink";
import { Form, SubmitButton } from "../styledComponents";

type FormValues = z.infer<typeof loginSchema>;

const EmailForm: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectUrl, signedOutError } = getSignedOutParams(location);
  const savedEmail = getStorageValue("login-remember-email");

  const [rememberMe, setRememberMe] = useState(Boolean(savedEmail));
  const successUrl = redirectUrl || "/dashboard";
  const [authRequest, setAuthRequest] = useState({ loading: false, error: false, message: "" });
  const { blockLogin } = useStatuspage();
  const form = useForm<FormValues>({
    defaultValues: { email: savedEmail || "", password: "" },
    resolver: zodResolver(loginSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    watch,
  } = form;

  const onSubmit: SubmitHandler<FormValues> = async body => {
    setStorageValue("login-remember-email", rememberMe ? body.email : "");
    setAuthRequest({ loading: true, error: false, message: "" });

    try {
      await authenticateWithEmail(body);
      navigate(successUrl);
    } catch (err: unknown) {
      let message = t("errors.generic_login");

      if (axios.isAxiosError(err)) {
        const { response } = err;

        if (response?.status === 400 || response?.status === 401 || response?.status === 422) {
          message = t("errors.invalid_login");
        }
      }

      setAuthRequest({ loading: false, error: true, message });
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledMUITextInput
          name="email"
          type="email"
          placeholder={t("form.email")}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.email?.message && t(errors?.email?.message)}
          startAdornment={<EmailIcon />}
          autoFocus={!savedEmail}
        />

        <RememberMeCheckBox
          label={t("email.remember_me")}
          name="rememberEmail"
          onChange={() => setRememberMe(!rememberMe)}
          checked={rememberMe}
          noHighlight
        />

        <StyledMUITextInput
          name="password"
          type="password"
          placeholder={t("form.password")}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors?.password?.message && t(errors.password.message)}
          startAdornment={<LockIcon />}
          autoFocus={Boolean(savedEmail)}
        />

        <ForgotPasswordLink email={watch("email")} />

        {authRequest.error && (
          <Notification variant="danger" style={{ margin: "10px 0" }}>
            {authRequest?.message}
          </Notification>
        )}

        {signedOutError && (
          <Notification variant="danger" style={{ margin: "10px 0" }}>
            {signedOutError === "session_expired" ? t("errors.session_expired") : signedOutError}
          </Notification>
        )}

        <StyledButton disabled={authRequest.loading || blockLogin} type="submit">
          {authRequest.loading ? <Spinner small /> : t("buttons.sign_in")}
        </StyledButton>
      </Form>
    </FormProvider>
  );
};

export default EmailForm;

const RememberMeCheckBox = styled(CheckBox)`
  width: 100%;
  margin: 12px 0 24px 12px;
`;

const StyledButton = styled(SubmitButton)`
  margin-top: 32px;
  margin-bottom: ${props => props.theme.spacing.S_15};
  box-shadow: 0px 10px 20px rgba(68, 104, 147, 0.3);
`;

const StyledMUITextInput = styled(MUITextInput)`
  flex: initial;
  width: 100%;
`;
