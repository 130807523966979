import { useEffect, useState } from "react";

import invariant from "ts-invariant";

import useGetHcpServices from "api/hooks/useGetHcpServices";
import useGetManagerServices from "api/hooks/useGetManagerServices";
import { useProfileContext } from "contexts/ProfileContext";
import { END_TIME, START_TIME } from "routes/calendar/helpers/misc";
import { convertMinutesToTime } from "routes/calendar/helpers/settingsHelpers";
import { userIsAdmin } from "utils/profile/profileHelper";

import type { Service } from "../../Forms/BookAppointmentForm";

type Hours = {
  openingHours: number;
  closingHours: number;
  openingHoursString: string;
  closingHoursString: string;
};

// TODO: Export services and refactor service usage?
export const useHours = () => {
  const { profile } = useProfileContext();
  const [services, setServices] = useState<Service[]>([]);
  const [hours, setHours] = useState<Hours>();

  invariant(profile);
  const isAdmin = userIsAdmin(profile);

  const { data: hcpServices = [] } = useGetHcpServices(profile.id, {
    enabled: !isAdmin,
  });
  const { data: servicesManager = [] } = useGetManagerServices(profile.id, {
    enabled: isAdmin,
  });

  useEffect(() => {
    if (!isAdmin && hcpServices.length > 0) {
      setServices(hcpServices);
    } else if (isAdmin && servicesManager.length > 0) {
      setServices(servicesManager);
    }
  }, [isAdmin, hcpServices, servicesManager]);

  useEffect(() => {
    if (services.length > 0) {
      const result = services.reduce(
        (acc, curr) => {
          // Finding the smallest opening_hours
          if (curr.opening_hours < acc.opening_hours) {
            acc.opening_hours = curr.opening_hours;
          }

          // Finding the biggest closing_hours
          if (curr.closing_hours > acc.closing_hours) {
            acc.closing_hours = curr.closing_hours;
          }

          return acc;
        },
        {
          opening_hours: START_TIME,
          closing_hours: END_TIME,
        }
      );

      setHours({
        openingHours: result.opening_hours,
        closingHours: result.closing_hours,
        openingHoursString: convertMinutesToTime(result.opening_hours),
        closingHoursString: convertMinutesToTime(result.closing_hours),
      });
    }
  }, [services]);

  return { ...hours };
};
