import type React from "react";

import { faPen, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { useTheme } from "styled-components";

interface Props {
  communicationGroup: string | null;
}

export const CommunicationGroupIcon: React.VFC<Props> = ({ communicationGroup }) => {
  const theme = useTheme();

  const getCommunicationGroupIcon = () => {
    switch (communicationGroup) {
      case "async":
        return <FontAwesomeIcon icon={faPen} color={theme.colors.redesign.db90} />;
      case "sync":
        return <FontAwesomeIcon icon={faVideo} color={theme.colors.redesign.db90} />;
      default:
        return null;
    }
  };

  return <Container>{getCommunicationGroupIcon()}</Container>;
};

const Container = styled.div`
  display: inline-block;
  min-width: 14px;
  margin-right: 8px;
`;
