import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserRole } from "api/models/User";
import { PatientAppCloseIcon, PhoneHeaderBar } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import IconButton from "shared/atoms/IconButton";
import Popup from "shared/atoms/Popup";

interface ImageProps {
  image: string;
}
interface Props {
  setOpen: (open: boolean) => void;
}
const ProfileBioPopup: React.VFC<Props> = ({ setOpen }) => {
  const { profile } = useProfileContext();
  const { t } = useTranslation();

  return (
    <StyledPopup bioPreviewModal>
      <Container data-testid="preview-therapist-profile">
        <PhoneHeader>
          <PhoneIconBar />
          <StyledIconButton label="" onClick={() => setOpen(false)}>
            <StyledPatientAppCloseIcon />
          </StyledIconButton>
          <HeaderTitle>DIN FYSIOTERAPEUT</HeaderTitle>
        </PhoneHeader>
        {profile?.avatars?.large ? (
          <ImageSection data-testid="physioBioModalHeader" image={profile?.avatars?.large} />
        ) : (
          <NoPicHeader data-testid="physioBioModalHeader" />
        )}
        <ViewSection>
          <Header>{`${profile?.first_name} ${profile?.last_name}`}</Header>
          {profile?.roles[0] === UserRole.Therapist && (
            <Secondaryheader>{t("bio_preview.therapist_role")}</Secondaryheader>
          )}
        </ViewSection>

        <TextSection data-testid="previewAbout">
          <Subheader>{t("bio_preview.about", { name: profile?.first_name })}</Subheader>
          {profile?.therapist_profile.bio ? (
            <Body>{profile?.therapist_profile.bio}</Body>
          ) : (
            <Body>{t("bio_preview.no_bio")}</Body>
          )}
        </TextSection>
        {profile?.therapist_profile.bio_experience && (
          <TextSection data-testid="preview-bio-experience">
            <Subheader>{t("bio_preview.education")}</Subheader>
            <Body>{profile?.therapist_profile.bio_experience}</Body>
          </TextSection>
        )}
        {profile?.therapist_profile.bio_availability && (
          <TextSection data-testid="preview-bio-availability">
            <Subheader>{t("bio_preview.availability")}</Subheader>
            <Body>{profile?.therapist_profile.bio_availability}</Body>
          </TextSection>
        )}
        {profile?.therapist_profile.bio_outside_the_clinic && (
          <TextSection data-testid="preview-bio-hobbies">
            <Subheader>{t("bio_preview.hobbies")}</Subheader>
            <Body>{profile?.therapist_profile.bio_outside_the_clinic}</Body>
          </TextSection>
        )}
      </Container>
    </StyledPopup>
  );
};
const StyledPopup = styled(Popup)`
  margin-left: 20%;
`;
const NoPicHeader = styled.div`
  margin: 10% 0 20% 0;
`;
const HeaderTitle = styled.p`
  margin: 14% 0 0 26%;
  color: #3c4c60;
  font-family: "Museo Sans", sans-serif;
  font-size: 11px;
  letter-spacing: 0.8px;
  font-weight: 700;
`;
const PhoneHeader = styled.div`
  position: fixed;
  display: flex;
  background-color: ${props => props.theme.colors.white};
  min-height: 13%;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.basic} ${props => props.theme.borderRadius.basic} 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;
const PhoneIconBar = styled(PhoneHeaderBar)`
  position: fixed;
  width: 100%;
  color: ${props => props.theme.colors.white};
`;
const StyledIconButton = styled(IconButton)`
  position: fixed;
  margin: 8% 0 0 85%;
  height: 42px;
  width: 42px;
  border-radius: ${props => props.theme.borderRadius.circular};
  z-index: ${props => props.theme.zIndex.header};
`;
const StyledPatientAppCloseIcon = styled(PatientAppCloseIcon)`
  color: #8c9aad;
  margin-top: -9px;
`;
const Header = styled.p`
  color: #3c4b60;
  font-family: "Museo Sans 700", sans-serif;
  ${props => props.theme.font.header5}
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1;
  letter-spacing: 0.5px;
  margin: 16px 0 6px 1%;
`;
const Subheader = styled.p`
  color: #3c4b60;
  font-family: "Museo Sans 700", sans-serif;
  ${props => props.theme.font.sub_menu.title};
  line-height: 15px;
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 0.5px;
  margin: 0;
`;
const Secondaryheader = styled.p`
  color: #7f94a9;
  font-family: "Museo Sans 700", sans-serif;
  font-size: 10px;
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 0.5px;
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacing.S_10};
  margin-left: 1%;
`;
const Body = styled.p`
  width: 80%;
  color: #3c4b60;
  font-family: "Museo Sans 700", sans-serif;
  letter-spacing: 0.15px;
  margin: 0;
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 13px;
  margin-bottom: ${props => props.theme.spacing.S_5};
`;
const ViewSection = styled.div`
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  width: 280px;
  height: 520px;
  display: flex;
  border-radius: ${props => props.theme.borderRadius.basic};
  flex-direction: column;
  overflow: scroll;
  ::-ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const TextSection = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 80%;
  margin: ${props => props.theme.spacing.S_5} 0 ${props => props.theme.spacing.S_10} 5%;
  border-radius: ${props => props.theme.borderRadius.basic};
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
const ImageSection = styled.div<ImageProps>`
  margin: 24% 0 2% 0;
  background-image: url(${props => props.image});
  min-height: 50%;
`;
export default ProfileBioPopup;
