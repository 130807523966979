import type React from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetPatientCareEventsQuery } from "api/hooks/useGetPatientCareEventsQuery";
import type { Props as MuiDropdownProps } from "shared/atoms/inputs/MUIDropdown";
import MUIDropdown from "shared/atoms/inputs/MUIDropdown";
import type { MedicalNoteType } from "types";
import { MedicalNoteTypes } from "types";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly onChange?: MuiDropdownProps["onChange"];
  readonly note_type?: MedicalNoteType;
  readonly isVGR: boolean | undefined;
}

const CommunicationTypeDropdown: React.VFC<Props> = ({
  error,
  label,
  name,
  disabled,
  required,
  onChange,
  note_type,
  isVGR,
}) => {
  const { t } = useTranslation();
  const patientId = Number(useParams<Record<string, string>>().patientId);
  const { refetch } = useGetPatientCareEventsQuery({ patientId, signed: false });
  // 3 base options with 2 contidional
  const communicationTypeOptions = [
    { label: t("patients.notes.communication_type_dropdown.options.none"), value: "none" },
    { label: t("patients.notes.communication_type_dropdown.options.video"), value: "video" },
    { label: t("patients.notes.communication_type_dropdown.options.phone"), value: "phone" },
  ];
  // for VGR note Appointment doesn't have the option "chat"
  if (!isVGR || note_type?.toLocaleUpperCase() !== MedicalNoteTypes.APPOINTMENT) {
    communicationTypeOptions.push({
      label: t("patients.notes.communication_type_dropdown.options.writing"),
      value: "writing",
    });
  }
  // in_person option is valid only for VGR
  if (isVGR) {
    communicationTypeOptions.push({
      label: t("patients.notes.communication_type_dropdown.options.in_person"),
      value: "in_person",
    });
  }

  return (
    <div>
      <CommunicationTypeLabel>{t("patients.notes.communication_type_dropdown.label")}</CommunicationTypeLabel>
      <StyledMUIDropdown
        error={error}
        label={label}
        name={name}
        options={communicationTypeOptions}
        disabled={disabled}
        required={required}
        onChange={event => {
          if (onChange) {
            onChange(event);
            refetch();
          }
        }}
      />
    </div>
  );
};

const StyledMUIDropdown = styled(MUIDropdown)`
  max-width: 355px;
`;

const CommunicationTypeLabel = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: 14px;
  color: ${props => props.theme.colors.greys.dark};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

export { CommunicationTypeDropdown };
