import type React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";

const Drawer = ({
  onClose,
  open,
  children,
  backgroundColor,
}: {
  onClose: () => void;
  open: boolean;
  children: React.ReactElement;
  backgroundColor?: string;
}) => {
  const [loadContent, setLoadContent] = useState(false);

  useEffect(() => {
    if (open) {
      setLoadContent(true);
    }
  }, [open]);
  return (
    <>
      <DrawerBg
        $open={open}
        onClick={() => {
          onClose();
        }}
      />
      <DrawerWrapper
        $open={open}
        $backgroundColor={backgroundColor}
        onTransitionEnd={() => {
          if (!open) {
            setLoadContent(false);
          }
        }}
      >
        {loadContent && <>{children}</>}
      </DrawerWrapper>
    </>
  );
};

export default Drawer;

const DrawerBg = styled.div<{ $open: boolean }>`
  pointer-events: ${props => (props.$open ? "inherit" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(111, 127, 147, 0.3);
  z-index: 1;
  transition-duration: 400ms;
  opacity: ${props => (props.$open ? 1 : 0)};
`;

const DrawerWrapper = styled.div<{ $open: boolean; $backgroundColor?: string }>`
  pointer-events: ${props => (props.$open ? "inherit" : "none")};
  opacity: ${props => (props.$open ? 1 : 0)};
  position: absolute;
  left: 0;
  right: 0;
  top: ${props => (props.$open ? "10vh" : "100vh")};
  bottom: 0;
  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    left: 0;
  }
  z-index: 2;
  transition-duration: 400ms;
  background-color: ${props => props.$backgroundColor ?? "white"};
`;
