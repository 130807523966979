import type React from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import TextArea from "shared/atoms/inputs/TextArea";
import { Notification } from "shared/atoms/Notification";

interface Props {
  customWelcomeMessageEnabled: boolean | false;
}

const WelcomeMessage: React.VFC<Props> = ({ customWelcomeMessageEnabled }) => {
  const { t } = useTranslation();
  const { profile, setProfile } = useProfileContext();
  const [isEditing, setIsEditing] = useState(false);
  const putTherapistMutation = usePutTherapistMutation();
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      welcome_message_extra: profile?.therapist_profile.welcome_message_extra || "",
    },
  });

  const handleChangeCustomWelcomeMessageClick = () => {
    putTherapistMutation.reset();
    setIsEditing(!isEditing);
  };

  const onSubmit = async ({ welcome_message_extra }: { welcome_message_extra: string }) => {
    invariant(profile);

    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        welcome_message_extra,
      },
    };

    const variables = { data, therapistId };

    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);
        reset({
          welcome_message_extra: data.therapist_profile.welcome_message_extra || "",
        });
        setIsEditing(!isEditing);
      },
    });
  };

  const GeneratedWelcomeMessage = () => {
    return (
      <>
        <StyledParagraph>{t("settings.welcome_message.paragraph_1")}</StyledParagraph>
        <StyledParagraph>{t("settings.welcome_message.paragraph_2")}</StyledParagraph>
        <StyledParagraph>{t("settings.welcome_message.paragraph_3")}</StyledParagraph>
        <StyledParagraph>{t("settings.welcome_message.paragraph_4")}</StyledParagraph>
      </>
    );
  };

  const GeneratedGreeting = () => {
    return (
      <>
        <StyledParagraph>{t("settings.welcome_message.greeting")}</StyledParagraph>
        <StyledParagraph>{`${profile?.first_name ?? ""} ${profile?.last_name ?? ""}`}</StyledParagraph>
      </>
    );
  };

  return (
    <WelcomeMessageContainer data-testid="welcome-message">
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader>{t("settings.welcome_message.heading")}</StyledHeader>
        {isEditing ? (
          <>
            <GeneratedWelcomeMessage />
            <StyledTextArea
              {...register("welcome_message_extra")}
              rows={7}
              placeholder={t("settings.welcome_message.custom_welcome_message")}
            />
            <GeneratedGreeting />
            <Wrapper>
              <TextButton onClick={handleChangeCustomWelcomeMessageClick}>{t("buttons.cancel")}</TextButton>
              <StyledPrimaryButton type="submit">{t("buttons.save")}</StyledPrimaryButton>
            </Wrapper>
          </>
        ) : (
          <>
            <GeneratedWelcomeMessage />
            <StyledParagraph data-testid="custom-welcome-message">
              {profile?.therapist_profile?.welcome_message_extra}
            </StyledParagraph>
            <GeneratedGreeting />
            {customWelcomeMessageEnabled && (
              <IconButton
                dataTestId="edit-welcome-message-button"
                label={t("buttons.change")}
                onClick={handleChangeCustomWelcomeMessageClick}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
        {putTherapistMutation.isError && (
          <Notification variant="danger" style={{ margin: "10px 0" }}>
            {t("errors.generic")}
          </Notification>
        )}
      </form>
    </WelcomeMessageContainer>
  );
};

const WelcomeMessageContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
  width: 100%;
`;

const StyledHeader = styled.h4`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const StyledParagraph = styled.p`
  white-space: pre-wrap;
  ${props => ({ ...props.theme.font.body1 })}
  color: ${props => props.theme.colors.black};
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-width: 100%;
  padding-top: ${props => props.theme.spacing.S_10};
  padding-bottom: ${props => props.theme.spacing.S_20};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

export default WelcomeMessage;
