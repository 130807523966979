import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import styled from "styled-components";

import { useGetHealthReportQuery } from "api/hooks/useGetHealthReportsQuery";
import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import type { HealthReport } from "api/models/HealthReport";
import { HealthReportSmall } from "assets";
import Hidden from "shared/atoms/Hidden";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import HealthReportsHeader from "../HealthReportsHeader";
import { answersChanged, questions, someAnswered } from "../helpers";

import { ColumnHeader, Container, Header, StateContainer, StyledColumn, Wrapper } from "./helpers";
import ReportQuestion from "./ReportQuestion";

const HealthReports: React.VFC = () => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const match = useMatch("*");
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const [selected, setSelected] = useState<HealthReport[]>([]);
  const [hidden, setHidden] = useState(false);
  const { data: healthJournal } = useGetPatientHealthJournalQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });
  const {
    data: reports = [],
    error,
    isLoading,
  } = useGetHealthReportQuery(
    { patientId: patient.id, treatment_id: previousTreatmentId },
    {
      onSettled(data) {
        if (data && data.length > 0) {
          switch (data.length) {
            case 0:
              setSelected([]);
              break;
            case 1:
              setSelected([data[0]]);
              break;
            default:
              setSelected([data[data.length - 1], data[data.length - 2]]);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.HEALTH_QUESTIONNAIRES, match.pathname);
    }
  }, [match?.pathname]);

  const lateralLocation = patient?.most_painful_location?.lateral_location;
  const ailment = healthJournal?.ailment || "joint_pain";

  const painLocation = t("patients.pain_construct", {
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lateral: lateralLocation !== "undefined" ? t(`patients.lateral_location.${lateralLocation}`) : "",
    location:
      lateralLocation === "both"
        ? // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t(`patients.pain_location.${patient.most_painful_location?.location}.1`)
        : // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t(`patients.pain_location.${patient.most_painful_location?.location}.0`),
  }).toLowerCase();
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const joint = t(`patients.pain_location.${patient.most_painful_location?.location}.0`).toLowerCase();

  const createIdList = () => {
    return selected.map(select => select.id);
  };

  const sortByNumber = (report1: HealthReport, report2: HealthReport) => {
    if (report1.health_report_number < report2.health_report_number) return 1;
    if (report1.health_report_number > report2.health_report_number) return -1;
    return 0;
  };

  const changeCallback = (selectedIds: number[]) => {
    const selectedReports = selectedIds.reduce((list, select) => {
      const report = reports.find(el => el.id === select);
      return report ? [...list, report] : list;
    }, [] as HealthReport[]);
    setSelected(selectedReports.sort(sortByNumber) || []);
  };

  const hideRowsCallback = (hide: boolean) => {
    setHidden(hide);
  };

  return (
    <>
      <HealthReportsHeader
        selected={createIdList()}
        reportList={reports}
        changeCallback={changeCallback}
        rowsCallback={hideRowsCallback}
      />
      {reports && reports.length === 0 && (
        <StateContainer>
          <StyledImage />
          {t("patients.health_reports.no_reports")}
          <Wrapper>{t("patients.health_reports.no_reports_detail")}</Wrapper>
        </StateContainer>
      )}
      {reports && reports.length !== 0 && (
        <Container data-testid="hr-content">
          <Hidden type="belowTablet">
            <Header>
              <StyledColumn>{t("patients.health_reports.questions")}</StyledColumn>
              {selected.map(report => (
                <ColumnHeader key={report.id} data-testid="column">
                  <div data-testid="column-title">
                    {t("patients.health_reports.questionnaire", { number: report.health_report_number })}
                  </div>
                  <Wrapper>{format(parseISO(report.created_at), "P")}</Wrapper>
                </ColumnHeader>
              ))}
            </Header>
          </Hidden>
          {questions(painLocation, joint, ailment).map(question => (
            <div key={question.key}>
              {someAnswered(selected, question.key, false, null) && (
                <>
                  {hidden && !answersChanged(selected, question.key, false, null, true) ? null : (
                    <ReportQuestion
                      question={question}
                      selected={selected}
                      painLocation={patient?.most_painful_location?.location}
                    />
                  )}
                </>
              )}
              {question.sub_questions &&
                question.sub_questions.map(sub => (
                  <div key={sub.key}>
                    {someAnswered(selected, sub.key, true, question.key) && (
                      <>
                        {hidden && !answersChanged(selected, sub.key, true, question.key, true) ? null : (
                          <ReportQuestion
                            question={sub}
                            parent={question}
                            selected={selected}
                            painLocation={patient?.most_painful_location?.location}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </Container>
      )}
      {isLoading && (
        <StateContainer>
          <Spinner small />
        </StateContainer>
      )}
      {error && (
        <StateContainer>
          <Notification variant="danger">{t("errors.generic")}</Notification>
        </StateContainer>
      )}
    </>
  );
};

export default HealthReports;

const StyledImage = styled(HealthReportSmall)`
  width: 160px;
  height: 160px;
`;
