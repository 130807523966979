import type { AxiosResponse } from "axios";
import axios from "axios";

import type {
  Component,
  ComponentsResponse,
  Incident,
  IncidentsResponse,
  ScheduledMaintenance,
  ScheduledMaintenancesResponse,
} from "./types";

const physioComponentId = process.env.REACT_APP_STATUSPAGE_PHYSIO_ID;

const parseComponents = (
  response: AxiosResponse<ComponentsResponse>,
  statuspageComponents: string[]
): Component[] | undefined => {
  if (response?.data?.components) {
    return response.data.components.filter(item => statuspageComponents.includes(item.id));
  }

  return undefined;
};

const getComponentStatuses = async (
  componentsUrl: string,
  componentIds: string[]
): Promise<Component[] | undefined> => {
  const componentsResponse = await axios.get<ComponentsResponse>(componentsUrl);
  const componentStatuses = parseComponents(componentsResponse, componentIds);

  return componentStatuses;
};

const parseIncidents = (
  response: AxiosResponse<IncidentsResponse>,
  statuspageComponents: string[]
): Incident[] | null => {
  const incidents = response?.data?.incidents;
  const incidentsAffectingUs: Incident[] = [];

  if (incidents) {
    incidents.map(incident => {
      const isAffectingUs = incident.components.some(item => statuspageComponents.includes(item.id));

      if (isAffectingUs) {
        incidentsAffectingUs.push(incident);
      }

      return incidentsAffectingUs;
    });

    return incidentsAffectingUs;
  }

  return null;
};

export interface IncidentData {
  components: Component[];
  title: string;
  body: string;
  shortlink: string;
}

const getIncidents = async (incidentsUrl: string, componentIds: string[]): Promise<IncidentData[] | null> => {
  try {
    const incidentResponse = await axios.get<IncidentsResponse>(incidentsUrl);
    const incidents = parseIncidents(incidentResponse, componentIds);

    if (incidents && incidents?.length > 0) {
      return incidents.map(incident => ({
        components: incident.components,
        title: incident.name,
        body: incident.incident_updates[0].body,
        shortlink: incident.shortlink,
      }));
    }

    return null;
  } catch (error) {
    return null;
  }
};

const parseMaintenances = (response: AxiosResponse<ScheduledMaintenancesResponse>): ScheduledMaintenance[] | null => {
  const scheduledMaintenancesInProgress = response.data.scheduled_maintenances.filter(
    scheduledMaintenance => scheduledMaintenance.status === "in_progress" || scheduledMaintenance.status === "scheduled"
  );
  const maintenancesAffectingUs: ScheduledMaintenance[] = [];

  if (scheduledMaintenancesInProgress.length) {
    scheduledMaintenancesInProgress.forEach(scheduledMaintenance => {
      const isAffectingUs = scheduledMaintenance.components.some(item => item.id === physioComponentId);

      if (isAffectingUs) {
        maintenancesAffectingUs.push(scheduledMaintenance);
      }
    });
  }

  if (maintenancesAffectingUs.length) {
    return maintenancesAffectingUs;
  }

  return null;
};

export { getComponentStatuses, getIncidents, parseMaintenances };
