import type { PatientProfile } from "../insuranceHelper";

const formatInsuranceCompanyName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const usePrimaryInsuranceInfo = (patient: PatientProfile): string | null => {
  const { insuranceData, providerGroupData } = patient || {};

  if (providerGroupData?.us_provider_group_clinic?.name) {
    return providerGroupData.us_provider_group_clinic.name;
  }

  if (insuranceData?.insurance_found && insuranceData?.data?.insurance_company_name) {
    const insuranceCompanyName = formatInsuranceCompanyName(insuranceData.data.insurance_company_name);
    return insuranceCompanyName;
  }

  return null;
};

export default usePrimaryInsuranceInfo;
