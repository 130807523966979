import type { MouseEventHandler } from "react";
import type React from "react";

import styled from "styled-components";

import ActivityTitle from "shared/atoms/ActivityTitle";
import PremiumIcon from "shared/atoms/PremiumIcon";
import exerciseName from "utils/exerciseName";

interface ActivityCardProps {
  onClick: MouseEventHandler;
  title: string;
  variation?: string | null;
  type?: string;
  levels?: number | null;
  currentLevel?: number | null;
  part?: string | null;
  image?: string | null;
  premium?: boolean | null;
}

const ActivityCard: React.VFC<ActivityCardProps> = ({
  onClick,
  title = "",
  variation,
  type = "lesson",
  levels = 0,
  currentLevel,
  part,
  image = "",
  premium = false,
}) => {
  const showPart = Boolean(part);

  const getSubtitle = () => {
    if (showPart && type === "TextLesson") return part;
    if (type === "Exercise" && variation) return variation;
    return undefined;
  };

  return (
    <Container data-testid="activity-card" onClick={onClick}>
      <ContentWrapper>
        <TitleWrapper>
          <ActivityTitle
            title={
              type === "Exercise" ? exerciseName(title, currentLevel != null ? `${currentLevel}` : "", levels) : title
            }
            subtitle={getSubtitle()}
          />
          {premium && <PremiumIcon premiumType="guided_care" size="big" margin="8px 0 0 0 " />}
        </TitleWrapper>
        {image && <img src={image} alt={title} />}
      </ContentWrapper>
    </Container>
  );
};

export default ActivityCard;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 300px;
  margin: 12px;
  padding: 16px;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: ${props => props.theme.colors.greys.silver};
  background: ${props => props.theme.colors.white};
  transition: transform 300ms ease, box-shadow 300ms ease, background 200ms;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.greys.light3};
  }

  ${props => props.theme.belowMobileBreakpoint} {
    width: 100%;
    margin: 4px 12px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & img {
    height: 100px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
`;
