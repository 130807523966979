import { useMutation } from "react-query";

import type {
  IntroductionHealthReportResponse,
  PatientOnboardingResponse,
  PatientResponse,
  PostIntroductionHealthReportArgs,
  PostPatientArgs,
  PostPatientOnboardingCompleteArgs,
  PutPatientOnboardingArgs,
} from "api/schemas/ManagerBooking";
import useApiClient from "api/useApiClient";

export const usePostPatient = () => {
  const client = useApiClient();
  return useMutation<PatientResponse, Error, PostPatientArgs>(args => client.postPatient(args));
};

export const usePostPatientIntroductionHealthReport = () => {
  const client = useApiClient();
  return useMutation<IntroductionHealthReportResponse, Error, PostIntroductionHealthReportArgs>(args =>
    client.postPatientIntroductionHealthReport(args)
  );
};

export const usePutPatientOnboarding = () => {
  const client = useApiClient();
  return useMutation<PatientOnboardingResponse, Error, PutPatientOnboardingArgs>(args =>
    client.putPatientOnboarding(args)
  );
};

export const usePostPatientOnboardingComplete = () => {
  const client = useApiClient();
  return useMutation<null, Error, PostPatientOnboardingCompleteArgs>(args =>
    client.postPatientOnboardingComplete(args)
  );
};
