import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";
import type { Goal } from "api/models/Goal";

import type { FormData } from "../PatientGoal";

const setGoal = async (id: number, body: FormData): Promise<AxiosResponse<Goal>> =>
  AxiosInstance.post(`patients/${id}/goals`, {
    ...body,
  });

const updateGoal = async (id: number, body: FormData, goalId: number): Promise<AxiosResponse<Goal>> =>
  AxiosInstance.put(`patients/${id}/goals/${goalId}`, {
    ...body,
  });

const deleteGoal = async (id: number, goalId: number): Promise<AxiosResponse<void>> =>
  AxiosInstance.delete(`patients/${id}/goals/${goalId}`);

export { setGoal, updateGoal, deleteGoal };
