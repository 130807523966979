import type { ComponentProps } from "react";
import React, { Suspense } from "react";

import type PDFDownloadButton from "./PDFDownloadButton";

const LazyLoadedComponent = React.lazy(() => import("./PDFDownloadButton"));

type Props = ComponentProps<typeof PDFDownloadButton>;

export const LazyLoadedPDFDownloadButton: React.VFC<Props> = props => {
  // TODO do we need any feedback on the button while loading? i.e. Suspense.fallback
  return (
    <Suspense fallback={<></>}>
      <LazyLoadedComponent {...props} />
    </Suspense>
  );
};
