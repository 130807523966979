import type React from "react";
import { createRef, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import type { PremiumType } from "api/models/PatientProfile";

import BroadcastConversation from "../Broadcast/BroadcastConversation/BroadcastConversation";
import ConversationItem from "../ConversationItem";

interface Props {
  conversations: Array<{
    id: number;
    avatar?: string;
    name: string;
    last: string | null;
    date: string;
    read: boolean;
    direction: string;
    therapist_assignment_role: string;
    premium_type: PremiumType;
  }>;
  updateConversations: (index: number) => void;
  selectedId?: number;
}

const ConversationList: React.VFC<Props> = ({ conversations, updateConversations, selectedId }) => {
  const { t } = useTranslation();
  const selectedLinkRef = createRef<HTMLAnchorElement>();

  useEffect(() => {
    if (selectedLinkRef.current) {
      selectedLinkRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedLinkRef.current]);

  return (
    <StyledUl data-testid="messages-conversation-list">
      <ScrollWrapper>
        {conversations.length === 0 && (
          <CenterWrapper>{t("messages.conversation_list.no_conversations")}</CenterWrapper>
        )}
        <BroadcastConversation />
        {conversations?.map((conversation, index) => {
          const isSelected = selectedId === conversation.id;

          return (
            <Link
              key={conversation.id}
              onClick={() => updateConversations(index)}
              ref={isSelected ? selectedLinkRef : undefined}
              to={`/messages/${conversation.id}`}
            >
              <ConversationItem
                key={conversation.id}
                avatar={conversation.avatar}
                name={conversation.name}
                lastMessage={conversation.last}
                date={conversation.date}
                selected={isSelected}
                read={conversation.read}
                direction={conversation.direction}
                id={conversation.id}
                therapist_assignment_role={conversation.therapist_assignment_role}
                premiumType={conversation.premium_type}
              />
            </Link>
          );
        })}
      </ScrollWrapper>
    </StyledUl>
  );
};

export default ConversationList;

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  flex-shrink: 0;
  height: calc(100% - 52px);
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  height: 100%;

  ${props => props.theme.aboveBreakpoint} {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const CenterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.font.body2};
  color: ${props => props.theme.colors.greys.warm};
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;
