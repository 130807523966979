import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { CareEvent } from "api/models/CareEvent";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getCareEvent";
type QueryKey = [typeof queryKeyPrefix, number];
export const getCareEventQueryKey = (careEventID: number): QueryKey => [queryKeyPrefix, careEventID];

const useGetCareEvent = (careEventID: number, options?: UseQueryOptions<CareEvent, Error, CareEvent, QueryKey>) => {
  const client = useApiClient();

  return useQuery(getCareEventQueryKey(careEventID), () => client.getCareEvent(careEventID), options);
};

export default useGetCareEvent;
