import { useMutation } from "react-query";

import useApiClient from "api/useApiClient";

export type DeleteServiceArgs = {
  managerId: number;
  serviceId: number;
};

export const useDeleteService = () => {
  const client = useApiClient();

  return useMutation<null, Error, DeleteServiceArgs>({
    mutationFn: args => client.deleteService(args),
  });
};
