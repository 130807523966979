import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";
import Popup from "shared/atoms/Popup";
import Body1 from "shared/atoms/Typography/Body1";

interface Props {
  readonly isOpen?: boolean;
  content?: string;
}

const ICDWarningPopup: React.VFC<Props> = ({ content }) => {
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation();
  return isOpen ? (
    <Popup fullBgOpacity onClickOutside={() => undefined}>
      <Body>
        <Content>{content}</Content>
        <PrimaryButton onClick={() => setOpen(false)}>{t("buttons.ok")}</PrimaryButton>
      </Body>
    </Popup>
  ) : null;
};

export default ICDWarningPopup;

const Body = styled(Body1)`
  padding: 20px;
  max-width: 320px;
  text-align: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  margin-bottom: 20px;
`;
