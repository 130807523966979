import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { useTheme } from "styled-components";

interface Props {
  header: string;
  onClose: () => void;
}

export const Header: React.VFC<Props> = ({ header, onClose }) => {
  const theme = useTheme();

  return (
    <StyledHeader>
      {header}
      <CloseButton onClick={() => onClose()}>
        <FontAwesomeIcon icon={faClose} color={theme.colors.redesign.db90} style={{ height: "24px", width: "24px" }} />
      </CloseButton>
    </StyledHeader>
  );
};

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px 24px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.redesign.db90};
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;
