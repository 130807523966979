import type React from "react";

import styled from "styled-components";

type ContainerProps = Pick<ActivityLevelsProps, "invert" | "paddingLeft" | "size">;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  ${props => (props.size === "small" ? props.theme.font.header5 : props.theme.font.header3)}
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => (props.invert ? props.theme.colors.white : props.theme.colors.primary.base)};
  padding-left: ${props => props?.paddingLeft};
`;

interface MaxProps extends Pick<ActivityLevelsProps, "invert"> {
  readonly reached: boolean;
}

const Max = styled.span<MaxProps>`
  color: ${props => (props.invert ? props.theme.colors.white : !props.reached && props.theme.colors.greys.newGrey)};
  font-weight: ${props => (props.reached ? props.theme.fontWeight.bold : props.theme.fontWeight.medium)};
  ${props => props.invert && !props.reached && `color: ${props.theme.colors.greys.lightestGrey};`}
`;

interface ActivityLevelsProps {
  readonly levels: number;
  readonly currentLevel: number;
  readonly size?: "small" | "large";
  readonly paddingLeft?: string | null;
  readonly invert?: boolean;
}

const ActivityLevels: React.VFC<ActivityLevelsProps> = ({
  levels,
  currentLevel,
  size = "large",
  invert = false,
  paddingLeft = null,
}) => {
  return (
    <Container data-testid="activity-levels" size={size} invert={invert} paddingLeft={paddingLeft}>
      <span data-testid="current-level">{currentLevel}</span>
      <Max reached={levels === currentLevel} invert={invert}>{`/${levels}`}</Max>
    </Container>
  );
};

export default ActivityLevels;
