import type React from "react";

import { useFormContext } from "react-hook-form";

import MUICheckBox from "shared/atoms/inputs/MUICheckBox";

interface Props {
  readonly error?: string;
  readonly label: string;
  readonly controller: string;
  readonly responder: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly onChange?: (value: boolean) => void;
}

/**
 * Checkbox where a controller, visible checkbox also sets the state of a hidden responder
 */
const ControllerResponderCheckbox: React.VFC<Props> = ({
  error,
  label,
  controller,
  responder,
  disabled,
  required,
  onChange,
}) => {
  const { setValue } = useFormContext();
  const onCheck = (checked: boolean) => {
    setValue(responder, checked);
    if (onChange) onChange(checked);
  };

  return (
    <MUICheckBox
      error={error}
      label={label}
      name={controller}
      disabled={disabled}
      required={required}
      onChange={onCheck}
    />
  );
};

export { ControllerResponderCheckbox };
