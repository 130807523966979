import type React from "react";

import styled from "styled-components";

import { getInitials } from "routes/videocall/components/DisabledPublicationsOverlay";

interface Props {
  className?: string;
  fullName: string;
}

export const Initials: React.VFC<Props> = ({ className, fullName }) => {
  return <Container className={className}>{getInitials(fullName)}</Container>;
};

const Container = styled.div`
  padding: 1px 2px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.redesign.db90};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db90};
  border-radius: 4px;
`;
