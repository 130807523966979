import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useQueryClient } from "react-query";
import invariant from "ts-invariant";

import { getPatientCareEventsQueryKey, useGetPatientCareEventsQuery } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import { useGetSuggestedCareEventsQuery } from "api/hooks/useGetSuggestedCareEvents";
import type { CareEventSuggestionType } from "api/models/SuggestedCareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import { newNoteSafe } from "routes/patients/utils/notesHelper";
import { addDismissedSuggestionListItem, isSuggestionDismissed } from "routes/patients/utils/suggestionsHelper";
import { CurrentPatientContext } from "utils/contexts";

import SuggestedCareEventCallBox from "./SuggestedCareEventCallBox";

interface Props {
  patientId: number;
}

const SuggestedCareEvent: React.VFC<Props> = ({ patientId }: Props) => {
  const { profile } = useProfileContext();
  const { setActiveCareEventNote } = useContext(CurrentPatientContext);
  const { isLoading: isCareEventsLoading } = useGetPatientCareEventsQuery({ patientId, signed: false });
  const queryClient = useQueryClient();

  const [suggestions, setSuggestions] = useState<Array<CareEventSuggestionType>>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  invariant(profile);

  const { data, isLoading, error, refetch } = useGetSuggestedCareEventsQuery({ therapistId: profile.id, patientId });

  const setNewDisableState = (newState: boolean) => {
    if (disabled !== newState) {
      setDisabled(newState);
    }
  };

  const cleanDismissedSuggestions = (dataToClean: CareEventSuggestionType[]) => {
    return dataToClean.reduce((prevData: CareEventSuggestionType[], suggestion: CareEventSuggestionType) => {
      if (!isSuggestionDismissed(patientId, suggestion.label, profile.id)) {
        prevData.push(suggestion);
      }
      return prevData;
    }, []);
  };

  useEffect(() => {
    if (data && !isLoading && !error) {
      setSuggestions(cleanDismissedSuggestions(data));
      if (!isCareEventsLoading) {
        setNewDisableState(false);
      }
    }
  }, [data, isLoading, error, isCareEventsLoading]);

  useEffect(() => {
    if (isCareEventsLoading) {
      setNewDisableState(true);
      if (!isLoading) {
        refetch();
      }
    }
  }, [isCareEventsLoading, isLoading]);

  const onCreate = async (pId: number, noteType: string, tId: number): Promise<void> => {
    setNewDisableState(true);
    const newNote = await newNoteSafe(pId, noteType, tId);
    if (newNote && newNote.data !== null) {
      const newNoteData = newNote.data as Record<string, unknown>;
      queryClient.invalidateQueries(getPatientCareEventsQueryKey({ patientId: pId, signed: false }));
      queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
      setActiveCareEventNote(newNoteData.id as number);
    }
  };

  const onDismiss = (pId: number, noteType: string, tId: number): void => {
    addDismissedSuggestionListItem(pId, noteType, tId);
    setSuggestions(cleanDismissedSuggestions(suggestions));
  };

  return suggestions.length ? (
    <SuggestedCareEventCallBox
      therapistId={profile.id}
      patientId={patientId}
      suggestion={suggestions[0]}
      onDismiss={onDismiss}
      onCreate={onCreate}
      disabled={disabled}
    />
  ) : null;
};

export default SuggestedCareEvent;
