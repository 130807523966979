/* eslint-disable @typescript-eslint/no-empty-function */
import type React from "react";

import styled from "styled-components";

import { SortingArrowActive, SortingArrows } from "assets";

const TableRowSort = styled.tr`
  height: 40px;
  background: ${props => props.theme.colors.greys.light4};
`;

const TableRowCell = styled.th<{ $dragOver: boolean; $dragDirection: string }>`
  border: none;
  padding: 0 ${props => props.theme.spacing.S_20};

  ${props => props.theme.font.table.sort};
  text-align: left;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  ${props => props.theme.belowBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_15};
  }

  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};

  ${props =>
    props.$dragOver &&
    props.$dragDirection === "left" &&
    `border-left: 2px solid ${props.theme.colors.table_border_highlight};`}
  ${props =>
    props.$dragOver &&
    props.$dragDirection === "right" &&
    `border-right: 2px solid ${props.theme.colors.table_border_highlight};`}
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Label = styled.span`
  margin-right: ${props => props.theme.spacing.S_10};
`;

const PaginationLabel = styled(Label)`
  margin-left: auto;
`;

const AnimatedArrow = styled(SortingArrowActive)<{ $descending: boolean }>`
  transition: transform 300ms;
  transform: ${props => (props?.$descending ? "rotate(0deg)" : "rotate(180deg)")};
`;

const sortIcon = (
  sortBy: string,
  descending: boolean,
  currentColumn: string,
  sortable: boolean
): JSX.Element | null => {
  if (sortBy === currentColumn) {
    return (
      <AnimatedArrow
        title={descending ? "sort-descending-icon" : "sort-ascending-icon"}
        data-testid={descending ? "sort-descending-icon" : "sort-ascending-icon"}
        $descending={descending}
      />
    );
  }
  if (sortable) {
    return <SortingArrows title="sort-icon" />;
  }
  return null;
};

interface Props {
  columns: Array<string>;
  sortBy: string;
  descending: boolean;
  onSort: (column: string) => void;
  sortable?: boolean;
  paginationLabel?: string;
  translateColumn?: (column: string) => string;
  onDragStart?: (e: React.DragEvent<HTMLTableCellElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLTableCellElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLTableCellElement>) => void;
  onDragEnter?: (e: React.DragEvent<HTMLTableCellElement>) => void;
  dragOver?: string;
  dragDirection?: string;
  className?: string;
}

const TableSort: React.VFC<Props> = ({
  columns,
  sortBy,
  descending,
  onSort = () => {},
  sortable = true,
  paginationLabel = null,
  translateColumn,
  onDragStart = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  onDragEnter = () => {},
  dragOver = "",
  dragDirection = "",
  className,
}) => {
  return (
    <TableRowSort data-testid="table-sort" className={className}>
      {columns.map((column, index) => (
        <TableRowCell
          data-testid={`table-sort-column-${column}`}
          role="button"
          scope="col"
          key={column}
          onClick={() => onSort(column)}
          id={column}
          draggable
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          $dragOver={column === dragOver}
          $dragDirection={dragDirection}
        >
          <Row>
            <Label>{translateColumn ? translateColumn(column) : column}</Label>
            {sortIcon(sortBy, descending, column, sortable)}
            {index === columns.length - 1 && paginationLabel && (
              <PaginationLabel onClick={e => e.stopPropagation()}>{paginationLabel}</PaginationLabel>
            )}
          </Row>
        </TableRowCell>
      ))}
    </TableRowSort>
  );
};

export default TableSort;
