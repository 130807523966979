import { useMutation } from "react-query";

import type {
  SwitchTreatmentPrograms,
  SwitchTreatmentProgramsArgs,
} from "api/schemas/patients/SwitchTreatmentPrograms";
import useApiClient from "api/useApiClient";

export const usePostPatientSwitchTreatmentPrograms = () => {
  const client = useApiClient();
  return useMutation<SwitchTreatmentPrograms, Error, SwitchTreatmentProgramsArgs>(args =>
    client.postPatientSwitchTreatmentPrograms(args)
  );
};
