import type React from "react";

import { format, startOfMonth } from "date-fns";
import invariant from "ts-invariant";

import useGetEarningsUsTherapist from "api/hooks/useGetEarningsUsTherapist";
import { useProfileContext } from "contexts/ProfileContext";

import { EnrolledPatients } from "./EnrolledPatients";
import { TotalEarnings } from "./TotalEarnings";

export const EarningsWidget: React.VFC = () => {
  const { profile } = useProfileContext();

  invariant(profile, "Profile should be defined");

  const { data } = useGetEarningsUsTherapist({
    therapist_id: profile.id,
    start_date: format(startOfMonth(new Date()), "yyyy-MM-dd"),
  });

  return (
    <div>
      <EnrolledPatients enrollments={data?.enrollments} />
      <TotalEarnings />
    </div>
  );
};
