import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";

export const LoginWrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  min-height: 520px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;

  ${props => props.theme.belowBreakpoint} {
    min-height: auto;
    flex: 1;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 0;
`;

export const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 76px;

  ${props => props.theme.belowBreakpoint} {
    justify-content: flex-start;
    padding: 32px 16px;
  }
`;

export const Instructions = styled.p<{ $center?: boolean }>`
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  white-space: pre-wrap;
  margin-bottom: 16px;
`;

export const Status = styled.p``;

export const SubmitButton = styled(PrimaryButton)`
  box-sizing: border-box;
  padding: 0 40px;
  margin-top: ${props => props.theme.spacing.S_30};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:disabled {
    background-color: ${props => props.theme.colors.redesign.b70};
    cursor: default;
  }

  ${props => props.theme.belowBreakpoint} {
    width: 100%;
    align-self: flex-end;
  }
`;
