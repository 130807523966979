import type { DataRegion } from "types";
import { DataRegionTypes, isTypeDataRegion, isTypeDataRegionMap } from "types";

export const getAppRawDataRegion = (): string | undefined => process.env.REACT_APP_MARKET;

export const isDataRegionPresent = (): boolean => !!getAppRawDataRegion();

export const getNormalizedDataRegion = (region: unknown): DataRegion | undefined => {
  if (!region) {
    return undefined;
  }
  if (isTypeDataRegion(region)) {
    return region;
  }
  if (isTypeDataRegionMap(region)) {
    return DataRegionTypes[region];
  }
  return undefined;
};

export const isDataRegion = (region: DataRegion | DataRegion[]): boolean => {
  const currentRegion = getAppDataRegion();
  if (!currentRegion) {
    return false;
  }
  let checkRegion = region;
  if (!Array.isArray(checkRegion)) {
    checkRegion = [region] as DataRegion[];
  }
  return checkRegion.includes(currentRegion);
};

export const getAppDataRegion = (): DataRegion | undefined => getNormalizedDataRegion(getAppRawDataRegion());
