import type React from "react";
import { useEffect } from "react";

import { Route, Routes, useMatch } from "react-router-dom";
import styled from "styled-components";

import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import Messages from "./views/Messages";

const StyledContainer = styled(Container)`
  overflow-x: hidden;
`;

const MessagesRoutes: React.VFC = () => {
  const match = useMatch("*");
  const offsetTop = usePageContentOffsetTop();

  useEffect(() => {
    if (match?.pathname) {
      AnalyticsService.viewedPage(AnalyticsPages.MESSAGES, match?.pathname);
    }
  }, [match?.pathname]);

  return (
    <Routes>
      <Route
        path=":patientId"
        element={
          <StyledContainer $pageContentOffsetTop={offsetTop}>
            <Messages />
          </StyledContainer>
        }
      />
      <Route
        path="/"
        element={
          <StyledContainer $pageContentOffsetTop={offsetTop}>
            <Messages />
          </StyledContainer>
        }
      />
    </Routes>
  );
};

export default MessagesRoutes;
