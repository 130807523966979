import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { MedicalNoteFieldConfig } from "types";
import { getSubsectionTitleKey } from "utils/medicalNotes";

import { SystemSuggestionsField } from ".";

interface Props {
  readonly subsection: Subsection;
}

interface Subsection {
  readonly name: string;
  fields: MedicalNoteFieldConfig[];
}

const SystemSuggestionsSubsection: React.VFC<Props> = ({ subsection }) => {
  const { t } = useTranslation();
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const header = t(getSubsectionTitleKey(subsection.name));

  return (
    <Subsection>
      <Header data-testid={`${subsection.name}-subsection`}>{header}</Header>
      {subsection.fields.map(field => (
        <SystemSuggestionsField field={field} key={field.name} />
      ))}
    </Subsection>
  );
};

const Subsection = styled.div`
  flex: 1 0 50%;
  background-color: ${({ theme }) => theme.colors.system_suggestions_section.background};
  border-left: 1px solid ${({ theme }) => theme.colors.system_suggestions_section.divider};
  margin-left: -1px;

  ${props => props.theme.belowBreakpoint} {
    flex: 1 0 100%;
    border-left: none;
  }
`;

const Header = styled.div`
  background-color: ${props => props.theme.colors.system_suggestions_section.header_background};
  ${({ theme }) => theme.font.system_suggestions_section.header};
  padding: 8px 12px;
`;

export { SystemSuggestionsSubsection };
