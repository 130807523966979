import styled, { css } from "styled-components";

const buttonStyles = css`
  ${props => props.theme.font.button_text};
  font-family: inherit;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_40};
  min-width: 176px;
  height: 50px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(68, 104, 147, 0.15);
  border: none;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  user-select: none;
  &:disabled {
    background-color: ${props => props.theme.colors.redesign.db20};
    color: ${props => props.theme.colors.greys.gunmetal};
    cursor: default;
    box-shadow: none;
    & path {
      fill: ${props => props.theme.colors.greys.gunmetal};
    }
  }
  & svg {
    width: 12px;
    height: 12px;
  }
`;

const PrimaryButton = styled.button`
  ${buttonStyles}
  background-color: ${props => props.theme.colors.redesign.b100};
  color: ${props => props.theme.colors.white};
  & path {
    fill: ${props => props.theme.colors.white};
  }
  &:active:enabled {
    box-shadow: none;
    background-color: ${props => props.theme.colors.redesign.b70};
  }
`;

const SecondaryButton = styled.button`
  ${buttonStyles}
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.redesign.b100};
  & path {
    fill: ${props => props.theme.colors.redesign.b100};
  }
  &:active:enabled {
    box-shadow: none;
    background-color: ${props => props.theme.colors.redesign.b70};
    color: ${props => props.theme.colors.white};
    & path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const TextButton = styled.button`
  ${props => props.theme.font.button_text};
  font-family: inherit;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_40};
  min-width: 176px;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${props => props.theme.colors.greys.gunmetal};
  background-color: transparent;
  svg {
    margin-right: ${props => props.theme.spacing.S_5};
  }
  &:disabled {
    cursor: default;
  }
`;

export { PrimaryButton, SecondaryButton, TextButton, buttonStyles };
