import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { PutUserCourseArgs } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const PUT_USER_COURSE_QUERY_KEY = "useGetUserCourseQuery";
type QueryKey = [typeof PUT_USER_COURSE_QUERY_KEY, number];

export const getPutUserCourseQueryKey = (args: { patientId: number }): QueryKey => [
  PUT_USER_COURSE_QUERY_KEY,
  args.patientId,
];

export const usePutUserCourseQuery = (
  args: { patientId: number },
  options?: UseMutationOptions<null, Error, PutUserCourseArgs, QueryKey>
): UseMutationResult<null, Error, PutUserCourseArgs> => {
  const client = useApiClient();

  return useMutation(
    getPutUserCourseQueryKey(args),
    ({ patientId, course }) => {
      return client.putUserCourse(patientId, course);
    },
    options
  );
};
