import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { BackIcon } from "assets";

export const Container = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: ${props => props.theme.colors.white};

  padding: 0px ${props => props.theme.spacing.S_20};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  display: flex;
  order: 0;
  flex-shrink: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  flex-basis: 50px;
  min-height: 50px;
  position: relative;
`;

export const StyledBackIcon = styled(BackIcon)`
  cursor: pointer;
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  ${props => props.theme.font.header4}
  ${props => props.theme.belowBreakpoint} {
    justify-content: space-between;
  }
`;

export const HeaderGroupRight = styled(HeaderGroup)`
  justify-content: flex-end;
  ${props => props.theme.belowBreakpoint} {
    justify-content: flex-end;
  }
`;

export const Notes = styled(HeaderGroupRight)`
  position: relative;
  text-decoration: none;
`;

export const NoteExtra = styled.div`
  position: absolute;
  top: 20px;
  right: 110px;
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.belowBreakpoint} {
    right: 0px;
    top: 25px;
  }
`;

export const Link = styled(NavLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  ${props => props.theme.font.header4}
  color: ${props => props.theme.colors.primary.base};
`;
