import type { ReactElement } from "react";
import type React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { iconButtonStyles, iconWrapperStyles } from "shared/styles";

const IconLink = styled(Link)<{ margin?: string }>`
  ${iconButtonStyles}
  min-width: 30px;
  box-sizing: border-box;
  padding: 3px;
`;

const IconWrapper = styled.div`
  ${iconWrapperStyles}
`;

interface Props {
  children: ReactElement;
  to: {
    pathname: string;
    state?: {
      pathname: string;
      text: string;
    };
  };
  m?: string;
  dataTestId?: string;
  title?: string;
}

const ActionLink: React.VFC<Props> = ({ children, to, m, dataTestId }) => (
  <IconLink to={to} margin={m} data-testid={dataTestId}>
    <IconWrapper>{children}</IconWrapper>
  </IconLink>
);

export default ActionLink;
