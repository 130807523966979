import { useEffect, useRef, useState } from "react";
import type React from "react";

import type { AxiosResponse } from "axios";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { AxiosInstance } from "api";
import useGetSmartMessageReplies from "api/hooks/useGetSmartMessageReplies";
import { DropdownIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { getChatMessages } from "routes/messages/queries/getChatMessages";
import type { Params } from "routes/messages/queries/getChatMessages";
import markAsReadRequest from "routes/messages/queries/markAsRead";
import useSendMessageCall from "routes/messages/queries/useSendMessage";
import { SecondaryButton } from "shared/atoms/Button";
import Spinner from "shared/atoms/Spinner";
import { QUERY_KEYS } from "types/QueryKeys";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { getPatientFullDetailsQueryKey } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";
import useUnreadMessagesCount from "utils/contexts/queries/useUnreadMessagesCount";
import { reportError } from "utils/errorReporting";
import getPaginationHeaders from "utils/pagination/getPaginationHeaders";
import type { ResponseHeaders } from "utils/pagination/getPaginationHeaders";

import type { Message } from "../../types";
import { LeifGPT } from "../LeifGPT";
import MessageInput from "../MessageInput";

import renderMessages from "./renderHelpers";

interface Props {
  selectedPatient: number;
}

const MESSAGES_AMOUNT = 15;

const initialState = {
  error: false,
  nextUrl: "",
  messages: [],
  hasMore: true,
  finishedInitialRequest: false,
  scrollDirection: "up",
};

interface State {
  messages: Message[];
  nextUrl: string;
  hasMore: boolean;
  finishedInitialRequest: boolean;
  error: boolean;
  scrollDirection: string;
}

const ChatWindow: React.VFC<Props> = ({ selectedPatient }) => {
  const location = useLocation();
  const values = queryString.parse(location.search) as { meeting_purpose: string; purpose: string };
  const { profile } = useProfileContext();
  const { unreadMessagesCount } = useUnreadMessagesCount();
  const { t } = useTranslation();

  invariant(profile);

  const profileId = profile.id;
  const conversationDirectionRef = useRef<HTMLDivElement>(null);
  const infinityScrollRef = useRef<InfiniteScroll>(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const queryClient = useQueryClient();

  const [{ error, messages, nextUrl, hasMore, finishedInitialRequest, scrollDirection }, setState] =
    useState<State>(initialState);

  const setError = () => setState(prev => ({ ...prev, error: true }));

  // LeifGPT - Smart message replies
  const isSmartRepliesEnabled = profile?.therapist_profile?.feature_flags?.includes("LEIF_GPT_MESSAGES");
  const { data: smartReplies } = useGetSmartMessageReplies(
    { therapistId: profile?.id, patientId: selectedPatient },
    { enabled: isSmartRepliesEnabled }
  );
  const smartReply = smartReplies?.message?.body;
  const [smartMessageReply, setSmartMessageReply] = useState("");
  const { mutateAsync: sendMessage } = useSendMessageCall();

  const setMessages = (response: AxiosResponse<Message[]>) => {
    const { data, headers } = response;
    const { next, totalCount } = getPaginationHeaders(headers as ResponseHeaders);

    setState(prev => {
      const newMessages = data.reduce((result, newMessage) => {
        if (!prev.messages.find(oldMessage => oldMessage.id === newMessage.id)) {
          return [...result, newMessage];
        }
        return result;
      }, prev.messages);

      return {
        ...prev,
        messages: newMessages,
        nextUrl: next || prev.nextUrl,
        hasMore: totalCount ? newMessages.length < totalCount : false,
        finishedInitialRequest: true,
      };
    });
  };

  const markAsRead = async (conversation: Message[]) => {
    const latestUnreadMessage = conversation.filter(
      message => message.from.id === selectedPatient && !message.read_at
    )?.[0];

    if (latestUnreadMessage?.id) {
      await markAsReadRequest(latestUnreadMessage.id).then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.conversations);
        queryClient.invalidateQueries(QUERY_KEYS.unreadMessagesCount);
      });
    }
  };

  const scrollIntoView = (force = false) => {
    const scrollElement = infinityScrollRef?.current?.getScrollableTarget();
    if ((scrollElement && scrollElement.scrollTop === 0) || force) {
      conversationDirectionRef?.current?.scrollIntoView();
    }
  };

  type FetchMessagesParams = {
    params?: Params;
    signal?: AbortSignal;
  };

  const fetchMessages = async ({ params, signal }: FetchMessagesParams) => {
    try {
      const response = await getChatMessages(profileId, selectedPatient, { ...params, limit: MESSAGES_AMOUNT }, signal);
      if (response) {
        setMessages(response);
        markAsRead(response.data);
      }
    } catch (e) {
      setState(prev => ({ ...prev, finishedInitialRequest: true }));
      setError();
      // TODO: add error logging
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    setIsButtonVisible(false);
    setState(initialState);
    fetchMessages({ signal: abortController.signal });
    scrollIntoView(true);
    markAsRead(messages);
    setSmartMessageReply("");

    return () => {
      abortController.abort();
    };
  }, [selectedPatient]);

  const fetchEarlier = async () => {
    try {
      const response = await AxiosInstance.get(nextUrl);
      setIsButtonVisible(true);
      setMessages(response);
    } catch (e) {
      setError();
      reportError("Caught an error while fetching earlier messages", e as Error);
    }
  };

  const fetchAfter = async () => {
    try {
      const response = await AxiosInstance.get(nextUrl);
      setMessages(response);
    } catch (e) {
      setError();
      reportError("Caught an error while fetching later messages", e as Error);
    }
  };

  const fetchNewAfterSend = async () => {
    if (!messages.length) return;

    if (scrollDirection === "down") {
      goToEndOfConversation();
    } else {
      fetchMessages({
        params: {
          after: messages[1] ? messages[1].id : messages[0].id,
        },
      });
    }
    scrollIntoView(true);

    if ((values.meeting_purpose || values.purpose) && profile.market) {
      const queryKey = getPatientFullDetailsQueryKey({
        patientId: selectedPatient,
        therapistId: profile.id,
        therapistMarket: profile.market,
      });
      queryClient.invalidateQueries(queryKey);
    }
  };

  const goToBeginningOfConversation = async () => {
    setState(prev => ({
      ...prev,
      finishedInitialRequest: false,
      nextUrl: "",
      messages: [],
      scrollDirection: "down",
      error: false,
      totalCount: 0,
    }));

    fetchMessages({
      params: {
        after: 1,
      },
    });
    scrollIntoView();
    setIsButtonVisible(true);
  };

  const goToEndOfConversation = () => {
    fetchMessages({});
    scrollIntoView(true);
    setIsButtonVisible(false);
    setState(initialState);
  };

  const loader = (
    <CenterWrapper style={{ flexGrow: 1, paddingTop: scrollDirection === "up" ? 10 : 0 }}>
      <Spinner dataTestId="loader" small />
    </CenterWrapper>
  );

  const errorComponent = <CenterWrapper>{t("errors.generic")}</CenterWrapper>;

  const renderLoaderOrError = () => {
    if (!finishedInitialRequest) return loader;
    if (error) return errorComponent;
    return false;
  };

  useEffect(() => {
    if (scrollDirection !== "down" && unreadMessagesCount > 0) {
      fetchMessages({});
      scrollIntoView();
    }
  }, [unreadMessagesCount]);

  const lastReadByPatientArray: Message[] = messages.filter(
    message =>
      message.read_at && message.from.id === profileId && (message.type === "text" || message.type === "activity")
  );

  const showReadLabelFromTop = hasMore === false ? lastReadByPatientArray?.length - 1 : 0;

  const lastReadByPatientId = lastReadByPatientArray[scrollDirection === "up" ? 0 : showReadLabelFromTop]?.id;

  return (
    <>
      <Container
        data-testid="chat-window"
        id="chat-window"
        style={{ flexDirection: scrollDirection === "up" ? "column-reverse" : "column" }}
      >
        {renderLoaderOrError() || (
          <>
            {messages.length === 0 && finishedInitialRequest && (
              <CenterWrapper>{t("messages.no_messages")}</CenterWrapper>
            )}

            <div ref={conversationDirectionRef}>
              {messages.length > 0 && scrollDirection === "down" && (
                <Title data-testid="beginning-of-conversation">{t("messages.chat_window.beginning")}</Title>
              )}
            </div>

            <InfiniteScroll
              dataLength={messages.length}
              ref={infinityScrollRef}
              next={scrollDirection === "up" ? fetchEarlier : fetchAfter}
              style={{
                display: "flex",
                flexDirection: scrollDirection === "up" ? "column-reverse" : "column",
              }}
              inverse={scrollDirection === "up"}
              hasMore={hasMore}
              loader={loader}
              scrollableTarget="chat-window"
            >
              {renderMessages(
                messages.sort((a, b) => (scrollDirection === "up" ? b.id - a.id : a.id - b.id)),
                profileId,
                selectedPatient,
                lastReadByPatientId
              )}
            </InfiniteScroll>

            {smartReply && (
              <StyledLeifGPT
                patientId={selectedPatient}
                smartReply={smartReply}
                onEdit={value => {
                  setSmartMessageReply(value);
                }}
                onSubmit={value => {
                  sendMessage({
                    to: selectedPatient,
                    body: value,
                  })
                    .then(() => {
                      AnalyticsService.track(AnalyticsEvents.MESSAGES.SEND_MESSAGE, {
                        patient: selectedPatient,
                      });
                      fetchNewAfterSend();
                      setSmartMessageReply("");
                      queryClient.invalidateQueries(QUERY_KEYS.conversations);
                    })
                    .catch(e => {
                      reportError("Caught an error while sending a smart message reply", e as Error);
                    });
                }}
                dismissEvent={AnalyticsEvents.LEIF_GPT.MESSAGES.SUGGESTION_DISMISSED}
                editEvent={AnalyticsEvents.LEIF_GPT.MESSAGES.SUGGESTION_EDITED}
                presentEvent={AnalyticsEvents.LEIF_GPT.MESSAGES.SUGGESTION_PRESENTED}
                submitEvent={AnalyticsEvents.LEIF_GPT.MESSAGES.SUGGESTION_SUBMITTED}
                $isScrollDirectionDown={scrollDirection === "down"}
              />
            )}

            {hasMore === false && scrollDirection === "up" && <Title>{t("messages.chat_window.beginning")}</Title>}
          </>
        )}
      </Container>
      <BottomContainer>
        <ButtonWrapper>
          <GoToBeginningButton data-testid="goto-beginning" onClick={goToBeginningOfConversation}>
            <>{t("messages.gototop_button")}</>
            <ArrowUp />
          </GoToBeginningButton>
          {isButtonVisible && (
            <GoToTheEndButton data-testid="goto-end" onClick={goToEndOfConversation}>
              <ArrowDown />
            </GoToTheEndButton>
          )}
        </ButtonWrapper>
        <MessageInput patientId={selectedPatient} smartMessageReply={smartMessageReply} onSuccess={fetchNewAfterSend} />
      </BottomContainer>
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 16px 24px;
  overflow: auto;
  ${props => props.theme.aboveBreakpoint} {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  height: 66px;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_20} 0;
  ${props => props.theme.font.header4};
  color: ${props => props.theme.colors.greys.dark};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 12px;
  top: -40px;
`;

const GoToBeginningButton = styled(SecondaryButton)`
  min-width: 0px;
  padding: 3px 8px 3px 10px;
  font-size: 12px;
  width: 172px;
  height: 30px;
  margin-left: auto;
`;

const ArrowDown = styled(DropdownIcon)`
  path {
    stroke: currentColor;
    stroke-width: 2.5px;
    fill: white;
    fill-opacity: 0;
  }
`;

const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
`;

const GoToTheEndButton = styled(SecondaryButton)`
  min-width: 0px;
  padding: 3px 8px;
  width: 60px;
  height: 30px;
  margin-left: 10px;
`;

const CenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.font.header3}
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.warm};
  margin-bottom: ${props => props.theme.spacing.S_20};
  margin-top: ${props => props.theme.spacing.S_20};
`;

const BottomContainer = styled.div`
  position: relative;
  order: 2;
  display: flex;
`;

const StyledLeifGPT = styled(LeifGPT)<{ $isScrollDirectionDown: boolean }>`
  width: auto;
  margin: 0 0 24px;
  order: ${({ $isScrollDirectionDown }) => ($isScrollDirectionDown ? "1" : "-1")};
`;

export default ChatWindow;
