import type React from "react";

import styled from "styled-components";

import useVideoJS from "utils/hooks/useVideoJS";

interface VideoProps {
  videoFileUrl: string;
  poster: string;
  captionFileUrl?: string;
}

const Video: React.VFC<VideoProps> = ({ videoFileUrl, poster, captionFileUrl }) => {
  const { Video: VideoPlayer } = useVideoJS({
    sources: [{ src: videoFileUrl }],
    poster,
    ...(captionFileUrl && {
      tracks: [{ kind: "captions", src: captionFileUrl, mode: "showing" }],
    }),
  });

  return (
    <Container data-testid="video-container">
      <VideoPlayer />
    </Container>
  );
};

export default Video;

const Container = styled.div`
  position: relative;
  display: block;
  height: 0;
  margin-bottom: ${props => props.theme.spacing.S_15};
  padding-bottom: 56.25%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .vjs-text-track-cue {
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffc800;
  }

  .video-js .vjs-text-track-display > div > div > div {
    font-family: "Museo Sans 500", sans-serif;
    font-size: 18px !important;
    line-height: 33px !important;
    padding: 5px !important;
    background: #00000055 !important;
    border-radius: 8px;
    display: inline-block !important;
  }
`;
