import isMobile from "is-mobile";

export type Device = {
  isMobileOrTablet: boolean;
  isChromeOnAppleDevice: boolean;
  isFirefoxOnAppleDevice: boolean;
  isOperaTouchOnAppleDevice: boolean;
};

export const useDevice = (): Device => {
  const isMobileOrTablet = isMobile({ tablet: true, featureDetect: true });
  const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/));
  const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/));
  const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/));

  return {
    isMobileOrTablet,
    isChromeOnAppleDevice,
    isFirefoxOnAppleDevice,
    isOperaTouchOnAppleDevice,
  };
};
