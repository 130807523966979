import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";

import { Container } from "../../misc";

import { HEALTH_QUESTIONNAIRE_SCREENS } from "./HealthQuestionnaire";

interface Props {
  specificJointPain?: number;
  setSpecificJointPain: (pain: number) => void;
}

export const PainScreen: React.VFC<Props> = ({ specificJointPain, setSpecificJointPain }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <PainGroup>
        {[...Array(11)].map((_value, i) => {
          const key = `pain_${i}`;
          return (
            <Pain key={key} onClick={() => setSpecificJointPain(i)} $selected={i <= Number(specificJointPain)}>
              {i}
            </Pain>
          );
        })}
      </PainGroup>

      <PrimaryButton
        onClick={() => {
          navigate(`../${HEALTH_QUESTIONNAIRE_SCREENS.PHYSICAL_EXAMINATION}`);
        }}
        style={{ alignSelf: "center" }}
      >
        {t("booking.buttons.continue")}
      </PrimaryButton>
    </Container>
  );
};

const PainGroup = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const Pain = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 32px;
  color: ${({ theme, $selected }) => ($selected ? theme.colors.white : theme.colors.redesign.db60)};
  background: ${({ theme, $selected }) => ($selected ? theme.colors.redesign.b100 : theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  cursor: pointer;
`;
