import { useMutation, useQueryClient } from "react-query";

import { getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import type { GetPatientCareEventsResponse } from "api/types/GetPatientCareEvents";
import type { PostMedicalNoteAddendumArgs, PostMedicalNoteAddendumResponse } from "api/types/PostMedicalNoteAddendum";
import useApiClient from "api/useApiClient";
import { useProfileContext } from "contexts/ProfileContext";

export const usePostMedicalNoteAddendum = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  const { profile } = useProfileContext();

  return useMutation<PostMedicalNoteAddendumResponse, Error, PostMedicalNoteAddendumArgs>(
    variables => client.postMedicalNoteAddendum(variables),
    {
      onMutate: async variables => {
        const queryKey = getPatientCareEventsQueryKey({ patientId: variables.patientId, signed: true });

        await queryClient.cancelQueries(queryKey);
        const previousCareEvents = queryClient.getQueryData<GetPatientCareEventsResponse>(queryKey);

        if (previousCareEvents) {
          const addendedMedicalNote = previousCareEvents.find(careEvent => careEvent.id === variables.careEventId);

          if (addendedMedicalNote?.medical_note_addenda) {
            addendedMedicalNote.medical_note_addenda = [
              ...addendedMedicalNote.medical_note_addenda,
              {
                health_care_professional_name: `${profile?.first_name} ${profile?.last_name}`,
                health_care_professional_id: profile?.id ?? 1234,
                body: variables.body,
                created_at: new Date().toISOString(),
                id: Math.random(),
              },
            ];
            queryClient.setQueryData<GetPatientCareEventsResponse>(queryKey, [...previousCareEvents]);
          }
        }

        return { previousCareEvents };
      },
      onError: (_error, variables, context) => {
        const queryKey = getPatientCareEventsQueryKey({ patientId: variables.patientId, signed: true });

        // TODO: Remove when upgrading to typescript 4.7
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (context?.previousCareEvents) {
          // TODO: Remove when upgrading to typescript 4.7
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          queryClient.setQueryData<GetPatientCareEventsResponse>(queryKey, context.previousCareEvents);
        }
      },
      onSettled: context => {
        if (context) {
          const queryKey = getPatientCareEventsQueryKey({ patientId: context.patient_id, signed: true });
          queryClient.invalidateQueries(queryKey);
        }
      },
    }
  );
};
