import type React from "react";

import { BlueLine, Body, Container, Content, Title } from "../InfoHelpers";

interface SystemMessageProps {
  title: string;
  body?: string;
  centerBody?: boolean;
}

const SystemMessage: React.VFC<SystemMessageProps> = ({ title, body, centerBody }) => {
  return (
    <Container>
      <BlueLine />
      <Content>
        <Title>{title}</Title>
        {body && <Body $center={centerBody}>{body}</Body>}
      </Content>
    </Container>
  );
};

export default SystemMessage;
