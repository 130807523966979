import { isAfter, isEqual } from "date-fns";

import type { GetTherapistEarningsResponse } from "api/types/GetTherapistEarnings";
import { MarketTypes } from "types";
import { isMarket } from "utils/market";

type EarningInfo = {
  name: string;
  count?: number;
  earnings?: number;
  hideIfEmpty?: boolean;
};

const earnings = (response?: GetTherapistEarningsResponse): Array<EarningInfo> => {
  // TODO: Remove this when BE stops return earningsdata for US therapists
  const [, timeWithZone] = response?.invoice_period.split("T") ?? [];
  const invoiceDate = new Date(response?.invoice_period ?? "");
  const cutOffDate = new Date(`2021-07-01T${timeWithZone}`);
  if (
    isMarket(MarketTypes.US) &&
    response?.invoice_period &&
    (isAfter(invoiceDate, cutOffDate) || isEqual(invoiceDate, cutOffDate))
  ) {
    return [
      {
        name: "enrollment_bonuses",
        count: response?.number_of_enrollments,
        earnings: response?.enrollment_income,
      },
      { name: "total", count: response?.number_of_enrollments, earnings: response?.enrollment_income },
    ];
  }

  return [
    {
      name: "kick_off",
      count: response?.number_of_kick_off_calls,
      earnings: response?.kick_off_call_income,
    },
    { name: "follow_up", count: response?.number_of_week_6_calls, earnings: response?.week_6_call_income },
    {
      name: "quarterly_follow_up",
      count: response?.number_of_quarterly_follow_ups,
      earnings: response?.quarterly_follow_up_income,
    },
    {
      name: "discharge_notes",
      count: response?.number_of_discharge_notes,
      earnings: response?.discharge_note_income,
    },
    {
      name: "supplementary_calls",
      count: response?.number_of_supplementary_calls,
      earnings: response?.supplementary_call_income,
    },
    {
      name: "monthly_notes",
      count: response?.number_of_monthly_follow_ups,
      earnings: response?.monthly_follow_up_income,
    },
    {
      name: "weekly_notes",
      count: response?.number_of_weekly_follow_ups,
      earnings: response?.weekly_follow_up_income,
    },
    { name: "chat_notes", count: response?.number_of_chats, earnings: response?.chat_income },
    {
      name: "enrollment_bonuses",
      count: response?.number_of_enrollments,
      earnings: response?.enrollment_income,
    },
    {
      name: "six_week_bonuses",
      count: response?.number_of_six_week_bonuses,
      earnings: response?.six_week_bonus_income,
    },
    {
      name: "selfcare_bonus_income",
      count: response?.number_of_selfcare_bonus,
      earnings: response?.selfcare_bonus_income,
    },
    {
      name: "treatments",
      count: response?.number_of_treatments,
      earnings: response?.treatment_income,
    },
    {
      name: "others",
      count: response?.number_of_other,
      earnings: response?.other_income,
    },
    { name: "total", count: response?.number_of_articles, earnings: response?.total_income },
  ];
};

export default earnings;
