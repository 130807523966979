// FIXME: type translation
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type React from "react";
import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import { booleanToAnswer, exists, handleHeight, hasAnsweredAcute, isAdditionalJoint, isLegOrArm } from "../helpers";

import { FallPreventionQuestions } from "./FallPreventionQuestions";
import { Container, LeftColumn, RecordRow, RightContainer, Row, generateItem } from "./helpers";
import { OsteoporosisQuestions } from "./OsteoporosisQuestions";

const fingerList = ["thumb", "index", "middle", "ring", "little"];

const SignUpReport: React.VFC = () => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const match = useMatch("*");
  const { data: healthJournal, isLoading } = useGetPatientHealthJournalQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });

  useEffect(() => {
    if (match) AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.SIGN_UP_QUESTIONS, match.pathname);
  }, [match?.pathname]);

  if (isLoading) {
    return <Spinner dataTestId="loading" small />;
  }

  if (!healthJournal) {
    return <span>{t("patients.health_journal.no_health_journal")}</span>;
  }

  const shouldDisplayAcuteQuestion = () =>
    isAdditionalJoint(healthJournal.most_painful_location?.location) || hasAnsweredAcute(healthJournal?.pain_duration);

  const specificHandJoints = healthJournal.hand_specific_finger_joints;

  const painLocation = healthJournal.most_painful_location
    ? t("patients.pain_construct", {
        lateral:
          healthJournal.most_painful_location?.lateral_location !== "undefined"
            ? t(`patients.lateral_location.${healthJournal.most_painful_location?.lateral_location}`)
            : null,
        location:
          healthJournal.most_painful_location?.lateral_location === "both"
            ? t(`patients.pain_location.${healthJournal.most_painful_location?.location}.1`)
            : t(`patients.pain_location.${healthJournal.most_painful_location?.location}.0`),
      }).toLowerCase()
    : t("patients.medical_records.not_applicable");

  const oaDiagnosed = () => {
    if (typeof healthJournal.oa_diagnosed === "boolean") {
      return booleanToAnswer(healthJournal.oa_diagnosed);
    }
    if (typeof healthJournal.specific_joint_oa_diagnosed === "boolean") {
      return booleanToAnswer(healthJournal.specific_joint_oa_diagnosed);
    }
    return t("patients.medical_records.not_applicable");
  };

  const getInnerJoint = (joint: string) => {
    const innerJoint = joint.split("_")[2];
    return t(`patients.painful_finger_joint_${innerJoint}`);
  };

  const getSelectedRightHand = (joints: string[]): string[] => {
    return joints?.filter(joint => joint.includes("right"));
  };

  const getSelectedLeftHand = (joints: string[]): string[] => {
    return joints?.filter(joint => joint.includes("left"));
  };

  const getSelectedFinger = (joints: string[], finger: string) => {
    const specificFinger = joints.filter(joint => joint.includes(finger));
    const fingerJoints = specificFinger?.map(j => getInnerJoint(j));
    const specificFingerJoint = fingerJoints.length > 0 && fingerJoints.reduce((a, b) => `${a}, ${b}`);

    return specificFinger.length > 0 ? `${t(`patients.painful_finger_${finger}`)} (${specificFingerJoint}), ` : "";
  };

  const getFingerJoints = (joints: string[]): string[] => {
    return fingerList.map(finger => getSelectedFinger(joints, finger));
  };

  const hasKnucklesPain = specificHandJoints?.filter(
    (joint: string) => !joint.includes("thumb") && joint.includes("mcp")
  );

  const handleMultipleAnswers = (keyPrefix: string, items: string[]): string[] => {
    return items?.map((item: string, index: number) => {
      return `${index ? ", " : ""}${t(`${keyPrefix}.${item}`)}`;
    });
  };

  const getStiffnessTimeLengthSuffixBasedOnPain = () => {
    let timeSuffix = "";
    if (healthJournal.most_painful_location?.location.includes("hip")) {
      timeSuffix = t("common.one_hour");
    } else if (healthJournal.most_painful_location?.location.includes("knee")) {
      timeSuffix = t("common.half_hour");
    }
    return timeSuffix;
  };

  const highlightStiffness = healthJournal?.stiffness_duration === "more_than";

  const getStiffnessDuration = () => {
    if (healthJournal.stiffness_duration) {
      return `${t(`patient.answered.${healthJournal.stiffness_present}`)}, ${t(
        `stiffness.duration.${healthJournal.stiffness_duration}`
      )} ${getStiffnessTimeLengthSuffixBasedOnPain()}`;
    }

    return t(`patient.answered.${healthJournal.stiffness_present}`);
  };

  return (
    <Container data-testid="sign-up-report">
      {healthJournal.updated_at && (
        <Row
          left={t("patients.medical_records.date_answered")}
          right={format(parseISO(healthJournal.updated_at), "PP")}
          translated
        />
      )}

      <RecordRow>
        <LeftColumn>{t("patients.medical_records.medical_history")}</LeftColumn>
        <RightContainer>
          {exists(healthJournal.oa_diagnosed || healthJournal.specific_joint_oa_diagnosed) &&
            generateItem(t("patients.medical_records.previously_diagnosed"), oaDiagnosed())}
          {generateItem(t("patients.medical_records.most_painful"), painLocation)}
          {exists(healthJournal.previous_consultation) &&
            generateItem(
              t("patients.medical_records.previous_consultation"),
              booleanToAnswer(healthJournal.previous_consultation)
            )}
          {exists(healthJournal.previous_consultation) &&
            generateItem(
              t("patients.medical_records.previous_consultation_year"),
              booleanToAnswer(healthJournal.previous_consultation_year)
            )}
        </RightContainer>
      </RecordRow>

      <RecordRow>
        <LeftColumn>{t("patients.medical_records.status")}</LeftColumn>
        <RightContainer>
          {healthJournal.general_feeling &&
            generateItem(t("patients.medical_records.general_feeling"), healthJournal.general_feeling)}
          {healthJournal.rate_your_health &&
            generateItem(
              t("patients.medical_records.rated_health"),
              t(`patients.health.health_rating.${healthJournal.rate_your_health}`)
            )}
          {healthJournal.specific_joint_pain &&
            generateItem(t("patients.medical_records.rated_pain"), healthJournal.specific_joint_pain)}
          {exists(healthJournal.radiating_pain) &&
            generateItem(
              t("patients.medical_records.radiating_pain_scale", {
                legArm: isLegOrArm(healthJournal.most_painful_location?.location),
              }),
              healthJournal.radiating_pain
            )}
          {healthJournal.low_back_radiating_pain &&
            generateItem(t("patients.medical_records.radiating_pain"), healthJournal.low_back_radiating_pain)}
          {healthJournal.shoulder_radiating_pain &&
            generateItem(t("patients.medical_records.shoulder.radiating_pain"), healthJournal.shoulder_radiating_pain)}
          {healthJournal.neck_radiating_pain &&
            generateItem(t("patients.medical_records.neck_radiating_pain"), healthJournal.neck_radiating_pain)}
          {exists(healthJournal.radiating_pain_distance) &&
            generateItem(
              t("patients.medical_records.radiating_pain_distance"),
              t(`patients.radiating_pain_distance.${healthJournal.radiating_pain_distance}`)
            )}
          {healthJournal.tenderness &&
            generateItem(t("patients.medical_records.tenderness"), t("patient.answered.yes"))}
          {healthJournal.heated_joints &&
            generateItem(t("patients.medical_records.heated_joints"), t("patient.answered.yes"))}
          {healthJournal.height &&
            healthJournal.height_unit &&
            generateItem(
              t("patients.medical_records.height"),
              handleHeight(healthJournal.height, healthJournal.height_unit)
            )}
          {healthJournal.weight &&
            healthJournal.weight_unit &&
            generateItem(t("patients.medical_records.weight"), `${healthJournal.weight} ${healthJournal.weight_unit}`)}
          {healthJournal.bmi && generateItem(t("patients.medical_records.bmi"), healthJournal.bmi)}
        </RightContainer>
      </RecordRow>

      {healthJournal?.pain_duration && (
        <Row
          left={t("patients.medical_records.acute.duration", {
            painJoint: healthJournal.most_painful_location?.location,
          })}
          right={t(`patient.pain_duration.${healthJournal.pain_duration}`)}
          translated
        />
      )}

      {healthJournal?.pain_frequency && (
        <Row
          left={t("patients.medical_records.acute.frequency_of_pain")}
          right={t(`patient.frequency_of_pain.${healthJournal.pain_frequency}`)}
          translated
        />
      )}

      {healthJournal?.night_pain && (
        <Row left={t("patients.medical_records.acute.night_pain")} right={healthJournal.night_pain} />
      )}

      {exists(healthJournal.pain_under_pressure_or_movement) && (
        <Row
          left={t("patients.medical_records.pain", { painLocation })}
          right={booleanToAnswer(healthJournal.pain_under_pressure_or_movement)}
          translated
        />
      )}

      {exists(healthJournal.stiffness) && (
        <Row
          left={`${t("patients.medical_records.stiffness")} ${
            painLocation.includes("hip") ? t("common.max_one_hour") : t("common.max_half_hour")
          }`}
          right={booleanToAnswer(healthJournal.stiffness)}
          translated
        />
      )}

      {exists(healthJournal.stiffness_present) && (
        <Row
          left={t("patients.medical_records.stiffness")}
          right={getStiffnessDuration()}
          highlight={highlightStiffness}
          translated
        />
      )}

      {exists(healthJournal.works_standing_walking_rotating) && (
        <Row
          left={t("patients.medical_records.standing_job")}
          right={booleanToAnswer(healthJournal.works_standing_walking_rotating)}
          translated
        />
      )}

      {exists(healthJournal.sought_care_for_injured_specific_joint) && (
        <Row
          left={t("patients.medical_records.injury", { painLocation })}
          right={booleanToAnswer(healthJournal.sought_care_for_injured_specific_joint)}
          translated
        />
      )}

      {exists(healthJournal.frequency_of_low_back_pain) && (
        <Row
          left={t("patients.medical_records.frequency_of_pain")}
          right={t(`patient.frequency_of_low_back_pain.${healthJournal.frequency_of_low_back_pain}`)}
          translated
        />
      )}

      {exists(healthJournal.frequency_of_shoulder_pain) && (
        <Row
          left={t("patients.medical_records.frequency_of_shoulder_pain")}
          right={t(`patient.frequency_of_pain.${healthJournal.frequency_of_shoulder_pain}`)}
          translated
        />
      )}

      {exists(healthJournal.frequency_of_neck_pain) && (
        <Row
          left={t("patients.medical_records.frequency_of_neck_pain")}
          right={t(`patient.frequency_of_pain.${healthJournal.frequency_of_neck_pain}`)}
          translated
        />
      )}

      {healthJournal.when_neck_pain_occurs && (
        <Row
          left={t("patients.medical_records.when_neck_pain_occurs")}
          right={handleMultipleAnswers("patient.when_pain_occurs", healthJournal.when_neck_pain_occurs)}
          translated
        />
      )}

      {healthJournal.when_low_back_pain_occurs && (
        <Row
          left={t("patients.medical_records.when_pain_occurs")}
          right={handleMultipleAnswers("patient.when_pain_occurs", healthJournal.when_low_back_pain_occurs)}
          translated
        />
      )}

      {healthJournal.when_shoulder_pain_occurs && (
        <Row
          left={t("patients.medical_records.when_shoulder_pain_occurs")}
          right={handleMultipleAnswers("patient.when_pain_occurs", healthJournal.when_shoulder_pain_occurs)}
          translated
        />
      )}

      {healthJournal.neck_related_pain && (
        <Row
          left={t("patients.medical_records.neck_related_pain")}
          right={handleMultipleAnswers("patient.neck_related_pain", healthJournal.neck_related_pain)}
          translated
        />
      )}

      {healthJournal.shoulder_type_of_pain && (
        <Row
          left={t("patients.medical_records.shoulder_type_of_pain")}
          right={handleMultipleAnswers("patient.type_of_pain", healthJournal.shoulder_type_of_pain)}
          translated
        />
      )}

      {healthJournal?.pain_caused_by_accident && (
        <Row
          left={t("patient.pain_caused_by_accident")}
          right={t(`patient.medical_records.acute_pain_caused_by_accident.${healthJournal.pain_caused_by_accident}`)}
          translated
        />
      )}

      {healthJournal.low_back_pain_caused_by_accident && (
        <Row
          left={t("patient.pain_caused_by_accident")}
          right={t(`patient.medical_records.pain_caused_by_accident.${healthJournal.low_back_pain_caused_by_accident}`)}
          translated
        />
      )}

      {healthJournal.shoulder_pain_caused_by_accident && (
        <Row
          left={t("patient.pain_caused_by_accident")}
          right={t(`patient.medical_records.pain_caused_by_accident.${healthJournal.shoulder_pain_caused_by_accident}`)}
          translated
        />
      )}

      {healthJournal.neck_pain_caused_by_accident && (
        <Row
          left={t("patient.pain_caused_by_accident")}
          right={t(`patient.medical_records.pain_caused_by_accident.${healthJournal.neck_pain_caused_by_accident}`)}
          translated
        />
      )}

      {healthJournal.reduced_sensation && (
        <Row
          left={
            shouldDisplayAcuteQuestion()
              ? t("patients.medical_records.acute.reduced_sensation")
              : t("patients.medical_records.reduced_sensation")
          }
          right={healthJournal.reduced_sensation}
        />
      )}

      {healthJournal.reduced_strength && (
        <Row left={t("patients.medical_records.reduced_strength")} right={healthJournal.reduced_strength} />
      )}

      {healthJournal.low_back_loss_sensation && (
        <Row
          left={t("patients.medical_records.loss_sensation")}
          right={t(`patient.low_back_loss_sensation.${healthJournal.low_back_loss_sensation}`)}
          translated
        />
      )}

      {healthJournal?.reduced_sensation_abdomen && (
        <Row
          left={t("patients.medical_records.acute.reduced_sensation_abdomen", {
            painJoint: healthJournal.most_painful_location?.location,
          })}
          right={healthJournal.reduced_sensation_abdomen}
        />
      )}

      {healthJournal.chest_pain && (
        <Row
          left={
            shouldDisplayAcuteQuestion()
              ? t("patients.medical_records.acute.chest_pain")
              : t("patients.medical_records.chest_pain")
          }
          right={t(`patient.chest_pain.${healthJournal.chest_pain}`)}
          translated
        />
      )}

      {(healthJournal.have_had_cancer || healthJournal.low_back_have_had_cancer) && (
        <Row
          left={t("patients.medical_records.have_had_cancer")}
          right={(healthJournal.have_had_cancer || healthJournal.low_back_have_had_cancer) as string}
          highlight={Boolean(healthJournal.have_had_cancer || healthJournal.low_back_have_had_cancer)}
        />
      )}

      {healthJournal?.unexplained_weight_loss && (
        <Row
          left={t("patients.medical_records.acute.weight_loss")}
          right={healthJournal.unexplained_weight_loss}
          highlight={Boolean(healthJournal.unexplained_weight_loss)}
        />
      )}

      {specificHandJoints && (
        <RecordRow>
          <LeftColumn>{t("patients.medical_records.hand_specific_finger_joints")}</LeftColumn>
          <RightContainer>
            {getSelectedLeftHand(specificHandJoints)?.length > 0 &&
              generateItem(
                t("patient.most_painful_location.left_hand"),
                getFingerJoints(getSelectedLeftHand(specificHandJoints))
              )}
            {getSelectedRightHand(specificHandJoints)?.length > 0 &&
              generateItem(
                t("patient.most_painful_location.right_hand"),
                getFingerJoints(getSelectedRightHand(specificHandJoints))
              )}
          </RightContainer>
        </RecordRow>
      )}

      {hasKnucklesPain && hasKnucklesPain.length > 0 && (
        <Row left={t("patients.medical_records.hand_knuckles_pain")} right="yes" />
      )}

      {healthJournal.hand_knuckles_swollen && (
        <Row left={t("patients.medical_records.hand_knuckles_swollen")} right={healthJournal.hand_knuckles_swollen} />
      )}

      {healthJournal.hand_wrists_pain && (
        <Row left={t("patients.medical_records.hand_wrists_pain")} right={healthJournal.hand_wrists_pain} />
      )}

      {healthJournal.hand_wrists_swollen && (
        <Row left={t("patients.medical_records.hand_wrists_swollen")} right={healthJournal.hand_wrists_swollen} />
      )}

      {healthJournal.ailment === "fall_prevention" && <FallPreventionQuestions fields={healthJournal} />}

      {healthJournal.ailment === "osteoporosis" && <OsteoporosisQuestions fields={healthJournal} />}
    </Container>
  );
};

export default SignUpReport;
