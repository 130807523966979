import type { HcpService } from "api/schemas/HcpService";

import { END_TIME, START_TIME } from "./misc";
import { convertMinutesToTime, generateArrayWithMinutesStep } from "./settingsHelpers";

export const generateAvailabilityOptions = (hcpService?: HcpService, step?: number) => {
  const options = generateArrayWithMinutesStep(
    hcpService?.opening_hours ?? START_TIME,
    hcpService?.closing_hours ?? END_TIME,
    step
  ).map(option => {
    return { value: option, label: convertMinutesToTime(option) };
  });

  return options;
};
