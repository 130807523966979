import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import styled, { keyframes } from "styled-components";

import { SaveError, SaveSuccess } from "assets";

export type AutosaveStatus = "loading" | "success" | "error" | "idle";

const AUTOSAVE_TIMEOUT = 5000;

interface Props {
  status: AutosaveStatus;
  readonly className?: string;
}

const AutosaveIndicator: React.VFC<Props> = ({ status, className }) => {
  const { t } = useTranslation();
  const [innerStatus, setInnerStatus] = useState<AutosaveStatus>("idle");
  const [opacity, animateOpacity] = useSpring(() => ({ opacity: 1 }));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (status === "loading")
      timeout = setTimeout(() => {
        setInnerStatus("error");
      }, AUTOSAVE_TIMEOUT);
    setInnerStatus(status);
    return () => clearTimeout(timeout);
  }, [status]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    animateOpacity({ opacity: 1 });
    if (innerStatus === "success" || innerStatus === "error")
      timeout = setTimeout(() => animateOpacity({ opacity: 0 }), 3000);
    return () => clearTimeout(timeout);
  }, [innerStatus]);

  return (
    <Wrapper className={className} data-testid={`autosave-indicator-${innerStatus || "unknown"}`}>
      {innerStatus === "loading" && <SmallerSpinner data-testid="spinner" />}
      {innerStatus === "success" && <SaveSuccess aria-label={t("dashboard.tables.notes.autosave_indicator_saved")} />}
      {innerStatus === "error" && <SaveError aria-label={t("dashboard.tables.notes.autosave_indicator_unsaved")} />}
      <Label style={opacity}>
        {innerStatus === "success" && t("dashboard.tables.notes.autosave_indicator_saved")}
        {innerStatus === "error" && t("dashboard.tables.notes.autosave_indicator_unsaved")}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  margin: 0 8px;

  svg {
    vertical-align: middle;
  }

  ${props => props.theme.belowBreakpoint} {
    margin: 0 16px;
    align-self: center;
    flex-direction: row-reverse;
    vertical-align: text-bottom;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SmallerSpinner = styled.div`
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-color: ${({ theme }) => theme.colors.secondary.base};
  border-width: 2px;
  border-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.greys.light1};
  border-radius: ${({ theme }) => theme.borderRadius.circular};
  animation: ${rotate} 1s linear infinite;
`;

const Label = styled(animated.span)`
  ${props => props.theme.font.autosave_label};
  padding: 0 8px;
  white-space: nowrap;
  opacity: 1;
  width: 0;
  ${props => props.theme.belowBreakpoint} {
    width: auto;
  }
`;

export default AutosaveIndicator;
