import type React from "react";

import NotificationsPane from "./components/NotificationsPane";

const NotificationsRoute: React.VFC = () => {
  return (
    <>
      <NotificationsPane />
    </>
  );
};

export * from "./types";

export default NotificationsRoute;
