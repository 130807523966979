const AnalyticsService = {
  initialize(): void {
    window?.analytics?.load(process.env.REACT_APP_SEGMENT_API_KEY as string);
  },

  alias(userId: string): void {
    window?.analytics?.alias(userId);
  },

  identify(userId: string): void {
    window?.analytics?.identify(userId);
  },

  reset(): void {
    window?.analytics?.reset();
  },

  track(name: string, properties?: Record<string, unknown>): void {
    window?.analytics?.track(name, { ...properties, appVersion: process.env.REACT_APP_VERSION });
  },

  viewedPage(name: string, path: string, options?: Record<string, unknown>): void {
    window?.analytics?.page(name, { path, ...options, appVersion: process.env.REACT_APP_VERSION });
  },
};

export default AnalyticsService;
