import type React from "react";

import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { Goal, HealthQuestionnaires, MedicalRecord, PersonalDetails, SignUpQuestions } from "./views";

const PatientsContainer = styled.div`
  background: ${props => props.theme.colors.white};
  min-height: 100vh;
`;

const InformationRoutes: React.VFC = () => {
  return (
    <>
      <PatientsContainer>
        <Routes>
          <Route path="journal" element={<MedicalRecord />} />
          <Route path="sign-up-questions" element={<SignUpQuestions />} />
          <Route path="goals" element={<Goal />} />
          <Route path="personal" element={<PersonalDetails />} />
          <Route path="health-reports" element={<HealthQuestionnaires />} />
        </Routes>
      </PatientsContainer>
    </>
  );
};

export default InformationRoutes;
