/**
 * Removes any items from `items` that doesn't exist in `available` and add
 * any items from `available` that wasn't in `items` (at the end) while
 * at the same time keeping the initial order of `items`.
 */
const reconcile = <T>(items: T[], available: T[]): T[] => {
  const toBeChecked = new Set(available);
  const checked = items.reduce<T[]>((accumulator, value) => {
    if (toBeChecked.has(value)) {
      toBeChecked.delete(value);
      return [...accumulator, value];
    }
    return accumulator;
  }, []);

  return [...checked, ...Array.from(toBeChecked)];
};

export default reconcile;
