import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import type { Cable } from "@anycable/core";
import { createCable } from "@anycable/web";
import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import useApiClient from "api/useApiClient";
import { useProfileContext } from "contexts/ProfileContext";
import { QUERY_KEYS } from "types/QueryKeys";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { getRestHeaders } from "utils/cookie";
import { reportError } from "utils/errorReporting";

import DropInCallContext from "./DropInCallContext";
import type { Session } from "./DropInCallContextValue";
import TherapistChannel from "./TherapistChannel";

type Props = {
  children: React.ReactElement;
  _testing?: {
    initialCurrentSession: Session | undefined;
  };
};

const DropInCallContextProvider: React.FC<Props> = ({ children, _testing }) => {
  const { profile } = useProfileContext();
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [currentSession, setCurrentSession] = useState<Session | undefined>(_testing?.initialCurrentSession);
  const [cable, setCable] = useState<undefined | Cable>();
  const channelRef = useRef(new TherapistChannel());
  const apiClient = useApiClient();
  const { patient: currentPatient, refreshPatient } = useContext(CurrentPatientContext);
  const [patientName, setPatientName] = useState<string | undefined>(undefined);
  const [expiryTime, setExpiryTime] = useState("");
  const [askForFeedback, setAskForFeedback] = useState(false);
  const pingTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const fetchEventCards = async () => AxiosInstance.get(`dashboard/actions`);
  const loggedIn = !!getRestHeaders();
  const { refetch: refetchDashboardCards } = useQuery(QUERY_KEYS.dashboard.eventCards, fetchEventCards, {
    enabled: !!(profile?.therapist_profile?.feature_flags?.includes("DROP_IN_CALLS_PUSH") && loggedIn),
  });

  const getWebSocketUrl = async () => {
    const { url } = await apiClient.postCableConnectionUrls();
    return url;
  };

  const trackError = (name: string, error: Error | string) => {
    reportError(name, error, {
      context: {
        name,
        error,
        patient_id: currentPatient?.id,
        physio_id: profile?.id,
      },
    });
    AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.ERROR, {
      name,
      error,
    });
  };

  const onSubscribed = () => {
    AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.SUBSCRIBED);
    setIsReady(true);
    setErrorCode(null);
  };

  const onConnect = () => {
    AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.CONNECT);
    requestStatus();
    setIsReady(true);
    setErrorCode(null);
  };

  const onDisconnect = () => {
    AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.DISCONNECT);
    setIsReady(false);
  };

  const requestStatus = async () => {
    await channelRef.current.requestStatus();
  };

  const sendPing = async () => {
    await channelRef.current.ping();
  };

  const toggleAvailability = async () => {
    try {
      await channelRef.current.updateAvailability(!available);
      setErrorCode(null);
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        setErrorCode(1);
        trackError("WebSocket toggleAvailability Error", err);
      }
    }
  };

  const startReviewing = async () => {
    try {
      // Error logs indicate that some PTs can call startReviewing even when state is not claimed, adding this check / tracking here to find out more
      if (currentSession?.state !== "claimed") {
        trackError(
          `Start review called when session state is: ${currentSession?.state} instead of expected: claimed`,
          ""
        );
        requestStatus();
        return;
      }

      await channelRef.current.startReviewing();
      setErrorCode(null);
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        setErrorCode(2);
        trackError("WebSocket startReviewing Error", err);
      }
    }
  };

  const finishReviewing = async () => {
    try {
      if (currentSession?.state !== "in_review") {
        trackError(
          `Start review called when session state is: ${currentSession?.state} instead of expected: in_review`,
          ""
        );
        requestStatus();
        return false;
      }

      await channelRef.current.finishReviewing();
      AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.COMPLETED, {
        patient: currentSession?.patient_id,
        sessionId: currentSession?.id,
      });
      setAskForFeedback(true);
      setErrorCode(null);
      await requestStatus();

      if (currentPatient.id === currentSession?.patient_id) {
        // trigger refresh of user so corresponding views are updated
        refreshPatient();
      }
      return true;
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        setErrorCode(3);
        reportError("WebSocket finishReviewing Error", err);
      }
    }
    return false;
  };

  const cancelSession = async () => {
    try {
      if (!currentSession && !available) {
        trackError("Cancel session called but with no session and no availability", "");
        requestStatus();
      }

      if (currentSession) {
        AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.CLIENT_CANCELED, { state: currentSession.state });
        await channelRef.current.cancelSession();
      }
      if (available) {
        AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.CLIENT_CANCELED, {
          state: "no session, toggle available off",
        });

        await channelRef.current.updateAvailability(!available);
      }

      setErrorCode(null);
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        setErrorCode(4);
        reportError("Websocket cancel session error", err);
      }
    }
  };

  const onSessionUpdate = async (session: Session) => {
    if (session) {
      const { patient_id, created_at: assignedAt, state, id: sessionId } = session;

      if (patient_id !== currentSession?.patient_id) {
        const patient = await apiClient.getPatient({ patientId: patient_id });
        setPatientName(`${patient?.first_name} ${patient?.last_name}`);
      }

      if (state) {
        if (state === "claimed") {
          AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.PATIENT_ASSIGNED, {
            patient: patient_id,
            sessionId,
            assignedAt,
          });
          setTimeout(() => {
            // ugly workaround since ws action occurs before db has been updated it seems like.
            refetchDashboardCards();
          }, 1000);
        }
        if (state === "in_review") {
          AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.IN_REVIEW, { patient: patient_id, sessionId });
        }
        if (state === "canceled") {
          AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.CANCELED, { patient: patient_id, sessionId });
        }
      }

      if (assignedAt) {
        try {
          const timeStamp = new Date(assignedAt).getTime();
          const expiry = new Date(timeStamp + 1000 * 60 * 10).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          if (expiry === "Invalid Date") {
            setErrorCode(5);
            trackError("Drop-In Call Error", "Invalid expory date");
          }
          setExpiryTime(expiry);
        } catch (e) {
          setErrorCode(6);
          trackError("Drop-In Call Error", `Error in timestamp assignement: ${e}`);
        }
      }
    } else {
      setPatientName(undefined);
    }

    setCurrentSession(session);
  };

  // keep alive pinging hook
  useEffect(() => {
    if (!pingTimer.current && (available || currentSession)) {
      sendPing();
      pingTimer.current = setInterval(sendPing, 1000 * 60);
    }
    return () => {
      if (pingTimer.current) {
        clearInterval(pingTimer.current);
        pingTimer.current = null;
      }
    };
  }, [available, currentSession]);

  // browser monitor hook
  useEffect(() => {
    const unloadCallback = () => {
      if (available && !currentSession) {
        AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.BROWSER_UNLOAD);
        toggleAvailability();
      }
    };

    window.addEventListener("unload", unloadCallback);

    return () => {
      window.removeEventListener("unload", unloadCallback);
    };
  }, [available, currentSession]);

  // setup cable hook
  useEffect(() => {
    if (!profile?.therapist_profile?.feature_flags?.includes("DROP_IN_CALLS_PUSH") || cable) {
      return;
    }

    const getCable = async () => {
      try {
        const url = await getWebSocketUrl();
        const createdCable = createCable(url, {
          tokenRefresher: async transport => {
            transport.setURL(await getWebSocketUrl());
          },
        });
        createdCable.on("disconnect", onDisconnect);
        setCable(createdCable);
      } catch (err) {
        if (err instanceof Error || typeof err === "string") {
          setErrorCode(7);
          trackError("WebSocket Cable Error", err);
        }
      }
    };
    getCable();
  }, [profile?.therapist_profile?.feature_flags]);

  // subscribe to cable hook
  useEffect(() => {
    const subscribe = async () => {
      if (cable === undefined) return;
      try {
        await cable.subscribe(channelRef.current).ensureSubscribed();
        channelRef.current.on("connect", onConnect);
        channelRef.current.on("disconnect", onDisconnect);
        channelRef.current.on("on_available", is_available => {
          setAvailable(is_available);
        });
        channelRef.current.on("on_update", session => {
          onSessionUpdate(session);
        });
        channelRef.current.on("on_error", cause => {
          setErrorCode(8);
          trackError("Websocket on_error: ", cause);
        });
        await requestStatus();
        onSubscribed();
      } catch (err) {
        if (err instanceof Error || typeof err === "string") {
          setErrorCode(9);
          trackError("Websocket setup error", err);
        }
      }
    };

    subscribe();
  }, [cable]);

  // Toggle availability tracking hook
  useEffect(() => {
    if (available) {
      AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.SET_TOGGLE_ON);
    } else {
      AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.SET_TOGGLE_OFF);
    }
  }, [available]);

  const value = {
    available,
    errorCode,
    isReady,
    patientName,
    toggleAvailability,
    startReviewing,
    finishReviewing,
    currentSession,
    expiryTime,
    requestStatus,
    cancelSession,
    askForFeedback,
    setAskForFeedback,
  };

  return <DropInCallContext.Provider value={value}>{children}</DropInCallContext.Provider>;
};

export default DropInCallContextProvider;
