import { differenceInCalendarDays } from "date-fns";

import { getStorageValue, setStorageValue } from "utils/storage";

type SuggestionDimissedList = Record<string, number>;

const getKey = (pId: number, noteType: string, tId: number): string => `${pId}_${noteType}_${tId}`;

const cleanDismissedSuggestionList = (dismissedSuggestions: SuggestionDimissedList): SuggestionDimissedList => {
  const labels = Object.keys(dismissedSuggestions);
  const cleanedDismissedList = labels.reduce(
    (newList: SuggestionDimissedList, label: string): SuggestionDimissedList => {
      const dismissedDate = dismissedSuggestions[label];
      if (differenceInCalendarDays(dismissedDate, Date.now()) >= 0) {
        return { ...newList, [label]: dismissedDate };
      }
      return newList;
    },
    {}
  );
  return cleanedDismissedList;
};

const getDismissedSuggestions = (): SuggestionDimissedList => {
  const result = getStorageValue("suggestion-dismissed");

  return cleanDismissedSuggestionList(result as SuggestionDimissedList);
};

const setDismissedSuggestions = (dismissedSuggestions: SuggestionDimissedList): void => {
  const cleaned = cleanDismissedSuggestionList(dismissedSuggestions);
  setStorageValue("suggestion-dismissed", cleaned);
};

export const addDismissedSuggestionListItem = (pId: number, noteType: string, tId: number): void => {
  const currentDismissedSuggestions = { ...getDismissedSuggestions(), [getKey(pId, noteType, tId)]: Date.now() };
  setDismissedSuggestions(currentDismissedSuggestions);
};

export const isSuggestionDismissed = (pId: number, noteType: string, tId: number): boolean => {
  const dismissed = getDismissedSuggestions();
  setDismissedSuggestions(dismissed);
  return !!dismissed[getKey(pId, noteType, tId)];
};
