import type React from "react";

import { useTranslation } from "react-i18next";

import type { TreatmentAdherence } from "api/schemas/patients/TreatmentAdherence";

import { Data, DataWrapper, InfoGrid, Label, Progress, ProgressBar, TreatmentPaneWrapper } from "../../helpers";
import TreatmentDropdown from "../../TreatmentDropdown";

import { DaysOfActivity } from "./DaysOfActivity";

interface Props {
  adherence: number;
  week: number;
  started: string;
  isCurrentTreatment: boolean;
  rtmData?: TreatmentAdherence;
}

const TreatmentPane: React.VFC<Props> = ({ adherence, week, isCurrentTreatment, started, rtmData }) => {
  const { t } = useTranslation();
  const treatmentAdherence = rtmData?.week?.adherence ?? adherence;
  const treatmentWeek = rtmData?.week?.number ?? week;

  return (
    <TreatmentPaneWrapper $isCurrentTreatment={isCurrentTreatment}>
      <TreatmentDropdown />
      <InfoGrid>
        <>
          <Label>{t("patients.header_info.treatment_started")}</Label>
          <Data>{started}</Data>
        </>
        <>
          <Label>{t("patients.header_info.adherence")}</Label>
          <DataWrapper>
            <ProgressBar>
              <Progress $value={treatmentAdherence} />
            </ProgressBar>
            <Data>{treatmentAdherence}%</Data>
          </DataWrapper>
        </>
        <>
          <Label>{t("patients.header_info.treatment_week")}</Label>
          <Data>{treatmentWeek}</Data>
        </>
      </InfoGrid>
      {rtmData && Object.hasOwn(rtmData, "activity_days") && <DaysOfActivity activityDays={rtmData.activity_days} />}
    </TreatmentPaneWrapper>
  );
};

export default TreatmentPane;
