/**
 * TypeScript has not defined Number.isFinite as a type guard, so this does
 * that.
 *
 * @example
 * if (isFiniteNumber(maybeNumber)) {
 *   console.log('maybeNumber is a number!');
 * }
 */
const isFiniteNumber = (value: unknown): value is number => Number.isFinite(value);

export default isFiniteNumber;
