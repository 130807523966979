import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

import { MiniArrowRight, SubMenuBackArrow } from "assets";
import theme from "theme";
import useHeight from "utils/hooks/useHeight";

import { DropdownButton, DropdownItem } from "../helpers";

interface Props {
  children: React.ReactNode;
  closeMenu: () => void;
  open: boolean;
  useBackgroundOverlay?: boolean;
  useToggleIcon?: boolean;
  subOpen?: boolean;
}

const PatientHeaderDropdown: React.VFC<Props> = ({
  children,
  closeMenu,
  open,
  useBackgroundOverlay = true,
  useToggleIcon = false,
  subOpen = false,
}) => {
  const [heightRef, heightMax] = useHeight<HTMLDivElement>();
  const { opacity, animatedDisplay, maxHeight } = useSpring({
    opacity: open ? 0.65 : 0,
    animatedDisplay: open ? 1 : 0,
    maxHeight: open && !subOpen ? heightMax : 0,
  });

  return (
    <>
      <Overlay
        style={{
          backgroundColor: useBackgroundOverlay ? theme.colors.black : "transparent",
          opacity,
          display: animatedDisplay.interpolate(v => (v === 0 ? "none" : "initial")),
        }}
        onClick={closeMenu}
        data-testid="dropdown-overlay"
      />
      <DropdownList
        data-testid="dropdown-list"
        style={{
          maxHeight,
          display: animatedDisplay.interpolate(v => (v === 0 ? "none" : "initial")),
          top: useToggleIcon ? "-2px" : "126px",
          paddingTop: useToggleIcon ? "24px" : "0px",
        }}
      >
        <div ref={heightRef}>{children}</div>
      </DropdownList>
    </>
  );
};

export default PatientHeaderDropdown;

const DropdownList = styled(animated.ul)`
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  right: 0;
  left: 0;
  top: 170px;
  list-style: none;
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.redesign.db20};
  z-index: ${props => props.theme.zIndex.headerDropdown};
`;

const Overlay = styled(animated.div)`
  position: fixed;
  top: 126px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.colors.black};
  z-index: ${props => props.theme.zIndex.headerDropdownOverlay};
  :hover {
    cursor: pointer;
  }
`;

interface SubProps {
  isMainOpen: boolean;
  children: React.ReactNode;
  label: React.ReactNode;
  onVisibleChange: (visible: boolean) => void;
}

export const PatientHeaderDropdownSub: React.VFC<SubProps> = ({ isMainOpen, children, label, onVisibleChange }) => {
  const [open, setOpen] = useState(false);
  const [heightRef, heightMax] = useHeight<HTMLDivElement>();
  const { animatedDisplay, maxHeight } = useSpring({
    animatedDisplay: open && isMainOpen ? 1 : 0,
    maxHeight: open && isMainOpen ? heightMax : 0,
  });
  const { t } = useTranslation();
  const dropdownAnimation = animatedDisplay.interpolate(v => (v === 0 ? "none" : "initial"));

  useEffect(() => {
    if (!isMainOpen) {
      onVisibleChange(false);
      setOpen(false);
    }
  }, [isMainOpen, onVisibleChange]);

  const flipVisibility = () => {
    onVisibleChange(!open);
    setOpen(!open);
  };

  return (
    <DropdownItem>
      <DropdownButton onClick={() => flipVisibility()}>
        <ButtonContent>
          <ButtonContentLabel>{label}</ButtonContentLabel>
          <MiniArrowRight />
        </ButtonContent>
      </DropdownButton>
      <DropdownSubList style={{ maxHeight, display: dropdownAnimation }}>
        <div ref={heightRef}>
          <DropdownItem>
            <DropdownButton onClick={() => flipVisibility()}>
              <SubMenuBackArrow />
              {t("buttons.back")}
            </DropdownButton>
          </DropdownItem>
          {children}
        </div>
      </DropdownSubList>
    </DropdownItem>
  );
};

const DropdownSubList = styled(animated.ul)`
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  right: 0;
  left: 0;
  top: 70px;
  list-style: none;
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: ${props => props.theme.zIndex.headerDropdown + 10};
`;

const ButtonContent = styled(animated.div)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;
const ButtonContentLabel = styled(animated.div)`
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${props => props.theme.spacing.S_10};
  }
`;
