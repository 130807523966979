import type React from "react";
import { useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { NotificationIcon } from "assets";
import type { ExtendedNotification } from "routes/notifications/types";

import NotificationsContext from "./NotificationsContext";

const NotificationsContextProvider: React.FC = ({ children }) => {
  const [allowDesktopNotifications, setAllowDesktopNotifications] = useState(false);

  // ask user to approve desktop notifications, has to be done on user interaction on safari
  const askForDesktopNotificationPermission = () => {
    const callback = (permission: NotificationPermission) => {
      setAllowDesktopNotifications(permission === "granted");
    };
    if ("Notification" in window && !allowDesktopNotifications) {
      // try old callback solution for safari 14.1 which can return undefined regardless of what ts thinks
      const request: Promise<NotificationPermission> | undefined = Notification.requestPermission(callback);

      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      if (request?.then) {
        Notification.requestPermission().then(callback);
      }
    }
  };

  const addDesktopNotification = (notification: ExtendedNotification) => {
    const desktopNotification = new Notification("Joint Academy", {
      body: notification.message,
      tag: notification.id,
      icon: NotificationIcon,
    });

    desktopNotification.addEventListener("click", () => {
      window.parent.focus();
      desktopNotification.close();
      closeNotification(notification.id);
    });
  };

  const [notifications, setNotifications] = useState<ExtendedNotification[]>([]);
  const addNotification = (notification: Omit<ExtendedNotification, "id">) => {
    const id = uuidv4();
    const extendedNotification = {
      ...notification,
      id,
    };
    setNotifications(nextNotifications => [...nextNotifications, extendedNotification]);

    if (allowDesktopNotifications) {
      addDesktopNotification(extendedNotification);
    }

    return id;
  };

  const closeNotification = (id: string) => {
    const found = notifications.find(n => n.id === id);
    if (found) {
      setNotifications(nextNotifications => nextNotifications.filter(n => n.id !== id));
    }
    return false;
  };

  const updateNotification = (id: string, updatedNotification: Omit<ExtendedNotification, "id">) => {
    const notification = notifications.find(n => n.id === id);
    if (notification) {
      const newNotification = {
        ...notification,
        ...updatedNotification,
      };

      setNotifications(nextNotifications => {
        return [...nextNotifications.filter(n => n.id !== id), newNotification];
      });

      if (allowDesktopNotifications) {
        addDesktopNotification(newNotification);
      }
      return true;
    }
    return false;
  };

  const value = {
    notifications,
    closeNotification,
    addNotification,
    updateNotification,
    askForDesktopNotificationPermission,
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsContextProvider;
