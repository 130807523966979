import type React from "react";

import Journal from "../components/Journal";

const MedicalRecord: React.VFC = () => {
  return (
    <div data-testid="medical-record">
      <Journal />
    </div>
  );
};

export default MedicalRecord;
