import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { AppointmentsResponse } from "api/schemas/NewAppointment";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getAppointments";
type QueryKey = [typeof queryKeyPrefix, GetAppointmentsArgs];
export const getAppointmentsQueryKey = (args: GetAppointmentsArgs): QueryKey => [queryKeyPrefix, args];

export interface GetAppointmentsArgs {
  userId: number;
  startDate: string;
  view: "day" | "week";
  isAdmin: boolean;
  onlyTherapistAppointments?: boolean;
}

const useGetAppointments = (
  args: GetAppointmentsArgs,
  options?: UseQueryOptions<AppointmentsResponse, Error, AppointmentsResponse, QueryKey>
) => {
  const client = useApiClient();

  const { isAdmin, onlyTherapistAppointments } = args;

  return useQuery(
    getAppointmentsQueryKey(args),
    () => {
      if (isAdmin && !onlyTherapistAppointments) return client.getAppointmentsManager(args);

      return client.getNewAppointments(args);
    },
    options
  );
};

export default useGetAppointments;
