import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { PutPlanOfCareArgs, PutPlanOfCareResponse } from "api/types/PutPlanOfCare";

import useApiClient from "../useApiClient";

const usePutPlanOfCareMutation = (
  options?: UseMutationOptions<PutPlanOfCareResponse, Error, PutPlanOfCareArgs>
): UseMutationResult<PutPlanOfCareResponse, Error, PutPlanOfCareArgs> => {
  const client = useApiClient();

  return useMutation<PutPlanOfCareResponse, Error, PutPlanOfCareArgs>(vars => client.putPlanOfCare(vars), options);
};

export default usePutPlanOfCareMutation;
