import type React from "react";
import { useContext, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import { CancelIcon, CheckmarkIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Hidden from "shared/atoms/Hidden";
import PhoneNumber from "shared/atoms/PhoneNumber";
import { TableCell, TableRow } from "shared/molecules/Table";
import useLocalizedDate from "utils/date";

import type { AppointmentType } from "../../UpcomingAppointments";
import CompleteAppointment from "../CompleteAppointment/CompleteAppointment";
import RemoveAppointment from "../RemoveAppointment";

const AppointmentRow = styled(TableRow)<{ $state: string | null }>`
  ${({ $state }) =>
    $state === "completed" &&
    `
    span {
      text-decoration: line-through;
      opacity: 50%;
    }

    & complete-appointment {
      opacity: 10%;
    }
  `};
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  height: auto;
`;

const TimeCell = styled(TableCell)`
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    padding: 0 ${props => props.theme.spacing.S_10};
  }
`;

const InformationCell = styled(TableCell)`
  position: relative;
  display: flex;
  flex: 3;
  flex-direction: row;
  justify-content: flex-start;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 ${props => props.theme.spacing.S_10};
  }
`;

const ActionCell = styled(TableCell)<{ $borderRight?: boolean }>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ $borderRight, theme }) => ($borderRight ? theme.colors.greys.silver : "0")};
  padding-right: ${({ $borderRight }) => ($borderRight ? "1px" : "0")};
  ${props => props.theme.aboveBreakpoint} {
    position: relative;
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  margin: 0 10px;
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)<{ $state: string | null }>`
  margin: 0 10px;
  path {
    stroke: green;
  }
  transform: scale(1.3) translateX(1px);
  ${({ $state }) => $state === "completed" && "opacity: 30%;disabled: true"}
`;

const Text = styled.span<{ $justify?: string; $color?: string; $weight?: string }>`
  flex-grow: 2;
  flex-basis: 0;
  display: flex;
  justify-content: ${props => props?.$justify || "flex-start"};
  line-height: 40px;
  font-size: 14px;
  color: ${props => props?.$color || props.theme.colors.greys.dark};
  font-weight: ${props => props?.$weight || props.theme.fontWeight.regular};
  ${props => props.theme.belowBreakpoint} {
    line-height: 25px;
    min-height: 25px;
  }
`;

const InformationText = styled(Text)`
  flex-grow: 3;
`;

interface Props {
  data: AppointmentType;
  refetch: () => void;
}

const Appointment: React.VFC<Props> = ({ data, refetch }) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const meetingRef = useRef<HTMLSpanElement>(null);
  const [deleteOpen, setDelete] = useState(false);
  const [completeOpen, setComplete] = useState(false);
  const [removeError, setRemoveError] = useState<string | null>(null);
  const { patient, startTime, endTime, purpose, medium, id: uid } = data;
  const { firstName, lastName, phoneNumber, id } = patient;
  const IS_PHONE = medium === "PHONE";
  const { profile } = useProfileContext();

  invariant(profile);
  let country = "";
  if (profile.locale === "en-US") {
    country = "USA";
  } else if (profile.locale === "sv-SE") {
    country = "Sweden";
  }

  return (
    <AppointmentRow data-testid="appointment_row" $state={data.state}>
      <TimeCell>
        <Text data-testid="appointment-date">{format(parseISO(startTime), "PP")}</Text>
        <Hidden type="belowTablet">
          <Text $justify="flex-end" $weight={theme.fontWeight.light}>
            {`${format(parseISO(startTime), "p")} -
              ${format(parseISO(endTime), "p")}`}
          </Text>
        </Hidden>
        <Hidden type="aboveTablet">
          <Text $weight={theme.fontWeight.light}>{`${format(parseISO(startTime), "p")} -`}</Text>
          <Text $weight={theme.fontWeight.light}>{format(parseISO(endTime), "p")}</Text>
        </Hidden>
      </TimeCell>
      <InformationCell
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) => {
          if (e.target === meetingRef.current) {
            if (IS_PHONE) {
              window.location.href = `tel:${phoneNumber}`;
            }
          } else {
            navigate(`/patients/${id}`, {
              state: {
                pathname,
                text: "dashboard",
              },
            });
          }
        }}
        dataTestId="appointment_meeting"
      >
        <InformationText $weight={theme.fontWeight.medium}>
          {firstName} {lastName}
        </InformationText>
        <Text
          ref={meetingRef}
          data-testid={IS_PHONE ? "phone" : "medium"}
          $justify="flex-start"
          $color={theme.colors.redesign.b100}
          $weight={theme.fontWeight.medium}
        >
          {IS_PHONE ? (
            <PhoneNumber phone={phoneNumber} country={country} />
          ) : (
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            t(`messages.appointments.types.${medium}`)
          )}
        </Text>
        <InformationText $justify="flex-end" $weight={theme.fontWeight.light}>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(`messages.appointments.types.${purpose}`)}
        </InformationText>
      </InformationCell>
      <ActionCell
        $borderRight
        dataTestId="complete_appointment"
        onClick={event => {
          if (!completeOpen && data.state !== "completed") {
            event.stopPropagation();
            setComplete(true);
          }
        }}
      >
        <CompleteAppointment
          uid={uid}
          onChange={refetch}
          error={removeError}
          setError={setRemoveError}
          close={() => {
            setComplete(false);
            setTimeout(() => setRemoveError(null), 500);
          }}
          visible={completeOpen}
        />
        <StyledCheckmarkIcon $state={data.state} />
      </ActionCell>
      <ActionCell
        dataTestId="remove_appointment"
        onClick={event => {
          if (!deleteOpen) {
            event.stopPropagation();
            setDelete(true);
          }
        }}
      >
        <RemoveAppointment
          uid={uid}
          onChange={refetch}
          error={removeError}
          setError={setRemoveError}
          close={() => {
            setDelete(false);
            setTimeout(() => setRemoveError(null), 500);
          }}
          visible={deleteOpen}
        />
        <StyledCancelIcon />
      </ActionCell>
    </AppointmentRow>
  );
};

export default Appointment;
