import type React from "react";
import { useContext } from "react";

import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import useGetPatientAppointments from "api/hooks/useGetPatientAppointments";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { getAppointmentTitle } from "routes/calendar/components/Calendar/helpers/getAppointmentTitle";
import { AppointmentDetails } from "routes/calendar/components/EventDetails";
import { AppointmentStateIcon } from "routes/calendar/components/shared/AppointmentStateIcon";
import { Table, TableCell, TableHeader, TableMissingContent, TableRow } from "shared/molecules/Table";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import { NoteTypeLabel } from "../SignNotesTable/components/MedicalNote/noteStyles";
import { Text } from "../SignNotesTable/components/MedicalNoteDate/MedicalNoteDate";

export const UpcomingAppointments: React.VFC = () => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const { profile } = useProfileContext();
  const { patient } = useContext(CurrentPatientContext);
  const { selectedAppointment, setSelectedAppointment } = useCalendarContext();
  const theme = useTheme();

  invariant(profile);

  const { data: upcomingAppointments, isLoading } = useGetPatientAppointments(profile.id, patient.id);

  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <TableHeader
              colSpan={2}
              title={t("patients.booked_appointments")}
              notifications={upcomingAppointments?.length}
            />
          </thead>
          <tbody>
            <TableMissingContent loading={isLoading} />
            {upcomingAppointments?.map(appointment => {
              return (
                <TableRow key={appointment.id} onClick={() => setSelectedAppointment(appointment)}>
                  <TableCell>
                    <AppointmentStateIcon state={appointment.state} />
                    <NoteTypeLabel>
                      {getAppointmentTitle({
                        hcpServiceName: appointment.health_care_provider_service_name,
                        purpose: appointment.purpose,
                      })}
                    </NoteTypeLabel>
                  </TableCell>
                  <StyledTableCell>
                    <Text>{format(parseISO(appointment.start_time), "PP")}</Text>
                    <Text style={{ marginLeft: "16px" }}>{`${format(parseISO(appointment.start_time), "p")} - ${format(
                      parseISO(appointment.end_time),
                      "p"
                    )}`}</Text>
                    <StyledFontAwesomeIcon icon={faCircleInfo} color={theme.colors.redesign.db90} />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {selectedAppointment && (
        <AppointmentDetails
          appointment={selectedAppointment}
          navigateToCalendar
          onClose={() => {
            setSelectedAppointment(null);
          }}
        />
      )}
    </>
  );
};

const TableContainer = styled.div`
  margin-top: 32px;
`;

const StyledTableCell = styled(TableCell)`
  text-align: right;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
  margin-left: 8px;

  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;
