import type { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import { QUERY_KEYS } from "types/QueryKeys";

interface Exercise {
  name: string;
  performed_level: {
    number: number;
    sets: number;
    reps: number;
  };
  predicted_level: {
    number: number;
    sets: number;
    reps: number;
  };
  max_level_number: number;
}

interface Includes {
  exercise: Exercise[];
  [type: string]: {
    [activityId: number]: { name: string };
  };
}

export interface Activity {
  comment: string;
  completed_at: string | null;
  content_id: number;
  content_type: string;
  id: number;
  reply: string | null;
  required: boolean;
  premium: boolean | null;
}

export interface Day {
  activities: Activity[];
  date: string;
}

export interface ProtocolWeek {
  days: Day[];
  end_date: string;
  id: number;
  next_id: number | null;
  number: number;
  overdue: boolean;
  overdue_delayed_days: number | null;
  overdue_activities: Activity[];
  previous_id: number;
  start_date: string;
}

interface Translation {
  content_id: number;
  translations: {
    library_id: number;
    title: string;
  };
}

export interface Protocol {
  includes: Includes;
  protocol_week: ProtocolWeek;
  translations: Translation[];
}

export interface ProtocolVars {
  weekInfo: string | undefined;
  patientId: string | undefined;
  treatment_id: number | null;
}

const fetchProtocol = ({ weekInfo, patientId, treatment_id }: ProtocolVars): Promise<AxiosResponse<Protocol>> => {
  const baseUrl =
    weekInfo === "current" ? `patients/${patientId}/protocol_weeks/find_one` : `protocol_weeks/${weekInfo}`;
  const queryString = `?includes=activity_content${weekInfo === "current" ? "&relative_week=current" : ""}`;
  const previousTreatmentParam = treatment_id ? `&treatment_id=${treatment_id}` : "";

  return AxiosInstance.get(baseUrl + queryString + previousTreatmentParam);
};

interface ProtocolQuery {
  response?: AxiosResponse<Protocol>;
  error: unknown;
  isLoading: boolean;
}

const useProtocol = ({ weekInfo, patientId, treatment_id }: ProtocolVars): ProtocolQuery => {
  const { data, error, isLoading } = useQuery(
    [QUERY_KEYS.patient.protocol, { weekInfo, patientId, treatment_id }],
    () => fetchProtocol({ weekInfo, patientId, treatment_id })
  );

  return { response: data, error, isLoading };
};

export default useProtocol;
