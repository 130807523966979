import type React from "react";

import styled from "styled-components";

const StyledBadge = styled.div<{ $collapsed: boolean }>`
  font-family: "Roboto", sans-serif;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  background-color: ${props => props.theme.colors.salmon};
  color: ${props => props.theme.colors.white};
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 8px;
  ${props =>
    props.$collapsed &&
    `
    right: 0px;
    top: 4px;
    color: transparent;
    border-radius: 50%;
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 2px solid white;
  `}
`;

interface Props {
  readonly label?: number;
  readonly dataTestId?: string;
  readonly $collapsed?: boolean;
}

const Badge: React.VFC<Props> = ({ label = 0, dataTestId = "", $collapsed = false }) => {
  const badgeLabel = label >= 100 ? "+99" : label;
  return label > 0 ? (
    <StyledBadge data-testid={dataTestId} $collapsed={$collapsed}>
      {badgeLabel}
    </StyledBadge>
  ) : null;
};
export default Badge;
