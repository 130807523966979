import type React from "react";

import i18n from "i18next";

import MUIDropdown from "shared/atoms/inputs/MUIDropdown";
import type { ValidLocale } from "types/validLocale";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly locale: ValidLocale | undefined;
  readonly onChange?: () => void;
}

const WHO = [
  "doctor",
  "orthopedist",
  "physiotherapist",
  "naprapath",
  "chiropractor",
  "osteopath",
  "nurse",
  "other",
  "no_visit",
];

const PreviousSurveysDropdown: React.VFC<Props> = ({ error, label, name, disabled, required, locale, onChange }) => {
  const getOptions = (fieldName: string) => {
    switch (fieldName) {
      case "structured_fields.previous_surveys_who": {
        return WHO.map(option => {
          return { value: option, label: i18n.t(`patients.notes.fields.previous_surveys_who_options.${option}`) };
        });
      }
      case "structured_fields.previous_surveys_when": {
        return optionsWhen(locale);
      }

      default:
        return [];
    }
  };

  return (
    <MUIDropdown
      error={error}
      label={label}
      name={name}
      options={getOptions(name)}
      disabled={disabled}
      required={required}
      onChange={onChange}
    />
  );
};

export { PreviousSurveysDropdown };

const optionsWhen = (locale: ValidLocale | undefined) => {
  const today = new Date();
  const options = [];
  const extraOptions = ["exclude", "no_visit"];

  for (let i = 0; i < 4; i += 1) {
    const month = today.getMonth() - i;
    const year = today.getFullYear();
    const date = new Date(year, month, 1);

    options.push({
      value: date.toLocaleDateString("en-US", { month: "long" }).toLowerCase(),
      label: date.toLocaleDateString(locale, { month: "long" }),
    });
  }

  extraOptions.forEach(option => {
    options.push({
      value: option,
      label: i18n.t(`patients.notes.fields.previous_surveys_when_options.${option}`),
    });
  });

  return options;
};
