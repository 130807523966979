import { z } from "zod";

import { ActivityTypeSchema } from "../models/ActivityLibraryEntryShow";

export const ActivityLibraryEntrySchema = z.object({
  id: z.string(),
  type: ActivityTypeSchema,
  title: z.string(),
  variation_title: z.string().nullish(),
  image_url: z.string().nullish(),
  order: z.number().optional(),
  parts_count: z.number().optional(),
  level: z.number().optional(),
  max_level: z.number().optional(),
  premium: z.boolean().optional(),
});

export type ActivityLibraryEntry = z.infer<typeof ActivityLibraryEntrySchema>;

export const ActivityLibraryEntriesSchema = z.array(ActivityLibraryEntrySchema);
