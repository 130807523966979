import type React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CrossIcon } from "assets";

interface ActivityTemplateProps {
  children: React.ReactNode;
  className?: string;
}

const ActivityTemplate: React.VFC<ActivityTemplateProps> = ({ children, className }) => {
  const navigate = useNavigate();

  return (
    <>
      <Wrapper data-testid="activity-template">
        <Container className={className}>{children}</Container>
      </Wrapper>
      <ButtonWrapper data-testid="close-button" onClick={() => navigate(-1)}>
        <CrossIcon />
      </ButtonWrapper>
    </>
  );
};

export default ActivityTemplate;

const Wrapper = styled.div`
  position: relative;
  padding: ${props => props.theme.spacing.S_20};
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;

  ${props => props.theme.belowBreakpoint} {
    padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_15};
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${props => props.theme.colors.white};
  max-width: 700px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.S_30};
  ${props => props.theme.belowBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_15};
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
  cursor: pointer;
`;
