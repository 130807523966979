import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CancelRoundIcon } from "assets";
import SearchableSelect from "shared/atoms/inputs/SearchableSelect/SearchableSelect";

import getIcdCodes from "../queries/GetIcdCodes";

import ReferralNoteSearchRow from "./ReferralNoteSearchRow";

const DiagnosisContainer = styled.div`
  flex: 1;
  padding: ${props => props.theme.spacing.S_10} 0;
`;

const IcdCodeList = styled.div`
  margin-top: ${props => props.theme.spacing.S_10};
`;

const IcdCode = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: ${props => props.theme.spacing.S_10};
  margin-bottom: ${props => props.theme.spacing.S_5};
  ${props => props.theme.font.caption}
`;

const DeleteIcon = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.S_5};
  right: ${props => props.theme.spacing.S_5};
  cursor: pointer;
`;

export type IcdCodeType = {
  value: string;
  label: string;
};

interface Props {
  setIcdCodes: (icdCodes: IcdCodeType[]) => void;
  icdCodes: IcdCodeType[];
}

const ReferralNoteDiagnosisCode: React.VFC<Props> = ({ icdCodes, setIcdCodes }) => {
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<IcdCodeType[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue === "") {
      setSearchResult([]);
    } else {
      getIcdCodes(searchValue)
        .then(response => {
          setSearchResult(response as IcdCodeType[]);
        })
        .catch(() => {
          setSearchResult([]);
        });
    }
  }, [searchValue]);

  const onItemClick = (item: IcdCodeType) => {
    const doesAlreadyExist = icdCodes.some(selected => selected.value === item.value);
    if (!doesAlreadyExist) {
      const newItem = [item];
      setIcdCodes([...icdCodes, ...newItem]);
    }
  };

  const onNoteDelete = (codeToDelete: IcdCodeType) => {
    const newArray = icdCodes.filter(item => item !== codeToDelete);
    setIcdCodes(newArray);
  };

  return (
    <DiagnosisContainer>
      <SearchableSelect
        onItemClick={onItemClick}
        placeholder={t("doctor.referrals.diagnosis_code")}
        listData={searchResult}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        renderItem={rowData => <ReferralNoteSearchRow item={rowData} />}
      />
      <IcdCodeList>
        {icdCodes.map(icdCode => {
          return (
            <IcdCode key={icdCode.value}>
              <ReferralNoteSearchRow item={icdCode} />
              <DeleteIcon onClick={() => onNoteDelete(icdCode)}>
                <CancelRoundIcon />
              </DeleteIcon>
            </IcdCode>
          );
        })}
      </IcdCodeList>
    </DiagnosisContainer>
  );
};

export default ReferralNoteDiagnosisCode;
