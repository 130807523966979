import type { ReactElement } from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { PatientsColumnsContext } from "utils/contexts";

import type { ColumnType } from "../dataColumns";
import { columnLabel } from "../dataColumns";

import { StyledCheckBox, Title } from "./helpers";

export default (): ReactElement => {
  const { enabledColumns, toggleColumn } = useContext(PatientsColumnsContext);
  const { t } = useTranslation();

  return (
    <>
      <Title>{t(`patients.customize_columns_title`)}</Title>

      {Object.keys(enabledColumns).map(column => (
        <StyledCheckBox
          key={`filter-${column}`}
          name={`filter-${column}`}
          label={columnLabel(t, column as ColumnType)}
          checked={enabledColumns[column]}
          onChange={() => {
            toggleColumn(column);
          }}
        />
      ))}
    </>
  );
};
