import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import { QUERY_KEYS } from "types/QueryKeys";

const fetchEventCards = async () => AxiosInstance.get(`dashboard/actions`);

interface EventCardsQuery {
  data?: { [key: string]: number };
  error: unknown;
  refetch: () => void;
}

const useEventCards = (): EventCardsQuery => {
  const { data, error, refetch } = useQuery(QUERY_KEYS.dashboard.eventCards, fetchEventCards);

  return { data: data?.data, error, refetch };
};

export default useEventCards;
