import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetMedicalReferralsResponse } from "api/types/GetMedicalReferralsResponse";
import useApiClient from "api/useApiClient";

export const getMedicalReferralsQueryKey = (patientId: number) => ["GetMedicalReferralsQuery", patientId] as const;

export const useMedicalReferrals = (patientId: number): UseQueryResult<GetMedicalReferralsResponse, Error> => {
  const client = useApiClient();
  return useQuery(getMedicalReferralsQueryKey(patientId), () => client.getMedicalReferrals(Number(patientId)));
};
