import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPlansOfCareArgs, GetPlansOfCareResponse } from "api/types/GetPlansOfCare";
import useApiClient from "api/useApiClient";

export type UseGetPlansOfCareQueryKey = readonly [
  query: "GetPlansOfCare",
  patientId: number,
  acknowledgedByPT?: boolean
];

export const usePlansOfCareQuery = (
  args: GetPlansOfCareArgs,
  options?: UseQueryOptions<GetPlansOfCareResponse, Error, GetPlansOfCareResponse, UseGetPlansOfCareQueryKey>
): UseQueryResult<GetPlansOfCareResponse, Error> => {
  const client = useApiClient();

  return useQuery(
    ["GetPlansOfCare", args.patientId, args.acknowledgedByPT ?? false],
    () => client.getPlansOfCare(args),
    options
  );
};
