import { useEffect, useState } from "react";

import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import type { Patient } from "../queries/fetchPatients";
import { TherapistAssignmentRole } from "../queries/TherapistAssignmentRole";

import stringifyJoint from "./stringifyJoint";

const filters = [
  "active",
  "inactive",
  "deleted",
  "new_patients",
  "new_comments",
  "new_messages",
  "appointment_to_schedule",
  "prio",
  "week",
  "primary_joint",
  "selfcare",
  "guided_care",
  TherapistAssignmentRole.MainTherapist,
  TherapistAssignmentRole.SubstituteTherapist,
  "awaiting_treatment_ready",
] as const;

export type Filter = typeof filters[number];

type FilterState = {
  active: boolean;
  from?: number;
  to?: number;
  selection?: string[];
};

const initialState: Record<Filter, FilterState> = {
  active: { active: false },
  inactive: { active: false },
  deleted: { active: false },
  new_patients: { active: false },
  new_comments: { active: false },
  new_messages: { active: false },
  appointment_to_schedule: { active: false },
  prio: { active: false },
  week: {
    active: false,
    from: 1,
    to: 3,
  },
  primary_joint: {
    active: false,
    selection: [],
  },
  main_therapist: { active: false },
  substitute_therapist: { active: false },
  selfcare: { active: false },
  guided_care: { active: false },
  awaiting_treatment_ready: { active: false },
};

const isFilter = (val: string | string[] | null): val is Filter => {
  if (typeof val === "string") {
    return filters.includes(val as Filter);
  }
  return false;
};

export type Filters = {
  activeFilters: Filter[];
  clearAll: () => void;
  filterLabel: (f: Filter) => string;
  getFilterState: (key: Filter) => FilterState;
  setFilter: (key: Filter, state: FilterState) => void;
  toggleFilter: (f: Filter) => void;
  matchesActiveFilters: (p: Patient) => boolean;
};

export default (): Filters => {
  const [filterStates, setFilterStates] = useState(initialState);
  const location = useLocation();
  const { filter: queryFilter } = queryString.parse(location.search);
  const { t } = useTranslation();
  const activeFilters = filters.filter(f => filterStates[f].active);

  useEffect(() => {
    if (isFilter(queryFilter)) {
      setFilterStates((state: Record<Filter, FilterState>) => ({
        ...initialState,
        [queryFilter]: {
          ...state[queryFilter],
          active: true,
        },
      }));
    }
  }, [setFilterStates, queryFilter]);

  return {
    activeFilters,

    clearAll: () => setFilterStates(initialState),

    filterLabel: (filter: Filter) => {
      switch (filter) {
        case "week": {
          const { from, to } = filterStates[filter];
          if (from === 0 && to === 0) return t(`patients.introduction`);
          if (from === to) return `${t("patients.week")} ${from}`;
          if (to && to < 100) return `${t("patients.week")} ${from} to ${to}`;
          return `${t("patients.week")} ${from} ${t("patients.and_up")}`;
        }
        case "active":
        case "inactive":
        case "deleted":
        case "awaiting_treatment_ready":
          return t(`patients.statuses.${filter}`);
        case "primary_joint":
          return t("patients.ailment");
        default:
          return t(`patients.${filter}`);
      }
    },

    getFilterState: (key: Filter) => filterStates[key],

    setFilter: (key: Filter, state: FilterState) => {
      setFilterStates({ ...filterStates, [key]: { ...filterStates[key], ...state } });
    },

    toggleFilter: (key: Filter) => {
      setFilterStates({ ...filterStates, [key]: { ...filterStates[key], active: !filterStates[key].active } });
    },

    matchesActiveFilters: (patient: Patient) => {
      return activeFilters.every(filter => {
        switch (filter) {
          case "active":
            return (
              patient.status === "active" ||
              (activeFilters.includes("inactive") && patient.status === "inactive") ||
              activeFilters.includes("deleted")
            );
          case "inactive":
            return (
              patient.status === "inactive" ||
              (activeFilters.includes("active") && patient.status === "active") ||
              activeFilters.includes("deleted")
            );
          case "deleted":
            return patient.deleted === true;
          case "week":
            return (
              (filterStates.week.from !== undefined ? patient.week >= filterStates.week.from : true) &&
              (filterStates.week.to !== undefined ? patient.week <= filterStates.week.to : true)
            );
          case "new_patients":
            return patient.newPatient;
          case "appointment_to_schedule":
            return patient.appointmentToSchedule;
          case "new_comments":
            return patient.newComments;
          case "new_messages":
            return patient.newMessages;
          case "prio":
            return patient.prio;
          case "primary_joint": {
            if (!patient.primaryJoint) {
              return true;
            }
            const jointId = stringifyJoint(patient.primaryJoint);

            return filterStates.primary_joint.selection?.includes(jointId);
          }
          case TherapistAssignmentRole.MainTherapist:
            return (
              patient.therapistAssignmentRole === TherapistAssignmentRole.MainTherapist ||
              (patient.therapistAssignmentRole === TherapistAssignmentRole.SubstituteTherapist &&
                activeFilters.includes(TherapistAssignmentRole.SubstituteTherapist))
            );
          case TherapistAssignmentRole.SubstituteTherapist:
            return (
              patient.therapistAssignmentRole === TherapistAssignmentRole.SubstituteTherapist ||
              (patient.therapistAssignmentRole === TherapistAssignmentRole.MainTherapist &&
                activeFilters.includes(TherapistAssignmentRole.MainTherapist))
            );
          case "selfcare":
            return (
              patient.premiumType === "selfcare" ||
              (activeFilters.includes("guided_care") && patient.premiumType === "guided_care")
            );
          case "guided_care":
            return (
              patient.premiumType === "guided_care" ||
              (activeFilters.includes("selfcare") && patient.premiumType === "selfcare")
            );
          case "awaiting_treatment_ready":
            return patient.awaitingTreatmentReady;
          default:
            return true;
        }
      });
    },
  };
};
