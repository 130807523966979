import { z } from "zod";

const SwitchLateralLocationArgsSchema = z.object({
  patientID: z.number(),
  data: z.object({
    lateral_location: z.string(),
  }),
});
export type SwitchLateralLocationArgs = z.infer<typeof SwitchLateralLocationArgsSchema>;

export const SwitchLateralLocationSchema = z.object({
  status: z.string(),
});
export type SwitchLateralLocation = z.infer<typeof SwitchLateralLocationSchema>;
