import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPainGraphArgs, GetPainGraphResponse } from "api/types/GetPainGraph";
import useApiClient from "api/useApiClient";

export const GET_PAIN_GRAPH_DATA_QUERY_KEY = "getPainGraphData";

export const useGetPainGraphData = (
  args: GetPainGraphArgs,
  options?: UseQueryOptions<GetPainGraphResponse, Error, GetPainGraphResponse, Array<string | number | null>>
): UseQueryResult<GetPainGraphResponse, Error> => {
  const client = useApiClient();

  return useQuery(
    [GET_PAIN_GRAPH_DATA_QUERY_KEY, args.patientId, args.treatment_id],
    () => client.getPainGraphData(args),
    options
  );
};
