import { useCallback, useEffect, useRef, useState } from "react";

import type { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";

interface useVideoJSResult {
  Video: () => JSX.Element;
  ready: boolean;
  player: VideoJsPlayer | null;
}

const useVideoJS = (videoJsOptions: VideoJsPlayerOptions): useVideoJSResult => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const [ready, setReady] = useState(false);
  const changedKey = JSON.stringify(videoJsOptions);
  const player = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    import("video.js").then(videojs => {
      if (videoNode.current) {
        player.current = videojs.default(videoNode.current, videoJsOptions) as VideoJsPlayer;
        player.current.ready(() => {
          setReady(true);
        });
      }
    });

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [changedKey]);

  const Video = useCallback(
    () => (
      <div data-vjs-player key={changedKey}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoNode} controls preload="none" className="video-js" />
      </div>
    ),
    [changedKey]
  );

  return { Video, ready, player: player.current };
};

export default useVideoJS;
