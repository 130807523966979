import type React from "react";

import styled from "styled-components";

interface DropdownOptionProps {
  id?: string;
  children: React.ReactNode;
  onClick?: () => void;
  row: number;
  dataTestId?: string;
  disabled?: boolean;
  border?: boolean;
}
const DropdownOption: React.VFC<DropdownOptionProps> = ({
  id,
  children,
  onClick = () => undefined,
  row,
  dataTestId,
  disabled = false,
  border,
}) => {
  return (
    <DropdownOptionElement id={id} onClick={onClick} data-testid={dataTestId} disabled={disabled}>
      <DropdownOptionElementContainer
        style={{ ...(row === 0 ? { borderTop: "none" } : {}) }}
        disabled={disabled}
        $border={border}
      >
        {children}
      </DropdownOptionElementContainer>
    </DropdownOptionElement>
  );
};

const DropdownOptionElementContainer = styled.div<{ disabled?: boolean; $border?: boolean }>`
  min-width: 125px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 4px;
  font-weight: ${props => props.theme.fontWeight.regular};
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  border-top: ${({ theme, $border }) => ($border ? `1px solid ${theme.colors.greys.silver}` : "none")};
  ${props => (props.disabled ? `color: ${props.theme.colors.greys.pinkish};` : "")}
  user-select: none;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  margin: 0 12px;
`;

const DropdownOptionElement = styled.li<{ disabled?: boolean }>`
  min-height: 45px;
  list-style: none;

  :hover {
    cursor: pointer;
    background: ${props =>
      props.disabled ? props.theme.colors.redesign.disabled_grey : props.theme.colors.redesign.b30};
  }

  svg {
    ${props => (props.disabled ? "opacity: 0.75" : "")}
  }
`;

export default DropdownOption;
