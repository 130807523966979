import type React from "react";

import styled from "styled-components";

import { useNotificationsContext } from "contexts/NotificationsContext";

import ExtendedNotification from "../ExtendedNotification/ExtendedNotification";

const NotificationsPane: React.VFC = () => {
  const { notifications, closeNotification } = useNotificationsContext();

  return (
    <RootContainer data-testid="notificationpane-root">
      {notifications
        .slice(0)
        .reverse()
        .map(notification => {
          const { onClick, id, ...rest } = notification;

          return (
            <ExtendedNotification
              {...rest}
              id={id}
              key={id}
              onClose={() => closeNotification(id)}
              onClick={() => {
                if (onClick) {
                  onClick();
                  closeNotification(id);
                }
              }}
            />
          );
        })}
    </RootContainer>
  );
};

export default NotificationsPane;

const RootContainer = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
`;
