import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useLocalizedDate from "utils/date";
import isNonEmptyString from "utils/string/is-non-empty-string";

type DateLabelProps = {
  date: string | null | undefined;
};

const Text = styled.div`
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const DateLabel: React.VFC<DateLabelProps> = ({ date: dateInput }) => {
  const { t } = useTranslation();
  const { format, formats, parseISO, isYesterday, isToday } = useLocalizedDate();

  if (!isNonEmptyString(dateInput)) {
    return null;
  }

  const date = parseISO(dateInput);
  const formatDate = () => {
    if (isYesterday(date)) return t("date_label.yesterday");
    if (isToday(date)) return t("date_label.today");
    return format(date, formats.LONG_LOCALIZED_DATE_PP);
  };
  return <Text title={format(date, formats.LONG_LOCALIZED_DATE_TIME_PPpp)}>{formatDate()}</Text>;
};

export default DateLabel;
