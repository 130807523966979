import type React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCalendarContext } from "contexts/CalendarContext";
import { PrimaryButton } from "shared/atoms/Button";
import { Notification } from "shared/atoms/Notification";

import { Container } from "../misc";

interface Props {
  error: string | null;
  bookAppointment: () => void;
}

export const Confirmation: React.VFC<Props> = ({ error, bookAppointment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveForm } = useCalendarContext();

  useEffect(() => {
    bookAppointment();
  }, []);

  return (
    <Container>
      <div style={{ marginBottom: "32px" }}>{t("booking.manager_booking.screens.confirmation.inform_patient")}</div>

      <PrimaryButton
        onClick={() => {
          setActiveForm(null);
          navigate("/calendar");
        }}
        style={{ alignSelf: "center" }}
      >
        {t("buttons.close")}
      </PrimaryButton>

      {error && (
        <Notification variant="danger" style={{ margin: "16px 0 20px 0" }}>
          {error}
        </Notification>
      )}
    </Container>
  );
};
