import type React from "react";
import { useEffect } from "react";

import { useMatch, useRoutes } from "react-router-dom";

import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import { AdminBillingView } from "./views/AdminBillingView";

const InviteRoutes: React.VFC = () => {
  const match = useMatch("*");
  const offsetTop = usePageContentOffsetTop();
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <Container $pageContentOffsetTop={offsetTop}>
          <AdminBillingView />
        </Container>
      ),
    },
  ]);

  useEffect(() => {
    if (match?.pathname) {
      AnalyticsService.viewedPage(AnalyticsPages.ADMIN_BILLING, match?.pathname);
    }
  }, [match?.pathname]);

  return routes;
};

export default InviteRoutes;
