import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { abortBankID, checkBankID, initBankID } from "routes/login/queries";
import type { CheckResponse, LegacyProgressStatus } from "routes/login/queries/api";
import getSignedOutParams from "routes/login/utils/getSignedOutParams";
import { translateStatus } from "routes/login/utils/translateStatus";
import type { Device } from "utils/hooks";
import { useDevice } from "utils/hooks";

import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { Container, Instructions, LoginWrapper, Status } from "../components/styledComponents";

const createReturnUrl = (device: Device, order_reference: string) => {
  if (device.isChromeOnAppleDevice) {
    return encodeURIComponent("googlechrome://");
  }
  if (device.isFirefoxOnAppleDevice) {
    return encodeURIComponent("firefox://");
  }
  if (device.isOperaTouchOnAppleDevice) {
    return encodeURIComponent(
      `${window.location.href.replace("http", "touch-http")}?order_ref=${order_reference}#initiated=true`
    );
  }

  return encodeURIComponent(`${window.location.href}?order_ref=${order_reference}#initiated=true`);
};

export const OpenMobileApp: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const device = useDevice();
  const confirmation_token = new URLSearchParams(location.search).get("t");
  const { redirectUrl } = getSignedOutParams(location);
  const successUrl = redirectUrl || "/dashboard";
  const [status, setStatus] = useState<LegacyProgressStatus | null>(null);
  const [openBankIDUrl, setOpenBankIDUrl] = useState<string | null>(null);
  const orderRefQueryParam = new URLSearchParams(location.search).get("order_ref");

  useEffect(() => {
    const handleFlowUpdate = (data: CheckResponse) => {
      setStatus(data.legacy_progress_status);
    };

    if (!location.hash.includes("initiated")) {
      initBankID({
        confirmation_token: confirmation_token || undefined,
        flowType: "mobile",
        successUrl,
        navigate,
        onInitDone: data => {
          const { auto_start_token, order_reference } = data;
          const returnUrl = createReturnUrl(device, order_reference);
          const url = `https://app.bankid.com/?autostarttoken=${auto_start_token}&redirect=${returnUrl}`;
          setOpenBankIDUrl(url);
          window.location.href = url;
        },
        onUpdate: handleFlowUpdate,
      });
    } else {
      checkBankID({
        flowType: "mobile",
        successUrl,
        transactionId: orderRefQueryParam ? decodeURIComponent(orderRefQueryParam.toString()) : "",
        navigate,
        onUpdate: handleFlowUpdate,
      });
    }

    return () => {
      abortBankID();
    };
  }, []);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.bankid.title")}>
          {t("login.bankid.open_app.header_text")}
        </LoginInfoContainer>
        <Container>
          <Instructions $center>{t("login.bankid.open_app.instructions")}</Instructions>
          {openBankIDUrl && <a href={openBankIDUrl}>{t("login.bankid.open_app.button")}</a>}
          {status && <Status>{translateStatus({ flowType: "mobile", status })}</Status>}
        </Container>
      </LoginContainer>
    </LoginWrapper>
  );
};
