import type React from "react";
import { useContext } from "react";

import { addMinutes } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { getCareEventsSuggestedQueryKey } from "api/hooks/useGetCareEventsSuggested";
import useGetTreatmentActivityLogCategories from "api/hooks/useGetTreatmentActivityLogCategories";
import { usePostTreatmentActivityLog } from "api/hooks/usePostTreatmentActivityLog";
import type { TreatmentActivityLogCategory, TreatmentActivityLogPayload } from "api/schemas/TreatmentActivityLog";
import { Content, Header } from "routes/calendar/components/EventDetails/styles";
import { Form, Input } from "routes/calendar/components/Forms/styles";
import { BaseButton } from "shared/atoms/BaseButton";
import { MUIDropdown } from "shared/atoms/inputs";
import Popup from "shared/atoms/Popup";
import { CurrentPatientContext } from "utils/contexts";
import { reportError } from "utils/errorReporting";

import { FormState } from "../PatientHeader/PatientHeaderInfo/PatientDataList/TreatmentSwitch/FormState";

const durationOptions = [
  { value: "1", label: "1 min" },
  { value: "2", label: "2 mins" },
  { value: "5", label: "5 mins" },
  { value: "10", label: "10 mins" },
  { value: "15", label: "15 mins" },
];

type FormData = {
  category: TreatmentActivityLogCategory;
  duration: string;
};

interface Props {
  onClose: () => void;
  refetch: () => void;
}

export const ActivityLogForm: React.VFC<Props> = ({ onClose, refetch }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { patient } = useContext(CurrentPatientContext);

  const { data: activityCategoriesData } = useGetTreatmentActivityLogCategories();
  const postTreatmentActivityLog = usePostTreatmentActivityLog();

  const activityCategories =
    activityCategoriesData?.map(category => ({ label: category.description, value: category.name })) || [];

  const form = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((formData: FormData) => {
    try {
      const startTime = new Date();
      const endTime = addMinutes(startTime, parseInt(formData.duration, 10));
      const data: TreatmentActivityLogPayload = {
        category: formData.category,
        patient_id: patient.id,
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
      };

      postTreatmentActivityLog.mutateAsync(
        { data },
        {
          onSuccess: () => {
            refetch();
            queryClient.invalidateQueries(getCareEventsSuggestedQueryKey([patient.id]));
            setTimeout(onClose, 2000);
          },
        }
      );
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  });

  return (
    <Popup onClose={onClose} fullBgOpacity noPadding={false} showCloseButton>
      <StyledContent>
        <Header>{t("patients.activity_log.form.header")}</Header>
        <FormProvider {...form}>
          <Form onSubmit={onSubmit} noValidate>
            <Input>
              <MUIDropdown
                label={t("patients.activity_log.form.category")}
                name="category"
                options={activityCategories}
                required
                error={errors.category && t("errors.field.required")}
              />
            </Input>
            <Input>
              <MUIDropdown
                label={t("patients.activity_log.form.duration")}
                name="duration"
                options={durationOptions}
                required
                error={errors.duration && t("errors.field.required")}
              />
            </Input>
            <BaseButton
              text="Save"
              type="submit"
              disabled={postTreatmentActivityLog.isLoading || postTreatmentActivityLog.isSuccess}
              style={{ marginBottom: "16px" }}
              uppercase
            />
          </Form>
        </FormProvider>

        <FormState
          formState={postTreatmentActivityLog.status}
          messages={{ loading: t("patients.activity_log.form.loading") }}
        />
      </StyledContent>
    </Popup>
  );
};

const StyledContent = styled(Content)`
  padding: 24px 26px;
`;
