import type React from "react";

import {
  faArrowsToCircle,
  faCalendarClock,
  faEnvelope,
  faList,
  faPhone,
  faUser,
  faUserPen,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import type { HcpServiceManager } from "api/schemas/HcpService";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton } from "shared/atoms/Button";
import useLocalizedDate from "utils/date";

import { BOOKING_SCREENS } from "../AdminBookingForm";
import { Container } from "../misc";

const iconStyle = { height: "18px", width: "18px" };

// TODO: Fix type
interface Props {
  email: string;
  endTime: string;
  mode: string;
  personal_number: string | null;
  personnel: string | null;
  phone: string;
  service: HcpServiceManager | undefined;
  startTime: string;
}

export const Summary: React.VFC<Props> = ({
  email,
  endTime,
  mode,
  personal_number,
  personnel,
  phone,
  service,
  startTime,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { parseISO, format } = useLocalizedDate();
  const { profile } = useProfileContext();
  const theme = useTheme();

  invariant(profile);

  return (
    <Container>
      <Row>
        <FontAwesomeIcon icon={faUser} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.personal_number")}</Label>
        <Value>{personal_number}</Value>
      </Row>

      <Row>
        <FontAwesomeIcon icon={faArrowsToCircle} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.mode")}</Label>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Value>{t(`booking.services.mode_options.${mode}`)}</Value>
      </Row>

      <Row>
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon icon={faList} color={theme.colors.redesign.db90} style={iconStyle} />
          <Label>{t("booking.manager_booking.summary.service")}</Label>
        </div>
        <Value>{service && service?.name}</Value>
      </Row>

      <Row>
        <FontAwesomeIcon icon={faCalendarClock} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.start_time")}</Label>
        <Value>
          {startTime && endTime && `${format(parseISO(startTime), "Y-MM-dd, p")} - ${format(parseISO(endTime), "p")}`}
        </Value>
      </Row>

      <Row>
        <FontAwesomeIcon icon={faUserPen} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.personnel")}</Label>
        <Value>{personnel}</Value>
      </Row>

      <Row>
        <FontAwesomeIcon icon={faPhone} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.phone")}</Label>
        <Value>{phone}</Value>
      </Row>

      <Row>
        <FontAwesomeIcon icon={faEnvelope} color={theme.colors.redesign.db90} style={iconStyle} />
        <Label>{t("booking.manager_booking.summary.email")}</Label>
        <Value>{email}</Value>
      </Row>

      <PrimaryButton
        onClick={() => {
          navigate(`../${BOOKING_SCREENS.CONFIRMATION}`);
        }}
        style={{ alignSelf: "center", marginTop: 16 }}
      >
        {t("booking.buttons.continue")}
      </PrimaryButton>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.redesign.db90};
  font-size: 18px;

  svg {
    width: 18px;
    margin-right: 16px;

    path {
      fill: ${({ theme }) => theme.colors.redesign.db90};
    }
  }
`;

const Label = styled.div`
  margin-right: 4px;
  font-weight: 300;
`;

const Value = styled.div`
  font-weight: 400;
`;
