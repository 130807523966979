import type React from "react";
import { useEffect } from "react";

import { useMatch, useParams } from "react-router-dom";
import styled from "styled-components";

import ChatWindow from "routes/messages/components/ChatWindow";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

const Messages: React.VFC = () => {
  const { patientId } = useParams<{ patientId?: string }>();
  const match = useMatch("*");
  const pageContentOffsetTop = usePageContentOffsetTop();

  useEffect(() => {
    if (match) AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.MESSAGES, match.pathname);
  }, [match?.pathname]);

  return (
    <ChatContainer $pageContentOffsetTop={pageContentOffsetTop}>
      <ChatWindow selectedPatient={Number(patientId)} />
    </ChatContainer>
  );
};

type ChatContainerProps = {
  $pageContentOffsetTop: number;
};

const ChatContainer = styled.div<ChatContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.greys.light2};
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  height: 100%;
  min-height: 200px;
  max-height: calc(100vh - 142px);
  overflow: hidden;
`;

export default Messages;
