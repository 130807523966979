import type { FC } from "react";
import { useMemo } from "react";

import { ApiClient } from "./ApiClient";
import { ApiClientContext } from "./ApiClientContext";
import { client as transport, clientLeifGPT as transportLeifGPT, clientV2 as transportV2 } from "./AxiosClient";

export const ApiClientProvider: FC = ({ children }) => {
  const client = useMemo(() => new ApiClient(transport, transportV2, transportLeifGPT), []);

  return <ApiClientContext.Provider value={client}>{children}</ApiClientContext.Provider>;
};

export default ApiClientProvider;
