import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { PhysioIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";
import TextArea from "shared/atoms/inputs/TextArea";

import { OnboardingButtons, OnboardingHeader, OnboardingMessage } from "../components";

const StyledPhysioIcon = styled(PhysioIcon)`
  display: block;
  margin: ${props => props.theme.spacing.S_20} auto 0;
`;

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing.S_30};
`;

const AboutYou = (): JSX.Element => {
  const { profile, setProfile } = useProfileContext();
  const [uploadError, setUploadError] = useState<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const putTherapistMutation = usePutTherapistMutation();

  invariant(profile);

  const { handleSubmit, register } = useForm({
    defaultValues: {
      profile_bio: profile.therapist_profile.bio || "",
    },
  });

  const onSubmit = (dataFromForm: { profile_bio: string }) => {
    if (!dataFromForm.profile_bio.trim()) {
      setUploadError(t("onboarding.3.error"));
      return;
    }

    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        bio: dataFromForm.profile_bio,
      },
    };

    const variables = { data, therapistId };

    putTherapistMutation.mutate(variables, {
      onError(error) {
        setUploadError(String(error));
      },
      onSuccess(response) {
        setProfile(response);
        navigate("/onboarding/complete");
      },
    });
  };

  return (
    <>
      <StyledPhysioIcon />

      <OnboardingHeader>{t("onboarding.3.title")}</OnboardingHeader>

      <OnboardingMessage data-testid="onboarding-about-you-body">{t("onboarding.3.body")}</OnboardingMessage>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          {...register("profile_bio")}
          rows={7}
          maxLength={700}
          error={uploadError}
          data-testid="onboarding-about-you-textarea"
        />

        <OnboardingButtons>
          <SecondaryButton
            data-testid="onboarding-about-you-back-btn"
            onClick={() => navigate("/onboarding/profile-picture")}
          >
            <span>{t("buttons.back")}</span>
          </SecondaryButton>

          <PrimaryButton data-testid="onboarding-about-you-next-btn" type="submit">
            <span>{t("buttons.next")}</span>
          </PrimaryButton>
        </OnboardingButtons>
      </StyledForm>
    </>
  );
};

export default AboutYou;
