import type { ChangeEvent } from "react";
import type React from "react";

import { createFilterOptions } from "@material-ui/lab";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Payer } from "api/models/CareEvent";
import MUIAutocomplete from "shared/atoms/inputs/MUIAutocomplete";
import { MenuItem, SelectedItem } from "shared/atoms/inputs/MUIMultiselectDropdown";
import theme from "theme";
import { useWindowSize } from "utils/hooks";
import { getFieldOptionKey, kvaCodesForPayer } from "utils/medicalNotes";

interface Props {
  readonly disabled?: boolean;
  readonly error?: string;
  readonly label?: string;
  readonly max?: number;
  readonly name: string;
  readonly required?: boolean;
  readonly payer?: Payer;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: ChangeEvent<{}>) => void;
}

const KVAAutocomplete: React.VFC<Props> = ({ disabled, error, label, max, name, required, payer, onChange }) => {
  const { setValue } = useFormContext();
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const isDesktop = width > parseInt(theme.breakpoint, 10);

  const validate =
    required && max
      ? {
          max_kva_codes: (v: string[]) => v.length <= max,
        }
      : undefined;

  const options = payer?.payer_type ? kvaCodesForPayer(payer.payer_type) : [];

  return (
    <MUIAutocomplete
      disableCloseOnSelect
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      options={options}
      required={required}
      validate={validate}
      onChange={(event, value) => {
        setValue(name, value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (onChange) onChange(event);
      }}
      filterOptions={createFilterOptions({
        stringify: (option: string) => {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return t(getFieldOptionKey(name, option)) as string;
        },
      })}
      renderOption={option => (
        <StyledMenuItem
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={`${t(getFieldOptionKey(name, option))} ${t(getFieldOptionKey(name, `${option}_description`))}`}
          name={name}
          option={option}
        />
      )}
      renderTag={(valueArray, getTagProps) =>
        valueArray.map((value, i) => (
          <SelectedItem
            key={value}
            label={
              isDesktop
                ? // FIXME: type translation
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `${t(getFieldOptionKey(name, value))} ${t(getFieldOptionKey(name, `${value}_description`))}`
                : // FIXME: type translation
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (t(getFieldOptionKey(name, value)) as string)
            }
            name={name}
            option={value}
            tagProps={getTagProps({ index: i })}
          />
        ))
      }
    />
  );
};

export { KVAAutocomplete };

const StyledMenuItem = styled(MenuItem)`
  ${props => props.theme.belowBreakpoint} {
    height: 100%;
  }
`;
