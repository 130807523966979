import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import type { UseQueryResult } from "react-query";
import type { TooltipProps } from "recharts";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent.d";
import type { Props as LabelProps } from "recharts/types/component/Label.d";
import type { AxisDomainItem, CartesianViewBox, ViewBox } from "recharts/types/util/types.d";
import { ThemeContext } from "styled-components";

import type { FunctionalityHistory } from "api/models/FunctionalityHistory";
import type { Reports } from "api/models/Reports";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { CurrentPatientContext } from "utils/contexts";

import {
  CardFooter,
  ChartHeader,
  ChartLegend,
  ChartLegendCircle,
  ChartWrapper,
  Container,
  Content,
  Empty,
  FooterContent,
  FooterSide,
  GoalRect,
  GoalText,
  SmallSection,
  StyledArrow,
  Tooltip as StyledTooltip,
} from "./helpers";

interface Props {
  type: string;
  goal: number | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feelingResponse?: UseQueryResult<any[], Error>;
  painResponse?: UseQueryResult<Reports[], Error>;
  physicalFunctionResponse?: UseQueryResult<FunctionalityHistory, Error>;
}

const toNumber = (val: string | number): number => (typeof val === "string" ? parseInt(val, 10) : val);

const Chart: React.VFC<Props> = ({ type, goal, feelingResponse, painResponse, physicalFunctionResponse }) => {
  const { t } = useTranslation();
  const { patient } = useContext(CurrentPatientContext);
  const theme = useContext(ThemeContext);

  /* Variables */
  const feelingOrPain = type === "feeling" || type === "pain";
  const goalLineColor = theme.colors.graph.goal;
  const lineColor = feelingOrPain ? "pain" : "function";
  let currentWeek = 0;
  let currentValue = 0;
  const graphData: { [key: string]: number }[] = [];
  let progress: (string | number)[] = [];
  let isError: boolean | undefined;
  let isLoading: boolean | undefined;
  const isGoalDefined = goal !== undefined && goal !== null;

  const calculateProgress = (start?: number, end?: number) => {
    if (start === undefined || end === undefined) return [0, 0];
    const difference = end - start;
    const percentageDifference = ((end / start - 1) * 100).toFixed(0);
    return [difference, percentageDifference];
  };

  const handlePainGraphData = () => {
    if (
      (patient?.specific_joint_pain || patient?.specific_joint_pain === 0) &&
      // eslint-disable-next-line no-prototype-builtins
      painResponse?.data?.find(week => week.hasOwnProperty("general_feeling"))
    ) {
      graphData.push({ week: 0, specific_joint_pain: patient?.specific_joint_pain });
    }

    painResponse?.data?.forEach(dataPoint => {
      if (typeof dataPoint.specific_joint_pain === "number") {
        graphData.push({ week: dataPoint.week, specific_joint_pain: dataPoint.specific_joint_pain });
      }
    });

    const startValue = patient?.specific_joint_pain;
    currentValue = graphData[0]?.specific_joint_pain ?? 0;
    currentWeek = graphData[0]?.week ?? 0;
    progress = calculateProgress(startValue, currentValue);
    isError = painResponse?.isError;
    isLoading = painResponse?.isLoading;
  };

  const handlePhysicalFunctionData = () => {
    physicalFunctionResponse?.data?.time_series.forEach(dataPoint => {
      if (dataPoint.repetitions) {
        graphData.push({ week: dataPoint.week, repetitions: dataPoint.repetitions });
      }
    });

    const startValue = graphData[0]?.repetitions;
    currentValue = graphData[graphData.length - 1]?.repetitions ?? 0;
    currentWeek = graphData[graphData.length - 1]?.week ?? 0;
    progress = calculateProgress(startValue, currentValue);
    isError = physicalFunctionResponse?.isError;
    isLoading = physicalFunctionResponse?.isLoading;
  };

  const handleFeelingData = () => {
    feelingResponse?.data?.forEach(dataPoint => {
      if (dataPoint.general_feeling) {
        graphData.push({ week: dataPoint.week, general_feeling: dataPoint.general_feeling });
      }
    });

    const startValue = graphData[graphData.length - 1]?.general_feeling;
    currentValue = graphData[0]?.general_feeling ?? 0;
    currentWeek = graphData[0]?.week ?? 0;
    progress = calculateProgress(startValue, currentValue);
    isError = feelingResponse?.isError;
    isLoading = feelingResponse?.isLoading;
  };

  if (type === "pain") {
    handlePainGraphData();
  } else if (type === "feeling") {
    handleFeelingData();
  } else {
    handlePhysicalFunctionData();
  }

  const generateArrow = (trend: number | string, color: string) => {
    if (toNumber(trend) > 0) return <StyledArrow data-testid="increase" $color={color} $increase />;
    if (toNumber(trend) < 0) return <StyledArrow data-testid="decrease" $color={color} $decrease />;
    return <StyledArrow data-testid="no-change" $color={color} />;
  };

  const FunctionTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length !== 0) {
      return (
        <StyledTooltip>
          <div>{t("common.week_num", { number: label })}</div>
          <div>{`${t("patients.physical_function")}: ${payload[0].value}`}</div>
        </StyledTooltip>
      );
    }
    return null;
  };

  const PainTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length !== 0) {
      return (
        <StyledTooltip>
          <div>{t("common.week_num", { number: label })}</div>
          <div>{`${t("patients.graph.pain_in_joint")}: ${payload[0].value}`}</div>
        </StyledTooltip>
      );
    }
    return null;
  };

  const FeelingTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length !== 0) {
      return (
        <StyledTooltip>
          <div>{t("common.week_num", { number: label })}</div>
          <div>{`${t("patients.graph.general_feeling")}: ${payload[0].value}`}</div>
        </StyledTooltip>
      );
    }
    return null;
  };

  const getTooltipContent = (tooltipType: string) => {
    if (tooltipType === "pain") return <PainTooltip />;
    if (tooltipType === "feeling") return <FeelingTooltip />;
    return <FunctionTooltip />;
  };

  const isCartesianViewbox = (value: ViewBox): value is Required<CartesianViewBox> => {
    return (value as CartesianViewBox).x !== undefined;
  };

  const GoalIndicator = ({ value, viewBox }: LabelProps) => {
    let width: number | string | undefined;

    if (value !== undefined) {
      width = toNumber(value) < 10 ? "14" : "26";
    }

    if (value && viewBox && isCartesianViewbox(viewBox) && width !== undefined) {
      return (
        <>
          <line
            x1={viewBox.x}
            y1={viewBox.y}
            x2={viewBox.x - 10}
            y2={viewBox.y}
            stroke={goalLineColor}
            strokeWidth={4}
          />
          <GoalRect x={viewBox.x - (toNumber(value) < 10 ? 20 : 30)} y={viewBox.y - 11} width={width} />
          <GoalText data-testid="goal-line" x={viewBox.x - 8} y={viewBox.y + 5}>
            {value}
          </GoalText>
        </>
      );
    }
    return null;
  };

  const calculatePainYDomain = () => {
    return [0, 10];
  };

  const calculatePhysicalFunctionYDomain = () => {
    const yMin = (dataMin: number) => {
      if (isGoalDefined && Math.min(goal - 2, dataMin - 2) > 0) {
        const minimum = Math.min(goal - 2, dataMin - 2);
        return minimum % 2 === 0 ? minimum : minimum - 1;
      }
      if (goal === null && dataMin !== Infinity && dataMin - 2 > 0) {
        const minimum = dataMin - 2;
        return minimum % 2 === 0 ? minimum : minimum - 1;
      }
      return 0;
    };
    const yMax = (dataMax: number) => {
      if (graphData.length > 0 || goal !== null || goal !== undefined) {
        if (isGoalDefined && Math.max(goal + 2, dataMax + 2) < 35) {
          const maximum = Math.max(goal + 2, dataMax + 2);
          return maximum % 2 === 0 ? maximum : maximum + 1;
        }
        if (goal === null && dataMax + 2 < 35) {
          const maximum = dataMax + 2;
          return maximum % 2 === 0 ? maximum : maximum + 1;
        }
        return 35;
      }
      return 2;
    };
    return [yMin, yMax];
  };

  const calculateFeelingYDomain = () => {
    return [0, 100];
  };

  const yDomain: AxisDomainItem = () => {
    switch (type) {
      case "feeling":
        return calculateFeelingYDomain();
      case "pain":
        return calculatePainYDomain();
      default:
        return calculatePhysicalFunctionYDomain();
    }
  };

  const generateLeftFooter = () => {
    if (graphData.length === 0) {
      return "-";
    }
    if (progress[1] === "Infinity") {
      return (
        <>
          - <SmallSection>({progress[0] ?? 0})</SmallSection>
        </>
      );
    }
    const percentageString =
      toNumber(progress[1]) > 0 ? `+${toNumber(progress[1]) ?? 0}%` : `${toNumber(progress[1]) ?? 0}%`;
    return (
      <>
        {percentageString}
        <SmallSection>({progress[0] ?? 0})</SmallSection>
      </>
    );
  };

  const getChartLegend = () => {
    switch (type) {
      case "feeling":
        return t("patients.graph.general_feeling");
      case "pain":
        return t("patients.graph.pain_in_joint");
      default:
        return t("patients.physical_function");
    }
  };

  const getDateKey = () => {
    switch (type) {
      case "feeling":
        return "general_feeling";
      case "pain":
        return "specific_joint_pain";
      default:
        return "repetitions";
    }
  };

  return (
    <Container data-testid="chart">
      <ChartWrapper>
        <ChartHeader>
          {isGoalDefined && (
            <>
              <ChartLegendCircle color="goal" />
              <ChartLegend data-testid="goal-legend" color="goal">
                {t("patients.your_goal")}
              </ChartLegend>
            </>
          )}
          <ChartLegendCircle color={lineColor} />
          <ChartLegend color={lineColor}>{getChartLegend()}</ChartLegend>
        </ChartHeader>
        <Content>
          {isError || isLoading ? (
            <Empty>
              {isLoading ? (
                <Spinner small />
              ) : (
                <Notification variant="danger" style={{ marginBottom: "5px" }}>
                  {t("errors.generic")}
                </Notification>
              )}
            </Empty>
          ) : (
            <ResponsiveContainer width="99%" height={278}>
              <LineChart data={graphData} margin={{ top: 10, right: 15, bottom: 5, left: -30 }}>
                <CartesianGrid stroke={theme.colors.greys.silver} vertical={false} />
                <XAxis
                  allowDecimals={false}
                  axisLine={false}
                  dataKey="week"
                  tick={{ stroke: theme.colors.greys.warm, strokeWidth: 0.5, fontSize: "14px" }}
                  tickCount={10}
                  tickLine={false}
                  domain={[
                    0,
                    (dataMax: number) => {
                      if (patient?.week !== undefined && patient?.week >= dataMax) {
                        if (patient?.week !== 0) return patient?.week;
                        return 1;
                      }
                      return dataMax;
                    },
                  ]}
                  type="number"
                />
                <YAxis
                  type="number"
                  domain={yDomain()}
                  axisLine={false}
                  allowDecimals={false}
                  tickLine={false}
                  tickCount={type === "pain" ? 20 : 10}
                  tick={{ stroke: theme.colors.greys.warm, strokeWidth: 0.5, fontSize: "14px" }}
                  interval={0}
                />
                {isGoalDefined && (
                  <ReferenceLine x="goal" y={goal} stroke={goalLineColor} strokeWidth={4}>
                    <Label value={goal} position="left" content={<GoalIndicator />} />
                  </ReferenceLine>
                )}
                <Line
                  type="monotone"
                  dataKey={getDateKey()}
                  dot={{
                    fill: feelingOrPain ? theme.colors.graph.pain : theme.colors.graph.function,
                    strokeWidth: 3,
                  }}
                  stroke={feelingOrPain ? theme.colors.graph.pain : theme.colors.graph.function}
                  strokeWidth={4}
                  isAnimationActive={false}
                />
                <Tooltip cursor={false} content={getTooltipContent(type)} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Content>
        <CardFooter>
          <FooterSide>
            {t("patients.graph.since_start")}
            <FooterContent color={lineColor}>{generateLeftFooter()}</FooterContent>
          </FooterSide>
          <FooterSide>
            {t("patients.graph.current")}
            <FooterContent color={lineColor}>
              {graphData.length > 0 ? (
                <>
                  {generateArrow(progress[1], lineColor)}
                  {currentValue}
                  <SmallSection>{t("common.week_num", { number: currentWeek })}</SmallSection>
                </>
              ) : (
                <>-</>
              )}
            </FooterContent>
          </FooterSide>
        </CardFooter>
      </ChartWrapper>
    </Container>
  );
};

export default Chart;
