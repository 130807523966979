import { useTranslation } from "react-i18next";

import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";

interface Props {
  className?: string;
  formState: "error" | "idle" | "loading" | "success";
  messages?: {
    error?: string;
    loading?: string;
    success?: string;
  };
}

export const FormState: React.VFC<Props> = ({ className, formState = "idle", messages }) => {
  const { t } = useTranslation();

  const getFormStateTranslation = () => {
    switch (formState) {
      case "error": {
        return <Notification variant="danger">{messages?.error || t("form.states.error")}</Notification>;
      }
      case "loading": {
        return (
          <Notification variant="info">
            {messages?.loading || t("form.states.loading")}
            <Spinner style={{ height: "30px", width: "30px" }} />
          </Notification>
        );
      }
      case "success": {
        return <Notification variant="success">{messages?.success || t("form.states.success")}</Notification>;
      }
      case "idle":
      default:
        return "";
    }
  };

  return <div className={className}>{getFormStateTranslation()}</div>;
};
