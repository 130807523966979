import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ActivityLevels from "shared/atoms/ActivityLevels";
import Counter from "shared/atoms/Counter";
import PremiumIcon from "shared/atoms/PremiumIcon";

interface DifficultyItemProps {
  label: string;
  levels?: number;
  currentLevel?: number;
  exerciseId: number;
  levelData: Array<{ id: number; level: number }>;
  onChange: (exerciseId: number, levelId: number) => void;
  premium?: boolean;
}

const DifficultyItem: React.VFC<DifficultyItemProps> = ({
  label,
  currentLevel = 1,
  levels = 1,
  levelData,
  onChange,
  exerciseId,
  premium = false,
}) => {
  const { t } = useTranslation();
  const [chosenLevel, setChosenLevel] = useState(currentLevel);

  const handleCounterClick = (newValue: number): void => {
    setChosenLevel(newValue);
    const level = levelData.find(element => {
      return element.level === newValue;
    });
    if (level?.id !== undefined) onChange(exerciseId, level.id);
  };

  return (
    <Container data-testid="difficulty-item">
      <LabelWrapper>
        <Text currentLevel={chosenLevel}>
          {premium && <PremiumIcon size="small" premiumType="guided_care" margin="0 4px 0 0 " />}
          {label}
        </Text>
        {chosenLevel === 0 && <DeletedText>{t("patients.protocol.removed_exercise")}</DeletedText>}{" "}
      </LabelWrapper>
      <Wrapper>
        <LevelsWrapper zero={chosenLevel === 0}>
          {chosenLevel !== 0 && <ActivityLevels size="small" levels={levels} currentLevel={chosenLevel} />}
        </LevelsWrapper>
        <Counter
          startValue={currentLevel}
          maxValue={levels}
          minValue={0}
          clickCallback={newValue => handleCounterClick(newValue)}
        />
      </Wrapper>
    </Container>
  );
};

export default DifficultyItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  margin: 0 ${props => props.theme.spacing.S_10};
  height: 50px;
  box-sizing: border-box;
  ${props => props.theme.belowBreakpoint} {
    margin: 0;
  }
`;

const LabelWrapper = styled.div`
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = styled.div<{ currentLevel: number }>`
  ${props => props.currentLevel === 0 && "opacity: 0.3;"}
  ${props => props.theme.font.header5}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;
`;

const DeletedText = styled.div`
  color: ${props => props.theme.colors.tomato};
  ${props => props.theme.font.caption}
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LevelsWrapper = styled.div<{ zero: boolean }>`
  ${props => props.theme.font.header5}
  color: ${props => (props.zero ? props.theme.colors.greys.gunmetal : props.theme.colors.primary.base)};
  margin-left: ${props => props.theme.spacing.S_20};
  margin-right: ${props => props.theme.spacing.S_5};
  height: 24px;
  width: 30px;
  text-align: right;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
