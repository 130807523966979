import type React from "react";

import styled from "styled-components";

import { useProfileContext } from "contexts/ProfileContext";
import Divider from "shared/atoms/Divider";
import { MarketTypes } from "types";
import { userIsTherapist } from "utils/profile/profileHelper";

import PatientPreferences from "../components/PatientPreferences";
import ProfileBio from "../components/ProfileBio";
import ProfileHeader from "../components/ProfileHeader";
import ProfileInformation from "../components/ProfileInformation";
import ProfilePassword from "../components/ProfilePassword";
import ProfileUsLicenses from "../components/ProfileUsLicenses";
import WelcomeMessage from "../components/WelcomeMessage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${props => props.theme.spacing.S_30} auto;
  padding: ${props => props.theme.spacing.S_50} ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowBreakpoint} {
    margin: ${props => props.theme.spacing.S_20} auto;
    padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_20};
  }
  max-width: 620px;
`;

const Settings: React.VFC = () => {
  const { profile } = useProfileContext();
  const isTherapist = userIsTherapist(profile);

  const customWelcomeMessageEnabled: boolean =
    profile?.therapist_profile?.feature_flags?.includes("CUSTOM_WELCOME_MESSAGE") || false;

  return (
    <Container>
      <ProfileHeader />
      <Divider marginVertical={30} />
      <ProfileInformation />
      <Divider marginVertical={30} />
      {profile?.market !== MarketTypes.SE && (
        <>
          <ProfilePassword />
          <Divider marginVertical={30} />
        </>
      )}
      {isTherapist && (
        <>
          <ProfileBio />
          <Divider marginVertical={16} />
          {profile?.market === MarketTypes.US && (
            <>
              <ProfileUsLicenses />
              <Divider marginVertical={16} />
            </>
          )}

          <PatientPreferences />
          <Divider marginVertical={30} />
          <WelcomeMessage customWelcomeMessageEnabled={customWelcomeMessageEnabled} />
        </>
      )}
    </Container>
  );
};

export default Settings;
