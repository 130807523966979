import type React from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ClockColor } from "assets";
import json from "assets/animations/dot-animation.json";
import { useDropInCallContext } from "contexts/DropInCallContext";
import useDropInCallTimerContext from "contexts/DropInCallContext/useDropInCallTimerContext";

type Props = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const DropInCallIndicator: React.VFC<Props> = ({ onClick }) => {
  const { available, isReady, currentSession, expiryTime } = useDropInCallContext();
  const { timerTime } = useDropInCallTimerContext();
  const sessionState = currentSession?.state;
  const patientId = currentSession?.patient_id;
  const { t } = useTranslation();

  return (
    <>
      {isReady && available && !patientId && !sessionState && (
        <Pane onClick={onClick}>
          <DotsAnimation>
            <Player speed={0.5} autoplay loop src={json} />
          </DotsAnimation>
        </Pane>
      )}

      {isReady && patientId && sessionState === "claimed" && (
        <PaneInverted onClick={onClick}>
          <Timer>{timerTime}</Timer>
          <ClockColor />
        </PaneInverted>
      )}

      {isReady && patientId && sessionState === "in_review" && (
        <PaneInverted onClick={onClick}>{t("drop_in_calls.pane.expected_small", { time: expiryTime })}</PaneInverted>
      )}
    </>
  );
};

const Pane = styled.div`
  background-color: ${props => props.theme.colors.redesign.b10};
  border-radius: 0 35px 35px 0;
  margin-left: -28px;
  padding-left: 35px;
  padding-right: 18px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
`;

const PaneInverted = styled(Pane)`
  background-color: ${props => props.theme.colors.redesign.b100};
  color: white;
`;

const DotsAnimation = styled.div`
  width: 52px;
`;

const Timer = styled.div`
  margin-right: 8px;
`;

export default DropInCallIndicator;
