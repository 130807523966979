import type React from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import invariant from "ts-invariant";

import { createPatientCareEvent, getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import type { CareEvent } from "api/models/CareEvent";
import { OutlinedPlusIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { newNoteSafe } from "routes/patients/utils/notesHelper";
import usePrepareCareEventOptions from "routes/patients/utils/usePrepareCareEventOptions";
import Body1 from "shared/atoms/Typography/Body1";
import { CurrentPatientContext } from "utils/contexts";

import DropdownButton from "./components/DropdownButton";

interface Props {
  customDropdownStyling: string;
}

const PatientHeaderInfoAddNoteButton: React.VFC<Props> = ({ customDropdownStyling }) => {
  const { t } = useTranslation();
  const { patient, updateIsCreatable, setActiveCareEventNote } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const queryClient = useQueryClient();
  invariant(profile);
  const [isOpenTooltip, setOpenTooltip] = useState(false);
  const [errorTooltip, setErrorTooltip] = useState(false);

  const createNote = async (type: string) => {
    try {
      const result = await newNoteSafe(patient.id, type, profile.id);
      if (result) {
        const newNoteData = result.data as CareEvent;
        const key = getPatientCareEventsQueryKey({ patientId: patient.id, signed: false });
        queryClient.cancelQueries(key);
        queryClient.setQueryData(key, createPatientCareEvent(newNoteData));
        setActiveCareEventNote(newNoteData.id);
        queryClient.invalidateQueries(key);
        queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
        updateIsCreatable();
      } else {
        setOpenTooltip(true);
      }
    } catch (_error) {
      setErrorTooltip(true);
    }
  };

  const options = usePrepareCareEventOptions({ createNote });

  return (
    <DropdownButton
      id="new-care-event-button"
      dataTestId="new-care-event-button"
      options={options}
      tooltips={[
        {
          isOpen: isOpenTooltip,
          requestClose: () => setOpenTooltip(false),
          offset: "bottom-right",
          children: <Text data-testid="info-tooltip">{t("messages.disabled_chat_note")}</Text>,
        },
        {
          isOpen: errorTooltip,
          requestClose: () => setErrorTooltip(false),
          offset: "bottom-right",
          children: <Text data-testid="error">{t("errors.generic")}</Text>,
        },
      ]}
      customDropdownStyling={customDropdownStyling}
    >
      <OutlinedPlusIcon color="red" />
      <NewNoteText customdropdownstyling={customDropdownStyling}>{t("messages.new_note")}</NewNoteText>
    </DropdownButton>
  );
};

const Text = styled(Body1)`
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.primary.base};
  display: inline-block;
  width: 200px;
`;

const NewNoteText = styled.div<{ customdropdownstyling?: string }>`
  ${props => props.customdropdownstyling === "custom-position" && ` margin-left: ${props.theme.spacing.S_10};`}
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

export default PatientHeaderInfoAddNoteButton;
