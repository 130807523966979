import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { State } from "api/schemas/NewAppointment";
import { CalendarIcon } from "assets";
import { isAppointmentDisabled } from "routes/calendar/components/Calendar/helpers/transformAppointments";
import { AppointmentState } from "routes/calendar/components/EventDetails/AppointmentDetails/AppointmentState";
import type { Medium, Purpose } from "routes/messages/types";
import Hidden from "shared/atoms/Hidden";
import Row from "shared/atoms/Row";
import useLocalizeDate from "utils/date";

import { BlueLine, Container, Content, Title } from "../InfoHelpers";

interface Props {
  calendarUrl?: string;
  purpose: Purpose;
  start: string;
  end: string;
  type: Medium;
  phoneNumber: string;
  appointmentState?: State;
}

const InfoSchedule: React.VFC<Props> = ({ calendarUrl, purpose, start, end, type, phoneNumber, appointmentState }) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizeDate();
  return (
    <Container data-testid="info-schedule">
      <BlueLine />
      <Content>
        <Title $schedule>
          <StateAndTitle>
            {appointmentState && isAppointmentDisabled(appointmentState) && (
              <StyledAppointmentState state={appointmentState} $state={appointmentState} />
            )}
            {t(`messages.appointments.types.${purpose}`)}
          </StateAndTitle>
          <Hidden type="belowMobile">
            {appointmentState !== undefined && !isAppointmentDisabled(appointmentState) && (
              <CalendarButton download href={calendarUrl}>
                <Icon />
                {t("messages.appointments.calendar_add")}
              </CalendarButton>
            )}
          </Hidden>
        </Title>
        <Information>
          <InfoItem>
            {t("messages.appointments.date")}
            <InfoData>{format(parseISO(start), "PP")}</InfoData>
          </InfoItem>
          <InfoItem>
            {t("messages.appointments.time")}
            <InfoData>{`${format(parseISO(start), "p")} - ${format(parseISO(end), "p")}`}</InfoData>
          </InfoItem>
          <InfoItem>
            {t("messages.appointments.type")}
            <InfoData>{t(`messages.appointments.types.${type}`)}</InfoData>
          </InfoItem>
          {type === "PHONE" && (
            <InfoItem>
              {t(`messages.appointments.phone`)}
              <InfoData>{phoneNumber}</InfoData>
            </InfoItem>
          )}
          <Hidden type="aboveMobile">
            <CalendarButton download href={calendarUrl}>
              <Icon />
              {t("messages.appointments.calendar_add")}
            </CalendarButton>
          </Hidden>
        </Information>
      </Content>
    </Container>
  );
};

export default InfoSchedule;

const CalendarButton = styled.a`
  ${props => props.theme.font.link2}
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.primary.base};
  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled(CalendarIcon)`
  margin-right: ${props => props.theme.spacing.S_10};
`;

const Information = styled(Row)`
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
  flex-wrap: wrap;
`;

const InfoItem = styled.div`
  display: inline-block;
  ${props => props.theme.font.body2}
  margin-right: ${props => props.theme.spacing.S_30};
  margin-bottom: ${props => props.theme.spacing.S_5};
  flex-wrap: nowrap;
`;

const InfoData = styled.div`
  display: inline-block;
  ${props => props.theme.font.header5}
  line-height: 23px;
  margin-left: ${props => props.theme.spacing.S_10};
`;

const StateAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAppointmentState = styled(AppointmentState)<{ $state: State }>`
  margin-right: 8px;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.primary.base};
  background: ${({ theme, $state }) =>
    $state === "rescheduled" ? theme.colors.redesign.o20 : theme.colors.redesign.r20};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
`;
