import type React from "react";
import { useContext, useState } from "react";

import styled from "styled-components";

import { PayerTypes } from "api/models/CareEvent";
import { useDropInCallContext } from "contexts/DropInCallContext";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import Hidden from "shared/atoms/Hidden";
import VideoCallAwareContainer from "shared/atoms/VideoCallAwareContainer/VideoCallAwareContainer";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";
import { userIsDoctor } from "utils/profile/profileHelper";

import PatientMenu from "../PatientMenu";

import PatientEndTreatmentForms from "./PatientEndTreatmentForms";
import PatientHeaderGuide from "./PatientHeaderGuide";
import PatientHeaderInfo from "./PatientHeaderInfo";
import { isPatientDischarged } from "./PatientHeaderInfo/helpers";
import PatientHeaderBanner from "./PatientHeaderInfo/PatientHeaderBanner";
import ReferralsHandler from "./Referrals";
import ScheduleCallBox from "./ScheduleCallBox";
import SignNotesTable from "./SignNotesTable";
import SuggestedCareEvent from "./SuggestedCareEvent";
import { UpcomingAppointments } from "./UpcomingAppointments";
import UserCourseNotificationBox from "./UserCourseNotificationBox";

const PatientHeader: React.VFC = () => {
  const { patient, patientError } = useContext(CurrentPatientContext);
  const { hasFeature } = useFeatureToggleContext();
  const { profile } = useProfileContext();
  const isDoctor = userIsDoctor(profile);
  const offsetTop = usePageContentOffsetTop();
  const [showEndTreatmentForm, setshowEndTreatmentForm] = useState(!!patient.end_treatment_at);
  const { currentSession } = useDropInCallContext();

  if (isDoctor) {
    return (
      <Header>
        <Content>
          <PatientHeaderInfo offsetTop={offsetTop} />
          <ReferralsHandler />
          {!patientError && <PatientMenu />}
        </Content>
      </Header>
    );
  }

  // Guides - only one guide is shown at a time
  const showGuide = () => {
    if (patient.pt_on_demand_enabled) {
      return <PatientHeaderGuide patient={patient} type="pt_on_demand_call" />;
    }
    if (!isPatientDischarged(patient) && patient.ready_for_discharge) {
      if (patient.premium_type === "none") {
        return <PatientHeaderGuide patient={patient} type="premium_none" />;
      }
      if (patient.premium_type === "selfcare") {
        return <PatientHeaderGuide patient={patient} type="premium_selfcare" />;
      }
      if (patient.premium_type === "guided_care") {
        return <PatientHeaderGuide patient={patient} type="premium_guided_care" />;
      }
    }
    return null;
  };

  const isDropInCallActive =
    currentSession &&
    patient.id === currentSession.patient_id &&
    (currentSession?.state === "claimed" || currentSession?.state === "in_review");

  const showScheduleCallBox =
    hasFeature(Feature.PATIENT_SCHEDULE_CALL_BOX) && patient?.appointment_to_schedule && !isDropInCallActive;

  const showUserCourseNotification = patient.awaiting_treatment_ready;

  // FE fix to hide low adherence notification for PTonDemand patients
  // https://jointacademy.atlassian.net/browse/CCC-1192
  const hideLowAdherenceNotification = patient?.pt_on_demand_enabled;

  return (
    <Header>
      <Content>
        <PatientHeaderInfo
          offsetTop={offsetTop}
          setShowEndTreatmentForm={setshowEndTreatmentForm}
          showEndTreatmentForm={showEndTreatmentForm}
        />
        <Hidden type="aboveTablet">
          <PatientHeaderBanner />
        </Hidden>
        {showEndTreatmentForm && <PatientEndTreatmentForms patient={patient} />}
        {showScheduleCallBox && !hideLowAdherenceNotification && (
          <ScheduleCallBox
            patientId={patient?.id}
            appointmentToSchedule={patient?.appointment_to_schedule}
            isPremiumSignedUp={patient?.premium_activation_state === "signed_up"}
            isStockholm={patient?.payer?.payer_type === PayerTypes.STOCKHOLM}
            isVGR={patient?.payer?.payer_type === PayerTypes.VGR}
          />
        )}
        {showUserCourseNotification && <UserCourseNotificationBox patientId={patient.id} />}
        {showGuide()}
        {hasFeature(Feature.PATIENT_SUGGESTED_CARE_EVENT) && <SuggestedCareEvent patientId={patient?.id} />}
        {profile?.therapist_profile?.feature_flags?.includes("BOOKING_V2") && <UpcomingAppointments />}
        {hasFeature(Feature.PATIENT_SIGN_NOTES_TABLE) && <SignNotesTable $isFullWidthOnMobile />}
        {!patientError && <PatientMenu />}
      </Content>
    </Header>
  );
};

export default PatientHeader;

const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  background-color: ${props => props.theme.colors.greys.light2};
  padding: 20px 20px 0 20px;
  ${props => props.theme.belowBreakpoint} {
    padding: 0;
  }
`;

const Content = styled(VideoCallAwareContainer)`
  display: flex;
  flex-flow: column nowrap;
  max-width: 1000px;
`;
