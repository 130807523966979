import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import invariant from "ts-invariant";

import { createPatientCareEvent, getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import type { CareEvent } from "api/models/CareEvent";
import { OutlinedPlusIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { newNoteSafe } from "routes/patients/utils/notesHelper";
import usePrepareCareEventOptions from "routes/patients/utils/usePrepareCareEventOptions";
import { CurrentPatientContext } from "utils/contexts";

import { Button, DropdownItem } from "./helpers";
import { PatientHeaderDropdownSub } from "./PatientHeaderDropdown/PatientHeaderDropdown";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setSubOpen: (visible: boolean) => void;
}

const NewNoteMenu: React.VFC<Props> = ({ isOpen, onClose, setSubOpen }) => {
  const { patient, updateIsCreatable, setActiveCareEventNote } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  invariant(profile);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  invariant(profile);

  const createNote = async (type: string) => {
    try {
      onClose();
      const result = await newNoteSafe(patient.id, type, profile.id);
      if (result) {
        const newNoteData = result.data as CareEvent;
        const key = getPatientCareEventsQueryKey({ patientId: patient.id, signed: false });
        queryClient.cancelQueries(key);
        queryClient.setQueryData(key, createPatientCareEvent(newNoteData));
        setActiveCareEventNote(newNoteData.id);
        queryClient.invalidateQueries(key);
        queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
        updateIsCreatable();
      }
    } catch (_error) {
      onClose();
    }
  };

  const options = usePrepareCareEventOptions({ createNote });

  return (
    <PatientHeaderDropdownSub
      isMainOpen={isOpen}
      onVisibleChange={visible => {
        setSubOpen(visible);
      }}
      data-testid="patient-header-dropdown-submenu"
      label={
        <>
          <OutlinedPlusIcon width={24} />
          {t("messages.new_note")}
        </>
      }
    >
      {options.map(option => {
        return (
          <DropdownItem key={option.dataTestId} data-testid={option.dataTestId} disabled={option.disabled}>
            <Button onClick={() => option.onClick()} disabled={option.disabled}>
              {option.label}
            </Button>
          </DropdownItem>
        );
      })}
    </PatientHeaderDropdownSub>
  );
};

export default NewNoteMenu;
