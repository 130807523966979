import type React from "react";
import { useEffect, useState } from "react";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { SendIcon, StarsIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { DesktopButton } from "routes/messages/styles/input";
import IconButton from "shared/atoms/IconButton";
import { AnalyticsService } from "utils/analytics";

interface Props {
  className?: string;
  commentId?: number;
  patientId: number;
  smartReply: string;
  dismissEvent: string;
  editEvent: string;
  presentEvent: string;
  submitEvent: string;
  onEdit: (value: string) => void;
  onSubmit: (value: string) => void;
}

export const LeifGPT: React.VFC<Props> = ({
  className,
  commentId,
  patientId,
  smartReply,
  dismissEvent,
  editEvent,
  presentEvent,
  submitEvent,
  onEdit,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const [showSmartQuickReply, setShowSmartQuickReply] = useState(true);

  invariant(profile);

  const trackEvent = (event: string) => {
    AnalyticsService.track(event, {
      comment_id: commentId,
      patient_id: patientId,
      therapist_id: profile.id,
    });
  };

  useEffect(() => {
    if (showSmartQuickReply) {
      trackEvent(presentEvent);
    }
  }, [showSmartQuickReply]);

  return (
    <>
      {showSmartQuickReply ? (
        <Container className={className}>
          <Header>
            <StarsIcon />
            <HeaderText>{t("leifgpt.header_text")}</HeaderText>

            <CloseButton
              onClick={() => {
                trackEvent(dismissEvent);
                setShowSmartQuickReply(false);
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </CloseButton>
          </Header>

          <SuggestedReply>{smartReply}</SuggestedReply>

          <Footer>
            <BlueIconButton
              label={t("leifgpt.edit_btn")}
              onClick={() => {
                trackEvent(editEvent);
                onEdit(smartReply);
                setShowSmartQuickReply(false);
              }}
            >
              <FontAwesomeIcon icon={faEdit} style={{ height: "18px" }} />
            </BlueIconButton>

            <DesktopButton
              onClick={() => {
                trackEvent(submitEvent);
                onSubmit(smartReply);
                setShowSmartQuickReply(false);
              }}
            >
              <SendIcon />
            </DesktopButton>
          </Footer>
        </Container>
      ) : null}
    </>
  );
};

const Container = styled.section`
  width: 100%;
  margin-top: 8px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 10px 0px rgba(68, 104, 147, 0.15);
  border-radius: 8px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 4px 0 4px 12px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.redesign.p100} 0%, ${theme.colors.redesign.b100} 100%)`};
  border-radius: 8px 8px 0 0;
`;

const HeaderText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 12px;
  cursor: pointer;
`;

const SuggestedReply = styled.div`
  padding: 16px 12px 8px;
  white-space: pre-wrap;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
`;

const BlueIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.redesign.b100};

  div:nth-of-type(1) {
    height: auto;
    width: auto;
  }

  div:nth-of-type(2) {
    margin-left: 4px;
    font-size: 16px;
    line-height: normal;
  }
`;

export const SmartReplyIndicator: React.VFC = () => {
  return (
    <Background>
      <StarsIcon />
    </Background>
  );
};

const Background = styled.div`
  margin-left: auto;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.redesign.b100};
    }
  }
`;
