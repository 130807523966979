import type React from "react";
import { useContext } from "react";

import styled, { ThemeContext } from "styled-components";

import {
  AudioIcon,
  AudioIconDisabled,
  EndCallIcon,
  MaximiseIcon,
  MinimiseIcon,
  VideoIcon,
  VideoIconDisabled,
} from "assets/videocall";
import { useWindowSize } from "utils/hooks";

interface ContainerProps {
  readonly $minimised: boolean;
}

const Container = styled.div<ContainerProps>`
  bottom: ${props => (props.$minimised ? "10px" : props.theme.spacing.S_20)};
  display: flex;
  left: 0;
  position: absolute;
  padding: ${props => (props.$minimised ? "0 10px" : "0 32px")};
  right: 0;

  & :first-child {
    margin-left: 0;
  }
  & :last-child {
    margin-right: 0;
  }
`;

const ControlButton = styled.button`
  border: 1px solid transparent;
  background: none;
  margin: 0 12px;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

const IconEndCall = styled(EndCallIcon)`
  width: 53px;
  height: 53px;
`;

const EnabledVideo = styled(VideoIcon)`
  width: 53px;
  height: 53px;
`;

const DisabledVideo = styled(VideoIconDisabled)`
  width: 53px;
  height: 53px;
`;

const EnabledAudio = styled(AudioIcon)`
  width: 53px;
  height: 53px;
`;

const DisabledAudio = styled(AudioIconDisabled)`
  width: 53px;
  height: 53px;
`;

const Filler = styled.div`
  flex: 1;
`;

interface Props {
  readonly audioEnabled: boolean;
  readonly minimised: boolean;
  readonly videoEnabled: boolean;
  readonly onAudioEnabledToggled: () => void;
  readonly onEndPressed: () => void;
  readonly onMinimisedToggled: () => void;
  readonly onVideoEnabledToggled: () => void;
}

const Callbuttons: React.VFC<Props> = ({
  audioEnabled,
  minimised,
  videoEnabled,
  onAudioEnabledToggled,
  onEndPressed,
  onMinimisedToggled,
  onVideoEnabledToggled,
}) => {
  const { width } = useWindowSize();
  const theme = useContext(ThemeContext);

  return (
    <Container $minimised={minimised}>
      {!minimised ? (
        <>
          <ControlButton data-testid="video-call-hangup-button" onClick={onEndPressed}>
            <IconEndCall />
          </ControlButton>
          <Filler />
          <ControlButton onClick={onVideoEnabledToggled}>
            {videoEnabled ? <EnabledVideo /> : <DisabledVideo />}
          </ControlButton>
          <ControlButton onClick={onAudioEnabledToggled}>
            {audioEnabled ? <EnabledAudio /> : <DisabledAudio />}
          </ControlButton>
          {width > parseInt(theme.breakpoint, 10) && (
            <ControlButton onClick={onMinimisedToggled}>
              <MinimiseIcon />
            </ControlButton>
          )}
        </>
      ) : (
        <ControlButton onClick={onMinimisedToggled}>
          <MaximiseIcon />
        </ControlButton>
      )}
    </Container>
  );
};

export default Callbuttons;
