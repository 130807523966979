import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { BlueCheckmarkIcon, PartnerWelcome } from "assets";
import { PrimaryButton } from "shared/atoms/Button";

import { OnboardingButtons, OnboardingHeader, OnboardingMessage } from "../components";

const WelcomePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <OnboardingWelcome>
      <StyledOnboardingImage data-testid="partner-onboarding-welcome-page-img" />

      <OnboardingHeader>{t("partner_onboarding.header")}</OnboardingHeader>

      <BulletPointContainer data-testid="partner-onboarding-welcome-page-body">
        <PartnerOnboardingMessage>
          <LightBlueCheckmarkIcon />
          {t("partner_onboarding.body1")}
        </PartnerOnboardingMessage>
        <PartnerOnboardingMessage>
          <LightBlueCheckmarkIcon />
          {t("partner_onboarding.body2")}
        </PartnerOnboardingMessage>
        <PartnerOnboardingMessage>
          <LightBlueCheckmarkIcon />
          {t("partner_onboarding.body3")}
        </PartnerOnboardingMessage>
      </BulletPointContainer>

      <OnboardingButtons>
        <PrimaryButton data-testid="onboarding-welcome-page-next-btn" onClick={() => navigate("/onboarding/complete")}>
          <span>{t("buttons.get_started")}</span>
        </PrimaryButton>
      </OnboardingButtons>
    </OnboardingWelcome>
  );
};

export default WelcomePage;

const LightBlueCheckmarkIcon = styled(BlueCheckmarkIcon)`
  color: ${props => props.theme.colors.redesign.b80};
  margin: 7px ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_5}
    ${props => props.theme.spacing.S_50};
  min-width: ${props => props.theme.spacing.S_20};
  min-height: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowMobileBreakpoint} {
    display: none;
  }
`;
const OnboardingWelcome = styled.div`
  margin-top: ${props => props.theme.spacing.S_50};
  text-align: center;
  padding-bottom: ${props => props.theme.spacing.S_50};
`;
const PartnerOnboardingMessage = styled(OnboardingMessage)`
  display: flex;
  align-items: left;
  margin: ${props => props.theme.spacing.S_5};
  ${props => props.theme.belowMobileBreakpoint} {
    padding: 0 10px 0 10px;
  }
`;
const StyledOnboardingImage = styled(PartnerWelcome)`
  height: auto;
  margin-top: ${props => props.theme.spacing.S_20};

  ${props => props.theme.belowMobileBreakpoint} {
    max-width: 200px;
    margin-top: auto;
  }
`;
const BulletPointContainer = styled.div`
  margin-top: ${props => props.theme.spacing.S_20};
  display: flex;
  flex-direction: column;
  align-items: left;
  ${props => props.theme.belowMobileBreakpoint} {
    align-items: left;
  }
`;
