import { z } from "zod";

import { AilmentSchema } from "./HealthJournal";

export const PayloadSchema = z
  .object({
    ailment: AilmentSchema,
    most_painful_joint: z.string().optional(),
    osteoporosis_diagnosed: z.boolean().optional(),
    osteoporosis_osteopenia_diagnosed: z.boolean().optional(),
    specific_joint_examined: z.boolean().optional(),
    specific_joint_oa_diagnosed: z.boolean().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.ailment === "joint_pain" && data.most_painful_joint === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "most_painful_joint is required when ailment is joint_pain",
        path: ["most_painful_joint"],
      });
    }

    if (data.ailment === "osteoporosis" && data.osteoporosis_diagnosed === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "osteoporosis_diagnosed is required when ailment is osteoporosis",
        path: ["osteoporosis_diagnosed"],
      });
    }

    if (data.ailment === "osteoporosis" && data.osteoporosis_osteopenia_diagnosed === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "osteoporosis_osteopenia_diagnosed is required when ailment is osteoporosis",
        path: ["osteoporosis_osteopenia_diagnosed"],
      });
    }

    const isHipOrKnee = data.most_painful_joint?.includes("hip") || data.most_painful_joint?.includes("knee");

    if (isHipOrKnee && data.specific_joint_examined === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "specific_joint_examined is required when most_painful_joint is hip/knee",
        path: ["specific_joint_examined"],
      });
    }

    if (isHipOrKnee && data.specific_joint_oa_diagnosed === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "specific_joint_oa_diagnosed is required when most_painful_joint is hip/knee",
        path: ["specific_joint_oa_diagnosed"],
      });
    }

    return true;
  });
export type Payload = z.infer<typeof PayloadSchema>;

export const SwitchTreatmentProgramsArgsSchema = z.object({
  therapistID: z.number(),
  patientID: z.number(),
  data: PayloadSchema,
});
export type SwitchTreatmentProgramsArgs = z.infer<typeof SwitchTreatmentProgramsArgsSchema>;

export const SwitchTreatmentProgramsSchema = z.object({
  status: z.string(),
});
export type SwitchTreatmentPrograms = z.infer<typeof SwitchTreatmentProgramsSchema>;
