import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetPainGraphData } from "api/hooks/useGetPainGraphData";
import { useGetPatientFeelingReports } from "api/hooks/useGetPatientFeelingReports";
import { useGetPatientGoalsQuery } from "api/hooks/useGetPatientGoalsQuery";
import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import { useGetPhysicalFunctionGraphData } from "api/hooks/useGetPhysicalFunctionGraphData";
import type { Goal } from "api/models/Goal";
import { EditIcon, JABlackWhite } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { SecondaryButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import PatientGoal from "shared/molecules/PatientGoal/PatientGoal";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { hasTreatmentFeature } from "utils/features";
import { userIsPartner } from "utils/profile/profileHelper";

import { isGoalUndefined } from "../Information/views/Goal";
import NonEditGoal from "../Information/views/NonEditGoal";

import Chart from "./components/Chart";
import Overview from "./components/Overview";
import PlanOfCareCertificationContainer from "./components/PlanOfCareCertificationBox/PlanOfCareCertificationContainer";

const PatientOverview: React.VFC = () => {
  const { patientId } = useParams<Record<string, string>>();
  const { t } = useTranslation();
  const printRef = React.useRef(null);
  const notSet = t("dashboard.tables.calls.goal.not_set");
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();

  const [editGoal, setEditGoal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [goal, setGoal] = useState<Partial<Goal>>();

  const { data: healthJournal } = useGetPatientHealthJournalQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });

  const enableFeelingChart =
    healthJournal?.ailment && hasTreatmentFeature({ ailment: healthJournal.ailment, feature: "feelingChart" });
  const enablePainChart =
    healthJournal?.ailment && hasTreatmentFeature({ ailment: healthJournal.ailment, feature: "painChart" });
  const enableFunctionChart =
    healthJournal?.ailment && hasTreatmentFeature({ ailment: healthJournal.ailment, feature: "functionChart" });
  const enablePainLocation =
    healthJournal?.ailment && hasTreatmentFeature({ ailment: healthJournal.ailment, feature: "painLocation" });

  const physicalFunctionResponse = useGetPhysicalFunctionGraphData(
    {
      patientId: patient.id,
      treatment_id: previousTreatmentId,
    },
    { enabled: Boolean(enableFunctionChart) }
  );
  const painResponse = useGetPainGraphData(
    { patientId: patient.id, treatment_id: previousTreatmentId },
    {
      enabled: Boolean(enablePainChart),
    }
  );
  const getPatientGoalsQuery = useGetPatientGoalsQuery({ patientId: patient.id, treatment_id: previousTreatmentId });
  const feelingResponse = useGetPatientFeelingReports(
    { patientID: patient.id, treatment_id: previousTreatmentId },
    { enabled: Boolean(enableFeelingChart) }
  );

  useEffect(() => {
    if (getPatientGoalsQuery.data?.[0]) {
      setGoal({
        id: getPatientGoalsQuery.data[0].id,
        free_text: getPatientGoalsQuery.data[0].free_text,
        pain: getPatientGoalsQuery.data[0].pain,
        functionality: getPatientGoalsQuery.data[0].functionality,
        feeling: getPatientGoalsQuery.data[0].feeling,
      });
    }
  }, [getPatientGoalsQuery.data]);

  const successFunction = ({ id, free_text, pain, functionality, feeling }: Partial<Goal>) => {
    setSuccess(true);
    setGoal({
      id,
      free_text,
      pain,
      functionality,
      feeling,
    });
    setEditGoal(false);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const goalUndefined = isGoalUndefined(goal);

  const handleDownloadPdf = async () => {
    const patientName = `${patient.first_name} ${patient.last_name}`;
    const element = printRef.current;
    if (element) {
      const html2canvas = (await import("html2canvas")).default;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const JsPDF = (await import("jspdf")).jsPDF;

      const pdf = new JsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      const headerName = `Patient name: ${patientName}`;
      const headerDOB = `Date of birth: ${patient.birthday}`;
      pdf.setFontSize(10);
      pdf.text(headerName, 150, 12);
      pdf.text(headerDOB, 150, 17);
      pdf.addImage(JABlackWhite, "svg", 10, 7, 50, 11);
      pdf.addImage(data, "PNG", 0, 20, pdfWidth, pdfHeight);
      pdf.save(`${patientName} JA-overview.pdf`);
    }
    AnalyticsService.track(AnalyticsEvents.CLINIC_PARTNER.PATIENT_OVERVIEW_PDF_DOWNLOADED);
  };

  const isPhysicalFunctionAvailable = () => {
    const mostPainfulLocation = patient.most_painful_location?.location;
    switch (mostPainfulLocation) {
      case "hand":
      case "shoulder":
      case "neck":
        return false;
      default:
        return true;
    }
  };

  // should really be healthJournal?.ailment === "joint_pain" but lets use location to be backwards compatible
  const isPainAvailable = () => patient.most_painful_location?.location;

  const isFeelingAvailable = () =>
    healthJournal?.ailment === "osteoporosis" || healthJournal?.ailment === "fall_prevention";

  return (
    <OuterContainer>
      {!userIsPartner(profile) ? (
        <POCContainer>
          <PlanOfCareCertificationContainer patientId={patient?.id} />
        </POCContainer>
      ) : (
        <PartnerHeaderContainer>
          <DownloadPDFButton onClick={handleDownloadPdf}>
            {t("patients.medical_records.partner_pdf_download_button")}
          </DownloadPDFButton>
        </PartnerHeaderContainer>
      )}
      <Container ref={printRef}>
        {enableFeelingChart && (
          <CardContainer>
            <CardTitle>
              <Title>{t("patients.graph.general_feeling")}</Title>
            </CardTitle>
            <Chart feelingResponse={feelingResponse} type="feeling" goal={goal?.feeling} />
          </CardContainer>
        )}

        {enablePainChart && isPainAvailable() && (
          <CardContainer>
            <CardTitle>
              <Title>{t("patients.pain")}</Title>
            </CardTitle>
            <Chart painResponse={painResponse} type="pain" goal={goal?.pain} />
          </CardContainer>
        )}

        {enableFunctionChart && isPhysicalFunctionAvailable() && (
          <CardContainer>
            <CardTitle>
              <Title>{t("patients.physical_function")}</Title>
            </CardTitle>
            <Chart
              physicalFunctionResponse={physicalFunctionResponse}
              type="physical-function"
              goal={goal?.functionality}
            />
          </CardContainer>
        )}

        {enablePainLocation && (
          <CardContainer $responsiveHeight>
            <CardTitle>
              <Title>{t("patients.your_pain")}</Title>
            </CardTitle>
            <CardBody $responsiveHeight>
              <Overview />
            </CardBody>
          </CardContainer>
        )}

        <CardContainer $responsiveHeight>
          <CardTitle>
            <Title>{t("patients.your_goal")}</Title>
            {editGoal || getPatientGoalsQuery.error ? null : (
              <IconButton
                dataTestId="add-new-goal-button"
                label={goalUndefined ? t("buttons.add_new_goal") : t("buttons.edit_goal")}
                onClick={() => setEditGoal(true)}
              >
                <EditIcon />
              </IconButton>
            )}
          </CardTitle>
          <CardBody $responsiveHeight>
            {editGoal ? (
              <div data-testid="edit-goal-field">
                <PatientGoal
                  id={Number(patientId)}
                  onCancel={() => setEditGoal(false)}
                  onSuccess={successFunction}
                  currentGoal={{
                    id: goal?.id,
                    description: goal?.free_text,
                    functionality: goal?.functionality?.toString(),
                    pain: goal?.pain?.toString(),
                    feeling: goal?.feeling?.toString(),
                  }}
                  isPhysicalFunctionAvailable={isPhysicalFunctionAvailable}
                  isFeelingAvailable={isFeelingAvailable}
                  isPainAvailable={isPainAvailable}
                />
              </div>
            ) : (
              <NonEditGoal
                canDeleteGoal={!goalUndefined}
                success={success}
                setGoal={setGoal}
                patient={patient}
                patientId={patientId ?? ""}
                goal={goal}
                notSet={notSet}
                error={getPatientGoalsQuery.error}
                isPhysicalFunctionAvailable={isPhysicalFunctionAvailable}
                isFeelingAvailable={isFeelingAvailable}
                isPainAvailable={isPainAvailable}
              />
            )}
          </CardBody>
        </CardContainer>
      </Container>
    </OuterContainer>
  );
};

export default PatientOverview;

const POCContainer = styled.div`
  margin: 0 auto;
  ${props => props.theme.belowLargeBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_10};
  }
  ${props => props.theme.aboveLargeBreakpoint} {
    margin-bottom: ${props => props.theme.spacing.S_20};
    max-width: 90%;
  }
`;

const OuterContainer = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  margin: 0 auto;
  max-width: 1000px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.S_20};
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowLargeBreakpoint} {
    grid-template-columns: 1fr;
    gap: ${props => props.theme.spacing.S_10};
    padding: ${props => props.theme.spacing.S_10};
  }
`;

const CardContainer = styled.div<{ $responsiveHeight?: boolean }>`
  ${props => !props.$responsiveHeight && `height: 459px;`}
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  ${props => props.theme.belowLargeBreakpoint} {
    width: 100%;
  }
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  padding: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_30};
  color: ${props => props.theme.colors.primary.base};
  font-size: 18px;
`;

const Title = styled.span`
  margin: auto 0;
  font-weight: 400;
  line-height: 48px;
`;

const CardBody = styled.div<{ $responsiveHeight: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${props => !props.$responsiveHeight && `height: 328px;`}
  padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_30};
`;

const PartnerHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 940px;
  align-items: center;
  padding: ${props => props.theme.spacing.S_15};
  padding-top: 0;
  margin: 0 auto;
`;

const DownloadPDFButton = styled(SecondaryButton)`
  border: 1px solid;
  margin-right: 0;
  margin-left: auto;
`;
