/* eslint-disable react-hooks/rules-of-hooks */
// TODO: Fix linting for rules-of-hooks
// FIXME: type translation
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type React from "react";

import { isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { CareEventSuggestionType } from "api/models/SuggestedCareEvent";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import useLocalizedDate from "utils/date";

interface Props {
  patientId: number;
  therapistId: number;
  suggestion: CareEventSuggestionType;
  onCreate: (pId: number, noteType: string, tId: number) => Promise<void>;
  onDismiss: (pId: number, noteType: string, tId: number) => void;
  disabled?: boolean;
}

const getWhen = (date: string): string | null => {
  const { t } = useTranslation();
  const { parseISO, isToday, isTomorrow, format } = useLocalizedDate();
  const deadlineParsed = date ? parseISO(date) : null;

  if (!deadlineParsed) {
    return null;
  }

  if (isToday(deadlineParsed)) {
    return t("scheduled_call.today");
  }

  if (isTomorrow(deadlineParsed)) {
    return t("scheduled_call.tomorrow");
  }

  const humanDayDiff = getHumanDayDiff(deadlineParsed);

  return `${humanDayDiff} (${format(deadlineParsed, "PP")})`;
};

const getHumanDayDiff = (deadline: Date): string => {
  const { t } = useTranslation();
  const { differenceInCalendarDays } = useLocalizedDate();

  const now = new Date();
  const dayDifference = differenceInCalendarDays(deadline, now);
  const daysLeftText =
    dayDifference > 0
      ? ` ${t("common.in")} ${dayDifference} ${t("common.days").toLowerCase()}`
      : ` ${Math.abs(dayDifference)} ${t("common.days_ago")}`;

  return daysLeftText;
};

const SuggestedCareEventCallBox: React.VFC<Props> = ({
  therapistId,
  patientId,
  suggestion,
  disabled,
  onDismiss,
  onCreate,
}) => {
  const { t } = useTranslation();
  const now = new Date();
  return (
    <Container data-testid="suggested-care-event-call-box">
      <TextWrapper>
        <span>
          {t(`messages.suggestions.a`)} <strong>{t(`messages.suggestions.${suggestion.label}`).toLowerCase()}</strong>{" "}
          {isBefore(new Date(suggestion.details.deadline), now)
            ? t("messages.suggestions.was_due")
            : t("messages.suggestions.is_due")}{" "}
          <strong>{getWhen(suggestion.details.deadline)}</strong>.
        </span>
        <span>{t("messages.suggestions.create_new_note_question")}</span>
      </TextWrapper>
      <ButtonWrapper>
        <StyledTextButton disabled={disabled} onClick={() => onDismiss(patientId, suggestion.label, therapistId)}>
          {t("buttons.suggestion_dismiss")}
        </StyledTextButton>
        <StyledPrimaryButton disabled={disabled} onClick={() => onCreate(patientId, suggestion.label, therapistId)}>
          {t(`buttons.suggestion_create`)}
        </StyledPrimaryButton>
      </ButtonWrapper>
    </Container>
  );
};

SuggestedCareEventCallBox.propTypes = {
  //   patientId: number.isRequired,
};

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_30};
  margin: ${props => props.theme.spacing.S_10} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const StyledTextButton = styled(TextButton)`
  margin-left: auto;
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const TextWrapper = styled.p`
  display: flex;
  margin: auto 0;
  flex-direction: column;
  span {
    color: ${props => props.theme.colors.greys.dark};
    ${props => props.theme.font.body1};
    ${props => props.theme.belowBreakpoint} {
      ${props => props.theme.font.body2};
    }
  }
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    padding-bottom: ${props => props.theme.spacing.S_10};
    ${props => props.theme.font.link3};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonWrapper = styled.p`
  display: flex;
  margin: auto 0;
  flex-direction: row;
  span {
    color: ${props => props.theme.colors.greys.dark};
    ${props => props.theme.font.body1};
    ${props => props.theme.belowBreakpoint} {
      ${props => props.theme.font.body2};
    }
  }
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    padding-bottom: ${props => props.theme.spacing.S_10};
    ${props => props.theme.font.link3};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
  }
`;

export default SuggestedCareEventCallBox;
