import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { UserCourseExercise } from "api/schemas/UserCourse";

import Counter from "./Counter";
import DropDown from "./DropDown";

const createOption = (i: number) => ({ label: String(i), value: String(i) });

const createOptions = (start: number, stop: number) => {
  const a = [];
  for (let i = start; i <= stop; i += 1) {
    a.push(createOption(i));
  }
  return a;
};

interface Props {
  exercise: UserCourseExercise;
  currentProtocolWeek: number;
  onChangeLevel: (exerciseId: number, level: number) => void;
  onChangeWeek: (id: number, week: number) => void;
  onChangeEndWeek: (id: number, week: number | null) => void;
}

const ExerciseEditRow: React.VFC<Props> = ({
  exercise,
  currentProtocolWeek,
  onChangeLevel,
  onChangeWeek,
  onChangeEndWeek,
}) => {
  const {
    id,
    current_level: currentLevel,
    introduction_protocol_week: week,
    end_protocol_week: endWeek,
    active,
  } = exercise;

  const { t } = useTranslation();
  const weekOptions = active ? [createOption(week)] : createOptions(currentProtocolWeek + 1, currentProtocolWeek + 16);
  const futureWeek = Math.max(week, currentProtocolWeek + 1);
  const endWeekOptions = [{ label: `\u221E`, value: "" }, ...createOptions(futureWeek, futureWeek + 15)];
  const numberOfLevels = exercise.levels.length;

  return (
    <ButtonRow>
      <GrowColumn>
        <Label>{t("usercourse.level")}</Label>
        <Counter
          value={currentLevel}
          maxValue={numberOfLevels}
          minValue={1}
          clickCallback={v => onChangeLevel(id, v)}
        />
      </GrowColumn>
      <GrowColumn>
        <Label>{t("usercourse.start_week")}</Label>
        <DropDown
          value={String(week)}
          options={weekOptions}
          onChange={v => {
            onChangeWeek(id, Number(v));
          }}
          disabled={active}
        />
      </GrowColumn>

      <GrowColumn>
        <Label>{t("usercourse.end_week")}</Label>
        <DropDown
          value={String(endWeek)}
          options={endWeekOptions}
          onChange={v => {
            onChangeEndWeek(id, v === "" ? null : Number(v));
          }}
        />
      </GrowColumn>
    </ButtonRow>
  );
};

export default ExerciseEditRow;

const Label = styled.div`
  color: ${props => props.theme.colors.redesign.db60};
  font-size: 12px;
  line-height: 16px;
`;

const GrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
`;
