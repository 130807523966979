import { useEffect, useState } from "react";

import { faCircleMinus, faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetUserCourseLibraryLessonsQuery } from "api/hooks/useGetUserCourseLibraryLessons";
import type { UserCourseLesson } from "api/schemas/UserCourse";
import { CloseIcon, SearchIcon } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import IconButton from "shared/atoms/IconButton";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import Search from "shared/molecules/Search";

import DropDown from "./DropDown";
import IconLink from "./IconLink";
import LessonPickerItem from "./LessonPickerItem";
import RadioButtons from "./RadioButtons";
import { OuterMargin, SmallPrimaryButton, TopBar, TopLeft, TopRight } from "./SharedStyledComponents";

type TypeOption = "all" | "favourites" | "added" | "new";
type SortOption = "a-z" | "z-a";

const alphaSort = (selectedSort: SortOption) => (a: UserCourseLesson, b: UserCourseLesson) => {
  if (selectedSort === "z-a") {
    return a.title < b.title ? 1 : -1;
  }
  return a.title < b.title ? -1 : 1;
};

const searchLesson = (search: string) => (lesson: UserCourseLesson) => {
  if (lesson.title && lesson.title.toLowerCase().includes(search.toLowerCase())) {
    return lesson;
  }
  return undefined;
};

const existsInSelectedLessons = (selectedLessons: UserCourseLesson[], item: UserCourseLesson) =>
  !!selectedLessons.find(e => e.id === item.id);

const filterLessons =
  (
    selectedLessons: UserCourseLesson[],
    previewJoint: string,
    selectedTypeOption: TypeOption,
    initialSelectedIds: number[]
  ) =>
  (item: UserCourseLesson) => {
    const added = initialSelectedIds.includes(item.id);
    const hasSelectedJoint =
      previewJoint === "all" || (item.target_joints && item.target_joints.find(j => j.indexOf(previewJoint) !== -1));

    if ((selectedTypeOption === "added" && !added) || (selectedTypeOption === "new" && added) || !hasSelectedJoint) {
      return false;
    }
    return true;
  };

const getUniqueJoints = (lessons: UserCourseLesson[]) =>
  [...lessons].reduce<string[]>((prev, curr) => {
    const currentJoints = curr.target_joints;
    if (currentJoints !== undefined && currentJoints !== null) {
      const newJoints = currentJoints.filter(j => prev.indexOf(j) === -1);
      return [...prev, ...newJoints];
    }
    return prev;
  }, []);

const LessonPicker = ({
  onClose,
  onAddLessons,
  initialSelectedIds = [],
}: {
  onAddLessons: (lessons: UserCourseLesson[]) => void;
  onClose: () => void;
  initialSelectedIds?: number[];
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [selectedSort, setSelectedSort] = useState<SortOption>("a-z");
  const [selectedLessons, setSelectedLessons] = useState<UserCourseLesson[]>([]);
  const [filteredLessons, setFilteredLessons] = useState<UserCourseLesson[]>([]);
  const [jointOptions, setJointOptions] = useState([]);
  const [previewJoint, setPreviewJoint] = useState("all");
  const { register, setValue } = useForm<{ search: string }>();
  const [selectedTypeOption, setSelectedTypeOption] = useState<TypeOption>("all");

  const {
    refetch: refetchLessons,
    data: allLessons,
    error: isError,
    isLoading,
  } = useGetUserCourseLibraryLessonsQuery();

  useEffect(() => {
    refetchLessons();
  }, []);

  // set initial selection
  useEffect(() => {
    if (!selectedLessons.length && allLessons && initialSelectedIds.length) {
      const lessons = [...allLessons].filter(item => initialSelectedIds.includes(item.id));
      if (lessons.length) {
        setSelectedLessons(lessons);
      }
    }
  }, [allLessons]);

  // genereate joints options
  useEffect(() => {
    if (allLessons?.length) {
      const availableJoints = getUniqueJoints(allLessons).map(i => ({
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`patients.pain_location.${i}.0`),
        value: i,
      }));
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setJointOptions([{ label: t("patients.pain_location.ALL"), value: "all" }, ...availableJoints]);
    }
  }, [allLessons]);

  // generate filtered lessons
  useEffect(() => {
    if (!allLessons?.length) {
      setFilteredLessons([]);
    }
    if (allLessons?.length) {
      const fe = [...allLessons]
        .filter(searchLesson(search))
        .filter(filterLessons(selectedLessons, previewJoint, selectedTypeOption, initialSelectedIds))
        .sort(alphaSort(selectedSort));
      setFilteredLessons(fe);
    }
  }, [allLessons, selectedSort, search, selectedTypeOption, initialSelectedIds, previewJoint]);

  const sortOptions: { label: string; value: SortOption }[] = [
    { label: t("usercourse.add.a-z"), value: "a-z" },
    { label: t("usercourse.add.z-a"), value: "z-a" },
  ];

  const typeOptions: { label: string; value: TypeOption }[] = [
    { label: t("usercourse.add.all"), value: "all" },
    { label: t("usercourse.add.added"), value: "added" },
    { label: t("usercourse.add.new"), value: "new" },
  ];

  const markAll = () => {
    if (allLessons) {
      setSelectedLessons(state => {
        const newLessons = filteredLessons.filter(
          filteredLesson => !selectedLessons.find(selectedLesson => selectedLesson.id === filteredLesson.id)
        );
        return [...state, ...newLessons];
      });
    }
  };

  const unmarkAll = () => {
    if (allLessons) {
      setSelectedLessons(state => {
        const newState = state.filter(se => !filteredLessons.find(ve => ve.id === se.id));
        return [...newState];
      });
    }
  };

  if (isError) {
    return (
      <LoadingContainer>
        <CenterText>
          <Notification variant="danger" style={{ marginBottom: "5px" }}>
            {t("errors.generic")}
          </Notification>
          <PrimaryButton
            onClick={() => {
              onClose();
            }}
          >
            {t("buttons.close")}
          </PrimaryButton>
        </CenterText>
      </LoadingContainer>
    );
  }

  return (
    <>
      <Root>
        <HeaderRow>
          <OuterMargin>
            <TopBar>
              <TopLeft>
                <Title>{t("usercourse.add_lesson")}</Title>
              </TopLeft>
              <TopRight>
                <SmallPrimaryButton
                  onClick={() => {
                    onAddLessons(selectedLessons);
                  }}
                >
                  {t("buttons.add")}
                </SmallPrimaryButton>
                <IconButton
                  label=""
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </TopRight>
            </TopBar>
          </OuterMargin>
        </HeaderRow>
        <OuterMargin>
          <Library>
            <TopBar>
              <TopLeft>
                <RadioButtons
                  value={selectedTypeOption}
                  options={typeOptions}
                  onChange={v => {
                    setSelectedTypeOption(v);
                  }}
                />
              </TopLeft>
              <TopRight>
                <Search
                  wrapperStyle={{ flexGrow: 1 }}
                  inputStyle={{ maxHeight: "38px", borderRadius: 0, borderWidth: "1px" }}
                  {...register("search")}
                  name="lessonSearch"
                  reset={() => {
                    setValue("search", "");
                    setSearch("");
                  }}
                  icon={SearchIcon}
                  placeholder={t("usercourse.search_lesson")}
                  search={search}
                  onChange={async e => {
                    setSearch(e.target.value);
                  }}
                />
              </TopRight>
            </TopBar>
            <TopBar>
              <Hidden type="belowTablet">
                <TopLeft>
                  <div>{`${selectedLessons.length} ${t("usercourse.lessons")}`}</div>
                  <IconLink icon={faCirclePlus} title={t("usercourse.select_all")} onClick={markAll} />
                  <IconLink icon={faCircleMinus} title={t("usercourse.deselect_all")} onClick={unmarkAll} />
                </TopLeft>
              </Hidden>
              <Hidden type="aboveTablet">
                <TopLeft>
                  <Col>
                    <div>{`${selectedLessons.length} ${t("usercourse.lessons")}`}</div>
                    <Row>
                      <IconLink icon={faCirclePlus} title={t("usercourse.select_all")} onClick={markAll} />
                      <IconLink icon={faCircleMinus} title={t("usercourse.deselect_all")} onClick={unmarkAll} />
                    </Row>
                  </Col>
                </TopLeft>
              </Hidden>
              <TopRight>
                <Column>
                  <DropDown
                    options={jointOptions}
                    value={previewJoint}
                    onChange={v => {
                      setPreviewJoint(v);
                    }}
                  />
                </Column>
                <Column>
                  <DropDown
                    options={sortOptions}
                    value={selectedSort}
                    onChange={v => {
                      setSelectedSort(v);
                    }}
                  />
                </Column>
              </TopRight>
            </TopBar>

            <SearchResults>
              {isLoading && (
                <FullWidth>
                  <Spinner />
                </FullWidth>
              )}

              {!isLoading && allLessons?.length === 0 && <NoResultsBox>{t("usercourse.no_result")}</NoResultsBox>}

              {filteredLessons.map(item => {
                const checked = existsInSelectedLessons(selectedLessons, item);

                return (
                  <LessonPickerItem
                    key={`lessonpickeritem_${item.id}`}
                    item={item}
                    checked={checked}
                    onChecked={() => {
                      if (checked) {
                        setSelectedLessons(state => state.filter(i => i.id !== item.id));
                      } else {
                        setSelectedLessons(state => [...state, item]);
                      }
                    }}
                  />
                );
              })}
            </SearchResults>
          </Library>
        </OuterMargin>
      </Root>
    </>
  );
};

export default LessonPicker;

const Library = styled.div`
  padding: "16px 0";
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const SearchResults = styled.div`
  margin-top: ${props => props.theme.spacing.S_20};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenterText = styled.div`
  text-align: center;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const NoResultsBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 20px;
  background-color: #f8f8f8;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const HeaderRow = styled.div`
  background-color: #fff;
  padding: 16px 0 12px 0;
  margin-bottom: 20px;
`;

const Root = styled.div`
  background-color: #f8f8f8;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
