import { useTheme } from "styled-components";

import { useIsPtOoo } from "contexts/ProfileContext";
import { OOO_BANNER_HEIGHT } from "shared/atoms/OooBanner/OooBanner";
import { NAVBAR_HEIGHT } from "shared/NavBar/NavBar";

const usePageContentOffsetTop = (): number => {
  const theme = useTheme();
  const isPtOoo = useIsPtOoo();

  if (window.innerWidth > parseInt(theme.breakpoint, 10)) {
    return isPtOoo ? OOO_BANNER_HEIGHT : 0;
  }
  return isPtOoo ? NAVBAR_HEIGHT + OOO_BANNER_HEIGHT : NAVBAR_HEIGHT;
};

export default usePageContentOffsetTop;
