import type React from "react";
import { useEffect, useState } from "react";

import { startOfDay, startOfToday } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TextButton } from "shared/atoms/Button";
import DatePicker from "shared/molecules/DatePicker";
import useLocalizedDate from "utils/date";

const StyledTextButton = styled(TextButton)`
  color: ${props => props.theme.colors.redesign.b100};
  padding: ${props => props.theme.spacing.S_10};
  ${props => props.theme.font.caption}
  font-weight: ${props => props.theme.fontWeight.bold};
  min-width: auto;
  font-variant: normal;
  text-transform: none;
`;

export const Text = styled.span`
  ${props => props.theme.font.header4};
  color: ${props => props.theme.colors.greys.dark};
`;

const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

interface Props {
  readonly defaultDate: string;
  readonly isEditingDateEnabled: boolean;
  readonly open: boolean;
  readonly onChange: (date: string) => void;
}

const MedicalNoteDate: React.VFC<Props> = ({ defaultDate, isEditingDateEnabled, open, onChange }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const { format, parseISO, sub } = useLocalizedDate();
  const [editedDate, setEditedDate] = useState(parseISO(defaultDate));

  useEffect(() => {
    if (editing && !open) {
      setEditing(false);
    }
  }, [open]);

  if (!defaultDate) {
    return null;
  }

  return (
    <Container>
      {editing ? (
        <DatePicker
          initialDate={parseISO(defaultDate)}
          range={[startOfDay(sub(parseISO(defaultDate), { years: 1 })), startOfToday()]}
          onChanged={date => onChange(date.toISOString())}
        />
      ) : (
        <Text data-testid="noteStartTime">{format(parseISO(defaultDate), "PP")}</Text>
      )}

      {open && isEditingDateEnabled && !editing && (
        <StyledTextButton
          type="button"
          data-testid="editNoteStartTimeButton"
          onClick={event => {
            setEditing(true);
            setEditedDate(parseISO(defaultDate));
            event.stopPropagation();
          }}
        >
          {t("buttons.edit").toUpperCase()}
        </StyledTextButton>
      )}

      {open && editing && (
        <>
          <StyledTextButton
            type="button"
            onClick={event => {
              onChange(editedDate.toISOString());
              setEditing(false);
              event.stopPropagation();
            }}
          >
            {t("buttons.cancel").toUpperCase()}
          </StyledTextButton>
        </>
      )}
    </Container>
  );
};

export default MedicalNoteDate;
