import type React from "react";

import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface MenuItemProps {
  to: string;
  children: React.ReactNode;
  dataTestId?: string;
}

const MenuItem: React.VFC<MenuItemProps> = ({ to, children, dataTestId }) => {
  const navigate = useNavigate();
  const match = useMatch("*");
  const toCompare = to.split("/")[3];
  const original = match?.pathname.split("/")[3];

  return (
    <Item data-testid={dataTestId} onClick={() => navigate(to)} $active={original === toCompare}>
      {children}
    </Item>
  );
};

export default MenuItem;

const Item = styled.div<{ $active: boolean }>`
  cursor: pointer;
  height: 46px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-bottom: none;
  margin-left: -1px;
  background: ${props => props.theme.colors.white};
  ${props =>
    props?.$active &&
    `
    border-bottom: 2px solid ${props.theme.colors.primary.base};
  `}
  &:hover {
    background: ${props => props.theme.colors.greys.light4};
  }
  &:first-child {
    border-top-left-radius: ${props => props.theme.borderRadius.basic};
    margin-left: 0;
  }
  &:last-child {
    border-top-right-radius: ${props => props.theme.borderRadius.basic};
  }

  ${props => props.theme.belowBreakpoint} {
    border-width: 1px 0 !important;
    border-radius: 0 !important;
    background: ${props => props.theme.colors.white} !important;
    ${props => props?.$active && "border-bottom-width: 2px !important"};
  }
`;
