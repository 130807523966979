import type React from "react";
import { useContext } from "react";

import { ThemeContext } from "styled-components";

import { useWindowSize } from "utils/hooks";

interface Props {
  readonly children: React.ReactNode;
  readonly type: "aboveTablet" | "belowTablet" | "aboveMobile" | "belowMobile" | "aboveLarge" | "belowLarge";
}

const Hidden: React.VFC<Props> = ({ children, type }) => {
  const { width } = useWindowSize();
  const theme = useContext(ThemeContext);

  switch (type) {
    case "aboveTablet":
      return width < Number(theme.breakpoint) ? <>{children}</> : null;
    case "belowTablet":
      return width > Number(theme.breakpoint) ? <>{children}</> : null;
    case "aboveMobile":
      return width < Number(theme.breakpointMobile) ? <>{children}</> : null;
    case "belowMobile":
      return width > Number(theme.breakpointMobile) ? <>{children}</> : null;
    case "aboveLarge":
      return width < Number(theme.breakpointLarge) ? <>{children}</> : null;
    case "belowLarge":
      return width > Number(theme.breakpointLarge) ? <>{children}</> : null;
    default:
      return null;
  }
};

export default Hidden;
