import type { FC } from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CrossIcon, EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import Dropdown from "shared/atoms/inputs/Dropdown";
import { StyledInput } from "shared/atoms/inputs/StyledInputs";
import { Notification } from "shared/atoms/Notification";
import DatePicker from "shared/molecules/DatePicker";
import useLocalizedDate from "utils/date";

import type { NewTherapistLicense } from "./queries/createUsTherapistLicense";
import createUsTherapistLicense from "./queries/createUsTherapistLicense";
import deleteUsTherapistLicense from "./queries/deleteUsTherapistLicense";
import type { UsTherapistLicense } from "./queries/useUsTherapistLicenses";
import useUsTherapistLicenses from "./queries/useUsTherapistLicenses";

const truncate = (s: string, len: number) => {
  if (s.length > len) {
    return `${s.substring(0, len)}...`;
  }
  return s;
};

const isBlank = (s: string) => {
  return /^\s*$/.test(s);
};

const US_STATES: Record<string, string> = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const ProfileUsLicenses: FC = () => {
  const { t } = useTranslation();
  const { usTherapistLicenses, refetch } = useUsTherapistLicenses();
  const { profile } = useProfileContext();
  const { add } = useLocalizedDate();
  const [submitError, setSubmitError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors },
  } = useForm<NewTherapistLicense>({
    defaultValues: {
      stateCode: "",
      licenseNumber: "",
    },
  });

  const [expirationDate, setExpirationDate] = useState(new Date());

  const submitNewLicense = async (newLicense: NewTherapistLicense) => {
    setSubmitError(false);
    if (isBlank(newLicense.licenseNumber)) {
      setError("licenseNumber", {
        type: "manual",
        message: "required",
      });
      return;
    }

    if (profile) {
      await createUsTherapistLicense(profile.id, { ...newLicense, expirationDate }).catch(error => {
        const status = error?.request?.status;
        if (status >= 400 && status <= 499) {
          setSubmitError(true);
        } else {
          throw error;
        }
      });
      refetch();
      reset();
    }
  };

  const submitDeleteLicense = async (id: number) => {
    await deleteUsTherapistLicense(id);
    refetch();
  };
  const handleChangeUsLicensesClick = () => {
    reset();
    setIsEditing(!isEditing);
    setSubmitError(false);
  };

  return (
    <ProfileUsLicensesContainer data-testid="profile-us-licenses-container">
      <StyledHeader data-testid="profile-us-licenses-header">{t("therapist_settings.us_license_header")}</StyledHeader>

      {usTherapistLicenses &&
        usTherapistLicenses.map(usTherapistLicense => (
          <ActiveLicenseRow
            key={usTherapistLicense.code}
            usTherapistLicense={usTherapistLicense}
            submitDeleteLicense={submitDeleteLicense}
            isEditing={isEditing}
          />
        ))}

      <StyledForm onSubmit={handleSubmit(submitNewLicense)}>
        {isEditing && (
          <>
            <StyledSubHeader>{t("therapist_settings.us_license_explanation")}</StyledSubHeader>
            <StyledTable>
              <tbody>
                <InputRow data-testid="us-license-state-code">
                  <td> {t("therapist_settings.us_license_state_code")} </td>
                  <InputColumn>
                    <StateSelectDropdown
                      {...register("stateCode")}
                      options={Object.keys(US_STATES).map(stateCode => ({
                        value: stateCode,
                        label: `${US_STATES[stateCode]} (${stateCode})`,
                      }))}
                    />
                  </InputColumn>
                </InputRow>

                <InputRow data-testid="us-license-number">
                  <td>{t("therapist_settings.us_license_number")}</td>
                  <InputColumn>
                    <LicenseNumberInput
                      error={errors.licenseNumber && errors.licenseNumber.toString()}
                      placeholder="123"
                      type="text"
                      {...register("licenseNumber")}
                    />
                  </InputColumn>
                </InputRow>
                <InputRow>
                  <td>{t("therapist_settings.us_license_expiration_date")}</td>
                  <InputColumn>
                    <DatePicker
                      initialDate={expirationDate}
                      range={[new Date(), add(new Date(), { years: 4 })]}
                      onChanged={date => setExpirationDate(date)}
                    />
                  </InputColumn>
                </InputRow>
              </tbody>
            </StyledTable>
          </>
        )}

        {submitError && (
          <Notification variant="danger" style={{ margin: "18px auto" }}>
            {t("therapist_settings.us_license_invalid_license")}
          </Notification>
        )}
        {isEditing ? (
          <Wrapper>
            <TextButton data-testid="us-license-cancel" onClick={handleChangeUsLicensesClick}>
              {t("buttons.cancel")}
            </TextButton>
            <StyledPrimaryButton data-testid="us-license-submit" type="submit">
              {t("buttons.save")}
            </StyledPrimaryButton>
          </Wrapper>
        ) : (
          <IconButton
            dataTestId="edit-us-licenses-button"
            label={t("settings.profile_information.change_us_licenses")}
            onClick={handleChangeUsLicensesClick}
          >
            <EditIcon />
          </IconButton>
        )}
      </StyledForm>
    </ProfileUsLicensesContainer>
  );
};

const ActiveLicenseRow: FC<{
  usTherapistLicense: UsTherapistLicense;
  submitDeleteLicense: (id: number) => void;
  isEditing: boolean;
}> = ({ usTherapistLicense, submitDeleteLicense, isEditing }) => {
  const { id, license_number, expiration_date, code, name } = usTherapistLicense;

  return (
    <ActiveLicense key={code} data-testid={`active-license-${code}`}>
      <div>
        {name} ({code})
      </div>
      <div title={license_number}>{license_number && `#${truncate(license_number, 15)}`}</div>
      <div>{expiration_date}</div>
      {isEditing && (
        <RemoveLicenseButton data-testid="delete-license" onClick={() => submitDeleteLicense(id)}>
          <CrossIcon />
        </RemoveLicenseButton>
      )}
    </ActiveLicense>
  );
};

const StyledForm = styled.form`
  margin-top: 40px;
`;

const StyledTable = styled.table`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InputRow = styled.tr`
  height: 60px;
`;

const InputColumn = styled.td`
  padding-left: 20px;
`;

// TODO: this color is probably defined somewhere
const ActiveLicense = styled.div`
  background-color: ${props => props.theme.colors.greys.light4};
  font-size: 17px;
  padding: 10px;
  padding-right: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-width: 500px;
  position: relative;
  margin-top: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.belowMobileBreakpoint} {
    display: block;
  }

  & div {
    min-width: 150px;
  }
`;

const RemoveLicenseButton = styled.span`
  position: absolute;
  right: 10px;
  top: 8px;
  font-size: 23px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledHeader = styled.h4`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const ProfileUsLicensesContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
  width: 100%;
`;

const StyledSubHeader = styled.h5`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  margin: 0px;
  font-weight: ${props => props.theme.fontWeight.light};
`;

const LicenseNumberInput = styled(StyledInput)`
  width: 240px;
  margin-top: 10px;
`;

const StateSelectDropdown = styled(Dropdown)`
  width: 230px;
  & select {
    width: 240px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

export default ProfileUsLicenses;
