import type React from "react";
import { useState } from "react";

import type { VoiceCallData } from "routes/voicecall";

import VoiceCallContext from "./VoiceCallContext";

const VoiceCallContextProvider: React.FC = ({ children }) => {
  const [voiceCallData, setVoiceCallData] = useState<VoiceCallData | null>(null);

  const value = {
    voiceCallData,
    setVoiceCallData,
  };

  return <VoiceCallContext.Provider value={value}>{children}</VoiceCallContext.Provider>;
};

export default VoiceCallContextProvider;
