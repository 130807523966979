import { useContext } from "react";

import CalendarContext from "./CalendarContext";
import type { CalendarContextValue } from "./CalendarContextValue";

const useCalendarContext = (): CalendarContextValue => {
  const value = useContext(CalendarContext);

  if (value === null) {
    throw new Error("useCalendarContext cannot be used outside the CalendarContextProvider");
  }
  return value;
};

export default useCalendarContext;
