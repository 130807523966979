import type React from "react";
import { useContext } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { CareEvent } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import { CommunicationGroupIcon } from "routes/dashboard/default/components/MedicalNotesTable/CommunicationGroupIcon";
import { AccordionItem } from "shared/atoms/Accordion";
import { MUITextInput } from "shared/atoms/inputs";
import type { MedicalNoteSectionConfig } from "types";
import { MedicalNoteSectionTypes } from "types";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { userIsPartner } from "utils/profile/profileHelper";

import { DynamicSection, SectionRow, SystemSuggestionsSection } from "../../../DynamicMedicalNotes/components";
import { reduceCareEventToFields } from "../../../DynamicMedicalNotes/helpers/careEventHelpers";
import { getAllFieldNamesFromConfig } from "../../../DynamicMedicalNotes/helpers/dynamicMedicalNoteHelpers";
import { Form } from "../../../DynamicMedicalNotes/utils/formStyles";
import MedicalNoteLabel from "../../../PatientHeader/SignNotesTable/components/MedicalNoteLabel";
import { Addenda } from "../Addenda";
import { PDFDownloadButton } from "../PDFDownload";

interface Props {
  record: CareEvent;
  config: MedicalNoteSectionConfig[];
}

const RecordNote: React.VFC<Props> = ({ record, config }) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const isPartner = userIsPartner(profile);
  const readonly = true; // turn into state when implementing Amendments

  const fieldsNames = getAllFieldNamesFromConfig(config, false);

  const form = useForm({
    defaultValues: reduceCareEventToFields(fieldsNames, record),
    mode: "onChange",
  });

  const patientBirthday =
    patient.birthday && new Date(patient.birthday).toLocaleDateString(profile?.locale, { timeZone: "UTC" });
  const personalNumber = patient?.personal_number;

  const getSection = (section: MedicalNoteSectionConfig) => {
    const onValueChange = () => null;

    if (section.type === MedicalNoteSectionTypes.EXCLUSION) return null;

    if (section.type === MedicalNoteSectionTypes.SUGGESTIONS && section.fields?.length)
      return (
        <SystemSuggestionsSection
          fields={section.fields}
          key="system-suggestions-section"
          collapsible={false}
          backgroundColor="default"
          readOnly
        />
      );

    return (
      <DynamicSection
        key={`dynamic-section-${section.name}`}
        name={section.name}
        readonly={readonly}
        fields={section.fields}
        forcedOptional={false}
        onChange={onValueChange}
        collapsible={false}
        payer={record.payer}
        label={record.label}
        isNoteSigned
      />
    );
  };

  return (
    <AccordionItem
      buttonLabel={t("patients.comments.show_more")}
      print
      header={
        <HeaderWrapper>
          <DateWrapper data-testid="record-note-date">{format(parseISO(record.start_time ?? ""), "PP")}</DateWrapper>
          <TextWrapper>
            <CommunicationGroupIcon communicationGroup={record.communication_group} />
            <MedicalNoteLabel weekNumber={record.week_number} careEventLabel={record.label} />
          </TextWrapper>
        </HeaderWrapper>
      }
      content={
        <>
          {isPartner && <PDFDownloadButton config={config} record={record} patientBirthday={patientBirthday} />}
          <FormProvider {...form}>
            <Form open={!readonly}>
              <ContentWrapper>
                <Section>
                  <StyledSectionRow>
                    <NameField
                      name="health_care_professional_name"
                      label={t("patients.medical_records.responsible")}
                      value={record.health_care_professional_name}
                      disabled={readonly}
                      unregistered
                    />
                    <DateField
                      name="health_care_professional_type"
                      label={t("patients.medical_records.role")}
                      value={record.health_care_professional_type}
                      disabled={readonly}
                      unregistered
                    />
                  </StyledSectionRow>
                  <StyledSectionRow>
                    <NameField
                      name="patient_name"
                      label={t("patients.extended_medical_note.patient_name.label")}
                      value={`${patient.first_name} ${patient.last_name}`}
                      disabled={readonly}
                      unregistered
                    />
                    <DateField
                      name={personalNumber ? "patient_personal_number" : "patient_birthday"}
                      label={
                        personalNumber
                          ? t("patients.medical_records.personal_number")
                          : t("patients.extended_medical_note.patient_birthday.label")
                      }
                      value={personalNumber || patientBirthday}
                      disabled={readonly}
                      unregistered
                    />
                  </StyledSectionRow>
                </Section>
                {config
                  .filter(section => section.fields && section.fields.length > 0)
                  .map(section => getSection(section))}
              </ContentWrapper>
            </Form>
          </FormProvider>
          {!isPartner && <Addenda careEventId={record.id} medical_note_addenda={record.medical_note_addenda} />}
        </>
      }
    />
  );
};

const Section = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  padding-right: 120px;

  ${props => props.theme.belowBreakpoint} {
    padding-right: ${props => props.theme.spacing.S_20};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.dark};
  overflow: hidden;
`;

const DateWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  min-width: 130px;
  flex-shrink: 0;
`;

const TextWrapper = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${props => props.theme.spacing.S_30};
`;

const StyledSectionRow = styled(SectionRow)`
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const NameField = styled(MUITextInput)`
  flex: 1;
  margin-right: ${props => props.theme.spacing.S_15};

  ${props => props.theme.belowBreakpoint} {
    margin-right: 0;
  }
`;

const DateField = styled(MUITextInput)`
  width: 135px;
  flex: none;

  ${props => props.theme.belowBreakpoint} {
    width: auto;
    flex: 1;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export default RecordNote;
