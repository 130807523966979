import type React from "react";

import { useTranslation } from "react-i18next";

import type { HealthReport } from "api/models/HealthReport";

import type { PainLocation, Question } from "../../helpers/helpers";
import { answersChanged } from "../../helpers/helpers";
import { ContentRow, MarginWrapper } from "../helpers";
import ReportQuestionAnswer from "../ReportQuestionAnswer";
import ReportQuestionHeader from "../ReportQuestionHeader";
import ReportQuestionWrapper from "../ReportQuestionWrapper";

interface ReportQuestionProps {
  question: Question;
  selected: HealthReport[];
  painLocation: PainLocation;
  parent?: Question;
}

const ReportQuestion: React.VFC<ReportQuestionProps> = ({ question, selected, painLocation, parent }) => {
  const { t } = useTranslation();
  const parentKey = parent ? parent.key : null;
  const changed = answersChanged(selected, question.key, Boolean(parentKey), parentKey || "", false);

  const isVasScale = question.key === "overall_health_vas" || question.key === "feeling_last_days";
  const minScale = isVasScale ? t("patients.health_reports.vas_min") : t("patients.health_reports.low");
  const maxScale = isVasScale ? t("patients.health_reports.vas_max") : t("patients.health_reports.high");

  const getReportQuestionTitle = () => {
    // TODO: Delete this when this translation supports ICU
    // See: https://jointacademy.atlassian.net/browse/CCC-1180
    if (painLocation === "neck" && question.key === "oa_regular_activities_effect") {
      return t("patients.health_reports.questions_content.oa_regular_activities_effect_neck");
    }

    if (question.params) {
      return t(
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `patients.health_reports.questions_content.${question.title_key ? question.title_key : question.key}`,
        question.params
      );
    }

    switch (question.type) {
      case "fes":
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t(`patients.health_reports.questions_content.fes_hq.${question.key}`);
      default:
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t(`patients.health_reports.questions_content.${question.title_key ? question.title_key : question.key}`);
    }
  };

  return question.type !== "hidden_header" ? (
    <>
      <ReportQuestionHeader {...question} />
      <ContentRow data-testid="hr-row">
        <ReportQuestionWrapper subQuestion={parent && parent.type !== "hidden_header"}>
          {getReportQuestionTitle()}
          {question.description && (
            <>
              <MarginWrapper>{`${minScale}: ${t(
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `patients.health_reports.questions_content.${question.description[0]}`
              )}`}</MarginWrapper>
              <MarginWrapper>{`${maxScale}: ${t(
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `patients.health_reports.questions_content.${question.description[1]}`
              )}`}</MarginWrapper>
            </>
          )}
          {question.subtitle && (
            <>
              {/* FIXME: type translation */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <MarginWrapper>{`${t(`patients.health_reports.questions_content.${question.subtitle}`)}`}</MarginWrapper>
            </>
          )}
        </ReportQuestionWrapper>
        <ReportQuestionAnswer
          changed={changed}
          parent={parent}
          question={question}
          selected={selected}
          painLocation={painLocation}
        />
      </ContentRow>
    </>
  ) : (
    <></>
  );
};

export default ReportQuestion;
