import type { MedicalNoteSectionConfig } from "types";
import { MedicalNoteReadonlyFieldTypes } from "types";
import type { CareEventFieldPath } from "types/MedicalNoteConfig";

const getFieldNamesFromConfig =
  (editableOnly: boolean) =>
  (config: MedicalNoteSectionConfig[], isEditingDateEnabled: boolean): CareEventFieldPath[] =>
    config
      .flatMap(em => em.fields || [])
      .filter(field => !editableOnly || !MedicalNoteReadonlyFieldTypes.some(fieldType => fieldType === field.type))
      // single checkbox in UI updates two fields in backend because reasons
      .flatMap(({ name }) => {
        if (name !== "confirmed_insurance_and_identity") return name;
        return ["confirmed_identity", "confirmed_insurance"] as const;
      })
      .concat(isEditingDateEnabled ? ["start_time"] : [])
      .concat(config.some(section => section.type === "exclusion") ? ["structured_fields.exclusion_reason"] : []);

const getEditableFieldNamesFromConfig = getFieldNamesFromConfig(true);
const getAllFieldNamesFromConfig = getFieldNamesFromConfig(false);

export { getEditableFieldNamesFromConfig, getAllFieldNamesFromConfig };
