import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { MedicalNoteFieldConfig } from "types";
import { getSectionTitleKey } from "utils/medicalNotes";

import { Section, SystemSuggestionsSubsection } from ".";

interface Props {
  readonly fields: MedicalNoteFieldConfig[];
  readonly collapsible?: boolean;
  readonly backgroundColor?: "white" | "default";
  readonly readOnly?: boolean;
}

interface Subsection {
  readonly name: string;
  fields: MedicalNoteFieldConfig[];
}

const reduceFieldsToSubsections = (subsections: Subsection[], field: MedicalNoteFieldConfig) => {
  if (!field.subsection) return subsections;
  const existingSubsection = subsections.find(subsection => subsection.name === field.subsection);
  if (existingSubsection) {
    return subsections.map(subsection =>
      subsection.name === field.subsection ? { ...subsection, fields: [...subsection.fields, field] } : subsection
    );
  }
  return [...subsections, { name: field.subsection, fields: [field] }];
};

const SystemSuggestionsSection: React.VFC<Props> = ({
  fields,
  collapsible = true,
  backgroundColor = "white",
  readOnly,
}) => {
  const { t } = useTranslation();

  return (
    <Section
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label={t(getSectionTitleKey("system_suggestions"))}
      dataTestId="system-suggestions-section"
      collapsedInitially
      collapsible={collapsible}
      backgroundColor={backgroundColor}
      readOnly={readOnly}
    >
      <Wrapper>
        {fields.reduce(reduceFieldsToSubsections, [] as Subsection[]).map(subsection => (
          <SystemSuggestionsSubsection subsection={subsection} key={subsection.name} />
        ))}
      </Wrapper>
    </Section>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
`;

const Subsection = styled.div`
  flex: 1 0 50%;
  background-color: ${({ theme }) => theme.colors.system_suggestions_section.background};
  border-left: 1px solid ${({ theme }) => theme.colors.system_suggestions_section.divider};
  margin-left: -1px;

  ${props => props.theme.belowBreakpoint} {
    flex: 1 0 100%;
    border-left: none;
  }
`;

export { SystemSuggestionsSection };
