import { Link } from "react-router-dom";
import styled from "styled-components";

import Search from "shared/molecules/Search";
import { TableCell } from "shared/molecules/Table";

const TableContainer = styled.div<{ $height: string }>`
  height: ${({ $height }) => $height};
`;

const StyledIcon = styled.div`
  margin: 4px 4px 0 0;
`;
const AfterNameIcon = styled.div`
  margin: 12px auto auto ${props => props.theme.spacing.S_10};
`;
const Wrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_30};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.spacing.S_10} 0;
`;

const NameWrapper = styled(TableCell)`
  width: auto;
  min-width: 350px;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    margin-bottom: ${props => props.theme.spacing.S_10};
    padding: 0 ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_5}
      ${props => props.theme.spacing.S_10};
  }
`;

const PreferredNameWrapper = styled(TableCell)`
  width: auto;
  min-width: 160px;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const PrimaryJointWrapper = styled(TableCell)`
  width: auto;
  min-width: 160px;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const DateWrapper = styled(TableCell)`
  width: auto;
  min-width: 125px;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  min-height: 40px;
`;

const StatusWrapper = styled(TableCell)`
  width: 15%;
  white-space: nowrap;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const SmallWrapper = styled(TableCell)`
  width: 10%;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const CoverageWrapper = styled(TableCell)`
  width: 20%;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const Name = styled.div<{ deleted: boolean }>`
  text-decoration: ${props => (props.deleted === true ? "line-through" : "none")};
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const DisplayNumber = styled.div`
  margin: auto;
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const DisplayAdherence = styled(DisplayNumber)<{ adherence: number }>`
  color: ${props => props.adherence < 60 && props.theme.colors.orange};
`;

const StyledSearch = styled(Search)`
  ${props => props.theme.belowBreakpoint} {
    height: 40px;
  }
  ${props => props.theme.belowMobileBreakpoint} {
    width: 100%;
    max-width: 240px;
  }
`;

const TableLink = styled(Link)<{ $minHeight: string }>`
  display: flex;
  align-items: center;
  min-height: ${({ $minHeight }) => $minHeight || "auto"};
  width: 100%;
  text-decoration: none;
`;

export {
  TableContainer,
  NameWrapper,
  PreferredNameWrapper,
  PrimaryJointWrapper,
  DateWrapper,
  InnerWrapper,
  StatusWrapper,
  SmallWrapper,
  CoverageWrapper,
  Name,
  DisplayNumber,
  DisplayAdherence,
  Container,
  StyledIcon,
  AfterNameIcon,
  StyledSearch,
  Wrapper,
  TableLink,
};
