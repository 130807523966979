import Cookies from "js-cookie";

interface AuthenticationHeaders {
  Authorization: string;
}

const getRestHeaders = (): AuthenticationHeaders | null => {
  const idToken = Cookies.get("rja_auth_id_token");
  if (idToken) {
    return {
      Authorization: `Bearer ${idToken}`,
    };
  }

  return null;
};

export default getRestHeaders;
