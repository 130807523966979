import type { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { AxiosInstance } from "api";
import { useProfileContext } from "contexts/ProfileContext";
import { QUERY_KEYS } from "types/QueryKeys";

interface UnreadMessagesCountVars {
  profileId?: number;
}

interface UnreadMessagesCount {
  count: number;
}

interface UnreadMessagesQuery {
  response?: AxiosResponse<UnreadMessagesCount>;
  error: unknown;
  isLoading: boolean;
  unreadMessagesCount: number;
}

const fetchUnreadMessagesCount = ({
  profileId,
}: UnreadMessagesCountVars): Promise<AxiosResponse<UnreadMessagesCount>> =>
  AxiosInstance.get(`users/${profileId}/unread_messages_count`);

const useUnreadMessagesCount = (): UnreadMessagesQuery => {
  const { profile } = useProfileContext();
  const profileId = profile?.id;
  const { data, error, isLoading } = useQuery(
    [QUERY_KEYS.unreadMessagesCount, { profileId }],
    () => fetchUnreadMessagesCount({ profileId }),
    { refetchInterval: 30000 }
  );
  return { response: data, error, isLoading, unreadMessagesCount: data?.data.count ?? 0 };
};

export default useUnreadMessagesCount;
