import type React from "react";
import { useState } from "react";

import { faCalendarCheck, faChevronDown, faClock, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import type { Form } from "contexts/CalendarContext";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import DropdownButton from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/components/DropdownButton";
import { MarketTypes } from "types";
import { isUserMarket, userIsAdmin } from "utils/profile/profileHelper";

export const AddButton: React.VFC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { profile } = useProfileContext();
  const { setActiveForm, setSelectedBookingInterval, setSelectedAppointment } = useCalendarContext();

  const [showFilters, setShowFilters] = useState(false);

  invariant(profile);

  const isAdmin = userIsAdmin(profile);
  const isSE = isUserMarket(MarketTypes.SE, profile);

  const onClick = (form: Form) => {
    setSelectedBookingInterval(null);
    setSelectedAppointment(null);
    setActiveForm(form);

    if (form === "create_patient") {
      navigate("book/create_patient");
      return;
    }

    navigate("/calendar");
  };

  const filters = [
    {
      children: (
        <Filter>
          <Icon>
            <StyledFontAwesomeIcon icon={faCalendarCheck} color={theme.colors.redesign.db50} />
          </Icon>
          {t("booking.forms.book_appointment")}
        </Filter>
      ),
      onClick: () => onClick("book_appointment"),
    },
    ...(isAdmin && isSE
      ? [
          {
            children: (
              <Filter>
                <Icon>
                  <StyledFontAwesomeIcon icon={faUser} color={theme.colors.redesign.db50} />
                </Icon>
                {t("booking.forms.create_patient")}
              </Filter>
            ),
            onClick: () => onClick("create_patient"),
          },
        ]
      : []),
    {
      children: (
        <Filter>
          <Icon>
            <StyledFontAwesomeIcon icon={faClock} color={theme.colors.redesign.db50} />
          </Icon>
          {t("booking.forms.availability")}
        </Filter>
      ),
      onClick: () => onClick("availability"),
    },
  ];

  return (
    <StyledDropdownButton
      id="calendar-filters"
      options={filters}
      border={false}
      onClick={() => setShowFilters(true)}
      onClose={() => setShowFilters(false)}
      $showFilters={showFilters}
      dropdownOrigin="left"
    >
      {t("booking.forms.header")} <StyledFontAwesomeIcon icon={faChevronDown} color={theme.colors.white} />
    </StyledDropdownButton>
  );
};

const StyledDropdownButton = styled(DropdownButton)<{ $showFilters: boolean }>`
  height: 40px;
  width: fit-content;
  position: absolute;
  left: 0;

  background: ${({ theme }) => theme.components.calendar.btn_bg};
  border: 1px solid ${({ theme }) => theme.components.calendar.btn_bg} !important;
  border-radius: 8px;

  #DropdownButton {
    padding: 0 16px;
    color: ${({ theme }) => theme.colors.white};
    background: none;

    > svg {
      margin: 0 0 0 8px;
    }
  }

  ${props => props.theme.belowBreakpoint} {
    left: 8px;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  min-width: 16px;
  margin-right: 8px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
`;
