import type { ChannelEvents } from "@anycable/core";
import { Channel } from "@anycable/web";

import type { Session } from "contexts/DropInCallContext/DropInCallContextValue";

type Params = Record<string, never>;

interface OnAvailableMessage {
  type: "on_available";
  data: { is_available: boolean };
  ref: string | null;
}

interface OnUpdateMessage {
  type: "on_update";
  data: { session: Session };
  ref: string | null;
}

interface OnErrorMessage {
  type: "on_error";
  data: {
    cause: string;
    errors: string[];
  };
  ref: string | null;
}

type TherapistChannelMessage = OnAvailableMessage | OnErrorMessage | OnUpdateMessage;

interface TherapistChannelEvents extends ChannelEvents<TherapistChannelMessage> {
  on_available: (is_available: boolean) => void;
  on_update: (session: Session) => void;
  on_error: (cause: string) => void;
}

export default class TherapistChannel extends Channel<Params, TherapistChannelMessage, TherapistChannelEvents> {
  static identifier = "DropInTherapistPushChannel";

  async updateAvailability(isAvailable: boolean) {
    return this.perform("available", { is_available: isAvailable });
  }

  async requestStatus() {
    return this.perform("status", { purpose: "kick_off" });
  }

  async startReviewing() {
    this.perform("update", { state: "in_review", purpose: "kick_off" });
  }

  async finishReviewing() {
    this.perform("update", { state: "completed", purpose: "kick_off" });
  }

  async cancelSession() {
    this.perform("update", { state: "canceled", purpose: "kick_off" });
  }

  async ping() {
    this.perform("ping");
  }

  receive(msg: TherapistChannelMessage) {
    const { type, data } = msg;
    switch (type) {
      case "on_available": {
        const { is_available } = data as OnAvailableMessage["data"];
        return this.emit("on_available", is_available);
      }
      case "on_update": {
        const { session } = data as OnUpdateMessage["data"];
        return this.emit("on_update", session);
      }
      case "on_error": {
        const { cause } = data as OnErrorMessage["data"];
        return this.emit("on_error", cause);
      }
      default:
        return super.receive(msg);
    }
  }
}
