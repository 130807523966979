import styled from "styled-components";

const Description = styled.div`
  ${props => ({ ...props.theme.font.body1 })};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.dark};
`;

const ScheduleCallForm = styled.div<{ $isPatientProfile: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    "header header header"
    "calendar patient patient"
    "calendar starts ends"
    "calendar purpose purpose"
    "calendar type type"
    "calendar buttons buttons";
  row-gap: ${props => props.theme.spacing.S_10};
  column-gap: ${props => props.theme.spacing.S_20};
  margin: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_15};

  ${props => props.theme.belowBreakpoint} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "calendar"
      "patient"
      "starts"
      "ends"
      "purpose"
      "type"
      "buttons";
    row-gap: ${props => props.theme.spacing.S_15};
    column-gap: ${props => props.theme.spacing.S_5};

    /* TODO: Hack to make it look okay on mobile */
    margin-top: ${({ $isPatientProfile }) => ($isPatientProfile ? "250px" : "90px")};
  }
`;

const Header = styled(Description)`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CalendarAndCalls = styled.div`
  grid-area: calendar;
`;

const Patient = styled(Description)`
  grid-area: patient;
`;

const Starts = styled(Description)`
  grid-area: starts;
`;

const Ends = styled(Description)`
  grid-area: ends;
`;

const PurposeWrapper = styled(Description)`
  grid-area: purpose;
`;

const Type = styled(Description)`
  grid-area: type;
`;

const Buttons = styled(Description)`
  grid-area: buttons;
`;

const WarningContainer = styled.div`
  grid-area: warnings;
`;

const ErrorContainer = styled.div`
  grid-area: error;
  padding-bottom: 22px;
  align-self: flex-end;
`;

const Warning = styled.div`
  ${props => ({ ...props.theme.font.body1 })};
  font-weight: ${props => props.theme.fontWeight.light};
  color: ${props => props.theme.colors.greys.dark};
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: ${props => props.theme.spacing.S_15};
  &:last-of-type {
    margin-bottom: 0;
  }
  svg {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    margin-right: ${props => props.theme.spacing.S_10};
  }
`;

export {
  WarningContainer,
  ErrorContainer,
  Warning,
  ScheduleCallForm,
  Header,
  CalendarAndCalls,
  Patient,
  Starts,
  Ends,
  PurposeWrapper,
  Type,
  Buttons,
};
