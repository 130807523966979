import type { AxiosResponse } from "axios";
import type { UseMutationResult } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import { AxiosInstance } from "api";
import { QUERY_KEYS } from "types/QueryKeys";

interface ExerciseRecommendationsVars {
  patientId: string;
  exercise_level_ids: number[];
}

interface Exercise {
  created_at: string;
  exercise_level_id: number;
  id: number;
  patient_id: number;
}

const postExerciseRecommendations = ({
  patientId,
  exercise_level_ids,
}: ExerciseRecommendationsVars): Promise<AxiosResponse<Exercise[]>> =>
  AxiosInstance.post(`patients/${patientId}/exercise_level_recommendations`, { exercise_level_ids });

const useUpdateExerciseRecommendations = (): UseMutationResult<
  AxiosResponse<Exercise[]>,
  unknown,
  ExerciseRecommendationsVars,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ patientId, exercise_level_ids }: ExerciseRecommendationsVars) =>
      postExerciseRecommendations({ patientId, exercise_level_ids }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.patient.protocol);
      },
    }
  );
};

export default useUpdateExerciseRecommendations;
