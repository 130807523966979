import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePutPatientLateralLocation } from "api/hooks/usePutPatientLateralLocation";
import type { MostPainfulJointLocation } from "api/schemas/patients/HealthJournal";
import { JointButton } from "routes/calendar/components/AdminBookingForm/screens/HealthQuestionnaire/JointScreen";
import { ailmentConfig } from "routes/calendar/helpers/getAilments";
import { BaseButton } from "shared/atoms/BaseButton";
import { isErrorOfType, reportError } from "utils/errorReporting";
import capitalize from "utils/string/capitalize";

import { StyledFormState } from "./styles";

const getOppositeLateralLocation = (lateralLocation?: "left" | "right" | "undefined") => {
  if (lateralLocation === "left") {
    return "right";
  }
  return "left";
};

interface Props {
  patientID: number;
  location: MostPainfulJointLocation;
  lateralLocation: "left" | "right" | "undefined";
  onClose: () => void;
  refreshPatient: () => void;
}

export const LateralSwitch: React.VFC<Props> = ({ patientID, location, lateralLocation, onClose, refreshPatient }) => {
  const { t } = useTranslation();
  const [selectedLateralSwitch, setSelectedLateralSwitch] = useState(false);
  const oppositeLateralLocation = getOppositeLateralLocation(lateralLocation);
  const currentAilment = ailmentConfig[location];

  const { mutateAsync, status } = usePutPatientLateralLocation();

  const onLateralSwitch = () => {
    if (oppositeLateralLocation) {
      try {
        mutateAsync(
          { patientID, data: { lateral_location: oppositeLateralLocation } },
          {
            onError: e => {
              reportError("LateralSwitch.tsx", e);
            },
            onSuccess: () => {
              refreshPatient();
              setTimeout(() => {
                onClose();
              }, 3000);
            },
          }
        );
      } catch (e) {
        if (isErrorOfType(e)) {
          reportError("LateralSwitch.tsx", e);
        }
      }
    }
  };

  return (
    <Container>
      <Header>{t("switch_lateral_location.label")}</Header>
      <StyledJointButton
        onClick={() => setSelectedLateralSwitch(prevState => !prevState)}
        active={selectedLateralSwitch}
      >
        <span>
          {capitalize(
            t("patients.pain_construct", {
              lateral: t(`patients.lateral_location.${oppositeLateralLocation}`),
              // FIXME: type translation
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              location: t(`patients.pain_location.${location}.0`),
            })
          )}
        </span>
        {currentAilment.icon}
      </StyledJointButton>

      {selectedLateralSwitch && (
        <BaseButton onClick={onLateralSwitch} text={t("switch_treatment.confirm")} style={{ marginTop: "16px" }} />
      )}

      <StyledFormState formState={status} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

const Header = styled.div`
  align-self: flex-start;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.redesign.db90};
  margin-left: 8px;
  margin-bottom: 16px;
`;

const StyledJointButton = styled(JointButton)`
  margin-left: 8px;
`;
