import { useMutation } from "react-query";

import type { PostTreatmentActivityLogArgs, PostTreatmentActivityLogResponse } from "api/schemas/TreatmentActivityLog";
import useApiClient from "api/useApiClient";

export const usePostTreatmentActivityLog = () => {
  const client = useApiClient();

  return useMutation<PostTreatmentActivityLogResponse, Error, PostTreatmentActivityLogArgs>(args =>
    client.postTreatmentActivityLog(args)
  );
};
