import type React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useProfileContext } from "contexts/ProfileContext";
import { Calendar } from "routes/calendar/components/Calendar";
import { BlueIconButton } from "routes/calendar/components/styles";
import useEventCards from "routes/dashboard/default/queries/useEventCards";
import { MarketTypes } from "types";
import useUnreadMessagesCount from "utils/contexts/queries/useUnreadMessagesCount";
import { isUserMarket } from "utils/profile/profileHelper";

import EarningsTable from "../components/EarningsTable";
import { EarningsWidget } from "../components/EarningsWidget";
import EventCard from "../components/EventCard";
import MedicalNotesTable from "../components/MedicalNotesTable";
import ReadyForDischargeTable from "../components/ReadyForDischargeTable";
import UpcomingAppointments from "../components/UpcomingAppointments";
import { getDashboardContent } from "../utils";

const Dashboard: React.VFC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { data, error, refetch } = useEventCards();
  const { unreadMessagesCount } = useUnreadMessagesCount();

  invariant(profile, "Profile should be defined");

  useEffect(() => {
    if (unreadMessagesCount) {
      refetch();
    }
  }, [unreadMessagesCount]);

  const cards = getDashboardContent();

  // controlled by the master hcp
  const showEarnings = profile?.therapist_profile.branded_health_care_provider.commission;
  const isUS = isUserMarket(MarketTypes.US, profile);

  return (
    <Container>
      {cards.map(({ label, link, icon, dataTestId }) => {
        return data ? (
          <EventCard
            key={label}
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t(`dashboard.event_cards.${label}`)}
            notifications={data[label]}
            linkTo={link}
            error={error}
            dataTestId={dataTestId}
          >
            {icon}
          </EventCard>
        ) : null;
      })}
      <WideElement data-testid="upcoming-appointments">
        {profile?.therapist_profile?.feature_flags?.includes("BOOKING_V2") ? (
          <Calendar
            customHeader={
              <CustomHeader>
                <div>{t("booking.calendar.header")}</div>{" "}
                <BlueIconButton
                  onClick={() => {
                    navigate("/calendar");
                  }}
                  label={t("booking.calendar.see_full_schedule")}
                />
              </CustomHeader>
            }
            interactive={false}
            navigateToCalendar
          />
        ) : (
          <UpcomingAppointments />
        )}
      </WideElement>
      <WideElement data-testid="medical-notes-table">
        <MedicalNotesTable />
      </WideElement>
      <StyledReadyForDischargeTable data-testid="ready-for-discharge-table" />
      {!isUS && showEarnings && <EarningsTable />}
      {isUS && profile.therapist_profile.feature_flags.includes("BOOKING_IGNORE_ACCEPTING_PATIENTS") && (
        <WideElement data-testid="earnings-widget">
          <EarningsWidget />
        </WideElement>
      )}
    </Container>
  );
};
export default Dashboard;

const Container = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.S_20};
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowBreakpoint} {
    grid-template-columns: 1fr;
    gap: ${props => props.theme.spacing.S_10};
    padding: ${props => props.theme.spacing.S_10};
  }
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
`;

const WideElement = styled.div`
  ${props => props.theme.aboveBreakpoint} {
    grid-column: 1 / span 2;
  }
`;

const StyledReadyForDischargeTable = styled(ReadyForDischargeTable)`
  ${props => props.theme.aboveBreakpoint} {
    grid-column: 1 / span 2;
  }
`;

const CustomHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #ddd;
  border-bottom: none;
`;
