import type React from "react";
import { useEffect } from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CheckmarkIcon, ClockColor, VideoCallIcon } from "assets";
import json from "assets/animations/dot-animation.json";
import { useDropInCallContext } from "contexts/DropInCallContext";
import useDropInCallTimerContext from "contexts/DropInCallContext/useDropInCallTimerContext";
import { useNotificationsContext } from "contexts/NotificationsContext";
import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";
import { Switch, SwitchLabel, SwitchWrapper } from "shared/atoms/Switch";
import Caption from "shared/atoms/Typography/Caption";
import DropInCallFeedbackPopup from "shared/molecules/DropInCallFeedbackPopup/DropInCallFeedbackPopup";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import DisconnectModal from "./DisconnectedModal";
import useDropInCallNotification from "./useDropInCallNotification";

interface Props {
  collapsed: boolean;
}

const DropInCallPane: React.VFC<Props> = ({ collapsed }) => {
  const { t } = useTranslation();

  const {
    toggleAvailability,
    available,
    isReady,
    currentSession,
    startReviewing,
    finishReviewing,
    expiryTime,
    errorCode,
    requestStatus,
    askForFeedback,
    setAskForFeedback,
    patientName = "",
  } = useDropInCallContext();
  const { timerTime } = useDropInCallTimerContext();
  const navigate = useNavigate();
  useDropInCallNotification();

  const sessionState = currentSession?.state;
  const patientId = currentSession?.patient_id;
  const match = useMatch(`/patients/${currentSession?.patient_id}/overview`);

  useEffect(() => {
    if (match?.pathname) {
      if (currentSession?.state === "claimed") {
        startReviewing();
      }
    }
  }, [match?.pathname, currentSession]);

  if (errorCode !== null) {
    return collapsed ? null : (
      <Pane>
        <StatusText>{t("drop_in_calls.error", { errorcode: errorCode })}</StatusText>
      </Pane>
    );
  }

  return (
    <Container>
      <DisconnectModal
        open={sessionState === "canceled"}
        name={patientName}
        onMessage={() => {
          navigate(`/messages/${patientId}`);
          requestStatus();
        }}
        onClose={() => {
          requestStatus();
        }}
      />
      <Top>
        <TinyCaption>{collapsed ? t("drop_in_calls.pane.header_small") : t("drop_in_calls.pane.header")}</TinyCaption>
      </Top>

      {isReady && !patientId && !sessionState && (
        <Toggle
          collapsed={collapsed}
          checked={available}
          toggleOn={() => {
            toggleAvailability();
            AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.TOGGLE_AVAILABLE_ON_CLICKED, { placement: "sidebar" });
          }}
          toggleOff={() => {
            toggleAvailability();
            AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.TOGGLE_AVAILABLE_OFF_CLICKED, {
              placement: "sidebar",
            });
          }}
        />
      )}

      {isReady && patientId && sessionState === "claimed" && (
        <Assigned
          collapsed={collapsed}
          onClick={() => {
            AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.START_REVIEW_CLICKED, { placement: "sidebar" });
            navigate(`/patients/${patientId}`);
          }}
          time={timerTime}
          name={patientName}
        />
      )}

      {isReady && patientId && sessionState === "in_review" && (
        <InReview
          collapsed={collapsed}
          onShortcutClick={() => {
            navigate(`/patients/${patientId}`);
          }}
          onClick={() => {
            finishReviewing();
            AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.FINISH_REVIEW_CLICKED, { placement: "sidebar" });
          }}
          time={expiryTime}
          name={patientName}
        />
      )}

      {askForFeedback && <DropInCallFeedbackPopup setOpen={setAskForFeedback} patientId={patientId} />}
    </Container>
  );
};

const Assigned = ({
  collapsed,
  onClick,
  time,
  name,
}: {
  collapsed: boolean;
  onClick: () => void;
  time: string;
  name: string;
}) => {
  const { t } = useTranslation();
  return collapsed ? (
    <MiniButton onClick={onClick}>
      <div>
        <div>{time}</div>
        <MiniButtonLabel>{t("drop_in_calls.pane.review_cta_small")}</MiniButtonLabel>
      </div>
    </MiniButton>
  ) : (
    <Pane>
      <StatusText>{t("drop_in_calls.pane.review_waiting", { name })}</StatusText>
      <AssignedTopRow>
        <ClockColorWrapper /> {time}
      </AssignedTopRow>
      <SmallPrimaryButton onClick={onClick}>{t("drop_in_calls.pane.review_cta")}</SmallPrimaryButton>
    </Pane>
  );
};

const InReview = ({
  collapsed,
  onClick,
  onShortcutClick,
  time,
  name,
}: {
  collapsed: boolean;
  onClick: () => void;
  onShortcutClick: () => void;
  time: string;
  name: string;
}) => {
  const { t } = useTranslation();
  return collapsed ? (
    <MiniButton onClick={onClick}>
      <div>
        <CheckmarkIcon />
        <MiniButtonLabel>{t("drop_in_calls.pane.finish_cta_small")}</MiniButtonLabel>
      </div>
    </MiniButton>
  ) : (
    <Pane>
      <StatusText>{t("drop_in_calls.pane.expected", { time, name })}</StatusText>
      <ShortcutButton onClick={onShortcutClick}>{t("drop_in_calls.pane.user_details")}</ShortcutButton>
      <FinishButton onClick={onClick}>{t("drop_in_calls.pane.finish_cta")}</FinishButton>
    </Pane>
  );
};

const Toggle = ({
  collapsed,
  toggleOn,
  toggleOff,
  checked,
}: {
  collapsed: boolean;
  toggleOn: () => void;
  toggleOff: () => void;
  checked: boolean;
}) => {
  const { t } = useTranslation();
  const { askForDesktopNotificationPermission } = useNotificationsContext();
  if (collapsed) {
    return (
      <MiniPane>
        <SwitchWrapper>
          <StyledSwitch
            id="enable-drop-in-call-switch"
            type="checkbox"
            checked={checked}
            onChange={() => {
              if (checked) {
                toggleOff();
              } else {
                toggleOn();
                askForDesktopNotificationPermission();
              }
            }}
          />
          <SwitchLabel htmlFor="enable-drop-in-call-switch" />
        </SwitchWrapper>
        {checked && (
          <DotsAnimationSmall>
            <Player speed={0.5} autoplay loop src={json} />
          </DotsAnimationSmall>
        )}
      </MiniPane>
    );
  }

  return (
    <Pane>
      <ToggleTopRow>
        <VideoCallIcon />
        {checked ? t("drop_in_calls.pane.available") : t("drop_in_calls.pane.not_available")}
        <SwitchWrapper>
          <StyledSwitch
            id="enable-drop-in-call-switch"
            type="checkbox"
            checked={checked}
            onChange={() => {
              if (checked) {
                toggleOff();
              } else {
                toggleOn();
                askForDesktopNotificationPermission();
              }
            }}
          />
          <SwitchLabel htmlFor="enable-drop-in-call-switch" />
        </SwitchWrapper>
      </ToggleTopRow>
      {checked && (
        <DotsAnimation>
          <Player speed={0.5} autoplay loop src={json} />
        </DotsAnimation>
      )}

      <Caption>{checked ? t("drop_in_calls.pane.waiting_for_patient") : t("drop_in_calls.pane.instructions")}</Caption>
    </Pane>
  );
};

export default DropInCallPane;

const Container = styled.div`
  position: sticky;
  ${props => props.theme.belowBreakpoint} {
    bottom: 0px;
  }
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const Top = styled.div`
  margin: ${props => props.theme.spacing.S_10};
`;

const TinyCaption = styled(Caption)`
  font-size: 11px;
`;

const Pane = styled.div`
  background-color: ${props => props.theme.colors.redesign.b10};
  padding: ${props => props.theme.spacing.S_20};
  margin: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  text-align: center;
  min-height: 110px;
`;

const MiniPane = styled(Pane)`
  padding: 4px;
  margin: 2px;
  text-align: center;
  min-height: 50px;
`;

const MiniButton = styled.div`
  padding: 8px 4px;
  margin: 2px;
  text-align: center;
  background-color: ${props => props.theme.colors.redesign.b100};
  color: white;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiniButtonLabel = styled.div`
  font-size: 10px;
`;

const StatusText = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const SmallPrimaryButton = styled(PrimaryButton)`
  height: 32px;
  font-size: 12px;
  border-radius: 16px;
  font-weight: normal;
  min-width: 100px;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
`;

const SmallSecondaryButton = styled(SecondaryButton)`
  height: 32px;
  font-size: 12px;
  border-radius: 16px;
  font-weight: normal;
  min-width: 100px;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
`;

const ToggleTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const StyledSwitch = styled(Switch)`
  height: 19px;
`;

const AssignedTopRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.S_20};
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.redesign.b100};
`;

const ClockColorWrapper = styled(ClockColor)`
  width: 24px;
  height: 24px;
  color: ${props => props.theme.colors.redesign.b100};
  margin-right: ${props => props.theme.spacing.S_10};
`;

const DotsAnimation = styled.div`
  width: 52px;
  margin: ${props => props.theme.spacing.S_20} auto ${props => props.theme.spacing.S_15} auto;
`;

const DotsAnimationSmall = styled.div`
  width: 38px;
  margin: ${props => props.theme.spacing.S_5} auto 0 auto;
`;

const ShortcutButton = styled(SmallSecondaryButton)`
  margin-top: 20px;
`;

const FinishButton = styled(SmallPrimaryButton)`
  margin-top: 9px;
`;
