import type React from "react";
import { useEffect } from "react";

import useDropInCallContext from "contexts/DropInCallContext/useDropInCallContext";
import { useTimer } from "utils/hooks";

import DropInCallTimerContext from "./DropInCallTimerContext";

const DropInCallTimerContextProvider: React.FC = ({ children }) => {
  const { currentSession } = useDropInCallContext();
  const { startTimer, stopTimer, time: timerTime, isRunning, setSeconds } = useTimer("countdown");

  useEffect(() => {
    if (currentSession?.state === "claimed") {
      if (
        currentSession?.timeout_interval_seconds &&
        currentSession?.current_time &&
        currentSession?.created_at &&
        !isRunning
      ) {
        const elapsedTime =
          new Date(
            new Date(currentSession.current_time).getTime() - new Date(currentSession.created_at).getTime()
          ).getTime() / 1000;
        setSeconds(Math.round(currentSession?.timeout_interval_seconds - elapsedTime));
        startTimer();
      }
    }
    if (currentSession?.state === "in_review" || !currentSession) {
      stopTimer();
    }
  }, [currentSession]);

  const value = {
    timerTime,
    isRunning,
  };

  return <DropInCallTimerContext.Provider value={value}>{children}</DropInCallTimerContext.Provider>;
};

export default DropInCallTimerContextProvider;
