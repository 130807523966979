import type React from "react";

import styled, { useTheme } from "styled-components";

interface Props {
  cptCode: string | null;
}

export const CPTCode: React.VFC<Props> = ({ cptCode }) => {
  const theme = useTheme();

  const getBgColor = () => {
    switch (cptCode) {
      case "98975":
        return theme.colors.redesign.g100;
      case "98977":
        return theme.colors.redesign.p100;
      case "98980":
        return theme.colors.redesign.t100;
      case "98981":
        return theme.colors.redesign.o100;
      default:
        return theme.colors.redesign.b100;
    }
  };
  return <Container $bgColor={getBgColor()}>{cptCode}</Container>;
};

const Container = styled.div<{ $bgColor?: string }>`
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ $bgColor }) => $bgColor};
  font-size: 14px;
  line-height: initial;
  padding: 6px;
  border-radius: 4px;
`;
