import type React from "react";
import { useState } from "react";

import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import useGetManagerServices from "api/hooks/useGetManagerServices";
import { BackIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Hidden from "shared/atoms/Hidden";
import { MUIDropdown } from "shared/atoms/inputs";
import Modal from "shared/atoms/Modal";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import theme from "theme";
import { useWindowSize } from "utils/hooks";

import { ServiceForm } from "../components/ServiceForm";

export type FormStates = "default" | "saving" | "success" | "dirty";

export const ServicesView: React.VFC = () => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();
  const { t } = useTranslation();

  const [selectedService, setSelectedService] = useState<number | undefined>(undefined);

  const { width } = useWindowSize();
  const isDesktop = width > parseInt(theme.breakpoint, 10);

  invariant(profile);

  // API requests
  const { data: services = [], isError, isLoading, refetch } = useGetManagerServices(profile.id);

  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { setValue } = form;

  const renderContent = () => {
    if (isError) {
      return <Notification variant="danger">{t("errors.generic")}</Notification>;
    }

    return (
      <Form>
        <TopContainer>
          {isDesktop && <Title>{t("booking.services.title")}</Title>}

          <FormProvider {...form}>
            <StyledMUIDropdown
              label={t("booking.services.title")}
              name="services"
              options={services.map(option => {
                return { value: option.id, label: option.name };
              })}
              onChange={event => setSelectedService(event.target.value as number)}
            />
          </FormProvider>
          <NewService
            $active={selectedService === undefined}
            onClick={() => {
              setSelectedService(undefined);
              setValue("services", undefined);
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              color={theme.colors.redesign.b100}
              style={{ height: "18px", width: "18px", marginRight: "4px" }}
            />
            {t("booking.services.new_service")}
          </NewService>
        </TopContainer>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <BottomContainer>
              <ServiceForm service={services.find(el => el.id === selectedService)} refetchServices={refetch} />
            </BottomContainer>
          </>
        )}
      </Form>
    );
  };

  return (
    <>
      <Hidden type="belowTablet">
        <Container>
          <Wrapper>
            <BackButton onClick={() => navigate(-1)}>
              <BackIcon style={{ marginRight: 8 }} />
              {t("buttons.back")}
            </BackButton>
            {renderContent()}
          </Wrapper>
        </Container>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal open close={() => navigate(-1)} title={t("booking.services.title")}>
          {renderContent()}
        </Modal>
      </Hidden>
    </>
  );
};

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  margin: 20px;
`;

const Form = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  padding: 32px;
  ${props => props.theme.belowBreakpoint} {
    height: 100%;
    padding: 0;
    min-width: auto;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  ${props => props.theme.belowBreakpoint} {
    background-color: ${props => props.theme.colors.greys.light4};
    padding: 12px 12px 0 12px;
    flex-direction: column;
    border-bottom: 1px solid ${props => props.theme.colors.redesign.db20};
  }
`;

const Title = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 21px;
  color: ${props => props.theme.colors.primary.base};
`;

const StyledMUIDropdown = styled(MUIDropdown)`
  width: auto;
  min-width: 250px;
  margin-right: 32px;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const NewService = styled.div<{ $active: boolean }>`
  display: flex;
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 500;
  padding: 16px 0px;
  cursor: ${props => (props.$active ? "default" : "pointer")};
  :hover {
    opacity: ${props => !props.$active && "0.8"};
  }
`;

const BackButton = styled.div`
  margin: 20px 0;
  font-weight: 500;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
`;
