import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { UserCourseLibraryExercises } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const GET_USER_COURSE_LIBRARY_EXERCISES_QUERY_KEY = "useGetUserCourseLibraryExercisesQuery";
type QueryKey = typeof GET_USER_COURSE_LIBRARY_EXERCISES_QUERY_KEY;

export const useGetUserCourseLibraryExercisesQuery = (
  options?: UseQueryOptions<UserCourseLibraryExercises, Error, UserCourseLibraryExercises, QueryKey>
): UseQueryResult<UserCourseLibraryExercises, Error> => {
  const client = useApiClient();
  return useQuery(
    GET_USER_COURSE_LIBRARY_EXERCISES_QUERY_KEY,
    () => {
      return client.getUserCourseLibraryExercises();
    },
    options
  );
};
