import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BlueLine, Body, Container, Content, Title } from "../InfoHelpers";

const Message = styled.div`
  margin-top: ${props => props.theme.spacing.S_10};
  background-color: ${props => props.theme.colors.greys.light4};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  padding: ${props => props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.basic};
  ${props => props.theme.font.header5}
`;

interface InfoPreferenceProps {
  message?: string;
}

const InfoPreference = ({ message }: InfoPreferenceProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <BlueLine />
      <Content>
        <Title>{t("messages.consultation.preferred_time")}</Title>
        <Body>
          {message ? t("messages.consultation.patient_statement") : t("messages.consultation.no_preference")}
          {message && <Message>{message}</Message>}
        </Body>
      </Content>
    </Container>
  );
};

export default InfoPreference;
