import styled from "styled-components";

import { DropdownIcon } from "assets";
import { TableCell } from "shared/molecules/Table";

const NotesTableCellContainer = styled.div`
  box-sizing: border-box;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${props => props.theme.belowBreakpoint} {
    max-height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const NotesTableCellContainerLeft = styled(NotesTableCellContainer)`
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  ${props => props.theme.belowBreakpoint} {
    display: block;
  }
`;

const NotesTableCellContainerRight = styled(NotesTableCellContainer)`
  justify-content: flex-end;
`;

interface TextProps {
  readonly secondary?: boolean;
  readonly light?: boolean;
}

const Text = styled.span<TextProps>`
  ${props => props.theme.font.table.row};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => (props?.secondary ? props.theme.colors.greys.dark : props.theme.colors.primary.base)};
  font-weight: ${props => {
    if (props?.secondary && props?.light) {
      return props.theme.fontWeight.light;
    }
    if (props?.secondary) {
      return props.theme.fontWeight.regular;
    }
    return props.theme.fontWeight.medium;
  }};
  ${props => props.theme.belowBreakpoint} {
    line-height: 30px;
  }
`;

const Label = styled(Text)`
  user-select: none;
  margin-right: ${props => props.theme.spacing.S_5};
`;

const NoteTypeLabel = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 18px;
`;

const AnimatedDropdownIcon = styled(DropdownIcon)<{ open?: boolean }>`
  transition: transform 300ms;
  transform: ${props => (props?.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const TR = styled.tr<{ open?: boolean }>`
  ${props => props.theme.aboveBreakpoint} {
    background: ${props => (props?.open ? props.theme.colors.greys.light4 : props.theme.colors.white)};
    ${props => !props?.open && `border-bottom: 1px solid ${props.theme.colors.greys.silver};`};
    &:nth-last-child(2) {
      border-color: transparent;
      border-width: 0;
      td {
        border-color: transparent;
        border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
        border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
      }
    }
  }

  ${props => props.theme.belowBreakpoint} {
    height: 70px;
    border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
    &:nth-last-child(1) {
      td {
        border-color: transparent;
        border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
        border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
      }
    }
  }

  cursor: pointer;
`;

const FormTR = styled.tr<{ open?: boolean }>`
  ${props => props?.open && `border-bottom: 1px solid ${props.theme.colors.greys.silver};`};
  &:nth-last-child(1) {
    td {
      border-color: transparent;
      border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
      border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
    }
  }
`;

const FormTD = styled.td<{ open?: boolean }>`
  padding: 0;
  background: ${props => (props?.open ? props.theme.colors.greys.light4 : props.theme.colors.white)};
  ${props => props.theme.belowBreakpoint} {
    background: ${props => props.theme.colors.white};
  }
`;

// NOTE: sets max-width in order to resize column DON'T TOUCH
const TD = styled(TableCell)`
  width: 30%;

  &:first-of-type {
    width: 50%;
  }

  ${props => props.theme.belowBreakpoint} {
    padding: 0 12px;
    & span {
      max-width: 100%;
    }
    &:last-child {
      width: 10%;
      & > div {
        align-items: center;
        & span {
          display: none;
        }
      }
    }
  }
`;

const MedicalNoteDateContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  min-height: 46px;
  padding: 0 ${props => props.theme.spacing.S_20};
  padding-top: ${props => props.theme.spacing.S_10};
  box-sizing: border-box;
`;

const ShowButton = styled.div`
  &:hover {
    opacity: 0.7;
  }
`;

const SignFirstBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 26px;
  margin-left: 12px;
  padding: 6px 12px;

  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;

  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary.base};
  background: ${({ theme }) => theme.colors.primary.lightError};

  ${props => props.theme.belowBreakpoint} {
    margin-left: 0;
  }
`;

export {
  NotesTableCellContainer,
  NotesTableCellContainerLeft,
  NotesTableCellContainerRight,
  Text,
  Label,
  AnimatedDropdownIcon,
  NoteTypeLabel,
  TR,
  FormTR,
  TD,
  FormTD,
  MedicalNoteDateContainer,
  ShowButton,
  SignFirstBadge,
};
