import type React from "react";

import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { HiddenIcon, StyledLi as Li, Wrapper, StyledAvatar as styledAvatar } from "routes/messages/styles/conversation";
import Avatar from "shared/atoms/Avatar";

const StyledLi = styled(Li)<{ $selected: boolean }>`
  background-color: ${({ theme }) => theme.colors.broadcast.background};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.broadcast.hover};
    }
  }
`;

const StyledLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.primary.base};
`;

const StyledAvatar = styled(styledAvatar)`
  div {
    padding: 2px;
  }
`;

const BroadcastConversation: React.VFC = () => {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId?: string }>();
  const selected = patientId === "broadcast";

  return (
    <Link to="/messages/broadcast" style={{ textDecoration: "none" }}>
      <StyledLi $selected={selected}>
        <StyledAvatar>
          <Avatar broadcast size={32} />
        </StyledAvatar>
        <Wrapper style={{ marginTop: "5px" }}>
          <StyledLabel>{t("messages.broadcast")}</StyledLabel>
        </Wrapper>
        <HiddenIcon className="showOnHover" />
      </StyledLi>
    </Link>
  );
};

export default BroadcastConversation;
