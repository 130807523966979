import type React from "react";
import { useContext } from "react";

import { faCalendarLinesPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useGetPatientUnreadCommentsCountQuery } from "api/hooks/useGetPatientUnreadCommentsCountQuery";
import { CalendarIcon, CommentsIcon, MessageIcon, PatientIcon, ProgressIcon } from "assets";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import Badge from "shared/atoms/Badge";
import Hidden from "shared/atoms/Hidden";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";
import { isRTM } from "utils/patient";

import MenuItem from "./MenuItem";
import { useActiveLinkTranslation } from "./useActiveLinkTranslation";

const PatientMenu: React.VFC = () => {
  const { t } = useTranslation();
  const { patientId, ...rest } = useParams();
  const activeLink = Object.values(rest)?.[0];
  const PATH = `/patients/${patientId}`;
  const { hasFeature } = useFeatureToggleContext();
  const activeLinkTranslation = useActiveLinkTranslation(activeLink);
  const { patient } = useContext(CurrentPatientContext);

  invariant(patient, "Patient should be defined");

  const { data } = useGetPatientUnreadCommentsCountQuery(Number(patientId), {
    enabled: Boolean(patientId),
  });

  return (
    <>
      <Container data-testid="patients-menu">
        <MenuItem to={`${PATH}/overview`} dataTestId="overview-tab-button">
          <Hidden type="aboveTablet">
            <ProgressIcon data-testid="item-icon" />
          </Hidden>
          <Text>{t("patients.menu.overview")}</Text>
        </MenuItem>
        {hasFeature(Feature.PATIENT_MESSAGES) && (
          <Hidden type="aboveTablet">
            <MenuItem to={`${PATH}/messages`}>
              <MessageIcon data-testid="item-icon" />
            </MenuItem>
          </Hidden>
        )}
        {hasFeature(Feature.PATIENT_COMMENTS) && (
          <MenuItem to={`${PATH}/comments`} dataTestId="comments-tab-button">
            <Wrapper>
              <Hidden type="aboveTablet">
                <CommentsIcon data-testid="item-icon" />
              </Hidden>
              <Text>{t("patients.menu.comments")}</Text>
              <BadgeWrapper>
                <Badge dataTestId="unread-comments-badge" label={data?.count} />
              </BadgeWrapper>
            </Wrapper>
          </MenuItem>
        )}
        {hasFeature(Feature.PATIENT_PROTOCOL) && (
          <MenuItem to={`${PATH}/protocol/current`} dataTestId="protocol-tab-button">
            <Hidden type="aboveTablet">
              <CalendarIcon data-testid="item-icon" />
            </Hidden>
            <Text>{t("patients.menu.protocol")}</Text>
          </MenuItem>
        )}
        <MenuItem to={`${PATH}/information/journal`} dataTestId="information-tab-button">
          <Hidden type="aboveTablet">
            <PatientIcon data-testid="item-icon" />
          </Hidden>
          <Text>{t("patients.menu.information")}</Text>
        </MenuItem>
        {isRTM(patient?.insuranceData) && (
          <MenuItem to={`${PATH}/activity_log`} dataTestId="information-tab-button">
            <Hidden type="aboveTablet">
              <FontAwesomeIcon
                data-testid="item-icon"
                icon={faCalendarLinesPen}
                color="rgb(56, 74, 95)"
                style={{ height: "24px", width: "24px" }}
              />
            </Hidden>
            <Text>{t("patients.menu.activity_log")}</Text>
          </MenuItem>
        )}
      </Container>
      <Hidden type="aboveTablet">
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <SubContainer data-testid="title">{t(activeLinkTranslation)}</SubContainer>
      </Hidden>
    </>
  );
};

export default PatientMenu;

const Container = styled.div`
  height: 46px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SubContainer = styled.div`
  display: flex;
  min-height: 32px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.sub_menu.title};
  background: ${props => props.theme.colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0 ${props => props.theme.spacing.S_15};
`;

const BadgeWrapper = styled.div`
  position: absolute;
  right: -32px;
  top: 0px;
`;

const Text = styled.span`
  ${props => props.theme.font.sub_menu.link};
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;
