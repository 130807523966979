import { z } from "zod";

export const HCProfessionalResponseSchema = z.object({
  avatar: z.object({
    large: z.string().nullable(),
    medium: z.string().nullable(),
    small: z.string().nullable(),
  }),
  health_care_professional_id: z.number(),
  health_care_professional_full_name: z.string(),
  profession: z.enum(["physical_therapist", "occupational_therapist", "speech_therapist", "wellness_therapist"]),
});

export type HCProfessionalResponse = z.infer<typeof HCProfessionalResponseSchema>;
