import type React from "react";

import styled from "styled-components";

const Wrapper = styled.div<{ $open: boolean }>`
  height: 24px;
  &:hover {
    opacity: 0.7;
  }
  & > :nth-child(1) {
    top: 5px;
    transition: transform 300ms;
    transform: ${props => (props?.$open ? "scale(0, 0) translateY(12px)" : "scale(1, 1) translateY(0)")};
  }
  & > :nth-child(2) {
    top: 9px;
    transition: transform 300ms 100ms;
    transform: ${props => (props?.$open ? "rotate(135deg)" : "rotate(0)")};
  }
  & > :nth-child(3) {
    top: 13px;
    transition: transform 300ms;
    transform: ${props => (props?.$open ? "scale(0, 0) translateY(12px)" : "scale(1, 1) translateY(0)")};
  }
  & > :nth-child(4) {
    top: 5px;
    transition: transform 300ms 100ms;
    transform: ${props => (props?.$open ? "rotate(225deg)" : "rotate(0)")};
  }
`;

const Line = styled.div`
  position: relative;
  width: 24px;
  height: 2px;
  background-color: ${props => props.theme.colors.primary.base};
`;

interface Props {
  open: boolean;
}

const Hamburger: React.VFC<Props> = ({ open }) => {
  return (
    <Wrapper $open={open}>
      <Line />
      <Line />
      <Line />
      <Line />
    </Wrapper>
  );
};

export default Hamburger;
