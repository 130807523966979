import styled from "styled-components";

import IconButton from "shared/atoms/IconButton";

export const BlueIconButton = styled(IconButton)`
  align-items: baseline;
  color: ${({ theme }) => theme.colors.redesign.b100} !important;

  & > div:nth-child(2) {
    margin-left: 0;
  }
`;
