import i18n from "i18next";

import type { Purpose } from "api/schemas/NewAppointment";

interface Args {
  purpose?: Purpose | null;
  hcpServiceName: string | null;
}

export function getAppointmentTitle({ purpose, hcpServiceName }: Args) {
  if (hcpServiceName) {
    return hcpServiceName;
  }

  if (purpose) {
    return i18n.t(`booking.calendar.event_title.${purpose}`);
  }

  return i18n.t("booking.calendar.event_title.default");
}
