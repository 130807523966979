import styled from "styled-components";

import { PrimaryButton, TextButton } from "shared/atoms/Button";

export const RelativeWrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div<{ $error: string }>`
  position: relative;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 3px 4px;
  box-shadow: 0px 5px 10px rgba(68, 104, 147, 0.15);
  margin: 0 12px 16px 12px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileButton = styled(TextButton)`
  color: ${props => props.theme.colors.redesign.b100};
  min-width: 60px;
  padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_10};
  ${props => props.theme.aboveBreakpoint} {
    display: none;
  }
  &:disabled {
    color: ${props => props.theme.colors.greys.gunmetal};
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const DesktopButton = styled(PrimaryButton)`
  padding: 0;
  min-height: 32px;
  height: 32px;
  min-width: 52px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;
