import { parseISO } from "date-fns";
import { format, fromZonedTime, toZonedTime } from "date-fns-tz";

interface GenerateOptionsArgs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array: Record<string, any>[];
  labelKey: string;
  valueKey: string;
}

export interface Option {
  label: string;
  value: string;
}

export function generateOptions(args: GenerateOptionsArgs): Option[] {
  const { array, labelKey, valueKey } = args;
  return array.map(object => ({ label: object[labelKey], value: object[valueKey].toString() }));
}

export function generateParticipantOptionsFull(
  args: { first_name: string | null; last_name?: string | null; id: number }[]
): Option[] {
  return args.map(patient => ({
    label: `${patient.first_name} ${patient.last_name}`,
    value: patient.id.toString(),
  }));
}

export function convertDateStringToObject(dateString: string | null) {
  if (dateString === null) {
    return {
      date: new Date().toISOString().slice(0, 10),
      startTime: undefined,
    };
  }

  const date = parseISO(dateString);

  // Extract date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based
  const day = date.getDate();

  // Extract time components
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Calculate time in minutes
  const startTime = hours * 60 + minutes;

  return {
    date: `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`,
    startTime,
  };
}

interface FormDateToJSDatesArgs {
  date: string;
  startTime: number;
  endTime: number;
  ianaTimeZone?: string | null;
}

export function formDateToJSDates(args: FormDateToJSDatesArgs) {
  const { date, startTime, endTime, ianaTimeZone } = args;

  if (!ianaTimeZone) {
    // Fallback: No IANA timezone specified
    const baseDateTime = new Date(date); // Defaults to local timezone
    const timezoneOffset = baseDateTime.getTimezoneOffset(); // Offset in minutes
    const startDate = new Date(baseDateTime.getTime() + (startTime + timezoneOffset) * 60 * 1000);
    const endDate = new Date(baseDateTime.getTime() + (endTime + timezoneOffset) * 60 * 1000);
    return { startDate, endDate };
  }

  // Step 1: Create the base date at midnight in the specified IANA timezone
  const baseDate = fromZonedTime(`${date}T00:00:00`, ianaTimeZone);

  // Step 2: Add the given minutes to the UTC base date
  const startDate = new Date(baseDate.getTime() + startTime * 60 * 1000); // Start time in UTC
  const endDate = new Date(baseDate.getTime() + endTime * 60 * 1000); // End time in UTC

  return { startDate, endDate };
}

interface ConvertUTCToZonedArgs {
  date: string; // UTC ISO date string, e.g., "2024-12-20T02:00:00.000Z"
  ianaTimeZone?: string | null; // User's IANA timezone, e.g., "America/Los_Angeles"
}

export function maybeConvertUTCToZoned(args: ConvertUTCToZonedArgs): string {
  const { date, ianaTimeZone } = args;

  if (!ianaTimeZone) {
    return date;
  }

  // Step 1: Parse the ISO date string into a Date object
  const utcDate = new Date(date);

  // Step 2: Localize the date to the specified IANA timezone
  const zonedDate = toZonedTime(utcDate, ianaTimeZone);

  // Step 3: Format the localized date back to an ISO string in the local timezone
  const zonedISO = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss", { timeZone: ianaTimeZone });

  return zonedISO;
}
