import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetHealthReportsArgs, GetHealthReportsResponse } from "api/types/GetHealthReportsResponse";
import useApiClient from "api/useApiClient";

type QueryKey = ["GetHealthReports", number, number | null];
export const getHealthReportsQueryKey = (args: GetHealthReportsArgs): QueryKey => [
  "GetHealthReports",
  args.patientId,
  args.treatment_id,
];

export const useGetHealthReportQuery = (
  args: GetHealthReportsArgs,
  options?: UseQueryOptions<GetHealthReportsResponse, Error, GetHealthReportsResponse, QueryKey>
): UseQueryResult<GetHealthReportsResponse, Error> => {
  const client = useApiClient();

  return useQuery(getHealthReportsQueryKey(args), () => client.getHealthReports(args), options);
};
