import { reportError } from "utils/errorReporting";

import type { StorageKey, StorageValue } from "./storageConfig";
import { storageDefinitions } from "./storageConfig";

export function getStorageValue<T extends StorageKey>(key: T): StorageValue<T> | undefined {
  try {
    const value = localStorage.getItem(key);

    if (value) {
      try {
        const parsed = JSON.parse(value) as unknown;
        const { schema } = storageDefinitions[key];
        const validated = schema.parse(parsed);
        return validated;
      } catch (error) {
        if (error instanceof Error || typeof error === "string") {
          reportError(`failed to parse ${key}`, error);
          removeStorageValue(key);
        }
      }
    }

    return undefined;
  } catch (error) {
    if (error instanceof Error || typeof error === "string") reportError(`failed to get ${key}`, error);
    throw error;
  }
}

export function removeStorageValue<T extends StorageKey>(key: T) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    if (error instanceof Error || typeof error === "string") reportError(`failed to remove ${key}`, error);
    throw error;
  }
}

export function setStorageValue<T extends StorageKey>(key: T, value: StorageValue<T>) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    if (error instanceof Error || typeof error === "string")
      reportError(`failed to write ${key} with value: '${String(value)}'`, error);
    throw error;
  }
}

export function clearStorage() {
  const keysToRemove = Object.keys(storageDefinitions).reduce((res, key) => {
    const value = storageDefinitions[key as StorageKey];
    if (!value.options.keepOnClear) {
      res.push(key as StorageKey);
    }
    return res;
  }, [] as StorageKey[]);
  try {
    keysToRemove.forEach(key => removeStorageValue(key));
  } catch (error) {
    if (error instanceof Error || typeof error === "string") reportError("failed to multi remove", error);
  }
}
