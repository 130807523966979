import type React from "react";

import styled from "styled-components";

import { AnatomyBack, AnatomyNeck, AnatomySpine, CircleRed } from "assets";

interface MarkProps {
  readonly $bottom: string;
  readonly $left: string;
}

const PainMark = styled(CircleRed)<MarkProps>`
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};
  z-index: 2;
`;

const NeckMark = styled(AnatomyNeck)<MarkProps>`
  position: absolute;
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};
  z-index: 2;
`;

const SpineMark = styled(AnatomySpine)<MarkProps>`
  position: absolute;
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};
  z-index: 2;
`;

const BackMark = styled(AnatomyBack)<MarkProps>`
  position: absolute;
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};
  z-index: 2;
`;

const jointsMap = {
  kneeright: { x: 75, y: 100 },
  kneeleft: { x: 100, y: 100 },
  hipright: { x: 65, y: 180 },
  hipleft: { x: 110, y: 180 },
  shoulderright: { x: 57, y: 280 },
  shoulderleft: { x: 117, y: 280 },
  footright: { x: 77, y: 30 },
  footleft: { x: 96, y: 30 },
  elbowright: { x: 45, y: 220 },
  elbowleft: { x: 129, y: 220 },
  handright: { x: 32, y: 170 },
  handleft: { x: 141, y: 170 },
  jawright: { x: 75, y: 315 },
  jawleft: { x: 97, y: 315 },
  neck: { x: 89, y: 290 },
  spine: { x: 89, y: 210 },
  lower_back: { x: 89, y: 210 },
  upper_back: { x: 89, y: 250 },
};

type Side = "left" | "right";
type JointsMapKeys = keyof typeof jointsMap;
type JointLocationType<T> = T extends `${infer Location}${Side}` ? Location : T;
interface PainMarkersProps {
  readonly location: JointLocationType<JointsMapKeys> | "undefined";
  readonly side: Side | "undefined";
}
const PainMarkers: React.VFC<PainMarkersProps> = ({ location = "undefined", side = "undefined" }) => {
  const joint = side !== "undefined" ? location + side : location;
  const jointCoordinates = jointsMap[joint as JointsMapKeys];
  if (jointCoordinates) {
    switch (joint) {
      case "neck":
        return (
          <NeckMark
            data-testid="neck-mark"
            key={joint}
            $bottom={`${jointCoordinates.y}px`}
            $left={`${jointCoordinates.x}px`}
          />
        );
      case "spine":
        return (
          <SpineMark
            data-testid="spine-mark"
            key={joint}
            $bottom={`${jointCoordinates.y}px`}
            $left={`${jointCoordinates.x}px`}
          />
        );
      case "lower_back":
      case "upper_back":
        return (
          <BackMark
            data-testid="back-mark"
            key={joint}
            $bottom={`${jointCoordinates.y}px`}
            $left={`${jointCoordinates.x}px`}
          />
        );
      default:
        return (
          <PainMark
            data-testid="pain-mark"
            key={side + location}
            $bottom={`${jointCoordinates.y}px`}
            $left={`${jointCoordinates.x}px`}
          />
        );
    }
  }
  return null;
};

export default PainMarkers;
