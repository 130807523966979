import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CTAWhiteArrowIcon } from "redesign-assets";
import { PrimaryButton } from "shared/atoms/Button";
import { Notification } from "shared/atoms/Notification";

const ErrorContainer = styled.div`
  width: 100%;
  min-height: 300px;
  margin-top: ${props => props.theme.spacing.S_80};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledArrow = styled(CTAWhiteArrowIcon)`
  transform: rotate(0.5turn);
`;

interface ErrorTemplateProps {
  errorMessage?: string;
}

const ErrorTemplate: React.VFC<ErrorTemplateProps> = ({ errorMessage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ErrorContainer data-testid="error-template">
      {/* FIXME: type translation */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Notification variant="danger">{t(errorMessage || "errors.failed_cookie") as string}</Notification>
      <PrimaryButton onClick={() => navigate(-1)}>
        <StyledArrow />
        {t("buttons.back")}
      </PrimaryButton>
    </ErrorContainer>
  );
};

export default ErrorTemplate;
