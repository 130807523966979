import { useState } from "react";
import type { ChangeEvent } from "react";
import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MUIDropdown } from "shared/atoms/inputs";
import { ICDCodeCategory, ICDCodeType } from "types";

import { ICDAutocomplete } from "./ICDAutocomplete";
import { SplitSectionRow } from "./SectionRow";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly icdFiltersEnabled: boolean; // filters are shown only for SE unsigned notes
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: ChangeEvent<{}>) => void;
}

const ICDSelect: React.VFC<Props> = ({
  className,
  disabled,
  error,
  label,
  name,
  required,
  onChange,
  icdFiltersEnabled,
}) => {
  const { t } = useTranslation();
  const [icdFilterType, setIcdFilterType] = useState<ICDCodeType>(ICDCodeType.ALL);
  const [icdFilterCategory, setIcdFilterCategory] = useState<ICDCodeCategory>(ICDCodeCategory.M00_M99);
  const [availableICDCount, setAvailableICDCount] = useState(0);

  // filters dropdown options
  const icdTypes = Object.values(ICDCodeType).map(el => el);
  const icdCategories = Object.values(ICDCodeCategory).map(el => el);

  return (
    <Wrapper>
      {icdFiltersEnabled && (
        <Container>
          <SplitSectionRow>
            <MUIDropdown
              label={t("patients.notes.fields.icd_codes_filter_category")}
              name="icd-codes-filter-category"
              onChange={el => setIcdFilterCategory(el.target.value as ICDCodeCategory)}
              defaultValue={ICDCodeCategory.M00_M99}
              options={icdCategories.map(el => {
                return {
                  value: el,
                  label: `${el !== "ALL" ? el : ""} ${t(`diagnosis.categories.${el}`)}`,
                };
              })}
            />
            <MUIDropdown
              label={t("patients.notes.fields.icd_codes_filter_type")}
              name="icd-codes-filter-type"
              onChange={el => setIcdFilterType(el.target.value as ICDCodeType)}
              defaultValue="ALL"
              options={icdTypes.map(el => {
                return {
                  value: el,
                  // FIXME: type translation
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  label: `${t(`patients.pain_location.${el}.0`)}`,
                };
              })}
            />
          </SplitSectionRow>
          <AvailableICDCount>
            {t("patients.notes.fields.icd_codes_available_count")}
            <span data-testid="icd-available-count"> {availableICDCount}</span>
          </AvailableICDCount>
        </Container>
      )}
      <ICDAutocomplete
        error={error}
        label={label}
        name={name}
        disabled={disabled}
        required={required}
        onChange={onChange}
        setAvailableICDCount={setAvailableICDCount}
        className={className}
        icdFilterType={icdFilterType}
        icdFilterCategory={icdFilterCategory}
        icdFiltersEnabled={icdFiltersEnabled}
      />
    </Wrapper>
  );
};

export { ICDSelect };

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: end;
  ${props => props.theme.belowBreakpoint} {
    display: block;
  }
`;

const AvailableICDCount = styled.div`
  font-size: 11px;
  color: ${props => props.theme.colors.redesign.db60};
  margin-bottom: 10px;
`;
