import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ClockSmallIcon, PhoneCallIcon, VideoCallIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import type { Appointment as AppointmentData } from "routes/messages/types";
import useLocalizedDate from "utils/date";

const Info = styled.td`
  ${props => props.theme.font.caption2};
  padding: 0 ${props => props.theme.spacing.S_5};
  background-color: white;
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled.span`
  margin-left: ${props => props.theme.spacing.S_5};
  color: ${props => props.theme.colors.redesign.b100};
`;

const ClockSmallIconWrapper = styled(ClockSmallIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
`;

const NoScheduledCallsCell = styled.td`
  display: flex;
  justify-content: center;
  font-size: 12px;
  background-color: white;
`;

export type AppointmentType = {
  patient: {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  medium: string;
  startTime: string;
  endTime: string;
  purpose: string;
  location: string;
  id: number;
};

const IconWrapper = styled.div`
  transform: scale(0.6);
`;
interface Props {
  chosenDate: Date;
}

const ScheduledCalls: React.VFC<Props> = ({ chosenDate }) => {
  const { t } = useTranslation();
  const { parseISO, format, isSameDay } = useLocalizedDate();
  const { scheduledCalls } = useProfileContext();
  const [todaysScheduledCalls, setTodaysScheduledCalls] = useState<AppointmentData[]>([]);
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTodaysScheduledCalls(getScheduledCallsForToday());
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTop = 0;
    }
  }, [chosenDate, scheduledCalls]);

  const getScheduledCallsForToday = () => {
    return scheduledCalls
      .filter((call: AppointmentData) => isSameDay(parseISO(call.start_time), chosenDate))
      .sort((a: AppointmentData, b: AppointmentData) => (a.start_time < b.start_time ? -1 : 1));
  };

  const renderCallTypeIcon = ({ medium }: AppointmentData) => {
    switch (medium) {
      case "phone":
        return (
          <IconWrapper title={t("scheduled_calls_table.tooltip.phone_call")}>
            <PhoneCallIcon />
          </IconWrapper>
        );
      case "video":
        return (
          <IconWrapper title={t("scheduled_calls_table.tooltip.video_call")}>
            <VideoCallIcon />
          </IconWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ height: "80px", maxHeight: "80px", overflowY: "auto" }} ref={tableWrapperRef}>
      <table style={{ width: "100%" }}>
        <tbody>
          {todaysScheduledCalls.length > 0 &&
            todaysScheduledCalls.map((call: AppointmentData) => (
              <tr key={call.id}>
                <Info>
                  <ClockSmallIconWrapper />
                  {`${format(parseISO(call.start_time), "p")} - ${format(parseISO(call.end_time), "p")} `}
                  <Link to={`/messages/${call.patient_id}`} style={{ textDecoration: "none" }}>
                    <LinkWrapper>
                      {call.patient_first_name} {call.patient_last_name}
                    </LinkWrapper>
                  </Link>
                  {renderCallTypeIcon(call)}
                </Info>
              </tr>
            ))}
          {todaysScheduledCalls.length <= 0 && (
            <tr>
              <NoScheduledCallsCell>{t("scheduled_calls_table.no_calls")}</NoScheduledCallsCell>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduledCalls;
