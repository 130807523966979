import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import TextArea from "shared/atoms/inputs/TextArea";

const TextContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
  width: 100%;
`;

const ProfileBioFields = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_20};
`;

const StyledIconButton = styled(IconButton)`
  margin-top: ${props => props.theme.spacing.S_10};
  ${props => props.theme.aboveBreakpoint} {
    margin-top: 0;
  }
`;
const StyledHeader = styled.h4`
  ${props => ({ ...props.theme.font.header4 })}
  margin: ${props => props.theme.spacing.S_30} auto 16px auto;
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const StyledSubHeader = styled.h5`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  margin: 0px;
  font-weight: ${props => props.theme.fontWeight.light};
`;

const StyledParagraph = styled.p`
  white-space: pre-wrap;
  ${props => ({ ...props.theme.font.body1 })}
  color: ${props => props.theme.colors.black};
  margin-top: 4px;
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-width: 100%;
  padding-top: ${props => props.theme.spacing.S_10};
  padding-bottom: ${props => props.theme.spacing.S_20};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${props => props.theme.aboveMobileBreakpoint} {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

export {
  TextContainer,
  StyledHeader,
  StyledSubHeader,
  StyledParagraph,
  StyledTextArea,
  StyledPrimaryButton,
  Wrapper,
  ProfileBioFields,
  StyledIconButton,
};
