import { z } from "zod";

const BaseSchema = z.object({
  therapist_id: z.number(),
  patient_id: z.number(),
  type: z.enum(["exercise_comment_reply", "message_reply"]),
  body: z.string().nullable(),
});

const CommentSchema = BaseSchema.extend({ user_activity_id: z.number() });
const MessageSchema = BaseSchema.extend({ message_id: z.number() });

export const CommentRepliesSchema = z.object({ comments: CommentSchema.array() });
export const MessageRepliesSchema = z.object({ message: MessageSchema.nullable() });

export type CommentReplies = z.infer<typeof CommentRepliesSchema>;
export type MessageReplies = z.infer<typeof MessageRepliesSchema>;

const GetSmartRepliesArgs = z.object({
  therapistId: z.number(),
  patientId: z.number(),
});

export type GetSmartRepliesArgs = z.infer<typeof GetSmartRepliesArgs>;
