import { useTranslation } from "react-i18next";

import type { Payload } from "api/schemas/patients/SwitchTreatmentPrograms";
import { BaseButton } from "shared/atoms/BaseButton";

import { ButtonGroup, ErrorMessage, Label, Question } from "./styles";
import type { FormError } from "./TreatmentSwitch";

interface Props {
  formError?: FormError;
  payload: Payload;
  updatePayload: (key: string, value: string | boolean) => void;
}

export const OsteoporosisQuestions: React.VFC<Props> = ({ formError, payload, updatePayload }) => {
  const { t } = useTranslation();

  return (
    <>
      <Question>
        <Label>{t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_DIAGNOSED")}</Label>
        <ButtonGroup>
          <BaseButton
            onClick={() => updatePayload("osteoporosis_diagnosed", true)}
            text={t("buttons.yes")}
            variant="secondary"
            active={payload.osteoporosis_diagnosed === true}
          />
          <BaseButton
            onClick={() => updatePayload("osteoporosis_diagnosed", false)}
            text={t("buttons.no")}
            variant="secondary"
            active={payload.osteoporosis_diagnosed === false}
          />
        </ButtonGroup>
        {formError?.osteoporosis_diagnosed && <ErrorMessage>{t("form.validation.required")}</ErrorMessage>}
      </Question>

      <Question>
        <Label>{t("patient.ONBOARDING.OSTEOPOROSIS.OSTEOPOROSIS_OSTEOPENI_DIAGNOSED")}</Label>
        <ButtonGroup>
          <BaseButton
            onClick={() => updatePayload("osteoporosis_osteopenia_diagnosed", true)}
            text={t("buttons.yes")}
            variant="secondary"
            active={payload.osteoporosis_osteopenia_diagnosed === true}
          />
          <BaseButton
            onClick={() => updatePayload("osteoporosis_osteopenia_diagnosed", false)}
            text={t("buttons.no")}
            variant="secondary"
            active={payload.osteoporosis_osteopenia_diagnosed === false}
          />
        </ButtonGroup>
        {formError?.osteoporosis_osteopenia_diagnosed && <ErrorMessage>{t("form.validation.required")}</ErrorMessage>}
      </Question>
    </>
  );
};
