import type React from "react";

import { useQueryClient } from "react-query";

import type { UseGetPlansOfCareQueryKey } from "api/hooks/useGetPlansOfCareQuery";
import { usePlansOfCareQuery } from "api/hooks/useGetPlansOfCareQuery";
import usePutPlanOfCareMutation from "api/hooks/usePutPlanOfCareMutation";
import type { PlanOfCare } from "api/models/PlanOfCare";
import useLocalizedDate from "utils/date";

import PlanOfCareCertificationBox from ".";

interface Props {
  patientId: number;
}

type SignedPlanOfCare = PlanOfCare & { date_of_service: string };

const isSigned = (poc: PlanOfCare): poc is SignedPlanOfCare => !!poc.date_of_service;

const PlanOfCareCertificationContainer: React.VFC<Props> = ({ patientId }) => {
  const { differenceInCalendarDays, parseISO, isAfter } = useLocalizedDate();
  const { data = [] } = usePlansOfCareQuery({ patientId, acknowledgedByPT: false });
  const { mutateAsync: updatePlanOfCare } = usePutPlanOfCareMutation();
  const queryClient = useQueryClient();

  const getLatestPlanOfCare = () => {
    const signedSample = data.find<SignedPlanOfCare>(isSigned);
    return (
      signedSample &&
      data.reduce<SignedPlanOfCare>((latest, poc) => {
        if (!isSigned(poc)) return latest;
        const date1 = parseISO(poc.date_of_service);
        const date2 = parseISO(latest.date_of_service);
        return isAfter(date1, date2) ? poc : latest;
      }, signedSample)
    );
  };

  const poc = getLatestPlanOfCare();
  if (!poc) return null;
  const age = differenceInCalendarDays(new Date(), parseISO(poc.date_of_service));
  if (age > 8) return null;

  const dismissModal = async () => {
    const queryKey: UseGetPlansOfCareQueryKey = ["GetPlansOfCare", patientId, false];
    queryClient.cancelQueries(queryKey);
    const newData = [...data];
    newData.splice(data.indexOf(poc), 1);
    queryClient.setQueryData(queryKey, newData);
    try {
      await updatePlanOfCare({ careEventId: poc.id, data: { acknowledged_by_therapist: true } });
    } catch (err) {
      queryClient.setQueryData(queryKey, data);
    } finally {
      const scope: UseGetPlansOfCareQueryKey = ["GetPlansOfCare", patientId];
      queryClient.invalidateQueries(scope);
    }
  };

  return (
    <PlanOfCareCertificationBox
      dateSigned={poc.date_of_service}
      onClose={dismissModal}
      fileLink={poc.file_link}
      duration={poc.duration_days}
    />
  );
};

export default PlanOfCareCertificationContainer;
