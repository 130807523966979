import type { Reference } from "http-link-header";
import { parse } from "http-link-header";

export interface ResponseHeaders {
  link?: string;
  "total-count"?: number;
  "page-items"?: number;
}

export interface PaginationHeaders {
  next?: string;
  prev?: string;
  totalCount?: number;
  pageItems?: number;
}

const getPaginationHeaders = (headers: ResponseHeaders = {}): PaginationHeaders => {
  let next;
  let prev;
  if (headers?.link) {
    const { refs } = parse(headers.link);
    next = refs.find((ref: Reference) => ref.rel === "next")?.uri;
    prev = refs.find((ref: Reference) => ref.rel === "prev")?.uri;
  }

  return {
    next,
    prev,
    totalCount: headers?.["total-count"],
    pageItems: headers?.["page-items"],
  };
};

export default getPaginationHeaders;
