import type React from "react";
import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CancelIcon, CircleRed, StatusBlackIcon, StatusGreenIcon, StatusOrangeIcon } from "assets";

const Status = styled.div`
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StyledIcon = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  width: max-content;
  height: fit-content;
  margin-left: 5px;
`;

type ValidStatusFields = "status" | "immediate_referral_required" | "pre_authorization_required" | "deleted";

export type PatientStatusRecord = {
  pre_authorization_required?: boolean;
  immediate_referral_required?: boolean;
  status?: string;
  deleted?: boolean;
};

export const StatusFieldNames: Record<string, ValidStatusFields> = {
  auth: "pre_authorization_required",
  referral: "immediate_referral_required",
  status: "status",
  deleted: "deleted",
};

interface Props {
  patientStatuses: PatientStatusRecord;
}

export const getPatientRelevantStatus = (
  datasets: Record<string, string | boolean | undefined>[]
): PatientStatusRecord => {
  const statuses: Record<string, string | boolean | undefined> = {};

  Object.keys(StatusFieldNames).forEach(keymap => {
    const key = StatusFieldNames[keymap];
    const dataset = datasets.find(datasetItem => datasetItem && datasetItem[key] !== undefined);
    if (dataset !== undefined) {
      statuses[key] = dataset[key];
    }
  });

  return statuses;
};

const PatientStatus: React.VFC<Props> = ({ patientStatuses }) => {
  const { t } = useTranslation();

  const STATUS_KEY_PREFIX = "patients.statuses";

  const getStatus = useCallback(() => {
    if (!patientStatuses) {
      return null;
    }

    const statuses = [
      {
        show: !!patientStatuses[StatusFieldNames.deleted],
        key: "deleted",
        icon: <StatusBlackIcon data-testid="black-icon" />,
      },
      {
        show: !!patientStatuses[StatusFieldNames.auth] && !!patientStatuses[StatusFieldNames.referral],
        key: "referral_and_authorization",
        icon: <CircleRed data-testid="red-icon" />,
      },
      {
        show: !!patientStatuses[StatusFieldNames.auth],
        key: "requires_authorization",
        icon: <CircleRed data-testid="red-icon" />,
      },
      {
        show: !!patientStatuses[StatusFieldNames.referral],
        key: "referral_needed",
        icon: <CircleRed data-testid="red-icon" />,
      },
      {
        show: patientStatuses[StatusFieldNames.status] === "active",
        key: patientStatuses[StatusFieldNames.status],
        icon: <StatusGreenIcon data-testid="active-icon" />,
      },
      {
        show: patientStatuses[StatusFieldNames.status] === "end_treatment",
        key: patientStatuses[StatusFieldNames.status],
        icon: <CancelIcon style={{ width: "14" }} data-testid="cancel-icon" />,
      },
      {
        show: true,
        key: "inactive",
        icon: <StatusOrangeIcon data-testid="inactive-icon" />,
      },
    ];

    return statuses.find(item => item.show);
  }, [patientStatuses]);

  if (!patientStatuses) {
    return null;
  }

  const currentStatus = getStatus();
  return currentStatus ? (
    <Status data-testid="patient-status">
      <StyledIcon>{currentStatus.icon}</StyledIcon>
      {/* FIXME: type translation */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <TextWrapper>{t(`${STATUS_KEY_PREFIX}.${currentStatus.key}`)}</TextWrapper>
    </Status>
  ) : null;
};

export default PatientStatus;
