import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";

import type { Therapist } from "api/models/Therapist";
import { PlusIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { updateImage } from "routes/settings/queries";
import Avatar from "shared/atoms/Avatar";
import { PrimaryButton } from "shared/atoms/Button";
import { useWindowSize } from "utils/hooks";

import { OnboardingButtons, OnboardingHeader, OnboardingMessage, UploadError } from "../components";

const ProfilePictureUpload = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.spacing.S_50};
`;

const ProfilePictureUploadContainer = styled.div`
  height: 80px;
  width: 80px;
  position: relative;

  ${props => props.theme.belowBreakpoint} {
    height: 50px;
    width: 50px;
  }
`;

const AvatarContainer = styled.div<{ $visible: boolean }>`
  transition: opacity 200ms;
  opacity: ${props => (props.$visible ? "1" : "0")};
`;

const StyledAvatar = styled(Avatar)`
  ${props => props.theme.belowBreakpoint} {
    height: 50px;
    width: 50px;
  }
`;

const ProgressRing = styled.svg<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  transition: opacity 200ms;
  opacity: ${props => (props.$visible ? "1" : "0")};

  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    r: 38;
    cx: 40;
    cy: 40;
  }

  ${props => props.theme.belowBreakpoint} {
    height: 50px;
    width: 50px;

    circle {
      r: 23;
      cx: 25;
      cy: 25;
    }
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  ${props => props.theme.font.link1};
  height: 30px;
  width: 30px;
  margin-right: ${props => props.theme.spacing.S_15};
`;

const StyledInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const StyledLabel = styled.label`
  ${props => props.theme.font.link1};
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.spacing.S_30};
  color: ${props => props.theme.colors.primary.base};

  &:hover {
    text-decoration: underline;
  }
`;

const ProfilePicture = (): JSX.Element => {
  const { profile, setProfile } = useProfileContext();
  const [avatar, setAvatar] = useState(profile?.avatars?.medium || "");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState("");
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const sendImage = (image: File | undefined) => {
    if (image) {
      setUploadProgress(0);
      const imgData = new FormData();
      imgData.set("file", image);
      updateImage(imgData, event => {
        setUploadProgress(Math.round((100 * event.loaded) / event.total));
      })
        .then(({ data }) => {
          const updatedProfile = {
            ...profile,
            avatars: {
              large: data?.avatar?.large_avatar?.url || null,
              medium: data?.avatar?.medium_avatar?.url || null,
              small: data?.avatar?.small_avatar?.url || null,
            },
          };
          setProfile(updatedProfile as Therapist);
          setAvatar(data?.avatar?.medium_avatar?.url || "");
          setUploadError("");
        })
        .catch(() => {
          setUploadError(t("settings.upload_failed"));
        })
        .finally(() => {
          setUploadProgress(0);
        });
    }
  };

  const radius = width > parseInt(theme.breakpoint, 10) ? 38 : 23;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (uploadProgress / 100) * circumference;

  return (
    <>
      <OnboardingHeader>{t("onboarding.2.title")}</OnboardingHeader>

      <OnboardingMessage data-testid="onboarding-profile-picture-upload-body">
        {t("onboarding.2.body")}
      </OnboardingMessage>

      <ProfilePictureUpload>
        <ProfilePictureUploadContainer>
          <AvatarContainer $visible={uploadProgress === 0}>
            <StyledAvatar src={avatar} size={80} />
          </AvatarContainer>

          <ProgressRing $visible={uploadProgress > 0}>
            <circle stroke="rgb(218, 236, 255)" strokeWidth="4" fill="transparent" />
            <circle
              stroke="rgb(74, 144, 226)"
              strokeWidth="4"
              strokeDasharray={`${circumference} ${circumference}`}
              strokeDashoffset={offset}
              fill="transparent"
            />
          </ProgressRing>
        </ProfilePictureUploadContainer>

        <StyledInput
          data-testid="onboarding-profile-picture-file-input"
          id="profilePicture"
          type="file"
          accept="image/*"
          onChange={event => {
            if (event?.target?.files?.[0]) {
              sendImage(event.target.files?.[0]);
            }
          }}
        />
        <StyledLabel data-testid="onboarding-profile-picture-file-input-label" htmlFor="profilePicture">
          <StyledPlusIcon />
          <span>{avatar ? t("onboarding.2.upload_new") : t("onboarding.2.upload")}</span>
        </StyledLabel>
      </ProfilePictureUpload>

      <UploadError $visible={!!uploadError}>{uploadError}</UploadError>

      <OnboardingButtons>
        <PrimaryButton
          data-testid="onboarding-profile-picture-next-btn"
          onClick={() => {
            if (!avatar) {
              setUploadError(t("onboarding.2.upload_error"));
            } else {
              navigate("/onboarding/about-you");
            }
          }}
        >
          <span>{t("buttons.next")}</span>
        </PrimaryButton>
      </OnboardingButtons>
    </>
  );
};

export default ProfilePicture;
