import { animated } from "react-spring";
import styled from "styled-components";

import Card from "shared/atoms/Card";
import Row from "shared/atoms/Row";

const Form = styled.form<{ open?: boolean }>`
  background: ${props => (props?.open ? props.theme.colors.greys.light4 : props.theme.colors.white)};
  overflow: hidden;
  border: none;
  border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
  border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
  ${props => props.theme.belowBreakpoint} {
    background: none;
  }
  display: flex;
  flex: 1;
  box-sizing: border-box;
  min-height: calc(100% - 46px);
`;

const StyledCol = styled(animated.div)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  ${props => props.theme.belowBreakpoint} {
    min-height: 100%;
  }
`;

const ButtonContainer = styled(Row)<{ notification?: boolean }>`
  flex: 0;
  min-height: ${props => (props?.notification ? "70px" : "46px")};
  margin: ${props => (props?.notification ? props.theme.spacing.S_10 : props.theme.spacing.S_20)};
  justify-content: flex-end;
  align-items: center;
  & button:first-child {
    margin-left: ${props => props.theme.spacing.S_20};
  }

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    & button:first-child {
      margin-left: 0;
    }
  }
`;

const BlueWrapper = styled(Card)<{ active?: boolean; align?: string }>`
  padding: 0;
  flex-direction: column;
  margin: ${props => props.theme.spacing.S_10};
  background-color: ${props => (props.active ? props.theme.colors.pastels.blue : "transparent")};
  ${props => props.theme.belowBreakpoint} {
    background-color: transparent;
  }
`;

export { Form, StyledCol, ContentWrapper, ButtonContainer, BlueWrapper };
