import type { AxiosResponse } from "axios";
import type { UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import { AxiosInstance } from "api";

interface SendMessageCallVars {
  to: number;
  body: string;
  activity_id?: number;
}

interface SendMessageCall {
  message: {
    body: string;
  };
  status: string;
}

const postSendMessageCall = (callVars: SendMessageCallVars): Promise<AxiosResponse<SendMessageCall>> =>
  AxiosInstance.post("messages", callVars);

const useSendMessageCall = (): UseMutationResult<
  AxiosResponse<SendMessageCall>,
  unknown,
  SendMessageCallVars,
  unknown
> => {
  return useMutation((callVars: SendMessageCallVars) => postSendMessageCall(callVars));
};

export default useSendMessageCall;
