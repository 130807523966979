import type { CompareFunction } from "../CompareFunction";

type NumericalCompareOptions = {
  descending?: boolean;
};

const createNumericalCompare = (options: NumericalCompareOptions = {}): CompareFunction<number> => {
  const { descending = false } = options;

  return (a: number, b: number): -1 | 0 | 1 => {
    if (a === b) {
      return 0;
    }
    if (descending) {
      return a > b ? -1 : 1;
    }
    return a > b ? 1 : -1;
  };
};

export default createNumericalCompare;
