import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import { BackIcon, CheckIcon, MessageToggleDownIcon, MessageToggleUpIcon } from "assets";
import { Container, HeaderGroup, HeaderGroupRight, Link, StyledBackIcon } from "routes/messages/styles/header";
import PatientHeaderDropdownInfo from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/PatientHeaderDropdownInfo";
import PatientHeaderInfoAddNoteButton from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/PatientHeaderInfoAddNoteButton";
import Hidden from "shared/atoms/Hidden";
import PremiumIcon from "shared/atoms/PremiumIcon";
import { CurrentPatientContext } from "utils/contexts";

interface Props {
  selectedPatient: {
    id: number;
    name: string;
  };
}

const ConversationHeader: React.VFC<Props> = ({ selectedPatient }) => {
  const [showPatientData, setShowPatientData] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { patient, patientError, updateIsCreatable, activeCareEventNote, setActiveCareEventNote } =
    useContext(CurrentPatientContext);
  const queryClient = useQueryClient();

  const [addedNoteStatus, setAddedNoteStatus] = useState(false);
  const [customDropdownStyling, setCustomDropdownStyling] = useState("default-position");

  const currentPatient = patient?.id;
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (currentPatient) {
      updateIsCreatable();
      setActiveCareEventNote(null);
      setAddedNoteStatus(false);
      setCustomDropdownStyling("custom-position");
    }
  }, [currentPatient]);

  useEffect(() => {
    if (activeCareEventNote) {
      setAddedNoteStatus(true);
      timeout = setTimeout(() => {
        setActiveCareEventNote(null);
        setAddedNoteStatus(false);
        queryClient.invalidateQueries(getPatientCareEventsQueryKey({ patientId: currentPatient, signed: false }));
        updateIsCreatable();
      }, 2000);
    } else {
      setAddedNoteStatus(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [activeCareEventNote]);

  const renderNotes = () => {
    if (addedNoteStatus) {
      return (
        <HeaderGroupRight>
          <StyledIcon>
            <CheckIcon />
          </StyledIcon>
          <div data-testid="confirm" style={{ marginRight: "21px" }}>
            {t("messages.added_note")}
          </div>
        </HeaderGroupRight>
      );
    }
    return <PatientHeaderInfoAddNoteButton customDropdownStyling={customDropdownStyling} />;
  };

  return (
    <>
      <Container data-testid="convo-header">
        <HeaderGroup>
          <Hidden type="aboveTablet">
            <Link to="/messages/">
              <StyledBackIcon />
            </Link>
          </Hidden>
          <Link
            to={{
              pathname: `/patients/${selectedPatient.id.toString()}`,
            }}
            state={{ pathname, text: "messages" }}
          >
            <PremiumIcon premiumType={patient?.premium_type} size="small" margin="0 4px 0 0 " />
            {selectedPatient?.name}
            <Hidden type="belowTablet">
              <ForwardIcon />
            </Hidden>
          </Link>
          <DropDownContainer>{renderNotes()}</DropDownContainer>
        </HeaderGroup>
      </Container>
      <Hidden type="aboveTablet">
        {!patientError && (
          <>
            <DropDownContainer>
              <PatientHeaderDropdownInfo
                useToggleIcon
                isOpen={showPatientData}
                onClose={() => setShowPatientData(!showPatientData)}
                patientError={patientError}
              />
            </DropDownContainer>
          </>
        )}
        <IconWrapper>
          <IconContainer onClick={() => setShowPatientData(!showPatientData)}>
            <Title title={t("messages.tooltip.patient_info")}>
              {showPatientData ? <MessageToggleDownIcon /> : <MessageToggleUpIcon />}
            </Title>
          </IconContainer>
        </IconWrapper>
      </Hidden>
      <Hidden type="belowTablet">
        {!patientError && (
          <DropDownContainer>
            <PatientHeaderDropdownInfo
              useBackgroundOverlay={false}
              useToggleIcon
              isOpen={showPatientData}
              onClose={() => setShowPatientData(!showPatientData)}
              patientError={patientError}
            />
            {showPatientData && <DesktopOverlay />}
          </DropDownContainer>
        )}
        <IconWrapper>
          <IconContainer onClick={() => setShowPatientData(!showPatientData)}>
            <Title title={t("messages.tooltip.patient_info")}>
              {showPatientData ? <MessageToggleDownIcon /> : <MessageToggleUpIcon />}
            </Title>
          </IconContainer>
        </IconWrapper>
      </Hidden>
    </>
  );
};

export default ConversationHeader;

const DesktopOverlay = styled.div`
  position: absolute;
  height: 100vh;
  opacity: 0.65;
  background-color: black;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.aboveMessage};
`;
const DropDownContainer = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 0;
  z-index: ${({ theme }) => theme.zIndex.headerDropdown};
`;

const IconContainer = styled.div`
  text-decoration: none;
  white-space: nowrap;
  z-index: ${({ theme }) => theme.zIndex.headerDropdown};
  margin-top: -5px;
`;

const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: ${({ theme }) => theme.spacing.S_10};
`;

const ForwardIcon = styled(BackIcon)`
  margin-left: ${({ theme }) => theme.spacing.S_10};
  transform: rotate(180deg);
`;

const Title = styled.div`
  cursor: pointer;
`;
