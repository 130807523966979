import type React from "react";

import styled from "styled-components";

const ButtonWrapper = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: ${props => props.theme.borderRadius.circular};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  padding: 0;
  border: none;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

interface Props {
  readonly children: React.ReactNode;
  readonly disabled?: boolean;
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
  readonly dataTestId?: string | null;
}

const CounterButton: React.VFC<Props> = ({
  disabled = false,
  children,
  onClick = () => undefined,
  dataTestId = null,
}) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} data-testid={dataTestId}>
      {children}
    </ButtonWrapper>
  );
};

export default CounterButton;
