import type React from "react";
import { useContext, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";
import { SearchIcon } from "assets";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import Dropdown from "shared/atoms/inputs/Dropdown";
import VideoCallAwareContainer from "shared/atoms/VideoCallAwareContainer/VideoCallAwareContainer";
import Search from "shared/molecules/Search";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { LibraryContext } from "utils/contexts";
import { Feature } from "utils/features/types";

type WrapperProps = {
  $pageContentOffsetTop: number;
};

let TIMER: ReturnType<typeof setTimeout>;

interface Props {
  readonly categories: string[];
}

interface FormState extends Pick<Props, "categories"> {
  readonly search: string;
  readonly jointFilter: string;
}

const LibrarySearch: React.VFC<Props> = ({ categories }) => {
  const { t } = useTranslation();
  const { category, setCategory, jointFilter, setJointFilter, search, setSearch } = useContext(LibraryContext);
  const { hasFeature } = useFeatureToggleContext();
  const offsetTop = usePageContentOffsetTop();
  const { watch, register, setValue } = useForm<FormState>({
    defaultValues: {
      search,
    },
  });

  const options = categories.map(option => ({
    value: option,
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    label: t(option),
  }));

  const jointsWithExercises: Joint[] = [
    "knee",
    "hip",
    "foot",
    "hand",
    "elbow",
    "shoulder",
    "lower_leg",
    "neck",
    "lower_back",
    "fall_prevention",
    "osteoporosis",
  ];

  const jointFilterOptions = jointsWithExercises
    .map(joint => ({
      value: joint,
      // FIXME: type translation (plurals not playing nice with typing and {count: 0} not supported in the version)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: t(`patients.pain_location.${joint}.0`),
    }))
    .sort(({ label: a }, { label: b }) => (a > b ? 1 : -1));

  const categoryValue = watch("categories");
  const jointFilterValue = watch("jointFilter");
  const searchValue = watch("search");

  useEffect(() => {
    setCategory(categoryValue);
  }, [categoryValue, setCategory]);

  useEffect(() => {
    setJointFilter(jointFilterValue);
  }, [jointFilterValue, setJointFilter]);

  useEffect(() => {
    clearTimeout(TIMER);
    TIMER = setTimeout(() => {
      if (typeof searchValue === "string" && searchValue.trim()) {
        setSearch(searchValue.trim());
      } else {
        setSearch("");
      }
    }, 300);
  }, [searchValue]);

  return (
    <Wrapper $pageContentOffsetTop={offsetTop} data-testid="search-header">
      <OuterContainer>
        <Container>
          <Title>{t("library.library_title")}</Title>
          <InputWrapper>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledDropdown {...register("categories")} options={options} selected={category} />
            {hasFeature(Feature.BACK_LIBRARY) && (
              // FIXME:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <StyledDropdown {...register("jointFilter")} options={jointFilterOptions} selected={jointFilter} />
            )}
            <SearchWrapper>
              <Search
                reset={() => setValue("search", "")}
                icon={SearchIcon}
                {...register("search")}
                placeholder={t("library.search_placeholder")}
                search={search}
              />
            </SearchWrapper>
          </InputWrapper>
        </Container>
      </OuterContainer>
    </Wrapper>
  );
};

export default LibrarySearch;

const Wrapper = styled.div<WrapperProps>`
  z-index: 1;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.redesign.db20};

  ${props => props.theme.belowBreakpoint} {
    position: static;
  }
`;

const OuterContainer = styled(VideoCallAwareContainer)`
  display: flex;
  justify-content: center;
  width: 990px;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.theme.belowBreakpoint} {
    flex: 1;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    padding: 12px 0;
  }
`;

const SearchWrapper = styled.div`
  min-width: 300px;
  margin: 12px;
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 300px;
  margin: 12px;
`;

const Title = styled.div`
  ${props => props.theme.font.header2};
  font-weight: 500;
  color: ${props => props.theme.colors.primary.base};
  padding-left: 12px;
  ${props => props.theme.belowBreakpoint} {
    height: 64px;
    line-height: 64px;
    text-align: center;
    padding: 0;
    border-bottom: 1px solid ${props => props.theme.colors.redesign.db20};
  }
`;
