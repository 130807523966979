import * as braze from "@braze/web-sdk";

const BrazeService = {
  initialize(userId: string): void {
    braze.initialize(process.env.REACT_APP_BRAZE_API_KEY as string, {
      baseUrl: process.env.REACT_APP_BRAZE_BASE_URL as string,
      allowUserSuppliedJavascript: true,
      doNotLoadFontAwesome: true,
      minimumIntervalBetweenTriggerActionsInSeconds: 0,
      enableLogging: false,
    });

    braze.automaticallyShowInAppMessages();
    braze.changeUser(userId);

    braze.openSession();
  },

  track(name: string, properties?: Record<string, unknown>): void {
    braze.logCustomEvent(name, { ...properties, appVersion: process.env.REACT_APP_VERSION });
  },
};

export default BrazeService;
