import type React from "react";

import { GenderFemaleIcon, GenderMaleIcon } from "assets";
import PhoneNumber from "shared/atoms/PhoneNumber";

import { DotSeparator, PersonalDetailsContainer, PhoneNumberWrapper, StyledPhoneCallIcon } from "../../helpers";

interface Props {
  readonly age: number;
  readonly gender: string;
  readonly preferredName: string;
  readonly phoneNumber: string;
  readonly country: string;
}

const PersonalDetails: React.VFC<Props> = ({ age, gender, preferredName, phoneNumber, country }) => {
  return (
    <PersonalDetailsContainer>
      {preferredName && (
        <>
          <div>{preferredName}</div>
          <DotSeparator />
        </>
      )}
      {age}
      {gender === "male" && <GenderMaleIcon style={{ marginLeft: 4 }} title="gender-male" />}
      {gender === "female" && <GenderFemaleIcon style={{ marginLeft: 4 }} title="gender-female" />}
      <PhoneNumberWrapper>
        <StyledPhoneCallIcon />
        <PhoneNumber phone={phoneNumber} country={country} />
      </PhoneNumberWrapper>
    </PersonalDetailsContainer>
  );
};

export default PersonalDetails;
