import type React from "react";

import { useTranslation } from "react-i18next";

import { MessageIcon } from "assets";
import { useAppConfigContext } from "contexts/AppConfigContext";
import Badge from "shared/atoms/Badge";
import useUnreadMessagesCount from "utils/contexts/queries/useUnreadMessagesCount";

import ListItem from "./ListItem";
import { StyledIcon, StyledSpan } from "./MenuStyles";

const ListItemMessage: React.VFC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { unreadMessagesCount } = useUnreadMessagesCount();
  const { navBarCollapsed } = useAppConfigContext();

  return (
    <ListItem to="/messages">
      <StyledIcon>
        <MessageIcon />
      </StyledIcon>
      <StyledSpan $collapsed={navBarCollapsed}>{t("views.messages")}</StyledSpan>
      {unreadMessagesCount > 0 && (
        <Badge dataTestId="unread-messages-count" label={unreadMessagesCount} $collapsed={navBarCollapsed} />
      )}
    </ListItem>
  );
};

export default ListItemMessage;
