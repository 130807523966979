import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { HealthJournal, HealthJournalArgs } from "api/schemas/patients/HealthJournal";
import useApiClient from "api/useApiClient";

type QueryKey = ["useGetPatientHealthJournalQuery", HealthJournalArgs];

export const getPatientHealthJournalQueryKey = (args: HealthJournalArgs): QueryKey => [
  "useGetPatientHealthJournalQuery",
  args,
];

export const useGetPatientHealthJournalQuery = (
  args: HealthJournalArgs,
  options?: UseQueryOptions<HealthJournal, Error, HealthJournal, QueryKey>
): UseQueryResult<HealthJournal, Error> => {
  const client = useApiClient();

  return useQuery(getPatientHealthJournalQueryKey(args), () => client.getPatientHealthJournal(args), options);
};
