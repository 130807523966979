import { createGlobalStyle } from "styled-components";

import Theme from "theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${Theme.colors.greys.light2};
    width: 100vw;
    max-width: 100%;
    height: ${props => (props.theme.vh ? `${props.theme.vh}px` : "100vh")};
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;

    #root {
      height: 100%;
      display: flex;
    }

    a {
      cursor: pointer;
    }
    svg {
      pointer-events: none;
    }
    @media print {
      margin: ${props => props.theme.spacing.S_30};
    }
  }
`;

export default GlobalStyle;
