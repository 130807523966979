import type React from "react";

import { animated, useSpring } from "react-spring";
import styled from "styled-components";

interface DropDownOptionsProps {
  parentId: string;
  isOpen: boolean;
  children?: React.ReactNode;
  small?: boolean;
  items: number;
  className?: string;
  dropdownListMinWidth?: string;
}

const DropDownOptions: React.VFC<DropDownOptionsProps> = ({
  parentId,
  isOpen,
  children,
  small,
  items,
  className,
  dropdownListMinWidth,
}) => {
  const { animDisplay, maxHeight } = useSpring({
    maxHeight: isOpen ? 90 * items : 0,
    animDisplay: isOpen ? 1 : 0,
  });

  const listId = `${parentId}-dropdown-list`;

  return (
    <>
      <DropdownList
        id={listId}
        style={{
          maxHeight,
          display: animDisplay.interpolate(displ => (displ === 0 ? "none" : "flex")),
        }}
        $small={small}
        className={className}
        $dropdownListMinWidth={dropdownListMinWidth}
      >
        <ListContainer>{children}</ListContainer>
      </DropdownList>
    </>
  );
};

const DropdownList = styled(animated.ul)<{ $small?: boolean; $dropdownListMinWidth?: string }>`
  margin: 0;
  padding: 0px 0px;
  top: 100%;
  min-width: ${({ $dropdownListMinWidth }) => $dropdownListMinWidth || "221px"};
  border-radius: 0px 0 8px 8px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 10px 20px ${props => props.theme.colors.greys.pinkish};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  z-index: ${props => props.theme.zIndex.modal};
  ${props => props.theme.belowBreakpoint} {
    top: 48px;
    border-radius: 8px 0 8px 8px;
  }
  overflow: hidden;
`;

const ListContainer = styled.div`
  width: 100%;
`;

export default DropDownOptions;
