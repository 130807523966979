import { format, parseISO } from "date-fns";

import type { Availability, BookingIntervalResponse } from "api/schemas/BookingInterval";
import type { Personnel } from "contexts/CalendarContext";

import { maybeConvertUTCToZoned } from "../../helpers";

import { getServiceAvailabilityColor } from "./getAppointmentColors";
import { HCPROF_COLORS } from "./transformAppointments";

export type ExtendedProps = {
  availability: Availability;
  bookingIntervalID: number;
  personnel: {
    full_name: string;
    profession: string;
  };
  serviceID: number | undefined;
  type: string;
};

type BookingInterval = {
  title: string;
  daysOfWeek?: number[];
  start?: string;
  startTime?: string;
  end?: string;
  endTime?: string;
  backgroundColor: string;
  borderColor: string;
  extendedProps: ExtendedProps;
};

type GroupedBookingIntervals = {
  [hcProfId: string]: BookingIntervalResponse[];
};

interface Args {
  bookingIntervals: BookingIntervalResponse[];
  isAdmin: boolean;
  selectedPersonnel?: Personnel[];
  unavailableTitle: string;
  showMyAvailability: boolean;
  showTherapistAvailability: boolean;
  userID: number;
  ianaTimeZone?: string | null;
}

export const transformBookingIntervals = ({
  bookingIntervals,
  isAdmin,
  selectedPersonnel,
  unavailableTitle,
  showMyAvailability,
  showTherapistAvailability,
  userID,
  ianaTimeZone,
}: Args) => {
  const filteredBookingIntervals: BookingIntervalResponse[] = bookingIntervals.filter(bookingInterval => {
    if (!showMyAvailability && bookingInterval.health_care_professional.id === userID) {
      return false; // Exclude logged in user's booking_invervals if showMyAvailability is false
    }

    if (!showTherapistAvailability && bookingInterval.health_care_professional.id !== userID) {
      return false; // Exclude other users' booking_invervals if showTherapistAvailability is false
    }

    if (showTherapistAvailability && bookingInterval.health_care_professional.id !== userID) {
      // Include or exclude based on selected personnel
      return selectedPersonnel && selectedPersonnel.length > 0
        ? selectedPersonnel.map(pr => pr.id)?.includes(bookingInterval.health_care_professional.id)
        : false;
    }

    return true; // Include booking_interval by default
  });
  let transformedBookingIntervals: BookingInterval[] = [];

  if (isAdmin) {
    // Group all booking_intervals by health_care_professional.id
    const groupedBookingIntervals: GroupedBookingIntervals = filteredBookingIntervals.reduce(
      (hcProfs, item) => ({
        ...hcProfs,
        [item.health_care_professional.id]: [...(hcProfs[item.health_care_professional.id] || []), item],
      }),
      {} as GroupedBookingIntervals
    );

    // Transform grouped booking_intervals and lastly flatten the nested array
    transformedBookingIntervals = Object.keys(groupedBookingIntervals)
      .map((hcProf: string, i) => {
        const backgroundColor =
          selectedPersonnel?.find(hcProfId => hcProfId.id === parseInt(hcProf, 10))?.bgColor ??
          HCPROF_COLORS[i]?.bgColor;
        const borderColor =
          selectedPersonnel?.find(hcProfId => hcProfId.id === parseInt(hcProf, 10))?.borderColor ??
          HCPROF_COLORS[i]?.borderColor;

        return groupedBookingIntervals[hcProf].map(bookingInterval => {
          const isUnavailable = bookingInterval.availability === "unavailable";
          const title = isUnavailable ? unavailableTitle : bookingInterval.health_care_service?.name || "";

          return {
            title,
            start: maybeConvertUTCToZoned({ date: bookingInterval.start_time, ianaTimeZone }),
            end: maybeConvertUTCToZoned({ date: bookingInterval.end_time, ianaTimeZone }),

            backgroundColor: isUnavailable ? backgroundColor : "#fff",
            borderColor,
            extendedProps: {
              type: "booking_interval",
              availability: bookingInterval.availability,
              bookingIntervalID: bookingInterval.id,
              serviceID: bookingInterval.health_care_service?.id,
              personnel: {
                full_name: bookingInterval.health_care_professional.full_name,
                profession: bookingInterval.health_care_professional.profession,
              },
            },
          };
        });
      })
      .flat();
  } else {
    filteredBookingIntervals.forEach(bookingInterval => {
      const isRecurrentBookingInterval = bookingInterval.recurrence === "weekly";
      const isUnavailable = bookingInterval.availability === "unavailable";
      const title = isUnavailable ? unavailableTitle : bookingInterval.health_care_service?.name || "";
      const backgroundColor = isUnavailable ? "#F5C1B9" : "#fff";
      const borderColor = getServiceAvailabilityColor(
        bookingInterval.availability,
        bookingInterval.health_care_service
      );

      if (isRecurrentBookingInterval) {
        const startDateObject = parseISO(maybeConvertUTCToZoned({ date: bookingInterval.start_time, ianaTimeZone }));
        const startTimeString = format(startDateObject, "HH:mm:ss");
        const endDateObject = parseISO(maybeConvertUTCToZoned({ date: bookingInterval.end_time, ianaTimeZone }));
        const endTimeString = format(endDateObject, "HH:mm:ss");
        const dayOfWeek = startDateObject.getDay();

        transformedBookingIntervals.push({
          title,
          daysOfWeek: [dayOfWeek],
          startTime: startTimeString,
          endTime: endTimeString,
          backgroundColor,
          borderColor,
          extendedProps: {
            type: "booking_interval",
            availability: bookingInterval.availability,
            bookingIntervalID: bookingInterval.id,
            serviceID: bookingInterval.health_care_service?.id,
            personnel: {
              full_name: bookingInterval.health_care_professional.full_name,
              profession: bookingInterval.health_care_professional.profession,
            },
          },
        });
      } else {
        transformedBookingIntervals.push({
          title,
          start: maybeConvertUTCToZoned({ date: bookingInterval.start_time, ianaTimeZone }),
          end: maybeConvertUTCToZoned({ date: bookingInterval.end_time, ianaTimeZone }),
          backgroundColor,
          borderColor,
          extendedProps: {
            type: "booking_interval",
            availability: bookingInterval.availability,
            bookingIntervalID: bookingInterval.id,
            serviceID: bookingInterval.health_care_service?.id,
            personnel: {
              full_name: bookingInterval.health_care_professional.full_name,
              profession: bookingInterval.health_care_professional.profession,
            },
          },
        });
      }
    });
  }

  return transformedBookingIntervals;
};
