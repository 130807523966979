import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPTCareEventsArgs, GetPTCareEventsResponse } from "api/types/GetPTCareEvents";
import useApiClient from "api/useApiClient";

export type UseGetPTCareEventsQueryArgs = GetPTCareEventsArgs;

type QueryKey = ["useGetPTCareEventsQuery", UseGetPTCareEventsQueryArgs];

export const getPTCareEventsQueryKey = (args: UseGetPTCareEventsQueryArgs): QueryKey => [
  "useGetPTCareEventsQuery",
  args,
];

export const useGetPTCareEventsQuery = (
  args: UseGetPTCareEventsQueryArgs,
  options?: UseQueryOptions<GetPTCareEventsResponse, Error, GetPTCareEventsResponse, QueryKey>
): UseQueryResult<GetPTCareEventsResponse, Error> => {
  const client = useApiClient();
  return useQuery(
    getPTCareEventsQueryKey(args),
    () => {
      return client.getPTCareEvents(args);
    },
    options
  );
};
