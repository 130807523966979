import { z } from "zod";

import { pinSchema } from "routes/login/utils/validation/loginBankId";

// Create a new patient
export const PatientPayloadSchema = z.object({
  personal_number: pinSchema,
  first_name: z.string(),
  last_name: z.string(),
});

export const PatientResponseSchema = z.object({
  id: z.number(),
  state: z.string(),
});

export const PostPatientArgsSchema = z.object({
  data: PatientPayloadSchema,
  managerId: z.number(),
});

export type PatientPayload = z.infer<typeof PatientPayloadSchema>;
export type PatientResponse = z.infer<typeof PatientResponseSchema>;
export type PostPatientArgs = z.infer<typeof PostPatientArgsSchema>;

// Get patient available painful joints

export const PatientAvailableJointsResponseSchema = z.object({
  elbow: z.boolean(),
  foot: z.boolean(),
  hand: z.boolean(),
  hip: z.boolean(),
  knee: z.boolean(),
  lower_back: z.boolean(),
  lower_leg: z.boolean(),
  neck: z.boolean(),
  shoulder: z.boolean(),
  wrist: z.boolean(),
});

export const GetPatientAvailableJointsArgsSchema = z.object({
  managerId: z.number(),
  patientId: z.number(),
});

export type PatientAvailableJointsResponse = z.infer<typeof PatientAvailableJointsResponseSchema>;
export type GetPatientAvailableJointsArgs = z.infer<typeof GetPatientAvailableJointsArgsSchema>;

// Store patient health questionnaire data
const ADMISSIBLE_FOR_ONBOARDING = z.enum([
  "right_hip",
  "left_hip",
  "right_knee",
  "left_knee",
  "lower_back",
  "right_hand",
  "left_hand",
  "right_shoulder",
  "left_shoulder",
  "neck",
]);

const ADDITIONAL_JOINTS = z.enum([
  "right_elbow",
  "left_elbow",
  "right_foot",
  "left_foot",
  "right_wrist",
  "left_wrist",
  "right_lower_leg",
  "left_lower_leg",
]);

const ADMISSIBLE_FOR_ONBOARDING_EXTENDED = z.union([ADMISSIBLE_FOR_ONBOARDING, ADDITIONAL_JOINTS]);

export type AvailableJoints = z.infer<typeof ADMISSIBLE_FOR_ONBOARDING_EXTENDED>;

export const IntroductionHealthReportPayloadSchema = z.object({
  most_painful_joint: ADMISSIBLE_FOR_ONBOARDING_EXTENDED,
  specific_joint_pain: z.number(),
  specific_joint_examined: z.boolean(),
});

export const IntroductionHealthReportResponseSchema = z.object({
  status: z.string(),
});

export const PostIntroductionHealthReportArgsSchema = z.object({
  data: IntroductionHealthReportPayloadSchema,
  managerId: z.number(),
  patientId: z.number(),
});

export type IntroductionHealthReportPayload = z.infer<typeof IntroductionHealthReportPayloadSchema>;
export type IntroductionHealthReportResponse = z.infer<typeof IntroductionHealthReportResponseSchema>;
export type PostIntroductionHealthReportArgs = z.infer<typeof PostIntroductionHealthReportArgsSchema>;

// Onboard patient
export const PatientOnboardingPayloadSchema = z.object({
  email: z.string(),
  phone_number: z.string(),
});

export const PatientOnboardingResponseSchema = z.object({
  status: z.string(),
});

export const PutPatientOnboardingArgsSchema = z.object({
  data: PatientOnboardingPayloadSchema,
  managerId: z.number(),
  patientId: z.number(),
});

export type PatientOnboardingPayload = z.infer<typeof PatientOnboardingPayloadSchema>;
export type PatientOnboardingResponse = z.infer<typeof PatientOnboardingResponseSchema>;
export type PutPatientOnboardingArgs = z.infer<typeof PutPatientOnboardingArgsSchema>;

// Complete patient onboarding
export const PostPatientOnboardingCompleteArgsSchema = z.object({
  managerId: z.number(),
  patientId: z.number(),
});

export type PostPatientOnboardingCompleteArgs = z.infer<typeof PostPatientOnboardingCompleteArgsSchema>;

export const GetManagerPatientsResponseSchema = z.object({
  email: z.string().nullable().optional(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  phone_number: z.string().nullable().optional(),
  preferred_name: z.string().nullable().optional(),
});

export type GetManagerPatientsResponse = z.infer<typeof GetManagerPatientsResponseSchema>;
