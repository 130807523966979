import toLower from "utils/string/toLower";

import type { CompareFunction } from "../CompareFunction";

type AlphabeticalCompareOptions = {
  descending?: boolean;
  ignoreCase?: boolean;
};

const createAlphabeticalCompare = (options: AlphabeticalCompareOptions = {}): CompareFunction<string> => {
  const { descending = false, ignoreCase = false } = options;

  return (a: string, b: string): -1 | 0 | 1 => {
    const aValue = ignoreCase ? toLower(a) : a;
    const bValue = ignoreCase ? toLower(b) : b;

    if (aValue === bValue) {
      return 0;
    }
    if (descending) {
      return aValue > bValue ? -1 : 1;
    }
    return aValue > bValue ? 1 : -1;
  };
};

export default createAlphabeticalCompare;
