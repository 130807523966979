import type React from "react";

import styled from "styled-components";

import type { IcdCodeType } from "./ReferralNoteDiagnosisCode";

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotesText = styled.span`
  padding: ${props => props.theme.spacing.S_5};
  border-radius: ${props => props.theme.borderRadius.basic};
  background-color: ${props => props.theme.colors.pastels.green};
`;

interface Props {
  item: IcdCodeType;
}

const ReferralNoteSearchRow: React.VFC<Props> = ({ item }) => {
  return (
    <NotesContainer>
      <div>
        <NotesText>{item.value}</NotesText>
      </div>
      <div>{item.label}</div>
    </NotesContainer>
  );
};

export default ReferralNoteSearchRow;
