import type { ReactNode } from "react";
import type React from "react";

import styled from "styled-components";

const StyledFooter = styled.tr<{ $addTopBorder: boolean }>`
  height: 60px;
  background: ${props => props.theme.colors.greys.light4};
  width: 100%;

  &:first-child > th,
  &:first-child > td {
    ${props => props.$addTopBorder && `border-top: 1px solid ${props.theme.colors.greys.silver};`}
  }
`;

const StyledElement = styled.td`
  padding: 0 ${props => props.theme.spacing.S_20};
  &:first-child {
    border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
    border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
  }

  ${props => props.theme.belowBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_15};
  }
`;

interface Props {
  children?: ReactNode;
  colSpan?: number;
  addTopBorder?: boolean;
}

const TableFooter: React.VFC<Props> = ({ children = null, colSpan = 1, addTopBorder = false }) => {
  return (
    <StyledFooter $addTopBorder={addTopBorder}>
      <StyledElement colSpan={colSpan}>{children}</StyledElement>
    </StyledFooter>
  );
};

export default TableFooter;
