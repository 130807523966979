import type React from "react";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useGetPartnerPatientList from "api/hooks/useGetPartnerPatientList";
import type { PartnerPatient } from "api/schemas/PartnerPatientsSchema";
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from "assets";
import Hidden from "shared/atoms/Hidden";
import { Table, TableMissingContent, TableSort } from "shared/molecules/Table";
import theme from "theme";
import { useWindowSize } from "utils/hooks";

import {
  Button,
  Container,
  Counter,
  HeaderContainer,
  StyledButtonGroup,
  StyledPrimaryButton,
  StyledSearch,
  Title,
} from "./helpers";
import PartnerPatientsTableRow from "./PartnerPatientsTableRow";

// set to 20 on BE by default
const PAGE_SIZE = 20;

const PartnerPatientsTable: React.VFC = () => {
  const { register, setValue, getValues } = useForm();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const COLUMNS =
    width > parseInt(theme.breakpoint, 10)
      ? [
          t("patients.id"),
          t("partner_patients.name"),
          t("patients.header_info.joined_date"),
          t("partner_patients.latest_note"),
          t("partner_patients.clinic"),
          t("partner_patients.icd_code"),
          t("partner_patients.adherence"),
        ]
      : [t("patients.id"), t("partner_patients.name"), t("patients.header_info.joined_date")];

  const [sort, setSort] = useState({
    selectedColumn: COLUMNS[0],
    descending: false,
  });

  const [queryParams, setQueryParams] = useState(`?page[limit]=${PAGE_SIZE}`);
  const { data, error, isFetching } = useGetPartnerPatientList(queryParams);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    let queryString = `?page[limit]=${PAGE_SIZE}`;
    if (searchValue !== "") queryString += `&search[name]=${searchValue}`;
    if (page !== 1) queryString += `&page[page]=${page}`;
    setQueryParams(queryString);
  }, [searchValue, page]);

  const onSort = (clickedColumn: string) => {
    if (sort.selectedColumn === clickedColumn) {
      setSort({ selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      setSort({ selectedColumn: clickedColumn, descending: true });
    }
  };

  const getSelectedColumnData = (row: PartnerPatient): string => {
    let columnData = "";
    switch (sort.selectedColumn) {
      case t("patients.id"):
        columnData = row.id.toString();
        break;
      case t("partner_patients.name"):
        columnData = row.name;
        break;
      case t("patients.header_info.joined_date"):
        columnData = row.created_at;
        break;
      case t("partner_patients.latest_note"):
        columnData = row.latest_note_at ? row.latest_note_at : "";
        break;
      case t("partner_patients.clinic"):
        columnData = row.sources[0] ? row.sources[0] : "";
        break;
      case t("partner_patients.adherence"):
        columnData = row.adherence.toString();
        break;
      case t("partner_patients.icd_code"):
        // eslint-disable-next-line prefer-destructuring
        columnData = row.icd_codes[0] ? row.icd_codes[0] : "";
        break;
      default:
        break;
    }

    return columnData;
  };

  const compare = (x: string, y: string): number => {
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  const compareRows = (a: PartnerPatient, b: PartnerPatient): number => {
    return compare(getSelectedColumnData(a), getSelectedColumnData(b));
  };

  const renderTableContent = () => {
    if (isFetching || error) {
      return <TableMissingContent loading={isFetching} error={!!error} columnCount={COLUMNS.length} />;
    }

    if (data) {
      const tableData = data?.patients;
      if (tableData.length === 0) {
        return (
          <TableMissingContent nodata message={t("partner_patients_table.no_content")} columnCount={COLUMNS.length} />
        );
      }
      return [...tableData].sort(compareRows).map((value: PartnerPatient) => {
        return <PartnerPatientsTableRow patient={value} key={value.id} />;
      });
    }

    return null;
  };

  const displayCounter = () => {
    const currentPageFirstItem = 1 + (page - 1) * PAGE_SIZE;
    const currentPageLastItem =
      data?.metadata?.total_count && PAGE_SIZE + currentPageFirstItem - 1 > data.metadata.total_count
        ? data.metadata.total_count
        : PAGE_SIZE + currentPageFirstItem - 1;

    if (data?.metadata?.total_count) {
      return `${currentPageFirstItem} - ${currentPageLastItem} of ${data.metadata.total_count}`;
    }
    return "";
  };

  const renderTableSearch = () => {
    return (
      <Container>
        <StyledSearch
          data-testid="search-input-patients"
          reset={() => setValue("patientSearch", "")}
          icon={SearchIcon}
          {...register("patientSearch")}
          placeholder="Search by name"
        />
        <StyledPrimaryButton
          onClick={() => {
            if (searchValue !== getValues("patientSearch")) {
              setPage(1);
            }
            setSearchValue(getValues("patientSearch"));
          }}
        >
          {t("patients.search")}
        </StyledPrimaryButton>
      </Container>
    );
  };

  const renderPaginationButtons = () => {
    return (
      <StyledButtonGroup>
        <Counter>{displayCounter()}</Counter>
        <Button
          $size="large"
          disabled={data?.metadata?.prev === null}
          onClick={() => {
            if (data?.metadata.prev) {
              setPage(data.metadata.prev);
            }
          }}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          $size="large"
          disabled={data?.metadata?.next === null}
          onClick={() => {
            if (data?.metadata.next) {
              setPage(data.metadata.next);
            }
          }}
        >
          <ChevronRightIcon />
        </Button>
      </StyledButtonGroup>
    );
  };

  return (
    <>
      <HeaderContainer>
        <Title>{t("partner_enrolled_patients_table.header")}</Title>
      </HeaderContainer>

      <HeaderContainer>
        {renderTableSearch()}
        {renderPaginationButtons()}
      </HeaderContainer>

      <Table>
        <thead>
          <TableSort
            columns={COLUMNS}
            sortBy={sort.selectedColumn}
            descending={sort.descending}
            onSort={onSort}
            sortable={false}
          />
        </thead>
        <tbody>{renderTableContent()}</tbody>
      </Table>

      <Hidden type="aboveTablet">{renderPaginationButtons()}</Hidden>
    </>
  );
};

export default PartnerPatientsTable;
