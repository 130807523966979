import { useContext } from "react";

import type ApiClient from "./ApiClient";
import ApiClientContext from "./ApiClientContext";

const useApiClient = (): ApiClient => {
  const value = useContext(ApiClientContext);

  if (value === null) {
    throw new Error("useApiClient cannot be used outside the ApiClientProvider");
  }
  return value;
};

export default useApiClient;
