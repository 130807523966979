import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getQuickRepliesQueryKey = (userId: number) => ["GetQuickReplies", userId] as const;

const useGetQuickReplies = (userId: number) => {
  const client = useApiClient();

  return useQuery(getQuickRepliesQueryKey(userId), () => client.getQuickReplies(userId));
};

export default useGetQuickReplies;
