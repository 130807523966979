import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { RepetitionsIcon, TimerIcon } from "redesign-assets";

import ActivityTip from "../ActivityTip";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;
  background: ${props => props.theme.colors.exercise.background};
  padding: ${props => props.theme.spacing.S_15};
  margin-bottom: ${props => props.theme.spacing.S_20};
  border-radius: ${props => props.theme.borderRadius.library};
  ${props => props.theme.belowLibBreakpoint} {
    flex-flow: column nowrap;
  }
`;

const Instructions = styled.div`
  ${props => props.theme.belowLibBreakpoint} {
    margin-bottom: ${props => props.theme.spacing.S_15};
  }
  :last-child {
    margin-bottom: 0;
  }
`;

const InstructionTitle = styled.div`
  text-transform: lowercase;
  ${props => props.theme.font.small_caps_title};
  color: ${props => props.theme.colors.exercise.accent};
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const Description = styled.div`
  ${props => props.theme.font.header5};
  color: ${props => props.theme.colors.greys.dark};
`;

const Repetitions = styled(RepetitionsIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
  position: relative;
  top: 3px;
`;

const Timer = styled(TimerIcon)`
  margin-right: ${props => props.theme.spacing.S_5};
  position: relative;
  top: 3px;
`;

const Reps = styled.span`
  ${props => props.theme.font.header2};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.greys.dark};
`;

const Body = styled.span`
  ${props => props.theme.font.header5};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.iron};
`;

interface ExerciseInstructionProps {
  instruction?: string;
  body: string;
  reps: string;
  tip?: { title: string; description: string };
  icon: "timer" | "repetitions";
  moreInstructions?: string;
}

const ExerciseInstruction: React.VFC<ExerciseInstructionProps> = ({
  instruction = "",
  body = "",
  reps,
  tip,
  icon,
  moreInstructions = "",
}) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="exercise-instruction">
      <Instructions>
        <InstructionTitle>{t("library.activity.exercise.instruction")}</InstructionTitle>
        <Description>{instruction}</Description>
        <span>
          {icon === "timer" && <Timer />}
          {icon === "repetitions" && <Repetitions />}
          <Reps>{`${reps}`}</Reps>
          <Body>{` ${body}`}</Body>
          <Description>{moreInstructions}</Description>
        </span>
      </Instructions>
      {tip && <ActivityTip title={tip.title} description={tip.description} />}
    </Container>
  );
};

export default ExerciseInstruction;
