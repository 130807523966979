import { useContext } from "react";

import VoiceCallContext from "./VoiceCallContext";
import type { VoiceCallContextValue } from "./VoiceCallContextValue";

const useVoiceCallContext = (): VoiceCallContextValue => {
  const value = useContext(VoiceCallContext);

  if (value === null) {
    throw new Error("useVoiceCallContext cannot be used outside the VoiceCallContextProvider");
  }
  return value;
};

export default useVoiceCallContext;
