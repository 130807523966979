import type React from "react";

import { useMatch } from "react-router-dom";
import styled from "styled-components";

import NavigationLink from "shared/atoms/NavigationLink";

import { StyledLi } from "./MenuStyles";

interface ListItemProps {
  readonly to: string;
  readonly children: React.ReactNode;
}

const ListItem: React.VFC<ListItemProps> = ({ to, children }) => {
  const match = useMatch(to);
  return (
    <SidebarLink $active={!!match}>
      <StyledLi>
        <NavigationLink to={to}>{children}</NavigationLink>
      </StyledLi>
    </SidebarLink>
  );
};

const SidebarLink = styled.div<{ $active: boolean }>`
  background-color: ${props => props.$active && props.theme.colors.redesign.b10};
  margin: 4px 8px;
  border-radius: 4px;
`;

export default ListItem;
