const calculateJointScoreRmdq = (answer: Record<string, boolean>): string => {
  if (answer.none === true) {
    return "0";
  }
  return Object.values(answer)
    .filter(value => value === true)
    .length.toString();
};

export default calculateJointScoreRmdq;
