import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

const resetPassword = (body: { email: string }): Promise<AxiosResponse> =>
  AxiosInstance.post("auth/password", {
    ...body,
    redirect_url: `${window.location.origin}/login/set-password`,
  });

export default resetPassword;
