import { useTranslation } from "react-i18next";
import styled from "styled-components";

const maxActivityDays = 30;

export const DaysOfActivity: React.VFC<{ activityDays: number }> = ({ activityDays }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TopSection>
        {t("patient.days_of_activity.header")}
        <Counter>{t("patient.days_of_activity.number_of_days", { activityDays, maxActivityDays })}</Counter>
      </TopSection>
      <DaysGroup>
        {[...Array(maxActivityDays)].map((_value, i) => {
          const key = `day_${i}`;
          return <Day key={key} $active={i < activityDays} />;
        })}
      </DaysGroup>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 12px;
  padding: 10px 12px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
`;

const TopSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Counter = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.redesign.g100};
  background: ${({ theme }) => theme.colors.redesign.g20};
  padding: 4px 8px;
  border-radius: 25px;
`;

const DaysGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Day = styled.div<{ $active: boolean }>`
  height: 12px;
  width: 4px;
  background: ${({ theme, $active }) => ($active ? theme.colors.redesign.g100 : theme.colors.redesign.db20)};
`;
