import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import type { RemoteParticipant, Room } from "twilio-video";

import { JoiningSound, RingingTone } from "assets/videocall";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import CallButtons from "../components/CallButtons";
import CallingStateModal from "../components/CallingStateModal";
import MainParticipant from "../components/mainParticipant/MainParticipant";
import RemoteParticipantView from "../components/remoteParticipant/RemoteParticipant";
import videoCall from "../queries/videoCall";
import type { CallData, CallState } from "../types";

import { BottomGradient, IdConfirmation, RoomContainer, StatusText, TopGradient } from "./RoomStyles";

const ringingTone = new Audio();
ringingTone.setAttribute("loop", "true");
ringingTone.volume = 0.25;

const joiningSound = new Audio(JoiningSound);

interface Props {
  callData: CallData;
  minimised: boolean;
  setMinimised: (minimised: boolean) => void;
  onCallEnded: () => void;
  onCallInitiated: (callId: number | null) => void;
}

const VideoCall: React.VFC<Props> = ({ callData, minimised, setMinimised, onCallEnded, onCallInitiated }) => {
  const { caller, callee } = callData;
  const { t } = useTranslation();
  const [room, setRoom] = useState<Room | null>(null);
  const [participant, setParticipant] = useState<RemoteParticipant | null>(null);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [callState, setCallState] = useState<CallState>("IDLE");
  const [isRoomVisible, setIsRoomVisible] = useState(true);
  const [callAgain, setCallAgain] = useState(false);
  const callStateRef = useRef<CallState>("IDLE");
  const callIdRef = useRef<number | null>(null);

  useEffect(() => {
    callStateRef.current = callState;

    if (callState === "CALLER_CONNECTED") {
      ringingTone.src = RingingTone;
      ringingTone.play();
    } else {
      ringingTone.pause();
    }

    return () => {
      ringingTone.src = "";
    };
  }, [callState]);

  useEffect(() => {
    const onParticipantConnected = (remoteParticipant: RemoteParticipant) => {
      AnalyticsService.track(AnalyticsEvents.VIDEO_CALL.PARTICIPANT_JOINED, {
        callId: callIdRef.current,
        label: "patient",
        patient: callData.callee.id,
      });
      setParticipant(remoteParticipant);
      setCallState("CALLEE_CONNECTED");
      joiningSound.play();
    };

    const onParticipantDisconnected = () => {
      AnalyticsService.track(AnalyticsEvents.VIDEO_CALL.ENDED, {
        callId: callIdRef.current,
        label: "patient",
        patient: callData.callee.id,
      });
      setParticipant(null);
      setMinimised(false);
      setCallState("CALLEE_DISCONNECTED");
      setIsRoomVisible(false);
    };

    const onDisconnected = () => {
      if (callStateRef.current === "CALLER_CONNECTED") {
        setRoom(null);
        setMinimised(false);
        setCallState("CALLEE_REJECTED");
        setIsRoomVisible(false);
      }
    };

    const connectToRoom = async () => {
      try {
        setCallState("CALLER_CONNECTING");
        const Video = await import("twilio-video");
        const response = await videoCall(callData.caller.id, callData.callee.id);
        callIdRef.current = response.data.id;
        const newRoom = await Video.connect(response.data.token, {});
        newRoom.on("participantConnected", onParticipantConnected);
        newRoom.on("participantDisconnected", onParticipantDisconnected);
        newRoom.on("disconnected", onDisconnected);
        setRoom(newRoom);
        AnalyticsService.track(AnalyticsEvents.VIDEO_CALL.PARTICIPANT_JOINED, {
          callId: callIdRef.current,
          label: "pro",
          patient: callData.callee.id,
        });
        setCallState("CALLER_CONNECTED");
        onCallInitiated(callIdRef.current);
      } catch (e) {
        setMinimised(false);
        setCallState("CONNECTION_FAILED");
        setIsRoomVisible(false);
      }
    };

    if (callData) {
      connectToRoom();
    }
  }, [callData, callAgain]);

  const callAgainClick = useCallback(() => {
    setCallAgain(prevState => !prevState);
    setIsRoomVisible(true);
  }, [callAgain]);

  const endCall = useCallback(() => {
    if (callState === "CALLER_CONNECTED") {
      ringingTone.pause();
    }

    if (room) {
      room.disconnect();
    }

    setCallState("IDLE");

    onCallEnded();
  }, [callState, onCallEnded, room]);

  const onHangup = useCallback(() => {
    AnalyticsService.track(AnalyticsEvents.VIDEO_CALL.ENDED, {
      callId: callIdRef.current,
      label: "pro",
      patient: callData.callee.id,
    });
    endCall();
  }, [callData.callee.id, endCall]);

  const renderIdentityConfirmationText = () => {
    return !minimised && callState === "CALLEE_CONNECTED" ? (
      <IdConfirmation>
        <span>
          <b>{callData.callee.name}</b> - {t("videocall.identification_confirmation")}
        </span>
      </IdConfirmation>
    ) : null;
  };

  const renderStatusText = () => {
    if (!minimised) {
      if (callState === "CALLER_CONNECTING") {
        return <StatusText>{t("videocall.connecting")}</StatusText>;
      }
      if (callState === "CALLER_CONNECTED") {
        return <StatusText>{`${t("videocall.calling")} ${callee.name}...`}</StatusText>;
      }
    }

    return null;
  };

  const renderRoom = () => {
    return room !== null ? (
      <>
        {participant && (
          <RemoteParticipantView minimised={minimised} participant={participant} username={callee.name} />
        )}

        {(!minimised || participant === null) && (
          <MainParticipant
            minimised={minimised}
            participant={room.localParticipant}
            videoEnabled={videoEnabled}
            audioEnabled={audioEnabled}
            hasRemoteParticipantConnected={participant !== null}
            username={caller.name}
          />
        )}

        {!minimised && (
          <>
            <TopGradient />
            <BottomGradient />
          </>
        )}
      </>
    ) : null;
  };

  const renderCallButtons = () => {
    return callState !== "IDLE" && callState !== "CALLER_CONNECTING" ? (
      <CallButtons
        audioEnabled={audioEnabled}
        minimised={minimised}
        videoEnabled={videoEnabled}
        onAudioEnabledToggled={() => setAudioEnabled(!audioEnabled)}
        onEndPressed={onHangup}
        onMinimisedToggled={() => setMinimised(!minimised)}
        onVideoEnabledToggled={() => setVideoEnabled(!videoEnabled)}
      />
    ) : null;
  };

  return (
    <>
      {isRoomVisible ? (
        <RoomContainer data-testid="video-call-container" $minimised={minimised}>
          {renderRoom()}

          {renderIdentityConfirmationText()}

          {renderStatusText()}

          {renderCallButtons()}
        </RoomContainer>
      ) : (
        <CallingStateModal callState={callState} error={null} onCallAgain={callAgainClick} onEndCall={endCall} />
      )}
    </>
  );
};

export default VideoCall;
