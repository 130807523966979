import type React from "react";
import { useContext, useRef } from "react";

import styled, { ThemeContext } from "styled-components";

interface Props {
  localStream: MediaStream;
  remoteStream: MediaStream;
}

const AudioVisualizer: React.VFC<Props> = ({ localStream, remoteStream }) => {
  const audioContext = new (window.AudioContext ||
    (window as unknown as { webkitAudioContext: unknown }).webkitAudioContext)();
  const analyzer = audioContext.createAnalyser();
  const localSource = audioContext.createMediaStreamSource(localStream);
  const remoteSource = audioContext.createMediaStreamSource(remoteStream);
  localSource.connect(analyzer);
  remoteSource.connect(analyzer);
  const theme = useContext(ThemeContext);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  analyzer.fftSize = 2 ** 10;
  const bufferLength = analyzer.frequencyBinCount;
  const frequencyData = new Uint8Array(bufferLength);

  const drawFrequency = (fData: Uint8Array) => {
    analyzer.getByteFrequencyData(frequencyData);

    const canvas = canvasRef.current;

    if (canvas) {
      const { height, width } = canvas;
      const canvasContext = canvas.getContext("2d");

      if (canvasContext) {
        canvasContext.clearRect(0, 0, width, height);
        canvasContext.fillStyle = theme.colors.redesign.b70;
        const barWidth = width / bufferLength;
        let x = 0;

        fData.forEach((amount: number) => {
          const percent = amount / 255;
          const barHeight = (height * percent) / 2;

          canvasContext.fillRect(x, height / 2, barWidth, barHeight);
          canvasContext.fillRect(x, height / 2 - barHeight, barWidth, barHeight);

          x += barWidth + 6;
        });
      }
    }

    requestAnimationFrame(() => drawFrequency(fData));
  };

  drawFrequency(frequencyData);

  return <Canvas ref={canvasRef} />;
};

export default AudioVisualizer;

const Canvas = styled.canvas`
  height: 100px;
  margin-top: auto;

  ${props => props.theme.belowBreakpoint} {
    height: 200px;
    width: 90%;
  }
`;
