import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { MedicalNoteAddendum } from "api/models/MedicalNoteAddendum";

import { AddendumForm, AddendumList } from ".";

interface Props {
  careEventId: number;
  medical_note_addenda?: MedicalNoteAddendum[];
}

export const Addenda: React.VFC<Props> = ({ careEventId, medical_note_addenda }) => {
  const [formTriggerHeader, setFormTriggerHeader] = useState(false);
  const { t } = useTranslation();

  const isAddendaPopulated = (medical_note_addenda && medical_note_addenda.length > 0) ?? false;

  return (
    <AddendaSection data-testid="record-note-addenda" $hasBackground={isAddendaPopulated}>
      <AddendaContent>
        {(formTriggerHeader || isAddendaPopulated) && (
          <AddendaHeader $darkStyle={isAddendaPopulated}>{t("patients.notes.sections.addenda.header")}</AddendaHeader>
        )}

        {isAddendaPopulated && <AddendumList medical_note_addenda={medical_note_addenda} />}

        <AddendumForm careEventId={careEventId} setFormTriggerHeader={setFormTriggerHeader} />
      </AddendaContent>
    </AddendaSection>
  );
};

const AddendaSection = styled.div<{ $hasBackground: boolean }>`
  background: ${({ theme, $hasBackground }) => $hasBackground && theme.colors.greys.light4};
  margin-top: ${({ $hasBackground }) => $hasBackground && "25px"};
  padding: 24px 16px;
`;

const AddendaContent = styled.div`
  ${props => props.theme.aboveBreakpoint} {
    max-width: 50%;
  }
`;

const AddendaHeader = styled.div<{ $darkStyle: boolean }>`
  ${({ theme }) => theme.font.header4}
  color: ${({ theme, $darkStyle }) => ($darkStyle ? theme.colors.greys.slate : theme.colors.greys.warm)};
  line-height: 20px;
  margin-bottom: 10px;
`;
