import type React from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useGetPartnerUnclaimedReferrals from "api/hooks/useGetPartnerUnclaimedReferrals";
import type { UnclaimedReferral } from "api/schemas/PartnerUnclaimedReferralSchema";
import { SearchIcon } from "assets";
import Pagination from "shared/atoms/Pagination";
import { Table, TableFooter, TableMissingContent, TableSort } from "shared/molecules/Table";

import { HeaderContainer, StyledSearch, Title } from "./helpers";
import UnclaimedReferralsTableRow from "./UnclaimedReferralsTableRow";

const LIMIT = 10;

const UnclaimedReferralsTable: React.VFC = () => {
  const { watch, register, setValue } = useForm();
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const COLUMNS = [
    t("patients.id"),
    t("partner_patients.name"),
    t("partner_referral_table.referral_issued"),
    t("partner_referral_table.duration"),
  ];
  const [sort, setSort] = useState({
    selectedColumn: COLUMNS[1],
    descending: true,
  });
  const { data, isLoading, error } = useGetPartnerUnclaimedReferrals();

  const searchValue = watch("referralSearch", "");
  const onSort = (clickedColumn: string) => {
    setSort({
      selectedColumn: clickedColumn,
      descending: sort.selectedColumn === clickedColumn ? !sort.descending : true,
    });
  };

  const getSelectedColumnData = (row: UnclaimedReferral): string | number => {
    let columnData: string | number = "";
    switch (sort.selectedColumn) {
      case t("partner_patients.name"):
        columnData = row.patientName;
        break;
      case t("partner_referral_table.referral_issued"):
        columnData = row.createdAt;
        break;
      case t("partner_referral_table.duration"):
        columnData = row.durationDays;
        break;
      default:
        break;
    }

    return columnData;
  };

  const compare = (x: string | number, y: string | number): number => {
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  const compareRows = (a: UnclaimedReferral, b: UnclaimedReferral): number => {
    return compare(getSelectedColumnData(a), getSelectedColumnData(b));
  };
  const renderTableContent = () => {
    let tableData;
    if (isLoading || error) {
      return <TableMissingContent loading={isLoading} error={!!error} columnCount={COLUMNS.length} />;
    }
    if (data && data.length === 0) {
      return <TableMissingContent nodata message={t("unclaimed_referrals.no_content")} columnCount={COLUMNS.length} />;
    }
    if (searchValue && data) {
      tableData = [...data].filter((value: UnclaimedReferral) =>
        searchValue
          ?.trim()
          .toLowerCase()
          .split(" ")
          .every((el: string) => value.patientName.toLowerCase().includes(el))
      );
    } else {
      tableData = data;
    }
    if (tableData) {
      return [...tableData]
        .sort(compareRows)
        .filter((_node: UnclaimedReferral, index: number) => index >= offset && index < offset + LIMIT)
        .map((value: UnclaimedReferral) => {
          return <UnclaimedReferralsTableRow {...value} key={value.id} />;
        });
    }
    return null;
  };
  const START = offset + 1;
  const END = offset + LIMIT;
  const hasNextPage = data ? data.length > offset + LIMIT : false;
  const hasPreviousPage = offset !== 0 || false;
  const totalCount = data?.length || 0;

  return (
    <>
      <HeaderContainer>
        <Title>{t("unclaimed_referrals_table.header")}</Title>
        {data && data.length > 0 && (
          <StyledSearch
            data-testid="search-input-referrals"
            reset={() => setValue("referralSearch", "")}
            icon={SearchIcon}
            {...register("referralSearch")}
            placeholder={t("patients.search")}
          />
        )}
      </HeaderContainer>
      <Table>
        <thead>
          <TableSort columns={COLUMNS} sortBy={sort.selectedColumn} descending={sort.descending} onSort={onSort} />
        </thead>
        <tbody>{renderTableContent()}</tbody>
        <tfoot>
          {totalCount > LIMIT && (
            <TableFooter colSpan={3}>
              <Pagination
                totalCount={totalCount}
                first={START}
                last={END < totalCount ? END : totalCount}
                pageInfo={{ hasNextPage, hasPreviousPage }}
                onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
              />
            </TableFooter>
          )}
        </tfoot>
      </Table>
    </>
  );
};

export default UnclaimedReferralsTable;
