import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { CareEventSuggested } from "api/schemas/TreatmentActivityLog";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getCareEventsSuggested";
type QueryKey = [typeof queryKeyPrefix, number[]];
export const getCareEventsSuggestedQueryKey = (patientIDs: number[]): QueryKey => [queryKeyPrefix, patientIDs];

const useGetCareEventsSuggested = (
  patientIDs: number[],
  options?: UseQueryOptions<CareEventSuggested[], Error, CareEventSuggested[], QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getCareEventsSuggestedQueryKey(patientIDs), () => client.getCareEventsSuggested(patientIDs), options);
};

export default useGetCareEventsSuggested;
