import type React from "react";
import { useEffect, useState } from "react";

import type { BookingIntervalResponse } from "api/schemas/BookingInterval";
import type { NewAppointment } from "api/schemas/NewAppointment";
import { getStorageValue, setStorageValue } from "utils/storage";

import CalendarContext from "./CalendarContext";
import type { Form, Personnel, SelectedDate } from "./CalendarContextValue";

const CalendarContextProvider: React.FC = ({ children }) => {
  const [selectedAppointment, setSelectedAppointment] = useState<NewAppointment | null>(null);
  const [selectedBookingInterval, setSelectedBookingInterval] = useState<BookingIntervalResponse | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [selectedPersonnel, setSelectedPersonnel] = useState<Personnel[]>([]);
  const [activeForm, setActiveForm] = useState<Form | null>(null);
  const [refreshForm, setRefreshForm] = useState(false);
  const [showMessagesForm, setShowMessagesForm] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<SelectedDate | null>(null);

  const calendarSettings = getStorageValue("calendar_settings");

  const [showMyAvailability, setShowMyAvailability] = useState<boolean>(calendarSettings?.show_my_availability ?? true);
  const [showTherapistAvailability, setShowTherapistAvailability] = useState<boolean>(
    calendarSettings?.show_others_availability ?? false
  );
  const [showCanceledAppointments, setShowCanceledAppointments] = useState<boolean>(
    calendarSettings?.show_canceled ?? false
  );
  const [showRescheduledAppointments, setShowRescheduledAppointments] = useState<boolean>(
    calendarSettings?.show_rescheduled ?? false
  );
  const [showWeekends, setShowWeekends] = useState<boolean>(calendarSettings?.show_weekends ?? false);

  const updateActiveForm = (newForm: Form | null) => {
    if (activeForm && activeForm === newForm) {
      setRefreshForm(true);
    }

    setActiveForm(newForm);
  };

  useEffect(() => {
    setStorageValue("calendar_settings", {
      show_my_availability: showMyAvailability,
      show_others_availability: showTherapistAvailability,
      show_canceled: showCanceledAppointments,
      show_rescheduled: showRescheduledAppointments,
      show_weekends: showWeekends,
    });
  }, [
    showMyAvailability,
    showTherapistAvailability,
    showCanceledAppointments,
    showRescheduledAppointments,
    showWeekends,
  ]);

  const value = {
    selectedAppointment,
    setSelectedAppointment,
    selectedBookingInterval,
    setSelectedBookingInterval,
    selectedPatient,
    setSelectedPatient,
    selectedPersonnel,
    setSelectedPersonnel,
    activeForm,
    setActiveForm: updateActiveForm,
    refreshForm,
    setRefreshForm,
    showMessagesForm,
    setShowMessagesForm,
    selectedDate,
    setSelectedDate,
    showMyAvailability,
    setShowMyAvailability,
    showTherapistAvailability,
    setShowTherapistAvailability,
    showCanceledAppointments,
    setShowCanceledAppointments,
    showRescheduledAppointments,
    setShowRescheduledAppointments,
    showWeekends,
    setShowWeekends,
  };

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

export default CalendarContextProvider;
