import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface Props {
  readonly color?: string;
}

const NavigationLink = styled(NavLink)<Props>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${props => ({ ...props.theme.font.link1 })};
  color: ${({ color = null, theme }) => color || theme.colors.primary.base};
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
  padding: 12px 8px;
`;

export default NavigationLink;
