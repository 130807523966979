import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { EditIcon, StickyNote } from "assets";
import IconButton from "shared/atoms/IconButton";

interface PatientViewStickyNoteProps {
  readonly stickyNoteMessage: string;
  readonly setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}
const PatientViewStickyNote: React.VFC<PatientViewStickyNoteProps> = ({ stickyNoteMessage, setIsEditing }) => {
  const { t } = useTranslation();
  const isDefault = stickyNoteMessage === "";
  return (
    <StickyNoteContainer data-testid="patient-sticky-note">
      <Container>
        <IconContainer>
          <StickyNote />
        </IconContainer>
        <NonEditNoteText>{isDefault ? t("sticky_note.default") : stickyNoteMessage}</NonEditNoteText>
      </Container>
      <Container>
        <IconButton
          dataTestId="edit-sticky-note"
          label={isDefault ? t("buttons.write") : t("buttons.edit")}
          onClick={() => setIsEditing(true)}
        >
          <EditIcon />
        </IconButton>
      </Container>
    </StickyNoteContainer>
  );
};

export const StickyNoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 40px 20px 40px;
  border-radius: ${props => props.theme.borderRadius.basic};
  background-color: ${props => props.theme.colors.greys.light3};

  ${props => props.theme.belowBreakpoint} {
    margin: 0 12px;
  }
`;

const IconContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.spacing.S_10};
  margin-right: ${props => props.theme.spacing.S_10};
  * {
    margin: ${props => props.theme.spacing.S_5};
  }
`;

const NonEditNoteText = styled.span`
  color: #686d74;
  white-space: pre-wrap;
`;

export default PatientViewStickyNote;
