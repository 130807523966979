type Item = { label: string; value: string };

const generateDropdownValues = (length?: number): Array<Item> => {
  let i = 0;
  const items = [{ label: "-", value: "" }];

  if (length)
    while (i <= length) {
      items.push({ label: i.toString(), value: i.toString() });
      i += 1;
    }

  return items;
};
export default generateDropdownValues;
