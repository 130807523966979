import type React from "react";
import { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { usePostMedicalNoteAddendum } from "api/hooks/usePostMedicalNoteAddendum";
import { NewMessageIcon } from "assets";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import { MUITextInput } from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";

interface Props {
  careEventId: number;
  setFormTriggerHeader: (formTriggerHeader: boolean) => void;
}

export const AddendumForm: React.VFC<Props> = ({ careEventId, setFormTriggerHeader }) => {
  const [showAddendumForm, setShowAddendumForm] = useState(false);
  const [error, setError] = useState(false);
  const patientId = Number(useParams().patientId);
  const { t } = useTranslation();
  const form = useForm({
    mode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  const postMedicalNoteAddendumMutation = usePostMedicalNoteAddendum();

  const onSubmit = handleSubmit(({ addendum }) => {
    postMedicalNoteAddendumMutation.mutate(
      { body: addendum, careEventId, patientId },
      {
        onSuccess() {
          setShowAddendumForm(false);
          setError(false);
          reset();
        },
        onError() {
          setError(true);
        },
      }
    );
  });

  return (
    <>
      <OpenFormButton
        data-testid="write-addendum-button"
        onClick={() => {
          setShowAddendumForm(true);
          setFormTriggerHeader(true);
        }}
        $show={!showAddendumForm}
      >
        <StyledIcon />
        {t("patients.notes.sections.addenda.write_addendum")}
      </OpenFormButton>

      {showAddendumForm && (
        <>
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <MUITextInput
                error={errors?.addendum ? t("errors.string.empty") : undefined}
                label={t("patients.notes.sections.addenda.label")}
                name="addendum"
                type="text"
                multiline
                required
              />

              <ButtonGroup>
                <CancelButton
                  data-testid="addendum-form-cancel-button"
                  type="button"
                  onClick={() => {
                    setShowAddendumForm(false);
                    setFormTriggerHeader(false);
                  }}
                >
                  {t("buttons.cancel")}
                </CancelButton>

                <PrimaryButton data-testid="addendum-form-submit-button" type="submit">
                  {t("buttons.sign")}
                </PrimaryButton>
              </ButtonGroup>
            </form>
          </FormProvider>

          {error && <Notification variant="danger">{t("patients.notes.sections.addenda.error")}</Notification>}
        </>
      )}
    </>
  );
};

const OpenFormButton = styled.div<{ $show?: boolean }>`
  display: ${({ $show }) => ($show ? "flex" : "none")};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.colors.primary.base};
  ${props => ({ ...props.theme.font.link1 })};
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledIcon = styled(NewMessageIcon)`
  width: 24px;
  height: 24px;
  margin-right: ${props => props.theme.spacing.S_5};
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    justify-content: center;
  }
`;

const CancelButton = styled(TextButton)`
  ${props => props.theme.aboveBreakpoint} {
    margin-right: ${props => props.theme.spacing.S_20};
  }
`;
