import type React from "react";

import styled from "styled-components";

import { InfoColumn } from "../helpers";

const QuestionWrapper = styled(InfoColumn)`
  background: ${props => props.theme.colors.white};
  padding: 0;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const QuestionBox = styled.div<{ subQuestion?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.spacing.S_15};
  background: ${props => props.theme.colors.greys.light4};
  box-shadow: 3px 2px 4px ${props => props.theme.colors.greys.pinkish};
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  ${props => props.theme.font.link1}
  ${props =>
    props.subQuestion &&
    `
  margin-left: ${props.theme.spacing.S_30};
  left: 30px;
  ${props.theme.belowBreakpoint} {
    margin-left: 0;
    left: 0;
  }
  border-left: 1px solid ${props.theme.colors.greys.silver};
  width: 369px;
  min-width: 369px;
  font-weight: ${props.theme.fontWeight.regular};
`}
z-index: 1;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

interface ReportQuestionWrapperProps {
  subQuestion?: boolean;
}

const ReportQuestionWrapper: React.FC<ReportQuestionWrapperProps> = ({ children, subQuestion }) => {
  return (
    <QuestionWrapper>
      <QuestionBox subQuestion={subQuestion}>{children}</QuestionBox>
    </QuestionWrapper>
  );
};

export default ReportQuestionWrapper;
