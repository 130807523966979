import TOGGLES from "./toggles";
import type {
  CheckFunction,
  CriteriaConditionNames,
  CriteriaConditions,
  FeatureSettings,
  FeatureToggles,
  StaticFeatureInfo,
  StaticFeatureToggles,
  ToggleOptions,
} from "./types";
import validators from "./validators";

let STATIC_TOGGLES: StaticFeatureToggles;

const getPathParts = (path: string): string[] => path.split(".");

const validateConditions = (conditions: CriteriaConditions, options: ToggleOptions) => {
  return Object.keys(conditions).reduce((result, conditionName) => {
    if (!result) {
      // escape early since one condition is false
      return false;
    }
    const name = conditionName as CriteriaConditionNames;
    const validate = validators[name];
    if (!validate) {
      // if no validator then it cannot be checkd and the outcome must be false
      return false;
    }
    const conditionValue = conditions[name];
    return result && validate(conditionValue, options);
  }, true);
};

const validateCriteria = (criteria: CriteriaConditions[], options: ToggleOptions = {}) => {
  const validCriteria =
    criteria.find(conditions => {
      const validatedCriteria = validateConditions(conditions, options);
      return validatedCriteria;
    }) || false;
  return validCriteria;
};

const isFeatureItemActive = (feature: FeatureSettings, options: ToggleOptions): boolean => {
  let featureActive: boolean = typeof feature.active !== "undefined" ? Boolean(feature.active) : true;

  if (featureActive && feature.criteria) {
    featureActive = featureActive && Boolean(validateCriteria(feature.criteria, options));
  }

  return featureActive;
};

const isFeaturePathActive = (pathParts: string[], featuresInfo: FeatureToggles, options?: ToggleOptions): boolean => {
  const featureName = pathParts.shift();
  let featureActive = false;

  if (!featureName || !featuresInfo[featureName]) {
    return false;
  }

  const feature = featuresInfo[featureName];
  featureActive = typeof feature.active === "boolean" ? feature.active : true;

  if (featureActive && feature.criteria) {
    featureActive = featureActive && Boolean(validateCriteria(feature.criteria, options));
  }

  if (featureActive && pathParts.length) {
    const childrenFeatures = feature.features;
    if (!childrenFeatures) {
      return false;
    }
    return isFeaturePathActive(pathParts, childrenFeatures, options);
  }

  return featureActive;
};

const getStaticFeatureToggle = (
  features: FeatureToggles,
  options: ToggleOptions,
  parentState?: boolean
): Readonly<StaticFeatureToggles> => {
  const checkedFeatures: StaticFeatureToggles = {};
  Object.keys(features).forEach((featureName: string) => {
    const feature = features[featureName];

    checkedFeatures[featureName] = { active: false };
    let activeFeature = checkedFeatures[featureName];

    if (parentState === false) {
      activeFeature.active = parentState;
    } else {
      activeFeature.active = isFeatureItemActive(feature, options);
    }
    if (feature.features) {
      activeFeature = {
        ...activeFeature,
        ...getStaticFeatureToggle(feature.features, options, activeFeature.active),
      };
    }
    checkedFeatures[featureName] = activeFeature;
  });
  return checkedFeatures;
};

/**
 * Provides a `hasFeature` function for the statically prepared toggle structure
 * Note: Unexisting features will return as not active
 *
 * @param options Options to be used when preparing the static toggle structure (eg: user profile information)
 * @param reset If it should regenerate the statick toggle structure (detauls to `false`)
 * @returns Check feature function
 */
export const getHasFeature = <T extends string>(options: ToggleOptions, reset = false): CheckFunction<T> => {
  if (reset || !STATIC_TOGGLES) {
    STATIC_TOGGLES = getStaticFeatureToggle(TOGGLES, options);
  }

  return (featurePath: T): boolean => {
    if (!featurePath || !STATIC_TOGGLES) {
      return false;
    }
    const pathParts = getPathParts(featurePath);
    let currentFeat: StaticFeatureInfo;
    const result = pathParts.reduce((final: boolean, path: string, idx: number): boolean => {
      if (path !== "active") {
        currentFeat = idx === 0 ? STATIC_TOGGLES[path] : (currentFeat[path] as StaticFeatureInfo);
        if (final === false || (idx === 0 && typeof currentFeat === "undefined")) {
          return false;
        }
        return (currentFeat.active as boolean) || false;
      }
      return final;
    }, true);

    return result;
  };
};

/**
 * Check if a feature is supposed to be active for the current moment
 * Note: Unexisting features will return as not active
 *
 * @param featurePath Feature path to check (dot notation)
 * @param options Options to be used when preparing the static toggle structure (eg: user profile information)
 * @returns True if a feature is active
 */
export const isFeatureActive = (featurePath: string, options?: ToggleOptions): boolean => {
  if (!featurePath) {
    return false;
  }
  const path = getPathParts(featurePath);
  return isFeaturePathActive(path, TOGGLES, options);
};

export default { getHasFeature, isFeatureActive };
