import type React from "react";

import type { TooltipProps } from "@material-ui/core";
import { Tooltip as MUITooltip, withStyles } from "@material-ui/core";

import theme from "theme";

const TextOnlyTooltip = withStyles({
  arrow: {
    "&:before": {
      border: `1px solid ${theme.colors.redesign.db30}`,
    },
    color: theme.colors.white,
  },
  tooltip: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.redesign.db30}`,
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.2)",
    color: theme.colors.redesign.db90,
    fontSize: "14px",
  },
})(MUITooltip);

interface Props extends TooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
  disabled?: boolean;
  title: NonNullable<React.ReactNode>;
}

export const Tooltip: React.VFC<Props> = ({ children, disabled, title, ...props }) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <TextOnlyTooltip title={title} {...props}>
      {children}
    </TextOnlyTooltip>
  );
};
