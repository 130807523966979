import type { Language, Market } from "types";
import { LanguageTypes, MarketTypes } from "types";

import getDefaultLng from "./getDefaultLng";

type DropdownOption = { label: string; value: Language };

const getLngDropdownOpts = (region: Market, lng?: Language): DropdownOption[] | null => {
  if (lng && lng !== LanguageTypes.en) {
    return [
      { label: `common.locales.${lng}`, value: lng },
      { label: "common.locales.en", value: LanguageTypes.en },
    ];
  }
  if (region !== MarketTypes.US && region !== MarketTypes.GB) {
    const defaultLngForRegion = getDefaultLng(region);
    if (defaultLngForRegion !== LanguageTypes.en) {
      return [
        { label: `common.locales.${defaultLngForRegion}`, value: defaultLngForRegion },
        { label: "common.locales.en", value: LanguageTypes.en },
      ];
    }
  }
  // only english no need for a dropdown
  return null;
};

export default getLngDropdownOpts;
