import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import { PasswordResetForm } from "../components/Forms";
import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { LoginWrapper, SubmitButton } from "../components/styledComponents";

const GoBack = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: ${props => `${props.theme.spacing.S_25} ${props.theme.spacing.S_15}`};
  min-height: 330px;

  ${props => props.theme.belowBreakpoint} {
    justify-content: flex-start;
  }
`;

const ResetPassword: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch("*");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.RESET_PASSWORD.FORGOT_PASSWORD, match.pathname);
    }
  }, [match?.pathname]);

  return (
    <LoginWrapper>
      {success ? (
        <LoginContainer>
          <LoginInfoContainer success={success} title={t("login.info.reset_password.success_title")}>
            {t("login.info.reset_password.success_text")}
          </LoginInfoContainer>
          <GoBack>
            <SubmitButton onClick={() => navigate("/")}>{t("buttons.reset_password_success")}</SubmitButton>
          </GoBack>
        </LoginContainer>
      ) : (
        <LoginContainer>
          <LoginInfoContainer title={t("login.info.reset_password.title")}>
            {t("login.info.reset_password.text")}
          </LoginInfoContainer>
          <PasswordResetForm setSuccess={() => setSuccess(true)} />
        </LoginContainer>
      )}
    </LoginWrapper>
  );
};

export default ResetPassword;
