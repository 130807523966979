export const generateArrayWithMinutesStep = (min: number, max: number, duration?: number) => {
  const step = duration ?? 15; // default step is 15 minutes
  const numSteps = Math.floor((max - min) / step) + 1;

  return Array.from({ length: numSteps }, (_, index) => min + index * step);
};

export const convertMinutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};
