import type React from "react";

import { useTranslation } from "react-i18next";

import { Container, HeaderGroup, Link, StyledBackIcon } from "routes/messages/styles/header";
import Hidden from "shared/atoms/Hidden";

const BroadcastHeader: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderGroup>
        <Hidden type="aboveTablet">
          <Link data-testid="back-to-messages" to="/messages">
            <StyledBackIcon />
          </Link>
        </Hidden>
        {t("messages.broadcast")}
      </HeaderGroup>
    </Container>
  );
};

export default BroadcastHeader;
