import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Notification } from "shared/atoms/Notification";
import { CurrentPatientContext } from "utils/contexts";

import InformationRoutes from "../components/Information";
import PatientSubMenu from "../components/PatientSubMenu";

const Information: React.VFC = () => {
  const { t } = useTranslation();
  const { patientError } = useContext(CurrentPatientContext);

  return patientError ? (
    <ErrorContainer>
      <Notification variant="danger" style={{ margin: "10px" }}>
        {t("errors.generic")}
      </Notification>
    </ErrorContainer>
  ) : (
    <div>
      <PatientSubMenu />
      <InformationRoutes />
    </div>
  );
};

const ErrorContainer = styled.div`
  margin: 0 auto ${props => props.theme.spacing.S_20};
  max-width: 1000px;
  padding: ${props => props.theme.spacing.S_20};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

export default Information;
