import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

export type NewTherapistLicense = {
  stateCode: string;
  expirationDate: Date;
  licenseNumber: string;
};

export default async (therapistID: number, newTherapistLicense: NewTherapistLicense): Promise<AxiosResponse> => {
  return AxiosInstance.post(`therapists/${therapistID}/us_therapist_licenses`, {
    state_code: newTherapistLicense.stateCode,
    expiration_date: newTherapistLicense.expirationDate,
    license_number: newTherapistLicense.licenseNumber,
  });
};
