import type React from "react";

import styled from "styled-components";

const Container = styled.div<{ bgColor?: string; color?: string; borderColor?: string; uppercase?: boolean }>`
  display: flex;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: ${props => props.uppercase && "uppercase"};
  padding: 0 8px;
  margin-right: ${props => props.theme.spacing.S_5};
  border: 1px solid ${props => (props.borderColor ? props.borderColor : props.theme.colors.redesign.p40)};
  background-color: ${props => (props.bgColor ? props.bgColor : props.theme.colors.redesign.p10)};
  color: ${props => (props.color ? props.color : props.theme.colors.redesign.p100)};
`;

interface Props {
  readonly label: string;
  readonly bgColor?: string;
  readonly color?: string;
  readonly borderColor?: string;
  readonly className?: string;
  readonly dataTestId?: string;
  readonly uppercase?: boolean;
}

const Tag: React.VFC<Props> = ({ label, bgColor, color, borderColor, className, dataTestId = "", uppercase }) => {
  return (
    <Container
      bgColor={bgColor}
      color={color}
      borderColor={borderColor}
      className={className}
      data-testid={dataTestId}
      uppercase={uppercase}
    >
      {label}
    </Container>
  );
};

export default Tag;
