import { useMutation } from "react-query";

import type { SwitchLateralLocation, SwitchLateralLocationArgs } from "api/schemas/patients/SwitchLateralLocation";
import useApiClient from "api/useApiClient";

export const usePutPatientLateralLocation = () => {
  const client = useApiClient();
  return useMutation<SwitchLateralLocation, Error, SwitchLateralLocationArgs>(args =>
    client.putPatientSwitchLateralLocation(args)
  );
};
