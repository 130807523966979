import type { AxiosResponse } from "axios";
import type { UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import { AxiosInstance } from "api";

export type isNoteCreatableResponse = {
  discharge_event: boolean;
  supplemental_event: boolean;
  chat_event: boolean;
  treatment_event: boolean;
  treatment_and_progress_event: boolean;
  re_evaluation_event: boolean;
  physical_visit_new: boolean;
  physical_visit_return: boolean;
};

const signNote = (cid: number, exclusion: string | null | undefined): Promise<AxiosResponse<unknown>> => {
  return AxiosInstance.post(
    `care_events/${cid}/sign${exclusion ? `?exclusion_reason=${exclusion.split(" ").join("+")}` : ""}`
  );
};

const removeNote = (cid: number): Promise<AxiosResponse<unknown>> =>
  AxiosInstance.post(`care_events/${cid}/discard`, { id: cid });

const newNote = (id?: number, type?: string, TID?: number): Promise<AxiosResponse<Record<string, unknown>>> =>
  AxiosInstance.post(`/patients/${id}/care_events`, {
    patient_id: id,
    label: type,
    therapist_id: TID,
  });

const useNewNote = (): UseMutationResult<AxiosResponse<Record<string, unknown>>, unknown, number, unknown> => {
  return useMutation(pid => newNote(pid));
};

const isNoteCreatable = (pid?: number): Promise<AxiosResponse<isNoteCreatableResponse>> => {
  return AxiosInstance.get(`/patients/${pid}/care_events/creatable`);
};

export { signNote, newNote, removeNote, useNewNote, isNoteCreatable };
