import type React from "react";
import { useEffect, useState } from "react";

import { Navigate, Route, Routes, useLocation, useMatch, useParams } from "react-router-dom";
import styled from "styled-components";

import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";

import PatientHeader from "../PatientProfile/components/PatientHeader";
import { Information, Overview } from "../PatientProfile/views";
import { getPatientInfo } from "../queries";

const PatientsContainer = styled.div`
  background: ${props => props.theme.colors.white};
  height: 100vh;
  overflow: scroll;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LocationState = {
  pathname: string;
  text: string;
};

const PatientProfileDoctor: React.VFC = () => {
  const { patientId } = useParams();
  const match = useMatch("*");
  const { state } = useLocation();
  const [previousPage, setPreviousPage] = useState({
    pathname: "/",
    text: "dashboard",
  });
  const [patientProfileRequest, setPatientProfileRequest] = useState({ data: {}, loading: true, error: undefined });

  useEffect(() => {
    if (state) {
      setPreviousPage(state as LocationState);
    }
  }, [state]);

  useEffect(() => {
    getPatientInfo(Number(patientId))
      .then(res => {
        if (match?.pathname) {
          AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PATIENTS_PROFILE, match.pathname);
        }

        const patientData = res.data;
        setPatientProfileRequest({
          data: { ...patientData },
          loading: false,
          error: undefined,
        });
      })
      .catch(error => {
        if (match?.pathname) {
          AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PATIENTS_PROFILE, match.pathname);
        }
        setPatientProfileRequest({ data: {}, loading: false, error });
      });
  }, [match, patientId]);

  const updatePatientContext = (value: { prio: boolean }) =>
    setPatientProfileRequest({ ...patientProfileRequest, data: { ...patientProfileRequest.data, ...value } });

  if (patientProfileRequest.data && !patientProfileRequest.loading) {
    return (
      <CurrentPatientContext.Provider
        value={{
          patient: patientProfileRequest.data,
          updatePatientContext,
          previousPage,
          patientError: patientProfileRequest.error,
        }}
      >
        <Routes>
          <Route path="information">
            <PatientsContainer>
              <PatientHeader />
              <Information />
            </PatientsContainer>
          </Route>
          <Route path="overview">
            <PatientsContainer>
              <PatientHeader />
              <Overview />
            </PatientsContainer>
          </Route>
          <Route path="/" element={<Navigate to={{ pathname: "overview" }} state={state} />} />
        </Routes>
      </CurrentPatientContext.Provider>
    );
  }
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default PatientProfileDoctor;
