import type { ICDCodeType } from "types/ICDCodes";
import { getHasFeature } from "utils/features";
import { Feature } from "utils/features/types";

const hasFeature = getHasFeature({});

export const ICDCodes = {
  M16_1: "M16.1",
  M17_1: "M17.1",
  R52: "R52",
  R52_9: "R52.9",
  M19_0D: "M19.0D",
  M19_049: "M19.049",
  M54_2: "M54.2",
  M54_5: "M54.5",
  M54_50: "M54.50",
  M25_5B: "M25.5B",
} as const;

export const mandatoryOaDiagnosisCodes = [
  "M16.0",
  "M16.1",
  "M16.2",
  "M16.3",
  "M16.4",
  "M16.5",
  "M16.6",
  "M16.7",
  "M16.9",
  "M17.0",
  "M17.1",
  "M17.2",
  "M17.3",
  "M17.4",
  "M17.5",
  "M17.9",
];

// ICD codes ending may vary, but we can match their start
// Tried to find the root code for each location using https://icd.who.int/browse10/2019/en#/M54.5
export const ICDCodeBaseLookup: Record<ICDCodeType, string> = {
  ALL: "",
  hip: "M16",
  knee: "M17",
  hand: "M19",
  back: "M54.5",
  shoulder: "M25.5",
  neck: "M54.2",
  pelvis: "",
  foot: "",
  head: "",
  elbow: "",
  exclusion: "R52",
  osteoporosis: "M80",
} as const;

export const getR52Code = (): string => (hasFeature(Feature.R52_ICD_CODE) ? ICDCodes.R52 : ICDCodes.R52_9);
export const isICDCodeMatchingTreatment = (ICDCode: string[], treatment: ICDCodeType): boolean => {
  if (ICDCode && treatment) {
    return ICDCode.some(
      icd_code => icd_code.includes(ICDCodeBaseLookup[treatment]) || icd_code.includes(ICDCodeBaseLookup.exclusion)
    );
  }
  return true;
};
