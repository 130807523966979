import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

import type { Message } from "../types";

type Params = {
  [param: string]: string | number;
};
export const getBroadcastMessages = async (id: number, params: Params): Promise<AxiosResponse<Message[]> | false> => {
  try {
    const param_string = Object.keys(params).reduce((current: string, key: string, index: number, keys: string[]) => {
      let s = current;
      if (key && params[key]) s += `page[${key}]=${params[key]}`;
      if (index < keys.length - 1) s += "&";
      return s;
    }, "");
    const response = await AxiosInstance.get<Message[]>(`/users/${id}/conversations/broadcast?${param_string}`);
    return response;
  } catch (error) {
    return false;
  }
};

export default getBroadcastMessages;
