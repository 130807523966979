import type React from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 15px;
  margin: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_5} 0;
  padding: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_15};
  color: ${props => props.theme.colors.greys.dark};
  background-color: ${props => props.theme.colors.greys.light4};
  ${props => props.theme.font.caption};
  font-weight: ${props => props.theme.fontWeight.regular};
  user-select: none;
  &:hover {
    background-color: ${props => props.theme.colors.greys.light2};
  }
`;

const CloseBtn = styled.a`
  display: flex;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  margin-left: ${props => props.theme.spacing.S_10};
  cursor: pointer;
`;

interface Props {
  readonly label: string;
  readonly close: () => void;
}

const FilterTag: React.VFC<Props> = ({ label, close }) => {
  return (
    <Container>
      {label}
      <CloseBtn data-testid="clear-filter" onClick={close}>
        &#10005;
      </CloseBtn>
    </Container>
  );
};

export default FilterTag;
