import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { abortBankID, initBankID } from "routes/login/queries";
import type { CheckResponse, LegacyProgressStatus } from "routes/login/queries/api";
import getSignedOutParams from "routes/login/utils/getSignedOutParams";
import { translateStatus } from "routes/login/utils/translateStatus";

import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { Container, Instructions, LoginWrapper, Status } from "../components/styledComponents";

export const OpenDesktopApp: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const confirmation_token = new URLSearchParams(location.search).get("t");
  const { redirectUrl } = getSignedOutParams(location);
  const successUrl = redirectUrl || "/dashboard";
  const [status, setStatus] = useState<LegacyProgressStatus | null>(null);

  useEffect(() => {
    const handleFlowUpdate = (data: CheckResponse) => {
      setStatus(data.legacy_progress_status);
    };

    initBankID({
      confirmation_token: confirmation_token || undefined,
      flowType: "desktop",
      successUrl,
      navigate,
      onInitDone: data => {
        const { auto_start_token } = data;
        window.location.href = `bankid:///?autostarttoken=${auto_start_token}&redirect=null`;
      },
      onUpdate: handleFlowUpdate,
    });

    return () => {
      abortBankID();
    };
  }, []);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.bankid.title")}>
          {t("login.bankid.open_app.header_text")}
        </LoginInfoContainer>
        <Container>
          <Instructions $center>{t("login.bankid.open_app.instructions")}</Instructions>
          {status && <Status>{translateStatus({ flowType: "desktop", status })}</Status>}
        </Container>
      </LoginContainer>
    </LoginWrapper>
  );
};
