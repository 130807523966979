import styled from "styled-components";

import { CounterMinusAltIcon, CounterPlusAltIcon } from "assets";

const Counter = ({
  value,
  maxValue,
  minValue,
  clickCallback,
}: {
  value: number;
  maxValue: number;
  minValue: number;
  clickCallback: (v: number) => void;
}) => {
  return (
    <Root>
      <RightBorder>
        <ButtonWrapper
          onClick={e => {
            e.stopPropagation();
            if (value > minValue) {
              clickCallback(value - 1);
            }
          }}
        >
          <CounterMinusAltIcon />
        </ButtonWrapper>
      </RightBorder>
      <Text>{`${value}/${maxValue}`} </Text>
      <LeftBorder>
        <ButtonWrapper
          onClick={e => {
            e.stopPropagation();
            if (value < maxValue) {
              clickCallback(value + 1);
            }
          }}
        >
          <CounterPlusAltIcon />
        </ButtonWrapper>
      </LeftBorder>
    </Root>
  );
};

const ButtonWrapper = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  padding: 0;
  border: 0;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  border-radius: 4px;
`;

const Text = styled.div`
  padding: 0px 8px;
  flex-grow: 1;
  text-align: center;
`;

const LeftBorder = styled.div`
  border-left: 1px solid ${props => props.theme.colors.greys.concrete};
`;

const RightBorder = styled.div`
  border-right: 1px solid ${props => props.theme.colors.greys.concrete};
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.greys.concrete};
  border-radius: 4px;
`;

export default Counter;
