import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useProfileContext } from "contexts/ProfileContext";

import AppointmentScheduledNotificationSwitch from "../AppointmentScheduledNotificationSwitch";
import AvailableToSubstituteSwitch from "../AvailableToSubstituteSwitch";
import NewPatientNotificationSwitch from "../NewPatientNotificationSwitch";
import WeeklyPatientLimit from "../WeeklyPatientLimit";

const PatientPreferences: React.VFC = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const weeklyPatientLimitEnabled: boolean =
    (profile?.therapist_profile?.feature_flags?.includes("WEEKLY_PATIENT_ASSIGNMENT_LIMIT") &&
      !profile?.therapist_profile?.feature_flags?.includes("BOOKING_V2")) ||
    false;
  const newPatientNotificationEnabled: boolean =
    profile?.therapist_profile?.feature_flags?.includes("IGNORE_NEW_PATIENTS_EMAIL_TOGGLE") || false;

  return (
    <PatientPreferencesContainer data-testid="patient-preferences-container">
      <StyledHeader data-testid="patient-preferences-header">
        {t("therapist_settings.patient_preferences_header")}
      </StyledHeader>
      {newPatientNotificationEnabled && <NewPatientNotificationSwitch />}
      <AppointmentScheduledNotificationSwitch />
      {weeklyPatientLimitEnabled && <WeeklyPatientLimit />}
      <AvailableToSubstituteSwitch />
    </PatientPreferencesContainer>
  );
};

const StyledHeader = styled.h4`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;
const PatientPreferencesContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
  width: 100%;
`;
export default PatientPreferences;
