import { z } from "zod";

export const AppointmentSchema = z.object({
  id: z.number(),
  location: z.string().nullable(),
  medium: z.enum(["phone", "video"]),
  purpose: z.enum(["discharge_call", "follow_up", "general", "kick_off"]),
  start_time: z.string(),
  end_time: z.string(),
  state: z.string().nullable(),
  patient_id: z.number(),
  patient_first_name: z.string(),
  patient_last_name: z.string(),
  patient_preferred_name: z.string(),
  patient_number: z.string(),
  ics_url: z.string(),
});

export type Appointment = z.infer<typeof AppointmentSchema>;
