import { z } from "zod";

const TreatmentActivityLogCategorySchema = z.enum([
  "patient_profile_viewed",
  "message_sent",
  "comment_posted",
  "meeting_booked_with_patient",
  "treatment_created",
  "treatment_protocol_changed",
  "video_call_took_place",
  "phone_call_took_place",
]);
export type TreatmentActivityLogCategory = z.infer<typeof TreatmentActivityLogCategorySchema>;
const TreatmentActivityLogCategories = z.object({
  name: TreatmentActivityLogCategorySchema,
  description: z.string(),
});
export const TreatmentActivityLogCategoriesSchema = z.array(TreatmentActivityLogCategories);
export type TreatmentActivityLogCategories = z.infer<typeof TreatmentActivityLogCategoriesSchema>;

const TreatmentActivityLogPayloadSchema = z.object({
  category: TreatmentActivityLogCategorySchema,
  patient_id: z.number(),
  start_time: z.string(),
  end_time: z.string(),
});
export type TreatmentActivityLogPayload = z.infer<typeof TreatmentActivityLogPayloadSchema>;

const PostTreatmentActivityLogArgsSchema = z.object({
  data: TreatmentActivityLogPayloadSchema,
});
export type PostTreatmentActivityLogArgs = z.infer<typeof PostTreatmentActivityLogArgsSchema>;

export const PostTreatmentActivityLogResponseSchema = z.object({
  id: z.number(),
});
export type PostTreatmentActivityLogResponse = z.infer<typeof PostTreatmentActivityLogResponseSchema>;

const DurationUnitSchema = z.enum(["seconds", "minutes", "hours"]);

const LogEntrySchema = z.object({
  id: z.number(),
  therapist_id: z.number(),
  patient_id: z.number(),
  duration: z.number(),
  duration_unit: DurationUnitSchema,
  description: z.string(),
  completed_at: z.string().datetime({ offset: true }), // RFC 3339 format with UTC offset
  course_week: z.number(),
});

const LogsSchema = z.record(z.string().regex(/^\d{4}-\d{2}-\d{2}$/), z.array(LogEntrySchema));
export type Logs = z.infer<typeof LogsSchema>;

const TotalSchema = z.record(
  z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  z.object({
    duration: z.number(),
    duration_unit: DurationUnitSchema,
  })
);

export const TreatmentActivityLogsPatientSchema = z.object({
  logs: LogsSchema,
  total: TotalSchema,
});
export type TreatmentActivityLogsPatient = z.infer<typeof TreatmentActivityLogsPatientSchema>;

export const CareEventSuggestedSchema = z.object({
  patient_id: z.number(),
  patient_name: z.string(),
  start_time: z.string(),
  label: z.string(),
  cpt_codes: z.array(z.string()),
  rtm_care_event_log_id: z.number(),
});
export type CareEventSuggested = z.infer<typeof CareEventSuggestedSchema>;
