import type React from "react";

import type { SelectProps } from "@material-ui/core";

import MUIDropdown from "shared/atoms/inputs/MUIDropdown";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly onChange?: (event: Parameters<NonNullable<SelectProps["onChange"]>>[0]) => void;
}

const painOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

const RangeDropdown: React.VFC<Props> = ({ error, label, name, disabled, required, onChange }) => {
  return (
    <MUIDropdown
      error={error}
      label={label}
      name={name}
      options={painOptions}
      disabled={disabled}
      required={required}
      onChange={onChange}
    />
  );
};

export { RangeDropdown };
