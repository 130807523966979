import type { AxiosResponse } from "axios";
import type { UseMutationResult } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import { AxiosInstance } from "api";
import { QUERY_KEYS } from "types/QueryKeys";

interface ScheduleCallVars {
  body: {
    end_time: string;
    health_care_professional_id: number;
    location: string | null;
    medium: string;
    patient_id: number;
    purpose: string;
    start_time: string;
  };
}

interface ScheduleCall {
  end_time: string;
  ics_url: string;
  id: number;
  location: string | null;
  medium: string;
  patient_first_name: string;
  patient_id: number;
  patient_last_name: string;
  patient_number: string;
  purpose: string;
  start_time: string;
  state: string | null;
}

const postScheduleCall = ({ body }: ScheduleCallVars): Promise<AxiosResponse<ScheduleCall>> =>
  AxiosInstance.post("appointments", body);

const useScheduleCall = (): UseMutationResult<AxiosResponse<ScheduleCall>, unknown, ScheduleCallVars, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(({ body }: ScheduleCallVars) => postScheduleCall({ body }), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.patient.creatableCareEvents);
    },
  });
};

export default useScheduleCall;
