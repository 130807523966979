import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ConfirmationStatusError, UpcomingMaintenanceIcon } from "assets";
import useLocalizedDate from "utils/date";
import { useStatuspage, useStatuspageBankId, useUpcomingMaintenance } from "utils/statuspage/hooks";

export const StatuspageBar: React.VFC = () => {
  const { t } = useTranslation();
  const { hasIncidents, physioStatusErrorMessage, patientStatusErrorMessage, physioIncidentUrl, patientIncidentUrl } =
    useStatuspage();
  const { bankIdOnline, bankIdStatusErrorMessage } = useStatuspageBankId();
  const { upcomingMaintenance } = useUpcomingMaintenance();
  const { differenceInHours, format, parseISO } = useLocalizedDate();

  // NOTE: Only display banner 48 hours in advance
  const showUpcomingMaintenance =
    upcomingMaintenance && differenceInHours(new Date(upcomingMaintenance.scheduled_for), new Date()) <= 48;

  if (!hasIncidents && bankIdOnline && !upcomingMaintenance) {
    return <></>;
  }

  return (
    <StatusBarContainer>
      {(hasIncidents || !bankIdOnline) && (
        <StatusBar data-testid="error-status-bar">
          <StatusIcon>
            <ConfirmationStatusError />
          </StatusIcon>

          <StatusWrapper>
            {physioStatusErrorMessage && (
              <Status>
                <StatusErrorText data-testid="incident-message">
                  {physioStatusErrorMessage} {t("statuspage.more_info")}{" "}
                  <DetailsLink onClick={() => window.open(physioIncidentUrl, "_blank")} data-testid="incident-link">
                    {t("statuspage.link")}
                  </DetailsLink>
                </StatusErrorText>
              </Status>
            )}
            {patientStatusErrorMessage && (
              <Status>
                <StatusErrorText data-testid="incident-message">
                  {patientStatusErrorMessage} {t("statuspage.more_info")}{" "}
                  <DetailsLink onClick={() => window.open(patientIncidentUrl, "_blank")} data-testid="incident-link">
                    {t("statuspage.link")}
                  </DetailsLink>
                </StatusErrorText>
              </Status>
            )}
            {!bankIdOnline && (
              <Status>
                <StatusErrorText data-testid="bankid-message">{bankIdStatusErrorMessage}</StatusErrorText>
              </Status>
            )}
          </StatusWrapper>
        </StatusBar>
      )}

      {showUpcomingMaintenance && (
        <StatusBar data-testid="error-status-bar" mode="maintenance">
          <StatusIcon>
            <UpcomingMaintenanceIcon />
          </StatusIcon>

          <Status>
            <StatusErrorText data-testid="incident-message">
              {t("statuspage.upcoming_maintenance", {
                startDate: format(parseISO(upcomingMaintenance.scheduled_for), "do MMMM"),
                startTime: format(parseISO(upcomingMaintenance.scheduled_for), "p"),
                endTime: format(parseISO(upcomingMaintenance.scheduled_until), "p"),
              })}{" "}
              {t("statuspage.more_info")}{" "}
              <DetailsLink
                onClick={() => window.open(upcomingMaintenance.shortlink, "_blank")}
                data-testid="incident-link"
              >
                {t("statuspage.link")}
              </DetailsLink>
            </StatusErrorText>
          </Status>
        </StatusBar>
      )}
    </StatusBarContainer>
  );
};

const StatusBarContainer = styled.div`
  width: 100%;
`;

const StatusBar = styled.div<{ mode?: "maintenance" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  min-height: 64px;
  padding: 8px;

  background-color: ${({ mode, theme }) =>
    mode === "maintenance" ? theme.colors.redesign.b20 : theme.colors.primary.lightError};
  z-index: ${({ theme }) => theme.zIndex.header};

  ${props => props.theme.belowMobileBreakpoint} {
    padding: 0px;
  }
`;

const StatusIcon = styled.div`
  min-width: 24px;
  max-width: 32px;
  margin-left: 32px;

  ${props => props.theme.belowMobileBreakpoint} {
    min-width: 32px;
    margin-left: 12px;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
`;

const StatusErrorText = styled.p`
  ${props => props.theme.font.link2};
  color: ${props => props.theme.colors.primary.base};
  margin: 0;
  margin-left: 24px;

  ${props => props.theme.belowMobileBreakpoint} {
    ${props => props.theme.font.caption};
    font-weight: ${props => props.theme.fontWeight.medium};
    margin-left: 12px;
    max-width: 16rem;
  }
`;

const DetailsLink = styled.span`
  ${props => props.theme.font.link2};
  color: ${props => props.theme.colors.redesign.b100};
  cursor: pointer;

  ${props => props.theme.belowMobileBreakpoint} {
    ${props => props.theme.font.caption};
    font-weight: ${props => props.theme.fontWeight.medium};
  }
`;
