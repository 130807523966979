import { areIntervalsOverlapping, parseISO } from "date-fns";
import i18n from "i18next";

import type { BookingIntervalResponse } from "api/schemas/BookingInterval";
import type { NewAppointment } from "api/schemas/NewAppointment";

import { formDateToJSDates } from "../helpers";

interface IsDoubleBookingArgs {
  startDate: Date;
  endDate: Date;
  appointments: NewAppointment[];
  personnelOptions: { value: number; label: string }[];
  selectedAppointment: NewAppointment | null | undefined;
  selectedPersonnel: number;
  userID: number;
}

function isDoubleBooking({
  startDate,
  endDate,
  appointments,
  personnelOptions,
  selectedAppointment,
  selectedPersonnel,
  userID,
}: IsDoubleBookingArgs) {
  const filteredAppointments = appointments.filter(
    appointment =>
      selectedPersonnel === appointment.health_care_professional_id &&
      appointment.state !== "canceled" &&
      appointment.state !== "rescheduled" &&
      appointment.id !== selectedAppointment?.id
  );

  const booked = filteredAppointments.some(el => {
    return areIntervalsOverlapping(
      { start: startDate, end: endDate },
      { start: parseISO(el.start_time), end: parseISO(el.end_time) }
    );
  });

  if (booked) {
    return i18n.t("booking.form.conflict_warnings.double_booked", {
      personnel:
        selectedPersonnel === userID
          ? i18n.t("common.you")
          : personnelOptions.find(p => p.value === selectedPersonnel)?.label,
    });
  }

  return "";
}

interface IsUnavailableArgs {
  startDate: Date;
  endDate: Date;
  bookingIntervals: BookingIntervalResponse[];
  personnelOptions: { value: number; label: string }[];
  selectedPersonnel: number;
  userID: number;
}

function isUnavailable({
  startDate,
  endDate,
  bookingIntervals,
  personnelOptions,
  selectedPersonnel,
  userID,
}: IsUnavailableArgs) {
  const filteredBookingIntervals = bookingIntervals.filter(
    bookingInterval =>
      selectedPersonnel === bookingInterval.health_care_professional.id &&
      bookingInterval.availability === "unavailable"
  );

  const unavailable = filteredBookingIntervals.some(el => {
    return areIntervalsOverlapping(
      { start: startDate, end: endDate },
      { start: parseISO(el.start_time), end: parseISO(el.end_time) }
    );
  });

  if (unavailable) {
    return i18n.t("booking.form.conflict_warnings.unavailable", {
      personnel:
        selectedPersonnel === userID
          ? i18n.t("common.you")
          : personnelOptions.find(p => p.value === selectedPersonnel)?.label,
    });
  }

  return "";
}

function isOutsideBookingInterval({
  startDate,
  endDate,
  bookingIntervals,
  personnelOptions,
  selectedPersonnel,
  userID,
}: IsUnavailableArgs) {
  const filteredBookingIntervals = bookingIntervals.filter(
    bookingInterval => selectedPersonnel === bookingInterval.health_care_professional.id
  );

  const unavailable = filteredBookingIntervals.some(el => {
    return areIntervalsOverlapping(
      { start: startDate, end: endDate },
      { start: parseISO(el.start_time), end: parseISO(el.end_time) }
    );
  });

  if (!unavailable) {
    return i18n.t("booking.form.conflict_warnings.outside_availability", {
      personnel:
        selectedPersonnel === userID
          ? i18n.t("common.your")
          : personnelOptions.find(p => p.value === selectedPersonnel)?.label,
    });
  }

  return "";
}

interface HasConflictArgs {
  date: string;
  startTime: number;
  endTime: number;
  appointments: NewAppointment[];
  bookingIntervals: BookingIntervalResponse[];
  personnelOptions: { value: number; label: string }[];
  selectedAppointment: NewAppointment | null | undefined;
  selectedPersonnel: number;
  userID: number;
}

export function hasConflict({
  date,
  startTime,
  endTime,
  appointments,
  bookingIntervals,
  personnelOptions,
  selectedAppointment,
  selectedPersonnel,
  userID,
}: HasConflictArgs) {
  const { startDate, endDate } = formDateToJSDates({ date, startTime, endTime });

  // 1. Check if selected personnel is already booked
  const booked = isDoubleBooking({
    startDate,
    endDate,
    appointments,
    personnelOptions,
    selectedAppointment,
    selectedPersonnel,
    userID,
  });
  if (booked) {
    return booked;
  }

  // 2. Check if selected personnel is unavailable
  const unavailable = isUnavailable({
    startDate,
    endDate,
    bookingIntervals,
    personnelOptions,
    selectedPersonnel,
    userID,
  });
  if (unavailable) {
    return unavailable;
  }

  // 3. Check if appointment falls outside of booking interval
  const outsideBookingInterval = isOutsideBookingInterval({
    startDate,
    endDate,
    bookingIntervals,
    personnelOptions,
    selectedPersonnel,
    userID,
  });
  if (outsideBookingInterval) {
    return outsideBookingInterval;
  }

  return "";
}
