import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { AvailableAilments, AvailableAilmentsArgs } from "api/schemas/AvailableAilments";
import useApiClient from "api/useApiClient";

type QueryKey = ["getPatientAvailableAilments", number, number];
export const getPatientAvailableAilmentsQueryKey = (args: AvailableAilmentsArgs): QueryKey => [
  "getPatientAvailableAilments",
  args.therapistID,
  args.patientID,
];

const useGetPatientAvailableAilments = (
  args: AvailableAilmentsArgs,
  options?: UseQueryOptions<AvailableAilments, Error, AvailableAilments, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getPatientAvailableAilmentsQueryKey(args), () => client.getPatientAvailableAilments(args), options);
};

export default useGetPatientAvailableAilments;
