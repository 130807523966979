import { useContext } from "react";

import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import invariant from "ts-invariant";

import type { CareEvent } from "api/models/CareEvent";
import type { GetPatientCareEventsArgs, GetPatientCareEventsResponse } from "api/types/GetPatientCareEvents";
import useApiClient from "api/useApiClient";
import { applyChanges } from "routes/patients/PatientProfile/components/DynamicMedicalNotes/helpers/careEventHelpers";
import type { ICDCodeResponse } from "types";
import type { CareEventSubset } from "types/MedicalNoteConfig";
import { CurrentPatientContext } from "utils/contexts";

export type UseGetPatientCareEventsQueryArgs = GetPatientCareEventsArgs;

type QueryKey = ["useGetPatientsCareEventsQuery", number, boolean?];

export const getPatientCareEventsQueryKey = ({ patientId, signed }: UseGetPatientCareEventsQueryArgs): QueryKey =>
  signed === undefined
    ? ["useGetPatientsCareEventsQuery", patientId]
    : ["useGetPatientsCareEventsQuery", patientId, signed];

export const useGetPatientCareEventsQuery = (
  args: UseGetPatientCareEventsQueryArgs,
  options?: UseQueryOptions<GetPatientCareEventsResponse, Error, GetPatientCareEventsResponse, QueryKey>
): UseQueryResult<GetPatientCareEventsResponse, Error> => {
  const client = useApiClient();
  const { availableICDCodes } = useContext<{ availableICDCodes: ICDCodeResponse[] }>(CurrentPatientContext);

  const isCareEventCodeAvailable = (icdCode: string) =>
    !availableICDCodes.length || availableICDCodes.some(({ code }) => code === icdCode);

  return useQuery(
    getPatientCareEventsQueryKey(args),
    async () => {
      const data = await client.getPatientCareEvents(args);

      // Remove unavailable ICD codes
      return data?.map(careEvent => {
        if (!careEvent.icd_code || isCareEventCodeAvailable(careEvent.icd_code)) return careEvent;
        return { ...careEvent, icd_code: null, icd_codes: [] };
      });
    },
    options
  );
};

export const mergePatientCareEvent =
  (id: number, changes: CareEventSubset) =>
  (oldData?: GetPatientCareEventsResponse): GetPatientCareEventsResponse => {
    invariant(oldData, "Care events are undefined");
    const em = oldData.find(ce => ce.id === id);
    invariant(em, `Could not find care event with id ${id}`);
    const index = oldData.indexOf(em);
    const newData = [...oldData];
    newData[index] = applyChanges(em, changes);
    return newData;
  };

export const removePatientCareEvent =
  (id: number) =>
  (oldData?: GetPatientCareEventsResponse): GetPatientCareEventsResponse => {
    invariant(oldData, "Care events are undefined");
    const em = oldData.find(ce => ce.id === id);
    invariant(em, `Could not find care event with id ${id}`);
    const index = oldData.indexOf(em);
    const newData = [...oldData];
    newData.splice(index, 1);
    return newData;
  };

export const createPatientCareEvent =
  (careEvent: CareEvent) =>
  (oldData?: GetPatientCareEventsResponse): GetPatientCareEventsResponse => {
    invariant(oldData, "Care events are undefined");
    return [...oldData, careEvent];
  };
