import styled from "styled-components";

import VideoCallAwareContainer from "shared/atoms/VideoCallAwareContainer/VideoCallAwareContainer";

interface Props {
  $pageContentOffsetTop: number;
  readonly maxWidth?: number;
}

const Container = styled(VideoCallAwareContainer)<Props>`
  width: 100%;
  display: block;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: ${props => props.$pageContentOffsetTop}px;
`;

export default Container;
