import type React from "react";

import styled from "styled-components";

import type { MedicalNoteAddendum } from "api/models/MedicalNoteAddendum";
import useLocalizedDate from "utils/date";

interface Props {
  medical_note_addenda?: MedicalNoteAddendum[];
}

export const AddendumList: React.VFC<Props> = ({ medical_note_addenda }) => {
  const { format, parseISO } = useLocalizedDate();

  return (
    <List>
      {medical_note_addenda?.map(addendum => {
        return (
          <ListItem key={addendum.id}>
            <DateAndAuthor title={format(parseISO(addendum.created_at), "PP p")}>{`${format(
              parseISO(addendum.created_at),
              "d MMMM, yyyy"
            )} - ${addendum.health_care_professional_name}`}</DateAndAuthor>
            <AddendumText>{addendum.body}</AddendumText>
          </ListItem>
        );
      })}
    </List>
  );
};

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.greys.dark};
`;

const DateAndAuthor = styled.div`
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const AddendumText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
`;
