import "emoji-mart/css/emoji-mart.css";
import type React from "react";
import { useState } from "react";

import styled from "styled-components";
import invariant from "ts-invariant";

import useGetQuickReplies from "api/hooks/useGetQuickReplies";
import type { QuickReply } from "api/schemas/QuickReply";
import { QuickReplyIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import QuickRepliesOverlay from "./QuickRepliesOverlay";

interface Props {
  readonly onQuickReplySelected: (quickReply: string) => void;
}

const QuickReplies: React.VFC<Props> = ({ onQuickReplySelected }) => {
  const { profile } = useProfileContext();
  const [popupOpen, setPopupOpen] = useState(false);

  invariant(profile);
  const quickRepliesQuery = useGetQuickReplies(profile.id);
  const quickRepliesShortcutsCount = 3;
  const quickRepliesShortcuts = quickRepliesQuery?.data?.slice(-quickRepliesShortcutsCount);

  return (
    <Container>
      <QuickReplyButton
        data-testid="quick-replies-form-toggle"
        onClick={event => {
          event.stopPropagation();
          setPopupOpen(prevState => !prevState);
        }}
      >
        <QuickReplyIcon />
      </QuickReplyButton>

      <QuickRepliesOverlay
        close={() => setPopupOpen(false)}
        visible={popupOpen}
        onQuickReplySelected={(quickReply: string) => {
          onQuickReplySelected(quickReply);
          setPopupOpen(false);
        }}
      />

      <QuickRepliesContainer>
        <QuickTextRepliesContainer data-testid="quick-replies-container">
          {quickRepliesShortcuts?.map((quickReply: QuickReply, index) => (
            <QuickTextReply
              key={quickReply.id}
              data-testid={`shortcut-quick-reply-${index}`}
              onClick={() => {
                onQuickReplySelected(quickReply.text);
                AnalyticsService.track(AnalyticsEvents.QUICK_REPLIES.USED_SHORTCUT_QUICK_REPLY);
              }}
            >
              {quickReply.text}
            </QuickTextReply>
          ))}
        </QuickTextRepliesContainer>
      </QuickRepliesContainer>

      {quickRepliesQuery?.data?.length && quickRepliesQuery.data.length > quickRepliesShortcutsCount ? (
        <QuickRepliesCounter onClick={() => setPopupOpen(true)}>
          +{quickRepliesQuery.data.length - quickRepliesShortcutsCount}
        </QuickRepliesCounter>
      ) : null}
    </Container>
  );
};

export default QuickReplies;

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  margin-left: ${props => props.theme.spacing.S_5};
`;

const QuickTextRepliesContainer = styled.div`
  display: flex;
  ${props => props.theme.belowMobileBreakpoint} {
    display: none;
  }
  margin: 0 ${props => props.theme.spacing.S_5};
`;

const QuickTextReply = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.colors.redesign.b10};
  color: ${props => props.theme.colors.redesign.b100};
  height: 32px;
  border-radius: 24px;
  ${props => ({ ...props.theme.font.link2 })};
  box-sizing: border-box;
  padding: 3px 12px;
  margin: 0 ${props => props.theme.spacing.S_5};
  max-width: 115px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const QuickRepliesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuickRepliesCounter = styled.div`
  ${props => props.theme.belowMobileBreakpoint} {
    display: none;
  }
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.greys.light2};
  color: ${props => props.theme.colors.greys.gunmetal};
  cursor: pointer;
`;

const QuickReplyButton = styled.div`
  display: flex;
  cursor: pointer;
`;
