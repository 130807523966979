import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetAuthenticatedUserResponse } from "api/types/GetAuthenticatedUser";
import useApiClient from "api/useApiClient";

export const getAuthenticatedUserQueryKey = (): ["GetAuthenticatedUserQuery"] => ["GetAuthenticatedUserQuery"];

export const useGetAuthenticatedUserQuery = (
  options?: UseQueryOptions<GetAuthenticatedUserResponse, Error>
): UseQueryResult<GetAuthenticatedUserResponse, Error> => {
  const client = useApiClient();

  return useQuery<GetAuthenticatedUserResponse, Error>(
    getAuthenticatedUserQueryKey(),
    () => client.getAuthenticatedUser(),
    options
  );
};
