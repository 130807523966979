import type React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";

interface Props {
  patientId: number;
}

const UserCourseNotificationBox: React.VFC<Props> = ({ patientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <TextWrapper $inverted>{t("usercourse.missing_usercourse")}</TextWrapper>
      <ButtonsWrapper>
        <StyledPrimaryButton
          onClick={event => {
            event.stopPropagation();
            navigate(`/patients/${patientId}/usercourse`);
          }}
        >
          {t("usercourse.create_course_short")}
        </StyledPrimaryButton>
      </ButtonsWrapper>
    </Container>
  );
};

export default UserCourseNotificationBox;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.exercise.background};
  border: 1px solid ${({ theme }) => theme.colors.exercise.accent};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: 16px ${props => props.theme.spacing.S_10} 16px 24px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
  }
`;

const ButtonsWrapper = styled.div`
  margin-left: 12px;
  text-align: center;
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    margin: auto;
    flex-direction: column;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 260px;
  margin-right: 12px;
  ${props => props.theme.belowBreakpoint} {
    margin: 12px auto;
  }
`;

const TextWrapper = styled.div<{ $inverted?: boolean }>`
  margin: auto 0;
  span {
    color: ${props => props.theme.colors.greys.dark};
    ${props => props.theme.font.body1};
    &:first-child {
      font-weight: ${({ $inverted, theme }) => ($inverted ? theme.fontWeight.medium : theme.fontWeight.light)};
    }
    &:nth-child(2) {
      font-weight: ${({ $inverted, theme }) => ($inverted ? theme.fontWeight.light : theme.fontWeight.medium)};
      color: ${props => props.theme.colors.primary.base};
      margin-left: ${props => props.theme.spacing.S_5};
    }
  }
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    padding-bottom: ${props => props.theme.spacing.S_10};
    ${props => props.theme.font.link3};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
`;
