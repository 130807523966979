import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { HealthReport } from "api/models/HealthReport";
import Hidden from "shared/atoms/Hidden";

import type { PainLocation, Question } from "../../helpers/helpers";
import { getChildAnswer } from "../../helpers/helpers";
import { Column, TextWrapper } from "../helpers";
import renderAnswer from "../utils/renderAnswer";

const AnswerWrapper = styled.div<{ changed?: boolean; empty?: boolean; selectedNum?: number }>`
  min-height: 50px;
  display: flex;
  flex: 1 1;
  background: ${props => (props.changed ? props.theme.colors.brightOrange : props.theme.colors.white)};
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  ${props =>
    props.empty &&
    `
      ${props.selectedNum && `min-width: ${props.selectedNum * 300}px;`}
      ${props.theme.belowBreakpoint} {
        height: 0px;
        min-height: 0px;
      }
  `}
`;

const Answer = styled.span`
  min-width: 300px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_15};
  font-weight: ${props => props.theme.fontWeight.regular};
  ${props => props.theme.belowBreakpoint} {
    padding: 0;
  }
  display: flex;
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
`;

const Abbreviation = styled(Column)`
  height: 100%;
  min-height: 50px;
  max-width: 55px;
  min-width: 10px;
  border: none;
  flex-shrink: 1;
  ${props => props.theme.belowBreakpoint} {
    padding: ${props => props.theme.spacing.S_15};
    margin-right: ${props => props.theme.spacing.S_15};
  }
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  background: ${props => props.theme.colors.white};
`;

interface ReportQuestionAnswerProps {
  changed: boolean;
  painLocation: PainLocation;
  parent?: Question;
  question: Question;
  selected: HealthReport[];
}

const ReportQuestionAnswer: React.VFC<ReportQuestionAnswerProps> = ({
  changed,
  painLocation,
  parent,
  question,
  selected,
}) => {
  const { t } = useTranslation();
  const isSub = Boolean(parent);

  return (
    <>
      {question.type !== "header" ? (
        selected.map(({ answers, health_report_number: healthReportNumber, week_number: weekNumber }) => {
          const childAnswer = getChildAnswer(parent && answers[parent.key], question.key);
          const answered = Boolean(parent && answers[parent.key] !== undefined && childAnswer !== undefined);

          return (
            <AnswerWrapper key={healthReportNumber} changed={changed}>
              <Answer>
                <Hidden type="aboveTablet">
                  <Abbreviation>
                    {t("patients.health_reports.question_short", { number: healthReportNumber })}
                  </Abbreviation>
                </Hidden>
                <TextWrapper notAsked={isSub ? !answered : answers[question.key] === undefined}>
                  {!isSub ? (
                    <>
                      {answers[question.key] !== undefined
                        ? renderAnswer(
                            question.type,
                            question.key,
                            answers[question.key],
                            question.params || {},
                            weekNumber,
                            painLocation,
                            t
                          )
                        : t("patients.health_reports.questions_content.not_asked")}
                    </>
                  ) : (
                    <>
                      {answered
                        ? renderAnswer(
                            question.type,
                            question.key,
                            childAnswer,
                            question.params || {},
                            weekNumber,
                            painLocation,
                            t
                          )
                        : t("patients.health_reports.questions_content.not_asked")}
                    </>
                  )}
                </TextWrapper>
              </Answer>
            </AnswerWrapper>
          );
        })
      ) : (
        <AnswerWrapper empty selectedNum={selected.length} data-testid="hr-header" />
      )}
    </>
  );
};

export default ReportQuestionAnswer;
