import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetPartnerUnclaimedReferrals = () => {
  const client = useApiClient();
  return useQuery("GetPartnerUnclaimedReferrals", () => client.getPartnerUnclaimedReferrals());
};

export default useGetPartnerUnclaimedReferrals;
