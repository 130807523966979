import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: table;
  table-layout: fixed;

  ${props => props.theme.belowBreakpoint} {
    overflow: hidden;
  }
`;

const StateContainer = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  margin: ${props => props.theme.spacing.S_20} 0;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  ${props => props.theme.font.link1}
  color: ${props => props.theme.colors.primary.base};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${props => props.theme.colors.greys.light4};
  position: sticky;
  top: 0px;
  z-index: 2;
  height: 80px;
  ${props => props.theme.font.link1}
  color: ${props => props.theme.colors.primary.base};
  box-shadow: 0 3px 4px ${props => props.theme.colors.greys.pinkish};
`;

const Column = styled.div`
  background: ${props => props.theme.colors.greys.light4};
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  min-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.spacing.S_15};
`;

const ColumnHeader = styled(Column)`
  flex: 1 1;
  height: 100%;
`;

const InfoColumn = styled(Column)`
  min-width: 400px;
  width: 400px;
  position: sticky;
  left: 0;
`;

const StyledColumn = styled(InfoColumn)`
  height: 80px;
`;

const Wrapper = styled.div`
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const MarginWrapper = styled(Wrapper)`
  margin: ${props => props.theme.spacing.S_5} 0;
`;

const ContentRow = styled.div`
  display: flex;
  width: 100%;
  color: ${props => props.theme.colors.primary.base};
  background: ${props => props.theme.colors.white};
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
  align-items: stretch;
`;

const TextWrapper = styled.div<{ notAsked: boolean }>`
  white-space: pre-wrap;
  ${props => props.notAsked && `color: ${props.theme.colors.greys.warm};`}
`;

export {
  Header,
  Container,
  StateContainer,
  InfoColumn,
  ColumnHeader,
  Column,
  StyledColumn,
  MarginWrapper,
  Wrapper,
  ContentRow,
  TextWrapper,
};
