import type React from "react";
import type { SetStateAction } from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { BackIcon } from "assets";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { useVoiceCallContext } from "contexts/VoiceCallContext";
import Avatar from "shared/atoms/Avatar";
import Hidden from "shared/atoms/Hidden";
import IconButton from "shared/atoms/IconButton";
import MeatballMenu from "shared/atoms/MeatballMenu";
import { Notification } from "shared/atoms/Notification";
import PremiumIcon from "shared/atoms/PremiumIcon";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";

import {
  AnimatedDropdownIcon,
  AvatarContainer,
  BackButton,
  CallOngoingContainer,
  Container,
  GoBackText,
  MobileContainer,
  MobileContent,
  MobileName,
  Priority,
  isPatientVisible,
} from "./helpers";
import PatientDataList from "./PatientDataList";
import PatientHeaderBanner from "./PatientHeaderBanner";
import PatientHeaderButtonRow from "./PatientHeaderButtonRow";
import PatientHeaderDropdownInfo from "./PatientHeaderDropdownInfo";
import PatientHeaderDropdownMenu from "./PatientHeaderDropdownMenu";
import PatientStickyNote from "./PatientStickyNote/PatientStickyNote";

interface Props {
  setShowEndTreatmentForm?: React.Dispatch<SetStateAction<boolean>> | undefined;
  showEndTreatmentForm?: boolean | undefined;
  offsetTop?: number;
}

const PatientHeaderInfo: React.VFC<Props> = ({ setShowEndTreatmentForm, showEndTreatmentForm, offsetTop = 0 }) => {
  const { patient, patientError, previousPage } = useContext(CurrentPatientContext);
  const { hasFeature } = useFeatureToggleContext();
  const { callData } = useVideoCallContext();
  const { voiceCallData } = useVoiceCallContext();
  const { t } = useTranslation();
  const [dropdownState, setDropdownState] = useState({ menuOpen: false, infoOpen: false });
  const ongoingVideoCall = callData ? callData.callee.id === patient.id : false;
  const ongoingVoiceCall = voiceCallData ? voiceCallData?.callee.id === patient.id : false;
  const ongoingCall = ongoingVideoCall || ongoingVoiceCall;

  return (
    <>
      <Hidden type="aboveTablet">
        <MobileContainer data-testid="patient-header-info-mobile" $offsetTop={offsetTop}>
          <MobileContent>
            <BackButton to={previousPage?.pathname}>
              <BackIcon />
            </BackButton>
            {patientError ? (
              <Notification variant="danger" style={{ margin: "10px" }}>
                {t("errors.generic")}
              </Notification>
            ) : (
              <>
                <AvatarContainer>
                  <Avatar
                    size={30}
                    fontSize={15}
                    id={patient.id}
                    name={`${patient.first_name} ${patient.last_name}`}
                    src={patient.avatars?.medium}
                  />
                  <Priority $hidden={!patient.prio} />
                </AvatarContainer>
                <PremiumIcon premiumType={patient.premium_type} size="small" />
                <MobileName>{`${patient.first_name} ${patient.last_name}`}</MobileName>

                <IconButton
                  label=""
                  onClick={() => setDropdownState({ menuOpen: false, infoOpen: !dropdownState.infoOpen })}
                >
                  <AnimatedDropdownIcon open={dropdownState.infoOpen} data-testid="open-info" />
                </IconButton>
              </>
            )}
          </MobileContent>
          {!patientError && (
            <>
              {isPatientVisible(patient) && (
                <MeatballMenu
                  open={dropdownState.menuOpen}
                  toggleMenu={() => setDropdownState({ menuOpen: !dropdownState.menuOpen, infoOpen: false })}
                />
              )}
              <PatientHeaderDropdownMenu
                isOpen={dropdownState.menuOpen}
                onClose={() => setDropdownState({ menuOpen: false, infoOpen: false })}
                showEndTreatmentForm={showEndTreatmentForm}
                setShowEndTreatmentForm={setShowEndTreatmentForm}
              />
              <PatientHeaderDropdownInfo
                isOpen={dropdownState.infoOpen}
                onClose={() => setDropdownState({ menuOpen: false, infoOpen: false })}
                patientError={patientError}
              />
            </>
          )}
        </MobileContainer>
      </Hidden>
      <Hidden type="belowTablet">
        <div>
          <BackButton to={previousPage?.pathname}>
            <BackIcon />
            <GoBackText>
              {t("patients.go_back_button", {
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                destination: t(`views.${previousPage?.text ? previousPage?.text : "dashboard"}`).toLowerCase(),
              })}
            </GoBackText>
          </BackButton>
        </div>

        <Container data-testid="patient-header-info-desktop">
          <PatientHeaderBanner />
          {patientError ? (
            <Notification variant="danger" style={{ margin: "10px" }}>
              {t("errors.generic")}
            </Notification>
          ) : (
            <>
              {ongoingCall && <CallOngoingContainer>{t("videocall.ongoing")}</CallOngoingContainer>}
              <PatientDataList />
              {hasFeature(Feature.PATIENT_STICKY_NOTE) && <PatientStickyNote />}
              {isPatientVisible(patient) && (
                <PatientHeaderButtonRow
                  toggleEndTreatment={() => {
                    if (setShowEndTreatmentForm) {
                      setShowEndTreatmentForm(!showEndTreatmentForm);
                    }
                  }}
                />
              )}
            </>
          )}
        </Container>
      </Hidden>
    </>
  );
};

export default PatientHeaderInfo;
