import type React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePinnedMessagesContext } from "contexts/PinnedMessagesContext";
import { Container, DateTime, Message } from "routes/messages/styles/bubble";
import RelativeDatetime from "shared/atoms/RelativeDatetime";
import MarkAsUnread from "shared/molecules/MarkAsUnread";

interface Props {
  id: number;
  sender?: boolean;
  children?: React.ReactNode;
  message: string;
  date: string;
  read: string | null;
  selectedPatient: number;
}

const ChatBubble: React.VFC<Props> = ({ id, sender = false, message, date, children, read, selectedPatient }) => {
  const { t } = useTranslation();
  const { messageIsPinned, unpinMessage } = usePinnedMessagesContext();

  useEffect(() => {
    return () => {
      if (messageIsPinned(id)) {
        unpinMessage(id);
      }
    };
  }, []);

  return (
    <Container>
      {messageIsPinned(id) && (
        <NewMessageIndicator>
          <span className="line" />
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <span className="text">{t("common.new.1")}</span>
        </NewMessageIndicator>
      )}

      <Bubble $sender={sender} data-testid="chat-bubble">
        <Wrapper $sender={sender}>
          {children}
          <Message $sender={sender}>{message}</Message>
          <DateTime $sender={sender}>
            <RelativeDatetime capitalize dateString={date} />
          </DateTime>
          {!sender && (
            <ReadWrapper>
              <MarkAsUnread message={{ body: message, created_at: date, id, peerId: selectedPatient }} />
            </ReadWrapper>
          )}
        </Wrapper>
      </Bubble>
      {read && (
        <ReadLabel $sender={sender} data-testid="read-label">
          {t("messages.read")} <RelativeDatetime dateString={read} />
        </ReadLabel>
      )}
    </Container>
  );
};

export default ChatBubble;

const Bubble = styled.div<{ $sender: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: ${props => (props?.$sender ? "flex-end" : "flex-start")};
  padding-left: ${props => props?.$sender && "115px"};
  padding-right: ${props => !props?.$sender && "115px"};

  ${props => props.theme.belowBreakpoint} {
    padding-left: ${props => props?.$sender && "50px"};
    padding-right: ${props => !props?.$sender && "50px"};
  }
`;

const Wrapper = styled.div<{ $sender: boolean }>`
  display: inline-block;
  padding: ${({ $sender }) => ($sender ? "16px" : "16px 52px 16px 16px;")};
  border-radius: ${({ $sender }) => ($sender ? "24px 24px 8px 24px" : "8px 24px 24px 24px")};
  background-color: ${({ theme: { colors }, $sender }) => ($sender ? colors.redesign.b100 : colors.white)};
  position: relative;
  border: ${({ theme: { colors }, $sender }) => !$sender && `1px solid ${colors.greys.silver}`};
`;

const ReadLabel = styled.div<{ $sender: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: ${props => (props?.$sender ? "flex-end" : "flex-start")};
  ${props => props.theme.font.caption}
  color: ${props => props.theme.colors.greys.dark};
`;

const NewMessageIndicator = styled.div`
  display: flex;
  align-items: center;

  .line {
    height: 2px;
    width: 100%;
    background-color: ${props => props.theme.colors.painful_blue};
  }

  .text {
    color: ${props => props.theme.colors.painful_blue};
    font-size: 18px;
    font-weight: 500;
    margin-left: 16px;
    text-transform: uppercase;
  }
`;

const ReadWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
`;
