import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Notification } from "shared/atoms/Notification";
import { CurrentPatientContext } from "utils/contexts";

import { isPatientVisible } from "./helpers";

const PatientHeaderBanner = () => {
  const { patient } = useContext(CurrentPatientContext);
  const { t } = useTranslation();

  return (
    <>
      {patient.deleted_at && (
        <Notification variant="danger" style={{ margin: "10px" }}>
          {t("errors.account_closed")}
        </Notification>
      )}
      {!patient.deleted_at && !isPatientVisible(patient) && (
        <Notification variant="info" style={{ margin: "10px" }}>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(`errors.account_${patient.state}`)}
        </Notification>
      )}
    </>
  );
};

export default PatientHeaderBanner;
