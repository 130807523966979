import type React from "react";

import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import InvitePatientForm from "../components/InvitePatientForm";

export const InviteView: React.VFC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PageContainer>
      <Container>
        <Header>
          <FontAwesomeIcon
            icon={faUserPlus}
            color={theme.colors.primary.base}
            style={{ height: "24px", width: "24px", marginRight: "8px" }}
          />
          {t("invite.header")}
        </Header>
        <Text>{t("invite.body")}</Text>
        <InvitePatientForm />
      </Container>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 720px;
  margin: 68px auto 0;
  padding: 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
  position: relative;

  ${props => props.theme.belowBreakpoint} {
    margin: 0;
  }
`;

const Header = styled.header`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.base};
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.base};
  margin-bottom: 22px;
`;
