import type { Availability, HealthCareService } from "api/schemas/BookingInterval";
import type { NewAppointment } from "api/schemas/NewAppointment";
import theme from "theme";

import { isAppointmentDisabled } from "./transformAppointments";

export function getAppointmentBgColor(appointment: NewAppointment) {
  if (isAppointmentDisabled(appointment.state)) return theme.components.calendar.appointments.canceled;

  if (appointment.health_care_provider_service_name === "Telefonrådgivning")
    return theme.components.calendar.appointments.phone;

  if (appointment.health_care_provider_service_name?.includes("Grupp"))
    return theme.components.calendar.appointments.group;

  switch (appointment.health_care_provider_service_mode) {
    case "digital":
      switch (appointment.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.digital_kick_off;
        case "follow_up":
          return theme.components.calendar.appointments.digital_follow_up;
        default:
          return theme.components.calendar.appointments.default;
      }
    case "physical":
      switch (appointment.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.physical_kick_off;
        case "follow_up":
          return theme.components.calendar.appointments.physical_follow_up;
        default:
          return theme.components.calendar.appointments.default;
      }
    default:
      return theme.components.calendar.appointments.default;
  }
}

export function getAppointmentBorderColor(appointment: NewAppointment) {
  if (isAppointmentDisabled(appointment.state)) return theme.components.calendar.appointments.canceled_border;

  if (appointment.health_care_provider_service_name === "Telefonrådgivning")
    return theme.components.calendar.appointments.phone_border;

  if (appointment.health_care_provider_service_name?.includes("Grupp"))
    return theme.components.calendar.appointments.group_border;

  switch (appointment.health_care_provider_service_mode) {
    case "digital":
      switch (appointment.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.digital_kick_off_border;
        case "follow_up":
          return theme.components.calendar.appointments.digital_follow_up_border;
        default:
          return theme.components.calendar.appointments.default_border;
      }
    case "physical":
      switch (appointment.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.physical_kick_off_border;
        case "follow_up":
          return theme.components.calendar.appointments.physical_follow_up_border;
        default:
          return theme.components.calendar.appointments.default_border;
      }
    default:
      return theme.components.calendar.appointments.default_border;
  }
}

export function getServiceAvailabilityColor(availability: Availability, service: HealthCareService) {
  if (availability === "unavailable") {
    return theme.components.calendar.appointments.blocked_border;
  }

  if (!service) {
    return theme.components.calendar.appointments.default_border;
  }

  if (service.name === "Telefonrådgivning") {
    return theme.components.calendar.appointments.phone_border;
  }

  switch (service.mode) {
    case "digital":
      switch (service.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.digital_kick_off_border;
        case "follow_up":
          return theme.components.calendar.appointments.digital_follow_up_border;
        default:
          return theme.components.calendar.appointments.default_border;
      }
    case "physical":
      switch (service.purpose) {
        case "kick_off":
          return theme.components.calendar.appointments.physical_kick_off_border;
        case "follow_up":
          return theme.components.calendar.appointments.physical_follow_up_border;
        default:
          return theme.components.calendar.appointments.default_border;
      }
    default:
      return theme.components.calendar.appointments.default_border;
  }
}
