import type { ReactNode } from "react";
import type React from "react";

import { FormControl, TextField } from "@material-ui/core";
import type { AutocompleteGetTagProps, FilterOptionsState } from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import type { Validate } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { MUIBorder, MUIErrorText, MUIInput, MUITextInputLabel } from "../StyledInputs";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly noOptionsText?: string;
  readonly name: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly disableCloseOnSelect?: boolean;
  readonly required?: boolean;
  readonly options: string[];
  readonly placeholder?: string;
  readonly searchValue?: string;
  readonly setSearchValue?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  readonly filterOptions?: (options: string[], state: FilterOptionsState<string>) => string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: React.ChangeEvent<{}>, value: string[]) => void;
  readonly getOptionLabel?: (option: string) => string;
  readonly getOptionSelected?: (option: string, value: string) => boolean;
  readonly renderOption?: (option: string) => ReactNode;
  readonly renderTag?: (value: string[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly validate?: Validate<any> | Record<string, Validate<any>> | undefined;
}

const MUIAutocomplete: React.VFC<Props> = ({
  className,
  disabled,
  disableCloseOnSelect,
  error,
  label,
  name,
  noOptionsText,
  options,
  required,
  placeholder,
  searchValue,
  validate,
  filterOptions,
  onChange,
  renderOption,
  renderTag,
  setSearchValue,
}) => {
  const { control } = useFormContext();
  const sanitizeValue = (valueArray: string[] | undefined) => {
    return Array.isArray(valueArray) ? valueArray.filter((value: string) => options.includes(value)) : [];
  };

  return (
    <Container className={className}>
      <Controller
        control={control}
        name={name}
        rules={{ required, validate }}
        render={({ field }) => {
          return (
            <StyledFormControl variant="outlined" fullWidth disabled={disabled}>
              <Autocomplete
                {...field}
                multiple
                value={sanitizeValue(field.value)}
                disabled={disabled}
                disableCloseOnSelect={disableCloseOnSelect}
                data-testid={`autocomplete-${name}`}
                id={`autocomplete-${name}`}
                options={options}
                noOptionsText={noOptionsText}
                onChange={onChange}
                filterOptions={filterOptions}
                renderOption={renderOption}
                renderTags={renderTag}
                inputValue={searchValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!!error}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                    onChange={setSearchValue}
                  />
                )}
              />
            </StyledFormControl>
          );
        }}
      />
      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

const Container = styled.div`
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)<{ disabled: boolean | undefined }>`
  &.MuiFormControl-root {
    flex: 1;
  }

  .MuiAutocomplete-root {
    background-color: white;
    border-radius: ${props => props.theme.borderRadius.basic};
    min-width: 116px;

    .MuiFormLabel-root {
      display: block;
    }
    .MuiFormControl-root {
      .MuiInputBase-root {
        padding: 23px 12px 7px;
        .MuiInputBase-input {
          padding: 0;
        }
      }
    }

    .MuiInputBase-root {
      padding: 23px 12px 7px;

      &.Mui-disabled {
        background-color: ${props => props.theme.colors.greys.light2};
      }
      .MuiChip-root {
        opacity: 1;
        color: ${props => (props.disabled ? props.theme.colors.redesign.db70 : props.theme.colors.primary.base)};
        .MuiChip-deleteIcon {
          display: ${props => props.disabled && "none"};
        }
      }
    }
    ${MUIInput}
    ${MUIBorder}
    ${MUITextInputLabel}
  }

  .MuiList-padding {
    padding: 0;
  }

  .MuiListItem-root {
    padding: 0;
    border-bottom: 1px solid #e1e8ef;
  }
`;

const ErrorText = styled.div`
  margin-top: 4px;
  ${MUIErrorText}
`;

export default MUIAutocomplete;
