import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { FeelingReport, FeelingReportArgs } from "api/schemas/patients/FeelingReport";
import useApiClient from "api/useApiClient";

type QueryKey = ["useGetPatientFeelingReports", FeelingReportArgs];
export const getPatientFeelingReportsQueryKey = (args: FeelingReportArgs): QueryKey => [
  "useGetPatientFeelingReports",
  args,
];

export const useGetPatientFeelingReports = (
  args: FeelingReportArgs,
  options?: UseQueryOptions<FeelingReport[], Error, FeelingReport[], QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getPatientFeelingReportsQueryKey(args), () => client.getPatientFeelingReports(args), options);
};
