import type { AxiosResponse } from "axios";

import { MarketTypes } from "types";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import { isNoteCreatable, newNote } from "../queries/notesApiHandler";

const canCreateNote = async (patientId?: number, type = "chat"): Promise<boolean> => {
  const isCreatable = await isNoteCreatable(patientId);
  const isCreatableData = isCreatable.data as Record<string, boolean>;

  if (type && isCreatableData[`${type}_event`]) {
    return isCreatableData[`${type}_event`];
  }
  return false;
};

const newNoteSafe = async (
  patientId?: number,
  type?: string,
  TID?: number
): Promise<AxiosResponse<unknown> | false> => {
  if (await canCreateNote(patientId, type)) {
    const newNoteResult = await newNote(patientId, type, TID);
    const newNoteResultData = newNoteResult.data as Record<string, unknown>;

    if (newNoteResult.status === 200 && newNoteResultData.id) {
      const properties: Record<string, unknown> = {
        patient: patientId,
      };
      if (type) {
        properties.label = type;
      }
      if (TID) {
        properties.TID = TID;
      }
      AnalyticsService.track(AnalyticsEvents.PATIENTS.CREATE_MEDICAL_NOTE, properties);
    }

    return newNoteResult;
  }

  return Promise.resolve(false);
};

const getAvailableOptionsFromBusinessLogic = (
  market: string,
  creatableInfo: Record<string, boolean>
): Array<string> => {
  // Early exit in case data is not present
  if (!creatableInfo || !market) {
    return [];
  }

  // ALL MARKETS - Supplemental, Chat note (visible and disabled if not creatable)
  // US - Exclusive options [treatment, treatment_and_progress, re_evaluation]
  // SE - SelfCare if enabled
  // NON US - Hide all that are not creatable (exception: [chat])
  const selfcareEnabled = creatableInfo.discharge_event || false;

  const isOptionVisible = (type: string) => {
    // We should only show Discharge to Selfcare when selfcare is enabled
    if (selfcareEnabled) {
      if (type === "discharge") {
        return true;
      }
    } else if (type === "discharge") {
      return false;
    }

    // Chat will always be visible
    if (type === "chat") {
      return true;
    }

    // US market specific rules
    if (market === MarketTypes.US) {
      return true;
    }

    // SE market specific rules
    if (market === MarketTypes.SE) {
      // Allow supplemental notes in swedem when self-care is enabled
      if (selfcareEnabled && ["supplemental"].indexOf(type) >= 0) {
        return true;
      }
    }

    // NON-US market specific rules
    if (market !== MarketTypes.US) {
      // Always hide the options that are not possible to create
      return creatableInfo ? creatableInfo[`${type}_event`] : false;
    }
    return false;
  };

  return Object.keys(creatableInfo).reduce((final, noteType: string) => {
    const type = noteType.replace("_event", "");

    if (isOptionVisible(type)) {
      final.push(type);
    }
    return final;
  }, [] as Array<string>);
};

export { canCreateNote, newNoteSafe, getAvailableOptionsFromBusinessLogic };
