import theme from "theme";
import type { ICDCodeType } from "types/ICDCodes";

const getColorFromICDCodeType = (type: ICDCodeType): string => {
  switch (type) {
    case "knee":
      return theme.colors.pain_location_colors.knee;
    case "hand":
      return theme.colors.pain_location_colors.hand;
    case "back":
      return theme.colors.pain_location_colors.back;
    case "exclusion":
      return theme.colors.pain_location_colors.exclusion;
    case "hip":
      return theme.colors.pain_location_colors.hip;
    case "shoulder":
      return theme.colors.pain_location_colors.shoulder;
    case "neck":
      return theme.colors.pain_location_colors.neck;

    default:
      return theme.colors.greys.bluish;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getColorFromICDCodeType };
