import axios from "axios";

import { configureAuthInterceptors } from "utils/auth/axiosInterceptors";
import { getAppDataRegion } from "utils/region";

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_RAILS_URL}/api/v1/`,
  headers: {
    "ja-app-name": "physio",
    "ja-app-build-number": process.env.REACT_APP_VERSION as string,
    "ja-app-market": getAppDataRegion() as string,
  },
});

export const clientV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/`,
  headers: {
    "ja-app-name": "physio",
    "ja-app-build-number": process.env.REACT_APP_VERSION as string,
    "ja-app-market": getAppDataRegion() as string,
  },
});

export const clientLeifGPT = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL?.replace("/api", "")}/leif-gpt/`,
  headers: {
    "ja-app-name": "physio",
    "ja-app-build-number": process.env.REACT_APP_VERSION as string,
    "ja-app-market": getAppDataRegion() as string,
  },
});

configureAuthInterceptors(client);
configureAuthInterceptors(clientV2);
configureAuthInterceptors(clientLeifGPT);

export default client;
