import { useContext } from "react";

import FeatureToggleContext from "./FeatureToggleContext";

import type { FeatureToggleContextValue } from ".";

const useFeatureToggleContext = (): FeatureToggleContextValue => {
  const value = useContext(FeatureToggleContext);

  if (value === null) {
    throw new Error("useFeatureToggleContext cannot be used outside the FeatureToggleContextProvider");
  }
  return value;
};

export default useFeatureToggleContext;
