import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

export interface FormData {
  name: string;
  phone_number?: string;
  phone_country_code?: string;
  email?: string;
  language_code: string;
  treatment_type?: string;
}

interface SendInviteResponse {
  id: number;
  phone_number: string | null;
  email: string;
}

const sendInvite = (body: FormData): Promise<AxiosResponse<SendInviteResponse>> =>
  AxiosInstance.post("invite/patient", {
    ...body,
  });

export default sendInvite;
