import { z } from "zod";

const TreatmentAdherenceArgsSchema = z.object({
  patientID: z.number(),
  treatmentID: z.number().optional(),
});
export type TreatmentAdherenceArgs = z.infer<typeof TreatmentAdherenceArgsSchema>;

export const TreatmentAdherenceSchema = z.object({
  activity_days: z.number(),
  patient_id: z.number(),
  week: z.object({
    adherence: z.number(),
    completed: z.number(),
    number: z.number(),
    total: z.number(),
  }),
});
export type TreatmentAdherence = z.infer<typeof TreatmentAdherenceSchema>;
