import { MarketTypes } from "types";
import { isMarket } from "utils/market";
import { isProduction, isStage } from "utils/misc/getBuildEnvironment";
import { getStorageValue } from "utils/storage";

const resetPasswordLinksStage = {
  eu: "https://forgot-password.eu.jastage.io/",
  us: "https://forgot-password.us.jastage.io/",
};

const resetPasswordLinksProduction = {
  eu: "https://forgot-password.eu.jointacademy.com/",
  us: "https://forgot-password.us.jointacademy.com/",
};

export function getResetPasswordLink(email?: string) {
  let link;

  const storedLanguage = getStorageValue("language")?.split("-")[0];
  const lang = storedLanguage === "fr" ? "fr" : "en";

  if (isStage()) {
    link = isMarket(MarketTypes.US) ? resetPasswordLinksStage.us : resetPasswordLinksStage.eu;
  } else if (isProduction()) {
    link = isMarket(MarketTypes.US) ? resetPasswordLinksProduction.us : resetPasswordLinksProduction.eu;
  }

  link = `${link}?lang=${lang}`;

  return email ? `${link}&email=${encodeURIComponent(email)}` : link;
}
