import type React from "react";

import styled from "styled-components";

import { BaseBlueCheckmarkIcon } from "assets";

type Button = {
  value: string;
  label: string;
};

interface Props {
  readonly group: Button[];
  active?: string;
  setActive: (value: string) => void;
}

const ButtonsGroup: React.VFC<Props> = ({ group, active, setActive }) => {
  return (
    <Container>
      {group.map(button => {
        const isActive = button.value === active;

        return (
          <ButtonWrapper
            key={button.value}
            data-testid={`invite-type-${button.value}`}
            className={isActive ? "active" : ""}
            onClick={() => setActive(button.value)}
          >
            {isActive && <BaseBlueCheckmarkIcon style={{ marginRight: "4px" }} />}
            {button.label}
          </ButtonWrapper>
        );
      })}
    </Container>
  );
};

export default ButtonsGroup;

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 52px;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  margin-left: -2px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.primary.base};
  letter-spacing: 0.5px;
  cursor: pointer;

  &:first-child {
    border-radius: 26px 0 0 26px;
  }
  &:last-child {
    border-radius: 0 26px 26px 0;
  }

  &.active {
    background-color: ${props => props.theme.colors.redesign.b10};
    color: ${props => props.theme.colors.redesign.b100};
    font-weight: 500;
  }
`;
