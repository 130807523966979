/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import type { LegacyRef } from "react";
import type React from "react";
import { useEffect, useRef } from "react";

import type { AudioTrack as AudioTrackType } from "twilio-video";

interface Props {
  readonly track: AudioTrackType;
}

const AudioTrack: React.VFC<Props> = ({ track }) => {
  const audioRef = useRef<HTMLAudioElement>();
  useEffect(() => {
    if (track) {
      track.attach(audioRef.current);
      return () => {
        track.detach();
      };
    }
  }, [track]);
  return <audio ref={audioRef as LegacyRef<HTMLAudioElement>} autoPlay />;
};
export default AudioTrack;
