import type React from "react";
import { createRef, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";

import type { Therapist } from "api/models/Therapist";
import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { updateImage } from "routes/settings/queries";
import Avatar from "shared/atoms/Avatar";
import IconButton from "shared/atoms/IconButton";
import { Notification } from "shared/atoms/Notification";
import { useWindowSize } from "utils/hooks";

const ProfileInformationContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing.S_15};
`;

const ProfileInformation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const ProfileName = styled.h6`
  ${props => props.theme.font.header1};
  color: ${props => props.theme.colors.primary.base};
  margin-left: ${props => props.theme.spacing.S_30};
  ${props => props.theme.belowBreakpoint} {
    ${props => props.theme.font.header3}
    margin-left: ${props => props.theme.spacing.S_20};
    margin-top: ${props => props.theme.spacing.S_10};
    margin-bottom: ${props => props.theme.spacing.S_10};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ProgressRing = styled.svg<{ $visible: boolean }>`
  position: absolute;
  height: 80px;
  width: 80px;
  transition: opacity 200ms;
  opacity: ${props => (props.$visible ? "1" : "0")};

  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    r: 38;
    cx: 40;
    cy: 40;
  }

  ${props => props.theme.belowBreakpoint} {
    height: 50px;
    width: 50px;

    circle {
      r: 23;
      cx: 25;
      cy: 25;
    }
  }
`;

const StyledDiv = styled.div<{ size: number; fontSize: number; color?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
  border-radius: ${props => props.theme.borderRadius.circular};
  text-align: center;
  overflow: hidden;
  user-select: none;
`;

const ProfileHeader: React.VFC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const theme = useContext(ThemeContext);
  const { profile, setProfile } = useProfileContext();
  const fileRef = createRef<HTMLInputElement>();
  const [error, setError] = useState(undefined);
  const [avatar, setAvatar] = useState(profile?.avatars?.medium || "");
  const [avatarUpdating, setAvatarUpdating] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const getAvatarSize = () => (width < parseInt(theme.breakpoint, 10) ? 56 : 78);
  const getAvatarFontSize = () => (width < parseInt(theme.breakpoint, 10) ? 24 : 32);

  const radius = width > parseInt(theme.breakpoint, 10) ? 38 : 23;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (uploadProgress / 100) * circumference;

  const handleProgress = ({ loaded = 0, total = 0 }) => {
    setUploadProgress(Math.round((100 * (loaded || 0)) / total));
  };

  const sendImage = (image?: File) => {
    const imgData = new FormData();

    if (image) {
      setAvatarUpdating(true);
      setUploadProgress(0);
      imgData.set("file", image);
    } else {
      setAvatar("");
    }
    updateImage(imgData, handleProgress)
      .then(({ data }) => {
        setUploadProgress(-1);
        const updatedProfile = {
          ...profile,
          avatars: {
            large: data?.avatar?.large_avatar?.url || null,
            medium: data?.avatar?.medium_avatar?.url || null,
            small: data?.avatar?.small_avatar?.url || null,
          },
        };
        setProfile(updatedProfile as Therapist);
        const resultSrc = data?.avatar?.medium_avatar?.url || "";
        setAvatar(resultSrc);
        setAvatarUpdating(false);
      })
      .catch(err => {
        setUploadProgress(-1);
        setError(err);
        setAvatarUpdating(false);
      });
  };

  return (
    <ProfileInformationContainer data-testid="profile-header">
      <ProfileInformation>
        <StyledDiv
          style={{ display: "flex", alignItems: "center", borderWidth: 0 }}
          size={getAvatarSize()}
          fontSize={getAvatarFontSize()}
        >
          <Avatar
            id={profile?.id}
            name={`${profile?.first_name} ${profile?.last_name}`}
            size={getAvatarSize()}
            fontSize={getAvatarFontSize()}
            src={avatar}
          />
          {avatarUpdating && uploadProgress >= 0 ? (
            <ProgressRing $visible={uploadProgress >= 0}>
              <circle stroke="rgb(218, 236, 255)" strokeWidth="4" fill="transparent" />
              <circle
                stroke="rgb(74, 144, 226)"
                strokeWidth="4"
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={offset}
                fill="transparent"
              />
            </ProgressRing>
          ) : null}
        </StyledDiv>
        <ProfileName>
          {profile?.first_name} {profile?.last_name}
        </ProfileName>
      </ProfileInformation>
      <IconButton
        label={t("settings.change_profile_picture")}
        onClick={() => {
          fileRef.current?.click();
        }}
      >
        <EditIcon />
      </IconButton>
      <HiddenInput
        data-testid="file-input"
        type="file"
        ref={fileRef}
        name="avatar"
        accept="image/*"
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
          if (!avatarUpdating && event.target?.files?.[0]) {
            sendImage(event.target.files?.[0]);
          }
        }}
      />
      {error && (
        <Notification variant="danger" style={{ margin: "18px auto" }}>
          {t("settings.upload_failed")}
        </Notification>
      )}
    </ProfileInformationContainer>
  );
};

export default ProfileHeader;
