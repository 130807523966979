import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { StickyNote } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import TextArea from "shared/atoms/inputs/TextArea";
import Row from "shared/atoms/Row";
import Spinner from "shared/atoms/Spinner";
import { CurrentPatientContext } from "utils/contexts";

import { StickyNoteContainer } from "./PatientViewStickyNote";
import putPatientNote from "./Queries/putPatientNote";

interface PatientEditStickyNoteProps {
  readonly setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setStickyNoteMessage: React.Dispatch<React.SetStateAction<string>>;
  readonly stickyNoteMessage: string;
}

const PatientEditStickyNote: React.VFC<PatientEditStickyNoteProps> = ({
  setIsEditing,
  setStickyNoteMessage,
  stickyNoteMessage,
}) => {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const { savedStickyNotes, setSavedStickyNotes } = useSavedInputsContext();
  const patientsStickyNote = savedStickyNotes[patient.id] ?? stickyNoteMessage;

  invariant(profile);

  useEffect(() => {
    return () => {
      if (textAreaRef.current) {
        setSavedStickyNotes({ [patient.id]: textAreaRef.current.value });
      }
    };
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.value = patientsStickyNote;
    }
  }, [textAreaRef]);

  const onSave = async () => {
    setIsSaving(true);
    const note = textAreaRef?.current?.value ?? "";
    await putPatientNote({ id: patient.id, therapistId: profile.id, note });
    setStickyNoteMessage(note);
    setSavedStickyNotes({ [patient.id]: note });
    setIsEditing(false);
  };

  if (isSaving) {
    return (
      <LoadingContainer>
        <Spinner dataTestId="spinner" small />
      </LoadingContainer>
    );
  }

  return (
    <StickyEditNoteContainer data-testid="edit-patient-sticky-note">
      <Container>
        <StickyNote />
        <StyledTextArea placeholder={t("sticky_note.default")} ref={textAreaRef} rows={3} />
      </Container>
      <ButtonContainer>
        <CancelButton onClick={() => setIsEditing(false)} type="button" data-testid="cancel-sticky-note">
          {t("buttons.cancel")}
        </CancelButton>
        <StyledButton onClick={onSave} data-testid="save-sticky-note">
          {t("buttons.save")}
        </StyledButton>
      </ButtonContainer>
    </StickyEditNoteContainer>
  );
};

const LoadingContainer = styled(StickyNoteContainer)`
  background-color: ${props => props.theme.colors.white};
  justify-content: center;
`;

const StyledTextArea = styled(TextArea)`
  width: 90%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: ${props => props.theme.spacing.S_5};
  * {
    margin-right: ${props => props.theme.spacing.S_15};
  }
`;

const CancelButton = styled(TextButton)`
  margin-right: ${props => props.theme.spacing.S_20};
  border-width: 2px;
  ${props => props.theme.belowMobileBreakpoint} {
    margin-right: 0;
  }
`;

const StyledButton = styled(PrimaryButton)<{ success?: boolean }>`
  margin-left: ${props => (props.success ? props.theme.spacing.S_20 : 0)};

  ${props => props.theme.belowBreakpoint} {
    margin-top: ${props => (props.success ? props.theme.spacing.S_20 : 0)};
  }
`;

const ButtonContainer = styled(Row)`
  padding-top: ${props => props.theme.spacing.S_20};
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing.S_25};
  & button {
    height: 50px;
  }

  ${props => props.theme.belowBreakpoint} {
    align-items: center;
    justify-content: flex-start;
  }
  ${props => props.theme.belowMobileBreakpoint} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const StickyEditNoteContainer = styled(StickyNoteContainer)`
  flex-direction: column;
  align-items: center;
`;

export default PatientEditStickyNote;
