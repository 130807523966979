import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface MarkAsViewedResponse {
  readonly activity_ids: number[];
}

const markAsViewed = (pid: string, ids: number[]): Promise<AxiosResponse<MarkAsViewedResponse>> =>
  AxiosInstance.post(`/patients/${pid}/activities/mark_as_viewed`, {
    activity_ids: ids,
  });

export default markAsViewed;
