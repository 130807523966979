import { useEffect, useRef, useState } from "react";

type Mode = "timer" | "countdown";
type Timer = {
  startTimer: () => void;
  stopTimer: () => void;
  seconds: number;
  setSeconds: (n: number) => void;
  time: string;
  isRunning: boolean;
};

const useTimer = (mode: Mode = "timer"): Timer => {
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!timer.current && isRunning) {
      timer.current = setInterval(() => {
        if (mode === "timer") {
          setSeconds(s => s + 1);
        }
        if (mode === "countdown") {
          setSeconds(s => {
            if (s > 1) {
              return s - 1;
            }
            if (timer.current) {
              clearInterval(timer.current);
            }
            return 0;
          });
        }
      }, 1000);
    }
    if (timer.current && !isRunning) {
      clearInterval(timer.current);
      timer.current = null;
    }
  }, [isRunning, timer, seconds]);

  useEffect(() => {
    const h = Math.floor(seconds / 60 / 60);
    const m = Math.floor((seconds / 60) % 60);
    const s = seconds % 60;
    const pad = (n: number): string => (n < 10 ? `0${n}` : String(n));
    setTime(`${h > 0 ? `${pad(h)}:` : ""}${pad(m)}:${pad(s)}`);
  }, [seconds]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  return {
    startTimer,
    stopTimer,
    seconds,
    setSeconds,
    time,
    isRunning,
  };
};

export default useTimer;
