import { useContext } from "react";

import VideoCallContext from "./VideoCallContext";
import type { VideoCallContextValue } from "./VideoCallContextValue";

const useVideoCallContext = (): VideoCallContextValue => {
  const value = useContext(VideoCallContext);

  if (value === null) {
    throw new Error("useVideoCallContext cannot be used outside the VideoCallContextProvider");
  }
  return value;
};

export default useVideoCallContext;
