import type { TOptions } from "i18next";
import { useTranslation } from "react-i18next";

const useTranslationMacro = (path: string): ((opt?: TOptions) => string) => {
  const { t } = useTranslation();
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return opt => t(path, opt);
};

export default useTranslationMacro;
