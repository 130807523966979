import type React from "react";

import styled from "styled-components";

import { CrossIcon } from "assets";

interface Props {
  fullBgOpacity?: boolean;
  showCloseButton?: boolean;
  bioPreviewModal?: boolean;
  children: React.ReactNode;
  bankIdModal?: boolean;
  selfCarePopup?: boolean;
  callModal?: boolean;
  blankModal?: boolean;
  noPadding?: boolean;
  onClose?: () => void;
  onClickOutside?: () => void;
}

const Popup: React.VFC<Props> = ({
  fullBgOpacity = false,
  showCloseButton = false,
  children,
  bioPreviewModal = false,
  bankIdModal,
  selfCarePopup,
  callModal = false,
  blankModal,
  noPadding,
  onClose,
  onClickOutside,
}) => {
  if (fullBgOpacity && (onClickOutside || onClose)) {
    const onClickOutsideHandler = (event: React.MouseEvent<HTMLElement>) => {
      if (event.target === event.currentTarget && onClickOutside) {
        onClickOutside();
      }
    };
    return (
      <OpacityWrapper onClick={onClickOutsideHandler}>
        <OpacityContainer $noPadding={noPadding}>
          {showCloseButton && (
            <ButtonWrapper
              data-testid="close-button"
              onClick={() => {
                if (onClose) {
                  onClose();
                } else if (onClickOutside) {
                  onClickOutside();
                }
              }}
            >
              <CrossIcon />
            </ButtonWrapper>
          )}
          {children}
        </OpacityContainer>
      </OpacityWrapper>
    );
  }
  if (bankIdModal) {
    return <BankIdWrapper>{children}</BankIdWrapper>;
  }
  if (selfCarePopup) {
    return <SelfCareWrapper>{children}</SelfCareWrapper>;
  }

  if (callModal) {
    return <CallModalWrapper data-testid="calling-state-modal">{children}</CallModalWrapper>;
  }

  if (blankModal) {
    return <BlankModalWrapper>{children}</BlankModalWrapper>;
  }

  return <ContentWrapper bioPreviewModal={bioPreviewModal}>{children}</ContentWrapper>;
};

export default Popup;

const Wrapper = styled.div<Props>`
  position: fixed;
  top: 50%;
  left: 50%;
  ${props => props.theme.aboveBreakpoint} {
    left: ${props => (props.bioPreviewModal === false ? "50%" : "85%")};
  }
  transform: translate(-50%, -50%);
  z-index: ${props => (props.bioPreviewModal === false ? props.theme.zIndex.modal : props.theme.zIndex.bioPreview)};
  display: flex;
  flex-direction: column;
`;

const OpacityContainer = styled.div<{ $noPadding?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  min-width: 300px;
  max-height: 90%;
  background-color: white;
  padding: ${({ $noPadding, theme }) => !!$noPadding && theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.library};
  box-shadow: 5px 10px 19px rgba(68, 104, 147, 0.3);
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1000;
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
  cursor: pointer;
`;

const OpacityWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(111, 127, 147, 0.7);
  z-index: 99;

  ${props => props.theme.belowBreakpoint} {
    max-width: 100vw;
    right: 0;
    left: unset;
  }
`;

const ContentWrapper = styled(Wrapper)<Props>`
  ${props => props.bioPreviewModal === false && "width: 300px;"};
  background: ${props => props.theme.colors.redesign.b10};
  padding: ${props => props.bioPreviewModal === false && props.theme.spacing.S_25};
  border-radius: ${props => props.theme.borderRadius.library};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
`;

const BankIdWrapper = styled(Wrapper)`
  background: #fff;
  padding: ${props => props.bioPreviewModal === false && props.theme.spacing.S_25};
  border-radius: ${props => props.theme.borderRadius.library};
  text-align: center;
  top: 50%;
  left: 50%;
  width: 80%;
  margin: auto;
  ${props => props.theme.aboveBreakpoint} {
    width: 30%;
    height: 60%;
  }
`;
const SelfCareWrapper = styled(Wrapper)`
  background: #fff;
  border-radius: ${props => props.theme.borderRadius.library};
  text-align: left;
  top: 55%;
  left: 50%;
  width: 90%;
  height: 85%;
  margin: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
  ${props => props.theme.aboveMobileBreakpoint} {
    width: 60%;
    height: 70%;
  }
  ${props => props.theme.aboveLargeBreakpoint} {
    width: 30%;
    height: 85%;
  }
`;

const CallModalWrapper = styled(Wrapper)`
  background: linear-gradient(to top, #fff 81%, ${props => props.theme.colors.pastels.red} 19%);
  width: 355px;
  height: 291px;
  padding: ${props => props.bioPreviewModal === false && props.theme.spacing.S_10};
  border-radius: ${props => props.theme.borderRadius.library};
  box-shadow: 5px 10px 19px rgba(68, 104, 147, 0.3);
  top: 50%;
  left: 50%;
`;

const BlankModalWrapper = styled(Wrapper)`
  background: ${props => props.theme.colors.white};
  box-shadow: 5px 10px 19px rgba(68, 104, 147, 0.3);
  top: 50%;
  left: 50%;
  ${props => props.theme.belowMobileBreakpoint} {
    width: 90%;
  }
`;
