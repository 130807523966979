import { z } from "zod";

import { PatientAvailableJointsResponseSchema } from "./ManagerBooking";

const AvailableAilmentsArgsSchema = z.object({
  therapistID: z.number(),
  patientID: z.number(),
});
export type AvailableAilmentsArgs = z.infer<typeof AvailableAilmentsArgsSchema>;

const AvailableAilmentsResponseSchema = z.object({
  fall_prevention: z.boolean(),
  joint_pain: z.boolean(),
  osteoporosis: z.boolean(),
});
export type AvailableAilmentsResponse = z.infer<typeof AvailableAilmentsResponseSchema>;

export const AvailableAilmentsSchema = z.object({
  available_ailments: AvailableAilmentsResponseSchema,
  available_joints: PatientAvailableJointsResponseSchema,
});
export type AvailableAilments = z.infer<typeof AvailableAilmentsSchema>;
