import type React from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import defaultSpinnerJson from "assets/animations/default_spinner.json";
import prideSpinnerJson from "assets/animations/pride_spinner.json";
import { useAppConfigContext } from "contexts/AppConfigContext";

interface SpinnerProps {
  dataTestId?: string;
  small?: boolean;
  style?: React.CSSProperties;
}

const Spinner: React.VFC<SpinnerProps> = ({ dataTestId, small, style }) => {
  const { isPrideMonth } = useAppConfigContext();

  let size = "150px";
  if (small) size = "70px";

  return (
    <div data-testid={dataTestId}>
      <Player
        autoplay
        loop
        src={isPrideMonth ? prideSpinnerJson : defaultSpinnerJson}
        style={{ height: size, width: size, ...style }}
      />
    </div>
  );
};

export default Spinner;
