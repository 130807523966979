import type React from "react";

import styled from "styled-components";

const StyledCell = styled.td<{ $hoverEnabled: boolean; $dragOver: boolean; $dragDirection: string }>`
  padding: 0 ${props => props.theme.spacing.S_20};
  ${props => props.$hoverEnabled && "cursor: pointer;"}
  ${props => props.theme.font.table.sort};
  line-height: initial;

  ${props => props.theme.belowBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_15};
  }

  ${props =>
    props.$dragOver &&
    props.$dragDirection === "left" &&
    `border-left: 2px solid ${props.theme.colors.table_border_highlight};`}
  ${props =>
    props.$dragOver &&
    props.$dragDirection === "right" &&
    `border-right: 2px solid ${props.theme.colors.table_border_highlight};`}
`;

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableCellElement>;
  className?: string;
  dataTestId?: string;
  colSpan?: number;
  hoverEnabled?: boolean;
  dragOver?: boolean;
  dragDirection?: string;
}

const TableCell: React.VFC<Props> = ({
  children,
  onClick,
  colSpan = 1,
  className = "",
  dataTestId = "table-cell",
  hoverEnabled = false,
  dragOver = false,
  dragDirection = "",
}) => {
  return (
    <StyledCell
      onClick={onClick}
      className={className}
      data-testid={dataTestId}
      colSpan={colSpan}
      $hoverEnabled={hoverEnabled}
      $dragOver={dragOver}
      $dragDirection={dragDirection}
    >
      {children}
    </StyledCell>
  );
};

export default TableCell;
