import { AuthClient } from "api";

export interface FormData {
  readonly country_code?: string;
  readonly email: string;
  readonly password: string;
  readonly password_confirmation: string;
  readonly confirmation_token: string;
  readonly source?: string;
}

interface AuthenticationResponse {
  readonly success: boolean;
}

const signupWithEmail = async (body: FormData): Promise<void> => {
  await AuthClient.post<AuthenticationResponse>("/email/sign_up", body);
};

export default signupWithEmail;
