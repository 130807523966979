import type { TFunction } from "i18next";

import type { PainLocation } from "../../helpers/helpers";

const calculateJointScore = (answer: Record<string, number>, painLocation: PainLocation, t: TFunction): string => {
  switch (painLocation) {
    case "knee": // hip and knee share the same calculation
    case "hip":
      return `${Math.round((1 - Object.values(answer).reduce((a, b) => a + b, 0) / 48) * 100)} %`;
    case "hand":
      return `${Object.values(answer).reduce((a, b) => a + b, 0)}/30`;
    case "shoulder":
      return `${Math.round((Object.values(answer).reduce((a, b) => a + b, 0) * 10) / 13)} %
      \n(${
        (answer.lying + answer.worst_pain + answer.reaching_high + answer.touching_neck + answer.pushing_arm) * 2
      }% ${t(`patients.health_reports.questions_content.spadi_calculation.pain`)}, ${Math.round(
        ((answer.back_pocket +
          answer.carry_heavy +
          answer.putting_on_pants +
          answer.putting_on_shirt +
          answer.putting_on_shirt_buttons +
          answer.washing_back +
          answer.washing_hair +
          answer.high_shelf) *
          100) /
          80
      )}% ${t(`patients.health_reports.questions_content.spadi_calculation.disability`)})`;
    case "lower_back":
      // if answer.sex_life is "6" it means the patient chose not to answer
      // BE api returns strings for this joint's answers
      if (`${answer.sex_life}` !== "6") {
        return `${Math.round(
          (Object.values(answer).reduce((a, b) => parseInt(`${a}`, 10) + parseInt(`${b}`, 10), 0) / 50) * 100
        )} %`;
      }
      return `${Math.round(
        ((Object.values(answer).reduce((a, b) => parseInt(`${a}`, 10) + parseInt(`${b}`, 10), 0) - 6) / 45) * 100
      )} %`;
    case "neck": {
      // if answer.driving is "5" it means the patient never drives a car, therefore the value of driving is excluded in the second return statement below
      // npq also has the value for "improvement" (on follow up forms), but that value is not wanted in the calculation
      const sum =
        answer.intensity +
        answer.sleeping +
        answer.numbness +
        answer.duration +
        answer.carrying +
        answer.reading +
        answer.working +
        answer.social;
      return answer.driving !== 5
        ? `${Math.round(((sum + answer.driving) * 100) / 36)} %`
        : `${Math.round((sum * 100) / 32)} %`;
    }
    default:
      return "";
  }
};

export default calculateJointScore;
