import { useContext } from "react";

import AppConfigContext from "./AppConfigContext";
import type { AppConfigContextValue } from "./AppConfigContextValue";

const useAppConfigContext = (): AppConfigContextValue => {
  const value = useContext(AppConfigContext);

  if (value === null) {
    throw new Error("useAppConfigContext cannot be used outside the AppConfigContextProvider");
  }
  return value;
};

export default useAppConfigContext;
