import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import type { HcpServiceManager } from "api/schemas/HcpService";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton } from "shared/atoms/Button";
import { MUIDropdown } from "shared/atoms/inputs";

import type { Option } from "../../helpers";
import { BOOKING_SCREENS } from "../AdminBookingForm";
import { Container } from "../misc";

interface Props {
  newPatient: boolean;
  services: HcpServiceManager[];
  serviceOptions: Option[];
  setService: (service: HcpServiceManager) => void;
}

export const SelectService: React.VFC<Props> = ({ newPatient, services, serviceOptions, setService }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  invariant(profile);

  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    setValue(serviceOptions[0]?.value);
  }, [serviceOptions]);

  return (
    <StyledContainer>
      {serviceOptions.length > 0 ? (
        <StyledMUIDropdown
          label={t("booking.form.service")}
          name="health_care_provider_service_id"
          options={serviceOptions}
          onChange={event => {
            setValue(event.target.value as string);
          }}
          value={value}
          in_form={false}
        />
      ) : (
        <span style={{ textAlign: "center", marginBottom: "32px" }}>{t("booking.manager_booking.no_services")}</span>
      )}
      {serviceOptions.length > 0 && (
        <PrimaryButton
          onClick={() => {
            const hcpService = services.find(service => service.id === Number(value));
            if (hcpService) {
              setService(hcpService);

              if (newPatient && hcpService.health_questionnaire_type === "default") {
                navigate(`../${BOOKING_SCREENS.HEALTH_QUESTIONNAIRE}/joint`);
              } else {
                navigate(`../${BOOKING_SCREENS.SELECT_DATE_AND_PERSONNEL}`);
              }
            }
          }}
          style={{ alignSelf: "center" }}
        >
          {t("booking.buttons.continue")}
        </PrimaryButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  min-width: 378px;
  max-width: 450px;

  ${props => props.theme.belowBreakpoint} {
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 8px;
  }
`;

const StyledMUIDropdown = styled(MUIDropdown)`
  margin-bottom: 32px;
`;
