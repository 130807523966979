/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef } from "react";

import type { ChangeHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DropdownIcon } from "assets";

import { StyledDropdown, StyledLabel } from "../StyledInputs";

export type Option = { label: string; value: string; disabled?: boolean };

interface Props {
  disabled?: boolean;
  name?: string;
  form?: string;
  required?: boolean;
  label?: string;
  options: Array<Option>;
  selected?: string;
  size?: "small" | "large";
  placeholder?: string;
  className?: string;
  error?: string;
  instruction?: string;
  m?: string;
  localiseLabels?: boolean;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
}

const Dropdown = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    disabled = false,
    name = undefined,
    form = undefined,
    required = false,
    label = null,
    options,
    selected = null,
    size = "large",
    placeholder = null,
    className = undefined,
    error = undefined,
    instruction = null,
    m = "5px 0 5px 0",
    localiseLabels = false,
    onChange = () => {},
    onBlur = () => {},
  } = props;

  const { t } = useTranslation();

  const leftText = <HelperText error={!!error}>{error || instruction}</HelperText>;

  return (
    <Container margin={m} className={className}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <StyledIcon size={size} />
      <StyledDropdown
        ref={ref}
        disabled={disabled}
        aria-label={name}
        name={name}
        form={form}
        required={required}
        $size={size}
        error={error}
        defaultValue={selected || placeholder || undefined}
        data-testid={`${name}-dropdown`}
        onChange={onChange}
        onBlur={onBlur}
        onClick={event => event.stopPropagation()}
      >
        {placeholder && (
          <option key={placeholder} value={placeholder} disabled>
            {placeholder}
          </option>
        )}
        {options.map((option: Option) => (
          <option disabled={option.disabled} key={option.value} value={option.value} data-testid={option.value}>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {localiseLabels ? t(option.label) : option.label}
          </option>
        ))}
      </StyledDropdown>
      {(error || instruction) && leftText}
    </Container>
  );
});

export default Dropdown;

const Container = styled.div<{ margin: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.margin};
  position: relative;
`;

const HelperText = styled.div<{ error: boolean }>`
  display: flex;
  flex-grow: 1;
  color: ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.greys.dark)};
  ${props => ({ ...props.theme.font.caption })};
  padding-left: 2px;
  padding-right: 2px;
  justify-content: flex-start;
`;

const StyledIcon = styled(DropdownIcon)<{ size: "small" | "large" }>`
  width: ${props => (props.size === "small" ? "11px" : "13px")};
  color: #0ebeff;
  right: 10px;
  top: 5px;
  height: 10px;
  padding: ${props => (props.size === "small" ? "6px" : "15px")} 0px 0px 8px;
  position: absolute;
  pointer-events: none;
`;
