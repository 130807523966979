import type React from "react";

import styled from "styled-components";

import PartnerPatientsTable from "./components/PartnerPatientsTable";
import UnclaimedReferralsTable from "./components/UnclaimedReferralsTable";

const Container = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  margin-bottom: ${props => props.theme.spacing.S_20};
`;

const PartnerDashboard: React.VFC = () => {
  return (
    <Container>
      <UnclaimedReferralsTable />
      <PartnerPatientsTable />
    </Container>
  );
};
export default PartnerDashboard;
