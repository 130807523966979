import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ArrowRightBlue, CheckmarkIcon } from "assets";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { PrimaryButton, SecondaryButton, TextButton } from "shared/atoms/Button";
import Popup from "shared/atoms/Popup";

import verifyCallSuccess from "../queries/verifyCallSuccess";

interface Props {
  callId: number | null;
}

const CallConfirmationPopup: React.VFC<Props> = ({ callId }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { callData, setCallData } = useVideoCallContext();
  const prevCallData = useRef(callData);

  useEffect(() => {
    if (callData === null && prevCallData.current !== null) {
      setShowModal(true);
    } else {
      prevCallData.current = callData;
    }
  }, [callData]);

  const close = () => {
    setShowModal(false);
    prevCallData.current = null;
  };

  const onSuccessfulCall = () => {
    try {
      if (callId) {
        verifyCallSuccess(callId);
      }
    } catch (e) {
      // No need to handle this error
    } finally {
      close();
    }
  };

  const onCallAgain = () => {
    if (prevCallData.current) {
      setCallData(prevCallData.current);
    }

    close();
  };

  return showModal ? (
    <Popup>
      <Container>
        {t("was_call_successful")}
        <YesButton onClick={onSuccessfulCall}>
          {t("buttons.yes")}
          <CheckMark />
        </YesButton>
        <NoButton onClick={onCallAgain}>
          {t("unsuccessful_call")}
          <Arrow />
        </NoButton>
        <CallLaterButton onClick={close}>{t("call_later")}</CallLaterButton>
      </Container>
    </Popup>
  ) : null;
};

const Container = styled.div`
  ${props => props.theme.font.modal.title};
  padding-top: 25px;
  margin: -25px;
  height: 350px;
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.library};
`;

const YesButton = styled(PrimaryButton)`
  margin: 20px auto auto auto;
  width: 243px;
  text-align: center;
  align-content: center;
  justify-content: center;
  & path {
    fill: none;
  }
  & svg {
    width: 18px;
    height: 18px;
  }
`;

const NoButton = styled(SecondaryButton)`
  margin: 20px auto auto auto;
  width: 243px;
`;

const CallLaterButton = styled(TextButton)`
  margin: 20px auto auto auto;
  width: 243px;
`;

const CheckMark = styled(CheckmarkIcon)`
  margin-left: 10px;
  margin-top: 2px;
`;

const Arrow = styled(ArrowRightBlue)`
  overflow: visible;
`;

export default CallConfirmationPopup;
