import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface Response {
  readonly activity_ids: number[];
}

const markAsUnviewed = (patientId: string, activityIds: number[]): Promise<AxiosResponse<Response>> =>
  AxiosInstance.post(`/patients/${patientId}/activities/mark_as_unviewed`, {
    activity_ids: activityIds,
  });

export default markAsUnviewed;
