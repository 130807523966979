import type React from "react";
import { useEffect, useState } from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { MaintenanceImage } from "assets";
import { useStatuspageMaintenance } from "utils/statuspage/hooks";

const ScheduledMaintenance: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isSuccess, maintenanceInProgress } = useStatuspageMaintenance();
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    if (isSuccess && !maintenanceInProgress) {
      navigate("/");
    }
  }, [isSuccess, maintenanceInProgress]);

  useEffect(() => {
    if (data?.scheduled_maintenances?.[0]?.scheduled_until) {
      setEndTime(format(new Date(data.scheduled_maintenances[0].scheduled_until), "p"));
    }
  }, [data]);

  return (
    <Container>
      <Content>
        <ImageContainer>
          <MaintenanceImage />
        </ImageContainer>
        <TextContainer>
          <h1>{t("maintenance.title")}</h1>
          <p>
            {t("maintenance.body", {
              endTime,
            })}{" "}
            <a href={data?.scheduled_maintenances?.[0]?.shortlink}>statuspage</a>.
          </p>
        </TextContainer>
      </Content>
    </Container>
  );
};

export default ScheduledMaintenance;

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.redesign.b10};
`;

const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
  margin-top: 60px;
  text-align: center;
`;

const TextContainer = styled.div`
  text-align: left;

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
`;
