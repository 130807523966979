import { useMutation, useQueryClient } from "react-query";

import type { Appointment } from "api/schemas/Appointment";
import useApiClient from "api/useApiClient";

import { queryKeyPrefix as appointmentsQueryKeyPrefix } from "./useGetAppointments";
import { queryKeyPrefix as patientAppointmentsQueryKeyPrefix } from "./useGetPatientAppointments";

export type PutAppointmentCompleted = {
  appointmentID: number;
};

export const usePutAppointmentCompleted = () => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<Appointment, Error, PutAppointmentCompleted>(args => client.putAppointmentCompleted(args), {
    onSuccess() {
      queryClient.invalidateQueries([appointmentsQueryKeyPrefix]);
      queryClient.invalidateQueries([patientAppointmentsQueryKeyPrefix]);
    },
  });
};
