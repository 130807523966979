const getSectionTitleKey = (name: string): string => `patients.notes.sections.${name}`;
const getSubsectionTitleKey = (name: string): string => `patients.notes.subsections.${name}`;
const getFieldLabelKey = (name: string): string => `patients.notes.fields.${getFieldNameRoot(name)}`;
const getFieldOptionKey = (name: string, option: string): string =>
  `patients.notes.fields.${getFieldNameRoot(name)}_options.${option}`;
const getFieldHelptextKey = (name: string): string => `patients.notes.helptexts.${getFieldNameRoot(name)}`;

// in Phrase App, translation keys for input-label and help-text are based on last portion of the field name
// ex: "structured_fields.goal_pain" => "patients.notes.fields.goal_pain"
const getFieldNameRoot = (name: string): string => name.replace("structured_fields.", "");

export {
  getSectionTitleKey,
  getFieldLabelKey,
  getFieldOptionKey,
  getFieldHelptextKey,
  getFieldNameRoot,
  getSubsectionTitleKey,
};
