import type React from "react";

import Hidden from "shared/atoms/Hidden";
import ModalBase from "shared/atoms/Modal";
import PopupBase from "shared/atoms/Popup";

const ResponsivePopup = ({
  onClose,
  open,
  children,
}: {
  onClose: () => void;
  open: boolean;
  children: React.ReactElement;
}) => {
  return open ? (
    <>
      <Hidden type="belowTablet">
        <PopupBase
          fullBgOpacity
          showCloseButton
          onClickOutside={() => {
            onClose();
          }}
        >
          {children}
        </PopupBase>
      </Hidden>

      <Hidden type="aboveTablet">
        <ModalBase
          open
          close={() => {
            onClose();
          }}
        >
          {children}
        </ModalBase>
      </Hidden>
    </>
  ) : null;
};

export default ResponsivePopup;
