import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { GetSmartRepliesArgs, MessageReplies } from "api/schemas/SmartReply";
import useApiClient from "api/useApiClient";

type QueryKey = ["getSmartMessageReplies", number, number];
const getSmartMessageRepliesQueryKey = ({ therapistId, patientId }: GetSmartRepliesArgs): QueryKey => [
  "getSmartMessageReplies",
  therapistId,
  patientId,
];

const useGetSmartMessageReplies = (
  args: GetSmartRepliesArgs,
  options?: UseQueryOptions<MessageReplies, Error, MessageReplies, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getSmartMessageRepliesQueryKey(args), () => client.getSmartMessageReplies(args), options);
};

export default useGetSmartMessageReplies;
