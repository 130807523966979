import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { CreateReferralVariables, PostMedicalReferralResponse } from "api/types/PostMedicalReferral";
import useApiClient from "api/useApiClient";

const usePostMedicalReferral = (
  options?: UseMutationOptions<PostMedicalReferralResponse, Error, CreateReferralVariables>
): UseMutationResult<PostMedicalReferralResponse, Error, CreateReferralVariables> => {
  const client = useApiClient();

  return useMutation<PostMedicalReferralResponse, Error, CreateReferralVariables>(
    ({ patientId, reason, duration, icdCodes, doctorId }) =>
      client.postMedicalReferral({ patientId, reason, duration, icdCodes, doctorId }),
    options
  );
};

export default usePostMedicalReferral;
