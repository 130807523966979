import type React from "react";
import { useContext, useEffect, useState } from "react";

import { CurrentPatientContext } from "utils/contexts";

import PatientEditStickyNote from "./PatientEditStickyNote";
import PatientViewStickyNote from "./PatientViewStickyNote";

const PatientStickyNote: React.VFC = () => {
  const { patient } = useContext(CurrentPatientContext);
  const [stickyNoteMessage, setStickyNoteMessage] = useState(patient?.note ?? "");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setStickyNoteMessage(patient?.note ?? "");
  }, [patient?.note]);

  return isEditing ? (
    <PatientEditStickyNote
      stickyNoteMessage={stickyNoteMessage}
      setStickyNoteMessage={setStickyNoteMessage}
      setIsEditing={setIsEditing}
    />
  ) : (
    <PatientViewStickyNote setIsEditing={setIsEditing} stickyNoteMessage={stickyNoteMessage} />
  );
};

export default PatientStickyNote;
