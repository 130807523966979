import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

import type { Activity } from "api/models/Activity";
import { generateUrlSuffix, sortActivities } from "routes/patients/utils/activityHelpers";
import { ProtocolContext } from "utils/contexts";
import { useWindowSize } from "utils/hooks";

import CalendarCard from "../CalendarCard";

type Offset =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right"
  | "side-left"
  | "side-right";

interface ProtocolActivityGroupProps {
  activities: Activity[];
  isOverdue?: boolean;
  selected?: number;
}

const ProtocolActivityGroup: React.VFC<ProtocolActivityGroupProps> = ({
  activities,
  isOverdue = true,
  selected = null,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { setSelectedDayMobile } = useContext(ProtocolContext);
  const match = useMatch("*");

  const WIDTH_XS = 334;
  const WIDTH_S = 486;
  const WIDTH_M = 638;
  const WIDTH_L = 790;

  const setWidth = () => {
    if (width > WIDTH_L) return "760px";
    if (width > WIDTH_M) return "608px";
    if (width > WIDTH_S) return "456px";
    if (width > WIDTH_XS) return "304px";
    return "152px";
  };

  const setPlacement = (index: number, length: number): Offset => {
    const positionsThree = ["top-left", "top-center", "top-right"];
    const positionsThreeLarge = ["side-right", "top-center", "side-left"];
    if (width <= WIDTH_XS) return "top-center";
    if (width <= WIDTH_S || (width <= WIDTH_M && length < 3)) return index % 2 === 0 ? "top-left" : "top-right";
    if (length < 3) return index % 2 === 0 ? "side-right" : "side-left";
    if (width <= WIDTH_M) return positionsThree[index % 3] as Offset;
    if (length < 4) return positionsThreeLarge[index % 3] as Offset;
    if (width <= WIDTH_L) return index % 4 < 2 ? "side-right" : "side-left";
    return index % 5 < 2 ? "side-right" : "side-left";
  };

  return (
    <>
      {(!isOverdue && activities.length !== 0) ||
      (isOverdue && activities.filter(activity => activity.required).length !== 0) ? (
        <Container data-testid="overdue" isOverdue={isOverdue}>
          {isOverdue && <Title>{t("patients.protocol.overdue")}</Title>}
          <Activities maxWidth={setWidth()}>
            {isOverdue
              ? activities
                  .filter(activity => activity.required)
                  .sort(sortActivities)
                  .map((activity, index) => (
                    <CalendarCard
                      key={activity.id}
                      openActivity={
                        activity.gqlId
                          ? () => navigate(`${match?.pathname}/${activity.contentType}/${generateUrlSuffix(activity)}`)
                          : undefined
                      }
                      title={activity.name}
                      subtitle={activity.part}
                      type={activity.contentType}
                      completed={activity.completedAt}
                      levels={activity.levels}
                      currentLevel={activity.currentLevel}
                      reply={activity.reply}
                      required={activity.required}
                      comment={activity.comment}
                      commentPlace={setPlacement(index, activities.length)}
                      isPast={activity.isPast}
                      id={activity.id}
                      premium={activity.premium}
                    />
                  ))
              : activities.sort(sortActivities).map((activity, index) => (
                  <CalendarCard
                    key={activity.id}
                    openActivity={
                      activity.gqlId
                        ? () => {
                            if (selected !== null) setSelectedDayMobile(selected);
                            navigate(`${match?.pathname}/${activity.contentType}/${generateUrlSuffix(activity)}`);
                          }
                        : undefined
                    }
                    title={activity.name}
                    subtitle={activity.part}
                    type={activity.contentType}
                    completed={activity.completedAt}
                    levels={activity.levels}
                    currentLevel={activity.currentLevel}
                    reply={activity.reply}
                    required={activity.required}
                    comment={activity.comment}
                    commentPlace={setPlacement(index, activities.length)}
                    isPast={activity.isPast}
                    id={activity.id}
                    premium={activity.premium}
                  />
                ))}
          </Activities>
        </Container>
      ) : null}
    </>
  );
};

const Container = styled.div<{ isOverdue: boolean }>`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: ${props => (props.isOverdue ? `1px solid ${props.theme.colors.greys.silver}` : "none")};
  padding-bottom: ${props => props.theme.spacing.S_10};
`;

const Title = styled.div`
  margin: 0 ${props => props.theme.spacing.S_30};
  margin-top: ${props => props.theme.spacing.S_20};
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.greys.dark};
`;

const Activities = styled.div<{ maxWidth: string }>`
  display: flex;
  flex-wrap: wrap;
  max-width: ${props => props.maxWidth};
  padding-top: ${props => props.theme.spacing.S_20};
  margin: 0 auto;
`;

export default ProtocolActivityGroup;
