import type { ChangeEventHandler, ComponentPropsWithoutRef, FocusEventHandler } from "react";
import type React from "react";
import { forwardRef } from "react";

import styled from "styled-components/macro";

import { StyledInput, StyledLabel } from "shared/atoms/inputs/StyledInputs";
import Caption from "shared/atoms/Typography/Caption";

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 25px;
  width: 300px;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const InputLabel = styled(StyledLabel)`
  max-width: 300px;
  width: 100%;
  ${props => props.theme.belowBreakpoint} {
    max-width: none;
  }
`;

const IconInput = styled(StyledInput)`
  box-sizing: border-box;
  height: 48px;
  padding: 0px 15px 0px 40px;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 14px;
  height: 48px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled(Caption)`
  position: absolute;
  bottom: 0;
  left: 8px;
  color: ${props => props.theme.colors.tomato};
`;

interface LoginInputProps extends ComponentPropsWithoutRef<"input"> {
  autoFocus?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  icon: typeof React.Component | React.FunctionComponent;
  label: string;
  name: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  placeholder?: string;
  type: "email" | "password";
  className?: string;
}

const LoginInput = forwardRef<HTMLInputElement, LoginInputProps>((props, ref) => {
  const {
    autoFocus = false,
    dataTestId = "input-id",
    disabled = false,
    error,
    icon: Icon,
    label = "",
    name = "",
    onBlur,
    onChange,
    placeholder = "",
    className = "",
    type,
  } = props;

  return (
    <InputLabel className={className}>
      {label}
      <Wrapper className="wrapper">
        <IconWrapper>{Icon && <Icon data-testid="icon-id" />}</IconWrapper>
        <IconInput
          autoFocus={autoFocus}
          data-testid={dataTestId}
          error={error}
          type={type}
          name={name}
          placeholder={placeholder}
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && <ErrorText data-testid="validation-error">{error}</ErrorText>}
      </Wrapper>
    </InputLabel>
  );
});

export default LoginInput;
