import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { PremiumType } from "api/models/PatientProfile";
import { SmallBlueClock } from "assets";
import { usePinnedMessagesContext } from "contexts/PinnedMessagesContext";
import { HiddenIcon, StyledAvatar, StyledLi, Wrapper } from "routes/messages/styles/conversation";
import Avatar from "shared/atoms/Avatar";
import PremiumIcon from "shared/atoms/PremiumIcon";
import RelativeDatetime from "shared/atoms/RelativeDatetime";
import Tag from "shared/atoms/Tag";

export interface Props {
  avatar?: string;
  name: string;
  lastMessage: string | null;
  date: string;
  selected?: boolean;
  read: boolean;
  direction: string;
  id: number;
  therapist_assignment_role: string;
  premiumType: PremiumType;
}

const ConversationItem: React.VFC<Props> = ({
  avatar,
  name,
  lastMessage = "",
  date,
  selected = false,
  read,
  direction,
  id,
  therapist_assignment_role,
  premiumType,
}) => {
  const { t } = useTranslation();
  const { pinnedMessageInConversation } = usePinnedMessagesContext();
  const directional = direction === "FROM_THERAPIST" ? t("messages.conversation_list.you_said") : "";
  const noMessage = directional ? t("messages.conversation_list.consultation") : "-";
  let message;
  if (pinnedMessageInConversation(id)?.body) {
    message = pinnedMessageInConversation(id)?.body;
  } else {
    message =
      lastMessage === null || lastMessage === ""
        ? "-"
        : `${directional} ${lastMessage !== null ? lastMessage : noMessage}`;
  }

  const pinnedMessage = !!pinnedMessageInConversation(id)?.body;

  return (
    <StyledLi $selected={selected}>
      <StyledAvatar>
        <Avatar id={id} name={name} src={avatar} size={32} fontSize={14} />
        {therapist_assignment_role === "substitute_therapist" && <OverlayTempIcon />}
      </StyledAvatar>
      <Wrapper>
        <StyledLabel $read={!pinnedMessage && (read || selected)}>
          <PremiumIcon premiumType={premiumType} size="small" margin="0 4px 0 0" />
          {name}
        </StyledLabel>
        <StyledSpan $read={!pinnedMessage && (read || selected)}>{message}</StyledSpan>
        <StyledDate>
          <RelativeDatetime capitalize dateString={date} />
        </StyledDate>
      </Wrapper>
      <HiddenIconWrapper>
        {!pinnedMessage && (read || selected) ? (
          <HiddenIcon className="showOnHover" />
        ) : (
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <NewMessage label={t("common.new.1")} dataTestId="new-message-label" />
        )}
      </HiddenIconWrapper>
    </StyledLi>
  );
};

export default ConversationItem;

const StyledDate = styled.div`
  top: ${({ theme }) => theme.spacing.S_5};
  right: ${({ theme }) => theme.spacing.S_10};
  font-size: 12px;
  color: ${props => props.theme.colors.redesign.db50};
`;

const OverlayTempIcon = styled(SmallBlueClock)`
  margin: auto auto 0 -15px;
`;

const StyledLabel = styled.div<{ $read: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${props => props.theme.colors.primary.base};
`;

const StyledSpan = styled.span<{ $read: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${props => props.theme.colors.redesign.db70};
`;

const NewMessage = styled(Tag)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

const HiddenIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
