import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface Response {
  status: string;
}

const pinMessage = (id: number): Promise<AxiosResponse<Response>> => AxiosInstance.put(`/messages/${id}/pin`);

export default pinMessage;
