import type React from "react";

import { faCircleXmark, faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { css, keyframes } from "styled-components";

import type { UserCourseLesson } from "api/schemas/UserCourse";
import IconButton from "shared/atoms/IconButton";
import Caption from "shared/atoms/Typography/Caption";

import { JointTag } from "./SharedStyledComponents";

interface UserCourseLessonProps {
  lesson: UserCourseLesson;
  onDelete: (id: number) => void;
  newlyAdded?: boolean;
  editable: boolean;
}

const UserCourseExerciseComponent: React.VFC<UserCourseLessonProps> = ({
  lesson,
  onDelete,
  newlyAdded = false,
  editable,
}) => {
  const { id, image_url: imageUrl, parts_count: partsCount, title, target_joints: targetJoints } = lesson;

  const { t } = useTranslation();

  return (
    <Root $newlyAdded={newlyAdded}>
      <Row>
        <div id="tip_draghandle" style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
          {editable && <FontAwesomeIcon icon={faGripDotsVertical} />}
        </div>
        <Fill>
          <Title>{title}</Title>
          <Caption>
            {partsCount} {t("usercourse.parts")}
          </Caption>
          <Tags>
            {targetJoints &&
              targetJoints.map(j => (
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <JointTag key={j}>{t(`patients.pain_location.${j}.0`)}</JointTag>
              ))}
          </Tags>
        </Fill>
        <FlexEnd>{imageUrl && <img height="88" alt="" src={`${imageUrl}`} />}</FlexEnd>
        <Relative>
          <DeleteButtonWrapper>
            {editable && (
              <div id="tip_removelesson">
                <IconButton
                  label=""
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(id);
                  }}
                >
                  <CloseIcon icon={faCircleXmark} />
                </IconButton>
              </div>
            )}
          </DeleteButtonWrapper>
        </Relative>
      </Row>
    </Root>
  );
};

export default UserCourseExerciseComponent;

const Fill = styled.div`
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const newAnimKeyframes = keyframes`
  0% {
    box-shadow: 0px 0px 15px 0px #5096E8;
    transform: scale(1.1);
  }
  5% {
    transform: scale(1);
  }
  75% {
    box-shadow: 0px 0px 15px 0px #5096E8
  }
  100% {
    box-shadow: 0px 2px 12px 0px #0000000d;
  }
`;

const newAnim = css`
  animation: 5s ease-out ${newAnimKeyframes} forwards;
`;

const defaultValues = css`
  box-shadow: 0px 2px 12px 0px #0000000d;
  scale: 1;
`;

const Root = styled.div<{ $newlyAdded: boolean }>`
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  ${props => (props.$newlyAdded ? newAnim : defaultValues)};
  max-height: 200px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: #d4dbe2;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: end;
`;

const Relative = styled.div`
  position: relative;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: -18px;
  top: -8px;
`;

const Tags = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px;
`;
