import { useEffect, useRef } from "react";

import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { BrazeEvents, BrazeService } from "utils/braze";

import { isCypressRunning } from "../../misc/isCypressRunning";

function useAnalytics(userId?: number, userIsPartner?: boolean): void {
  const previousUserIdRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (userId) {
      const userIdString = userId.toString();
      if (userIsPartner) AnalyticsService.track(AnalyticsEvents.LANDING.PARTNER_LOGIN, { userId: userIdString });
      AnalyticsService.track(AnalyticsEvents.LANDING.LOGIN, { userId: userIdString });
      AnalyticsService.alias(userIdString);
      AnalyticsService.identify(userIdString);

      if (!isCypressRunning()) {
        BrazeService.initialize(userIdString);
        BrazeService.track(BrazeEvents.PAGE_LOADED);
      }
    } else if (previousUserIdRef.current) {
      AnalyticsService.reset();
    }

    previousUserIdRef.current = userId;
  }, [userId]);
}

export default useAnalytics;
