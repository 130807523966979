import type React from "react";
import { useEffect, useRef, useState } from "react";

import type { DateSelectArg, DatesSetArg, EventClickArg, EventDropArg } from "@fullcalendar/core";
import svLocale from "@fullcalendar/core/locales/sv";
import dayGridPlugin from "@fullcalendar/daygrid";
import type { EventResizeDoneArg } from "@fullcalendar/interaction";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import useGetAppointments from "api/hooks/useGetAppointments";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { CALENDAR_TIMES } from "routes/calendar/helpers/misc";
import { useWindowSize } from "utils/hooks";
import { userIsAdmin } from "utils/profile/profileHelper";

import { AppointmentDetails, BookingIntervalDetails } from "../EventDetails";
import { FormsContainer } from "../Forms";
import { PersonnelAutocomplete } from "../PersonnelAutocomplete";

import { AddButton } from "./components/AddButton";
import { Filters } from "./components/Filters";
import { renderDayHeaderContent, transformAppointments } from "./helpers";
import { EventContent } from "./helpers/EventContent";
import { transformBookingIntervals } from "./helpers/transformBookingIntervals";
import { useHours } from "./hooks/useHours";

type CalendarView = "timeGridDay" | "timeGridWeek";

export interface SetSelectArgs {
  start: Date;
  end: Date;
}

interface Props {
  className?: string;
  customHeader?: React.ReactNode;
  interactive?: boolean;
  navigateToCalendar?: boolean;
  lockedView?: CalendarView;
  showButtons?: boolean;
  initialDate?: SetSelectArgs;
  customOnSelect?: (serviceID: number | undefined) => void;
}

export const Calendar: React.VFC<Props> = ({
  className,
  customHeader,
  interactive = true,
  navigateToCalendar = false,
  lockedView,
  showButtons = false,
  initialDate,
  customOnSelect,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { isLargeScreen } = useWindowSize();
  const {
    selectedAppointment,
    setSelectedAppointment,
    selectedBookingInterval,
    setSelectedBookingInterval,
    selectedPersonnel,
    setSelectedPatient,
    activeForm,
    setActiveForm,
    selectedDate,
    setSelectedDate,
    showMyAvailability,
    showTherapistAvailability,
    showCanceledAppointments,
    showRescheduledAppointments,
    showWeekends,
  } = useCalendarContext();

  const [showEventDetails, setShowEventDetails] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [view, setView] = useState<"day" | "week">(interactive ? "week" : "day");
  const [calendarView] = useState<CalendarView>(isLargeScreen ? "timeGridWeek" : "timeGridDay");
  const [defaultServiceID, setDefaultServiceID] = useState<number | undefined>();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);

  invariant(profile);

  const isAdmin = userIsAdmin(profile);

  const { openingHoursString, closingHoursString } = useHours();

  const { data, refetch } = useGetAppointments(
    {
      userId: profile.id,
      startDate: encodeURIComponent(startDate),
      view,
      isAdmin,
      onlyTherapistAppointments: !interactive,
    },
    { enabled: !!startDate }
  );

  const appointments = data?.appointments ?? [];
  const bookingIntervals = data?.booking_intervals ?? [];

  const transformedAppointments = transformAppointments({
    appointments,
    isAdmin,
    selectedPersonnel,
    selectedAppointment,
    showCanceledAppointments,
    showRescheduledAppointments,
    ianaTimeZone: profile.iana_timezone,
  });
  const transformedBookingIntervals = transformBookingIntervals({
    bookingIntervals,
    isAdmin,
    selectedPersonnel,
    showMyAvailability,
    showTherapistAvailability,
    unavailableTitle: t("booking.calendar.event_title.blocked_time"),
    userID: profile.id,
    ianaTimeZone: profile.iana_timezone,
  });
  const events = [...transformedAppointments, ...transformedBookingIntervals];

  const onSelect = (args: DateSelectArg) => {
    if (args.jsEvent) {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        const calendarEvents = calendarApi.getEvents();
        const bookingInterval = calendarEvents
          .filter(event => event.extendedProps.type === "booking_interval")
          .find(event => {
            if (event.start && event.end) {
              return event.start <= args.start && event.end >= args.end;
            }
            return false;
          });

        setDefaultServiceID(bookingInterval?.extendedProps.serviceID);
        setSelectedDate({ start: args.start, startStr: args.startStr, end: args.end, endStr: args.endStr });
        if (customOnSelect) {
          customOnSelect(bookingInterval?.extendedProps.serviceID);
        } else if (!activeForm && !selectedBookingInterval) {
          setActiveForm("book_appointment");
        }
      }
    }
  };

  const setSelect = (args: SetSelectArgs | null) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (args) {
        setTimeout(() => {
          calendarApi.gotoDate(args.start);
          calendarApi.select(args);
        }, 0);
      } else {
        calendarApi.unselect();
      }
    }
  };

  useEffect(() => {
    if (initialDate) {
      setSelect(initialDate);
    }
  }, [initialDate]);

  const getHeaderToolbar = () => {
    if (lockedView) {
      return {
        left: "",
        center: "prev next",
        right: "",
      };
    }

    if (interactive) {
      return {
        left: "",
        center: "prev title next",
        right: isLargeScreen ? "timeGridDay,timeGridWeek" : "",
      };
    }

    return false;
  };

  const onDatesSet = (args: DatesSetArg) => {
    const inputDate = args.start;
    const utcEquivalent = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
    const utcDatetimeString = utcEquivalent.toISOString();
    setStartDate(utcDatetimeString);

    if (args.view.type === "timeGridDay") {
      setView("day");
    } else if (args.view.type === "timeGridWeek") {
      setView("week");
    }
  };

  const onEventClick = (args: EventClickArg) => {
    args.jsEvent.preventDefault();

    if (args.event.extendedProps.type === "booking_interval") {
      const clickedbookingInterval = bookingIntervals.find(a => a.id === args.event.extendedProps.bookingIntervalID);
      if (clickedbookingInterval) {
        setSelectedBookingInterval(clickedbookingInterval);
        setSelectedAppointment(null);
        setShowEventDetails(true);
      }
    } else {
      const clickedAppointment = appointments.find(a => a.id === args.event.extendedProps.eventId);
      if (clickedAppointment) {
        setSelectedAppointment(clickedAppointment);
        setSelectedBookingInterval(null);
        setShowEventDetails(true);
      }
    }
  };

  const onEventDrop = (args: EventDropArg | EventResizeDoneArg) => {
    const {
      event: { start, startStr, end, endStr, extendedProps },
    } = args;

    if (extendedProps.type === "appointment") {
      const droppedAppointment = appointments.find(a => a.id === extendedProps.eventId);
      if (droppedAppointment && start && startStr && end && endStr) {
        setSelectedAppointment(droppedAppointment);
        setSelectedDate({
          start,
          startStr,
          end,
          endStr,
        });
        setActiveForm("book_appointment");
      }
    } else if (extendedProps.type === "booking_interval") {
      const droppedBookingInterval = bookingIntervals.find(a => a.id === extendedProps.bookingIntervalID);
      if (droppedBookingInterval && start && startStr && end && endStr) {
        setSelectedBookingInterval(droppedBookingInterval);
        setSelectedDate({
          start,
          startStr,
          end,
          endStr,
        });
        setActiveForm("availability");
      }
    }
  };

  const onWindowResize = () => {
    if (!lockedView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(isLargeScreen ? "timeGridWeek" : "timeGridDay");
    }
  };

  return (
    <Container>
      <CalendarContainer
        className={className}
        $interactive={interactive}
        $decreaseMargin={Boolean(activeForm) || location.pathname.includes("/book")}
      >
        {customHeader}

        {showButtons && isAdmin && <PersonnelAutocomplete />}

        {showButtons && <AddButton />}

        {showButtons && <Filters />}

        <FullCalendar
          ref={calendarRef}
          allDaySlot={false}
          buttonText={{
            day: t("common.day"),
            week: t("common.week_num", { number: undefined }),
          }}
          contentHeight={1000}
          dayHeaderFormat={{ weekday: "short" }}
          dayHeaderContent={renderDayHeaderContent}
          displayEventTime={false}
          editable={interactive}
          events={events}
          eventContent={eventContentArgs => (
            <EventContent eventContentArgs={eventContentArgs} isAdmin={isAdmin} isDayView={view === "day"} />
          )}
          expandRows
          firstDay={1}
          headerToolbar={getHeaderToolbar()}
          initialView={lockedView || (interactive ? calendarView : "timeGridDay")}
          locale={profile.language_code}
          locales={[svLocale]}
          longPressDelay={isLargeScreen ? 1 : 250}
          nowIndicator
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          selectable={interactive}
          select={interactive ? onSelect : undefined}
          selectMirror
          selectConstraint={{
            startTime: openingHoursString || CALENDAR_TIMES.start,
            endTime: closingHoursString || CALENDAR_TIMES.end,
          }}
          slotLabelFormat={{ hour: "numeric", minute: "numeric", omitZeroMinute: false }}
          slotLabelInterval={{ hours: 1 }}
          slotMinTime={openingHoursString || CALENDAR_TIMES.start}
          slotMaxTime={closingHoursString || CALENDAR_TIMES.end}
          titleFormat={{ year: "numeric", month: "long" }}
          unselectAuto={Boolean(activeForm)}
          datesSet={onDatesSet}
          eventClick={onEventClick}
          eventDrop={onEventDrop}
          eventResize={onEventDrop}
          eventOrder={["-type", "availability"]}
          eventOrderStrict
          weekends={showWeekends}
          weekNumbers
          windowResize={interactive ? onWindowResize : undefined}
          eventDidMount={event => {
            if (event.event.extendedProps.boxShadow) {
              // eslint-disable-next-line no-param-reassign
              event.el.style.boxShadow = event.event.extendedProps.boxShadow;
            }
          }}
        />
      </CalendarContainer>

      {selectedAppointment && showEventDetails && (
        <AppointmentDetails
          appointment={selectedAppointment}
          navigateToCalendar={navigateToCalendar}
          onClose={() => {
            setSelectedAppointment(null);
            setShowEventDetails(false);
          }}
          editBooking={() => {
            setSelectedDate(null);
            setActiveForm("book_appointment");
            setShowEventDetails(false);
          }}
          refetchAppointments={refetch}
        />
      )}

      {selectedBookingInterval && showEventDetails && (
        <BookingIntervalDetails
          bookingInterval={selectedBookingInterval}
          navigateToCalendar={navigateToCalendar}
          onClose={() => {
            setSelectedBookingInterval(null);
            setShowEventDetails(false);
          }}
          onEditBookingInterval={() => {
            setSelectedDate(null);
            setActiveForm("availability");
            setShowEventDetails(false);
          }}
          refetchAppointments={refetch}
        />
      )}

      {!navigateToCalendar && (
        <FormsContainer
          defaultServiceID={defaultServiceID}
          onCloseBookingForm={() => {
            setSelectedDate(null);
            setSelectedAppointment(null);
            setSelectedBookingInterval(null);
            setSelectedPatient(null);
            setActiveForm(null);
            setSelect(null);
          }}
          selectedDate={selectedDate}
          refetchAppointments={refetch}
          setSelect={setSelect}
        >
          <Outlet />
        </FormsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const CalendarContainer = styled.div<{ $interactive?: boolean; $decreaseMargin?: boolean }>`
  flex: 1;
  margin: ${({ $interactive, $decreaseMargin }) =>
    // eslint-disable-next-line no-nested-ternary
    $decreaseMargin ? "16px 20px" : $interactive ? "16px 120px" : "auto"};
  margin-bottom: 48px;
  position: relative;

  .fc-view,
  .fc-timegrid-col.fc-day-today {
    background: ${({ theme }) => theme.components.calendar.timegrid_bg_today} !important;
  }

  .fc-daygrid-day.fc-day-today {
    background: ${({ theme }) => theme.components.calendar.daygrid_bg_today};
  }

  .fc-header-toolbar {
    width: calc(100% - 56px);
    justify-content: flex-end;
  }

  .fc-toolbar-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.components.calendar.toolbar_title_color};
  }

  .fc-timegrid-axis-frame {
    justify-content: center;
  }

  .fc-timegrid-slot-label,
  .fc-timegrid-slot-minor {
    border: none;
  }

  .fc-col-header-cell {
    padding: 16px 4px 8px;
  }

  .fc-timegrid-slot-label-cushion {
    padding: 0 12px 0 16px;
  }

  .fc-button-primary {
    padding: 7px 10px;
    color: ${({ theme }) => theme.components.calendar.btn_color};
    background: ${({ theme }) => theme.components.calendar.btn_bg};
    border: 1px solid ${({ theme }) => theme.components.calendar.btn_bg} !important;
    border-radius: 8px;

    :focus,
    :hover,
    :active {
      background: ${({ theme }) => theme.components.calendar.btn_bg_hover};
      box-shadow: none !important;
    }
  }

  .fc-timeGridDay-button,
  .fc-timeGridWeek-button {
    color: ${({ theme }) => theme.components.calendar.btn_bg};
    background: ${({ theme }) => theme.colors.white};
  }

  .fc-button-active {
    background: ${({ theme }) => theme.components.calendar.btn_bg} !important;
  }

  .fc-toolbar-chunk:nth-of-type(2) {
    display: flex;
    align-items: center;
  }

  .fc-next-button,
  .fc-prev-button {
    color: ${({ theme }) => theme.colors.greys.gunmetal};
    background: transparent !important;
    border: none !important;

    :active,
    :hover {
      color: ${({ theme }) => theme.colors.black} !important;
      background: transparent !important;
    }
  }

  .fc-event {
    width: 85%;
    border-width: 2px;
  }

  .fc-event-mirror {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.redesign.b40};
    border-color: ${({ theme }) => theme.colors.redesign.b40};
    opacity: 0.75;
  }

  ${props => props.theme.belowBreakpoint} {
    margin: auto;

    .fc-header-toolbar.fc-toolbar {
      align-items: flex-end;
      flex-direction: column;
      padding: 0;
      margin-bottom: 8px;

      div:first-child {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      & > * > :not(:first-child) {
        margin-left: 0;
      }
    }
  }
`;
