import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface ImageData {
  avatar: {
    large_avatar: {
      url: string;
    };
    medium_avatar: {
      url: string;
    };
    small_avatar: {
      url: string;
    };
  };
}

const updateImage = (
  file: FormData,
  onUploadProgress?: (progressEvent: { loaded: number; total: number }) => void
): Promise<AxiosResponse<ImageData>> =>
  AxiosInstance.post(`/users/avatar`, file, {
    headers: {
      "Content-type": "multipart/form-data",
    },
    onUploadProgress,
  });

export default updateImage;
