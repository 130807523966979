import type { DayHeaderContentArg } from "@fullcalendar/core";
import styled from "styled-components";

export const renderDayHeaderContent = (args: DayHeaderContentArg) => {
  const { date, isToday, text } = args;

  return (
    <DayHeaderContent $isToday={isToday}>
      <div className="day">{text}</div>
      <div className="date">{date.getDate()}</div>
    </DayHeaderContent>
  );
};

const DayHeaderContent = styled.div<{ $isToday: boolean }>`
  .day {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-transform: capitalize;
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    color: ${({ $isToday, theme }) =>
      $isToday ? theme.components.calendar.col_header_color_today : theme.components.calendar.col_header_color};
    background: ${({ $isToday, theme }) =>
      $isToday ? theme.components.calendar.col_header_bg_today : theme.components.calendar.col_header_bg};
    border-radius: 50%;

    font-size: 16px;
    font-weight: 600;
  }
`;
