import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DropdownIcon } from "assets";
import isNonEmptyString from "utils/string/is-non-empty-string";

import { InformationContainer, InformationContainerHeader } from "../PatientHeaderInfo/helpers";

// Paragraphs configuration in the various guides
const paragraphsConfig = {
  pt_on_demand: ["consultation"],
  premium_none: ["offer", "intro", "reimbursement"],
  premium_selfcare: ["subscription", "intro"],
  premium_guided_care: ["subscription", "intro"],
  pt_on_demand_call: [],
};

type PartialPatientResponse = {
  first_name: string;
  preferred_name: string;
};

type PatientHeaderGuide = {
  patient: PartialPatientResponse;
  type: "pt_on_demand" | "premium_none" | "premium_selfcare" | "premium_guided_care" | "pt_on_demand_call";
};

const PatientHeaderGuide: React.VFC<PatientHeaderGuide> = ({ patient, type }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const name = isNonEmptyString(patient.preferred_name) ? patient.preferred_name : patient.first_name;

  return (
    <InformationContainer>
      <InformationContainerHeader onClick={() => setIsOpen(!isOpen)} type="button">
        <div>
          {t(`patient.${type}.header`, { name })} <Emphasize>{t("patient.pt_on_demand.header_emphasize")}</Emphasize>
        </div>
        <IconWrapper $isOpen={isOpen}>
          <DropdownIcon />
        </IconWrapper>
      </InformationContainerHeader>
      <Body $isOpen={isOpen}>
        {paragraphsConfig[type].map(paragraph => (
          <React.Fragment key={`${type}.${paragraph}`}>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Title>{t(`patient.${type}.${paragraph}_header`)}</Title>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Text>{t(`patient.${type}.${paragraph}_text`)}</Text>
          </React.Fragment>
        ))}
        <StepsTitle>{t("patient.pt_on_demand.steps_header")}</StepsTitle>
        <Steps>
          <Step>
            <StepTitle>{t(`patient.${type}.step1_header`)}</StepTitle>
            <StepText>
              <BulletList text={t(`patient.${type}.step1_text`)} />
            </StepText>
          </Step>
          <Step>
            <StepTitle>{t(`patient.${type}.step2_header`)}</StepTitle>
            <StepText>
              <BulletList text={t(`patient.${type}.step2_text`)} />
            </StepText>
          </Step>
        </Steps>
      </Body>
    </InformationContainer>
  );
};

export default PatientHeaderGuide;

const Emphasize = styled.span`
  font-weight: 700;
`;

type IconWrapperProps = {
  $isOpen: boolean;
};

const IconWrapper = styled.div<IconWrapperProps>`
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "none")};
`;

type BodyProps = {
  $isOpen: boolean;
};

const Body = styled.div<BodyProps>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  margin: 0 24px 24px;
`;

const Steps = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 8px 0 24px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  position: relative;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  margin: 8px 0;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin: 6px 0;
`;

const StepsTitle = styled(Title)`
  margin-top: 24px;
`;

const StepTitle = styled(Text)`
  font-weight: 600;
`;

const StepText = styled(Text)``;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 2em;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 6px;
  }
`;

type BulletListProps = {
  separator?: string;
  text: string;
};

const BulletList: React.VFC<BulletListProps> = ({ separator = "\n", text }) => {
  const lines = text.split(separator);

  return (
    <List>
      {lines.map((line: string) => (
        <ListItem key={line}>{line}</ListItem>
      ))}
    </List>
  );
};
