import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getTreatmentActivityLogCategoriesQueryKey = () => ["getTreatmentActivityLogCategories"] as const;

const useGetTreatmentActivityLogCategories = () => {
  const client = useApiClient();

  return useQuery(getTreatmentActivityLogCategoriesQueryKey(), () => client.getTreatmentActivityLogCategories());
};

export default useGetTreatmentActivityLogCategories;
