import type { GetUserCourseResponse, UserCourseExercise } from "api/schemas/UserCourse";

const isSameCourse = (courseA: GetUserCourseResponse, courseB: GetUserCourseResponse) => {
  if (!courseA) {
    return false;
  }
  if (courseA.max_exercises_per_day !== courseB.max_exercises_per_day) {
    return false;
  }
  if (courseA.functional_test !== courseB.functional_test) {
    return false;
  }
  if (courseA.health_form_type !== courseB.health_form_type) {
    return false;
  }

  const savedExercises = courseA.exercises.slice().sort((a, b) => (a.id > b.id ? -1 : 1));
  const currentExercises = courseB.exercises.slice().sort((a, b) => (a.id > b.id ? -1 : 1));

  if (savedExercises.length !== currentExercises.length) {
    return false;
  }

  const diffExercises = savedExercises.some((savedE, index) => {
    const { current_level, introduction_protocol_week, end_protocol_week, id } = savedE;
    const currentE = currentExercises[index];
    return (
      current_level !== currentE.current_level ||
      introduction_protocol_week !== currentE.introduction_protocol_week ||
      end_protocol_week !== currentE.end_protocol_week ||
      id !== currentE.id
    );
  });

  if (diffExercises) {
    return false;
  }

  if (
    ((!courseA.lessons || courseA.lessons.length === 0) && courseB.lessons?.length) ||
    ((!courseB.lessons || courseB.lessons.length === 0) && courseA.lessons?.length)
  ) {
    return false;
  }

  if (courseA.lessons && courseB.lessons) {
    const savedLessons = courseA.lessons.slice();
    const currentLessons = courseB.lessons.slice();

    if (savedLessons.length !== currentLessons.length) {
      return false;
    }

    const diffLessons = savedLessons.some((savedL, index) => {
      const { id } = savedL;
      const currentL = currentLessons[index];
      return id !== currentL.id;
    });

    if (diffLessons) {
      return false;
    }
  }

  return true;
};

export const removeUnchangedLevels = (savedExercises: UserCourseExercise[], currentExercises: UserCourseExercise[]) => {
  const finalExercises = currentExercises.map(currentExercise => {
    const savedExercise = savedExercises.find(e => e.id === currentExercise.id);
    if (savedExercise && savedExercise.current_level === currentExercise.current_level) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { current_level, ...rest } = currentExercise;
      return rest;
    }
    return currentExercise;
  });
  return finalExercises;
};

export default isSameCourse;
