import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import type { PremiumType } from "api/models/PatientProfile";
import { SearchIcon } from "assets";
import { useCalendarContext } from "contexts/CalendarContext";
import { usePinnedMessagesContext } from "contexts/PinnedMessagesContext";
import { useProfileContext } from "contexts/ProfileContext";
import type { Conversation, PinnedMessage } from "routes/messages/types";
import IconButton from "shared/atoms/IconButton";
import Spinner from "shared/atoms/Spinner";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { CurrentPatientContext } from "utils/contexts";
import useUnreadMessagesCount from "utils/contexts/queries/useUnreadMessagesCount";
import useLocalizedDate from "utils/date";
import { useWindowSize } from "utils/hooks";

import BroadcastHeader from "../components/Broadcast/BroadcastHeader";
import BroadcastWindow from "../components/Broadcast/BroadcastWindow";
import ChatNotSelected from "../components/ChatNotSelected";
import ChatWindow from "../components/ChatWindow";
import ConversationHeader from "../components/ConversationHeader";
import ConversationList from "../components/ConversationList";
import ConversationSearch from "../components/ConversationSearch";
import useGetConversations from "../queries/GetConversations";

type ConversationData = {
  id: number;
  avatar?: string;
  name: string;
  last: string | null;
  date: string;
  read: boolean;
  direction: "FROM_PATIENT" | "FROM_THERAPIST";
  therapist_assignment_role: string;
  pinned_messages: PinnedMessage[];
  premium_type: PremiumType;
};

const Messages: React.VFC = () => {
  const { t } = useTranslation();
  const { parseISO } = useLocalizedDate();
  const [conversations, setConversations] = useState<Array<ConversationData>>([]);
  const { patientId } = useParams<{ patientId?: string }>();
  const { height } = useWindowSize();
  const { setPatientId } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const { unreadMessagesCount } = useUnreadMessagesCount();
  const { setPinnedMessages } = usePinnedMessagesContext();
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const isBroadcast = patientId === "broadcast";
  const offsetTop = usePageContentOffsetTop();
  const { showMessagesForm, setShowMessagesForm } = useCalendarContext();

  invariant(profile);

  const { response, error, isLoading, refetch } = useGetConversations(profile.id);
  const fetchedConversations = response?.data;

  useEffect(() => {
    setPatientId(patientId);
    setShowMessagesForm(false);
  }, [patientId]);

  const sortConversations = (conv1: Conversation, conv2: Conversation) => {
    if (conv1.last_message == null) return 1;
    if (conv2.last_message == null) return -1;

    const conv1CreatedAt = conv1.last_message.created_at;
    const conv2CreatedAt = conv2.last_message.created_at;
    const date1 = parseISO(conv1CreatedAt);
    const date2 = parseISO(conv2CreatedAt);
    if (!conv1CreatedAt || date1 < date2) return 1;
    if (!conv2CreatedAt || date1 > date2) return -1;
    return 0;
  };

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, [unreadMessagesCount, refetch]);

  // Observable of data
  useEffect(() => {
    if (!error && !isLoading && fetchedConversations) {
      const pinnedMessagesCollection: PinnedMessage[] = [];

      setConversations(
        fetchedConversations.conversations.sort(sortConversations).map((record: Conversation) => {
          if (record.pinned_messages.length > 0) {
            const pinnedMessages = record.pinned_messages.map(pinnedMessage => {
              return { ...pinnedMessage, peerId: record.peer.id };
            });
            pinnedMessagesCollection.push(...pinnedMessages);
          }

          const lastMessageDirection = record.last_message?.inbound ? "FROM_PATIENT" : "FROM_THERAPIST";
          const { id, avatars, first_name: firstName, last_name: lastName, therapist_assignment_role } = record.peer;
          return {
            id,
            avatar: avatars?.medium ?? undefined,
            name: `${firstName} ${lastName}`,
            last: record.last_message?.body,
            date: record.last_message?.created_at || "",
            read: !record.has_unread_messages,
            direction: lastMessageDirection,
            therapist_assignment_role,
            pinned_messages: record.pinned_messages,
            premium_type: record.peer.premium_type,
          };
        })
      );

      setPinnedMessages(pinnedMessagesCollection);
      setLoading(false);
    }
  }, [fetchedConversations]);

  const closeSearch = () => {
    setSearch(false);
  };

  const patientData = (pId: number) => {
    const patient = conversations
      .map(({ id, name }) => {
        return { id, name };
      })
      .find(element => element.id === pId);
    if (patient === undefined) return { id: pId, name: "" };
    return patient;
  };

  const renderHeaderAndWindow = () => {
    if (isBroadcast) {
      return (
        <>
          <BroadcastHeader />
          <BroadcastWindow />
        </>
      );
    }
    return (
      <>
        <ConversationHeader selectedPatient={patientData(Number(patientId))} />
        <ChatWindow selectedPatient={Number(patientId)} />
      </>
    );
  };

  return (
    <Container
      $pageContentOffsetTop={offsetTop}
      $transition={!!patientId || search}
      $vh={height}
      $showMessagesForm={showMessagesForm}
    >
      <StyledDiv>
        <StyledHeader>
          {t("messages.conversation_list.recent")}
          <IconButton label={t("messages.conversation_list.search_patient")} onClick={() => setSearch(true)}>
            <SearchIcon />
          </IconButton>
        </StyledHeader>
        {loading || error ? (
          <LoadingContainer>{error ? t("errors.generic") : <Spinner small />}</LoadingContainer>
        ) : (
          <ConversationList
            conversations={conversations}
            selectedId={Number(patientId)}
            updateConversations={index =>
              setConversations(conv => {
                return conv.map((el, ind) => {
                  // eslint-disable-next-line no-param-reassign
                  if (ind === index) el.read = true;
                  return el;
                });
              })
            }
          />
        )}
      </StyledDiv>
      <ChatContainer>
        {search ? (
          <ConversationSearch
            patientData={conversations.map(element => {
              return { id: element.id, name: element.name };
            })}
            onSelect={() => closeSearch()}
          />
        ) : (
          <>{patientId ? renderHeaderAndWindow() : <ChatNotSelected />}</>
        )}
      </ChatContainer>
    </Container>
  );
};

type ContainerProps = {
  $pageContentOffsetTop: number;
  $transition: boolean;
  $vh: number;
  $showMessagesForm: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  ${props => props.theme.belowBreakpoint} {
    width: 200%;
    transition: transform 700ms;
    transform: ${props => (props.$transition ? "translateX(-50%)" : "translateX(0)")};
  }
  height: calc(${props => (props.$vh ? `${props.$vh}px` : "100vh")} - ${props => props.$pageContentOffsetTop}px);
  max-height: calc(100vh - ${props => props.$pageContentOffsetTop}px);
  width: ${({ $showMessagesForm }) => ($showMessagesForm ? "calc(100% - 400px)" : "100%")};
`;

const ChatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.greys.light2};
  ${props => props.theme.belowBreakpoint} {
    width: 50%;
    max-width: unset;
    box-sizing: border-box;
  }
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const StyledDiv = styled.div`
  width: 320px;
  min-width: 320px;
  overflow-x: auto;
  height: 100%;
  ${props => props.theme.belowBreakpoint} {
    width: 50%;
    box-sizing: border-box;
    min-width: unset;
  }
  ${props => props.theme.aboveBreakpoint} {
    background-color: ${props => props.theme.colors.white};
    border-left: 1px solid ${props => props.theme.colors.greys.silver};
    border-right: 1px solid ${props => props.theme.colors.greys.silver};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-shrink: 0;
  width: 320px;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
  height: calc(100% - 52px);
  ${props => props.theme.font.body2};
  color: ${props => props.theme.colors.greys.warm};
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${props => props.theme.spacing.S_20};
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  ${props => ({ ...props.theme.font.header3 })};
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.primary.base};
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

export default Messages;
