import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPatientIcdCodesArgs, GetPatientIcdCodesResponse } from "api/types/GetPatientIcdCodes";
import useApiClient from "api/useApiClient";

export type UseGetPatientIcdCodesQueryArgs = GetPatientIcdCodesArgs | null;

type QueryKey = ["useGetPatientIcdCodesQuery", UseGetPatientIcdCodesQueryArgs];

export const getPatientIcdCodesQueryKey = (args: UseGetPatientIcdCodesQueryArgs): QueryKey => [
  "useGetPatientIcdCodesQuery",
  args,
];

export const useGetPatientIcdCodesQuery = (
  args: UseGetPatientIcdCodesQueryArgs,
  options?: UseQueryOptions<GetPatientIcdCodesResponse, Error, GetPatientIcdCodesResponse, QueryKey>
): UseQueryResult<GetPatientIcdCodesResponse, Error> => {
  const client = useApiClient();

  return useQuery(
    getPatientIcdCodesQueryKey(args),
    () => {
      if (!args) {
        return Promise.reject(new Error("Invalid query arguments"));
      }
      return client.getPatientIcdCodes(args);
    },
    options
  );
};
