import { useTranslation } from "react-i18next";

import type { Insurance } from "../insuranceHelper";

const formatInsuranceCompanyName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const useSecondaryInsuranceInfo = (insuranceData: Insurance): string | null => {
  const { t } = useTranslation();

  if (insuranceData?.insurance_found && insuranceData?.data?.secondary_payer) {
    const secondaryPayer = insuranceData?.data?.secondary_payer;

    if (secondaryPayer) {
      const insuranceCompanyName = formatInsuranceCompanyName(secondaryPayer.insurance_company_name);
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const insuranceStatus = t(`patients.header_info.insurance_status.${secondaryPayer?.status}`);
      return `${insuranceCompanyName} (${insuranceStatus})`;
    }
  }

  return null;
};

export default useSecondaryInsuranceInfo;
