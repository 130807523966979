import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { GetPatientAvailableJointsArgs, PatientAvailableJointsResponse } from "api/schemas/ManagerBooking";
import useApiClient from "api/useApiClient";

type QueryKey = ["getPatientAvailableJoints", number, number];
export const getPatientAvailableJointsQueryKey = (args: GetPatientAvailableJointsArgs): QueryKey => [
  "getPatientAvailableJoints",
  args.managerId,
  args.patientId,
];

const useGetPatientAvailableJoints = (
  args: GetPatientAvailableJointsArgs,
  options?: UseQueryOptions<PatientAvailableJointsResponse, Error, PatientAvailableJointsResponse, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(
    getPatientAvailableJointsQueryKey(args),
    () => client.getPatientAvailablePainfulJoints(args),
    options
  );
};

export default useGetPatientAvailableJoints;
