import type { Activity, ActivityTypes } from "api/models/Activity";

const handleType = (type: string): ActivityTypes => {
  switch (type) {
    case "weekly_health_report":
    case "w1_health_report":
    case "followup_health_report":
    case "goal":
      return "report";
    case "text_lesson":
      return "lesson";
    case "exercise":
    case "functionality":
      return type;
    case "subscription":
    case "update_payment_method":
      return "payment";
    default:
      return "payment";
  }
};
const generateUrlSuffix = (activity: Activity): string => {
  if (activity.contentType === "exercise") return `Exercise::${activity.gqlId}`;
  if (activity.contentType === "lesson") return `TextLesson::${activity.gqlId}`;
  if (activity.contentType === "functionality") return `FunctionalityTest::${activity.gqlId}`;
  return `${activity.gqlId}`;
};
const getTextLessonPart = (activityName: string): string => {
  return activityName.substring(activityName.lastIndexOf("_") + 1);
};

const isTranslatedActivity = (type: string): boolean => {
  return type === "exercise" || type === "functionality" || type === "text_lesson";
};

const sortActivities = (a: Activity, b: Activity): number => {
  const TYPES = ["payment", "report", "functionality", "lesson", "exercise"];
  const x = TYPES.indexOf(a.contentType);
  const y = TYPES.indexOf(b.contentType);
  if (x === y) {
    return a.completedAt === null ? 1 : -1;
  }
  return x < y ? -1 : 1;
};

export { getTextLessonPart, isTranslatedActivity, handleType, sortActivities, generateUrlSuffix };
