import { useMutation, useQueryClient } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import useApiClient from "api/useApiClient";

import { queryKeyPrefix as appointmentsQueryKeyPrefix } from "./useGetAppointments";
import { queryKeyPrefix as patientAppointmentsQueryKeyPrefix } from "./useGetPatientAppointments";

export type PostAppointmentNoShow = {
  appointmentID: number;
  hcProfID: number;
};

export const usePostAppointmentNoShow = () => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<NewAppointment, Error, PostAppointmentNoShow>(args => client.postAppointmentNoShow(args), {
    onSuccess() {
      queryClient.invalidateQueries([appointmentsQueryKeyPrefix]);
      queryClient.invalidateQueries([patientAppointmentsQueryKeyPrefix]);
    },
  });
};
