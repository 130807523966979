import { useEffect, useState } from "react";

import styled from "styled-components";

import { BroadcastIcon, DefaultAvatar } from "assets";
import theme from "theme";

import Spinner from "../Spinner";

const StyledImg = styled.img<{ size: number }>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
  border-radius: ${props => props.theme.borderRadius.circular};
`;

const StyledDiv = styled.div<{ size: number; fontSize: number; color?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
  border-width: 2px;
  border-style: solid;
  border-radius: ${props => props.theme.borderRadius.circular};
  border-color: ${props => props.color || props.theme.colors.primary.base};
  text-align: center;
  overflow: hidden;
  user-select: none;
  & span {
    font-weight: 500;
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color || props.theme.colors.primary.base};
  }
`;

const getColor = (id: number): string => {
  return theme.colors.avatar_colors[id % theme.colors.avatar_colors.length];
};

const getInitials = (str: string): string[] | string => {
  if (typeof str !== "string") {
    return "";
  }
  const words = str.split(" ");
  return words.slice(0, 2).map(word => word.substr(0, 1).toUpperCase());
};

interface AvatarProps {
  id?: number;
  name?: string;
  src?: string;
  size?: number;
  color?: string;
  fontSize?: number;
  className?: string;
  broadcast?: boolean;
  loading?: boolean;
  onLoad?: () => void;
}

const Avatar = ({
  src = "",
  name = "",
  size = 50,
  color = "",
  fontSize = 18,
  id = 1,
  className = "",
  broadcast = false,
  loading = false,
  onLoad = () => undefined,
}: AvatarProps): JSX.Element => {
  const [currentSrc, setSrc] = useState<string>();
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingStyle, setLoadingStyle] = useState({});

  useEffect(() => {
    if (!src) {
      setSrc(src);
      setLocalLoading(loading);
    } else if (src !== currentSrc) {
      setLocalLoading(true);
      setSrc(src);
    } else {
      setLocalLoading(localLoading || loading);
    }
  }, [src, loading, currentSrc, localLoading]);

  useEffect(() => {
    setLoadingStyle(localLoading ? { opacity: "50%" } : {});
  }, [localLoading]);

  const imageLoaded = (success = true) => {
    if (!success) {
      setSrc(undefined);
    }
    signalAsLoaded();
  };

  const signalAsLoaded = (externalSignal = true) => {
    if (localLoading === true) {
      setLocalLoading(false);
      if (externalSignal) {
        onLoad();
      }
    }
  };

  const getAvatar = (providedSrc?: string) => {
    if (providedSrc) {
      return (
        <StyledImg
          alt={name}
          src={providedSrc}
          size={size}
          className={className}
          style={loadingStyle}
          onLoad={() => imageLoaded()}
          onError={() => imageLoaded(false)}
        />
      );
    }

    if (name?.trim()) {
      return (
        <StyledDiv
          fontSize={fontSize}
          color={color || getColor(id)}
          size={size}
          className={className}
          style={loadingStyle}
        >
          <span>{getInitials(name)}</span>
        </StyledDiv>
      );
    }

    if (broadcast) {
      return (
        <StyledDiv
          style={{
            position: "absolute",
            backgroundColor: theme.colors.broadcast.accent,
            ...loadingStyle,
          }}
          size={size}
          fontSize={fontSize}
          color={theme.colors.broadcast.accent}
          className={className}
        >
          <BroadcastIcon style={{ margin: "auto" }} />
        </StyledDiv>
      );
    }

    return <DefaultAvatar className={className} style={loadingStyle} />;
  };

  return (
    <StyledDiv style={{ display: "flex", alignItems: "center", borderWidth: 0 }} size={size} fontSize={fontSize}>
      {getAvatar(currentSrc)}
      {localLoading ? (
        <>
          <StyledDiv
            style={{ position: "absolute", display: "flex", alignItems: "center", borderWidth: 0, margin: 0 }}
            size={size}
            fontSize={fontSize}
          >
            <Spinner small style={{ height: "50%", width: "50%" }} />
          </StyledDiv>
        </>
      ) : null}
    </StyledDiv>
  );
};

export default Avatar;
