import styled from "styled-components";

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  position: relative;
  border: 1px solid ${props => props.theme.colors.greys.silver};
  margin-bottom: ${props => props.theme.spacing.S_30};
`;

const BlueLine = styled.div`
  min-width: 8px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  position: absolute;
  left: 0;
  background-color: ${props => props.theme.colors.primary.base};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${props => props.theme.colors.primary.base};
`;

const Title = styled.div<{ $message?: boolean; $schedule?: boolean }>`
  ${props => props.theme.font.header4}
  padding: ${props => props.theme.spacing.S_10} ${props =>
    props.$schedule ? props.theme.spacing.S_15 : props.theme.spacing.S_40};
  ${props => !props.$message && `padding-left: ${props.theme.spacing.S_20};`};
  padding-right: ${props => props.theme.spacing.S_15};
  display: flex;
  flex-direction: row;
  min-height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
`;

const Body = styled.div<{ $center?: boolean }>`
  ${props => props.theme.font.body1}
  display: flex;
  flex-direction: column;
  align-items: ${({ $center }) => ($center ? "center" : "stretch")};
  justify-content: flex-start;
  padding: 10px 16px;
`;

export { Body, Container, Content, BlueLine, Title };
