import type React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { JointAcademyLogo, LogoIcon } from "assets";
import Row from "shared/atoms/Row/Row";

interface Props {
  fullLogo?: boolean;
  className?: string;
}

const Logo: React.VFC<Props> = ({ fullLogo, className }) => {
  return (
    <LogoWrapper className={className}>
      <Link to="/dashboard">{fullLogo ? <StyledJointAcademyLogo /> : <StyledLogo />}</Link>
    </LogoWrapper>
  );
};

export default Logo;

const LogoWrapper = styled(Row)`
  align-items: center;
  flex: 0;
`;

const StyledLogo = styled(LogoIcon)`
  max-width: 32px;
  max-height: 40px;
  margin: 12px;
`;

const StyledJointAcademyLogo = styled(JointAcademyLogo)`
  max-width: 180px;
  margin-top: 12px;
  margin-left: 16px;
`;
