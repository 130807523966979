import { t } from "i18next";
import styled from "styled-components";

import ResponsivePopup from "./ResponsivePopup";
import { SmallPrimaryButton, SmallSecondaryButton } from "./SharedStyledComponents";

const YesNoPopup = ({
  onClose,
  onYes,
  onNo,
  headerText,
  bodyText,
}: {
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
  headerText?: string;
  bodyText?: string;
}) => {
  return (
    <ResponsivePopup onClose={onClose} open>
      <PopupRoot>
        <Header>{headerText}</Header>
        <div>
          <div>{bodyText}</div>
        </div>
        <Pair>
          <SmallPrimaryButton onClick={onYes}>{t("buttons.yes")}</SmallPrimaryButton>
          <SmallSecondaryButton onClick={onNo}>{t("buttons.no")}</SmallSecondaryButton>
        </Pair>
      </PopupRoot>
    </ResponsivePopup>
  );
};

export default YesNoPopup;

const PopupRoot = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Pair = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
`;
