import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { HcpServiceManager } from "api/schemas/HcpService";
import useApiClient from "api/useApiClient";

type QueryKey = ["getManagerServices", number];
export const getManagerServicesQueryKey = (managerId: number): QueryKey => ["getManagerServices", managerId];

const useGetManagerServices = (
  managerId: number,
  options?: UseQueryOptions<HcpServiceManager[], Error, HcpServiceManager[], QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getManagerServicesQueryKey(managerId), () => client.getManagerServices(managerId), options);
};

export default useGetManagerServices;
