/* eslint-disable react-hooks/rules-of-hooks */
import type React from "react";

import styled from "styled-components";
import type { RemoteParticipant as RemoteParticipantType } from "twilio-video";

import DisabledPublicationsOverlay from "../DisabledPublicationsOverlay";
import usePublications from "../video/hooks/usePublications";

import RemotePublication from "./RemotePublication";

const RemoteParticipantScreen = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

interface Props {
  readonly minimised: boolean;
  readonly participant?: RemoteParticipantType | null;
  readonly username: string;
}

const RemoteParticipant: React.VFC<Props> = ({ minimised, participant = null, username }) => {
  if (participant) {
    const publications = usePublications(participant);

    return (
      <RemoteParticipantScreen>
        {publications.map(publication => (
          <RemotePublication key={`${publication.kind}_${publication.trackSid}`} publication={publication} />
        ))}
        <DisabledPublicationsOverlay minimised={minimised} publications={publications} username={username} />
      </RemoteParticipantScreen>
    );
  }
  return null;
};

export default RemoteParticipant;
