import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { DeleteUserCourseArgs } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const useDeleteUserCourseTemplate = (
  options?: UseMutationOptions<null, Error, DeleteUserCourseArgs>
): UseMutationResult<null, Error, DeleteUserCourseArgs> => {
  const client = useApiClient();
  return useMutation<null, Error, DeleteUserCourseArgs>(({ therapistId, templateId }) => {
    return client.deleteUserCourseTemplate(therapistId, templateId);
  }, options);
};
