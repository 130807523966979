import type { ValidLocale } from "types/validLocale";
import { getStorageValue, setStorageValue } from "utils/storage";

const getBestLng = (profileLocale?: ValidLocale): ValidLocale => {
  const envLocale = process.env.REACT_APP_LOCALE as ValidLocale;
  const storedLocale = getStorageValue("language");

  if (profileLocale) {
    if (!storedLocale) {
      setStorageValue("language", profileLocale);
    }

    if (storedLocale && storedLocale !== profileLocale) {
      setStorageValue("language", profileLocale);
    }

    return profileLocale;
  }

  if (storedLocale) {
    return storedLocale as ValidLocale;
  }

  if (envLocale) {
    setStorageValue("language", envLocale);
    return envLocale;
  }

  // Return hardcoded as fail safe
  return "en-US";
};

export default getBestLng;
