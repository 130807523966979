import type { TFunction } from "i18next";

export const calculateFesScore = (answer: Record<string, number> | undefined, t: TFunction) => {
  if (!answer) {
    return "";
  }

  const score =
    answer.dressing +
    answer.showering +
    answer.chair_sit_stand +
    answer.stairs +
    answer.reach +
    answer.slope +
    answer.social;

  switch (true) {
    case score <= 9:
      return `${score} ${t("patients.health_reports.questions_content.fes_score.low_concern")}`;
    case score <= 13:
      return `${score} ${t("patients.health_reports.questions_content.fes_score.moderate_concern")}`;
    case score <= 28:
      return `${score} ${t("patients.health_reports.questions_content.fes_score.high_concern")}`;
    default:
      return score;
  }
};
