import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetTherapistEarningsResponse } from "api/types/GetTherapistEarnings";
import useApiClient from "api/useApiClient";
import useLocalizedDate from "utils/date";

type UseGetTherapistEarningsArgs = {
  date: Date;
  therapistId: number;
};
type QueryKey = [string, UseGetTherapistEarningsArgs];

export const getTherapistEarningsQueryKey = (args: UseGetTherapistEarningsArgs): QueryKey => [
  "GetTherapistEarningsQuery",
  args,
];

export const useGetTherapistEarnings = (
  args: UseGetTherapistEarningsArgs,
  options?: UseQueryOptions<GetTherapistEarningsResponse, Error, GetTherapistEarningsResponse, QueryKey>
): UseQueryResult<GetTherapistEarningsResponse, Error> => {
  const { date, therapistId } = args;
  const client = useApiClient();
  const { format, formats } = useLocalizedDate();

  return useQuery(
    getTherapistEarningsQueryKey(args),
    () =>
      client.getTherapistEarnings({
        date: format(date, formats.ISO_DATE),
        therapistId,
      }),
    options
  );
};
