import type React from "react";
import { useState } from "react";

import { Route, Routes } from "react-router-dom";

import Container from "shared/atoms/Container";
import { LibraryContext } from "utils/contexts";

import Activity from "./views/Activity";
import Library from "./views/Library";

export const activityRoutes = () => {
  return (
    <Routes>
      <Route path="lesson/:activityID" element={<Activity activity="lesson" />} />
      <Route path="exercise/:activityID" element={<Activity activity="exercise" />} />
      <Route path="functionality/:activityID" element={<Activity activity="functionality" />} />
    </Routes>
  );
};

const LibraryRoutes: React.VFC = () => {
  const [category, setCategory] = useState("library.show_all");
  const [search, setSearch] = useState("");
  const [jointFilter, setJointFilter] = useState("hip");

  return (
    <LibraryContext.Provider value={{ category, setCategory, jointFilter, setJointFilter, search, setSearch }}>
      <Container $pageContentOffsetTop={0}>
        {activityRoutes()}
        <Library />
      </Container>
    </LibraryContext.Provider>
  );
};

export default LibraryRoutes;
