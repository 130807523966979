import type React from "react";

import styled from "styled-components";

import ActivityIcon from "shared/atoms/ActivityIcon";
import ActivityLevels from "shared/atoms/ActivityLevels";

interface Props {
  readonly subtitle?: string | null;
  readonly title: string;
  readonly levels?: {
    current: number;
    max: number;
  } | null;
  readonly size?: "small" | "large";
  readonly type?: "exercise" | "lesson" | "functionality" | "report" | "payment" | null;
}

const ActivityTitle: React.VFC<Props> = ({ title, subtitle, levels = null, size = "large", type = null }) => {
  const renderTitle = () => (
    <Container size={size} data-testid="activity-title">
      <Title size={size}>
        {title}
        {type === "exercise" && levels && (
          <ActivityLevels size={size} levels={levels.max} currentLevel={levels.current} paddingLeft="5px" />
        )}
      </Title>
      {subtitle && <Subtitle size={size}>{subtitle}</Subtitle>}
    </Container>
  );

  if (size === "small") {
    return (
      <Activity>
        <ActivityIcon type={type} />
        {renderTitle()}
      </Activity>
    );
  }
  return renderTitle();
};

export default ActivityTitle;

const Activity = styled.div`
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  padding-bottom: ${props => props.theme.spacing.S_15};
`;

const Container = styled.div<Pick<Props, "size">>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  ${props => props.size === "small" && `margin-left: ${props.theme.spacing.S_10}`};
  color: ${props => props.theme.colors.primary.base};
`;

const Title = styled.div<Pick<Props, "size">>`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 1.4em;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
`;

const Subtitle = styled.div<Pick<Props, "size">>`
  ${props => (props.size === "small" ? props.theme.font.caption : props.theme.font.header5)}
  color: ${props => props.theme.colors.redesign.db60};
  margin-top: 8px;
`;
