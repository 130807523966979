import styled from "styled-components";

import { Chevron } from "assets";

export const StyledLi = styled.li<{ $selected: boolean }>`
  display: flex;
  position: relative;
  min-width: 300px;
  padding: 12px;
  background-color: ${props => (props.$selected ? props.theme.colors.redesign.b10 : props.theme.colors.white)};
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  font-size: 14px;
  line-height: 1.5em;
  cursor: pointer;

  & .showOnHover {
    visibility: ${props => (props.$selected ? "visible" : "hidden")};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colors.greys.light4};

      .showOnHover {
        visibility: visible;
      }
    }
  }
`;

export const Wrapper = styled.div`
  margin-left: 12px;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 32px;
`;

export const HiddenIcon = styled(Chevron)`
  visibility: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 24px;
`;
