import type React from "react";
import { useEffect, useState } from "react";

import type { FieldErrors, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MUIDropdown } from "shared/atoms/inputs";

import type { FormData } from "./Forms/BookAppointmentForm";

interface Props {
  options: Option[];
  selectedStartTime: number | undefined;
  selectedEndTime?: number | undefined;
  errors: FieldErrors;
  duration?: number | undefined;
  // TODO: Fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<FormData | any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<FormData | any>;
}

type Option = {
  label: string;
  value: number;
};

const AppointmentInterval: React.VFC<Props> = ({
  options,
  selectedStartTime,
  selectedEndTime,
  errors,
  duration = 30,
  setValue,
  getValues,
}) => {
  const { t } = useTranslation();

  const [startTime, setStartTime] = useState(selectedStartTime);
  const [updatedDuration, setUpdatedDuration] = useState<number | null>(null);

  const handleStartChange = () => {
    const currentStart = getValues("start_time");
    const currentEnd = getValues("end_time");

    setStartTime(currentStart);
    if (currentStart > currentEnd) {
      setValue("end_time", currentStart);
    }
    // preselect end_time if not set, based on duration
    const lastOption = options.at(-1)?.value ?? 0;

    if (updatedDuration) {
      if (currentStart + updatedDuration > lastOption) {
        setValue("end_time", lastOption);
      } else {
        setValue("end_time", currentStart + updatedDuration);
      }
    } else if (currentStart + duration > lastOption) {
      setValue("end_time", lastOption);
    } else {
      setValue("end_time", currentStart + duration);
    }
  };

  const handleEndChange = () => {
    const currentStart = getValues("start_time");
    const currentEnd = getValues("end_time");

    setUpdatedDuration(currentEnd - currentStart);
  };

  // update time when the event calendar is clicked
  useEffect(() => {
    if (selectedStartTime) {
      setValue("start_time", selectedStartTime);
      if (updatedDuration) {
        setValue("end_time", selectedStartTime + updatedDuration);
      } else {
        setValue("end_time", selectedStartTime + duration);
      }
    }
    if (selectedEndTime) {
      setValue("end_time", selectedEndTime);
    }
    setStartTime(selectedStartTime);
  }, [selectedStartTime, selectedEndTime]);

  // update time when a new service having a different duration is selected
  useEffect(() => {
    if (startTime && duration) {
      setValue("start_time", startTime);
      setValue("end_time", startTime + duration);
    }
    setStartTime(startTime);
  }, [duration]);

  // end_time options to be bigger than start_time
  // and after 60mins difference to be only every half an hour
  const filteredOptions = options.filter(option => (startTime ? option.value > startTime : option.value));

  // if startTime is set, create end_time custom options with custom labels
  const customOptions = filteredOptions.map(option => {
    let customLabel: string | undefined;
    if (startTime) {
      if (option.value - startTime > 60) {
        const num = (option.value - startTime) / 60;
        if (num % 1 === 0 || num % 1 === 0.25 || num % 1 === 0.5 || num % 1 === 0.75) {
          customLabel = `(${(option.value - startTime) / 60} ${t("booking.form.hours_short")})`;
        }
      } else {
        customLabel = `(${option.value - startTime} ${t("booking.form.minutes_short")})`;
      }
    }

    return {
      value: option.value,
      label: option.label,
      customLabel,
    };
  });

  return (
    <Container>
      <MUIDropdown
        label={t("booking.form.start_time")}
        name="start_time"
        options={options}
        onChange={() => handleStartChange()}
        disabled={options.length === 0}
        minWidth={85}
        required
        error={errors.start_time && t("errors.field.required")}
      />
      <MUIDropdown
        label={t("booking.form.end_time")}
        name="end_time"
        options={startTime ? customOptions : filteredOptions}
        onChange={() => handleEndChange()}
        disabled={options.length === 0}
        minWidth={85}
        required
        error={errors.end_time && t("errors.field.required")}
      />
    </Container>
  );
};

export default AppointmentInterval;

const Container = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;
