import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CloseIcon } from "assets";

import PatientSearchResults from "../PatientSearchResults";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.base};
  padding: 0px ${props => props.theme.spacing.S_20};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${props => props.theme.font.header4}
`;

const SearchGroup = styled(HeaderGroup)`
  ${props => props.theme.font.caption}
  margin-bottom: ${props => props.theme.spacing.S_10};
`;

const SearchInput = styled.input`
  outline: 0;
  margin-left: ${props => props.theme.spacing.S_15};
  border-width: 0 0 2px;
  border-color: ${props => props.theme.colors.greys.silver};
  color: ${props => props.theme.colors.primary.base};
  ${props => props.theme.font.caption}
  &:focus {
    border-color: ${props => props.theme.colors.secondary.base};
  }
`;

interface Props {
  onSelect: () => void;
  patientData: Array<{
    id: number;
    name: string;
  }>;
}

const ConversationSearch: React.VFC<Props> = ({ patientData, onSelect }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [filteredPatients, setFilteredPatients] = useState(patientData);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 700);
  }, []);

  useEffect(() => {
    const searchPatients = () => {
      return patientData.filter(patient => patient.name.toLowerCase().search(value.trim().toLowerCase()) !== -1);
    };
    setFilteredPatients(searchPatients());
  }, [value, patientData]);

  const closeSearch = () => {
    setValue("");
    onSelect();
  };

  return (
    <>
      <Container data-testid="convo-search">
        <Header>
          <HeaderGroup>{t("messages.conversation_list.search_patient")}</HeaderGroup>
          <CloseButton onClick={() => closeSearch()}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <SearchGroup>
          {t("messages.patient_name")}
          <SearchInput ref={inputRef} value={value} onChange={event => setValue(event.target.value)} />
        </SearchGroup>
      </Container>
      <PatientSearchResults
        patientData={filteredPatients}
        open={value.trim().length !== 0}
        handleSelect={() => closeSearch()}
      />
    </>
  );
};

export default ConversationSearch;
