import type React from "react";

import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

interface Props {
  readonly className?: string;
  readonly label?: string;
  readonly name: string;
}

export const Textbox: React.VFC<Props> = ({ className, label = "", name }) => {
  const { control } = useFormContext();

  return (
    <Container className={className}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div {...field}>
            <Header>{label}</Header>
            <Text>{field.value ?? ""}</Text>
          </div>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.spacing.S_5};
  border-radius: 8px;
  border-width: 2px;
  flex-direction: column;
  position: relative;
  padding: 23px 12px 7px;
  color: ${props => props.theme.colors.greys.bluish};
  background-color: ${props => props.theme.colors.redesign.disabled_grey};
`;
const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;
const Header = styled.label`
  color: ${props => props.theme.colors.redesign.db60};
  letter-spacing: 0.00938em;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  top: 8px;
  left: 8px;
  position: absolute;
`;

export default Textbox;
