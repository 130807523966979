import type { MutableRefObject } from "react";
import { useLayoutEffect, useRef, useState } from "react";

import ResizeObserver from "resize-observer-polyfill";

const useHeight = <T extends HTMLElement>(): [MutableRefObject<T | null>, number] => {
  const ref = useRef<T>(null);
  const [height, setHeight] = useState(0);
  const heightRef = useRef(height);
  const [ro] = useState(() => {
    return new ResizeObserver(() => {
      if (ref.current && heightRef.current !== ref.current.offsetHeight) {
        heightRef.current = ref.current.offsetHeight;
        setHeight(ref.current.offsetHeight);
      }
    });
  });
  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
      ro.observe(ref.current);
    }
    return () => ro.disconnect();
  }, [ref.current]);
  return [ref, height];
};

export default useHeight;
