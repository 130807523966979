import { z } from "zod";

export const ActivityTypeSchema = z.enum(["TextLesson", "Exercise", "FunctionalityTest"]);
export type ActivityType = z.infer<typeof ActivityTypeSchema>;

interface Content {
  id: string;
  type: string;
  content: string;
}

export interface ActivityLibraryEntryShow {
  id: string;
  type: ActivityType;
  title: string;
  variation_title: string | null;
  image_url: string | null;
  order: number | null;
  parts_count: number | null;
  level: number | null;
  max_level: number | null;
  reps: string | null;
  caption_file_url: string | null;
  video_file_url: string;
  video_thumbnail_file_url: string;
  instructions_icon_name: "repetitions" | "timer";
  instructions_text1: string | null;
  instructions_text2: string;
  instructions_text3: string | null;
  tip_title: string;
  tip_description: string;
  about: string | null;
  content: Content[];
  premium: boolean | null;
}
