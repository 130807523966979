import type React from "react";
import { useEffect } from "react";

import { useMatch, useRoutes } from "react-router-dom";

import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import { AdminBookingForm } from "./components/AdminBookingForm";
import { CalendarView } from "./views/CalendarView";
import { ServicesView } from "./views/ServicesView";

const CalendarRoutes: React.VFC = () => {
  const match = useMatch("*");
  const offsetTop = usePageContentOffsetTop();
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <Container $pageContentOffsetTop={offsetTop}>
          <CalendarView />
        </Container>
      ),
      children: [
        {
          path: "book/*",
          element: <AdminBookingForm />,
        },
      ],
    },
    {
      path: "/services",
      element: (
        <Container $pageContentOffsetTop={offsetTop}>
          <ServicesView />
        </Container>
      ),
    },
  ]);

  useEffect(() => {
    if (match?.pathname) {
      AnalyticsService.viewedPage(AnalyticsPages.CALENDAR, match?.pathname);
    }
  }, [match?.pathname]);

  return routes;
};

export default CalendarRoutes;
