export const PLACEHOLDER_FORMAT = /\{[^}]+\}/;
const PLACEHOLDER_FORMAT_GLOBAL = /\{[^}]+\}/g;

export const getPresentPlaceholders = (value: string) => {
  if (value) {
    const presentPlaceholders = value?.match(PLACEHOLDER_FORMAT_GLOBAL);
    const uniquePlacehodlers = presentPlaceholders?.filter((item, index, self) => self.indexOf(item) === index);
    return uniquePlacehodlers?.join(", ");
  }

  return "";
};

export const validatePlaceholderPresent = (value: string) => !RegExp(PLACEHOLDER_FORMAT).test(value);
