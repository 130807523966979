import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CloseIcon, ServiceErrorLogin } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import Popup from "shared/atoms/Popup";

interface Props {
  setOpen: (open: boolean) => void;
}
export const BankIdDisturbancePopup: React.VFC<Props> = ({ setOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      <FadedBackground />
      <Popup bankIdModal>
        <StyledIconButton label="" onClick={() => setOpen(false)}>
          <CloseIcon />
        </StyledIconButton>
        <StyledServiceErrorLogin />
        <Header>{t("bankid_disturbance_popup.header")}</Header>
        <Body>{t("bankid_disturbance_popup.body")}</Body>
        <StyledPrimaryButton onClick={() => setOpen(false)}>{t("buttons.ok")}</StyledPrimaryButton>
      </Popup>
    </>
  );
};

const Body = styled.div`
  ${props => props.theme.font.body2};
  margin: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_40} ${props => props.theme.spacing.S_20}
    ${props => props.theme.spacing.S_40};
  text-align: center;
  white-space: pre-wrap;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: ${props => props.theme.colors.redesign.db80};
`;
const Header = styled.div`
  ${props => props.theme.font.header1};
  ${props => props.theme.belowBreakpoint} {
    margin-top: ${props => props.theme.spacing.S_10};
  }
`;
const StyledServiceErrorLogin = styled(ServiceErrorLogin)`
  min-height: 170px;
  margin: auto;
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  width: 30%;
  margin: auto auto 63px auto;
`;
const StyledIconButton = styled(IconButton)`
  position: fixed;
  margin: 5% auto auto 85%;
`;
const FadedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.redesign.db60};
  opacity: 50%;
`;
