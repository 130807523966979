import { useMutation, useQueryClient } from "react-query";

import type { QuickReply } from "api/schemas/QuickReply";
import useApiClient from "api/useApiClient";

import { getQuickRepliesQueryKey } from "./useGetQuickReplies";

export type PostQuickReplyArgs = {
  userId: number;
  text: string;
};

export const usePostQuickReply = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation(({ userId, text }: PostQuickReplyArgs) => client.postQuickReply({ userId, text }), {
    onMutate: async (newQuickReply: PostQuickReplyArgs) => {
      const queryKey = getQuickRepliesQueryKey(newQuickReply.userId);

      await queryClient.cancelQueries(queryKey);
      const previousQuickReplies = queryClient.getQueryData<QuickReply[]>(queryKey);

      if (previousQuickReplies) {
        queryClient.setQueryData<QuickReply[]>(queryKey, [
          ...previousQuickReplies,
          {
            id: Math.random(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            text: newQuickReply.text,
            userId: newQuickReply.userId,
          },
        ]);
      }

      return { previousQuickReplies };
    },
    onError: (error, variables, context) => {
      const queryKey = getQuickRepliesQueryKey(variables.userId);

      if (context?.previousQuickReplies) {
        queryClient.setQueryData<QuickReply[]>(queryKey, context.previousQuickReplies);
      }
    },
    onSettled: context => {
      if (context) {
        queryClient.invalidateQueries(getQuickRepliesQueryKey(context.userId));
      }
    },
  });
};
