import type { AxiosResponse } from "axios";
import axios from "axios";
import Cookies from "js-cookie";
import { useQuery } from "react-query";

import { signOut } from "utils/auth/queries";
import { getRestHeaders, removeAuthCookies } from "utils/cookie";
import { parseMaintenances } from "utils/statuspage/statuspageHelper";
import type { ScheduledMaintenancesResponse } from "utils/statuspage/types";

const statuspageBaseUrl = `${process.env.REACT_APP_STATUSPAGE_URL}/api/v2`;
const activeMaintenancesUrl = `${statuspageBaseUrl}/scheduled-maintenances/active.json`;

const CHECK_STATUSPAGE_INTERVAL_IN_MS = 60000;

const hasBypassMaintenanceCookie = (): boolean => !!Cookies.get("bypass_maintenance");

const redirectUser = () => {
  if (window.location.pathname !== "/maintenance") {
    window.location.replace("/maintenance");
  }
};

const signOutUser = async () => {
  const authHeaders = getRestHeaders();

  if (authHeaders) {
    try {
      await signOut();
    } finally {
      removeAuthCookies();
    }
  }
};

const getActiveMaintenances = (): Promise<AxiosResponse<ScheduledMaintenancesResponse>> => {
  return axios.get(activeMaintenancesUrl);
};

export type UseStatuspageMaintenanceReturnValue = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isSuccess: boolean;
  maintenanceInProgress: boolean;
};

const useStatuspageMaintenance = (): UseStatuspageMaintenanceReturnValue => {
  const { isSuccess, data: allData } = useQuery("GetActiveMaintenances", getActiveMaintenances, {
    select(data) {
      const activeMaintenance = parseMaintenances(data);

      if (activeMaintenance && !hasBypassMaintenanceCookie()) {
        signOutUser();
        redirectUser();
        return { ...data, maintenanceInProgress: true };
      }

      return { ...data, maintenanceInProgress: false };
    },
    refetchInterval: CHECK_STATUSPAGE_INTERVAL_IN_MS,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return { data: allData?.data, isSuccess, maintenanceInProgress: allData?.maintenanceInProgress };
};

export default useStatuspageMaintenance;
