import styled from "styled-components";

interface Props {
  readonly marginVertical?: number;
  readonly width?: number;
  readonly marginTop?: number;
  readonly marginBottom?: number;
}

const Divider = styled.div<Props>`
  height: 1px;
  min-height: 1px;
  background-color: ${props => props.theme.colors.greys.silver};
  width: 100%;
  max-width: ${props => (props?.width ? `${props.width}px` : "100%")};
  margin: ${props => (props?.marginVertical ? `${props.marginVertical}px 0` : "0")};
  margin-top: ${props => (props?.marginTop ? `${props.marginTop}px` : "0")};
  margin-bottom: ${props => (props?.marginBottom ? `${props.marginBottom}px` : "0")};
`;

export default Divider;
