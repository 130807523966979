import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  className?: string;
}

const AppVersion: React.VFC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>{t("general.app_version", { version: process.env.REACT_APP_VERSION })}</Container>
  );
};

const Container = styled.div`
  ${props => props.theme.font.app_version};
  margin-left: auto;
  padding: 4px 12px;
`;

export default AppVersion;
