import type { TFunction } from "i18next";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

const passwordMinLength = 8;
const passwordMaxLength = 128;

export const getErrorMessage = (t: TFunction, errors?: FieldError) => {
  if (!errors?.message) {
    return undefined;
  }
  if (errors?.type === "too_small") {
    return t(errors.message, { minLength: passwordMinLength });
  }
  if (errors?.type === "too_big") {
    return t(errors.message, { maxLength: passwordMaxLength });
  }
  return t(errors.message);
};

export const nonemptyStringSchema = z
  .string({ required_error: "errors.string.empty" })
  // This is a workaround because ZOD doesn't support a "required" or "nonempty" for strings
  .min(1, { message: "errors.string.empty" });

export const emailSchema = z.object({
  email: nonemptyStringSchema.email({ message: "errors.string.email" }),
});

export const passwordResetSchema = emailSchema;

export const loginSchema = emailSchema.merge(z.object({ password: nonemptyStringSchema }));

const passwordSchema = z.object({
  password: nonemptyStringSchema
    .min(passwordMinLength, { message: "errors.password.string.min" })
    .max(passwordMaxLength, { message: "errors.password.string.max" }),
  password_confirmation: z.string(),
});

export const newPasswordSchema = passwordSchema.refine(obj => obj.password === obj.password_confirmation, {
  message: "errors.password.any.only",
  path: ["password_confirmation"],
});

export const signupSchema = emailSchema
  .merge(passwordSchema)
  .refine(obj => obj.password === obj.password_confirmation, {
    message: "errors.password.any.only",
    path: ["password_confirmation"],
  });
