import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getTreatmentActivityLogsPatientQueryKey = (patientID: number) =>
  ["getTreatmentActivityLogsPatient", patientID] as const;

const useGetTreatmentActivityLogsPatient = (patientID: number) => {
  const client = useApiClient();

  return useQuery(getTreatmentActivityLogsPatientQueryKey(patientID), () =>
    client.getTreatmentActivityLogsPatient(patientID)
  );
};

export default useGetTreatmentActivityLogsPatient;
