import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getPatientAppointments";
type QueryKey = [typeof queryKeyPrefix, number, number];
export const getPatientAppointmentsQueryKey = (userId: number, patientId: number): QueryKey => [
  queryKeyPrefix,
  userId,
  patientId,
];

const useGetPatientAppointments = (
  userId: number,
  patientId: number,
  options?: UseQueryOptions<NewAppointment[], Error, NewAppointment[], QueryKey>
) => {
  const client = useApiClient();

  return useQuery(
    getPatientAppointmentsQueryKey(userId, patientId),
    () => client.getPatientAppointments(userId, patientId),
    options
  );
};

export default useGetPatientAppointments;
