import { useLocation } from "react-router-dom";

const PATIENT_INFORMATION_ROUTES = {
  journal: "medical_record",
  "sign-up-questions": "sign_up_questions",
  "health-reports": "health_questionnaires",
  goals: "goal",
  personal: "personal_details",
};

export type PatientInformationPath = keyof typeof PATIENT_INFORMATION_ROUTES;

export const useActiveLinkTranslation = (activeLink?: string): string => {
  const location = useLocation();

  if (activeLink && activeLink in PATIENT_INFORMATION_ROUTES) {
    return `patients.sub_menu.${PATIENT_INFORMATION_ROUTES[activeLink as PatientInformationPath]}`;
  }

  if (location.pathname.includes("protocol")) {
    return "patients.menu.protocol";
  }

  return `patients.menu.${activeLink}`;
};
