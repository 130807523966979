import type React from "react";
import { useState } from "react";

import { Chip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useDeleteQuickReply } from "api/hooks/useDeleteQuickReply";
import useGetQuickReplies, { getQuickRepliesQueryKey } from "api/hooks/useGetQuickReplies";
import { usePostQuickReply } from "api/hooks/usePostQuickReply";
import type { QuickReply } from "api/schemas/QuickReply";
import { EditIconRedesign } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { SecondaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import NewQuickReplyForm from "./NewQuickReplyForm";

interface Props {
  readonly onQuickReplySelected: (quickReply: string) => void;
}

const QuickRepliesForm: React.VFC<Props> = ({ onQuickReplySelected }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { profile } = useProfileContext();
  const [editMode, setEditMode] = useState(false);

  invariant(profile);

  const getQuickRepliesQuery = useGetQuickReplies(profile.id);
  const deleteQuickReplyMutation = useDeleteQuickReply();
  const postQuickReplyMutation = usePostQuickReply();

  const handleClick = (quickReply: QuickReply) => {
    AnalyticsService.track(AnalyticsEvents.QUICK_REPLIES.USED_FORM_QUICK_REPLY);
    onQuickReplySelected(quickReply.text);
  };

  const handleDelete = (quickReply: QuickReply) => {
    deleteQuickReplyMutation.mutateAsync(
      { userId: profile.id, id: quickReply.id },
      {
        onSuccess() {
          AnalyticsService.track(AnalyticsEvents.QUICK_REPLIES.DELETED_QUICK_REPLY);
          queryClient.invalidateQueries(getQuickRepliesQueryKey(profile.id));
        },
      }
    );
  };

  const onSubmit = (newQuickReply: string) => {
    postQuickReplyMutation.mutateAsync(
      { userId: profile.id, text: newQuickReply },
      {
        onSuccess() {
          AnalyticsService.track(AnalyticsEvents.QUICK_REPLIES.SAVED_QUICK_REPLY);
          queryClient.invalidateQueries(getQuickRepliesQueryKey(profile.id));
          setEditMode(false);
        },
      }
    );
  };

  return (
    <Wrapper data-testid="quick-replies-form">
      <Header>
        <HeaderLabel>{t("messages.quick_replies")}</HeaderLabel>
        <EditButton
          type="button"
          data-testid="quick-replies-form-edit-mode-button"
          onClick={() => setEditMode(prevState => !prevState)}
        >
          {!editMode && <EditIconRedesign />}
          {editMode ? t("buttons.done") : t("buttons.edit")}
        </EditButton>
      </Header>
      <ChipContainer $editMode={editMode}>
        {getQuickRepliesQuery.data?.map((quickReply, index) => {
          return (
            <Chip
              key={quickReply.id}
              data-testid={`form-quick-reply-${index}`}
              label={quickReply.text}
              onClick={
                editMode
                  ? undefined
                  : () => {
                      handleClick(quickReply);
                    }
              }
              onDelete={
                editMode
                  ? () => {
                      handleDelete(quickReply);
                    }
                  : undefined
              }
              deleteIcon={
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                  role="button"
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Cancel />
                </div>
              }
            />
          );
        })}

        {editMode && <NewQuickReplyForm onSubmit={onSubmit} />}
      </ChipContainer>

      <Hidden type="aboveTablet">
        <div>
          <StyledSecondaryButton type="button" onClick={() => setEditMode(prevState => !prevState)}>
            {editMode ? t("buttons.done") : t("buttons.edit")}
            {!editMode && <EditIconRedesign />}
          </StyledSecondaryButton>
        </div>
      </Hidden>
    </Wrapper>
  );
};

export default QuickRepliesForm;

const Wrapper = styled.div`
  height: 348px;
  width: 430px;
  padding: 1px;
  ${props => props.theme.belowMobileBreakpoint} {
    width: auto;
    height: 100%;
    padding: ${props => props.theme.spacing.S_15};
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const HeaderLabel = styled.div`
  font-weight: 500;
  color: ${props => props.theme.colors.primary.base};
`;

const EditButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.redesign.b100};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
`;

const ChipContainer = styled.div<{ $editMode: boolean }>`
  height: calc(100% - 35px);
  overflow: auto;

  ${props => props.theme.aboveBreakpoint} {
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .MuiChip-root {
    background-color: ${({ theme, $editMode }) => ($editMode ? theme.colors.redesign.db10 : theme.colors.redesign.b10)};
    color: ${({ theme, $editMode }) => ($editMode ? theme.colors.redesign.db60 : theme.colors.redesign.b100)};
    font-size: 16px;
    font-weight: 500;
    height: auto;
    min-height: 35px;
    margin: 0 12px 12px 0;

    &:hover {
      background-color: ${({ theme, $editMode }) => !$editMode && theme.colors.redesign.b30};
    }
  }

  .MuiChip-label {
    white-space: pre-wrap;
    padding: 6px 12px;
  }

  .MuiChip-deleteIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px 0 -6px;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  gap: 4px;
  min-width: auto;

  svg {
    height: 24px;
    width: 24px;
  }
`;
