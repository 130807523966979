import type React from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import type { CustomStatus, FlowType } from "routes/login/queries/authenticateWithBankid";
import { translateStatus } from "routes/login/utils/translateStatus";
import { PrimaryButton, TextButton } from "shared/atoms/Button";

import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { Container, LoginWrapper } from "../components/styledComponents";

interface CustomizedState {
  error: CustomStatus;
  flowType: FlowType;
}

export const ResultFailed: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.bankid.title")} />
        <Container>
          <Error>{translateStatus({ flowType: state?.flowType, status: state?.error })}</Error>
          <Links>
            <PrimaryButton id="bankid-try-again" data-testid="bankid-try-again" onClick={() => navigate(-1)}>
              {t("buttons.retry")}
            </PrimaryButton>
            <TextButton id="bankid-cancel" data-testid="bankid-cancel" onClick={() => navigate("/login/bankid")}>
              {t("buttons.cancel")}
            </TextButton>
          </Links>
        </Container>
      </LoginContainer>
    </LoginWrapper>
  );
};

const Error = styled.div`
  margin-bottom: 24px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 16px;
  }
`;
