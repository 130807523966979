import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ActiveTag = ({ active, week }: { active: boolean; week: string }) => {
  const { t } = useTranslation();

  return <Root active={active}>{active ? t("usercourse.tag.active") : t("usercourse.tag.starts_week", { week })}</Root>;
};

const Root = styled.div<{ active: boolean }>`
  font-size: 14px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: ${props => (props.active ? "#EFF9F3" : "#FCF9ED")};
`;

export default ActiveTag;
