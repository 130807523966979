import { useContext } from "react";

import NotificationsContext from "./NotificationsContext";

import type { NotificationsContextValue } from ".";

const useNotificationsContext = (): NotificationsContextValue => {
  const value = useContext(NotificationsContext);

  if (value === null) {
    throw new Error("useNotificationsContext cannot be used outside the NotificationsContextProvider");
  }
  return value;
};

export default useNotificationsContext;
