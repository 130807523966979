import type React from "react";
import { useState } from "react";

import styled from "styled-components";

import { DropdownArrowIcon, TipsIcon } from "redesign-assets";

import parseHTML from "../../utils/parseHTML";

const Container = styled.div`
  background: ${props => props.theme.colors.exercise.background};
  border-radius: ${props => props.theme.borderRadius.library};
  padding: ${props => props.theme.spacing.S_15};
  width: 250px;
  ${props => props.theme.belowLibBreakpoint} {
    width: 100%;
    box-sizing: border-box;
  }
`;

const DropdownIcon = styled(DropdownArrowIcon)<{ $open: boolean }>`
  transition: transform 300ms;
  transform: ${props => (props.$open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const Header = styled.div<{ $open: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.font.header5};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.greys.dark};
  margin-bottom: ${props => (props.$open ? props.theme.spacing.S_10 : 0)};
  :hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & :first-child {
    margin-right: ${props => props.theme.spacing.S_10};
    min-height: 28px;
    min-width: 28px;
  }
`;

const TitleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  height: 100%;
  ${props => props.theme.font.header5};
  color: ${props => props.theme.colors.greys.dark};
  white-space: pre-wrap;
`;

interface ActivityTipProps {
  title: string;
  description: string;
}

const ActivityTip: React.VFC<ActivityTipProps> = ({ title, description }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Container data-testid="exercise-tip">
      <Header data-testid="dropdown-button" $open={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)}>
        <Title>
          <TipsIcon />
          <TitleText>{title}</TitleText>
        </Title>
        <DropdownIcon $open={dropdownOpen} />
      </Header>
      {dropdownOpen && <Description dangerouslySetInnerHTML={{ __html: parseHTML(description) }} />}
    </Container>
  );
};

export default ActivityTip;
