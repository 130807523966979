import type { TFunction } from "i18next";

import type { HealthReportAnswer, HealthReportAnswers } from "api/models/HealthReport";
import calculateJointScoreRmdq from "routes/patients/PatientProfile/components/Information/components/HealthReports/utils/calculateJointScoreRmdq";

import {
  booleanToAnswer,
  getInnerJoint,
  getSelectedLeftHand,
  getSelectedRightHand,
  multipleToAnswer,
  rangeToAnswer,
  stringRangeToAnswer,
} from "../../helpers";
import type { PainLocation } from "../../helpers/helpers";

import { calculateFesScore } from "./calculateFesScore";
import calculateJointScore from "./calculateJointScore";

const booleanGuard = (answer: unknown): boolean | null | undefined =>
  answer == null || typeof answer === "boolean" ? answer : null;
const stringOrNumberGuard = (answer: unknown): string => {
  if (typeof answer === "number") {
    return `${answer}`;
  }
  if (typeof answer === "string") {
    return answer;
  }
  return "";
};
const arrayGuard = <T = string>(answer: unknown): T[] => (answer instanceof Array ? answer : []);
const jointScoreGuard = (answer: unknown): Record<string, number> => {
  if (answer !== null && typeof answer === "object" && !(answer instanceof Array)) {
    return answer as Record<string, number>;
  }
  return {};
};
const jointScoreGuardRmdq = (answer: HealthReportAnswer | HealthReportAnswers): Record<string, boolean> => {
  if (answer !== null && typeof answer === "object" && !(answer instanceof Array)) {
    return answer as Record<string, boolean>;
  }
  return {};
};

const renderAnswer = (
  type: string,
  key: string,
  answer: HealthReportAnswers | HealthReportAnswer,
  params: Record<string, string>,
  weekNumber: number,
  painLocation: PainLocation,
  t: TFunction
): string => {
  const getSelectedFinger = (joints: string[], finger: string) => {
    const specificFinger = joints.filter(j => j.includes(finger));
    const fingerJoints = specificFinger?.map(j => t(getInnerJoint(j)));
    const specificFingerJoint = fingerJoints?.length > 0 && fingerJoints.reduce((a, b) => `${a}, ${b}`);

    return specificFinger?.length > 0 ? `${t(`patients.painful_finger_${finger}`)} (${specificFingerJoint}) ` : "";
  };

  const getFingerJoints = (joints: string[]) => {
    const fingerList = ["thumb", "index", "middle", "ring", "little"];
    const specificJoints = fingerList.map(finger => getSelectedFinger(joints, finger));
    return specificJoints.filter(j => j.length);
  };

  const isWeekOne = weekNumber === 1;
  switch (type) {
    case "boolean":
      return t(booleanToAnswer(booleanGuard(answer)));
    case "range":
      return t(`patients.health_reports.questions_content.${rangeToAnswer(key, stringOrNumberGuard(answer))}`);
    case "range_surgery_special":
      return t(
        `patients.health_reports.questions_content.${rangeToAnswer(
          key,
          isWeekOne ? `w1_${answer}` : `follow_up_${answer}`
        )}`
      );
    case "fihoa_range":
      return t(`patients.health_reports.questions_content.${rangeToAnswer("fihoa", stringOrNumberGuard(answer))}`);
    case "npq_range":
      return t(`patients.health_reports.questions_content.${rangeToAnswer(`npq_${key}`, stringOrNumberGuard(answer))}`);
    case "string_range":
      return t(`patients.health_reports.questions_content.${stringRangeToAnswer(key, stringOrNumberGuard(answer))}`);
    case "range_with_params":
      return t(`patients.health_reports.questions_content.${rangeToAnswer(key, stringOrNumberGuard(answer))}`, params);
    case "frequency":
      return t(`patients.health.frequency_values.${answer}`);
    case "difficulty":
      return t(`patients.health.difficulty_values.${answer}`);
    case "severity":
      return t(`patients.health_reports.questions_content.severity_values.${answer}`);
    case "multiple":
      return multipleToAnswer(key, arrayGuard(answer))
        .map(item => t(`patients.health_reports.questions_content.${item}`))
        .join(", ");
    case "multiple_fingers":
      return `${t("patient.most_painful_location.left_hand")} ${
        getSelectedLeftHand(arrayGuard(answer)).length ? getFingerJoints(getSelectedLeftHand(arrayGuard(answer))) : "-"
      }
       \n${t("patient.most_painful_location.right_hand")} ${
        getSelectedRightHand(arrayGuard(answer)).length
          ? getFingerJoints(getSelectedRightHand(arrayGuard(answer)))
          : "-"
      }`;
    case "locations":
      return arrayGuard<Record<string, number | string>>(answer)
        .map(part => {
          return `${t(`patients.pain_location.${part.location}.0`)}${
            part.lateral_location && part.lateral_location !== "undefined"
              ? `: ${t(`patients.lateral_location.${part.lateral_location}`)}`
              : ""
          }`;
        })
        .join(", ");
    case "calculation_joint_score":
      return calculateJointScore(jointScoreGuard(answer), painLocation, t);
    case "calculation_joint_score_rmdq":
      return calculateJointScoreRmdq(jointScoreGuardRmdq(answer));
    case "injured_by_fall":
      return t(`patients.health_reports.questions_content.injured_by_fall_options.${answer}`);
    case "help_with_daily_activities":
      return t(`patients.health_reports.questions_content.help_with_daily_activities_options.${answer}`);
    case "walking_aid_type":
      return t(`patients.health_reports.questions_content.walking_aid_type_options.${answer}`);
    case "walking_aid_usage":
      return t(`patients.health_reports.questions_content.walking_aid_usage_options.${answer}`);
    case "fes_calculation":
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return calculateFesScore(answer, t);
    case "diagnosed_time_ago":
      return t(`patients.health_reports.questions_content.diagnosed_time_ago_options.${answer}`);
    case "dxa_scan":
      return t(`patients.health_reports.questions_content.dxa_scan_options.${answer}`);
    case "educational_level_v2":
      return t(`patients.health_reports.questions_content.educational_level_v2_options.${answer}`);
    case "unwanted_side_effects":
      return t(`patients.health_reports.questions_content.unwanted_side_effects_options.${answer}`);
    default:
      return stringOrNumberGuard(answer);
  }
};

export default renderAnswer;
