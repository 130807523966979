import type React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import { EmailForm } from "../components/Forms";
import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { LoginWrapper } from "../components/styledComponents";

const Email: React.VFC = () => {
  const { t } = useTranslation();
  const match = useMatch("*");

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.LOGIN.EMAIL, match.pathname);
    }
  }, [match?.pathname]);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.info.email.title")}>{t("login.info.email.text")}</LoginInfoContainer>
        <EmailForm />
      </LoginContainer>
    </LoginWrapper>
  );
};

export default Email;
