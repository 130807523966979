import type React from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { getResetPasswordLink } from "utils/misc/getResetPasswordLink";

interface Props {
  email?: string;
}

export const ForgotPasswordLink: React.VFC<Props> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <ForgotPasswordAnchorTag href={getResetPasswordLink(email)} target="_blank" rel="noreferrer">
      {t("login.reset_password")}
    </ForgotPasswordAnchorTag>
  );
};

const ForgotPasswordLinkStyle = css`
  align-self: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: none;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.redesign.b100};
`;

const ForgotPasswordAnchorTag = styled.a`
  ${ForgotPasswordLinkStyle}
`;
