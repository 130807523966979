import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  left: string;
  right: string | string[];
  highlight?: boolean;
  translated?: boolean;
}

const Row: React.VFC<Props> = ({ left, right, highlight = false, translated = false }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RecordRow>
      <LeftColumn>{left}</LeftColumn>
      <RightContainer $highlight={highlight}>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {translated ? right : t(`patient.answered.${right}`)}
      </RightContainer>
    </RecordRow>
  );
};

const generateItem = (left: string, right: number | string | string[]): JSX.Element => {
  return (
    <MultipleElements>
      {left}
      <StyledI>{right}</StyledI>
    </MultipleElements>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 970px;
  padding: ${props => props.theme.spacing.S_50} ${props => props.theme.spacing.S_15};
  margin: 0 auto;
`;

const RecordRow = styled.div`
  display: flex;
  max-width: 940px;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  box-sizing: border-box;
  max-width: 280px;
  min-width: 280px;
  margin-right: ${props => props.theme.spacing.S_20};
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.warm};
`;

const RightContainer = styled.div<{ $highlight?: boolean }>`
  box-sizing: border-box;
  width: calc(100% - 300px);
  margin-right: ${props => props.theme.spacing.S_20};
  color: ${({ theme, $highlight }) => ($highlight ? theme.colors.salmon : theme.colors.greys.dark)};
  ${props => props.theme.font.header4}
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const MultipleElements = styled.div`
  display: flex;
  align-items: center;
`;

const StyledI = styled.i`
  font-weight: ${props => props.theme.fontWeight.light};
  color: ${props => props.theme.colors.greys.warm};
  margin-left: ${props => props.theme.spacing.S_5};
  flex: 1;
`;

export { generateItem, Row, Container, RecordRow, LeftColumn, RightContainer };
