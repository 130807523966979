import styled from "styled-components";

type Props<T extends string> = {
  options: { label: string; value: T }[];
  onChange: (value: T) => void;
  value: T;
  pillForm?: boolean;
  orientation?: "horizontal" | "vertical";
  disabled?: boolean;
};

const RadioButtons = <T extends string>(props: Props<T>) => {
  const { options, onChange, value, pillForm, orientation = "horizontal", disabled = false } = props;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        gap: pillForm ? "0px" : "8px",
        flexDirection: orientation === "horizontal" ? "row" : "column",
      }}
    >
      {options.map((o, i) => {
        let radius = "4px";
        if (pillForm && i === 0) {
          // left
          radius = "20px 0px 0px 20px";
        }
        if (pillForm && i === options.length - 1) {
          // right:
          radius = "0px 20px 20px 0px";
        }
        if (pillForm && orientation === "vertical" && i === 0) {
          // top
          radius = "20px 20px 0px 0px";
        }
        if (pillForm && orientation === "vertical" && i === options.length - 1) {
          // bottom
          radius = "0px 0px 20px 20px";
        }

        return (
          <RadioButton
            $radius={radius}
            key={o.value}
            $selected={o.value === value && !disabled}
            onClick={() => !disabled && onChange(o.value)}
            $disabled={disabled}
          >
            {o.label}
          </RadioButton>
        );
      })}
    </div>
  );
};

const RadioButton = styled.div<{ $selected?: boolean; $radius?: string; $disabled?: boolean }>`
  border-radius: ${props => props.$radius};
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  color: ${props => {
    if (props.$disabled) {
      return "#B0BBC8";
    }
    return props.$selected ? props.theme.colors.redesign.b100 : props.theme.colors.redesign.db60;
  }};
  border-color: ${props => (props.$selected ? props.theme.colors.redesign.b100 : props.theme.colors.greys.concrete)};
  background: ${props => {
    if (props.$disabled) {
      return props.theme.colors.greys.light4;
    }
    return props.$selected ? props.theme.colors.redesign.b10 : props.theme.colors.white;
  }};
  height: 36px;
  justify-content: center;
  cursor: pointer;
  padding: 0 14px;
  user-select: none;
  ${props => props.theme.belowBreakpoint} {
    flex-grow: 1;
  }
`;

export default RadioButtons;
