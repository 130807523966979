import type React from "react";

import styled from "styled-components";

import { DropdownIcon } from "assets";
import Hidden from "shared/atoms/Hidden";

interface Container {
  readonly disabled?: boolean;
}
const Container = styled.div<Container>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.font.link1}
  font-size: 14px;
  color: ${props => props.theme.colors.primary.base};
  text-decoration: none;
  user-select: none;
  &:hover {
    ${props => !props.disabled && `opacity: 0.7;`}
  }
  cursor: pointer;
  ${props =>
    props.disabled &&
    ` cursor: default;
    color: ${props.theme.colors.greys.pinkish};
  `}
  padding: ${props => props.theme.spacing.S_10} 0;
  @media print {
    display: none;
  }
`;

interface StyledDropDownIconProps {
  readonly $animated: boolean;
  readonly $open: boolean;
}
const StyledDropDownIcon = styled(DropdownIcon)<StyledDropDownIconProps>`
  margin-left: ${props => props.theme.spacing.S_10};
  ${props => props.$animated && `transition: transform 300ms;`}
  transform: ${props => (props.$animated && props.$open ? "rotate(180deg)" : "rotate(0deg)")};
`;

interface ShowMoreProps {
  readonly text?: string;
  readonly onClick?: () => void;
  readonly hideLabel?: "aboveTablet" | "belowTablet" | "aboveMobile" | "belowMobile";
  readonly animated?: boolean;
  readonly open?: boolean;
}

const ShowMore: React.VFC<ShowMoreProps> = ({ text, onClick, hideLabel = null, animated = false, open = false }) => {
  return (
    <Container onClick={onClick}>
      {hideLabel !== null ? <Hidden type={hideLabel}>{text}</Hidden> : text}
      <StyledDropDownIcon $animated={animated} $open={open} />
    </Container>
  );
};

export default ShowMore;
