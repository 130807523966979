import type { FC } from "react";
import { useState } from "react";

import SavedInputsContext from "./SavedInputsContext";
import type { Comments, Goals, SavedMessages, StickyNotes } from "./SavedInputsContextValue";

const SavedInputsContextProvider: FC = ({ children }) => {
  const [savedMessages, setSavedMessages] = useState<SavedMessages>({});
  const [savedComments, setSavedComments] = useState<Comments>({});
  const [savedGoals, setSavedGoals] = useState<Goals>({});
  const [savedStickyNotes, setSavedStickyNotes] = useState<StickyNotes>({});

  return (
    <SavedInputsContext.Provider
      value={{
        savedMessages,
        setSavedMessages,
        savedComments,
        setSavedComments,
        savedGoals,
        setSavedGoals,
        savedStickyNotes,
        setSavedStickyNotes,
      }}
    >
      {children}
    </SavedInputsContext.Provider>
  );
};

export default SavedInputsContextProvider;
