import styled from "styled-components";

type RadioRowProps<T extends string> = {
  options: { label: string; value: T }[];
  value: T;
  onChange: (v: T) => void;
};

const RadioRow = <T extends string>(props: RadioRowProps<T>) => {
  const { options, value, onChange } = props;
  return (
    <RadioRowWrapper>
      {options.map(o => {
        return <RadioItem value={o.value} checked={value === o.value} onChange={v => onChange(v)} label={o.label} />;
      })}
    </RadioRowWrapper>
  );
};

type RadioItemProps<T extends string> = {
  label: string;
  value: T;
  checked: boolean;
  onChange: (v: T) => void;
};

export const RadioItem = <T extends string>(props: RadioItemProps<T>) => {
  const { value, label, onChange, checked } = props;
  return (
    <Center key={value}>
      <CheckBox type="radio" id={value} value={value} checked={checked} onChange={e => onChange(e.target.value as T)} />
      <Label htmlFor={value}>{label}</Label>
    </Center>
  );
};

export default RadioRow;
const CheckBox = styled.input`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
`;

const RadioRowWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
`;

const Label = styled.label`
  font-weight: bold;
`;
