import type React from "react";
import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import styled from "styled-components";

import { useProfileContext } from "contexts/ProfileContext";
import usePrimaryInsuranceInfo from "routes/patients/utils/hooks/usePrimaryInsuranceInfo";
import useSecondaryInsuranceInfo from "routes/patients/utils/hooks/useSecondaryInsuranceInfo";
import { getPrimaryInsurancePlan, getSecondaryInsurancePlan } from "routes/patients/utils/insuranceHelper";
import PhoneNumber from "shared/atoms/PhoneNumber";
import { MarketTypes } from "types";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { isMarket } from "utils/market";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.S_50} ${props => props.theme.spacing.S_15};
  ${props => props.theme.belowBreakpoint} {
    padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_15};
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  flex: 1 1;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_10}
    ${props => props.theme.spacing.S_10} 0;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.warm};
`;

const RightItem = styled(Item)`
  font-weight: ${props => props.theme.fontWeight.regular};
  color: ${props => props.theme.colors.greys.dark};
`;

const PersonalDetails: React.VFC = () => {
  const { t } = useTranslation();
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const match = useMatch("*");

  const primaryInsuranceInfo = usePrimaryInsuranceInfo(patient);
  const primaryInsurancePlan = getPrimaryInsurancePlan(patient?.insuranceData);
  const secondaryInsuranceInfo = useSecondaryInsuranceInfo(patient?.insuranceData);
  const secondaryInsurancePlan = getSecondaryInsurancePlan(patient?.insuranceData);

  useEffect(() => {
    if (match) AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.PERSONAL_DETAILS, match.pathname);
  }, [match?.pathname]);

  const generateRow = (left: string, right: string | React.ReactNode) => {
    return (
      <Row>
        <Item>{left}</Item>
        <RightItem>{right}</RightItem>
      </Row>
    );
  };

  const showPayer = isMarket(MarketTypes.UK);

  return (
    <Container data-testid="personal-details">
      {patient?.first_name && generateRow(t("form.first_name"), patient.first_name)}
      {patient?.last_name && generateRow(t("form.last_name"), patient.last_name)}
      {patient?.personal_number && generateRow(t("patients.medical_records.personal_number"), patient.personal_number)}
      {patient?.address && generateRow(t("form.address"), patient.address)}
      {patient?.zip_code && generateRow(t("form.post"), patient.zip_code)}
      {patient?.city && generateRow(t("form.city"), patient.city)}
      {patient?.country && generateRow(t("form.country"), patient.country)}
      {patient?.phone_number &&
        generateRow(t("form.phone"), <PhoneNumber phone={patient.phone_number} country={patient?.country} />)}
      {patient?.email && generateRow(t("form.email"), patient.email)}
      {patient?.birthday &&
        generateRow(
          t("form.birth"),
          new Date(patient.birthday).toLocaleDateString(profile?.locale, { timeZone: "UTC" })
        )}
      {patient?.npo_consent !== undefined &&
        generateRow(
          t("form.npo_consent"),
          patient.npo_consent === true ? t("patient.answered.true") : t("patient.answered.false")
        )}
      {primaryInsuranceInfo &&
        generateRow(
          secondaryInsuranceInfo
            ? t("patient.personal_details.coverage.primary")
            : t("patient.personal_details.coverage.default"),
          primaryInsuranceInfo
        )}
      {primaryInsurancePlan &&
        generateRow(
          secondaryInsuranceInfo
            ? t("patient.personal_details.plan.primary")
            : t("patient.personal_details.plan.default"),
          primaryInsurancePlan
        )}
      {secondaryInsuranceInfo && generateRow(t("patient.personal_details.coverage.secondary"), secondaryInsuranceInfo)}
      {secondaryInsurancePlan && generateRow(t("patient.personal_details.plan.secondary"), secondaryInsurancePlan)}
      {showPayer && patient?.payer_name && generateRow(t("form.payer_name"), patient.payer_name)}
      {showPayer && patient?.payer_category && generateRow(t("form.payer_category"), patient.payer_category)}
    </Container>
  );
};

export default PersonalDetails;
