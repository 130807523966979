import type React from "react";

import styled from "styled-components";

import AppVersion from "shared/atoms/AppVersion";

import { LanguageDropdown } from "../LanguageDropdown";

interface Props {
  className?: string;
}

export const LoginContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <Container className={className}>
      {children}

      <BottomSection>
        <LanguageDropdown />

        <AppVersion />
      </BottomSection>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  max-width: 480px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(141, 155, 173, 0.3);

  ${props => props.theme.belowBreakpoint} {
    min-height: 500px;
    border-radius: 0;
  }
`;

const BottomSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 12px 4px 24px;
`;
