import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Content = styled.div`
  min-width: 340px;
  padding: 40px 26px 32px;
  font-size: 16px;

  ${props => props.theme.belowBreakpoint} {
    min-width: auto;
  }
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.redesign.db90};
  font-size: 18px;
  font-weight: 500;
`;

export const Row = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 14px;
  color: ${({ theme }) => theme.colors.redesign.db70};
  font-size: 16px;
  font-weight: 500;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const Value = styled.div`
  margin-left: 34px;
  color: ${({ theme }) => theme.colors.redesign.db90};
  font-size: 16px;
  white-space: pre-wrap;
`;

export const Buttons = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
`;
