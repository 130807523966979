import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { DotDotDotIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Hidden from "shared/atoms/Hidden";
import { MUITextInput } from "shared/atoms/inputs";
import Modal from "shared/atoms/Modal";
import Tooltip from "shared/molecules/Tooltip";

import { getQuickTextValues } from "../helpers/quickTextValues";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly isNoteSigned?: boolean;
  readonly onUpdate?: () => void;
}

const TextInputQuickText: React.VFC<Props> = ({ disabled, error, label, name, required, isNoteSigned, onUpdate }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { setValue, getValues, trigger } = useFormContext();
  const [popupOpen, setPopupOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  invariant(profile);

  const isGB = profile.market === "GB";

  // existing translations can be used with trimmed strings
  const trimmedName = name.replace("structured_fields.", "").replace("_v2", "");

  const handleQuickText = (quickText: string): void => {
    const currentText = getValues(name);
    const updatedText = currentText === null || currentText === "" ? quickText : `${currentText}\n${quickText}`;
    setValue(name, updatedText);
    trigger(name);
    if (onUpdate) {
      onUpdate();
    }
  };

  useEffect(() => {
    if (popupOpen && ref.current) {
      ref.current.focus();
    }
  }, [popupOpen]);

  const renderQuickText = () => {
    return getQuickTextValues(trimmedName, isGB).map(option => (
      <QuickText
        key={option}
        onClick={() => {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleQuickText(t(`patients.notes.fields.${trimmedName}_options.${option}`));
          // refocus the text area
          if (ref.current) {
            ref.current.focus();
          }
        }}
      >
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {t(`patients.notes.fields.${trimmedName}_options.${option}`)}
      </QuickText>
    ));
  };

  return (
    <MUITextInput
      error={error}
      label={label}
      name={name}
      disabled={disabled}
      multiline
      required={required}
      onUpdate={onUpdate}
      inputRef={ref}
      endAdornment={
        !isNoteSigned && (
          <Wrapper
            onClick={event => {
              event.stopPropagation();
              setPopupOpen(true);
            }}
          >
            <IconContainer>
              <DotDotDotIcon />
            </IconContainer>
            <Hidden type="belowTablet">
              <Tooltip
                data-testid="quick-text-overlay-desktop"
                offset="bottom-right"
                isOpen={popupOpen}
                requestClose={() => setPopupOpen(false)}
              >
                {renderQuickText()}
              </Tooltip>
            </Hidden>
            <Hidden type="aboveTablet">
              <Modal
                data-testid="quick-text-overlay-mobile"
                open={popupOpen}
                close={() => setPopupOpen(false)}
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                title={t(`patients.notes.fields.${trimmedName}`)}
              >
                {renderQuickText()}
              </Modal>
            </Hidden>
          </Wrapper>
        )
      }
    />
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 4px 16px 0px 16px;
  svg {
    stroke: ${props => props.theme.colors.redesign.db50};
  }
`;

const QuickText = styled.div`
  background-color: ${props => props.theme.colors.redesign.b10};
  color: ${props => props.theme.colors.redesign.b100};
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
  ${props => props.theme.belowMobileBreakpoint} {
    padding: 16px;
    margin: 12px;
    border-radius: 8px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  :hover {
    background-color: ${props => props.theme.colors.redesign.b30};
  }
  cursor: pointer;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

export { TextInputQuickText };
