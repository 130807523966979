// Disabling dangerouslySetInnerHTML since we sanitize the html before render.
/* eslint-disable react/no-danger */
import type { ReactNode } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { UserCourseExercise, UserCourseLibraryExercise } from "api/schemas/UserCourse";
import Video from "routes/library/components/Video";
import parseHTML from "routes/library/utils/parseHTML";

import RadioButtons from "./RadioButtons";
import ResponsivePopup from "./ResponsivePopup";
import { OuterMargin } from "./SharedStyledComponents";

const ExerciseDetails = ({
  exercise,
  currentLevel,
  children,
  onClose,
}: {
  exercise: UserCourseExercise | UserCourseLibraryExercise;
  currentLevel: number;
  children?: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [view, setView] = useState<"video" | "about">("video");
  const level = exercise.levels[currentLevel - 1];
  const { video_file_url, video_thumbnail_file_url, title, about, tip_description, variation_title, caption_file_url } =
    level;

  return (
    <ResponsivePopup open onClose={() => onClose()}>
      <OuterMargin>
        <div style={{ padding: "16px 0 32px 0" }}>
          <Wrapper>
            <Title>
              {title} {variation_title && `(${variation_title})`}
            </Title>
            <div style={{ marginBottom: "16px" }}>
              <RadioButtons
                options={[
                  { label: t("usercourse.exercise_details.video"), value: "video" },
                  { label: t("usercourse.exercise_details.about"), value: "about" },
                ]}
                onChange={v => setView(v)}
                value={view}
              />
            </div>
            {view === "video" && (
              <>
                <Video
                  videoFileUrl={video_file_url || ""}
                  captionFileUrl={caption_file_url || undefined}
                  poster={video_thumbnail_file_url || ""}
                />
              </>
            )}
            {view === "about" && (
              <About>
                {about && (
                  <>
                    <SmallTitle>{t("usercourse.exercise_details.about")}</SmallTitle>
                    <div dangerouslySetInnerHTML={{ __html: parseHTML(about) }} />
                  </>
                )}
                {tip_description && (
                  <>
                    <SmallTitle>{t("usercourse.exercise_details.tip")}</SmallTitle>
                    <div dangerouslySetInnerHTML={{ __html: parseHTML(tip_description) }} />
                  </>
                )}
              </About>
            )}
          </Wrapper>
          {children}
        </div>
      </OuterMargin>
    </ResponsivePopup>
  );
};

const About = styled.div`
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  padding: 16px 0px;
`;

const SmallTitle = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.primary.base};
  margin: 16px 0 8px 0;
`;

const Wrapper = styled.div`
  ${props => props.theme.aboveBreakpoint} {
    width: 500px;
    height: 450px;
    overflow: auto;
  }
`;

export default ExerciseDetails;
