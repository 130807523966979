import styled from "styled-components";

import { StatusArrow } from "assets";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 400px;
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_15};
  color: ${props => props.theme.colors.greys.dark};
`;
const ChartWrapper = styled.div`
  box-sizing: border-box;
  height: 400px;
  position: relative;
`;

const Empty = styled.div`
  ${props => props.theme.font.caption}
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartLegendCircle = styled.div`
  border-radius: ${props => props.theme.borderRadius.circular};
  height: 14px;
  width: 14px;
  background-color: ${props =>
    (props.color === "pain" && props.theme.colors.graph.pain) ||
    (props.color === "function" && props.theme.colors.graph.function) ||
    (props.color === "goal" && props.theme.colors.graph.goal)};
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
  ${props => props.theme.font.table.row}
  line-height: 1.57;
`;

const ChartLegend = styled.div`
  margin-right: ${props => props.theme.spacing.S_20};
  padding-left: ${props => props.theme.spacing.S_10};
  position: relative;
  color: ${props =>
    (props.color === "pain" && props.theme.colors.graph.pain) ||
    (props.color === "function" && props.theme.colors.graph.functionText) ||
    (props.color === "goal" && props.theme.colors.graph.goal)};
`;

const CardFooter = styled.div`
  display: flex;
  margin: 0 auto;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
`;

const FooterSide = styled.div`
  flex: 1 1;
  height: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_5} ${props => props.theme.spacing.S_15};
  &:first-child {
    border-right: 1px solid ${props => props.theme.colors.greys.silver};
  }
  display: flex;
  flex-direction: column;
  ${props => props.theme.font.caption}
  color: ${props => props.theme.colors.greys.dark};
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.font.header2}
  color: ${props => (props.color === "pain" ? props.theme.colors.graph.pain : props.theme.colors.graph.functionText)};
`;

const SmallSection = styled.div`
  margin-left: ${props => props.theme.spacing.S_5};
  ${props => props.theme.font.caption}
  color: ${props => props.theme.colors.greys.warm};
`;

const StyledArrow = styled(StatusArrow)<{ $color: string; $decrease?: boolean; $increase?: boolean }>`
  margin-right: ${props => props.theme.spacing.S_5};
  transform: rotate(0deg);
  ${props => props.$decrease && "transform: rotate(45deg);"}
  ${props => props.$increase && "transform: rotate(-45deg);"}
  fill: ${props => (props.$color === "pain" ? props.theme.colors.graph.pain : props.theme.colors.graph.functionText)};
`;

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.font.caption}
  color: ${props => props.theme.colors.greys.warm};
  padding: ${props => props.theme.spacing.S_10};
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.basic};
  border: 1px solid ${props => props.theme.colors.greys.silver};
`;

const GoalRect = styled.rect`
  fill: ${props => props.theme.colors.graph.goal};
  height: 22px;
  rx: 4px;
`;

const GoalText = styled.text`
  fill: ${props => props.theme.colors.white};
  text-anchor: end;
`;

export {
  Container,
  Content,
  ChartWrapper,
  Empty,
  ChartLegend,
  ChartLegendCircle,
  ChartHeader,
  CardFooter,
  FooterSide,
  FooterContent,
  SmallSection,
  StyledArrow,
  Tooltip,
  GoalRect,
  GoalText,
};
