import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

import type { IcdCodeType } from "../ReferralNotes/ReferralNoteDiagnosisCode";

const ICD_CODES_ARRAY_INDEX = 3;

export const icdFormatter = (icdResponse: AxiosResponse): [] | IcdCodeType[] => {
  if (icdResponse?.data && icdResponse?.data[ICD_CODES_ARRAY_INDEX]) {
    const dataArray = icdResponse?.data[ICD_CODES_ARRAY_INDEX];
    const formatted = dataArray.map((dataObj: [string, string]) => {
      return {
        value: dataObj[0],
        label: dataObj[1],
      };
    });
    return formatted;
  }
  return [];
};

const getIcdCodes = async (searchValue: string): Promise<IcdCodeType[] | Error> => {
  const response = await AxiosInstance.get(
    `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?terms=${searchValue}&sf=code,name`
  );
  if (response) {
    return icdFormatter(response);
  }
  return new Error();
};

export default getIcdCodes;
