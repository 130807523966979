import { useContext, useEffect, useRef, useState } from "react";
import type React from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";

import type { Payer } from "api/models/CareEvent";
import type { MedicalNoteFieldConfig, MedicalNoteFieldType, MedicalNoteType } from "types";
import { MedicalNoteHalfwidthFieldTypes } from "types";
import { useWindowSize } from "utils/hooks";
import { getSectionTitleKey } from "utils/medicalNotes";

import { DynamicField, Section, SectionHelpContent, SectionRow, SplitSectionRow } from ".";

interface Props {
  readonly fields: MedicalNoteFieldConfig[] | undefined;
  readonly forcedOptional?: boolean;
  readonly name: string | undefined;
  readonly readonly: boolean;
  readonly collapsible?: boolean;
  readonly onChange?: () => void;
  readonly label?: MedicalNoteType;
  readonly payer?: Payer;
  readonly isNoteSigned?: boolean;
  readonly signedLimitReached?: boolean;
}

const isRequired = (forcedOptional: boolean | undefined, required: boolean | undefined): boolean | undefined =>
  forcedOptional ? false : required;

const DynamicSection: React.VFC<Props> = ({
  fields = [],
  forcedOptional,
  name,
  readonly,
  onChange,
  collapsible = true,
  label,
  payer,
  isNoteSigned = false,
  signedLimitReached,
}) => {
  const [disabled, setDisabled] = useState(false);
  const { width } = useWindowSize();
  const { formState, trigger } = useFormContext();
  const theme = useContext(ThemeContext);
  const initialized = useRef(false);
  const { t } = useTranslation();

  const resolveNestedError = (formErrors: typeof formState.errors, fieldName: string) => {
    const path = fieldName.split(".");
    if (path.length > 2) throw new Error(`Invalid field name path length in ${path}`);
    return path.length > 1 ? formErrors[path[0]]?.[path[1]] : formErrors[path[0]];
  };

  const isMobile = width <= parseInt(theme.breakpointMobile, 10);

  const reduceFieldsToRows = (
    rows: MedicalNoteFieldConfig[][],
    field: MedicalNoteFieldConfig
  ): MedicalNoteFieldConfig[][] => {
    const rowIndex = rows.length - 1;

    const isPrevHalfWidth = (index: number): boolean => {
      if (!rows[index] || !rows[index][0]?.type || rows[index].length !== 1) return false;
      return MedicalNoteHalfwidthFieldTypes.includes((rows[rowIndex][0].type || "") as MedicalNoteFieldType);
    };

    if (!isMobile && field.type && MedicalNoteHalfwidthFieldTypes.includes(field.type) && isPrevHalfWidth(rowIndex))
      return [...rows.slice(0, -1), [...rows[rowIndex], field]];

    return [...rows, [field]];
  };

  // Revalidate form when 'forceOptional' prop changes 'required' prop of Fields in Section
  useEffect(() => {
    const fieldNames = fields.map(field => field.name);
    if (initialized.current) trigger(fieldNames);
    initialized.current = true;
  }, [forcedOptional]);

  // Monitoring formState to detect changes in errors in section's fields, and in turn disable the section
  useEffect(() => {
    const hasSectionError = fields.some(field => resolveNestedError(formState.errors, field.name));
    setDisabled(hasSectionError);
  }, [fields, formState]);

  const helptexts = fields.filter((field: MedicalNoteFieldConfig) => field.helptext).map(field => field.name);

  return (
    <Section
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label={name && t(getSectionTitleKey(name))}
      helpContent={helptexts.length > 0 ? <SectionHelpContent fields={helptexts} /> : undefined}
      disabled={disabled}
      dataTestId={`${name}-section`}
      optional={forcedOptional}
      collapsible={collapsible}
      readOnly={readonly}
    >
      {fields.reduce(reduceFieldsToRows, []).map(row => {
        // number is 0 or 1 depending if one or two fields are on the same row
        const dynamicFieldsPerRow = (number: number) => {
          return (
            <DynamicField
              type={row[number].type}
              name={row[number].name}
              forceLabel={row[number].forceLabel}
              readonly={readonly}
              required={isRequired(forcedOptional, row[number].required)}
              options={row[number].options}
              onChange={onChange}
              note_type={label}
              payer={payer}
              isNoteSigned={isNoteSigned}
              signedLimitReached={signedLimitReached}
            />
          );
        };

        if (row.length === 2)
          return (
            <SplitSectionRow key={`section-row-${row[0].name}`} role="row">
              {dynamicFieldsPerRow(0)}
              {dynamicFieldsPerRow(1)}
            </SplitSectionRow>
          );

        return (
          <SectionRow key={`section-row-${row[0].name}`} role="row">
            {dynamicFieldsPerRow(0)}
          </SectionRow>
        );
      })}
    </Section>
  );
};

export { DynamicSection };
