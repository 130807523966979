import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getCareEventsBillingQueryKey = () => ["getCareEventsBilling"] as const;

const useGetCareEventsBilling = () => {
  const client = useApiClient();

  return useQuery(getCareEventsBillingQueryKey(), () => client.getCareEventsBilling());
};

export default useGetCareEventsBilling;
