import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { OnboardingAroundTheWorld } from "assets";
import { PrimaryButton } from "shared/atoms/Button";

import { OnboardingButtons, OnboardingHeader, OnboardingMessage } from "../components";

const WelcomePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <OnboardingWelcome>
      <StyledOnboardingAroundTheWorld data-testid="onboarding-welcome-page-img" viewBox="0 0 302 260" width="302" />

      <OnboardingHeader>{t("onboarding.1.title")}</OnboardingHeader>

      <OnboardingMessage data-testid="onboarding-welcome-page-body">{t("onboarding.1.body")}</OnboardingMessage>

      <OnboardingButtons>
        <PrimaryButton
          data-testid="onboarding-welcome-page-next-btn"
          onClick={() => navigate("/onboarding/profile-picture")}
        >
          <span>{t("buttons.next")}</span>
        </PrimaryButton>
      </OnboardingButtons>
    </OnboardingWelcome>
  );
};

export default WelcomePage;

const OnboardingWelcome = styled.div`
  text-align: center;
`;

const StyledOnboardingAroundTheWorld = styled(OnboardingAroundTheWorld)`
  height: auto;
  max-width: 302px;
  margin-top: ${props => props.theme.spacing.S_20};

  ${props => props.theme.belowMobileBreakpoint} {
    max-width: 200px;
  }
`;
