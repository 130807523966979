import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getFieldHelptextKey, getFieldLabelKey } from "utils/medicalNotes";

interface Props {
  readonly fields: string[];
}

// generates help with label and description based on list of fields
const SectionHelpContent: React.VFC<Props> = ({ fields }): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <HelpContainer>
      {fields.map(field => (
        <HelpFieldWrapper key={field}>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <HelpFieldLabel>{t(getFieldLabelKey(field))}</HelpFieldLabel>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <HelpFieldDescription>{t(getFieldHelptextKey(field))}</HelpFieldDescription>
        </HelpFieldWrapper>
      ))}
    </HelpContainer>
  );
};

const HelpContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  ${props => props.theme.aboveMobileBreakpoint} {
    width: 50vw;
    max-width: 400px;
  }

  ${props => props.theme.belowMobileBreakpoint} {
    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: ${props => props.theme.spacing.S_15};
  }
`;

const HelpFieldWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.S_20};
  &:first-child {
    margin-top: 0;
  }
`;

const HelpFieldLabel = styled.div`
  font-weight: 700;
  white-space: nowrap;
`;

const HelpFieldDescription = styled.span`
  white-space: pre-wrap;
`;

export { SectionHelpContent };
