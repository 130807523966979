import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";

import type { GetManagerPatientsResponse } from "api/schemas/ManagerBooking";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getManagerPatients";
type QueryKey = [typeof queryKeyPrefix, number];
export const getManagerQueryKey = (userId: number): QueryKey => [queryKeyPrefix, userId];

const useGetManagerPatients = (
  userId: number,
  options?: UseQueryOptions<GetManagerPatientsResponse[], Error, GetManagerPatientsResponse[], QueryKey>
) => {
  const client = useApiClient();
  return useQuery(getManagerQueryKey(userId), () => client.getManagerPatients(userId), options);
};

export default useGetManagerPatients;
