import { z } from "zod";

const HealthJournalArgsSchema = z.object({
  patientId: z.number(),
  treatment_id: z.number().nullable().optional(),
});
export type HealthJournalArgs = z.infer<typeof HealthJournalArgsSchema>;

export const AilmentSchema = z.enum(["fall_prevention", "joint_pain", "osteoporosis"]);
export type Ailment = z.infer<typeof AilmentSchema>;
const MostPainfulJointLocationSchema = z.enum([
  "elbow",
  "foot",
  "hand",
  "hip",
  "knee",
  "lower_back",
  "lower_leg",
  "neck",
  "shoulder",
  "wrist",
]);
export type MostPainfulJointLocation = z.infer<typeof MostPainfulJointLocationSchema>;
const ExaminedSchema = z.enum(["yes_examined", "yes_unexamined", "no"]).nullable();
const FrequencyOfNeckPainSchema = z.enum(["always", "hourly", "daily", "weekly", "monthly"]).nullable();
const HeightUnitSchema = z.enum(["cm", "in"]).nullable();
const WeightUnitSchema = z.enum(["kg", "lb"]).nullable();
const YesOrNoSchema = z.enum(["yes", "no"]).nullable();
const YesNoDontKnowSchema = z.enum(["yes", "no", "dont_know"]).nullable();
const OsteoporosisCauseSchema = z.enum(["age_menopause", "dont_know", "medicine"]).nullable();
export type OsteoporosisCause = z.infer<typeof OsteoporosisCauseSchema>;
const PainCausedByAccidentSchema = z.enum(["yes_examined", "yes", "no"]).nullable();
const PainDurationSchema = z
  .enum(["less_than_1_month", "1_to_3_months", "3_to_6_months", "more_than_6_months"])
  .nullable();
const PainFrequencySchema = z.enum(["always", "hourly", "daily", "weekly", "monthly", "never"]).nullable();
const RadiatingPainDistanceSchema = z.enum(["out_in_the_arm", "to_hand", "to_shoulder"]).nullable();
const WhenNeckPainOccursSchema = z.enum(["still", "moving", "at_night", "walking", "lying"]).array().nullable();

const TextAnswerSchema = z.enum(["dont_know", "no", "yes", "yes_examined", "yes_unexamined"]).nullable();
export type TextAnswer = z.infer<typeof TextAnswerSchema>;

const FallSymptomsSchema = z
  .enum(["lightheadedness", "unsteadiness", "sleepiness", "incontinence"])
  .array()
  .nullable()
  .optional();
export type FallSymptoms = z.infer<typeof FallSymptomsSchema>;

const HPFormSchema = z
  .object({
    limit: z.boolean(),
    selected: z.boolean(),
    treatment: z.boolean(),
  })
  .nullable()
  .optional();
const HealthProblemsSchema = z
  .object({
    arthritis: HPFormSchema,
    back_pain: HPFormSchema,
    blood_disease: HPFormSchema,
    cancer: HPFormSchema,
    depression: HPFormSchema,
    diabetes: HPFormSchema,
    heart_disease: HPFormSchema,
    high_blood_pressure: HPFormSchema,
    kidney_disease: HPFormSchema,
    liver_disease: HPFormSchema,
    lung_disease: HPFormSchema,
    ulcer_disease: HPFormSchema,
    other_problems: z.object({ text: z.string() }).nullable().optional(),
  })
  .nullable()
  .optional();

const FallPreventionSchema = z.object({
  fall_prevention_accident_or_fall_last_months: ExaminedSchema.optional(),
  fall_prevention_assistive_device_advised: z.boolean().nullable().optional(),
  fall_prevention_fallen_past_year: z.boolean().nullable().optional(),
  fall_prevention_have_or_had_cancer: z.boolean().nullable().optional(),
  fall_prevention_injured_by_fall: ExaminedSchema.optional(),
  fall_prevention_medicine_side_effects: YesNoDontKnowSchema.optional(),
  fall_prevention_sudden_weight_loss: z.boolean().nullable().optional(),
  fall_prevention_symptoms_before_falling: FallSymptomsSchema,
  fall_prevention_times_fallen_past_year: z.number().nullable().optional(),
  fall_prevention_unsteady_standing_or_walking: YesOrNoSchema.optional(),
  fall_prevention_using_assistive_device_during_fall: z.boolean().nullable().optional(),
  fall_prevention_worried_about_falling: z.boolean().nullable().optional(),
});
export type FallPrevention = z.infer<typeof FallPreventionSchema>;

const OsteoporosisSchema = z.object({
  osteoporosis_accident_or_fall_last_months: ExaminedSchema.optional(),
  osteoporosis_cause: OsteoporosisCauseSchema.optional(),
  osteoporosis_diagnosed: z.boolean().nullable().optional(),
  osteoporosis_diagnosed_during_fracture: z.boolean().nullable().optional(),
  osteoporosis_exercise_recommended: z.boolean().nullable().optional(),
  osteoporosis_have_or_had_cancer: z.boolean().nullable().optional(),
  osteoporosis_osteopenia_diagnosed: z.boolean().nullable().optional(),
  osteoporosis_sudden_back_pain: z.boolean().nullable().optional(),
  osteoporosis_sudden_weight_loss: z.boolean().nullable().optional(),
});
export type Osteoporosis = z.infer<typeof OsteoporosisSchema>;

export const HealthJournalSchema = z
  .object({
    accepted: z.boolean().nullable().optional(),
    afraid_to_get_hurt: z.boolean().nullable().optional(),
    ailment: AilmentSchema.optional(),
    age: z.number().nullable().optional(),
    bend_over_difficulty: z.number().nullable().optional(),
    bmi: z.number().nullable().optional(),
    chest_pain: YesOrNoSchema.optional(),
    comment: z.string().nullable().optional(),
    cracking_joints: z.boolean().nullable().optional(),
    difficulty_walking: z.boolean().nullable().optional(),
    enlargement: z.boolean().nullable().optional(),
    free_text_medical_information: z.string().nullable().optional(),
    frequency_of_low_back_pain: z.string().nullable().optional(),
    frequency_of_neck_pain: FrequencyOfNeckPainSchema.optional(),
    frequency_of_shoulder_pain: z.string().nullable().optional(),
    frequency_pain_choice: z.number().nullable().optional(),
    gender: z.string().nullable().optional(),
    general_feeling: z.number().nullable().optional(),
    hand_knuckles_swollen: z.string().nullable().optional(),
    hand_specific_finger_joints: z.array(z.string()).nullable().optional(),
    hand_wrists_pain: z.string().nullable().optional(),
    hand_wrists_swollen: z.string().nullable().optional(),
    have_had_cancer: z.string().nullable().optional(),
    health_plan_code: z.string().nullable().optional(),
    health_problems: HealthProblemsSchema,
    heated_joints: z.boolean().nullable().optional(),
    height: z.number().nullable().optional(),
    height_unit: HeightUnitSchema.optional(),
    initial_pain: z.number().nullable().optional(),
    invasive_any_scan: z.number().nullable().optional(),
    invasive_info_from_therapist: z.boolean().nullable().optional(),
    invasive_less_medication: z.boolean().nullable().optional(),
    invasive_medicines_other: z.string().nullable().optional(),
    invasive_medicines_selections: z.array(z.number()).nullable().optional(),
    invasive_operation_done: z.boolean().nullable().optional(),
    invasive_operation_scheduled: z.boolean().nullable().optional(),
    invasive_operation_type: z.number().nullable().optional(),
    invasive_skip_surgery: z.boolean().nullable().optional(),
    invasive_using_medicine: z.boolean().nullable().optional(),
    joint_pains: z.boolean().nullable().optional(),
    lay_on_knee_difficulty: z.number().nullable().optional(),
    life_activities: z.number().nullable().optional(),
    life_hygiene: z.string().nullable().optional(),
    life_mobility: z.string().nullable().optional(),
    life_pain: z.string().nullable().optional(),
    life_worry: z.string().nullable().optional(),
    low_back_have_had_cancer: z.string().nullable().optional(),
    low_back_loss_sensation: z.string().nullable().optional(),
    low_back_pain_caused_by_accident: z.string().nullable().optional(),
    low_back_radiating_pain: z.number().nullable().optional(),
    most_painful_location: z
      .object({
        lateral_location: z.enum(["left", "right", "undefined"]).nullable().optional(),
        location: MostPainfulJointLocationSchema.nullable().optional(),
      })
      .nullable()
      .optional(),
    neck_pain_caused_by_accident: z.string().nullable().optional(),
    neck_radiating_pain: z.number().nullable().optional(),
    neck_related_pain: z.array(z.string()).nullable().optional(),
    night_pain: YesOrNoSchema.optional(),
    oa_diagnosed: z.boolean().nullable().optional(),
    oa_risk: z.number().nullable().optional(),
    oa_school: z.boolean().nullable().optional(),
    other_problems_walking: z.number().nullable().optional(),
    pain_caused_by_accident: PainCausedByAccidentSchema.optional(),
    pain_duration: PainDurationSchema.optional(),
    pain_frequency: PainFrequencySchema.optional(),
    pain_locations: z.array(z.object({ lateral_location: z.string(), location: z.string() })).optional(),
    pain_under_pressure_or_movement: z.boolean().nullable().optional(),
    physical_activity_choice: z.number().nullable().optional(),
    previous_consultation: z.boolean().nullable().optional(),
    previous_consultation_year: z.string().nullable().optional(),
    put_on_sock_difficulty: z.number().nullable().optional(),
    radiating_pain: z.number().nullable().optional(),
    radiating_pain_distance: RadiatingPainDistanceSchema.optional(),
    rate_your_health: z.number().nullable().optional(),
    reduced_sensation: YesOrNoSchema.optional(),
    reduced_sensation_abdomen: YesOrNoSchema.optional(),
    reduced_strength: YesOrNoSchema.optional(),
    relatives: z.boolean().nullable().optional(),
    responsible: z.string().nullable().optional(),
    rise_out_of_bed_difficulty: z.number().nullable().optional(),
    shoulder_pain_caused_by_accident: z.string().nullable().optional(),
    shoulder_radiating_pain: z.number().nullable().optional(),
    shoulder_type_of_pain: z.array(z.string()).nullable().optional(),
    sit_up_difficulty: z.number().nullable().optional(),
    sitting_down_choice: z.number().nullable().optional(),
    sought_care_for_injured_specific_joint: z.boolean().nullable().optional(),
    specific_joint_examined: z.boolean().nullable().optional(),
    specific_joint_oa_diagnosed: z.boolean().nullable().optional(),
    specific_joint_pain: z.number().nullable().optional(),
    squat_difficulty: z.number().nullable().optional(),
    stiffness: z.boolean().nullable().optional(),
    stiffness_duration: z.enum(["less_than", "more_than"]).nullable().optional(),
    stiffness_present: z.boolean().nullable().optional(),
    tenderness: z.boolean().nullable().optional(),
    training_choice: z.number().nullable().optional(),
    twist_leg_difficulty: z.number().nullable().optional(),
    unexplained_weight_loss: YesOrNoSchema.optional(),
    updated_at: z.string().optional(),
    viewed_by: z.array(z.number()).nullable().optional(),
    want_operation: z.boolean().nullable().optional(),
    weight: z.number().nullable().optional(),
    weight_unit: WeightUnitSchema.optional(),
    when_low_back_pain_occurs: z.array(z.string()).nullable().optional(),
    when_neck_pain_occurs: WhenNeckPainOccursSchema.optional(),
    when_shoulder_pain_occurs: z.array(z.string()).nullable().optional(),
    work_current_situation: z.number().nullable().optional(),
    work_reduced_sickleave: z.boolean().nullable().optional(),
    work_reporting_sick: z.boolean().nullable().optional(),
    work_sick_length: z.string().nullable().optional(),
    works_standing_walking_rotating: z.boolean().nullable().optional(),
  })
  .merge(FallPreventionSchema)
  .merge(OsteoporosisSchema);

export type HealthJournal = z.infer<typeof HealthJournalSchema>;
