import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SaveSuccess } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import Popup from "shared/atoms/Popup";

const MarkTreatmentReadyConfirmation = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <FadedBackground />
      <Popup blankModal>
        <Wrapper>
          <Checkmark />
          <Title>{t("usercourse.activepopup.header")}</Title>
          <BodyText>{t("usercourse.activepopup.body")}</BodyText>
          <PrimaryButton onClick={() => onClose()}>{t("buttons.close")}</PrimaryButton>
        </Wrapper>
      </Popup>
    </>
  );
};

export default MarkTreatmentReadyConfirmation;

const Title = styled.div`
  font-size: 22px;
  line-height: 1.4em;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 32px;
  background-color: white;
`;

const FadedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.redesign.db60};
  opacity: 50%;
`;

const BodyText = styled.div`
  margin-bottom: 24px;
`;

const Checkmark = styled(SaveSuccess)`
  width: 32px;
  height: 32px;
`;
