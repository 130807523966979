import type { ChangeEvent } from "react";
import type React from "react";
import { useCallback, useEffect } from "react";

import { TextField } from "@material-ui/core";
import type { UseFormReturn } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { NumberFormatProps } from "react-number-format";
import NumberFormat from "react-number-format";
import styled from "styled-components";

import { MUIBorder, MUIError, MUIInput, MUITextInputLabel } from "../StyledInputs";

interface Props {
  readonly disabled?: boolean;
  readonly required?: boolean;
  /* also takes on the role of id for the label */
  readonly name: string;
  readonly label?: string;
  /* additional props */
  readonly instruction?: string;
  readonly error?: string;
  readonly className?: string;
  readonly dataTestId?: string;
  readonly autoComplete?: "on" | "off";
  readonly suffix: string;
  /* value or symbol to set when input is empty */
  readonly blankSymbol?: string;
  readonly onBlur?: (event: Parameters<NonNullable<NumberFormatProps["onBlur"]>>[0]) => void;
}

const MUIFormattedNumberInput: React.VFC<Props> = ({
  name,
  error,
  label = "",
  instruction = null,
  className,
  autoComplete = "off",
  dataTestId = "masked-input",
  disabled,
  suffix,
  blankSymbol = "",
  required,
  onBlur,
}) => {
  const { control, register, setValue, unregister } = useFormContext();
  const handleChange = useProxyFormatting(name, suffix, blankSymbol, {
    register,
    unregister,
    setValue,
  });

  const validate = required ? { whiteSpace: (v: string) => RegExp(/\S/).test(v) } : undefined;

  return (
    <Container className={className}>
      <Controller
        name={name}
        control={control}
        rules={{ required, validate }}
        render={({ field }) => (
          <NumberFormat
            {...field}
            autoComplete={autoComplete}
            control={control}
            customInput={StyledTextField}
            data-testid={dataTestId}
            disabled={disabled}
            error={!!error}
            helperText={error || instruction}
            label={label}
            suffix={suffix}
            type="tel"
            variant="outlined"
            onChange={handleChange}
            onBlur={onBlur}
          />
        )}
      />
    </Container>
  );
};

const StyledTextField = styled(TextField)`
  ${MUIInput}
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      padding: 23px 12px 7px;
    }
  }
  ${MUIBorder}
  ${MUITextInputLabel}
  ${MUIError}

  input::-webkit-inner-spin-button {
    margin-top: -12px;
    margin-bottom: 4px;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 0;
`;

const formatStringToNumber = (str: string, symbol: string, blankSymbol: string) => {
  if (!str) {
    return blankSymbol;
  }

  return Number(str.slice(0, -symbol.length));
};

const useProxyFormatting = (
  key: string,
  symbol: string,
  blankSymbol: string,
  {
    register,
    unregister,
    setValue,
  }: {
    register: UseFormReturn["register"];
    unregister: UseFormReturn["unregister"];
    setValue: UseFormReturn["setValue"];
  }
) => {
  // lazy register the field to prevent `getInputRef={register}` overriding the already formatted value
  useEffect(() => {
    register(key);
  }, [register, key]);

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      // necessary to keep NumberFormat & react-hook-form in sync
      unregister(key);
      // reregister to allow react-hook-form to do its job
      register(key);
      // set the value to the formatted value; account for validation & isDirty to trigger onSubmit mode="onBlur" case
      setValue(key, formatStringToNumber(value, symbol, blankSymbol), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },

    [key, register, setValue, unregister, symbol]
  );

  return handleChange;
};

export default MUIFormattedNumberInput;
