import styled from "styled-components";

interface RowProps {
  background?: string;
  align?: "flex-start" | "flex-end" | "center" | "stretch";
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
  reverse?: boolean;
  flex?: number;
  children: React.ReactNode | Array<React.ReactNode>;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex: ${props => props.flex || "1"};
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  justify-content: ${props => props.justify || "flex-start"};
  align-items: ${props => props.align || "stretch"};
  background: ${props => props.background || "transparent"};
`;

export default Row;
