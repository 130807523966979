import type React from "react";
import { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { EducationIcon, HelpCenterIcon, LibraryIcon, LogoutIcon, ProfileIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import AppVersion from "shared/atoms/AppVersion";
import NavigationLink from "shared/atoms/NavigationLink";
import { MarketTypes } from "types";
import { signOutUser } from "utils/auth";
import { isRouteAllowed, userIsDoctor } from "utils/profile/profileHelper";

interface Props {
  open: boolean;
  collapsedNavBar?: boolean | undefined;
  onClose: () => void;
}

const NavBarDropdown: React.VFC<Props> = ({ open, onClose, collapsedNavBar }) => {
  const { t } = useTranslation();
  const { profile, setProfile } = useProfileContext();
  const isDoctor = userIsDoctor(profile);
  const ifAllowed = (route: string) => !!(profile && isRouteAllowed(profile, route));

  const handleLogoutClick = async () => {
    await signOutUser();
    setProfile(undefined);
  };

  const clickAway = useCallback(() => {
    if (open) {
      onClose();
    }
  }, [open, onClose]);

  useEffect(() => {
    document.addEventListener("click", clickAway);
    return () => {
      document.removeEventListener("click", clickAway);
    };
  }, [clickAway]);

  const resourcesLink = getResourcesLink(profile?.market);
  const supportLink = getSupportLink(profile?.market);

  return (
    <DropdownList data-testid="dropdown-list" $open={open} $collapsedNavBar={collapsedNavBar}>
      <AppVersionLi data-testid="header-app-version">
        <HeaderAppVersion />
      </AppVersionLi>
      {!isDoctor && profile?.state === "ready" && (
        <>
          {/* Settings */}
          <ListItem data-testid="dropdown-item-settings">
            <InternalLink to="/settings">
              <ProfileIcon />
              <span>{t("dropdown.profile")}</span>
            </InternalLink>
          </ListItem>

          {/* Helpful Resources */}
          {resourcesLink && (
            <ListItem data-testid="dropdown-item-resources">
              <ExternalLink href={resourcesLink} target="_blank">
                <EducationIcon />
                <span>{t("dropdown.tutorial")}</span>
              </ExternalLink>
            </ListItem>
          )}

          {/* Library */}
          {ifAllowed("/library") && (
            <ListItem data-testid="dropdown-item-library">
              <InternalLink to="/library">
                <LibraryIcon />
                <span>{t("dropdown.library")}</span>
              </InternalLink>
            </ListItem>
          )}
        </>
      )}

      {/* FAQ & Support */}
      <ListItem data-testid="dropdown-item-support">
        <ExternalLink href={supportLink} target="_blank">
          <HelpCenterIcon />
          <span>{t("dropdown.support")}</span>
        </ExternalLink>
      </ListItem>

      {/* Logout */}
      <ListItem data-testid="dropdown-item-logout">
        <ExternalLink target="_blank" onClick={() => handleLogoutClick()}>
          <LogoutIcon />
          <span>{t("dropdown.logout")}</span>
        </ExternalLink>
      </ListItem>
    </DropdownList>
  );
};

export default NavBarDropdown;

const getResourcesLink = (market?: string) => {
  if (market === MarketTypes.GB) {
    return "https://drive.google.com/drive/folders/1DKllipDJGdf_KzqIm_Bj2A6f6_eCpaOV";
  }
  return null;
};

const getSupportLink = (market?: string) => {
  if (market === MarketTypes.GB) {
    return "https://jointacademy.my.salesforce-sites.com/support/gb/en/therapist";
  }
  if (market === MarketTypes.SE) {
    return "https://jointacademy.my.salesforce-sites.com/support/se/sv/therapist";
  }
  if (market === MarketTypes.US) {
    return "https://jointacademy.secure.force.com/support/us/en/therapist";
  }
  return "mailto:support@jointacademy.com";
};

type DropdownListProps = {
  $collapsedNavBar: boolean | undefined;
  $open: boolean;
};

const DropdownList = styled.div<DropdownListProps>`
  margin: 0;
  padding: 0;
  position: absolute;
  min-width: 240px;
  background-color: ${props => props.theme.colors.white};
  z-index: 1;
  transition: 0.3s;
  bottom: ${props => (props.$open ? "57px" : "-270px")};
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  opacity: ${props => (props.$open ? "1" : "0")};

  ${props =>
    props.$collapsedNavBar &&
    `
    left: ${props.$open ? "0px" : "-270px"};
    bottom: 56px;
    border: 1px solid ${props.theme.colors.greys.silver};
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 10%);
  `}
`;

const ListItem = styled.div`
  width: 240px;
  &:nth-child(2) a {
    border-top: none;
  }
`;

const linkStyles = css`
  ${props => ({ ...props.theme.font.link1 })};
  align-items: center;
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  gap: 12px;
  padding: 0px 12px;
  height: 56px;
  text-decoration: none;
  user-select: none;

  &:hover {
    opacity: 0.7;
  }

  & > :first-child {
    width: 30px;
  }
`;

const InternalLink = styled(NavigationLink)`
  ${linkStyles}
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const HeaderAppVersion = styled(AppVersion)`
  border-radius: 0 0 0 8px;
  background-color: ${props => props.theme.colors.redesign.b10};
`;

const AppVersionLi = styled.div`
  position: absolute;
  right: 0;
  box-sizing: border-box;
  margin-bottom: -8px;
  display: flex;
  flex-direction: column;
  align-items: end;
  user-select: none;
  & a :nth-child(n + 2) {
    margin-left: ${props => props.theme.spacing.S_20};
  }
`;
