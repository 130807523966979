import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

import type { GetCurrentCourseArgs, GetCurrentCourseResponse } from "../types/GetCurrentCourse";

export const GET_CURRENT_COURSE_QUERY_KEY = "GetCurrentCourse";

export const useGetCurrentCourseQuery = (
  args: GetCurrentCourseArgs,
  options?: UseQueryOptions<
    GetCurrentCourseResponse,
    Error,
    GetCurrentCourseResponse,
    Array<string | GetCurrentCourseArgs>
  >
): UseQueryResult<GetCurrentCourseResponse, Error> => {
  const client = useApiClient();

  return useQuery([GET_CURRENT_COURSE_QUERY_KEY, args], () => client.getPatientCurrentCourse(args), options);
};
