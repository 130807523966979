import { useContext } from "react";

import DropInCallContext from "./DropInCallContext";
import type { DropInCallContextValue } from "./DropInCallContextValue";

const useDropInCallContext = (): DropInCallContextValue => {
  const value = useContext(DropInCallContext);

  if (value === null) {
    throw new Error("useDropInCallContext cannot be used outside the DropInCallContextProvider");
  }
  return value;
};

export default useDropInCallContext;
