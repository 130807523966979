import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 24px;

  &:hover [data-testid="MarkAsUnread-menu-button"] {
    opacity: 1;
  }
`;

export const Message = styled.div<{ $sender?: boolean }>`
  ${({ theme, $sender }) => ($sender ? theme.font.message.therapist_body : theme.font.message.patient_body)}
  color: ${({ theme, $sender }) => ($sender ? theme.colors.white : theme.colors.greys.dark)};
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const DateTime = styled.div<{ $sender?: boolean }>`
  ${props => props.theme.font.message.date}
  color: ${({ theme, $sender }) => ($sender ? theme.colors.redesign.b30 : theme.colors.greys.newGrey)};
  text-align: ${({ $sender }) => ($sender ? "right" : "left")};
  margin-top: 4px;
`;
