import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CallDisconnectedIcon, CallFailedIcon } from "assets/videocall";

import type { CallState } from "../../types";

const IconContainer = styled.div`
  margin: 0 auto;
  position: relative;
  top: 33px;
`;

const DisconnectedIconContainer = styled(IconContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fd7b7b;
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StateText = styled.p`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.primary.base};
  font-size: 18px;
  margin: 0;
  margin-top: ${props => props.theme.spacing.S_25};
`;

const ErrorText = styled.p`
  ${props => props.theme.font.body2};
  color: ${props => props.theme.colors.primary.lighter};
  margin: 0;
`;

const IconDisconnected = styled(CallDisconnectedIcon)`
  width: 25xpx;
  height: 25px;
`;

const IconFailedCall = styled(CallFailedIcon)`
  width: 44pxpx;
  height: 44px;
`;

interface Props {
  readonly callState: CallState;
  readonly error?: string | null;
}

const StateInfo: React.VFC<Props> = ({ callState, error = null }) => {
  const { t } = useTranslation();

  if (callState === "CALLEE_DISCONNECTED") {
    return (
      <>
        <DisconnectedIconContainer>
          <IconDisconnected />
        </DisconnectedIconContainer>
        <TextContainer>
          <StateText>{t("videocall.disconnected")}</StateText>
        </TextContainer>
      </>
    );
  }
  if (callState === "CALLEE_REJECTED") {
    return (
      <>
        <DisconnectedIconContainer>
          <IconDisconnected />
        </DisconnectedIconContainer>
        <TextContainer>
          <StateText>{t("videocall.roomclosed")}</StateText>
        </TextContainer>
      </>
    );
  }
  return (
    <>
      <IconContainer>
        <IconFailedCall />
      </IconContainer>
      <TextContainer>
        <StateText>{t("videocall.disconnected")}</StateText>
        {error && <ErrorText>{error}</ErrorText>}
      </TextContainer>
    </>
  );
};

export default StateInfo;
