import { useMutation } from "react-query";

import type { NewAppointment } from "api/schemas/NewAppointment";
import type { PutAppointmentManagerArgs } from "api/types/PostAppointment";
import useApiClient from "api/useApiClient";

export const usePutAppointmentManager = () => {
  const client = useApiClient();

  return useMutation<NewAppointment, Error, PutAppointmentManagerArgs>(args => client.putAppointmentManager(args));
};
