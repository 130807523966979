import type React from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useGetTreatmentActivityLogsPatient from "api/hooks/useGetTreatmentActivityLogsPatient";
import { Accordion, AccordionItem } from "shared/atoms/Accordion";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import { Table, TableHeader } from "shared/molecules/Table";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import { ActivityLogForm } from "../components/ActivityLogForm";

const ActivityLog: React.VFC = () => {
  const { t } = useTranslation();
  const { format } = useLocalizedDate();
  const { patient, patientError } = useContext(CurrentPatientContext);
  const [showAddActivityForm, setShowAddActivityForm] = useState(false);

  const { data: activityLogsPatient, refetch } = useGetTreatmentActivityLogsPatient(patient.id);

  const logs = activityLogsPatient?.logs || {};

  return patientError ? (
    <ErrorContainer>
      <Notification variant="danger" style={{ margin: "10px" }}>
        {t("errors.generic")}
      </Notification>
    </ErrorContainer>
  ) : (
    <Container>
      <Table>
        <thead>
          <StyledTableHeader title={t("patients.menu.activity_log")}>
            <BaseButton
              text=""
              onClick={() => setShowAddActivityForm(true)}
              variant="tertiary"
              icon="circle-plus"
              iconPlacement="end"
            />
          </StyledTableHeader>
        </thead>
        <tbody>
          <Accordion>
            {logs &&
              Object.keys(logs).length > 0 &&
              Object.keys(logs)?.map(month => {
                const totalDuration = logs[month]?.reduce((acc, log) => acc + log.duration, 0);

                return (
                  <StyledAccordionItem
                    key={month}
                    header={format(new Date(month), "MMMM yyyy")}
                    content={
                      <List>
                        <ListItem>
                          <BrightCell>{t("patients.activity_log.table.date")}</BrightCell>
                          <DarkCell>{t("patients.activity_log.table.activity")}</DarkCell>
                          <BrightCell>{t("patients.activity_log.table.time")}</BrightCell>
                        </ListItem>
                        {logs[month]?.map(activity => (
                          <ListItem key={activity.id}>
                            <BrightCell>{format(new Date(activity.completed_at), "yyyy-MM-dd")}</BrightCell>
                            <DarkCell>{activity.description}</DarkCell>
                            <BrightCell>
                              {t("patients.activity_log.table.duration", { duration: activity.duration / 60 })}
                            </BrightCell>
                          </ListItem>
                        ))}
                        <ListItem>
                          <DarkCell>{t("patients.activity_log.table.summary")}</DarkCell>
                          <DarkCell />
                          <DarkCell>
                            {t("patients.activity_log.table.duration", { duration: totalDuration / 60 })}
                          </DarkCell>
                        </ListItem>
                      </List>
                    }
                  />
                );
              })}
          </Accordion>
        </tbody>
      </Table>

      {showAddActivityForm && <ActivityLogForm onClose={() => setShowAddActivityForm(false)} refetch={refetch} />}
    </Container>
  );
};

const ErrorContainer = styled.div`
  margin: 0 auto ${props => props.theme.spacing.S_20};
  max-width: 1000px;
  padding: ${props => props.theme.spacing.S_20};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledTableHeader = styled(TableHeader)`
  h6 {
    margin: 0 8px 0 0;
  }
`;

const StyledAccordionItem = styled(AccordionItem)`
  .header {
    background: ${({ theme }) => theme.colors.greys.light4};
  }

  .content {
    padding: 0;
  }
`;

const List = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  padding: 12px 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  }
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
`;

const BrightCell = styled(Cell)`
  color: ${({ theme }) => theme.colors.redesign.db60};
`;

const DarkCell = styled(Cell)`
  color: ${({ theme }) => theme.colors.redesign.db90};
`;

export default ActivityLog;
