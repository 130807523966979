import styled from "styled-components";

import { TableCell } from "shared/molecules/Table";

const NameWrapper = styled(TableCell)`
  margin-top: ${props => props.theme.spacing.S_10};
  ${props => props.theme.belowBreakpoint} {
    line-height: 25px;
  }
`;

const NameWrapperInner = styled.div`
  display: flex;
  align-items: center;
`;

const AfterNameIcon = styled.div`
  margin: auto auto auto ${props => props.theme.spacing.S_10};
`;

const TypeWrapper = styled(TableCell)`
  width: 30%;
  ${props => props.theme.belowBreakpoint} {
    width: 60%;
    padding: ${props => props.theme.spacing.S_5} 0;
    line-height: 25px;
  }
`;

const DateWrapper = styled(TableCell)`
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
`;

const Name = styled.div<{ $label_state: boolean }>`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    color: ${props => (props.$label_state ? props.theme.colors.tomato : props.theme.colors.primary.base)};
  }
`;

const Closed = styled.div`
  margin-left: ${props => props.theme.spacing.S_10};
  color: ${props => props.theme.colors.tomato};
  font-weight: ${props => props.theme.fontWeight.regular};
  ${props => props.theme.belowBreakpoint} {
    display: none;
    margin-left: 0;
  }
`;

const Type = styled.div`
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};

  ${props => props.theme.belowBreakpoint} {
    display: flex;
    flex-flow: column nowrap;
  }
`;

const DateContainer = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.light};
`;

export { NameWrapper, NameWrapperInner, TypeWrapper, DateWrapper, Name, Closed, Type, DateContainer, AfterNameIcon };
