import type React from "react";
import { useEffect } from "react";

import { useMatch } from "react-router-dom";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { Feature } from "utils/features/types";

import RecordNoteAndReferralList from "../RecordNoteAndReferralList";
import RecordNoteList from "../RecordNoteList";

import { Container, RecordContainer, RecordRow } from "./helpers";

const Journal: React.VFC = () => {
  const { hasFeature } = useFeatureToggleContext();
  const match = useMatch("*");

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.MEDICAL_RECORD, match.pathname);
    }
  }, [match?.pathname]);

  return (
    <Container data-testid="journal">
      {hasFeature(Feature.REFERRALS) ? (
        <RecordRow>
          <RecordContainer>
            <RecordNoteAndReferralList />
          </RecordContainer>
        </RecordRow>
      ) : (
        <RecordRow>
          <RecordContainer>
            <RecordNoteList />
          </RecordContainer>
        </RecordRow>
      )}
    </Container>
  );
};

export default Journal;
