import type React from "react";
import { useEffect } from "react";

import type { LocalTrack, LocalTrackPublication } from "twilio-video";

import AudioTrack from "../video/AudioTrack";
import useTrack from "../video/hooks/useTrack";
import VideoTrack from "../video/VideoTrack";

interface Props {
  readonly publication: LocalTrackPublication;
  readonly audioEnabled: boolean;
  readonly videoEnabled: boolean;
}

const MainPublication: React.VFC<Props> = ({ publication, audioEnabled, videoEnabled }) => {
  const track = useTrack(publication) as LocalTrack;

  useEffect(() => {
    if (track.kind === "audio") {
      if (audioEnabled) {
        track.enable();
      } else {
        track.disable();
      }
    }
  }, [audioEnabled, track]);

  useEffect(() => {
    if (track.kind === "video") {
      if (videoEnabled) {
        track.enable();
      } else {
        track.disable();
      }
    }
  }, [videoEnabled, track]);

  if (track) {
    if (track.kind === "video") {
      return <VideoTrack track={track} isMain />;
    }

    if (track.kind === "audio") {
      return <AudioTrack track={track} />;
    }
  }

  return null;
};

export default MainPublication;
