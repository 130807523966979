import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useGetTherapistEarnings } from "api/hooks/useGetTherapistEarnings";
import { useProfileContext } from "contexts/ProfileContext";
import Pagination from "shared/atoms/Pagination";
import { Table, TableHeader, TableMissingContent } from "shared/molecules/Table";
import useLocalizedDate from "utils/date";

import { earnings } from "../../utils";

const EarningsTable = (): JSX.Element => {
  const { t } = useTranslation();
  const { add, sub, format, set, isAfter, isBefore } = useLocalizedDate();
  const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  const { profile } = useProfileContext();

  invariant(profile);

  const { id: therapistId, created_at: createdAt } = profile;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const { data, error, isLoading } = useGetTherapistEarnings({ date: selectedMonth, therapistId });

  const hasPreviousPage = isAfter(selectedMonth, new Date(createdAt ?? ""));
  const hasNextPage = isBefore(selectedMonth, currentMonth);

  const currency = data?.currency;

  const renderedRows = earnings(data)
    ?.filter(activity => !(activity.count === 0 && activity.name !== "total"))
    .map(activity => {
      const earnedAmount = activity.earnings;
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const earning = currency && t(`common.currency.amountIn${currency}`, { amount: earnedAmount });

      return (
        <TableRow key={activity.name}>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <NameCell>{t(`dashboard.tables.earnings.rows.${activity.name}`)}</NameCell>
          <AmountCell>{activity.count}</AmountCell>
          <EarningCell data-testid={`${activity.name}-amount`}>{earning}</EarningCell>
        </TableRow>
      );
    });

  const dateLabel = format(selectedMonth, "MMM yyyy").toLocaleUpperCase();

  return (
    <Container height={isLoading ? "490px" : "auto"}>
      <Table>
        <thead>
          <StyledTableHeader title={t("dashboard.tables.earnings.title")} colSpan={3} justify="space-between">
            <Pagination
              label={dateLabel}
              pageInfo={{ hasNextPage, hasPreviousPage }}
              onPageChange={goTo => {
                setSelectedMonth(
                  goTo === "next" ? add(selectedMonth, { months: 1 }) : sub(selectedMonth, { months: 1 })
                );
              }}
            />
          </StyledTableHeader>
        </thead>
        <tbody>
          {error || (isLoading && !data) ? (
            <TableMissingContent columnCount={3} error={!!error} loading={isLoading} />
          ) : (
            renderedRows
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default EarningsTable;

const Container = styled.div<{ height: string }>`
  height: ${props => props.height};
`;

const StyledTableHeader = styled(TableHeader)`
  ${props => props.theme.belowMobileBreakpoint} {
    padding: 0 ${props => props.theme.spacing.S_10};
  }
`;

const TableRow = styled.tr`
  ${props => props.theme.font.table.row};
  display: flex;
  height: 42px;
  line-height: 20px;

  &:last-child {
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.colors.primary.base};
    background-color: ${props => props.theme.colors.greys.light2};
  }

  &:last-child > td {
    border-bottom-style: none;
  }
`;

const Cell = styled.td`
  border: none;
  padding: ${props => props.theme.spacing.S_10};
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
  ${props => props.theme.belowMobileBreakpoint} {
    padding: ${props => props.theme.spacing.S_10} 8px;
  }
`;

const NameCell = styled(Cell)`
  flex-grow: 4;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AmountCell = styled(Cell)`
  border-left: 1px solid ${props => props.theme.colors.greys.silver};
  border-right: none;
`;

const EarningCell = styled(Cell)`
  text-align: right;
  flex-grow: 2;
`;
