export const translateError = (code: string) => {
  // the code strings are implemented on the BE
  switch (code) {
    case "BOK:APPOINTMENT_ALREADY_STARTED": {
      return "errors.BOK_APPOINTMENT_ALREADY_STARTED";
    }
    case "BOK:APPOINTMENT_ALREADY_DELETED": {
      return "errors.BOK_APPOINTMENT_ALREADY_DELETED";
    }
    case "BOK:CAN_NOT_RESCHEDULE": {
      return "errors.BOK_CAN_NOT_RESCHEDULE";
    }
    case "REQ:INVALID_TIME": {
      return "errors.invalid_time";
    }
    default:
      return "errors.generic";
  }
};
