import type React from "react";

import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TextButton } from "shared/atoms/Button";
import useLocalizedDate from "utils/date";

interface Props {
  dateSigned: string;
  onClose: () => void;
  fileLink: string | null;
  duration: number;
}

const PlanOfCareCertificationBox: React.VFC<Props> = ({ onClose, dateSigned, fileLink, duration }) => {
  const { t } = useTranslation();
  const { parseISO, format } = useLocalizedDate();
  const dateSignedParsed = parseISO(dateSigned);
  const validUntil = addDays(dateSignedParsed, duration);
  return (
    <Container data-testid="plan-of-care-box">
      <TextWrapper>
        <span>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(`patient_header.plan_of_care_box.certified`)}
          <strong>{format(dateSignedParsed, "PP")}</strong>
        </span>
      </TextWrapper>
      <TextWrapper>
        <span>
          {/* FIXME: type translation */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(`patient_header.plan_of_care_box.valid_until`)}
          <strong>{format(validUntil, "PP")}</strong>
        </span>
      </TextWrapper>
      {fileLink && (
        <ViewLink href={fileLink} target="_blank">
          {t(`buttons.view`)}
        </ViewLink>
      )}
      <StyledTextButton onClick={() => onClose()}>{t("buttons.dismiss")}</StyledTextButton>
    </Container>
  );
};

const Container = styled.div`
  background: ${props => props.theme.colors.confirmation_green};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: 0 0 0 ${props => props.theme.spacing.S_20};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
  }
`;

const StyledTextButton = styled(TextButton)`
  margin-left: auto;
  padding: 15px 0;
  min-width: 100px;
  ${props => props.theme.belowBreakpoint} {
    padding: 5px;
    margin: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const ViewLink = styled.a`
  ${props => props.theme.font.button_text};
  color: ${props => props.theme.colors.redesign.b100};
  ${props => props.theme.aboveBreakpoint} {
    margin: auto 0 auto auto;
  }
  text-decoration: none;
`;

const TextWrapper = styled.p`
  flex-shrink: 0;
  display: flex;
  margin: 0;
  span {
    color: ${props => props.theme.colors.greys.dark};
    ${props => props.theme.font.body1};
    ${props => props.theme.belowBreakpoint} {
      ${props => props.theme.font.body2};
    }
    &:first-child {
      margin-right: 10px;
    }
  }
  ${props => props.theme.belowBreakpoint} {
    padding-bottom: ${props => props.theme.spacing.S_10};
    ${props => props.theme.font.link3};
  }
`;

export default PlanOfCareCertificationBox;
