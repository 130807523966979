import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { State } from "api/schemas/NewAppointment";

interface Props {
  className?: string;
  state: State;
}

export const AppointmentState: React.VFC<Props> = ({ className, state }) => {
  const { t } = useTranslation();

  if (!state) {
    return <></>;
  }

  return <Container className={className}>{t(`booking.event_details.${state}`)}</Container>;
};

const Container = styled.span`
  text-transform: uppercase;
`;
