import type { RefObject } from "react";
import type React from "react";
import { useCallback, useEffect, useRef } from "react";

import type { UseSpringProps } from "react-spring";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

type OffsetX = "right" | "left" | "center";
type OffsetY = "top" | "bottom" | "side";
type Offset =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right"
  | "side-left"
  | "side-right";

const Wrapper = styled(animated.div)<{ $x: OffsetX; $y: OffsetY; $offset: Offset }>`
  position: absolute;
  left: ${props => {
    switch (props.$x) {
      case "left":
        return "0";
      case "right":
        return "100%";
      default:
        return "50%";
    }
  }};
  ${props => (props.$y !== "side" ? `${props.$y}: 0` : "top: 0")};
  transform-origin: ${props => (props.$y !== "side" ? `${props.$y} ${props.$x}` : `top ${props.$x}`)};
  transform: ${props => {
    switch (props.$offset) {
      case "top-left":
        return "translate(0%, calc(-100% - 10px))";
      case "top-right":
        return "translate(-100%, calc(-100% - 10px))";
      case "bottom-left":
        return "translate(0%, calc(100% + 10px))";
      case "bottom-center":
        return "translate(-50%, calc(100% + 10px))";
      case "bottom-right":
        return "translate(-100%, calc(100% + 10px))";
      case "side-right":
        return "translate(15px, -10px)";
      case "side-left":
        return "translate(calc(-100% - 10px), -5px)";
      default:
        return "translate(-50%, calc(-100% - 10px))";
    }
  }};
  z-index: ${props => props.theme.zIndex.info};
`;

const Container = styled.div`
  position: relative;
  border-radius: ${props => props.theme.borderRadius.basic};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.S_15};
`;

const TailWrapper = styled.div<{ $y: OffsetY; $x: OffsetX }>`
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  ${props => (props.$y === "top" ? "bottom" : "top")}: 0;
  transform-origin: ${props => props.$y};
  transform: ${props => (props.$y === "top" ? "translateY(100%)" : "translateY(-100%)")};
  width: 100%;
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => {
    switch (props.$x) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
  padding: 0 ${props => props.theme.spacing.S_15};
`;

const SideTailWrapper = styled.div<{ $y: OffsetY; $x: OffsetX }>`
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  ${props => props.$y === "side" && `top: 0%; ${props.$x === "left" ? "right" : "left"}: 0%;`}
  transform-origin: top;
  transform: ${props => (props.$x === "left" ? "translateX(100%)" : "translateX(-100%)")};
  width: 14px;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: ${props => props.theme.spacing.S_15} 0;
`;

const Tail = styled.div<{ $y: OffsetY }>`
  margin-bottom: 50%;
  width: 15px;
  height: 15px;
  background: ${props => props.theme.colors.white};
  transform: ${props => `translateY(${props.$y === "top" ? "-50%" : "50%"}) rotate(45deg)`};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
`;

const SideTail = styled.div<{ $x: OffsetX }>`
  width: 15px;
  height: 15px;
  background: ${props => props.theme.colors.white};
  transform: ${props => `translateX(${props.$x === "left" ? "-50%" : "50%"}) rotate(45deg)`};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
`;

type SpringProps = {
  opacity: number;
  top?: number;
  bottom?: number;
  left?: number;
  transform?: string;
};

export interface Props {
  children: React.ReactNode;
  requestClose?: () => void;
  // To be able to temporarily prevent this feature if for example another dialog is opened above
  ignoreClickOutside?: boolean;
  isOpen: boolean;
  offset?: Offset;
  dataTestId?: string;
}

const Tooltip: React.VFC<Props> = ({
  children,
  isOpen,
  offset = "top-center",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestClose = () => {},
  ignoreClickOutside = false,
  dataTestId = "info",
}) => {
  const ref = useRef<HTMLDivElement>();
  const y = offset.split("-")[0] as OffsetY;
  const x = offset.split("-")[1] as OffsetX;

  const animatedStyles = useSpring(
    (() => {
      const baseAnimation = { opacity: isOpen ? 1 : 0 };
      if (y === "top") return { ...baseAnimation, top: isOpen ? 0 : -10 } as UseSpringProps<SpringProps>;
      if (y === "bottom") return { ...baseAnimation, bottom: isOpen ? 0 : -10 } as UseSpringProps<SpringProps>;
      if (x === "left") return { ...baseAnimation, left: isOpen ? 0 : -10 } as UseSpringProps<SpringProps>;
      if (x === "right") return { ...baseAnimation, marginRight: isOpen ? 25 : 35 } as UseSpringProps<SpringProps>;
      return {
        ...baseAnimation,
      } as UseSpringProps<SpringProps>;
    })()
  );

  const clickAway = useCallback(
    e => {
      if (!ref.current?.contains(e.target) && !ignoreClickOutside) {
        requestClose();
      }
    },
    [requestClose, ignoreClickOutside]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("click", clickAway);
    return () => {
      if (isOpen) document.removeEventListener("click", clickAway);
    };
  }, [clickAway, isOpen]);

  return (
    <Wrapper
      style={{
        ...animatedStyles,
        display: animatedStyles?.opacity.interpolate(o => (o === 0 ? "none" : "initial")),
      }}
      data-testid={dataTestId}
      ref={ref as RefObject<HTMLDivElement>}
      $offset={offset}
      $y={y}
      $x={x}
    >
      <Container>{children}</Container>
      {y !== "side" ? (
        <TailWrapper $y={y} $x={x}>
          <Tail $y={y} />
        </TailWrapper>
      ) : (
        <SideTailWrapper $y={y} $x={x}>
          <SideTail $x={x} />
        </SideTailWrapper>
      )}
    </Wrapper>
  );
};

export default Tooltip;
