import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import type { Patient } from "routes/patients/PatientsList/queries/fetchPatients";
import Pagination from "shared/atoms/Pagination";
import PremiumIcon from "shared/atoms/PremiumIcon";
import {
  Table,
  TableCell,
  TableFooter,
  TableHeader,
  TableMissingContent,
  TableRow,
  TableSort,
} from "shared/molecules/Table";
import sort, { createAlphabeticalCompare, createNumericalCompare } from "utils/array/sort";
import useLocalizedDate from "utils/date";
import { Feature } from "utils/features/types";
import { useWindowSize } from "utils/hooks";
import { getStorageValue, setStorageValue } from "utils/storage";
import isNonEmptyString from "utils/string/is-non-empty-string";

import usePatientsReadyForDischargeQuery from "./usePatientsReadyForDischargeQuery";

type SortingState = {
  column: string;
  descending: boolean;
};

const PAGE_SIZE = 10;

const ReadyForDischargeTable: React.VFC = props => {
  const { t } = useTranslation();
  const { parseISO, format, formats } = useLocalizedDate();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useContext(ThemeContext);
  const windowSize = useWindowSize();

  const isLargeScreen = windowSize.width > parseInt(theme.breakpoint, 10);
  const columnIds = isLargeScreen ? ["patient", "careEvents", "date"] : ["patient", "careEvents"];

  const [sorting, setSorting] = useState<SortingState>({
    column: "careEvents",
    descending: true,
  });

  const [pageOffset, setOffset] = useState(0);
  const [patientsToRender, setPatientsToRender] = useState<Patient[]>([]);
  const { profile } = useProfileContext();
  const { hasFeature } = useFeatureToggleContext();

  if (!profile) {
    throw new Error("No user profile");
  }

  const isSelfCareEnabled = hasFeature(Feature.SELFCARE);
  const patientsQuery = usePatientsReadyForDischargeQuery({ therapistId: profile.id }, { enabled: isSelfCareEnabled });

  useEffect(() => {
    const storedValue = getStorageValue("dashboard.discharge.sort");

    if (storedValue) {
      setSorting(storedValue);
    }
  }, []);

  useEffect(() => {
    if (patientsQuery.data) {
      const compareAlphabetically = createAlphabeticalCompare({
        descending: sorting.descending,
        ignoreCase: true,
      });
      const compareNumerically = createNumericalCompare({
        descending: sorting.descending,
      });

      const sortedPatients = sort(patientsQuery.data, (a, b) => {
        switch (sorting.column) {
          case "patient":
            return compareAlphabetically(a.name, b.name);
          case "careEvents":
            return compareNumerically(a.billableCareEvents ?? 0, b.billableCareEvents ?? 0);
          case "date":
            return compareAlphabetically(a.premiumActivationOccurredAt ?? "", b.premiumActivationOccurredAt ?? "");
          default:
            throw new Error("Unrecognized column");
        }
      });
      const nextPatients = sortedPatients.slice(pageOffset, pageOffset + PAGE_SIZE);

      setPatientsToRender(nextPatients);
    }
  }, [patientsQuery.data, pageOffset, sorting.descending, sorting.column]);

  if (!isSelfCareEnabled) {
    return null;
  }

  const phrases: Record<string, string> = {
    title: t("dashboard.tables.discharge.title"),
    patient: t("dashboard.tables.discharge.labels.patient"),
    careEvents: t("dashboard.tables.discharge.labels.careEvents"),
    date: t("dashboard.tables.discharge.labels.date"),
    closedAccount: t("errors.account_closed"),
  };

  if (patientsQuery.isError || patientsQuery.isLoading) {
    return (
      <div {...props}>
        <Table>
          <thead>
            <TableHeader colSpan={columnIds.length} title={phrases.title} />
          </thead>
          <tbody>
            <TableMissingContent error={patientsQuery.isError} loading={patientsQuery.isLoading} />
          </tbody>
          <tfoot />
        </Table>
      </div>
    );
  }

  const patientCount = patientsQuery.data?.length ?? 0;
  const hasPatients = patientCount > 0;

  const nextPageStart = pageOffset + PAGE_SIZE;

  const hasPreviousPage = pageOffset !== 0;
  const hasNextPage = patientCount > nextPageStart;

  const firstPageItem = pageOffset + 1;
  const lastPageItem = nextPageStart < patientCount ? nextPageStart : patientCount;

  const onPageChange = (action: string) => {
    if (action === "next") {
      setOffset(pageOffset + PAGE_SIZE);
    } else {
      setOffset(pageOffset - PAGE_SIZE);
    }
  };

  const onSortColumn = (column: string) => {
    const isSameColumn = sorting.column === column;
    const nextSortSetting = {
      column,
      descending: isSameColumn ? !sorting.descending : sorting.descending,
    };

    setSorting(nextSortSetting);
    setOffset(0);
    setStorageValue("dashboard.discharge.sort", nextSortSetting);
  };

  const navigateToPatientDetails = (patientId: number) =>
    navigate(`/patients/${patientId}`, {
      state: {
        pathname,
        text: "dashboard",
      },
    });

  return (
    <div {...props}>
      <Table>
        <thead>
          <TableHeader
            colSpan={columnIds.length}
            notifications={patientCount}
            showZeroNotifications
            title={phrases.title}
          />
          {hasPatients && (
            <TableSort
              columns={columnIds}
              descending={!sorting.descending}
              onSort={onSortColumn}
              sortBy={sorting.column}
              translateColumn={column => phrases[column]}
            />
          )}
        </thead>
        <tbody>
          {hasPatients &&
            patientsToRender.map((patient: Patient) => {
              const formattedDate = isNonEmptyString(patient.premiumActivationOccurredAt)
                ? format(parseISO(patient.premiumActivationOccurredAt), formats.LONG_LOCALIZED_DATE_PP)
                : null;

              return (
                <TableRow key={patient.id} onClick={() => navigateToPatientDetails(patient.id)}>
                  <NameCell dataTestId="discharge-patient">
                    <PremiumIcon size="small" premiumType={patient.premiumType} margin="0 4px 0 0 " />
                    <Name $deleted={patient.deleted}>
                      {patient.name}
                      {patient.deleted && <Closed data-testid="closed-account">{phrases.closedAccount}</Closed>}
                    </Name>
                  </NameCell>
                  <CareEventsCell dataTestId="discharge-care-events">
                    <CareEvents data-testid={`patient-${patient.id}`}>{patient.billableCareEvents}</CareEvents>
                  </CareEventsCell>
                  {isLargeScreen && (
                    <DateCell dataTestId="discharge-date">
                      <Date>{formattedDate}</Date>
                    </DateCell>
                  )}
                </TableRow>
              );
            })}
        </tbody>
        <tfoot>
          {patientCount > PAGE_SIZE && (
            <TableFooter colSpan={columnIds.length} addTopBorder>
              <Pagination
                first={firstPageItem}
                last={lastPageItem}
                onPageChange={onPageChange}
                pageInfo={{ hasNextPage, hasPreviousPage }}
                totalCount={patientCount}
              />
            </TableFooter>
          )}
        </tfoot>
      </Table>
    </div>
  );
};

export default ReadyForDischargeTable;

const NameCell = styled(TableCell)`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.spacing.S_10};
  ${props => props.theme.belowBreakpoint} {
    line-height: 25px;
  }
`;

const CareEventsCell = styled(TableCell)`
  ${props => props.theme.belowBreakpoint} {
    line-height: 25px;
  }
`;

const DateCell = styled(TableCell)`
  ${props => props.theme.belowBreakpoint} {
    line-height: 25px;
  }
`;

const Name = styled.div<{ $deleted: boolean }>`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    color: ${props => (props.$deleted ? props.theme.colors.tomato : props.theme.colors.primary.base)};
  }
`;

const Closed = styled.div`
  margin-left: ${props => props.theme.spacing.S_10};
  color: ${props => props.theme.colors.tomato};
  font-weight: ${props => props.theme.fontWeight.regular};
  ${props => props.theme.belowBreakpoint} {
    display: none;
    margin-left: 0;
  }
`;

const CareEvents = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.regular};
  max-width: 90px;
  text-align: center;

  ${props => props.theme.belowBreakpoint} {
    display: flex;
    flex-flow: column nowrap;
  }
`;

const Date = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.light};
`;
