/* eslint-disable @typescript-eslint/ban-ts-comment */
import i18n from "i18next";

import type { AvailableAilmentsResponse } from "api/schemas/AvailableAilments";
import type { PatientAvailableJointsResponse } from "api/schemas/ManagerBooking";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import {
  JaBack,
  JaCalf,
  JaElbow,
  JaFallPrevention,
  JaFoot,
  JaHand,
  JaHip,
  JaKnee,
  JaNeck,
  JaOsteoporosis,
  JaShoulder,
  JaWrist,
} from "assets";

import type { JointType } from "../components/AdminBookingForm/screens/HealthQuestionnaire/HealthQuestionnaire";

type AilmentType = Exclude<JointType, "other"> | "fall_prevention" | "osteoporosis";
export type AilmentItem = {
  ailment: Ailment;
  icon: React.ReactNode;
  label: string;
  value: AilmentType;
  hasLateralLocation: boolean;
};

export const ailmentConfig: Record<AilmentType, AilmentItem> = {
  neck: {
    ailment: "joint_pain",
    icon: <JaNeck />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.neck.0"),
    value: "neck",
    hasLateralLocation: false,
  },
  shoulder: {
    ailment: "joint_pain",
    icon: <JaShoulder />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.shoulder.0"),
    value: "shoulder",
    hasLateralLocation: true,
  },
  elbow: {
    ailment: "joint_pain",
    icon: <JaElbow />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.elbow.0"),
    value: "elbow",
    hasLateralLocation: true,
  },
  wrist: {
    ailment: "joint_pain",
    icon: <JaWrist />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.wrist.0"),
    value: "wrist",
    hasLateralLocation: true,
  },
  hand: {
    ailment: "joint_pain",
    icon: <JaHand />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.hand.0"),
    value: "hand",
    hasLateralLocation: true,
  },
  lower_back: {
    ailment: "joint_pain",
    icon: <JaBack />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.back.0"),
    value: "lower_back",
    hasLateralLocation: false,
  },
  hip: {
    ailment: "joint_pain",
    icon: <JaHip />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.hip.0"),
    value: "hip",
    hasLateralLocation: true,
  },
  knee: {
    ailment: "joint_pain",
    icon: <JaKnee />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.knee.0"),
    value: "knee",
    hasLateralLocation: true,
  },
  lower_leg: {
    ailment: "joint_pain",
    icon: <JaCalf />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.lower_leg.0"),
    value: "lower_leg",
    hasLateralLocation: true,
  },
  foot: {
    ailment: "joint_pain",
    icon: <JaFoot />,
    // @ts-ignore
    label: i18n.t("patients.pain_location.foot.0"),
    value: "foot",
    hasLateralLocation: true,
  },
  fall_prevention: {
    ailment: "fall_prevention",
    icon: <JaFallPrevention />,
    label: i18n.t("patients.pain_location.fall_prevention"),
    value: "fall_prevention",
    hasLateralLocation: false,
  },
  osteoporosis: {
    ailment: "osteoporosis",
    icon: <JaOsteoporosis />,
    label: i18n.t("patients.pain_location.osteoporosis"),
    value: "osteoporosis",
    hasLateralLocation: false,
  },
};

interface Args {
  availableAilments?: AvailableAilmentsResponse;
  availableJoints?: PatientAvailableJointsResponse;
}

export const getAilments = ({ availableAilments, availableJoints }: Args) => {
  const jointsArray: AilmentItem[] = [];

  if (availableJoints) {
    if (availableJoints.neck) {
      jointsArray.push(ailmentConfig.neck);
    }

    if (availableJoints.shoulder) {
      jointsArray.push(ailmentConfig.shoulder);
    }

    if (availableJoints.elbow) {
      jointsArray.push(ailmentConfig.elbow);
    }

    if (availableJoints.wrist) {
      jointsArray.push(ailmentConfig.wrist);
    }

    if (availableJoints.hand) {
      jointsArray.push(ailmentConfig.hand);
    }

    if (availableJoints.lower_back) {
      jointsArray.push(ailmentConfig.lower_back);
    }

    if (availableJoints.hip) {
      jointsArray.push(ailmentConfig.hip);
    }

    if (availableJoints.knee) {
      jointsArray.push(ailmentConfig.knee);
    }

    if (availableJoints.lower_leg) {
      jointsArray.push(ailmentConfig.lower_leg);
    }

    if (availableJoints.foot) {
      jointsArray.push(ailmentConfig.foot);
    }
  }

  if (availableAilments) {
    if (availableAilments.fall_prevention) {
      jointsArray.push(ailmentConfig.fall_prevention);
    }

    if (availableAilments.osteoporosis) {
      jointsArray.push(ailmentConfig.osteoporosis);
    }
  }

  return jointsArray;
};
