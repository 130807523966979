import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { UserCourseLesson } from "api/schemas/UserCourse";
import Caption from "shared/atoms/Typography/Caption";

import { JointTag } from "./SharedStyledComponents";

const LessonPickerItem = ({
  item,
  checked,
  onChecked,
}: {
  item: UserCourseLesson;
  checked: boolean;
  onChecked: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Root
      onClick={() => {
        onChecked();
      }}
    >
      <Row>
        <Details>
          <Title>{item.title}</Title>
          <div>
            <Caption>
              {item.parts_count}
              {` `}
              {t("usercourse.parts")}
            </Caption>
          </div>
        </Details>
        <Illustration>
          <img alt="" src={`${item.image_url}`} height="100%" />
        </Illustration>
      </Row>

      <Tags>
        {item.target_joints &&
          item.target_joints.map(j => (
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <JointTag key={j}>{t(`patients.pain_location.${j}.0`)}</JointTag>
          ))}
      </Tags>

      <BottomWrapper>
        <BottomRow>
          <Catcher
            onClick={e => {
              e.stopPropagation();
            }}
          />
          <AddIcon checked={checked} />
        </BottomRow>
      </BottomWrapper>
    </Root>
  );
};

const AddIcon = ({ checked }: { checked: boolean }) => {
  return checked ? <AddChecked icon={faCircleCheck} /> : <AddUnChecked icon={faCirclePlus} />;
};

const Root = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 300px;
  border: 1px solid ${props => props.theme.colors.greys.concrete};
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 694px) {
    width: 100%;
  }
  background-color: white;
  &:hover {
    border-color: ${({ theme }) => theme.colors.redesign.b100};
    background-color: ${({ theme }) => theme.colors.redesign.b10};
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Catcher = styled.div``;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const BottomWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;

const Row = styled.div`
  display: flex;
`;

const Details = styled.div`
  flex-grow: 1;
`;

const Illustration = styled.div`
  height: 88px;
`;

const AddUnChecked = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: #273546;
`;

const AddChecked = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: #60c286;
`;

const Tags = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;
export default LessonPickerItem;
