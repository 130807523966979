import type React from "react";
import { useEffect, useRef } from "react";

import { createPortal } from "react-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

import { BackIcon, CrossIcon } from "assets";
import Row from "shared/atoms/Row";
import { useWindowSize } from "utils/hooks";

const CORNER_CONTAINER_WIDTH = 46;

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: ${props => props.theme.zIndex.modal};
  overflow-x: scroll;
`;

const Header = styled(Row)`
  height: 46px;
  margin-bottom: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
`;

const TitleContainer = styled.div<{ $alignTitle: string }>`
  width: calc(100% - ${CORNER_CONTAINER_WIDTH * 2}px);
  text-align: ${props => props.$alignTitle};
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  justify-content: space-between;
  ${props => props.theme.font.modal.title};
`;

const CornerContainer = styled.div`
  width: ${CORNER_CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RightCornerContainer = styled(CornerContainer)`
  justify-content: flex-end;
`;

const Content = styled.div`
  height: calc(100% - 48px);
  overflow: scroll;
`;

const rootModal = document.getElementById("root");
const Portal = ({ children }: Pick<Props, "children">) => {
  const mainDivRef = useRef(document.createElement("div"));

  useEffect(() => {
    const mainDiv = mainDivRef.current;
    if (rootModal && mainDiv) {
      rootModal.appendChild(mainDiv);
    }
    return () => {
      if (rootModal && mainDiv) {
        rootModal.removeChild(mainDiv);
      }
    };
  }, []);

  return mainDivRef.current ? createPortal(children, mainDivRef.current) : null;
};

interface Props {
  readonly open: boolean;
  readonly close: () => void;
  readonly title?: string | React.ReactNode;
  readonly alignTitle?: "left" | "center" | "right";
  readonly children?: React.ReactNode;
  readonly status?: React.ReactNode;
  readonly altIcon?: boolean;
  readonly top?: number;
}

const Modal: React.VFC<Props> = ({
  open,
  close,
  title = "",
  altIcon = false,
  children = null,
  status = null,
  alignTitle = "center",
  top = 0,
}) => {
  const { height } = useWindowSize();

  const transitions = useTransition(open, null, {
    from: { top: `${height}px`, opacity: 1 },
    enter: { top: `${top}px`, opacity: 1 },
    leave: { top: `${height}px`, opacity: 1 },
    unique: true,
  });

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper data-testid="modal" key={key} style={props}>
              <Header>
                <CornerContainer
                  data-testid="close-modal"
                  onClick={event => {
                    close();
                    event.stopPropagation();
                  }}
                >
                  {altIcon ? <BackIcon /> : <CrossIcon />}
                </CornerContainer>
                <TitleContainer $alignTitle={alignTitle}>{title}</TitleContainer>
                <RightCornerContainer>{status}</RightCornerContainer>
              </Header>
              <Content>{children}</Content>
            </Wrapper>
          )
      )}
    </Portal>
  );
};

export default Modal;
