const moveItem = <T>(items: T[], fromIndex: number, toIndex: number): T[] => {
  const copy = [...items];
  const max = copy.length - 1;

  if (fromIndex < 0 || toIndex < 0) {
    throw new Error("Index cannot be negative");
  }
  if (fromIndex > max || toIndex > max) {
    throw new Error("Index cannot be greater than the length of the array");
  }
  if (fromIndex === toIndex) {
    return copy;
  }
  const [item] = copy.splice(fromIndex, 1);

  copy.splice(toIndex, 0, item);
  return copy;
};

export default moveItem;
