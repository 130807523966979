import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Card from "shared/atoms/Card";

import ReferralNoteForm from "./ReferralNoteForm";

const ReferralCard = styled(Card)`
  flex-direction: column;
  align-items: initial;
  padding: ${props => props.theme.spacing.S_20};
`;

const Title = styled.span`
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin: auto 0;
`;

interface Props {
  onDone: () => void;
}
const ReferralNotes: React.VFC<Props> = ({ onDone }) => {
  const { t } = useTranslation();

  return (
    <ReferralCard border>
      <Title>{t("doctor.referrals.sign")}</Title>
      <ReferralNoteForm onDone={onDone} />
    </ReferralCard>
  );
};

export default ReferralNotes;
