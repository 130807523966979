import { css } from "styled-components";

const MUILabelBase = css`
  color: ${props => props.theme.colors.redesign.db60};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
`;

const LABEL_VERTICAL_OFFSET = 18;
const LABEL_SHRINK_VERTICAL_OFFSET = 8;

const MUITextInputLabel = css`
  .MuiFormLabel-root {
    ${MUILabelBase}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(12px, ${LABEL_VERTICAL_OFFSET}px) scale(1);
    max-width: calc(100% - ${LABEL_VERTICAL_OFFSET * 2}px);
    &.MuiInputLabel-shrink {
      transform: translate(12px, ${LABEL_SHRINK_VERTICAL_OFFSET}px) scale(0.75);
      max-width: calc(100% - ${LABEL_SHRINK_VERTICAL_OFFSET}px);
    }

    &.Mui-focused {
      color: ${props => props.theme.colors.redesign.b100};
    }
    &.Mui-error {
      color: ${props => props.theme.colors.redesign.db60};
    }
    &.Mui-disabled {
      color: ${props => props.theme.colors.redesign.db60};
    }
  }
`;

const MUIErrorText = css`
  font-size: 11px;
  line-height: 13px;
  color: ${props => props.theme.colors.redesign.r100};
`;

const MUIError = css`
  .MuiFormHelperText-root {
    margin-left: 0;
    margin-top: 4px;

    &.Mui-error {
      ${MUIErrorText}
    }
  }
`;

const MUIBorder = css`
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    border-radius: ${props => props.theme.borderRadius.basic};
    border-color: ${props => props.theme.colors.redesign.db30};
    border-width: 2px;
  }
  .MuiInputBase-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.redesign.db30};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.redesign.b60};
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.redesign.r100};
      }
    }
    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.redesign.disabled_grey};
      }
    }
  }
`;

const MUIInput = css`
  &.MuiFormControl-root {
    position: relative;
    width: 100%;
  }

  .MuiInputBase-root {
    background-color: ${props => props.theme.colors.white};
    min-height: 48px;
    padding: 0;
    .MuiInputBase-input {
      color: ${props => props.theme.colors.primary.base};
      ${props => props.theme.font.input.field}
    }
    .MuiOutlinedInput-notchedOutline legend {
      display: none;
    }
    &.Mui-disabled {
      .MuiOutlinedInput-input {
        background-color: ${props => props.theme.colors.redesign.disabled_grey};
        color: ${props => props.theme.colors.redesign.db70};
        border-radius: ${props => props.theme.borderRadius.basic};
      }
    }
  }
`;

export { MUILabelBase, MUITextInputLabel, MUIError, MUIErrorText, MUIBorder, MUIInput };
