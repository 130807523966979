import { useContext, useEffect, useState } from "react";
import type { ChangeEvent } from "react";
import type React from "react";

import { Chip } from "@material-ui/core";
import type { AutocompleteGetTagProps, FilterOptionsState } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MUIAutocomplete from "shared/atoms/inputs/MUIAutocomplete";
import { ICDCodeTypeKeywordsSE } from "types";
import type { ICDCode, ICDCodeCategory, ICDCodeResponse, ICDCodeType } from "types";
import { CurrentPatientContext } from "utils/contexts";

import { getColorFromICDCodeType } from "../helpers/icdCodeHelpers";

interface Option {
  label: string;
  value: string | number;
  type?: ICDCodeType;
}

interface SelectedItemProps extends Option {
  tagProps: Record<string, unknown>;
}

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly icdFiltersEnabled?: boolean;
  readonly icdFilterType?: ICDCodeType;
  readonly icdFilterCategory?: ICDCodeCategory;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: ChangeEvent<{}>) => void;
  readonly setAvailableICDCount?: (totalCount: number) => void;
}

const ICDAutocomplete: React.VFC<Props> = ({
  className,
  disabled,
  error,
  label,
  name,
  required,
  icdFiltersEnabled,
  icdFilterType,
  icdFilterCategory,
  onChange,
  setAvailableICDCount,
}) => {
  const { availableICDCodes }: { availableICDCodes: ICDCodeResponse[] } = useContext(CurrentPatientContext);
  const { t } = useTranslation();
  const { setValue, getValues, watch } = useFormContext();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // updating the counter when filters are selected
    if (setAvailableICDCount) setAvailableICDCount(options.length);
    // updating value with R52.9 when filtering on "ALL" and "exclusion"
    if (
      icdFilterCategory === "ALL" &&
      icdFilterType === "exclusion" &&
      getValues(name) &&
      getValues(name).length === 0
    ) {
      setValue(name, ["R52.9"]);
    }
  }, [icdFilterType, icdFilterCategory]);

  const filterOptions = (testOptions: string[], state: FilterOptionsState<string>) => {
    const filter = createFilterOptions({
      stringify: (icdCode: string) => {
        const opt = getOptionByCode(icdCode);
        const baseString = `${opt.value} ${opt.label}`;
        if (opt.type) {
          const typeArray = opt.type.split(", ");
          return `${baseString} ${opt.type} ${typeArray
            .map(type => ICDCodeTypeKeywordsSE[type.toUpperCase()])
            .join(", ")}`;
        }
        return baseString;
      },
    })(testOptions, state);
    // updating the counter when typing
    if (setAvailableICDCount) setAvailableICDCount(filter.length);

    return filter;
  };

  const filteredAvailableICDCodes = availableICDCodes
    // filtering by type returns codes which descriptions have that type text string
    .filter(icd => {
      return icdFilterType && icdFilterType !== "ALL"
        ? ICDCodeTypeKeywordsSE?.[icdFilterType?.toUpperCase()]?.some(keyword =>
            icd.description.toLowerCase().includes(keyword)
          ) ||
            icd.type.includes(icdFilterType) ||
            (icdFilterType !== "exclusion" &&
              ICDCodeTypeKeywordsSE.GENERAL.some(keyword => icd.description.toLowerCase().includes(keyword)))
        : icd;
    })
    // filtering by category is inactive if a code does not have the property
    .filter(icd => (icdFilterCategory && icdFilterCategory !== "ALL" ? icd.category === icdFilterCategory : icd));

  // filtering is applied only if filters are shown
  const options = icdFiltersEnabled
    ? filteredAvailableICDCodes.map(({ code }) => code)
    : availableICDCodes.map(({ code }) => code);

  // Current values are added to the options
  if (getValues(name)) {
    getValues(name).forEach((element: ICDCode) => {
      if (!options.includes(element)) {
        options.push(element);
      }
    });
  }

  // For Frösjö the field "previous_surveys_when" is displayed
  const watchedFieldValue = watch("structured_fields.previous_surveys_when");
  useEffect(() => {
    if (
      getValues(name) &&
      getValues(name).length === 0 &&
      (watchedFieldValue === "exclude" || watchedFieldValue === "no_visit")
    ) {
      setValue(name, ["R52.9"]);
    }
  }, [watchedFieldValue]);

  /**
   * NOTE: Translations for ICD code labels in Phrase have dots replaced with underscores to avoid namespace conflicts
   * @param {ICDCodeResponse[]} codes ex: [{"code":"M16.0","type":"hip"}]
   *                                  Phrase translation key: "diagnosis.codes.M16_0"
   * @returns {Option[]}              ex: [{label: "Bilateral primary OA of hip", value: "M16.0", type: "hip"}]
   */
  const getOptionByCode = (code: string): Option => {
    const opt = availableICDCodes.find(em => em.code === code);
    if (opt) {
      return {
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: opt.description ? opt.description : t(`diagnosis.codes.${code.replace(".", "_")}`),
        value: code,
        type: opt.type,
      };
    }
    return { label: "", value: "" };
  };

  const renderCustomTag = (valueArray: string[], getTagProps: AutocompleteGetTagProps) => {
    return valueArray.map((value: string, i: number) => (
      <ICDSelectedItem key={value} {...getOptionByCode(value)} tagProps={getTagProps({ index: i })} />
    ));
  };

  return (
    <MUIAutocomplete
      disableCloseOnSelect
      className={className}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      options={options}
      required={required}
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      placeholder={!disabled && t("patients.notes.fields.icd_codes_placeholder")}
      onChange={(event, valueArray) => {
        setValue(name, valueArray, {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (onChange) onChange(event);
      }}
      noOptionsText={t("errors.icd_autocomplete_notfound")}
      filterOptions={filterOptions}
      renderOption={option => <ICDMenuItem {...getOptionByCode(option)} />}
      renderTag={(valueArray, getTagProps) => renderCustomTag(valueArray, getTagProps)}
      searchValue={searchValue}
      setSearchValue={event => {
        setSearchValue(event.target.value);
      }}
    />
  );
};

const ICDMenuItem: React.VFC<Option> = ({ label, value, type }): JSX.Element => (
  <ItemContainer>
    <CodeWrapper>
      <CodeContainer $color={getColorFromICDCodeType(type as ICDCodeType)}>{value}</CodeContainer>
    </CodeWrapper>
    <DescriptionContainer>{label}</DescriptionContainer>
  </ItemContainer>
);

const ICDSelectedItem: React.VFC<SelectedItemProps> = ({ type, label, value, tagProps }): JSX.Element => (
  <StyledChip
    {...tagProps}
    variant="outlined"
    label={
      <ItemContainer>
        <CodeContainer $color={getColorFromICDCodeType(type as ICDCodeType)}>{value}</CodeContainer>
        <DescriptionContainer>{label}</DescriptionContainer>
      </ItemContainer>
    }
  />
);

export { ICDAutocomplete };

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: ${props => props.theme.borderRadius.basic};
    margin: ${props => props.theme.spacing.S_5};
    margin-left: 0;
    border: none;
    background-color: ${props => props.theme.colors.greys.light2};

    &.Mui-disabled {
      background-color: ${props => props.theme.colors.greys.light6};
    }

    .MuiChip-label {
      padding-left: ${props => props.theme.spacing.S_5};
      padding-right: ${props => props.theme.spacing.S_5};
    }
    .MuiChip-deleteIcon {
      pointer-events: all;
    }
  }
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.font.input.field["font-size"]};
  height: 30px;
`;

const CodeWrapper = styled.div`
  width: 80px;
`;

const CodeContainer = styled.span<{ $color: string }>`
  background-color: ${props => props.$color};
  border-radius: 4px;
  color: #fff;
  font-size: ${props => props.theme.font.input.field["font-size"]};
  margin-right: 4px;
  padding: 2px 4px;
`;

const DescriptionContainer = styled.div`
  padding: 2px 4px;
  white-space: nowrap;
  ${props => props.theme.belowBreakpoint} {
    white-space: normal;
  }
`;
