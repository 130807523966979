import type React from "react";
import { useState } from "react";

import { activityRoutes } from "routes/library";
import ProtocolView from "routes/patients/PatientProfile/components/Protocol";
import { ProtocolContext } from "utils/contexts";

const Protocol: React.VFC = () => {
  const [selectedDayMobile, setSelectedDayMobile] = useState(null);

  return (
    <ProtocolContext.Provider
      value={{
        selectedDayMobile,
        setSelectedDayMobile,
      }}
    >
      {activityRoutes()}
      <ProtocolView />
    </ProtocolContext.Provider>
  );
};

export default Protocol;
