import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface PostAcknowledgeNewPatientResponse {
  acknowledged_at: string;
  patient_id: number;
  recipeint_id: number;
}

const acknowledgeNewPatientAlert = (
  patientId: string,
  therapistId: string
): Promise<AxiosResponse<PostAcknowledgeNewPatientResponse>> =>
  AxiosInstance.post(`/patients/${patientId}/acknowledge_new_patient_alert`, {
    id: patientId,
    recipient_id: therapistId,
  });

export default acknowledgeNewPatientAlert;
