import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { UserCourseTemplates } from "api/schemas/UserCourse";
import { PrimaryButton } from "shared/atoms/Button";

import DropDown from "./DropDown";
import { RadioItem } from "./RadioRow";
import ResponsivePopup from "./ResponsivePopup";
import YesNoPopup from "./YesNoPopup";

const TemplatePopup = ({
  onSaveTemplate,
  onUpdateTemplate,
  onDeleteTemplate,
  onSetTemplate,
  templates,
  onClose,
  initialView = "save",
}: {
  onSaveTemplate: (name: string) => void;
  onUpdateTemplate: (id: number, name: string) => void;
  onDeleteTemplate: (id: number) => void;
  onSetTemplate: (id: number) => void;
  templates?: UserCourseTemplates;
  onClose: () => void;
  initialView: "none" | "save" | "use" | "delete" | "replace";
}) => {
  const { t } = useTranslation();
  const [templateSaveName, setTemplateSaveName] = useState("");
  const [templateReplaceName, setTemplateReplaceName] = useState("");
  const [view, setView] = useState(initialView);
  const [showReplaceExercisesConfirmationPopup, setShowReplaceExercisesConfirmationPopup] = useState(false);
  const [selectedUseExercisesId, setSelectedUseExercisesId] = useState<number | undefined>();

  return (
    <ResponsivePopup open onClose={() => onClose()}>
      <>
        <Wrapper>
          <Title>{t("usercourse.templates.manage")}</Title>

          <Section
            label={t("usercourse.templates.save_as.title")}
            checked={view === "save"}
            onClick={() => setView("save")}
          >
            <>
              <TextInput
                placeholder={t("usercourse.templates.save_as.placeholder")}
                type="text"
                value={templateSaveName}
                onChange={e => setTemplateSaveName(e.target.value)}
              />
              <div>
                <PrimaryButton
                  disabled={!templateSaveName}
                  onClick={() => {
                    onSaveTemplate(templateSaveName);
                  }}
                >
                  {t("usercourse.templates.save_as.cta")}
                </PrimaryButton>
              </div>
            </>
          </Section>
          <Section
            label={t("usercourse.templates.replace.title")}
            checked={view === "replace"}
            onClick={() => setView("replace")}
          >
            <>
              <TemplateSelect
                hideStandardized
                templates={templates}
                submitLabel={t("usercourse.templates.replace.cta")}
                onSubmit={id => onUpdateTemplate(id, templateReplaceName)}
                onChange={id => setTemplateReplaceName(templates?.find(tmp => tmp.id === id)?.name || "")}
              >
                <Col>
                  <TextInput
                    placeholder={t("usercourse.templates.save_as.placeholder")}
                    type="text"
                    value={templateReplaceName}
                    onChange={e => setTemplateReplaceName(e.target.value)}
                  />
                </Col>
              </TemplateSelect>
            </>
          </Section>

          <Section label={t("usercourse.templates.use.title")} checked={view === "use"} onClick={() => setView("use")}>
            <>
              <TemplateSelect
                templates={templates}
                submitLabel={t("usercourse.templates.use.cta")}
                onSubmit={id => {
                  setSelectedUseExercisesId(id);
                  setShowReplaceExercisesConfirmationPopup(true);
                }}
              />
            </>
          </Section>

          <Section
            label={t("usercourse.templates.delete.title")}
            checked={view === "delete"}
            onClick={() => setView("delete")}
          >
            <TemplateSelect
              hideStandardized
              templates={templates}
              onSubmit={id => onDeleteTemplate(id)}
              submitLabel={t("usercourse.templates.delete.cta")}
            />
          </Section>
        </Wrapper>
        {showReplaceExercisesConfirmationPopup && (
          <YesNoPopup
            headerText={t("usercourse.templates.use.cta")}
            bodyText={t("usercourse.templates.use.body")}
            onClose={() => {
              setShowReplaceExercisesConfirmationPopup(false);
            }}
            onYes={() => {
              if (selectedUseExercisesId) {
                setSelectedUseExercisesId(undefined);
                setShowReplaceExercisesConfirmationPopup(false);
                onSetTemplate(selectedUseExercisesId);
                onClose();
              }
            }}
            onNo={() => {
              setShowReplaceExercisesConfirmationPopup(false);
            }}
          />
        )}
      </>
    </ResponsivePopup>
  );
};

const TemplateSelect = ({
  templates = [],
  onSubmit,
  onChange,
  submitLabel,
  hideStandardized,
  children,
}: {
  templates?: UserCourseTemplates;
  onSubmit: (id: number) => void;
  onChange?: (id: number) => void;
  submitLabel: string;
  hideStandardized?: boolean;
  children?: React.ReactElement;
}) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState<undefined | number>();
  return (
    <>
      <DropDown
        size="large"
        value={String(templateId) || ""}
        options={
          templates
            ? [{ label: t("usercourse.templates.replace.label"), value: "" }].concat(
                templates
                  .filter(tmp => (hideStandardized ? !tmp.standardized : tmp))
                  .map(tmp => {
                    const name = tmp.key
                      ? t(`usercourse.templates.standardized.name.${tmp.key}`)
                      : tmp.name || String(tmp.id);
                    return {
                      label: name,
                      value: String(tmp.id),
                    };
                  })
              )
            : []
        }
        disabled={!templates}
        onChange={v => {
          setTemplateId(parseInt(v, 10));
          if (onChange) {
            onChange(parseInt(v, 10));
          }
        }}
      />
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <div>
        <PrimaryButton
          disabled={!templateId}
          onClick={() => {
            if (templateId) {
              onSubmit(templateId);
            }
          }}
        >
          {submitLabel}
        </PrimaryButton>
      </div>
    </>
  );
};

const Section = ({
  label,
  checked,
  onClick,
  children,
}: {
  label: string;
  checked: boolean;
  onClick: () => void;
  children: React.ReactElement;
}) => {
  return (
    <SectionRoot
      $selected={checked}
      onClick={() => {
        onClick();
      }}
    >
      <RadioItem label={label} value="" checked={checked} onChange={() => onClick()} />
      {checked && children}
    </SectionRoot>
  );
};

export default TemplatePopup;

const Title = styled.div`
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: 16px;
`;

const TextInput = styled.input`
  font-size: 16px;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.greys.concrete};
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  padding: 32px;
  background-color: white;
  ${props => props.theme.aboveBreakpoint} {
    width: 500px;
  }
  text-align: center;
`;

const ChildrenWrapper = styled.div`
  display: flex;
`;

const Col = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const SectionRoot = styled.div<{ $selected: boolean }>`
  border: 1px solid #e1e8ef;
  background-color: ${props => (props.$selected ? "#f8f8f8" : "white")};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
