import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CheckIcon } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import { CurrentPatientContext } from "utils/contexts";

const ViewButton = styled(PrimaryButton)`
  min-height: 56px;
  min-width: 124px;
  margin-right: 20px;
`;
const Icon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
  margin-left: 32px;
`;
const ReferralBox = styled.div`
  max-width: ${props => props.theme.containerWidth}px;
  padding: ${props => props.theme.spacing.S_10};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  flex-grow: 1;
  margin-left: 12px;
`;

interface ReferralValidBoxProps {
  referralExpiryDate: string;
}

const ReferralValidBox: React.VFC<ReferralValidBoxProps> = ({ referralExpiryDate }) => {
  const { t } = useTranslation();
  const { patient } = useContext(CurrentPatientContext);
  const navigate = useNavigate();
  return (
    <ReferralBox>
      <Icon />
      <Text>
        {t("patients.referral_expires")} {referralExpiryDate}
      </Text>
      <ViewButton onClick={() => navigate(`/patients/${patient.id}/information/journal`)}>View</ViewButton>
    </ReferralBox>
  );
};

export default ReferralValidBox;
