import type React from "react";
import { useState } from "react";

import styled from "styled-components";

import { CounterMinusIcon, CounterPlusIcon } from "assets";

import CounterButton from "../CounterButton";

const Container = styled.div`
  background-color: ${props => props.theme.colors.payment.background};
  border-radius: ${props => props.theme.borderRadius.library};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  height: 34px;
`;

const StyledPlus = styled(CounterPlusIcon)`
  width: 20px;
  height: 20px;
`;

const StyledMinus = styled(CounterMinusIcon)`
  width: 20px;
  height: 20px;
`;

const maxAndMinValueValidator = (minValue: number | null, maxValue: number | null, startValue: number) => {
  if (typeof minValue !== "number") {
    return new Error(`Invalid prop minValue supplied to Counter, expected number`);
  }
  if (typeof maxValue !== "number") {
    return new Error(`Invalid prop maxValue supplied to Counter, expected number`);
  }
  if (minValue > maxValue) {
    return new Error(`Invalid props, minValue is bigger than maxValue in Counter`);
  }
  if (minValue > startValue) {
    return new Error(`Invalid props, minValue is bigger than startValue in Counter`);
  }
  if (maxValue < minValue) {
    return new Error(`Invalid props, maxValue is smaller than minValue in Counter`);
  }
  if (maxValue < startValue) {
    return new Error(`Invalid props, maxValue is smaller than startValue in Counter`);
  }
  return undefined;
};

interface Props {
  readonly startValue?: number;
  readonly clickCallback?: (value: number) => void;
  readonly minValue?: number | null;
  readonly maxValue?: number | null;
}

const Counter: React.VFC<Props> = ({ startValue = 0, clickCallback = () => undefined, minValue = 0, maxValue = 1 }) => {
  const [value, setValue] = useState(startValue);
  maxAndMinValueValidator(minValue, maxValue, startValue);
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, newValue: number) => {
    evt.preventDefault();
    setValue(newValue);
    clickCallback(newValue);
  };

  return (
    <Container data-testid="counter">
      <CounterButton
        dataTestId="decrease-level"
        onClick={evt => handleClick(evt, value - 1)}
        disabled={value === minValue}
      >
        <StyledMinus />
      </CounterButton>
      <CounterButton
        dataTestId="increase-level"
        onClick={evt => handleClick(evt, value + 1)}
        disabled={value === maxValue}
      >
        <StyledPlus />
      </CounterButton>
    </Container>
  );
};

export default Counter;
