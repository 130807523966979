import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import type { Call, Device } from "@twilio/voice-sdk";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AudioIcon, AudioIconDisabled, EndCallIcon } from "assets/videocall";

import CallingStateModal from "../../videocall/components/CallingStateModal";
import type { CallState } from "../../videocall/types";
import AudioVisualizer from "../component/AudioVisualizer";
import getPhoneCallToken from "../queries/getPhoneCallToken";
import getVoiceCallToken from "../queries/getVoiceCallToken";
import type { VoiceCallData } from "../types";

interface Props {
  readonly voiceCallData: VoiceCallData;
  readonly onVoiceCallEnded: () => void;
}

const VoiceCall: React.VFC<Props> = ({ voiceCallData, onVoiceCallEnded }) => {
  const { t } = useTranslation();
  const device = useRef<Device | null>(null);
  const [voiceCall, setVoiceCall] = useState<Call | null>(null);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [callState, setCallState] = useState<CallState>("IDLE");

  useEffect(() => {
    initiateCall();
  }, []);

  const initiateCall = async () => {
    try {
      const response = voiceCallData.callee.phone_number
        ? await getPhoneCallToken(voiceCallData.callee.id, voiceCallData.caller.id)
        : await getVoiceCallToken(voiceCallData.callee.id, voiceCallData.caller.id);

      if (response.data.token) {
        const twilio = await import("@twilio/voice-sdk");
        device.current = new twilio.Device(response.data.token);
        device.current.on("error", () => {
          setCallState("CONNECTION_FAILED");
          setVoiceCall(null);
        });

        if (!voiceCall) {
          deviceConnect(response.data.call_identifier);
        }
      }
    } catch (error) {
      setCallState("CONNECTION_FAILED");
      setVoiceCall(null);
    }
  };

  const deviceConnect = (callIdentifier: string) => {
    if (device.current) {
      device.current
        .connect({
          params: voiceCallData.callee.phone_number
            ? { phone_number: voiceCallData.callee.phone_number, phone_call_identifier: callIdentifier }
            : { identifier: callIdentifier },
        })
        .then(call => {
          call.on("disconnect", () => {
            setVoiceCall(null);
            setCallState("CALLEE_DISCONNECTED");
            setTimeout(() => {
              if (voiceCall) endVoiceCall();
            }, 3000);
          });

          const interval = setInterval(() => {
            if (call.status() === "open") {
              setVoiceCall(call);
              clearInterval(interval);
            }
          }, 500);
        });
    }
  };

  const onAudioEnabledToggled = () => {
    if (voiceCall) {
      voiceCall.mute(audioEnabled);
      setAudioEnabled(prevAudioEnabled => !prevAudioEnabled);
    }
  };

  const endVoiceCall = useCallback(() => {
    if (device.current) {
      device.current?.disconnectAll();
      device.current?.destroy();
      device.current = null;
    }
    setVoiceCall(null);
    setCallState("IDLE");
    onVoiceCallEnded();
  }, [onVoiceCallEnded]);

  return (
    <>
      {voiceCall ? (
        <Container data-testid="phone-call-container">
          <NameText>{voiceCallData.callee.name}</NameText>
          <Text>{t("phone_call.confirm_identity")}</Text>
          {voiceCall?.getLocalStream() && voiceCall?.getRemoteStream() && (
            <AudioVisualizer
              localStream={voiceCall.getLocalStream() as MediaStream}
              remoteStream={voiceCall.getRemoteStream() as MediaStream}
            />
          )}
          <ButtonContainer>
            <ControlButton data-testid="phone-call-hangup-button" onClick={() => endVoiceCall()}>
              <IconEndCall />
            </ControlButton>
            <ControlButton onClick={onAudioEnabledToggled}>
              {audioEnabled ? <EnabledAudio /> : <DisabledAudio />}
            </ControlButton>
          </ButtonContainer>
        </Container>
      ) : (
        <CallingStateModal callState={callState} error={null} onEndCall={endVoiceCall} onCallAgain={initiateCall} />
      )}
    </>
  );
};

export default VoiceCall;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 160px;
  padding: ${props => props.theme.spacing.S_20};
  position: fixed;
  top: ${props => props.theme.spacing.S_180};
  right: 0;
  background: ${props => props.theme.colors.voice_call_container};
  box-shadow: 0 10px 20px rgba(141, 155, 173, 0.3);
  border-radius: 8px 0 0 8px;
  z-index: ${props => props.theme.zIndex.videoCallMobile};

  ${props => props.theme.belowBreakpoint} {
    align-items: center;
    height: calc(100% - ${props => props.theme.spacing.S_40});
    width: calc(100% - ${props => props.theme.spacing.S_40});
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: auto;

  ${props => props.theme.belowBreakpoint} {
    justify-content: space-around;
    width: 100%;
  }
`;

const ControlButton = styled.button`
  border: 1px solid transparent;
  background: none;
  margin: 12px;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const IconEndCall = styled(EndCallIcon)`
  width: 53px;
  height: 53px;
`;

const EnabledAudio = styled(AudioIcon)`
  width: 53px;
  height: 53px;
`;

const DisabledAudio = styled(AudioIconDisabled)`
  width: 53px;
  height: 53px;
`;

const NameText = styled.span`
  ${props => props.theme.font.link2};
  color: ${props => props.theme.colors.white};

  ${props => props.theme.belowBreakpoint} {
    ${props => props.theme.font.header1};
  }
`;

const Text = styled.span`
  ${props => props.theme.font.caption};
  color: ${props => props.theme.colors.white};

  ${props => props.theme.belowBreakpoint} {
    ${props => props.theme.font.body1};
  }
`;
