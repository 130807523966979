import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import type { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";

import { AudioIconDisabled } from "assets/videocall";

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const AudioContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AudioDisabledIcon = styled(AudioIconDisabled)`
  width: 35px;
  height: 35px;
`;

const AudioDisabledText = styled.p`
  ${props => props.theme.font.body2};
  color: ${props => props.theme.colors.white};
  margin-top: ${props => props.theme.spacing.S_15};
  padding: ${props => `0 ${props.theme.spacing.S_10}`};
`;

const VideoDisabledText = styled.p`
  margin: ${props => props.theme.spacing.S_10};
  line-height: 36px;
  font-size: 30px;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
`;

interface Props {
  kinds?: Array<"audio" | "video">;
  minimised: boolean;
  publications: Array<LocalTrackPublication | RemoteTrackPublication>;
  username: string;
}

export const getInitials = (fullName: string): string => {
  return fullName
    ? fullName
        .trim()
        .split(/\s+/)
        .reduce((initials, name) => `${initials}${name[0].toUpperCase()}`, "")
    : "";
};

const DisabledPublicationsOverlay: React.VFC<Props> = ({
  kinds = ["audio", "video"],
  minimised,
  publications,
  username,
}) => {
  const { t } = useTranslation();

  const renderDisabledAudio = (key: string) => {
    return (
      <AudioContainer key={key}>
        <AudioDisabledIcon />
        {!minimised && <AudioDisabledText>{t("videocall.disabled_microphone", { user: username })}</AudioDisabledText>}
      </AudioContainer>
    );
  };

  const renderDisabledVideo = (key: string) => {
    return <VideoDisabledText key={key}>{getInitials(username)}</VideoDisabledText>;
  };

  const renderDisabledMessage = (key: string, kind: string) => {
    switch (kind) {
      case "audio":
        return kinds.includes("audio") ? renderDisabledAudio(key) : null;
      case "video":
        return kinds.includes("video") ? renderDisabledVideo(key) : null;
      default:
        return null;
    }
  };

  return (
    <Container>
      {publications.map(({ isTrackEnabled, kind, trackSid }) => {
        return !isTrackEnabled ? renderDisabledMessage(`${kind}_${trackSid}`, kind) : null;
      })}
    </Container>
  );
};

export default DisabledPublicationsOverlay;
