import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { HCProfessionalResponse } from "api/schemas/HCProfessional";
import useApiClient from "api/useApiClient";

type QueryKey = ["getHCProfessionals", number];
export const getHCProfessionalsQueryKey = (managerID: number): QueryKey => ["getHCProfessionals", managerID];

const useGetProfessionals = (
  managerID: number,
  options?: UseQueryOptions<HCProfessionalResponse[], Error, HCProfessionalResponse[], QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getHCProfessionalsQueryKey(managerID), () => client.getHCProfessionals(managerID), options);
};

export default useGetProfessionals;
