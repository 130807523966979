import { css } from "styled-components";

const iconButtonStyles = css<{ margin?: string }>`
  height: 30px;
  width: 30px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius.basic};
  background-color: ${props => props.theme.colors.white};
  margin: ${props => (props.margin ? props.margin : props.theme.spacing.S_10)};
  padding: 0;
  &:hover {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.primary.base};
  }
  &:active {
    background-color: ${props => props.theme.colors.primary.lighter};
    border: ${props => `1px solid ${props.theme.colors.primary.lighter}`};
  }
  &:disabled {
    border: ${props => `1px solid ${props.theme.colors.greys.pinkish}`};
    background-color: ${props => props.theme.colors.white};
    cursor: default;
  }
`;

const iconWrapperStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export { iconButtonStyles, iconWrapperStyles };
