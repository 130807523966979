import type { Ailment } from "api/schemas/patients/HealthJournal";

type TreatmentFeature = "feelingChart" | "functionChart" | "painChart" | "painLocation";

type TrearmentConfig = {
  feelingChart: boolean;
  functionChart: boolean;
  painChart: boolean;
  painLocation: boolean;
};

const TREATMENT_CONFIG: Record<Ailment, TrearmentConfig> = {
  fall_prevention: {
    feelingChart: true,
    functionChart: true,
    painChart: false,
    painLocation: false,
  },
  joint_pain: {
    feelingChart: false,
    functionChart: true,
    painChart: true,
    painLocation: true,
  },
  osteoporosis: {
    feelingChart: true,
    functionChart: true,
    painChart: false,
    painLocation: false,
  },
};

interface Args {
  ailment: Ailment;
  feature: TreatmentFeature;
}

export const hasTreatmentFeature = ({ ailment, feature }: Args): boolean => {
  return TREATMENT_CONFIG[ailment][feature];
};
