import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation } from "react-query";

import type { UpdateUserCourseArgs } from "api/schemas/UserCourse";
import useApiClient from "api/useApiClient";

export const useUpdateUserCourseTemplate = (
  options?: UseMutationOptions<null, Error, UpdateUserCourseArgs>
): UseMutationResult<null, Error, UpdateUserCourseArgs> => {
  const client = useApiClient();
  return useMutation<null, Error, UpdateUserCourseArgs>(({ therapistId, templateId, course }) => {
    return client.updateUserCourseTemplate(therapistId, templateId, course);
  }, options);
};
