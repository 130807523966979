import { z } from "zod";

/**
 * Checksum according to Luhn algorithm: https://en.wikipedia.org/wiki/Personal_identity_number_(Sweden)
 * @param personalNumber The string that should be validated
 */
function isSocialSecurityNumberValid(personalNumber: string) {
  const parsedValue = personalNumber.replace("-", "");
  const root = parsedValue.substr(2, 9);
  const splitNumeric = root.split("").map(n => parseInt(n, 10));
  const alternate = splitNumeric.map((n, i) => n * (((i + 1) % 2) + 1));
  const flatten = alternate.map(n => (n > 9 ? n - 9 : n));
  const sum = flatten.reduce((tot, n) => tot + n);
  const control = (10 - (sum % 10)) % 10;
  const lastDigit = parseInt(parsedValue.charAt(11), 10);

  return control === lastDigit;
}

export const pinSchema = z
  .string()
  .length(12)
  .regex(/^[0-9]+$/, { message: "contains_nondigits" })
  .refine(isSocialSecurityNumberValid, {
    message: "Not a valid swedish social security number",
  });

export const bankIdSchema = z.object({
  pin: pinSchema,
});

export type BankIdLoginParams = z.infer<typeof bankIdSchema>;

const loginBankId = (data: BankIdLoginParams) => {
  return bankIdSchema.safeParse(data);
};

export default loginBankId;
