/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/rules-of-hooks */

import type React from "react";
import { useEffect, useRef } from "react";

import styled from "styled-components";
import type { LocalVideoTrack, RemoteVideoTrack, Track } from "twilio-video";

import useMediaStreamTrack from "./hooks/useMediaStreamTrack";

const Video = styled.video`
  border-radius: ${props => `${props.theme.borderRadius.basic} 0 0 ${props.theme.borderRadius.basic}`};
  object-fit: cover;
  width: 100%;

  ${props => props.theme.belowBreakpoint} {
    border-radius: 0;
  }
`;

interface MainVideoProps {
  readonly $isFrontFacing: boolean;
}

const MainVideo = styled.video<MainVideoProps>`
  border-radius: ${props => props.theme.borderRadius.basic};
  object-fit: cover;
  transform: ${props => (props.$isFrontFacing ? "rotateY(180deg)" : "")};
  width: 100%;

  ${props => props.theme.belowBreakpoint} {
    border-radius: 0;
  }
`;

const isVideoElement = (videoElement: HTMLVideoElement | null): videoElement is HTMLVideoElement =>
  videoElement !== null;

const isRemoteVideoTrack = (track: LocalVideoTrack | RemoteVideoTrack): track is RemoteVideoTrack =>
  "setPriority" in track;

interface Props {
  readonly track: LocalVideoTrack | RemoteVideoTrack;
  readonly isMain: boolean;
  readonly priority?: Track.Priority;
}

const VideoTrack: React.VFC<Props> = ({ track, isMain, priority = "high" }) => {
  if (track?.isEnabled) {
    const ref = useRef<HTMLVideoElement>(null);
    const mediaStreamTrack = useMediaStreamTrack(track);

    useEffect(() => {
      const el = ref.current;
      if (isVideoElement(el)) {
        el.muted = true;
        track.attach(el);
      }
      if (isRemoteVideoTrack(track) && priority) {
        track.setPriority(priority);
      }

      return () => {
        if (isVideoElement(el)) {
          track.detach(el);
        }
        if (isRemoteVideoTrack(track) && priority) {
          // NOTE: Passing `null` to setPriority will set the track's priority to that which it was published with.
          track.setPriority(null);
        }
      };
    }, [track, priority]);

    if (isMain) {
      // The local video track is mirrored if it is not facing the environment.
      const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== "environment";
      return <MainVideo ref={ref} $isFrontFacing={isFrontFacing} />;
    }
    return <Video ref={ref} />;
  }
  return null;
};
export default VideoTrack;
